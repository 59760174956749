import { useConnections } from "@/features/connectors";
import useChainedObject from "@/hooks/useChainedObject";

import { useSyncContext } from "../modules/SyncContext";

export default function useDestinations() {
  const [state] = useSyncContext();
  const { connections: destinations, loading } = useConnections(
    "ReverseEltDestinationConnection",
  );

  const { currentObject } = useChainedObject(
    destinations,
    "id",
    state.destinationId,
  );

  return {
    destinations,
    destinationsLoading: loading,
    currentDestination: currentObject,
  };
}
