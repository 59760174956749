import classNames from "@/helpers/classNames";

const DraftBadge = (props: React.ComponentProps<"span">) => {
  return (
    <span
      style={{
        fontSize: "0.6rem",
        paddingRight: "3px",
        paddingLeft: "3px",
      }}
      className={classNames(
        "inline-flex items-center rounded-md bg-gray-400 py-px text-xs font-medium leading-normal text-white dark:bg-gray-600",
        props.className,
      )}
    >
      draft
    </span>
  );
};

export default DraftBadge;
