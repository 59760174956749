import { Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@/components/elements/input/ListboxDropdown";
import { RowCountBasis, useUsageStatsContext } from "@/features/usage-stats";

import { ActiveRowTooltipTrigger } from "../ActiveRow";

export function RowBasisMenu() {
  const { rowCountBasis, setRowCountBasis, enableBillableRows } =
    useUsageStatsContext();
  return (
    <Listbox<"div", RowCountBasis>
      onChange={(value) => setRowCountBasis(value)}
      as="div"
      className="relative z-10 inline-block text-left"
    >
      <ListboxButton
        className="truncate"
        size="sm"
        iconRight={<ChevronDownIcon className="ml-1 h-5 w-5" />}
      >
        {rowCountBasis === "billable-rows" ? "Active rows" : "Total rows"}
      </ListboxButton>

      <ListboxOptions className={"w-fit"}>
        <div className="px-1 py-1">
          <ListboxOption
            value="billable-rows"
            disabled={!enableBillableRows}
            className="whitespace-nowrap"
          >
            <span className="inline-flex items-center gap-2">
              Active rows
              <ActiveRowTooltipTrigger>
                <InformationCircleIcon className="h-4" />
              </ActiveRowTooltipTrigger>
            </span>
          </ListboxOption>
          <ListboxOption value="total-rows" className="whitespace-nowrap">
            Total rows
          </ListboxOption>
        </div>
      </ListboxOptions>
    </Listbox>
  );
}
