import React from "react";

type EmojiProps = {
  symbol: number | string;
  label?: string;
} & React.ComponentProps<"span">;

export function Emoji({ symbol, label, ...props }: EmojiProps) {
  const renderSymbol = (symbol: string | number) => {
    if (typeof symbol === "number") {
      return String.fromCodePoint(symbol);
    }
    if (typeof symbol === "string" && symbol.startsWith("0x")) {
      return String.fromCodePoint(parseInt(symbol, 16));
    }
    return symbol;
  };

  return (
    <span
      role="img"
      aria-label={label ? label : ""}
      aria-hidden={label ? "false" : "true"}
      {...props}
    >
      {renderSymbol(symbol)}
    </span>
  );
}
