import { ActionButton, UtilityButton } from "@/components/elements/Button";
import Center from "@/components/elements/layout/Center";
import { DangerGraphicIcon } from "@/components/icons/ContextualGraphicIcon";
import SimplePageLayout from "@/layouts/SimplePageLayout";
import { useAuth0 } from "@auth0/auth0-react";

import PageContainer from "../../components/PageContainer";
import { Heading } from "../../components/Typography";

export default function EmailNotValidPage() {
  const { loginWithRedirect } = useAuth0();

  const handleBookMeeting = () => {
    window.location.href = "https://weld.app/demo";
  };

  const handleCancel = () => {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    });
  };

  return (
    <SimplePageLayout>
      <Center className="h-full">
        <PageContainer size="sm">
          <DangerGraphicIcon className="mb-5 w-12" />
          <Heading className="mb-6">Hello friend</Heading>
          <div className="text-center text-sm leading-relaxed text-gray-600">
            <p>
              We have detected that the email you have used is a bit suspect.
            </p>
            <p className="mt-6">
              We would of course love to have you using WELD, but for continuing
              we will need you to book a meeting with our awesome customer
              support first.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center space-y-8">
            <ActionButton onClick={handleBookMeeting}>
              Book a meeting
            </ActionButton>
            <UtilityButton className="p-2" onClick={handleCancel}>
              Cancel
            </UtilityButton>
          </div>
        </PageContainer>
      </Center>
    </SimplePageLayout>
  );
}
