import { PlusIcon } from "@heroicons/react/24/outline";
import { Button } from "@/components/elements/Button";
import TabLayout from "@/components/layouts/TabLayout";
import { useCurrentAccount } from "@/providers/account";
import React from "react";
import { LinkWithSlug } from "routes";

function useTabs() {
  const account = useCurrentAccount();
  return React.useMemo(() => {
    const basePath = `/${account.slug}/reverse-etl`;
    return [
      {
        name: "Syncs",
        href: basePath,
        id: "syncs",
      },
      {
        name: "Usage",
        href: `${basePath}/usage`,
        id: "usage",
      },
    ];
  }, [account]);
}

export default function MainTabsLayout(props: React.PropsWithChildren<{}>) {
  const tabs = useTabs();
  return (
    <TabLayout
      tabs={tabs}
      action={
        <Button
          as={LinkWithSlug}
          to="/reverse-etl/new"
          icon={<PlusIcon className="w-4" />}
          tabIndex={-1}
          variant="solid"
          colorScheme="primary"
        >
          New
        </Button>
      }
    >
      <>{props.children}</>
    </TabLayout>
  );
}
