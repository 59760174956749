import { useMemo, useState } from "react";

import { TaxIdData, useTaxIdsDataQuery } from "@/apollo/types";

export function useTaxIdOptions() {
  const [options, setOptions] = useState<
    {
      label: string;
      value: string;
      data: TaxIdData;
    }[]
  >([]);

  useTaxIdsDataQuery({
    onCompleted(data) {
      setOptions(
        data.taxIdsData.map((x) => ({
          label: `${x.shortName} - ${x.country}`,
          value: x.enum,
          data: x,
        })),
      );
    },
  });

  return options;
}

export function useTaxIdOptionsForCountry(countryCode: string | undefined) {
  const options = useTaxIdOptions();
  return useMemo(() => {
    if (!countryCode) {
      return [];
    }
    return options.filter(
      (x) => x.data.countryCode.toLowerCase() === countryCode.toLowerCase(),
    );
  }, [options, countryCode]);
}
