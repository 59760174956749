import classNames from "@/helpers/classNames";
import React from "react";
import { twMerge } from "tailwind-merge";

import { Emoji } from "./Emoji";

export function EmojiIcon({
  symbol,
  className,
  ...props
}: React.ComponentProps<typeof Emoji>) {
  const [height, setHeight] = React.useState<number | null>(null);

  const ref = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    if (ref.current?.clientHeight) {
      setHeight(ref.current.clientHeight);
    }
  }, [ref.current?.clientHeight]);

  const fontSizeScale = window.devicePixelRatio > 1 ? 0.875 : 0.75;
  return (
    <span
      className={twMerge(
        classNames(
          "inline-flex shrink-0 items-center justify-center",
          "h-4 w-4",
          className,
        ),
      )}
      ref={ref}
      style={{
        fontSize: height === null ? "inherit" : `${height * fontSizeScale}px`,
      }}
      {...props}
    >
      <Emoji symbol={symbol} label={props.label} />
    </span>
  );
}
