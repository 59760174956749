export default function NewEntryButton(props: {
  open: boolean;
  selected: boolean;
  onClick: () => any;
}) {
  return (
    <li
      className={`relative border-b bg-gray-50 p-2 dark:border-gray-700 dark:bg-gray-900`}
    >
      <button
        className="group relative flex w-full items-center space-x-4 p-2 text-left"
        onClick={() => props.onClick()}
      >
        <span className="flex h-9 items-center">
          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border border-dashed border-gray-400 dark:bg-gray-800 ">
            <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-400" />
          </span>
        </span>

        <span
          className={`flex min-w-0 flex-col  ${
            props.open ? "opacity-100" : "opacity-0"
          }`}
        >
          <span className="text-xs font-semibold uppercase tracking-wide dark:text-white">
            Edited
          </span>
          <span className="truncate text-xs text-gray-500">
            Unpublished changes
          </span>
        </span>
      </button>
    </li>
  );
}
