import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Tooltip from "@/components/elements/Tooltip";
import classNames from "@/helpers/classNames";
import { IntegrationLogoBox } from "@/integrations";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { EtlJobBox } from "./SharedComponents";
import { EltSourceStreamRow } from "./useTableRows";

export const EltSourceStreamItemColumn = ({
  row,
}: {
  row: EltSourceStreamRow;
}) => {
  const isLoading = row.isLoading;

  const notInJob = !row.job;
  const noLongerInOrchestration =
    !isLoading && !row.isHistorical && row.isRemoved;
  return (
    <div
      className={classNames(
        "flex items-center space-x-4",
        noLongerInOrchestration && "opacity-50",
      )}
    >
      <EtlJobBox />
      {isLoading && (
        <div className="flex items-center space-x-2">
          <LoadingSpinner /> <span>Loading model..</span>
        </div>
      )}
      <div className="text-xs">
        <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
          {row.sync && (
            <>
              <IntegrationLogoBox id={row.sync.sourceIntegrationId} size="xs" />
              {row.sync.destinationSchemaName}
            </>
          )}
        </div>
        <div>{row.job?.target?.name ?? "Unknown table"}</div>
      </div>

      {notInJob && (
        <Tooltip
          content={
            "This sync was recently configured to use orchestration. It will be included in the next run."
          }
        >
          <div className="flex items-center space-x-1 rounded border p-1 text-xs">
            <InformationCircleIcon className="h-4 w-4" />
            <span>Recently added</span>
          </div>
        </Tooltip>
      )}
      {noLongerInOrchestration && (
        <Tooltip
          content={
            "This sync is no longer configured to use orchestration. It will not be included in the next run."
          }
        >
          <div className="flex items-center space-x-1 rounded border p-1 text-xs">
            <InformationCircleIcon className="h-4 w-4" />
            <span>Removed</span>
          </div>
        </Tooltip>
      )}
    </div>
  );
};
