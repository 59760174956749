import { atom, useAtom } from "jotai";
import { ComponentProps, useCallback, useEffect } from "react";

import { Button, DangerButton } from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import { Heading } from "@/components/elements/Typography";
import { Overlay, OverlayBackdrop } from "@/components/elements/layout/Overlay";
import { HelpIcon } from "@/components/icons/outline";
import { useCheckoutModal } from "@/features/billing";
import { DeleteAccountDialog } from "@/features/delete-account";
import { useIsFreemiumTrialExpired } from "@/features/subscription";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useIntercom } from "@/hooks/useIntercom";

import { UpgradeNowButton } from "../UpgradeNowButton";

function FreemiumTrialEndedDialogContent() {
  const disclosure = useDisclosure();
  const intercom = useIntercom();
  return (
    <div className="flex flex-col gap-8">
      <div>
        <div className="text-center leading-snug">
          To continue using WELD's powerful data pipelines, please upgrade to a
          paid plan.
        </div>
        <div className="mb-5 mt-10 flex justify-center">
          <UpgradeNowButton source="freemium trial ended app blocker" />
        </div>
      </div>
      <hr />
      <div>
        <div className="text-center text-sm">
          Not ready to upgrade yet? Chat with us to discuss extending your trial
          or explore other options.
        </div>
        <div className="mt-5 flex justify-center">
          <Button
            size="sm"
            icon={<HelpIcon />}
            onClick={() => intercom.openChat()}
          >
            Open chat
          </Button>
        </div>
      </div>
      <hr />
      <div>
        <div className="text-sm">
          If you choose not to upgrade, you may delete your account. Note that
          this will permanently delete all your data stored in WELD but will not
          affect data already synced to your warehouse.
        </div>
        <DangerButton
          size="sm"
          variant="link"
          onClick={() => disclosure.onOpen()}
        >
          Delete Account
        </DangerButton>
        <DeleteAccountDialog {...disclosure} />
      </div>
    </div>
  );
}

export function FreemiumTrialEndedAppBlocker(props: { show?: boolean }) {
  if (props.show !== true) {
    return null;
  }
  return (
    <Overlay className="absolute">
      <OverlayBackdrop />
      <div className="relative flex h-full w-full items-center justify-center">
        <div className="m-4 max-w-lg rounded bg-white p-8 shadow dark:border dark:border-gray-700 dark:bg-gray-800">
          <Heading className="mb-4 text-center text-xl">
            Your Free Trial Has Ended
          </Heading>
          <FreemiumTrialEndedDialogContent />
        </div>
      </div>
    </Overlay>
  );
}

const wasClosedAtom = atom(false);

export function useFreemiumTrialEndedDialogDisclosure() {
  const disclosure = useDisclosure();
  const isFreemiumTrialExpired = useIsFreemiumTrialExpired();

  const onOpen = disclosure.onOpen;
  const onClose = disclosure.onClose;

  const [wasClosed, setWasClosed] = useAtom(wasClosedAtom);

  const { isOpen: isCheckoutModalOpen } = useCheckoutModal();

  useEffect(() => {
    // Open the dialog if the freemium trial has expired.
    // Don't open the dialog when the checkout modal is open.
    // If the dialog was closed, don't open it again until next app load.
    if (isFreemiumTrialExpired && !wasClosed && !isCheckoutModalOpen) {
      onOpen();
    }
  }, [isFreemiumTrialExpired, wasClosed, isCheckoutModalOpen, onOpen]);

  return {
    ...disclosure,
    onClose: useCallback(() => {
      setWasClosed(true);
      onClose();
    }, [onClose, setWasClosed]),
  };
}

export function FreemiumTrialEndedDialog(props: ComponentProps<typeof Modal>) {
  return (
    <Modal {...props}>
      <ModalHeader className="text-center text-xl">
        Your Free Trial Has Ended
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody className="pb-6">
        <FreemiumTrialEndedDialogContent />
      </ModalBody>
    </Modal>
  );
}
