import { useValidateCustomFormLazyQuery } from "@/apollo/types";
import { AsyncSelect } from "@/components/elements/Select";
import { useToast } from "@/providers/ToastProvider";
import { useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";

export type Location = {
  lat: number;
  lon: number;
  city: string;
  country: string;
};

type LocationOption = {
  label: string;
  value: Location;
};

const formatLabel = (location: Location) => {
  return `${location.city} (${location.country}) [${location.lat}, ${location.lon}]`;
};

export default function OpenWeatherLocationInput(props: {
  value: Location | null;
  onChangeLocation: (location: Location) => void;
  connectionId: string;
}) {
  const toast = useToast();
  const [callValidationQuery] = useValidateCustomFormLazyQuery({
    onError(error) {
      toast("OpenWeatherMap API call failed", error.message, "error");
    },
  });

  const fetchOptions = useCallback(
    async (query: string) => {
      //Call validation endpoint
      const queryData = {
        query,
      };
      const { data, error } = await callValidationQuery({
        variables: {
          input: {
            connectionId: props.connectionId,
            payload: JSON.stringify({
              queryData,
              validateViaApi: true,
            }),
          },
        },
      });
      if (error) {
        throw error;
      }
      return data?.validateCustomForm.customFormValidationResult ?? [];
    },
    [callValidationQuery, props.connectionId],
  );

  const loadOptions = useDebouncedCallback(
    (inputValue: string, callback: (options: LocationOption[]) => void) => {
      if (!inputValue) {
        callback([]);
        return;
      }
      try {
        fetchOptions(inputValue).then((results) => {
          const dropdownOptions = (results ?? []).map((x: Location) => ({
            label: formatLabel(x),
            value: x,
          }));
          callback(dropdownOptions);
        });
      } catch (error) {
        callback([]);
      }
    },
    250,
  );

  const currentOption =
    props.value == null
      ? null
      : {
          label: formatLabel(props.value),
          value: props.value,
        };

  return (
    <AsyncSelect<LocationOption>
      value={currentOption}
      placeholder="Search for location"
      loadOptions={loadOptions}
      onChange={(option) => {
        if (!option) {
          return;
        }
        props.onChangeLocation(option.value);
      }}
      autoFocus
    />
  );
}
