import { FireMonacoEditor, IFireMonacoEditor } from "@otjs/firebase-monaco";
import { EditorClientEvent, TEditorClientError } from "@otjs/plaintext-editor";
import * as Sentry from "@sentry/react";
import { useFirebaseDatabase } from "@/features/firebase";
import { onValue, ref } from "firebase/database";
import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import { atom, useAtom, useAtomValue } from "jotai";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { useCurrentAccount } from "@/providers/account";
import { useCurrentUser } from "@/providers/UserProvider";
import React from "react";
import { useModelEditorState } from "../ModelEditorStore";

function getUserColor() {
  // Palette: "Retro Metro" from https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
  const colorPalette = [
    "#ea5545",
    "#f46a9b",
    "#ef9b20",
    "#edbf33",
    "#ede15b",
    "#bdcf32",
    "#87bc45",
    "#27aeef",
    "#b33dc6",
  ];
  const index = Math.floor(Math.random() * colorPalette.length);
  return colorPalette[index];
}

const fireMonacoInstanceAtom = atom<IFireMonacoEditor | null>(null);

type UseFireMonacoOptions = {
  onSynced?: (text: string) => void;
  onReady?: (text: string, updateText: (text: string) => void) => void;
  readonly?: boolean;
};

export function useFireMonaco(options: UseFireMonacoOptions = {}) {
  const database = useFirebaseDatabase();
  const currentUser = useCurrentUser();
  const currentAccount = useCurrentAccount();

  const onSyncedRef = useLatestValueRef(options.onSynced);
  const onReadyRef = useLatestValueRef(options.onReady);

  const accountId = currentAccount.id;
  const userId = currentUser.id;
  const userEmail = currentUser.email;

  const [fireMonacoInstance, setFireMonacoInstance] = useAtom(
    fireMonacoInstanceAtom,
  );
  const [isConnected, setIsConnected] = React.useState(false);

  const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(
    null,
  );

  const connect = React.useCallback(
    (editor: monaco.editor.IStandaloneCodeEditor, modelId: string) => {
      if (!accountId || !userEmail || !userId) {
        return;
      }

      const dbRef = ref(
        database,
        `workspaces-flattened/${accountId}/models/${modelId}`,
      );

      const fireMonaco = new FireMonacoEditor({
        announcementDuration: 1000,
        databaseRef: dbRef,
        editor: editor,
        userId: userId,
        userName: userEmail,
        userColor: getUserColor(),
      });

      editorRef.current = editor;
      setFireMonacoInstance(fireMonaco);
    },
    [database, accountId, userEmail, userId, setFireMonacoInstance],
  );

  React.useEffect(() => {
    if (fireMonacoInstance === null) {
      return;
    }
    if (fireMonacoInstance.disposed) {
      return;
    }

    const readyHandler = (isReady: boolean) => {
      if (!isReady) return;
      setIsConnected(true);
      editorRef.current?.updateOptions({ readOnly: false });
      onReadyRef.current?.(fireMonacoInstance.text, (text: string) => {
        if (fireMonacoInstance?.disposed === false) {
          fireMonacoInstance.text = text;
        }
      });
    };

    const syncHandler = () => {
      onSyncedRef.current?.(fireMonacoInstance.text);
    };

    const errorHandler = ({
      err,
      operation,
      contentLength,
      document,
      retain,
      skippedChars,
    }: TEditorClientError) => {
      Sentry.captureException(err, {
        extra: {
          operation,
          contentLength,
          document,
          retain,
          skippedChars,
        },
      });
    };

    fireMonacoInstance.on(EditorClientEvent.Ready, readyHandler);
    fireMonacoInstance.on(EditorClientEvent.Synced, syncHandler);
    fireMonacoInstance.on(EditorClientEvent.Error, errorHandler);

    const editor = editorRef.current;
    return () => {
      fireMonacoInstance.off(EditorClientEvent.Ready, readyHandler);
      fireMonacoInstance.off(EditorClientEvent.Synced, syncHandler);
      fireMonacoInstance.off(EditorClientEvent.Error, errorHandler);
      fireMonacoInstance.dispose();
      setIsConnected(false);
      setFireMonacoInstance(null);
      editor?.updateOptions({ readOnly: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fireMonacoInstance]);

  return {
    connect,
    isConnected,
  };
}

export function useUpdateFireMonacoText() {
  const fireMonacoInstance = useAtomValue(fireMonacoInstanceAtom);
  return React.useCallback(
    (text: string) => {
      if (fireMonacoInstance === null || fireMonacoInstance.disposed) {
        return;
      }
      fireMonacoInstance.text = text;
    },
    [fireMonacoInstance],
  );
}

export const useDetectFlattenedSQL = (onHistoryReset: () => void) => {
  const database = useFirebaseDatabase();
  const currentAccount = useCurrentAccount();
  const { currentModelId } = useModelEditorState();

  React.useEffect(() => {
    const dbRef = ref(
      database,
      `workspaces-flattened/${currentAccount.id}/models/${currentModelId}`,
    );
    let historyLength = 0;
    return onValue(dbRef, (snap) => {
      const value = snap.val();
      if (!value?.history) return;
      const newHistoryLength = Object.keys(value.history).length;
      if (newHistoryLength < historyLength && historyLength > 1) {
        onHistoryReset();
      }
      historyLength = newHistoryLength;
    });
  }, [currentAccount.id, currentModelId, database, onHistoryReset]);
};
