import * as Sentry from "@sentry/react";
import { MakeGenerics, useNavigate, useSearch } from "@tanstack/react-location";
import {
  SlideOver,
  SlideOverCloseButton,
} from "@/components/elements/SlideOver";
import React, { Suspense, useCallback } from "react";

import { CheckoutModalSkeletonLoader } from "./plan/CheckoutModalSkeletonLoader";

const AddOnsCheckoutModal = React.lazy(
  () => import("./add-ons/AddOnsCheckoutDialog"),
);

type LocationGenerics = MakeGenerics<{
  Search: { checkout_addons: string };
}>;

export function useAddOnsCheckoutDialog() {
  const navigate = useNavigate<LocationGenerics>();
  const { checkout_addons } = useSearch<LocationGenerics>();

  const createOpenProps = (productId?: string) => ({
    search: {
      checkout_addons: productId ?? "",
    },
  });

  const onOpen = useCallback(
    (productId?: string) => {
      navigate(createOpenProps(productId));
    },
    [navigate],
  );

  const onClose = useCallback(() => {
    navigate({
      search: {
        checkout_addons: undefined,
      },
    });
  }, [navigate]);

  return {
    openCheckoutDialog: onOpen,
    closeCheckoutDialog: onClose,
    getLinkProps: () => createOpenProps(),
    isOpen: checkout_addons !== undefined,
  };
}

export function AddOnsCheckoutModalRoot() {
  const { closeCheckoutDialog, isOpen } = useAddOnsCheckoutDialog();
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <SlideOver show={isOpen} onClose={closeCheckoutDialog} bgOverlay>
        <SlideOverCloseButton />
        <Suspense fallback={<CheckoutModalSkeletonLoader />}>
          <AddOnsCheckoutModal onClose={closeCheckoutDialog} />
        </Suspense>
      </SlideOver>
    </Sentry.ErrorBoundary>
  );
}
