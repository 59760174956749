import { Notification, useRemoveNotificationMutation } from "@/apollo/types";
import { useCallback } from "react";
import { ApolloError, BaseMutationOptions } from "@apollo/client";

export const useRemoveNotification = (props?: {
  onCompleted?: (data: any, clientOptions?: BaseMutationOptions) => void;
  onError?: (error: ApolloError, clientOptions?: BaseMutationOptions) => void;
}): [(notification: Notification) => void, boolean] => {
  const [remove, { loading }] = useRemoveNotificationMutation();

  const callback = useCallback(
    (notification: Notification) => {
      if (loading) return;

      void remove({
        variables: { input: { id: notification.id } },
        onError(error, clientOptions) {
          props?.onError?.(error, clientOptions);
        },
        onCompleted: (data, clientOptions) => {
          props?.onCompleted?.(data, clientOptions);
        },
        update(cache, { data }) {
          if (!data) return;
          const normalizedId = cache.identify(notification);
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
    [loading, props, remove],
  );

  return [callback, loading];
};
