import dayjs, { Dayjs } from "dayjs";
import useNow from "@/hooks/useNow";
import { forwardRef } from "react";

type DateInput = string | number | Date | Dayjs;

type FormatRelativeTimeProps = {
  date: DateInput;
} & React.ComponentProps<"span">;

export const FormatRelativeTime = forwardRef<
  HTMLSpanElement,
  FormatRelativeTimeProps
>((props, ref) => {
  const { date, ...spanProps } = props;
  const diffInSeconds = dayjs(date).diff(dayjs(), "seconds");
  const updateInterval = getUpdateIntervalMillis(diffInSeconds);
  const now = useNow(updateInterval);
  return (
    <span ref={ref} {...spanProps}>
      {dayjs(date).from(now)}
    </span>
  );
});

function getUpdateIntervalMillis(intervalInSeconds: number) {
  const absValue = Math.abs(intervalInSeconds);
  if (absValue <= 60) {
    return 10 * 1000; // 10 seconds
  } else if (absValue <= 10 * 60) {
    return 30 * 1000; // 30 seconds
  } else if (absValue <= 30 * 60) {
    return 60 * 1000; // 1 minute
  } else if (absValue <= 60 * 60) {
    return 10 * 60 * 1000; // 10 minutes
  } else if (absValue <= 60 * 60 * 24) {
    return 30 * 60 * 1000; // 30 minutes
  }
  return null;
}
