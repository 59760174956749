import { isOnProductionDomain } from "@/helpers/environment";

const firebaseConfig = isOnProductionDomain
  ? {
      apiKey: "AIzaSyDqGxJhCTt36Y1vtRH9ylq9hq5YnnQUPSY",
      authDomain: "weld-production-308222.firebaseapp.com",
      databaseURL:
        "https://weld-production-308222-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "weld-production-308222",
      storageBucket: "weld-production-308222.appspot.com",
      messagingSenderId: "270764851263",
      appId: "1:270764851263:web:9fb7fb7e2dca1d45f1b88a",
    }
  : {
      apiKey: "AIzaSyCqtGkvF1fkAT4iSCxcObrwScUmK7DqHI0",
      authDomain: "weld-development.firebaseapp.com",
      databaseURL:
        "https://weld-development-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "weld-development",
      storageBucket: "weld-development.appspot.com",
      messagingSenderId: "651115802069",
      appId: "1:651115802069:web:eb19a0938e1ee89149a15c",
    };

export default firebaseConfig;
