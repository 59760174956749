import { atom, useAtom } from "jotai";
import { useCallback } from "react";

import { SlideOverInputOptions } from "./useValidInputProps";

const slideOverOpenState = atom<
  {
    isOpen: boolean;
  } & SlideOverInputOptions
>({
  isOpen: false,
});

export function useDataSourceSlideOver() {
  const [state, setState] = useAtom(slideOverOpenState);
  return {
    state,
    onOpen: useCallback(
      (options?: SlideOverInputOptions) => {
        setState({
          isOpen: true,
          ...(options ?? {}),
        });
      },
      [setState],
    ),
    onClose: useCallback(() => {
      setState({
        isOpen: false,
      });
    }, [setState]),
  };
}
