import { ModelTestStatus } from "@/apollo/types";
import { OrchestrationJob } from "../WorkflowTable/useTableRows";

export default function getJobTestRunsWithStatus(job: OrchestrationJob) {
  const modelTestRuns =
    job && job.target && "modelTestRuns" in job.target
      ? job.target.modelTestRuns
      : [];
  const modelTestStatus = modelTestRuns.reduce<{
    numFailed: number;
    numSuccessful: number;
    numRunning: number;
  }>(
    (acc, modelTestRun) => {
      const status = modelTestRun.result?.status;
      const numRowsTotal = modelTestRun.result?.numRowsTotal;
      if (status === ModelTestStatus.Done) {
        if (typeof numRowsTotal === "number" && numRowsTotal > 0) {
          acc.numFailed += 1;
        } else {
          acc.numSuccessful += 1;
        }
      } else if (status === ModelTestStatus.Running) {
        acc.numRunning += 1;
      }
      return acc;
    },
    { numFailed: 0, numSuccessful: 0, numRunning: 0 },
  );
  return { runs: modelTestRuns, status: modelTestStatus };
}
