import useResizeObserver from "@react-hook/resize-observer";
import React, { RefObject } from "react";

const useSize = (target: RefObject<HTMLDivElement>) => {
  const [size, setSize] = React.useState<DOMRect>();

  React.useLayoutEffect(() => {
    if (!target.current) return;
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

export default useSize;
