import classNames from "@/helpers/classNames";
import { useDisplayCron } from "@/hooks/useCron";
import { capitalize } from "lodash";
import React from "react";

export const CronDisplayReadable: React.FC<{
  cron: string;
  className?: string;
  verbose?: true;
  capitalize?: true;
}> = (props) => {
  const { cronDisplay, cronDisplayVerbose, cronValid } = useDisplayCron(
    props.cron,
  );
  const display = props.verbose ? cronDisplayVerbose : cronDisplay;
  return (
    <span className={classNames(props.className, !cronValid && "text-red-600")}>
      {props.capitalize ? capitalize(display) : display}
    </span>
  );
};
