import { useMemo } from "react";

import { IntegrationType } from "@/integrations";

import { useAliasConnectorOptions } from "./useAliasConnectorOptions";
import { useAvailableIntegrations } from "./useAvailableIntegrations";
import { useBetaConnectorOptions } from "./useBetaConnectorOptions";
import { useCommunityCustomConnectorOptions } from "./useCommunityCustomConnectorOptions";
import { useCreateConnectorOptions } from "./useCreateConnectorOptions";
import { usePseudoConnectorOptions } from "./usePsedoConnectorOptions";

export function useConnectorOptions(options: {
  dwIntegration: IntegrationType;
  includeBeta?: boolean;
  includeCommunity?: boolean;
}) {
  const integrations = useAvailableIntegrations(options.dwIntegration);
  const connectorOptions = useCreateConnectorOptions(integrations);

  const betaConnectorOptions = useBetaConnectorOptions(options.dwIntegration);
  const communityConnectorOptions = useCommunityCustomConnectorOptions();
  const aliasConnectorOptions = useAliasConnectorOptions();
  const pseudoConnectorOptions = usePseudoConnectorOptions();

  const { includeBeta = true, includeCommunity = true } = options ?? {};
  const resultOptions = useMemo(() => {
    let options = connectorOptions
      .concat(pseudoConnectorOptions)
      .concat(aliasConnectorOptions);
    if (includeBeta) {
      options = options.concat(betaConnectorOptions);
    }
    if (includeCommunity) {
      options = options.concat(communityConnectorOptions);
    }
    return options;
  }, [
    pseudoConnectorOptions,
    aliasConnectorOptions,
    betaConnectorOptions,
    communityConnectorOptions,
    connectorOptions,
    includeBeta,
    includeCommunity,
  ]);
  return resultOptions;
}
