import { useNavigateWithSlug } from "routes";

import { useListSyncsQuery } from "@/apollo/types";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { useReflexAccordionItemContext } from "@/components/elements/ReflexAccordion/ReflexAccordionItem";
import Tooltip from "@/components/elements/Tooltip";
import { ReverseEtlIcon } from "@/components/icons/outline";
import { useMixpanel } from "@/monitoring/mixpanel";
import { PlusIcon } from "@heroicons/react/24/outline";

import { PaneActionButton } from "../components/PaneActionButton";
import {
  PaneEmptyState,
  PaneEmptyStateButton,
  PaneEmptyStateText,
} from "../components/PaneEmptyState";
import { PaneContent, PaneHeader } from "../components/SidebarPane";
import { ExportItems } from "./ExportItems";
import { useExportsFolders } from "./useExportFolders";

export function ExportsPane() {
  const navigate = useNavigateWithSlug();
  const { isOpen, toggle } = useReflexAccordionItemContext();
  const mixpanel = useMixpanel();

  const { data, loading } = useListSyncsQuery();
  const exportsFolders = useExportsFolders(data?.syncs);

  const handleAddExport = () => {
    navigate({
      to: "/reverse-etl/new",
      search: {
        redirect: window.location.pathname,
      },
    });
    mixpanel.track("Add Export Clicked", {
      source: "sidebar",
    });
  };

  return (
    <>
      <PaneHeader
        open={isOpen}
        toggle={toggle}
        actions={
          <>
            <Tooltip content="Create new export">
              <PaneActionButton
                onClick={() => handleAddExport()}
                icon={<PlusIcon />}
              />
            </Tooltip>
          </>
        }
      >
        Reverse ETL
      </PaneHeader>
      <PaneContent>
        {loading ? (
          <div className="flex items-center justify-center px-4 py-2">
            <LoadingSpinner />
          </div>
        ) : exportsFolders.length === 0 ? (
          <PaneEmptyState>
            <PaneEmptyStateText>
              Export your models to your favorite SaaS tool
            </PaneEmptyStateText>
            <PaneEmptyStateButton
              icon={<ReverseEtlIcon />}
              onClick={() => handleAddExport()}
            >
              <span className="truncate">Create a new export</span>
            </PaneEmptyStateButton>
          </PaneEmptyState>
        ) : (
          <ExportItems items={exportsFolders} />
        )}
      </PaneContent>
    </>
  );
}
