import classNames from "@/helpers/classNames";
import { range } from "lodash";
import { useMemo } from "react";

import Select from "../../Select";
import { useSyncScheduleContext } from "../useSyncSchedule";

type TimeSelectorOption = {
  label: string;
  value: number;
};

function generateOptions(maxValue: number) {
  return range(0, maxValue + 1).map((val) => ({
    label: String(val).padStart(2, "0"),
    value: val,
  }));
}

export function TimeInput() {
  const { mode, timeOffset, onChangeTimeOffset, showTimeOffsetInput } =
    useSyncScheduleContext();

  const maxHour = timeOffset.maxHour;
  const maxMinute = timeOffset.maxMinute;

  const hourOptions = useMemo(() => {
    if (!maxHour) return null;
    return generateOptions(maxHour);
  }, [maxHour]);

  const minuteOptions = useMemo(() => {
    if (!maxMinute) return null;
    return generateOptions(maxMinute);
  }, [maxMinute]);

  const currentHourOption = hourOptions?.find(
    (option) => option.value === timeOffset.hour,
  );
  const currentMinuteOption = minuteOptions?.find(
    (option) => option.value === timeOffset.minute,
  );

  if (!hourOptions && !minuteOptions) return null;
  return (
    <div
      className={classNames(
        "flex items-center space-x-2 text-gray-900 dark:text-white",
        (!showTimeOffsetInput || mode === "custom") && "hidden",
      )}
    >
      {hourOptions ? (
        <Select
          className={"w-16"}
          disableCheckmark
          value={currentHourOption}
          isClearable={false}
          options={hourOptions}
          onChange={(option: TimeSelectorOption) => {
            onChangeTimeOffset({
              hour: option.value,
              minute: currentMinuteOption?.value ?? 0,
            });
          }}
        />
      ) : (
        <div className="rounded-sm border border-gray-300 bg-gray-100 px-3 py-2 dark:border-gray-700 dark:bg-gray-800">
          HH
        </div>
      )}
      <span>:</span>
      {minuteOptions ? (
        <Select
          className={"w-16"}
          value={currentMinuteOption}
          isClearable={false}
          options={minuteOptions}
          onChange={(option: TimeSelectorOption) => {
            onChangeTimeOffset({
              hour: currentHourOption?.value ?? 0,
              minute: option.value,
            });
          }}
          disableCheckmark
        />
      ) : (
        <div className="rounded-sm border border-gray-300 bg-gray-100 px-3 py-2 dark:border-gray-700 dark:bg-gray-800">
          MM
        </div>
      )}
    </div>
  );
}
