import { useEltSyncQuery } from "@/apollo/types";
import { IntegrationLogo } from "@/integrations";

export function EltSyncBreadcrumb(props: { syncId: string }) {
  const { syncId } = props;
  const { data, loading } = useEltSyncQuery({
    variables: { eltSyncId: syncId },
  });

  if (loading) return null;
  if (data == null) return <span>Unknown sync</span>;

  const { sourceIntegrationId, destinationSchemaName } = data.eltSync;
  return (
    <div className="flex items-center gap-2">
      <IntegrationLogo id={sourceIntegrationId} className="h-4 w-4" />
      <span>{destinationSchemaName}</span>
    </div>
  );
}
