import { useMatch } from "@tanstack/react-location";
import {
  FindAvailableOperationsQuery,
  FindOneSyncDocument,
  FindOneSyncQuery,
  ListSyncsDocument,
  OrchestrationSchedulerType,
  useFindOneSyncQuery,
  useUpdateSyncMutation,
} from "@/apollo/types";
import { ActionButton } from "@/components/elements/Button";
import DataBox from "@/components/elements/DataBox";
import Select from "@/components/elements/Select";
import TabTaskLayout from "@/components/layouts/TabTaskLayout";
import ConnectionSettings from "@/components/modules/ConnectionSettings";
import { SchedulerSelecter } from "@/components/modules/SchedulerSelecter";
import { TaskAction } from "@/components/modules/TaskActionBar";
import useConnectionSettings from "@/hooks/useConnectionSettings";
import useLoadingManager from "@/hooks/useLoadingManager";
import { isEqual, sortBy } from "lodash";
import { useToast } from "@/providers/ToastProvider";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { LocationGenerics } from "routes";

import { AdvancedSyncScheduler } from "@/components/modules/WeldSyncScheduler";
import { useOrchestrationFeatureGuard } from "@/features/feature-guards";
import useDestinationProperties from "./hooks/useDestinationProperties";
import useDestinations from "./hooks/useDestinations";
import useGenerateOperationInputArray from "./hooks/useGenerateOperationInputArray";
import useObjectTypes from "./hooks/useObjectTypes";
import useOperations from "./hooks/useOperations";
import useSyncValidated from "./hooks/useSyncValidated";
import MappingStep from "./modules/MappingStep";
import SyncButton from "./modules/SyncButton";
import { useSyncContext } from "./modules/SyncContext";
import SyncStateProvider from "./modules/SyncStateProvider";
import { SyncStateType, initSyncState } from "./modules/syncReducer";

export default function EditReverseEltSyncPage() {
  const tabs = useMemo(
    () => [
      { name: "Overview", href: `..`, id: "overview" },
      {
        name: "Configuration",
        href: `.`,
        id: "configuration",
      },
    ],
    [],
  );

  return (
    <TabTaskLayout tabs={tabs}>
      <SyncStateProvider>
        <EditSync />
      </SyncStateProvider>
    </TabTaskLayout>
  );
}

function EditSync() {
  const {
    params: { syncId },
  } = useMatch<LocationGenerics>();

  const [state, dispatch] = useSyncContext();

  const { isEnabled: isOrchestrationEnabled } = useOrchestrationFeatureGuard();

  useEffect(() => {
    if (
      isOrchestrationEnabled === false &&
      state.schedulerType === OrchestrationSchedulerType.Global
    ) {
      dispatch({
        type: "update_scheduler_type",
        payload: {
          schedulerType: OrchestrationSchedulerType.Local,
        },
      });
    }
  }, [isOrchestrationEnabled, state.schedulerType, dispatch]);

  const initialSyncRef = useRef<SyncStateType>();

  const { loading: loadingSync } = useFindOneSyncQuery({
    variables: { syncId },
    onCompleted(data) {
      const initialSyncState = initSyncState(data);
      initialSyncRef.current = initialSyncState;
      dispatch({
        type: "initialize",
        payload: initialSyncState,
      });
    },
    onError(error) {
      toast("Something went wrong", error.message, "error");
    },
  });

  const hasChanged = useMemo(
    () =>
      initialSyncRef.current &&
      Object.keys(initialSyncRef.current).some((key) => {
        if (key === "active") return false;
        if (key === "mapping") {
          return !isEqual(
            sortBy(state.mapping, ["uuid"]),
            sortBy(initialSyncRef.current?.mapping, ["uuid"]),
          );
        }
        return !isEqual(
          state[key as keyof SyncStateType],
          initialSyncRef.current?.[key as keyof SyncStateType],
        );
      }),
    [state],
  );

  const { destinationsLoading } = useDestinations();
  const { objectTypesLoading } = useObjectTypes();

  const { findAllAvailableOperations, operationsLoading, currentOperation } =
    useOperations();

  const { destinationProperties } = useDestinationProperties();

  const [updateSync, { loading: updating }] = useUpdateSyncMutation({
    onError(error) {
      toast(`Sync not updated`, error.message, "error");
    },
    onCompleted() {
      toast(`Sync updated`, `The sync was succesfully updated`, "success");
    },
    refetchQueries: [
      {
        query: ListSyncsDocument,
      },
      {
        query: FindOneSyncDocument,
        variables: { syncId },
      },
    ],
  });

  const toast = useToast();

  const generateOperationInputArray = useGenerateOperationInputArray();

  const onChangeOperation = useCallback(
    (item: FindAvailableOperationsQuery["findAllAvailableOperations"][0]) =>
      dispatch({
        type: "change_operation",
        payload: item ? { id: item.id, mode: item.operationMode } : null,
      }),
    [dispatch],
  );

  const connectionSettings = useConnectionSettings(
    state.destinationId,
    "Activate",
  );

  const generateConfig = useCallback(() => {
    let config: FindOneSyncQuery["findOneSync"]["config"] = {};
    if (connectionSettings.required) {
      config.destinationSettings = state.destinationSettings;
    }
    return JSON.stringify(config);
  }, [connectionSettings.required, state.destinationSettings]);

  const handleSubmit = useCallback(async () => {
    const missingMappings = destinationProperties
      .filter((dp) => dp.isRequired)
      .filter(
        (dp) =>
          !state.mapping.some(
            (mapping) => mapping.destinationPropertyId === dp.propertyId,
          ),
      );
    if (missingMappings.length > 0) {
      const missingString = missingMappings
        .map((dp) => `"${dp.propertyName}"`)
        .join(", ");
      toast(
        "Missing mappings",
        `Missing destination mappings for: ${missingString}`,
        "error",
      );
      return;
    }

    updateSync({
      variables: {
        syncId: syncId,
        operations: generateOperationInputArray(),
        syncInterval: state.scheduleKey,
        config: generateConfig(),
        schedulerType: state.schedulerType,
        orchestrationWorkflowId: state.orchestrationWorkflowId,
      },
    });
  }, [
    destinationProperties,
    updateSync,
    syncId,
    generateOperationInputArray,
    state.scheduleKey,
    state.mapping,
    generateConfig,
    state.schedulerType,
    state.orchestrationWorkflowId,
    toast,
  ]);

  const loading = useMemo(
    () => destinationsLoading || loadingSync || objectTypesLoading,
    [destinationsLoading, loadingSync, objectTypesLoading],
  );

  const { syncValidated, handleChangeConnectionSettingsValid } =
    useSyncValidated();

  return useLoadingManager(
    {
      loading,
      message: "Loading sync configuration",
    },
    () => (
      <div className="relative">
        <div className="space-y-8">
          <ConnectionSettings
            onChange={(payload) =>
              dispatch({ type: "change_destination_settings", payload })
            }
            connectionSettings={connectionSettings}
            onChangeValid={handleChangeConnectionSettingsValid}
            initialValues={state.destinationSettings}
          />

          {state.objectTypeName && (
            <>
              <DataBox
                header="Sync operation"
                subheader="How do you want us to sync your data?"
              >
                <Select
                  placeholder="Select your sync operation"
                  isLoading={operationsLoading}
                  value={currentOperation}
                  options={findAllAvailableOperations}
                  labelKey="operationMode"
                  onChange={onChangeOperation}
                  autoSelectOnSingleOption
                />
              </DataBox>
              <DataBox
                header="Schedule"
                subheader="How often do you want your sync to run?"
              >
                <div className="space-y-4">
                  {isOrchestrationEnabled && (
                    <SchedulerSelecter
                      configuration={{
                        allowNewWorkflow: false,
                        allowNoSchedule: false,
                      }}
                      orchestrationScheduler={state.schedulerType}
                      orchestrationWorkflowId={state.orchestrationWorkflowId}
                      onUpdate={(config) => {
                        if (!config.orchestrationScheduler) return;
                        dispatch({
                          type: "update_scheduler_type",
                          payload: {
                            schedulerType: config.orchestrationScheduler,
                            orchestrationWorkflowId:
                              config.orchestrationWorkflowId,
                          },
                        });
                      }}
                      hideLabel
                    />
                  )}
                  {state.schedulerType === OrchestrationSchedulerType.Local && (
                    <AdvancedSyncScheduler
                      cron={state.scheduleKey}
                      onChange={(cron) => {
                        dispatch({
                          type: "change_schedule",
                          payload: cron,
                        });
                      }}
                    />
                  )}
                </div>
              </DataBox>
            </>
          )}
          {state.operation &&
          (state.scheduleKey || state.orchestrationWorkflowId) &&
          state.objectTypeName ? (
            <MappingStep />
          ) : (
            <div className="flex items-center justify-center rounded border border-gray-300 bg-gray-200 py-16 text-center text-gray-400 dark:border-gray-600 dark:bg-gray-800">
              Fill out setting above and more options will appear.
            </div>
          )}
          <TaskAction>
            {state.active ? (
              <SyncButton onChange={() => dispatch({ type: "stop_sync" })} />
            ) : (
              <ActionButton
                onClick={handleSubmit}
                disabled={!hasChanged || !syncValidated || updating}
                loadingText="Updating sync"
                isLoading={updating}
              >
                Update sync
              </ActionButton>
            )}
          </TaskAction>
        </div>
      </div>
    ),
  );
}
