import { ListSyncsQuery } from "@/apollo/types";
import {
  CollapsibleAlertTable,
  useReverseEltSyncNotifications,
} from "@/features/notifications";

export const RevEltSyncsNotifications = (props: {
  syncs: ListSyncsQuery["syncs"];
}) => {
  const { notifications } = useReverseEltSyncNotifications(props.syncs);
  if (notifications.count === 0) {
    return null;
  }
  return (
    <CollapsibleAlertTable
      notifications={notifications}
      triggerText={`${
        notifications.count === 1
          ? `${notifications.count} error`
          : `${notifications.count} errors`
      } affecting your syncs`}
    />
  );
};
