import classNames from "@/helpers/classNames";
import { forwardRef } from "react";

export type ButtonProps = {
  isFocused?: boolean;
  isDisabled?: boolean;
} & React.ComponentPropsWithoutRef<"button">;

const InputContainerButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { className, isDisabled, isFocused, ...restProps } = props;

    return (
      <button
        tabIndex={-1}
        ref={ref}
        type="button"
        className={classNames(
          "group relative flex h-12 w-full cursor-default items-center rounded-sm border border-gray-300 text-left transition-all duration-300 focus:outline-none dark:border-gray-700 dark:bg-gray-800",
          isFocused && "border-blue-500 ring-2 ring-blue-600",
          isDisabled
            ? "bg-gray-200 bg-opacity-70"
            : "bg-white hover:border-gray-400",
          className,
        )}
        {...restProps}
      >
        {props.children}
      </button>
    );
  },
);

export default InputContainerButton;
