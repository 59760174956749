import { MaterializationType, useListModelsQuery } from "@/apollo/types";
import useChainedObject from "@/hooks/useChainedObject";
import { useModelFolders } from "@/pages/ModelTool/hooks/useModelFolder";
import { useCurrentAccount } from "@/providers/account";
import { useMemo } from "react";

export type ModelOption = {
  id: string;
  name: string;
  type: MaterializationType;
};

export default function useModelOptions(currentModelId?: string) {
  const account = useCurrentAccount();
  const { modelFolders } = useModelFolders();

  const { loading: modelsLoading, data: { models = [] } = {} } =
    useListModelsQuery({
      fetchPolicy: "network-only",
      skip: !account.dataWarehouseConnectionId,
    });

  const publishedModelsOptions = useMemo(() => {
    return models
      .filter((q) => !!q.publishedQuery)
      .map((model) => {
        const parentModelName = model.folder?.parentId
          ? modelFolders.find((f) => f.id === model.folder?.parentId)?.name
          : "";
        const folderName = parentModelName
          ? `${parentModelName}.${model.folder?.name}`
          : model.folder?.name;
        const displayName = `${folderName}.${model.name}`;
        return {
          id: model.id,
          label: displayName,
          type: model.materializationType,
          model: model,
        };
      });
  }, [models, modelFolders]);

  const { currentObject } = useChainedObject(
    publishedModelsOptions,
    "id",
    currentModelId ?? "",
  );

  return {
    publishedModelsOptions,
    modelsLoading,
    currentQuery: currentObject,
  };
}
