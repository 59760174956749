import {
  SlideOver,
  SlideOverCloseButton,
} from "@/components/elements/SlideOver";
import { GoogleSheetsReverseEltSidebar } from "./integrations/googleSheets/GoogleSheetsReverseEltSidebar";
import { useSetupRevELTSimple } from "./useSetupRevELTSimple";

type Props = {};

const SimplifiedRevELTSidebar = (props: Props) => {
  const { close, isOpen, integrationId } = useSetupRevELTSimple();

  const renderContent = () => {
    switch (integrationId) {
      case "google-sheets":
        return <GoogleSheetsReverseEltSidebar />;
      default:
        return null;
    }
  };

  return (
    <SlideOver show={isOpen} onClose={close} bgOverlay>
      <SlideOverCloseButton />
      {renderContent()}
    </SlideOver>
  );
};

export default SimplifiedRevELTSidebar;
