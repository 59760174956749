import { ComponentProps, deriveClassed } from "@tw-classed/react";
import cn from "@/helpers/classNames";

import { classed } from "../../classed.config";

const ListRoot = classed.ul("w-full overflow-auto p-2");

const ListItemBase = classed.li(
  "flex grow min-w-0 justify-start items-center px-4 py-2 relative group",
);

const ListItemButtonBase = classed.button(
  "flex grow min-w-0 justify-start items-center px-4 py-2 relative group",
);

const ListItemSecondaryActionContainer = classed.div(
  "absolute right-3 top-1/2 -translate-y-1/2",
  {
    variants: {
      showOnHover: {
        true: "opacity-0 group-hover:opacity-100 group-focus:opacity-100 group-focus-within:opacity-100",
      },
    },
    defaultVariants: {
      showOnHover: true,
    },
  },
);

const ListItem = deriveClassed<
  typeof ListItemBase,
  ComponentProps<typeof ListItemBase> & {
    secondaryAction?: React.ReactNode;
    showSecondaryActionOnHover?: boolean;
  }
>(({ secondaryAction, showSecondaryActionOnHover, ...props }, ref) => {
  return (
    <ListItemBase
      ref={ref}
      {...props}
      className={cn(props.className, {
        "pr-10": secondaryAction != null,
      })}
    >
      {props.children}
      {secondaryAction != null && (
        <ListItemSecondaryActionContainer
          showOnHover={showSecondaryActionOnHover}
        >
          {secondaryAction}
        </ListItemSecondaryActionContainer>
      )}
    </ListItemBase>
  );
});

export const ListItemButton = deriveClassed<
  typeof ListItemButtonBase,
  ComponentProps<typeof ListItemButtonBase> & {
    secondaryAction?: React.ReactNode;
  }
>(({ secondaryAction, ...props }, ref) => {
  const hasSecondaryAction = secondaryAction != null;
  return (
    <ListItemButtonBase
      ref={ref}
      type="button"
      {...({
        as: hasSecondaryAction ? "div" : "button",
        role: hasSecondaryAction ? "button" : undefined,
      } as ComponentProps<typeof ListItemButtonBase>)}
      {...props}
      className={cn(
        "transition-all focus-within:bg-black/5 hover:bg-black/5 active:bg-black/10 dark:focus-within:bg-white/5 dark:hover:bg-white/5 dark:active:bg-white/10",
        props.className,
        {
          "pr-10": hasSecondaryAction,
        },
      )}
    >
      {props.children}
      {hasSecondaryAction && (
        <ListItemSecondaryActionContainer>
          {secondaryAction}
        </ListItemSecondaryActionContainer>
      )}
    </ListItemButtonBase>
  );
});

const ListItemHeading = classed.h3("font-medium");
const ListItemText = classed.p("");
const ListItemIcon = classed.div("shrink-0 min-w-[1.5rem]");

const ListNamespace = Object.assign(ListRoot, {
  Item: ListItem,
  ItemButton: ListItemButton,
  ItemIcon: ListItemIcon,
  Heading: ListItemHeading,
  Text: ListItemText,
});

export { ListNamespace as List };
