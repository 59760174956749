import React from "react";

type WeldBadgeIconProps = React.ComponentProps<"svg"> & {
  isChecked?: boolean;
};

export const WeldBadgeIcon = ({ isChecked, ...props }: WeldBadgeIconProps) => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.5 1.73205C29.3564 0.660254 31.6436 0.660254 33.5 1.73205L53.9138 13.5179C55.7702 14.5897 56.9138 16.5705 56.9138 18.7141V42.2859C56.9138 44.4295 55.7702 46.4103 53.9138 47.4821L33.5 59.2679C31.6436 60.3397 29.3564 60.3397 27.5 59.2679L7.08623 47.4821C5.22982 46.4103 4.08623 44.4295 4.08623 42.2859V18.7141C4.08623 16.5705 5.22982 14.5897 7.08623 13.5179L27.5 1.73205Z"
        fill="currentColor"
      />
      <path
        d="M29.8858 5.34825C30.3499 5.0803 30.9217 5.0803 31.3858 5.34825L51.9256 17.2069C52.3897 17.4748 52.6756 17.97 52.6756 18.5059V42.2232C52.6756 42.7591 52.3897 43.2543 51.9256 43.5222L31.3858 55.3809C30.9217 55.6488 30.3499 55.6488 29.8858 55.3809L9.34604 43.5222C8.88194 43.2543 8.59604 42.7591 8.59604 42.2232V18.5059C8.59604 17.97 8.88194 17.4748 9.34604 17.2069L29.8858 5.34825Z"
        stroke="white"
      />
      {isChecked && (
        <path
          d="M23.4092 32.437L28.317 36.5273L37 25"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};
