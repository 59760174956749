import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";

import { useInterval } from "@/hooks/useInterval";

import { Resolution } from "./types";

function roundUpToNearestMultiple(num: number, multiple: number) {
  if (multiple === 0) {
    return num; // Check for zero to avoid division by zero
  }
  return Math.ceil(num / multiple) * multiple;
}

export function useTimelineWindow(resolution: Resolution) {
  const refreshInterval = useMemo(() => {
    switch (resolution) {
      case "hour":
        return 10 * 1000; // 10 seconds
      case "day":
        return 2 * 60 * 1000; // 2 minutes
      case "week":
        return 60 * 60 * 1000; // 1 hour
    }
  }, [resolution]);

  const generateWindow = useCallback((resolution: Resolution) => {
    const currentDate = dayjs();
    switch (resolution) {
      case "hour": {
        const startDate = dayjs(currentDate).subtract(1, "hour");
        const stepSize = 10 * 60 * 1000; // 10 minutes
        const stepAnchor = new Date(
          roundUpToNearestMultiple(startDate.valueOf(), stepSize),
        );
        const end = currentDate.add(stepSize, "milliseconds").toDate();
        const size = end.getTime() - startDate.valueOf();
        return {
          startDate: startDate.toDate(),
          endDate: end,
          size,
          stepSize,
          stepAnchor,
          domain: [startDate.valueOf(), end.getTime()] as [number, number],
          currentDate,
        };
      }
      case "day": {
        const startDate = dayjs(currentDate).subtract(1, "day");
        const startDateHour = startDate.hour();
        const nextEvenHour =
          startDateHour % 2 === 0 ? startDateHour + 2 : startDateHour + 1;
        const stepAnchor = startDate
          .add(nextEvenHour - startDateHour, "hours")
          .startOf("hour");

        const end = currentDate.add(1, "hours").toDate();
        const size = end.getTime() - startDate.valueOf();
        return {
          startDate: startDate.toDate(),
          endDate: end,
          size,
          stepSize: 2 * 60 * 60 * 1000, // 2 hours
          stepAnchor: stepAnchor.toDate(),
          domain: [startDate.valueOf(), end.getTime()] as [number, number],
          currentDate,
        };
      }
      case "week": {
        const startDate = dayjs(currentDate).subtract(7, "days");
        const stepAnchor = startDate.add(1, "day").startOf("day");
        const end = currentDate.add(4, "hours").toDate();
        const size = end.getTime() - startDate.valueOf();
        return {
          startDate: startDate.toDate(),
          endDate: end,
          size,
          stepSize: 24 * 60 * 60 * 1000, // 24 hours
          stepAnchor: stepAnchor.toDate(),
          domain: [startDate.valueOf(), end.getTime()] as [number, number],
          currentDate,
        };
      }
    }
  }, []);

  const [window, setWindow] = useState(() => generateWindow(resolution));

  useInterval(() => {
    const window = generateWindow(resolution);
    setWindow(window);
  }, refreshInterval);

  return {
    window,
    recalcWindow: useCallback(
      (resolution: Resolution) => {
        const window = generateWindow(resolution);
        setWindow(window);
        return window;
      },
      [generateWindow],
    ),
  };
}
