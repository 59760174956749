import {
  FindQueryHistoryDocument,
  QueryModelDocument,
  useUpdateUnpublishedModelQueryMutation,
} from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import { useToast } from "@/providers/ToastProvider";
import { useState } from "react";

import {
  useGetEditorTextState,
  useModelEditorDirtyState,
  useModelEditorState,
} from "../ModelEditorStore";
import { useGetQueryDependencies } from "../useQueryDependencies";

const SaveDashboardModel = () => {
  const state = useModelEditorState();
  const getTextState = useGetEditorTextState();
  const getQueryDependencies = useGetQueryDependencies();
  const isModelDirty = useModelEditorDirtyState();

  const [loading, setLoading] = useState(false);

  const [updateModel] = useUpdateUnpublishedModelQueryMutation({
    refetchQueries: [
      {
        query: QueryModelDocument,
        variables: { modelId: state.currentModelId },
      },
      {
        query: FindQueryHistoryDocument,
        variables: { modelId: state.currentModelId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const toast = useToast();

  const handleSaveModel = async () => {
    const modelId = state.currentModelId;
    if (!modelId) return;

    setLoading(true);
    try {
      if (isModelDirty) {
        await updateModel({
          variables: {
            id: modelId,
            query: {
              weldSql: getTextState().weldSql,
              dependencies: getQueryDependencies(
                getTextState().dependencyReferences,
              ),
            },
          },
        });
      }
      toast(
        "Updated SQL model",
        "Updated SQL model for visualization.",
        "success",
      );
      setLoading(false);
    } catch (error) {
      toast(
        "Error saving SQL for tile",
        "Error saving tile to dashboard.",
        "error",
      );
      setLoading(false);
    }
  };

  return (
    <Button
      variant="outline"
      colorScheme="primary"
      isLoading={loading}
      disabled={!isModelDirty}
      onClick={() => {
        handleSaveModel();
      }}
    >
      Save to dashboard
    </Button>
  );
};

export default SaveDashboardModel;
