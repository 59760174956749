import { useCallback, useState } from "react";

export interface UseDisclosureProps<T> {
  defaultState?: {
    isOpen: boolean;
    data?: T;
  };
}

/**
 * `useDisclosure` is a custom hook used to help handle common open, close, or toggle scenarios.
 */
export function useDisclosure<T>(props: UseDisclosureProps<T> = {}) {
  const [{ isOpen, data }, setIsOpen] = useState<{
    isOpen: boolean;
    data: T | undefined;
  }>({
    isOpen: props.defaultState?.isOpen || false,
    data: undefined,
  });

  const onClose = useCallback(() => {
    setIsOpen({ isOpen: false, data: undefined });
  }, []);

  const onOpen = useCallback((data?: T) => {
    setIsOpen({ isOpen: true, data });
  }, []);

  const onToggle = useCallback(() => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  }, [isOpen, onOpen, onClose]);

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
    data,
  };
}

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>;
