import { baseThemeDark, baseThemeLight, mergeThemes } from "./baseTheme";

const themeLight = mergeThemes(baseThemeLight, {
  BarChart: {
    barSize: 22,
  },
  Bar: {
    fill: "rgba(212 214 218 / 0.8)", // gray-300
  },
});

const themeDark = mergeThemes(baseThemeDark, {
  BarChart: {
    barSize: 22,
  },
  Bar: {
    fill: "rgba(81 88 98 / 0.8)", // gray-600
  },
  text: "white",
});

const theme = {
  light: themeLight,
  dark: themeDark,
};

export default theme;
