import React from "react";

export const BlueOneEye2 = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 100 100"
    >
      <ellipse cx="50.5" cy="50" fill="#99C5FA" rx="50.5" ry="50"></ellipse>
      <mask
        id="mask0_1928_10519"
        style={{ maskType: "alpha" }}
        width="100"
        height="100"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect width="100" height="100" fill="#C4C4C4" rx="2"></rect>
      </mask>
      <g mask="url(#mask0_1928_10519)">
        <ellipse
          cx="55.724"
          cy="55.171"
          fill="#0070F4"
          rx="104.483"
          ry="82.759"
        ></ellipse>
        <ellipse
          cx="48.349"
          cy="106.694"
          fill="#0059C3"
          rx="66.172"
          ry="65.517"
        ></ellipse>
        <path
          fill="#0070F4"
          d="M51.225 87.429a1.396 1.396 0 01-1.841-.002c-54.527-47.907-54.527-132.83 0-180.736a1.396 1.396 0 011.841-.002c54.664 47.847 54.664 132.893 0 180.74z"
        ></path>
        <ellipse
          fill="#fff"
          rx="21.738"
          ry="21.523"
          transform="rotate(.389 -4883.604 7272.416) skewX(.051)"
        ></ellipse>
        <ellipse
          fill="#000"
          rx="11.244"
          ry="11.133"
          transform="rotate(.389 -4883.766 7272.27) skewX(.051)"
        ></ellipse>
      </g>
    </svg>
  );
};
