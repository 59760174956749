import {
  useConnectionQuery,
  useEltConnectionSettingsQuery,
} from "@/apollo/types";
import { useIntegrationsMap } from "@/integrations";

export type SlideOverInputOptions =
  | { integrationId?: string; connectionId?: never }
  | { integrationId?: string | never; connectionId?: string }
  | { integrationId?: never; connectionId?: never };

export function useValidInputProps(props: SlideOverInputOptions): {
  validInputProps: SlideOverInputOptions & {
    connectionHasSettings?: boolean;
  };
  isLoading: boolean;
  isValidConnectionId?: boolean | undefined;
  isValidIntegrationId?: boolean | undefined;
} {
  const integrationsRegistry = useIntegrationsMap("EltSourceConnection");

  const { data: connectionData, loading: isLoadingConnection } =
    useConnectionQuery({
      fetchPolicy: "network-only",
      variables: {
        id: props.connectionId || "",
      },
      skip: !props.connectionId,
    });

  const { data: connectionSettingsData, loading: isLoadingConnectionSettings } =
    useEltConnectionSettingsQuery({
      variables: {
        input: {
          id: props.connectionId || "",
        },
      },
      skip: !props.connectionId,
    });

  const isLoading = isLoadingConnection || isLoadingConnectionSettings;
  if (isLoading) {
    return {
      validInputProps: {},
      isLoading,
    };
  }

  if (props.connectionId) {
    const isValidConnectionId =
      !isLoadingConnection && connectionData !== undefined;
    if (connectionData && connectionSettingsData) {
      const integration = integrationsRegistry.get(
        connectionData.connection.integrationId,
      );
      const connectionHasSettings =
        !!Object.keys(
          connectionSettingsData.getEltSettings.connectionSettings ?? {},
        ).length || integration?.supportsStartDate;
      return {
        validInputProps: {
          connectionId: props.connectionId,
          connectionHasSettings,
        },
        isLoading,
        isValidConnectionId,
      };
    } else {
      return {
        validInputProps: {},
        isLoading,
        isValidConnectionId,
      };
    }
  }
  if (props.integrationId) {
    const integration = integrationsRegistry.get(props.integrationId || "");
    const isValidIntegrationId = integration !== undefined;
    if (isValidIntegrationId) {
      return {
        validInputProps: { integrationId: props.integrationId },
        isLoading,
        isValidIntegrationId,
      };
    } else {
      return {
        validInputProps: {},
        isLoading,
        isValidIntegrationId,
      };
    }
  }
  return {
    validInputProps: {},
    isLoading: false,
  };
}
