import { ArrowUpIcon } from "@heroicons/react/24/solid";
import classNames from "@/helpers/classNames";
import React, { useMemo } from "react";
import { MetricVisualization } from "../VisualizationType";

type Props = { chart: MetricVisualization; data: any[] };

const MetricComponent = (props: Props) => {
  const currentValue = useMemo(() => {
    const value = props.data[0]?.[props.chart.metricColumn] as
      | string
      | number
      | undefined;
    if (value === undefined) return undefined;

    const asNumber = typeof value === "number" ? value : parseFloat(value);

    //check if nan
    if (isNaN(asNumber)) return value;

    return asNumber;
  }, [props.chart.metricColumn, props.data]);

  const formatedCurentValue = useMemo(() => {
    if (!currentValue) return null;
    if (typeof currentValue === "number") {
      return formatNumber(currentValue);
    }
    return currentValue;
  }, [currentValue]);

  const prevValue = useMemo(() => {
    const val = props.data[1]?.[props.chart.metricColumn] as
      | string
      | number
      | undefined;

    if (!val) return undefined;

    const asNumber = typeof val === "number" ? val : parseFloat(val);

    if (isNaN(asNumber)) return undefined;

    return asNumber;
  }, [props.chart.metricColumn, props.data]);

  const change = useMemo(() => {
    if (!currentValue || typeof currentValue !== "number" || !prevValue)
      return undefined;
    //get the change in percentage
    return ((currentValue - prevValue) / prevValue) * 100;
  }, [currentValue, prevValue]);

  return (
    <div className="flex h-full w-full items-center px-8 pb-4">
      <div className="flex w-full flex-wrap items-center">
        {!currentValue && <span>No data available</span>}
        {currentValue && (
          <div className="mb-2 flex-grow space-x-1 pr-4">
            {props.chart.unit && props.chart.unitPostition === "prefix" && (
              <span className="text-xl">{props.chart.unit}</span>
            )}
            <span className="truncate text-xl">{formatedCurentValue}</span>
            {props.chart.unit && props.chart.unitPostition === "suffix" && (
              <span className="text-xl">{props.chart.unit}</span>
            )}
          </div>
        )}
        {typeof change === "number" && typeof prevValue === "number" && (
          <div className="mb-2 flex items-center space-x-1.5 rounded border p-1.5 dark:border-gray-600">
            <ArrowUpIcon
              className={classNames(
                "h-4 w-4",
                change > 0 ? "text-green-500" : "text-red-500",
              )}
              style={{
                transform: change < 0 ? "rotate(180deg)" : "",
              }}
            />
            <span className="text-xs dark:text-gray-100">
              {formatNumber(change, change < 1 ? 2 : 1)}%
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const formatNumber = (value: number, fractionDigits: number = 2) => {
  const fixedValue = value.toFixed(fractionDigits);

  //if value does not have more decimals than requested fractions, return as is
  if (value.toString().length <= fixedValue.length) return value.toString();
  else return fixedValue;
};

export default MetricComponent;
