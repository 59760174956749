import dayjs, { Dayjs } from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { dateTimeFormat } from "@/helpers/dateFormat";

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);

type DateInput = string | number | Date | Dayjs;

type FormatTimeProps = {
  date: DateInput;
  format?: string;
  utc?: boolean;
} & React.ComponentProps<"span">;

export function FormatTime({
  date,
  format = dateTimeFormat,
  utc = false,
  ...props
}: FormatTimeProps) {
  const d = utc ? dayjs.utc(date) : dayjs(date);
  return <span {...props}>{d.format(format)}</span>;
}
