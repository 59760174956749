import { useMonaco } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import { useCallback, useEffect } from "react";

import {
  getWeldTagRange,
  getWordRange,
  useGetModelSuggestionItems,
  useGetRawViewSuggestionItems,
} from "@/pages/ModelTool/QueryEditor/completion-suggestions/useCompletionItems";

const openingBracketsRegex = /{{[\w._-]*$/;
const closingBracketsRegex = /^.*}}/;

type WeldTagConfig = {
  hasOpeningBrackets: boolean;
  hasClosingBrackets: boolean;
};

export default function useAssitantCompletionItems() {
  const monaco = useMonaco();

  const getRawViewSuggestionItems = useGetRawViewSuggestionItems();

  const { getModelSuggestionViews, getModelSuggestionTables } =
    useGetModelSuggestionItems();

  const provideCompletionItems: monaco.languages.CompletionItemProvider["provideCompletionItems"] =
    useCallback(
      (model: monaco.editor.ITextModel, position: monaco.Position) => {
        const textUntilPosition = model.getValueInRange({
          startLineNumber: 1,
          startColumn: 1,
          endLineNumber: position.lineNumber,
          endColumn: position.column,
        });
        const restOfLineText = model.getValueInRange({
          startLineNumber: position.lineNumber,
          startColumn: position.column,
          endLineNumber: position.lineNumber,
          endColumn: model.getLineMaxColumn(position.lineNumber),
        });

        const hasOpeningBrackets = openingBracketsRegex.test(textUntilPosition);
        const hasClosingBrackets = closingBracketsRegex.test(restOfLineText);

        const weldTagConfig: WeldTagConfig = {
          hasOpeningBrackets,
          hasClosingBrackets,
        };

        const suggestionRange = hasOpeningBrackets
          ? getWeldTagRange(model, position, weldTagConfig)
          : getWordRange(model, position);

        const suggestions = [
          ...getRawViewSuggestionItems(suggestionRange, weldTagConfig),
          ...getModelSuggestionViews(suggestionRange, weldTagConfig),
          ...getModelSuggestionTables(suggestionRange, weldTagConfig),
        ];
        return {
          suggestions,
        };
      },
      [
        getRawViewSuggestionItems,
        getModelSuggestionViews,
        getModelSuggestionTables,
      ],
    );

  useEffect(() => {
    if (!monaco) return;
    const subscription = monaco.languages.registerCompletionItemProvider(
      "plaintext",
      {
        provideCompletionItems: provideCompletionItems,
      },
    );
    return () => subscription.dispose();
  }, [monaco, provideCompletionItems]);
}
