import { classed } from "@/components/classed.config";

export const Badge = classed.span(
  "inline-flex items-center border border-gray-400 dark:border-transparent rounded-md px-2.5 py-0.5 text-xs font-medium bg-gray-200 text-gray-600",
  {
    variants: {
      variant: {
        info: "bg-blue-100 border-blue-800/30 text-blue-800 dark:bg-blue-600 dark:text-white",
        success:
          "bg-green-100 border-green-800/30 text-green-800 dark:bg-green-600 dark:text-white",
        error:
          "bg-red-100 border-red-800/30 text-red-800 dark:bg-red-500 dark:text-white",
      },
    },
  },
);
