import { useMatches } from "@tanstack/react-location";
import { useCloseRemovedTab } from "@/components/modules/ModelTabs";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { atomFamily, atomWithStorage } from "jotai/utils";
import { useCurrentAccount } from "@/providers/account";
import { useCallback, useEffect, useMemo } from "react";
import { v4 as uuid } from "uuid";

import { createModelToolStorageKey } from "../utils/createLocalStorageKey";

export type DraftModel = {
  id: string;
  name: string;
  weldSql: string;
  number: number;
  createdAt: string;
  updatedAt: string;
  hidden?: boolean;
};
const draftsAtom = atomFamily((accountId: string) =>
  atomWithStorage<DraftModel[]>(
    createModelToolStorageKey("draft_models", accountId),
    [],
  ),
);

const useDraftsAtom = () => {
  const { id } = useCurrentAccount();
  return useAtom(draftsAtom(id));
};

function isExpired(draft: DraftModel) {
  const yesterday = dayjs().subtract(1, "day");
  return dayjs(draft.updatedAt).isBefore(yesterday);
}

export function useAchivedDrafts() {
  const [drafts] = useDraftsAtom();
  return useMemo(() => {
    return drafts.filter((m) => {
      if (m.hidden) return true;
      return isExpired(m);
    });
  }, [drafts]);
}

export const useHasArchivedDrafts = () => useAchivedDrafts().length > 0;

export function useActiveDrafts() {
  const [drafts] = useDraftsAtom();
  return useMemo(() => {
    return drafts
      .filter((m) => {
        if (m.hidden) return false;
        return !isExpired(m);
      })
      .sort((a, b) => {
        return a.number - b.number;
      });
  }, [drafts]);
}

export const useDrafts = () => {
  const [, setDrafts] = useDraftsAtom();

  const drafts = useActiveDrafts();
  const archived = useAchivedDrafts();

  //auto remove expired drafts that are empty
  useEffect(() => {
    const emptyArchivedDraftIds = archived
      .filter((m) => {
        return m.weldSql.length === 0;
      })
      .map((m) => m.id);

    if (emptyArchivedDraftIds.length > 0) {
      setDrafts((p) => p.filter((m) => !emptyArchivedDraftIds.includes(m.id)));
    }
  }, [archived, setDrafts]);

  return { drafts, archived };
};

export const useCreateDraftModel = () => {
  const [draftModels, setDraftModels] = useDraftsAtom();

  //drafts that are not archived:
  const { drafts } = useDrafts();
  const lowestAvailableNr = useMemo(() => {
    //find lowest available free number for new draft
    const numbers = drafts.map((m) => m.number);
    let number = 0;
    while (numbers.includes(number)) {
      number++;
    }
    return number;
  }, [drafts]);

  return useCallback(
    (weldSql: string = "") => {
      const timeStamp = dayjs().toISOString();

      const newDraft: DraftModel = {
        id: uuid(),
        name: "Unsaved draft",
        weldSql,
        number: lowestAvailableNr,
        createdAt: timeStamp,
        updatedAt: timeStamp,
      };

      setDraftModels([...draftModels, newDraft]);
      return newDraft;
    },
    [draftModels, lowestAvailableNr, setDraftModels],
  );
};

export const useCurrentModelDraft = () => {
  const matches = useMatches();

  const [draftModels, setDraftModels] = useDraftsAtom();

  const { draftId, currentDraftModel } = useMemo(() => {
    const draftId = matches.find((m) => !!m.params.draftId)?.params.draftId;
    const currentDraftModel = draftModels.find(
      (m) => !m.hidden && m.id === draftId,
    );
    return { draftId, currentDraftModel };
  }, [draftModels, matches]);

  const updateCurrentDraftModel = useCallback(
    (weldSql: string) => {
      const newDraftModels = draftModels.map((m) =>
        m.id === draftId
          ? { ...m, weldSql, updatedAt: dayjs().toISOString() }
          : m,
      );
      setDraftModels(newDraftModels);
    },
    [draftModels, draftId, setDraftModels],
  );

  return { draftId, currentDraftModel, updateCurrentDraftModel };
};

export const useHideModelDraft = () => {
  const [, setDraftModels] = useDraftsAtom();

  const closeRemovedTab = useCloseRemovedTab();

  return useCallback(
    (draftId: string) => {
      closeRemovedTab("model", draftId);

      setDraftModels((p) =>
        p.map((m) =>
          m.id === draftId
            ? {
                ...m,
                hidden: true,
              }
            : m,
        ),
      );
    },
    [setDraftModels, closeRemovedTab],
  );
};

export const useRestoreDraft = () => {
  const [, setDraftModels] = useDraftsAtom();

  const { drafts } = useDrafts();
  const lowestAvailableNr = useMemo(() => {
    //find lowest available free number for new draft
    const numbers = drafts.map((m) => m.number);
    let number = 0;
    while (numbers.includes(number)) {
      number++;
    }
    return number;
  }, [drafts]);

  return useCallback(
    (draftId: string) => {
      setDraftModels((p) =>
        p.map((m) =>
          m.id === draftId
            ? {
                ...m,
                hidden: false,
                updatedAt: dayjs().toISOString(),
                number: lowestAvailableNr,
              }
            : m,
        ),
      );
      return { newOrder: lowestAvailableNr };
    },
    [setDraftModels, lowestAvailableNr],
  );
};

export const useDeleteModelDraft = () => {
  const [, setDraftModels] = useDraftsAtom();

  return useCallback(
    (draftId: string, hidden: boolean = true) => {
      setDraftModels((p) => p.filter((m) => m.id !== draftId));
    },
    [setDraftModels],
  );
};
