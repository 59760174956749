import { useToast } from "@/providers/ToastProvider";
import capitalize from "@/helpers/capitalize";
import { useCallback } from "react";

export default function useDeleteItem<Input>({
  variables,
  mutation: useMutation,
  refetchQueries,
  title,
}: {
  variables: Input;
  mutation: any;
  refetchQueries: any[];
  title: string;
}) {
  const toast = useToast();

  const [mutate] = useMutation({
    onError(error: { message: string }) {
      toast(`${capitalize(title)} not deleted`, error.message, "error");
    },
    onCompleted() {
      toast(
        `${capitalize(title)} deleted`,
        `Your ${title} has succesfully been deleted.`,
        "success",
      );
    },
    refetchQueries,
  });

  const onDelete = useCallback(async () => {
    await mutate({
      variables,
    });
  }, [variables, mutate]);

  return onDelete;
}
