import { useFlags } from "launchdarkly-react-client-sdk";
import { PropsWithChildren } from "react";
import { createPortal } from "react-dom";

import { useIntercom, useStartIntercomForUser } from "@/hooks/useIntercom";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";

/**
 * Wrap this component around the routes of the app where we want a intercom launcher.
 * This will make a launcher button to appear in the bottom right corner.
 */
export const IntercomLauncherWrapper = (props: PropsWithChildren) => {
  useStartIntercomForUser();
  const { intercomChat } = useFlags();

  return (
    <>
      {intercomChat && createPortal(<IntercomLaunchButton />, document.body)}
      {props.children}
    </>
  );
};

const IntercomLaunchButton = () => {
  const intercom = useIntercom();
  return (
    <div className="fixed bottom-0 right-0 mb-4 mr-4">
      <button
        className="flex h-12 w-12 items-center justify-center rounded-full bg-blue-500 text-white hover:bg-blue-400"
        onClick={() => {
          intercom.show();
        }}
      >
        {<ChatBubbleLeftIcon className="h-6 w-6" />}
      </button>
    </div>
  );
};
