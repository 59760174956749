import {
  ElementType,
  MouseEvent,
  MutableRefObject,
  PropsWithChildren,
  Ref,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
} from "react";

import mergeRefs from "@/utils/mergeRefs";
import {
  Popover as HUIPopover,
  PopoverButtonProps,
  PopoverPanelProps,
  PopoverProps,
} from "@headlessui/react";

/**
 * This is a compound component that wraps the HeadlessUI Popover component.
 * It enhances the Popover component with the ability to open the popover on hover.
 * Other than that, it is a drop-in replacement for the HeadlessUI Popover component.
 */

type PopoverTrigger = "click" | "hover";

type HoverProps = {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

type PopoverRenderPropArg = {
  open: boolean;
  close(
    focusableElement?:
      | HTMLElement
      | MutableRefObject<HTMLElement | null>
      | MouseEvent<HTMLElement>,
  ): void;
};

const PopoverContext = createContext<{
  getHoverProps: () => HoverProps;
  buttonRef: MutableRefObject<HTMLButtonElement | null>;
} | null>(null);

function usePopoverContext() {
  const context = useContext(PopoverContext);
  if (!context) {
    throw new Error(
      "Popover compound components cannot be rendered outside the Popover component",
    );
  }
  return context;
}

function PopoverHoverContextProvider(
  props: PropsWithChildren<
    { trigger?: PopoverTrigger; hoverDelay?: number } & PopoverRenderPropArg
  >,
) {
  const enterDelay = props.hoverDelay ?? 0;
  const leaveDelay = 100;

  const enterTimeoutRef = useRef<NodeJS.Timeout>();
  const leaveTimeoutRef = useRef<NodeJS.Timeout>();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(
    () => () => {
      clearTimeout(enterTimeoutRef.current);
      clearTimeout(leaveTimeoutRef.current);
    },
    [],
  );

  const onMouseEnter = () => {
    clearTimeout(leaveTimeoutRef.current);
    if (props.open) return;
    enterTimeoutRef.current = setTimeout(() => {
      buttonRef.current?.click();
    }, enterDelay);
  };

  const onMouseLeave = () => {
    clearTimeout(enterTimeoutRef.current);
    if (!props.open) return;
    leaveTimeoutRef.current = setTimeout(() => props.close(), leaveDelay);
  };

  const getHoverProps = () => {
    return props.trigger === "hover" ? { onMouseEnter, onMouseLeave } : {};
  };

  const context = {
    getHoverProps,
    buttonRef,
  };

  return (
    <PopoverContext.Provider value={context}>
      {props.children}
    </PopoverContext.Provider>
  );
}

function PopoverFn({
  trigger,
  hoverDelay,
  children,
  ...props
}: PopoverProps<"div"> & { trigger?: PopoverTrigger; hoverDelay?: number }) {
  return (
    <HUIPopover {...props}>
      {(renderProps) => (
        <PopoverHoverContextProvider
          trigger={trigger}
          hoverDelay={hoverDelay}
          {...renderProps}
        >
          {typeof children === "function" ? children(renderProps) : children}
        </PopoverHoverContextProvider>
      )}
    </HUIPopover>
  );
}

function ButtonFn<TTag extends ElementType = "button">(
  props: PopoverButtonProps<TTag>,
  ref: Ref<HTMLButtonElement>,
) {
  const { getHoverProps, buttonRef } = usePopoverContext();
  return (
    <HUIPopover.Button
      ref={mergeRefs(ref, buttonRef)}
      {...getHoverProps()}
      {...props}
    />
  );
}

const PopoverPanel = forwardRef<HTMLDivElement, PopoverPanelProps<"div">>(
  (props, ref) => {
    const { getHoverProps } = usePopoverContext();
    return <HUIPopover.Panel ref={ref} {...getHoverProps()} {...props} />;
  },
);

const Button = forwardRef(ButtonFn) as <TTag extends ElementType = "button">(
  props: PopoverButtonProps<TTag> & { ref?: Ref<HTMLButtonElement> },
) => ReturnType<typeof ButtonFn>;

export let Popover = Object.assign(PopoverFn, {
  Button: Button,
  Overlay: HUIPopover.Overlay,
  Panel: PopoverPanel,
  Group: HUIPopover.Group,
});
