import { useMountEffect } from "@/hooks/useMountEffect";
import React from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { createPortal } from "react-dom";

const CONFETTI_ID = "confetti-container";

export function ConfettiRoot() {
  return <div id={CONFETTI_ID} />;
}

export function ConfettiContainer() {
  const [showConfetti, setShowConfetti] = React.useState(false);

  useMountEffect(() => {
    setShowConfetti(true);
  });

  // Make sure `ConfettiRoot` is mounted before rendering
  if (!showConfetti) return null;

  return createPortal(
    <div className="absolute inset-0 flex items-start justify-center overflow-hidden">
      <ConfettiExplosion
        force={0.6}
        duration={3500}
        particleCount={200}
        floorHeight={window.innerHeight}
        floorWidth={window.innerWidth}
      />
    </div>,
    document.getElementById("confetti-container") || document.body,
  );
}
