import { useMonaco } from "@monaco-editor/react";
import { DependencyType } from "@/apollo/types";
import * as monaco from "monaco-editor";
import { useCurrentModel } from "@/pages/ModelTool/hooks/useCurrentModel";
import { useListPublishedModels } from "@/pages/ModelTool/hooks/useListModels";
import { useModelEditorDependencyReferences } from "@/pages/ModelTool/ModelEditorStore";
import { useGetQueryDependencies } from "@/pages/ModelTool/useQueryDependencies";
import { useRawViews } from "@/pages/ModelTool/useRawViews";
import { MutableRefObject, useEffect, useMemo, useState } from "react";

export default function useModelMarkers(
  editorRef: MutableRefObject<monaco.editor.IStandaloneCodeEditor | undefined>,
) {
  const monaco = useMonaco();

  const weldTags = useModelEditorDependencyReferences();
  const getQueryDependencies = useGetQueryDependencies();

  const [markers, setMarkers] = useState<monaco.editor.IMarkerData[]>([]);

  const unusedDependenciesWeldTags = useUnusedDependenciesWeldTags();

  useEffect(() => {
    const model = editorRef.current?.getModel();
    if (!monaco || !model) return;

    const missingDependencies = getQueryDependencies(weldTags).filter(
      (d) => d.type === DependencyType.MissingReference,
    );

    const markers: monaco.editor.IMarkerData[] = [];

    missingDependencies.forEach((dependency) => {
      const matches = model.findMatches(
        dependency.weldTag,
        true,
        false,
        true,
        null,
        false,
      );

      matches.forEach((match) => {
        markers.push({
          severity: monaco.MarkerSeverity.Error,
          message: `Invalid dependency: ${dependency.weldTag}`,
          source: dependency.weldTag,
          startLineNumber: match.range.startLineNumber,
          startColumn: match.range.startColumn,
          endLineNumber: match.range.endLineNumber,
          endColumn: match.range.endColumn,
        });
      });
    });
    setMarkers(markers);
    monaco.editor.setModelMarkers(model, "weld", markers);
  }, [editorRef, monaco, weldTags, getQueryDependencies]);

  useEffect(() => {
    const model = editorRef.current?.getModel();
    if (!monaco || !model || !unusedDependenciesWeldTags?.length) return;

    const dispose = monaco.languages.registerCodeActionProvider("sql", {
      provideCodeActions(model, range, context, token) {
        const codeActions: monaco.languages.CodeAction[] = [];

        for (const marker of markers) {
          unusedDependenciesWeldTags.forEach((weldTag) => {
            const fix: monaco.languages.TextEdit = {
              range: {
                endColumn: marker.endColumn,
                endLineNumber: marker.endLineNumber,
                startColumn: marker.startColumn,
                startLineNumber: marker.startLineNumber,
              },
              text: weldTag,
            };
            codeActions.push({
              title: `Change to: ${weldTag}`,
              diagnostics: [marker],
              edit: {
                edits: [
                  {
                    edit: fix,
                    resource: model.uri,
                  },
                ],
              },
              kind: "quickfix", // not sure if necessary / what value should be used
            });
          });
        }
        return { actions: codeActions, dispose() {} };
      },
    });

    return () => {
      dispose.dispose();
    };
  }, [markers, editorRef, monaco, unusedDependenciesWeldTags]);
}

const useUnusedDependenciesWeldTags = () => {
  const currentModel = useCurrentModel();

  const weldTags = useModelEditorDependencyReferences();
  const getQueryDependencies = useGetQueryDependencies();

  const { publishedModels } = useListPublishedModels();
  const { rawViews } = useRawViews();

  const unusedDependenciesWeldTags = useMemo(() => {
    const unusedDeps = currentModel?.publishedQuery?.dependencies?.filter(
      (d) =>
        !getQueryDependencies(weldTags).some(
          (cur) => cur.dwItemId === d.dwItemId,
        ),
    );
    const modelTags = publishedModels
      .filter((m) => {
        return unusedDeps?.some((d) => d.dwItemId === m.id);
      })
      .map((m) => m.dwSync?.weldTag || m.dwTable?.weldTag || m.name);
    const rawViewtags = rawViews
      .filter((v) => {
        return unusedDeps?.some((d) => d.dwItemId === v.viewId);
      })
      .map((v) => v.weldTag);

    return [...modelTags, ...rawViewtags];
  }, [
    currentModel?.publishedQuery?.dependencies,
    weldTags,
    getQueryDependencies,
    publishedModels,
    rawViews,
  ]);

  return unusedDependenciesWeldTags;
};
