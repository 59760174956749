import { useMemo } from "react";
import { Tile } from "../VisualizationType";
import { useQueryModelQuery } from "@/apollo/types";
import { usePreviewData } from "../ModelVisualizePanel/usePreviewData";

export type columnOption = {
  value: string;
  label: string;
  type:
    | "string"
    | "number"
    | "bigint"
    | "boolean"
    | "symbol"
    | "undefined"
    | "object"
    | "function";
};
export const useTileColumnOptions = (tile: Tile, usePreview?: boolean) => {
  const previewQueryQuery = useQueryModelQuery({
    variables: {
      modelId: tile.modelId ?? "",
    },
    skip: !tile.modelId || usePreview,
  });

  const { data: previewData } = usePreviewData(tile.modelId ?? "");

  const columnOptions = useMemo(() => {
    const data = usePreview
      ? previewData
      : previewQueryQuery.data?.queryModel.response;

    const firstRow = data?.[0];
    if (!firstRow) return [];
    return Object.keys(firstRow).map((key) => {
      return {
        value: key,
        label: key,
        type: typeof firstRow[key],
      };
    });
  }, [previewData, previewQueryQuery.data?.queryModel.response, usePreview]);

  return { columnOptions, loading: previewQueryQuery.loading };
};
