import "./month-picker.css";

import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { IconButton, SecondaryButton } from "@/components/elements/Button";
import dayjs from "dayjs";
import React from "react";
import ReactDatePicker from "react-datepicker";

export function MonthPicker(
  props: React.ComponentProps<typeof ReactDatePicker>,
) {
  return (
    <ReactDatePicker
      portalId="root"
      showMonthYearPicker
      maxDate={new Date()}
      customInput={<ChartDatePickerInput />}
      renderCustomHeader={({
        date,
        changeYear,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => {
        const year = date.getFullYear();
        return (
          <div className="z-10 flex items-center gap-2 px-2">
            <div className="w-8 flex-none">
              <IconButton
                size="sm"
                icon={<ChevronLeftIcon />}
                onClick={() => changeYear(year - 1)}
                isDisabled={prevMonthButtonDisabled}
              />
            </div>
            <div className="flex-1 text-base">{year}</div>
            <div className="w-8 flex-none">
              <IconButton
                size="sm"
                icon={<ChevronRightIcon />}
                onClick={() => changeYear(year + 1)}
                isDisabled={nextMonthButtonDisabled}
              />
            </div>
          </div>
        );
      }}
      {...props}
    />
  );
}

const ChartDatePickerInput = React.forwardRef<
  HTMLButtonElement,
  {
    value?: string;
    onClick?: () => void;
  }
>(({ value, onClick }, ref) => {
  const displayText = React.useMemo(() => {
    if (value == null) return "";
    const now = dayjs();
    const date = dayjs(value);

    const isSameYear = date.year() === now.year();
    const isSameMonth = date.month() === now.month();

    if (isSameYear && isSameMonth) {
      return "This month";
    }
    return Intl.DateTimeFormat(navigator.language, {
      month: isSameYear ? "long" : "short",
      year: isSameYear ? undefined : "numeric",
    }).format(date.toDate());
  }, [value]);

  return (
    <SecondaryButton
      ref={ref}
      onClick={onClick}
      size="sm"
      icon={<CalendarDaysIcon />}
      className="flex-shrink-0 whitespace-nowrap"
    >
      {displayText}
    </SecondaryButton>
  );
});
