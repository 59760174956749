import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
/* This example requires Tailwind CSS v2.0+ */
import React, {
  ComponentProps,
  Fragment,
  cloneElement,
  createContext,
  useContext,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

const TableMenuContext = createContext<{
  size?: "sm";
  ref: React.RefCallback<HTMLButtonElement>;
} | null>(null);

const useTableMenuContext = () => {
  const context = useContext(TableMenuContext);
  if (!context) {
    throw new Error("useTableMenuContext must be used within a TableMenu");
  }
  return context;
};

export default function TableMenu({
  children,
  size,
  button: buttonComponent,
}: React.PropsWithChildren<{
  size?: "sm";
  button?: React.ReactNode;
}>) {
  const [referenceElement, setReferenceElement] = useState<any>();
  const [popperElement, setPopperElement] = useState<any>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "absolute",
    placement: "bottom-end",
    modifiers: [
      { name: "preventOverflow", enabled: false },
      { name: "offset", options: { offset: [10, 10] } },
    ],
  });

  return (
    <TableMenuContext.Provider value={{ size, ref: setReferenceElement }}>
      <Menu as="div" className="shrink-0 text-left">
        {({ open }) => (
          <>
            {buttonComponent ? buttonComponent : <MenuButton />}
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <div>
                {ReactDOM.createPortal(
                  <Menu.Items
                    className="z-50 w-56 rounded-md border border-gray-200 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:border-gray-700 dark:bg-gray-800"
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    static
                  >
                    <div className={size === "sm" ? "" : "py-1"}>
                      {children}
                    </div>
                  </Menu.Items>,
                  document.body,
                )}
              </div>
            </Transition>
          </>
        )}
      </Menu>
    </TableMenuContext.Provider>
  );
}

export function MenuButton(
  props: ComponentProps<"button"> & {
    icon?: React.ReactNode;
  },
) {
  const { size, ref } = useTableMenuContext();
  const IconComponent = props.icon ? props.icon : <EllipsisHorizontalIcon />;
  return (
    <Menu.Button
      ref={ref as any}
      onClick={(e: any) => e.stopPropagation()}
      className="flex items-center rounded-sm text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:hover:text-gray-300 dark:focus:ring-offset-gray-800"
      {...props}
    >
      <span className="sr-only">Open options</span>
      {cloneElement(IconComponent as any, {
        className: size === "sm" ? "h-4 w-4" : "h-5 w-5",
        "aria-hidden": true,
      })}
    </Menu.Button>
  );
}
