import { useMonaco } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import { useDataWarehouse } from "@/providers/account";
import { useCallback, useEffect } from "react";

import * as KEYWORDS_SF from "../../constants/keywordsSnowflake";
import * as KEYWORDS_BQ from "../../constants/keywordsBigQuery";
import { useCurrentModel } from "../../hooks/useCurrentModel";
import { useListModels } from "../../hooks/useListModels";
import { useRawViews } from "../../useRawViews";
import { useDependencySchemas } from "./useDependencySchemas";

const PropertyKind = monaco.languages.CompletionItemKind.Property;
const FieldKind = monaco.languages.CompletionItemKind.Field;

const openingBracketsRegex = /{{[\w._-]*$/;
const closingBracketsRegex = /^.*}}/;

type WeldTagConfig = {
  hasOpeningBrackets: boolean;
  hasClosingBrackets: boolean;
};

export function getWordRange(
  model: monaco.editor.ITextModel,
  position: monaco.Position,
): monaco.IRange {
  const word = model.getWordUntilPosition(position);
  return {
    startLineNumber: position.lineNumber,
    endLineNumber: position.lineNumber,
    startColumn: word.startColumn,
    endColumn: word.endColumn,
  };
}

/**
 * Calculate the Range of a weld tag.
 */
export function getWeldTagRange(
  model: monaco.editor.ITextModel,
  position: monaco.Position,
  weldTagConfig: WeldTagConfig,
): monaco.IRange {
  const textUntilPosition = model.getValueInRange({
    startLineNumber: 1,
    startColumn: 1,
    endLineNumber: position.lineNumber,
    endColumn: position.column,
  });

  const bracketsSyntaxMatch = textUntilPosition.match(openingBracketsRegex);

  if (bracketsSyntaxMatch != null) {
    const match = model.findPreviousMatch(
      bracketsSyntaxMatch[0],
      position,
      false,
      false,
      null,
      true,
    );

    if (match?.matches?.[0] != null) {
      let endColumn = match.range.endColumn;

      if (weldTagConfig.hasClosingBrackets) {
        const endTagMatch = model.findNextMatch(
          "}}",
          position,
          false,
          false,
          null,
          true,
        );
        if (endTagMatch?.matches?.[0] != null) {
          endColumn = endTagMatch.range.endColumn - 2; // subtract 2 columns to stop before `}}`
        }
      }
      return {
        endColumn,
        endLineNumber: match.range.endLineNumber,
        startColumn: match.range.startColumn + 2, // add 2 columns to skip past `{{`
        startLineNumber: match.range.startLineNumber,
      };
    }
  }
  return getWordRange(model, position);
}

function getSQLKeywordSuggestionItems(
  range: monaco.IRange,
  dwIntegrationId?: string,
) {
  const SQL_KEYWORDS =
    dwIntegrationId === "snowflake" ? KEYWORDS_SF : KEYWORDS_BQ;

  return [
    ...SQL_KEYWORDS.keywords.map((keyword) => ({
      label: keyword,
      insertText: keyword,
      kind: monaco.languages.CompletionItemKind.Keyword,
      detail: "SQL Keyword",
      range,
    })),
    ...SQL_KEYWORDS.operators.map((operator) => ({
      label: operator,
      insertText: operator,
      kind: monaco.languages.CompletionItemKind.Operator,
      detail: "SQL Operator",
      range,
    })),
    ...SQL_KEYWORDS.builtinFunctions.map((fn) => ({
      label: fn,
      insertText: fn,
      kind: monaco.languages.CompletionItemKind.Function,
      detail: "SQL Function",
      range,
    })),
  ];
}

type Model = ReturnType<typeof useListModels>["models"][number];

const hasDwTable = (
  m: Model,
): m is Model & {
  dwTable: NonNullable<Model["dwTable"]>;
} => !!m.dwTable;

const hasDwSync = (
  m: Model,
): m is Model & {
  dwSync: NonNullable<Model["dwSync"]>;
} => !!m.dwSync;

function formatWeldTag(value: string, config: WeldTagConfig) {
  if (!config.hasOpeningBrackets) {
    value = "{{" + value;
  }
  if (!config.hasClosingBrackets) {
    value = value + "}}";
  }
  return value;
}

export default function useCompletionItems() {
  const monaco = useMonaco();
  const dw = useDataWarehouse();

  const getColumnNameSuggestionItems = useGetSchemaSuggestionItems();
  const getRawViewSuggestionItems = useGetRawViewSuggestionItems();

  const { getModelSuggestionViews, getModelSuggestionTables } =
    useGetModelSuggestionItems();

  const provideCompletionItems: monaco.languages.CompletionItemProvider["provideCompletionItems"] =
    useCallback(
      (model: monaco.editor.ITextModel, position: monaco.Position) => {
        const textUntilPosition = model.getValueInRange({
          startLineNumber: 1,
          startColumn: 1,
          endLineNumber: position.lineNumber,
          endColumn: position.column,
        });
        const restOfLineText = model.getValueInRange({
          startLineNumber: position.lineNumber,
          startColumn: position.column,
          endLineNumber: position.lineNumber,
          endColumn: model.getLineMaxColumn(position.lineNumber),
        });

        const hasOpeningBrackets = openingBracketsRegex.test(textUntilPosition);
        const hasClosingBrackets = closingBracketsRegex.test(restOfLineText);

        const weldTagConfig: WeldTagConfig = {
          hasOpeningBrackets,
          hasClosingBrackets,
        };

        const suggestionRange = hasOpeningBrackets
          ? getWeldTagRange(model, position, weldTagConfig)
          : getWordRange(model, position);

        const suggestions = [
          ...getColumnNameSuggestionItems(suggestionRange),
          ...getRawViewSuggestionItems(suggestionRange, weldTagConfig),
          ...getModelSuggestionViews(suggestionRange, weldTagConfig),
          ...getModelSuggestionTables(suggestionRange, weldTagConfig),
          ...(hasOpeningBrackets
            ? [] // do not add SQL keyword suggestions when inside placeholder brackets.
            : getSQLKeywordSuggestionItems(suggestionRange, dw.integrationId)),
        ];
        return {
          suggestions,
        };
      },
      [
        getColumnNameSuggestionItems,
        getRawViewSuggestionItems,
        getModelSuggestionViews,
        getModelSuggestionTables,
        dw.integrationId,
      ],
    );

  useEffect(() => {
    if (!monaco) return;
    const subscription = monaco.languages.registerCompletionItemProvider(
      "sql",
      {
        provideCompletionItems: provideCompletionItems,
      },
    );
    return () => subscription.dispose();
  }, [monaco, provideCompletionItems]);
}

export const useGetRawViewSuggestionItems = () => {
  const { rawViews } = useRawViews();
  return useCallback(
    (
      range: monaco.IRange,
      weldTagConfig: WeldTagConfig,
    ): monaco.languages.CompletionItem[] => {
      return rawViews.map((view) => {
        return {
          label: view.weldTag,
          insertText: formatWeldTag(view.weldTag, weldTagConfig),
          kind: PropertyKind,
          detail: "Data warehouse raw data view",
          range,
        };
      });
    },
    [rawViews],
  );
};

export const useGetModelSuggestionItems = () => {
  const models = useListModels().models;
  const currentModel = useCurrentModel();

  const getModelSuggestionViews = useCallback(
    (
      range: monaco.IRange,
      weldTagConfig: WeldTagConfig,
    ): monaco.languages.CompletionItem[] => {
      return models
        .filter(hasDwSync)
        .filter((m) => m.id !== currentModel?.id)
        .map((model): monaco.languages.CompletionItem => {
          return {
            label: model.dwSync.weldTag,
            insertText: formatWeldTag(model.dwSync.weldTag, weldTagConfig),
            kind: PropertyKind,
            detail: "Data model view",
            range,
          };
        });
    },
    [models, currentModel],
  );
  const getModelSuggestionTables = useCallback(
    (
      range: monaco.IRange,
      weldTagConfig: WeldTagConfig,
    ): monaco.languages.CompletionItem[] => {
      return models.filter(hasDwTable).map((model) => {
        const item = {
          label: model.dwTable.weldTag,
          insertText: formatWeldTag(model.dwTable.weldTag, weldTagConfig),
          kind: PropertyKind,
          detail: "Data model table",
          range,
        };
        return item;
      });
    },
    [models],
  );

  return {
    getModelSuggestionViews,
    getModelSuggestionTables,
  };
};

export const useGetSchemaSuggestionItems = () => {
  const schemas = useDependencySchemas();

  return useCallback(
    (range: monaco.IRange): monaco.languages.CompletionItem[] => {
      return schemas.flatMap((ref) => {
        return ref.schema.map((col) => ({
          label: `${col.name} (${ref.weldTag})`,
          insertText: col.name,
          kind: FieldKind,
          detail: col.type,
          range,
        }));
      });
    },
    [schemas],
  );
};
