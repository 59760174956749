import TabLayout from "@/components/layouts/TabLayout";
import { useSettingsTabs } from "@/pages/Settings/hooks/useSettingsTabs";

import NotificationPreferences from "./NotificationPreferences";

export function NotificationsPage() {
  const tabs = useSettingsTabs();
  return (
    <TabLayout tabs={tabs}>
      <NotificationPreferences />
    </TabLayout>
  );
}
