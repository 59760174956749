import React from "react";

import classNames from "@/helpers/classNames";

export type InputAddonProps = {
  placement?: "left" | "right";
  disableGutters?: boolean;
} & React.ComponentProps<"div">;

export const InputAddon = ({
  placement = "left",
  disableGutters = false,
  className,
  ...props
}: InputAddonProps) => {
  return (
    <div
      className={classNames(
        "rounded-sm text-sm",
        !disableGutters && "flex items-center px-3",
        "bg-gray-50 text-muted-foreground dark:bg-gray-700",
        "border border-gray-300 dark:border-gray-700",
        placement === "left"
          ? "rounded-br-none rounded-tr-none"
          : "rounded-bl-none rounded-tl-none",
        className,
      )}
      {...props}
    />
  );
};

export const InputAddonLeft = ({
  className,
  ...props
}: Omit<InputAddonProps, "placement">) => (
  <InputAddon
    placement="left"
    className={classNames("-mr-px", className)}
    {...props}
  />
);

// Used in InputGroup.tsx
InputAddonLeft.id = "InputAddonLeft";

export const InputAddonRight = ({
  className,
  ...props
}: Omit<InputAddonProps, "placement">) => (
  <InputAddon
    placement="right"
    className={classNames("-ml-px", className)}
    {...props}
  />
);

// Used in InputGroup.tsx
InputAddonRight.id = "InputAddonRight";
