import { useOrchestrationWorkflowRunQuery } from "@/apollo/types";
import { useMemo } from "react";

export const useWorkflowRun = (workflowRunId?: string) => {
  const { data, loading } = useOrchestrationWorkflowRunQuery({
    variables: { runId: workflowRunId ?? "" },
    skip: !workflowRunId,
  });

  return useMemo(() => {
    return {
      orchestrationWorkflowRun: data?.orchestrationWorkflowRun,
      loading,
    };
  }, [data, loading]);
};
