import { motion } from "framer-motion";
import { ComponentProps } from "react";

import { RecurrenceInterval, WeldPlan } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import { TextMuted } from "@/components/elements/Typography";
import { WELD_PLAN_COMPARISON_TABLE_URL } from "@/constants/external-urls/weld-website";
import cn from "@/helpers/classNames";
import { RadioGroup } from "@headlessui/react";

import { useBillingPeriodToggle } from "../../shared/hooks/useBillingPeriodToggle";
import { LocaleProvider } from "../../shared/providers";
import { Badge, PlanUpgradePillars } from "../../shared/ui";
import { useBillingDetails } from "../../useBillingDetails";
import { useCheckoutModal } from "../create-subscription";

function ChangePlanDialog(
  props: ComponentProps<typeof Modal> & {
    title?: string;
    message?: string;
  },
) {
  const { title = "Upgrade your plan", message, ...modalProps } = props;

  const { data } = useBillingDetails();

  const { openCheckoutModal } = useCheckoutModal();
  const { billingPeriod, setBillingPeriod } = useBillingPeriodToggle();

  const handlePlanSelect = async (plan: WeldPlan) => {
    props.onClose();
    openCheckoutModal({ planName: plan.name, billingPeriod });
  };

  return (
    <Modal {...modalProps}>
      <ModalHeader className="text-center text-2xl">{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody className="pb-6">
        {props.message && (
          <div className="mb-8 flex justify-center">
            <p className="max-w-lg text-center">{props.message}</p>
          </div>
        )}
        {data?.isAnnualSubscription !== true && (
          <div className="mb-8 flex justify-center">
            <BillingPeriodSwitcher
              value={billingPeriod}
              onChange={setBillingPeriod}
            />
          </div>
        )}
        <div className="mt-8">
          <PlanUpgradePillars onSelectPlan={handlePlanSelect} />
        </div>
        <div className="mt-8">
          <div className="flex flex-col items-center text-sm">
            <TextMuted>More details about our plans?</TextMuted>
            <Button
              as="a"
              href={WELD_PLAN_COMPARISON_TABLE_URL}
              target="_blank"
              variant="link"
              colorScheme="primary"
              className="ml-2"
            >
              View pricing page
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export function BillingPeriodSwitcher(props: {
  value: RecurrenceInterval.Month | RecurrenceInterval.Year;
  onChange: (
    period: RecurrenceInterval.Month | RecurrenceInterval.Year,
  ) => void;
}) {
  return (
    <RadioGroup
      value={props.value}
      onChange={(value) => {
        props.onChange?.(value);
      }}
      className="flex rounded-xl bg-gray-200 p-1 text-sm dark:bg-gray-700"
    >
      <RadioGroup.Option
        as="button"
        value={RecurrenceInterval.Month}
        className="relative isolate px-8 py-1"
      >
        {({ checked }) => (
          <>
            {checked && (
              <motion.div
                layoutId="selected"
                transition={{ type: "tween" }}
                className="absolute inset-0 -z-[1] rounded-lg bg-white shadow dark:bg-gray-600"
              />
            )}
            <span
              className={cn("flex items-center gap-2 text-gray-400", {
                "text-black dark:text-white": checked,
              })}
            >
              Monthly
            </span>
          </>
        )}
      </RadioGroup.Option>
      <RadioGroup.Option
        as="button"
        value={RecurrenceInterval.Year}
        className="relative isolate px-4 py-1"
      >
        {({ checked }) => (
          <>
            {checked && (
              <motion.div
                layoutId="selected"
                transition={{ type: "tween" }}
                className="absolute inset-0 -z-[1] rounded-lg bg-white shadow dark:bg-gray-600"
              />
            )}
            <span
              className={cn("flex items-center gap-2 text-gray-400", {
                "text-black dark:text-white": checked,
              })}
            >
              Annually{" "}
              <Badge
                variant="success"
                className={cn("text-xs", {
                  "saturate-0": !checked,
                })}
              >
                -20% off
              </Badge>
            </span>
          </>
        )}
      </RadioGroup.Option>
    </RadioGroup>
  );
}

function ChangePlanDialogWrapper(
  props: ComponentProps<typeof ChangePlanDialog>,
) {
  return (
    <LocaleProvider>
      <ChangePlanDialog {...props} />
    </LocaleProvider>
  );
}

export { ChangePlanDialogWrapper as ChangePlanDialog };
