import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useState } from "react";

import { useInterval } from "./useInterval";

dayjs.extend(relativeTime);

const useNow = (interval: number | null) => {
  const [now, setNow] = useState(dayjs());
  useInterval(() => {
    setNow(dayjs());
  }, interval);

  return now;
};

export default useNow;
