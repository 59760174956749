import { useMatch } from "@tanstack/react-location";
import dayjs from "dayjs";
import * as jsdiff from "diff";
import * as diff2html from "diff2html";
import { useMemo } from "react";
import { LocationGenerics } from "routes";

import { useQueryHistory } from "../../hooks/useQueryHistory";
import {
  useGetEditorTextState,
  useModelEditorDirtyState,
  useModelEditorState,
} from "../../ModelEditorStore";

export function HistoryDiffViewer() {
  const state = useModelEditorState();

  const isModelDirty = useModelEditorDirtyState();
  const getTextState = useGetEditorTextState();

  const {
    params: { modelId },
  } = useMatch<LocationGenerics>();
  const queryHistory = useQueryHistory(modelId);

  const selectedQuery =
    state.selectedHistoryItem === -1
      ? undefined
      : queryHistory[state.selectedHistoryItem];

  const originalSql = selectedQuery?.weldSql;
  const originalDate = selectedQuery && dayjs(selectedQuery.createdAt);
  const isPublishedModel = state.selectedHistoryItem === 0;

  const html = useMemo(() => {
    if (!originalSql) return null;

    const diffString = jsdiff.createTwoFilesPatch(
      "Old",
      "New",
      originalSql,
      getTextState().weldSql,
    );
    return diff2html.html(diffString, {
      drawFileList: true,
      matching: "lines",
      outputFormat: "side-by-side",
    });
  }, [originalSql, getTextState]);

  if (!html) return null;

  return (
    <div className="absolute inset-0 z-10 h-full overflow-y-auto bg-gray-100 dark:bg-gray-800 dark:text-white">
      <div className="flex w-full border-b dark:border-gray-600">
        <div className="w-1/2 border-r py-1 text-center text-xs dark:border-gray-600">
          {isPublishedModel
            ? `Published model`
            : originalDate?.format("HH:mm DD. MMM YYYY") || "History"}
        </div>
        <div className="w-1/2 py-1 text-center text-xs">
          {isModelDirty ? "Current edited version" : "Published model"}
        </div>
      </div>
      <div className="w-full" dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
}
