import {
  ListModelFoldersDocument,
  ListModelsQuery,
  useUpdateFolderEmojiMutation,
  useUpdateModelEmojiMutation,
} from "@/apollo/types";
import { EmojiPicker } from "@/components/modules/EmojiPicker";
import { useToast } from "@/providers/ToastProvider";

import { FolderItemType } from "../Sidebar/SidebarFactory";
import { ModelIcon } from "./ModelIcon/ModelIcon";

export const FolderEmojiPicker = (props: {
  item: FolderItemType;
  children: JSX.Element;
}) => {
  const toast = useToast();
  const [updateEmoji] = useUpdateFolderEmojiMutation({
    onError(error) {
      toast("Error updating emoji", error.message, "error");
    },
    refetchQueries: [{ query: ListModelFoldersDocument }],
  });

  const mutateEmoji = (emoji: string | null) =>
    updateEmoji({
      variables: {
        folderId: props.item.id,
        emoji,
      },
    });

  return (
    <EmojiPicker onEmojiChange={mutateEmoji}>{props.children}</EmojiPicker>
  );
};

export const ModelEmojiPicker = (props: {
  model: ListModelsQuery["models"][0];
}) => {
  const toast = useToast();
  const [updateEmoji] = useUpdateModelEmojiMutation({
    onError(error) {
      toast("Error updating emoji", error.message, "error");
    },
  });

  const mutateEmoji = (emoji: string | null) =>
    updateEmoji({
      variables: {
        modelId: props.model.id,
        emoji,
      },
    });

  return (
    <EmojiPicker onEmojiChange={mutateEmoji}>
      <ModelIcon model={props.model} />
    </EmojiPicker>
  );
};
