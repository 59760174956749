import { useFindAvailableOperationsQuery } from "@/apollo/types";

export const useGoogleSheetsOperations = (options: {
  connectionId: string;
  skip?: boolean;
}) => {
  const {
    loading,
    data: { findAllAvailableOperations: operations = [] } = {},
  } = useFindAvailableOperationsQuery({
    variables: {
      connectionId: options.connectionId,
      objectType: "sheet",
    },
    skip: options.skip ?? false,
  });

  return { operations, loading };
};
