import { useSyncContext } from "../modules/SyncContext";
import { MappingType } from "../modules/syncReducer";
import { useCallback } from "react";
import { OperationTypes } from "@/apollo/types";
import useSourceProperties from "./useSourceProperties";
import useDestinationProperties from "./useDestinationProperties";
import useDestinations from "./useDestinations";
import useOperations from "./useOperations";
export default function useGenerateOperationInputArray() {
  const [state] = useSyncContext();

  const { sourcePropertiesObject } = useSourceProperties();
  const { destinationPropertiesObject } = useDestinationProperties();
  const { currentDestination } = useDestinations();
  const { currentOperation } = useOperations();

  const generateOperationInputArray = useCallback(() => {
    if (!currentDestination) throw Error("Current destination is not set");
    if (!currentOperation) throw Error("Current operation is not set");
    return [
      {
        id: state.operation?.id ?? "",
        objectType: state.objectTypeName,
        mappings: state.mapping
          .filter((item): item is Required<MappingType> => {
            return !!item.sourcePropertyId && !!item.destinationPropertyId;
          })
          .map((item) => {
            const sourcePropery = sourcePropertiesObject[item.sourcePropertyId];
            const destinationPropery =
              destinationPropertiesObject[item.destinationPropertyId];

            return {
              type: OperationTypes.Primary,
              operation: currentOperation?.operationMode,
              object: state.objectTypeName,
              sourcePropertyId: item.sourcePropertyId,
              primaryKey: item.isPrimaryMapping || false,
              sourcePropertyName: sourcePropery.propertyName,
              sourcePropertyType: sourcePropery.propertyType,
              destinationPropertyId: item.destinationPropertyId,
              destinationPropertyName:
                destinationPropery?.propertyName || item.destinationPropertyId,
              destinationPropertyType:
                destinationPropery?.propertyType || "string",
              readonly: destinationPropery?.readonly || false,
            };
          }),
        integrationId: currentDestination.integrationId,
      },
    ];
  }, [
    currentDestination,
    currentOperation,
    state.operation?.id,
    state.objectTypeName,
    state.mapping,
    sourcePropertiesObject,
    destinationPropertiesObject,
  ]);

  return generateOperationInputArray;
}
