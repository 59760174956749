import { throttle } from "lodash";
import { useEffect, useState } from "react";

/**
 * Implementation based on `useIdle` from `react-use`: https://github.com/streamich/react-use/blob/master/src/useIdle.ts
 */

const events = [
  "mousemove",
  "mousedown",
  "resize",
  "keydown",
  "touchstart",
  "wheel",
];

const useIdleTimer = (ms: number = 60_000): boolean => {
  const [state, setState] = useState(false);

  useEffect(() => {
    let mounted = true;
    let timeout: ReturnType<typeof setTimeout>;
    let localState = state;

    const set = (newState: boolean) => {
      if (mounted) {
        localState = newState;
        setState(newState);
      }
    };

    const onEvent = throttle(() => {
      if (localState) {
        set(false);
      }

      clearTimeout(timeout);
      timeout = setTimeout(() => set(true), ms);
    }, 50);

    const onVisibility = () => {
      if (!document.hidden) {
        onEvent();
      }
    };

    for (let i = 0; i < events.length; i++) {
      window.addEventListener(events[i], onEvent);
    }

    document.addEventListener("visibilitychange", onVisibility);
    timeout = setTimeout(() => set(true), ms);
    return () => {
      mounted = false;
      for (let i = 0; i < events.length; i++) {
        window.removeEventListener(events[i], onEvent);
      }
      document.removeEventListener("visibilitychange", onVisibility);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ms, events]);

  return state;
};

export default useIdleTimer;
