import Content from "@/components/elements/layout/Content";
import PageHeader from "@/components/elements/PageHeader";
import Breadcrumbs from "@/components/modules/Breadcrumbs";
import Tabs, { Tab } from "@/components/modules/Tabs";

type Props = {
  children: JSX.Element;
  tabs: Tab[];
  action?: JSX.Element;
};

export default function TabLayout({ children, tabs, action }: Props) {
  return (
    <Content>
      <PageHeader.Container>
        <Breadcrumbs />
        <>{action}</>
      </PageHeader.Container>
      <Tabs tabs={tabs} />
      <div className="grow">{children}</div>
    </Content>
  );
}
