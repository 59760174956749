import {
  OrchestrationWorkflowDocument,
  useEditWorkflowMutation,
} from "@/apollo/types";
import { PrimaryButton, SecondaryButton } from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import { AdvancedSyncScheduler } from "@/components/modules/WeldSyncScheduler";
import { useValidateCron } from "@/hooks/useCron";
import { useToast } from "@/providers/ToastProvider";
import { useEffect, useState } from "react";

type Props = {
  edting: boolean;
  onFinish: () => void;
  initialCron: string;
  workflowId: string;
};

const EditOrchestrationSchedule = (props: Props) => {
  const [cron, setCron] = useState<string | undefined>(props.initialCron);
  useEffect(() => {
    setCron(props.initialCron);
  }, [props.initialCron]);

  const cronUpdated = cron !== props.initialCron;

  const toast = useToast();

  const cronValid = useValidateCron(cron);

  const [updateWorkflow, updateWorkflowMutation] = useEditWorkflowMutation({
    onCompleted: () => {
      toast(
        "Orchestration schedule updated",
        "Successfully updated schedule for Orchestration.",
        "success",
      );
      props.onFinish();
    },
    onError: (error) => {
      toast(
        "Orchestration update error",
        "Error updating schedule for Orchestration. ",
        "error",
      );
      props.onFinish();
    },
    refetchQueries: [
      {
        query: OrchestrationWorkflowDocument,
        variables: { workflowId: props.workflowId },
      },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <Modal isOpen={props.edting} onClose={() => props.onFinish()}>
      <ModalCloseButton />
      <ModalHeader>Update orchestration schedule</ModalHeader>
      <ModalBody>
        <AdvancedSyncScheduler cron={cron} onChange={setCron} />
      </ModalBody>
      <ModalFooter className="flex-row-reverse justify-start gap-4">
        <PrimaryButton
          disabled={!cronUpdated || !cron || !cronValid}
          onClick={() => {
            if (updateWorkflowMutation.loading || !cronUpdated || !cron) return;
            updateWorkflow({
              variables: {
                cronExpression: cron,
                workflowId: props.workflowId,
              },
            });
          }}
          isLoading={updateWorkflowMutation.loading}
          className="flex-1"
        >
          Save
        </PrimaryButton>
        <SecondaryButton
          className="flex-1"
          disabled={updateWorkflowMutation.loading}
          onClick={() => {
            props.onFinish();
          }}
        >
          Cancel
        </SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

export default EditOrchestrationSchedule;
