import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  MaterializationType,
  OrchestrationSchedulerType,
} from "@/apollo/types";
import { PrimaryButton, SecondaryButton } from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import Tooltip from "@/components/elements/Tooltip";
import { useCallback, useMemo, useState } from "react";

import { useCurrentModel } from "../hooks/useCurrentModel";
import { useUpdateMaterialization } from "../hooks/usePublishModel";
import { useModelEditorState } from "../ModelEditorStore";
import { ConfigureMaterializationType } from "./MaterializationSettings";

const EditModelMaterializationButton = () => {
  const currentModel = useCurrentModel();
  const state = useModelEditorState();
  const [editingMaterialization, setEditingMaterialization] = useState(false);

  const onFinish = useCallback(() => {
    setEditingMaterialization(false);
  }, []);

  const { handleUpdateMaterialization, loading } = useUpdateMaterialization(
    currentModel?.id,
    onFinish,
  );

  const isValid = useMemo(() => {
    if (state.materializationType === MaterializationType.Table) {
      if (state.orchestrationScheduler === OrchestrationSchedulerType.Local) {
        return !!state.materializationSchedule;
      }
      if (state.orchestrationScheduler === OrchestrationSchedulerType.Global) {
        return !!state.orchestrationWorkflowId;
      }
    }
    return true;
  }, [state]);

  if (!currentModel || !currentModel.publishedQuery) return null;

  const hasChanged =
    state.materializationType !== currentModel.materializationType ||
    state.materializationSchedule !==
      (currentModel.materializationSchedule || undefined) ||
    state.orchestrationScheduler !==
      (currentModel.orchestrationScheduler || undefined) ||
    state.orchestrationWorkflowId !== currentModel.orchestrationWorkflow?.id;

  return (
    <>
      <div className="flex items-center space-x-2">
        <SecondaryButton
          onClick={() => setEditingMaterialization(true)}
          className="w-44"
        >
          Edit schedule
        </SecondaryButton>
        <Tooltip content="Change how the query is stored on the datawarehouse, and how often tables should be updated.">
          <div>
            <InformationCircleIcon className="h-5 w-5 dark:text-white" />
          </div>
        </Tooltip>
      </div>
      <Modal
        size="lg"
        onClose={() => setEditingMaterialization(false)}
        isOpen={editingMaterialization}
      >
        <ModalCloseButton />
        <ModalHeader>Edit scheduled updates</ModalHeader>
        <ModalBody>
          <ConfigureMaterializationType />
        </ModalBody>
        <ModalFooter className="flex-row-reverse justify-start gap-4">
          <PrimaryButton
            onClick={() => {
              if (loading || !hasChanged || !isValid) return;
              handleUpdateMaterialization({
                id: currentModel.id,
                materializationType: state.materializationType,
                materializationSchedule: state.materializationSchedule,
                orchestrationScheduler: state.orchestrationScheduler,
                orchestrationWorkflowId: state.orchestrationWorkflowId,
              });
            }}
            className="flex-1"
            isDisabled={!hasChanged || !isValid}
            isLoading={loading}
            loadingText="Updating ..."
          >
            Update
          </PrimaryButton>
          <SecondaryButton
            className="flex-1"
            onClick={() => setEditingMaterialization(false)}
          >
            Cancel
          </SecondaryButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditModelMaterializationButton;
