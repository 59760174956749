import cn from "@/helpers/classNames";
import {
  ComponentProps,
  createContext,
  forwardRef,
  useContext,
  useMemo,
} from "react";

import { ButtonProps } from "./Button";

export type ButtonGroupOptions = Pick<
  ButtonProps,
  "colorScheme" | "isDisabled" | "size" | "variant"
>;

type ButtonGroupProps = ButtonGroupOptions & ComponentProps<"div">;

const ButtonContext = createContext<ButtonGroupOptions>({});

export const useButtonGroupContext = () => useContext(ButtonContext);

export const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(
  (props, ref) => {
    const { size, colorScheme, variant, isDisabled, ...restProps } = props;

    const context: ButtonGroupOptions = useMemo(
      () => ({ size, colorScheme, variant, isDisabled }),
      [size, colorScheme, variant, isDisabled],
    );
    return (
      <ButtonContext.Provider value={context}>
        <div
          ref={ref}
          role="group"
          {...restProps}
          className={cn("flex", restProps.className)}
        />
      </ButtonContext.Provider>
    );
  },
);
