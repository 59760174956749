import WeldLogo from "@/components/elements/Weldlogo";
import React from "react";

export default function SimplePageLayout({
  children,
}: React.PropsWithChildren<{}>) {
  return (
    <div className="relative h-screen overflow-hidden">
      <div className="flex h-full flex-col bg-white dark:bg-gray-900 dark:text-white">
        <div className="mt-12 pb-4">
          <WeldLogo className="mx-auto h-3 w-auto" />
        </div>
        <div className="flex flex-1 justify-center overflow-scroll px-4 py-12 sm:px-6 lg:px-8">
          <div className="w-full max-w-xl space-y-8">{children}</div>
        </div>
      </div>
    </div>
  );
}
