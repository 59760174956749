import { LinkWithSlug } from "routes";

import { Button } from "@/components/elements/Button";
import Tooltip from "@/components/elements/Tooltip";
import { ModelIcon } from "@/pages/ModelTool/components/ModelIcon/ModelIcon";

import { RowData } from "../columns";

function truncateStringMiddle(s: string, targetLength: number): string {
  // Check if truncation is necessary
  if (s.length <= targetLength) {
    return s;
  }

  const midpoint = Math.floor(s.length / 2);
  const left = s.lastIndexOf(".", midpoint);
  const right = s.indexOf(".", midpoint);

  let truncated: string;

  if (left === -1 && right === -1) {
    // If no periods are found, just do a plain truncation around the midpoint
    truncated = s.slice(0, midpoint - 2) + "..." + s.slice(midpoint + 1);
  } else if (left === -1) {
    // If there's no period to the left, truncate around the right period
    truncated = s.slice(0, right - 2) + "..." + s.slice(right + 1);
  } else if (right === -1) {
    // If there's no period to the right, truncate around the left period
    truncated = s.slice(0, left) + "..." + s.slice(left + 1);
  } else {
    // If both periods exist, choose the closer one
    if (midpoint - left <= right - midpoint) {
      truncated = s.slice(0, left) + "..." + s.slice(left + 1);
    } else {
      truncated = s.slice(0, right - 2) + "..." + s.slice(right);
    }
  }

  // Final adjustment if length has gone wrong
  if (truncated.length > targetLength) {
    // Adjust the string to fit within the desired target length
    const partLength = Math.floor((targetLength - 3) / 2); // 3 for the ellipsis
    truncated = s.slice(0, partLength) + "..." + s.slice(s.length - partLength);
  }

  return truncated;
}

export const ModelCell = ({
  model,
  modelPath,
}: Pick<RowData, "model" | "modelPath">) => {
  if (!model) return <div />;
  const truncatedModelPath = truncateStringMiddle(modelPath ?? "", 30);
  return (
    <div className="whitespace-nowrap">
      <Tooltip content={modelPath ?? null}>
        <Button
          variant="ghost"
          icon={<ModelIcon model={model} />}
          size="sm"
          className="h-auto min-h-[2rem] px-1.5 text-xs font-normal leading-none underline sm:text-sm"
          as={LinkWithSlug}
          to={`/editor/${model.id}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {truncatedModelPath}
        </Button>
      </Tooltip>
    </div>
  );
};
