import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Tooltip from "@/components/elements/Tooltip";
import classNames from "@/helpers/classNames";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { lowerCase, startCase } from "lodash";
import { WebhookJobBox } from "./SharedComponents";
import { WebhookRow } from "./useTableRows";

export const WebhookItemColumn = ({ row }: { row: WebhookRow }) => {
  const isLoading = row.isLoading;

  const notInJob = !row.job;
  const noLongerInOrchestration =
    !isLoading && !row.isHistorical && row.isRemoved;
  const integrationName = lowerCase(startCase(row.webhook.type));
  return (
    <div
      className={classNames(
        "flex items-center space-x-4",
        noLongerInOrchestration && "opacity-50",
      )}
    >
      <WebhookJobBox tooltipContent={`${integrationName}`} />
      {isLoading && (
        <div className="flex items-center space-x-2">
          <LoadingSpinner /> <span>Loading model..</span>
        </div>
      )}
      {row.webhook && (
        <div className="text-xs">
          <div className="text-gray-600 dark:text-gray-400">
            {integrationName}
          </div>
          <div className="flex items-center gap-1">
            <span>{row.webhook.name}</span>
          </div>
        </div>
      )}

      {notInJob && (
        <Tooltip
          content={
            "This sync was recently configured to use orchestration. It will be included in the next run."
          }
        >
          <div className="flex items-center space-x-1 rounded border p-1 text-xs">
            <InformationCircleIcon className="h-4 w-4" />
            <span>Recently added</span>
          </div>
        </Tooltip>
      )}
      {noLongerInOrchestration && (
        <Tooltip
          content={
            "This sync is no longer configured to use orchestration. It will not be included in the next run."
          }
        >
          <div className="flex items-center space-x-1 rounded border p-1 text-xs">
            <InformationCircleIcon className="h-4 w-4" />
            <span>Removed</span>
          </div>
        </Tooltip>
      )}
    </div>
  );
};
