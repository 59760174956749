import {
  useWorkspaceSyncStatsByIntegrationQuery,
  useWorkspaceSyncStatsBySourceStreamQuery,
} from "@/apollo/types";
import AppearTransition from "@/components/elements/AppearTransition";
import { Button } from "@/components/elements/Button";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "@/components/elements/Card";
import { MonthPicker } from "@/components/elements/MonthPicker";
import { Heading } from "@/components/elements/Typography";
import {
  BarChartSkeleton,
  ErrorDataPlaceholder,
  NoDataPlaceholder,
  RowsSyncedByIntegrationChart,
  RowsSyncedBySourceStreamChart,
} from "@/components/modules/sync-stats-charts";
import { RowBasisMenu } from "@/components/modules/sync-stats-charts/RowBasisMenu";
import {
  UsageStatsProvider,
  useRowCountSelector,
  useUsageStatsContext,
} from "@/features/usage-stats";
import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import { ResponsiveContainer } from "recharts";
import { startOfMonthUTC } from "@/utils/date-utils";

import MainTabsLayout from "./MainTabsLayout";

const dateAtom = atom(new Date());

export default function EltSyncsUsagePage() {
  return (
    <MainTabsLayout>
      <SyncStatsContainer />
    </MainTabsLayout>
  );
}

function SyncStatsContainer() {
  const [date] = useAtom(dateAtom);
  return (
    <UsageStatsProvider referenceDate={date}>
      <div className="grid gap-8 overflow-hidden pb-8 xl:grid-cols-2">
        <AppearTransition>
          <EltSyncStats />
        </AppearTransition>
        <AppearTransition>
          <SourceStreamStats />
        </AppearTransition>
      </div>
    </UsageStatsProvider>
  );
}

const EltSyncStats = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
  const [date, setDate] = useAtom(dateAtom);

  const { data, loading, error } = useWorkspaceSyncStatsByIntegrationQuery({
    variables: {
      month: startOfMonthUTC(date),
    },
  });

  const { rowCountBasis, enableBillableRows } = useUsageStatsContext();
  const selectRowsSynced = useRowCountSelector();

  const sortedData = (data?.workspaceSyncStatsByIntegration.data ?? [])
    .slice()
    .sort((a, b) => {
      return selectRowsSynced(b.elt) - selectRowsSynced(a.elt);
    });

  const hasError = data == null && error != null;
  const hasNoResults = !loading && sortedData.length === 0;
  return (
    <Card ref={ref}>
      <CardHeader className="flex items-center">
        <Heading className="font-normal">
          Amount of rows synced by connection
        </Heading>
        <div className="ml-auto flex items-center gap-4">
          <RowBasisMenu />
          <MonthPicker
            selected={date}
            onChange={(date: Date) => {
              setDate(date);
            }}
          />
        </div>
      </CardHeader>
      {hasError ? (
        <ErrorDataPlaceholder />
      ) : hasNoResults ? (
        <NoDataPlaceholder />
      ) : (
        <CardBody className="max-h-96 overflow-auto">
          <BarChartSkeleton isLoaded={!loading}>
            {/* 99% width is a hack to have the ResponsiveContainer work properly when shrinking the viewport https://github.com/recharts/recharts/issues/1423 */}
            <ResponsiveContainer width="99%" height="100%">
              <RowsSyncedByIntegrationChart
                data={sortedData}
                rowCountBasis={rowCountBasis}
                isBillableRowsEnabled={enableBillableRows}
                showReverseEltData={false}
              />
            </ResponsiveContainer>
          </BarChartSkeleton>
        </CardBody>
      )}
    </Card>
  );
});

const SourceStreamStats = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
  const [date, setDate] = useAtom(dateAtom);

  const { data, loading, error } = useWorkspaceSyncStatsBySourceStreamQuery({
    variables: {
      month: startOfMonthUTC(date),
    },
  });

  const { rowCountBasis, enableBillableRows } = useUsageStatsContext();
  const selectRowsSynced = useRowCountSelector();

  const [barLimit, setBarLimit] = useState(12);

  const sortedData = (data?.workspaceSyncStatsBySourceStream.data ?? [])
    .slice()
    .sort((a, b) => {
      return selectRowsSynced(b) - selectRowsSynced(a);
    })
    .filter((x) => selectRowsSynced(x) > 0);

  const partialData = sortedData.slice(0, barLimit);

  const hasError = data == null && error != null;
  const hasNoResults = !loading && sortedData.length === 0;
  return (
    <Card ref={ref}>
      <CardHeader className="flex items-center">
        <Heading className="font-normal">
          Amount of rows synced by table
        </Heading>
        <div className="ml-auto flex items-center gap-4">
          <RowBasisMenu />
          <MonthPicker
            selected={date}
            onChange={(date: Date) => {
              setDate(date);
            }}
          />
        </div>
      </CardHeader>
      {hasError ? (
        <ErrorDataPlaceholder />
      ) : hasNoResults ? (
        <NoDataPlaceholder />
      ) : (
        <>
          <CardBody className="max-h-96 overflow-auto">
            <BarChartSkeleton isLoaded={!loading}>
              {/* 99% width is a hack to have the ResponsiveContainer work properly when shrinking the viewport https://github.com/recharts/recharts/issues/1423 */}
              <ResponsiveContainer width="99%" height="100%">
                <RowsSyncedBySourceStreamChart
                  data={partialData}
                  rowCountBasis={rowCountBasis}
                  isBillableRowsEnabled={enableBillableRows}
                />
              </ResponsiveContainer>
            </BarChartSkeleton>
          </CardBody>
          {barLimit < sortedData.length && (
            <>
              <hr />
              <CardFooter className="justify-center">
                <Button
                  variant="link"
                  size="sm"
                  className=""
                  onClick={() => setBarLimit((x) => x + 10)}
                >
                  Show 10 more
                </Button>
              </CardFooter>
            </>
          )}
        </>
      )}
    </Card>
  );
});
