import { XMarkIcon as IconXSmall } from "@heroicons/react/16/solid";
import { XMarkIcon as IconSmall } from "@heroicons/react/20/solid";
import { XMarkIcon as IconDefault } from "@heroicons/react/24/solid";
import { ComponentProps } from "@tw-classed/react";

import { IconButton } from "../Button";

export const CloseButtonIcon = IconDefault;

export function CloseButton(props: Partial<ComponentProps<typeof IconButton>>) {
  let icon = <IconDefault />;
  if (props.size === "sm") {
    icon = <IconSmall />;
  }
  if (props.size === "xs") {
    icon = <IconXSmall />;
  }
  return (
    <IconButton variant="ghost" icon={icon} {...props}>
      <span className="sr-only">Close panel</span>
    </IconButton>
  );
}
