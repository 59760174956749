import { classed } from "@/components/classed.config";

export const Badge = classed.div(
  "inline-flex items-center whitespace-nowrap rounded border px-1.5 py-px text-1xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-transparent bg-primary text-primary-foreground shadow",
        secondary:
          "border-transparent bg-gray-100 dark:bg-gray-700 dark:text-gray-200",
        tertiary:
          "border-transparent bg-indigo-100 text-indigo-500 dark:bg-indigo-800 dark:text-indigo-200",
        quarternary:
          "border-transparent bg-emerald-100 text-emerald-600 dark:bg-emerald-800 dark:text-emerald-200",
        outline: "text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);
