import * as Sentry from "@sentry/react";
import {
  Account,
  useGetDatawarehouseSetupStatusLazyQuery,
  useSetDatawarehouseMutation,
} from "@/apollo/types";
import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import { useCallback } from "react";

import { usePollDWConnectionStatus } from "./usePollDWConnectionStatus";

type AccountInfo = Pick<Account, "id" | "slug">;

type UseConnectCustomDWOptions = {
  onCompleted: (account: AccountInfo, dwIntegrationId: string) => void;
  onError: (error: Error, account: AccountInfo, integrationId: string) => void;
};

export function useConnectCustomDW(options: UseConnectCustomDWOptions): [
  typeof connect,
  {
    isConnecting: boolean;
    isConnected: boolean;
    data?: AccountInfo;
  },
] {
  const optionsRef = useLatestValueRef(options);

  const [
    connectDatawarehouse,
    { called: connectDatawarehouseCalled, reset: connectDatawarehouseReset },
  ] = useSetDatawarehouseMutation({
    onError(error) {
      Sentry.captureException(error);
    },
  });

  const [getConnectStatus] = useGetDatawarehouseSetupStatusLazyQuery();

  const { startPeriodicStatusCheck, isConnectionEstablished } =
    usePollDWConnectionStatus({
      getStatus: getConnectStatus,
      selectStatus: (data) => data?.getDatawarehouseSetupStatus,
    });

  const connect = useCallback(
    async (
      account: AccountInfo,
      integrationId: string,
      configuration: Record<string, any>,
    ) => {
      return new Promise<void>((resolve, reject) => {
        // Start connecting data warehouse
        connectDatawarehouse({
          context: {
            accountId: account.id,
          },
          variables: {
            createConnectionInput: {
              label: "_datawarehouse",
              integrationId,
              payload: btoa(JSON.stringify(configuration)),
            },
          },
          onError(error) {
            optionsRef.current.onError(error, account, integrationId);
            reject();
            connectDatawarehouseReset();
          },
          onCompleted() {
            // Start polling for connection status to the data warehouse
            startPeriodicStatusCheck({
              context: {
                accountId: account.id,
              },
              onStatusCheckCompleted: () => {
                // Data warehouse is now connected
                resolve();
                optionsRef.current.onCompleted(account, integrationId);
                connectDatawarehouseReset();
              },
              onStatusCheckError: (error) => {
                // Data warehouse connection failed
                reject(error.message);
                optionsRef.current.onError(error, account, integrationId);
                connectDatawarehouseReset();
              },
            });
          },
        });
      });
    },
    [
      connectDatawarehouse,
      optionsRef,
      startPeriodicStatusCheck,
      connectDatawarehouseReset,
    ],
  );

  return [
    connect,
    {
      isConnecting: connectDatawarehouseCalled,
      isConnected: isConnectionEstablished,
    },
  ];
}
