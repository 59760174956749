import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { ComponentProps } from "@tw-classed/react";
import { ReactComponent as EdIcon } from "@/assets/ed.svg";
import { IconButton } from "@/components/elements/Button";
import { List } from "@/components/elements/List";
import { Skeleton } from "@/components/elements/Skeleton";
import cn from "@/helpers/classNames";
import { useRef } from "react";

import {
  PromptSuggestion,
  usePromptSuggestionsFromSync,
} from "../usePromptSuggestionsFromSync";

function PromptListItemSkeletonLoader() {
  const variants = ["w-5/6", "w-3/4", "w-1/2"];
  const variantRef = useRef<string>();
  if (variantRef.current == null) {
    variantRef.current = variants[Math.floor(Math.random() * variants.length)];
  }
  return (
    <List.Item as="div" className="flex items-center gap-4">
      <Skeleton className="h-4 w-4" />
      <div className="flex h-5 w-full items-center">
        <Skeleton className={cn("h-3", variantRef.current)} />
      </div>
    </List.Item>
  );
}

function PromptListItem(
  props: {
    prompt: {
      prompt: string;
      description: string;
      dependencies: string[];
    };
  } & ComponentProps<typeof List.ItemButton>,
) {
  const { prompt, ...itemProps } = props;
  return (
    <List.ItemButton
      {...itemProps}
      className={cn(
        "flex w-full items-center gap-2 text-left",
        itemProps.className,
      )}
    >
      <List.ItemIcon>
        <EdIcon className="h-4 w-4" />
      </List.ItemIcon>
      <List.Text className="line-clamp-2">{prompt.description}</List.Text>
      <IconButton
        icon={<ArrowRightIcon className="h-4 w-4" />}
        size="xs"
        variant="solid"
        colorScheme="primary"
        as={"div" as any}
        className="ml-auto"
      />
    </List.ItemButton>
  );
}

export function PromptSuggestionsList(props: {
  prompts: ReturnType<typeof usePromptSuggestionsFromSync>["promptSuggestions"];
  onClickSuggestion: (prompt: PromptSuggestion) => void;
}) {
  return (
    <List className="divide-y p-0 text-sm">
      {props.prompts.map((prompt) => (
        <li key={prompt.key}>
          {prompt.isLoading && <PromptListItemSkeletonLoader />}
          {prompt.value !== null && (
            <PromptListItem
              prompt={prompt.value}
              onClick={() => {
                if (prompt.value == null) return;
                props.onClickSuggestion(prompt.value);
              }}
            />
          )}
        </li>
      ))}
    </List>
  );
}
