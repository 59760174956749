import { push, ref } from "firebase/database";
import { useMemo, useState } from "react";

import { ListModelsQuery } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import { RadioButtonCard } from "@/components/elements/ButtonCard";
import { BetaFeatureText } from "@/components/elements/FeatureStateText";
import Select from "@/components/elements/Select";
import {
  SlideOver,
  SlideOverBody,
  SlideOverCloseButton,
  SlideOverFooter,
  SlideOverHeader,
} from "@/components/elements/SlideOver";
import ModelOptionsSelecter from "@/components/modules/ModelOptionsSelecter";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";
import FieldLabel from "@/components/primitives/InputLabel";
import { Input } from "@/components/primitives/input";
import { useFirebaseDatabase } from "@/features/firebase";
import classNames from "@/helpers/classNames";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useSaveUnpublishedModel } from "@/pages/ModelTool/hooks/usePublishModel";
import { useCurrentAccount } from "@/providers/account";
import { ChartBarSquareIcon } from "@heroicons/react/24/solid";

import { Tile, VisualizationType } from "../VisualizationType";
import { useDashboards } from "../useDashboards";
import { PreviewEditedtileVisualization } from "./PreviewEditedtileVisualization";
import { SelectVisualizationType } from "./SelectVisualizationType";
import { useTileColumnOptions } from "./useTileColumnOptions";
import { useValidateTile } from "./useValidateTile";

type Props = {
  show: boolean;
  onClose: (state: boolean) => void;
  dashboardId?: string;
  modelName?: string;
  modelId?: string;
};

const defaultTile: Tile = {
  i: "new",
  x: 0,
  y: 9999,
  w: 10,
  h: 5,
  name: "",
  visualization: { type: VisualizationType.Table },
};

export const AddNewVisualization = (props: Props) => {
  return (
    <>
      <Button
        colorScheme="primary"
        size="sm"
        onClick={() => {
          props.onClose(true);
        }}
        icon={<ChartBarSquareIcon className="h-4 w-4" />}
      >
        Add visualization
      </Button>
      <AddVisualizationSidebar {...props} />
    </>
  );
};

export const AddVisualizationSidebar = (
  props: Props & { usePreviewData?: boolean },
) => {
  const database = useFirebaseDatabase();
  const account = useCurrentAccount();

  const [newTile, setNewTile] = useState<Tile>({
    ...defaultTile,
    name: props.modelName?.replaceAll("_", " ") ?? "",
    modelId: props.modelId,
  });

  const tileValid = useValidateTile(newTile);

  const [newModel, setNewModel] = useState(false);

  const openModelInTab = useOpenModelInTab();

  const { columnOptions } = useTileColumnOptions(newTile, props.usePreviewData);

  const [dashboardId, setDashboardId] = useState(props.dashboardId);

  const mixpanel = useMixpanel();

  const tilesDoc = useMemo(() => {
    if (!account.id) return null;
    if (dashboardId)
      return ref(
        database,
        `workspaces/${account.id}/dashboards/${dashboardId}/tiles`,
      );

    if (props.modelId)
      return ref(
        database,
        `workspaces/${account.id}/models/${props.modelId}/tiles`,
      );

    return null;
  }, [account.id, dashboardId, database, props.modelId]);

  const { createDraftModel, loading } = useSaveUnpublishedModel();

  const handleAddTile = async () => {
    if (
      !tilesDoc ||
      !account.dataWarehouseConnectionId ||
      loading ||
      (!dashboardId && !props.modelId)
    )
      return;
    let modelId = newTile.modelId;

    if (!modelId) {
      //todo: add new model creation here...
      const res = await createDraftModel({
        variables: {
          name: newTile.name,
          weldSql: "",
          sourceConnectionId: account.dataWarehouseConnectionId,
          dashboardId: dashboardId,
        },
      });
      modelId = res.data?.createDraftModel?.id;
    }

    //add the new tile to FB without the i (which will be added by firebase)
    const { i, ...newTileProperties } = newTile;
    const newTileRef = await push(tilesDoc, {
      ...newTileProperties,
      modelId,
    });

    if (newModel) {
      const tileId = newTileRef.key ?? undefined;
      openModelInTab({
        modelId: modelId!,
        type: "model",
        dashboardId,
        tileId,
      });
    }

    onFinished();

    mixpanel.track("New Dashboard Tile Added", {
      dashboardId: props.dashboardId,
      tileName: newTile.name,
      modelId: newTile.modelId,
    });
  };

  const handleSelectModel = (model: ListModelsQuery["models"][0]) => {
    setNewTile((p) => {
      return {
        ...p,
        modelId: model.id,
        name: p.name ?? model.name,
      };
    });
  };

  const onFinished = () => {
    props.onClose(false);
    setNewTile({
      x: 0,
      y: 9999,
      w: 10,
      h: 5,
      name: props.modelName?.replaceAll("_", " ") ?? "",
      modelId: props.modelId,
      visualization: { type: VisualizationType.Table },
      i: "new",
    });
  };

  return (
    <SlideOver
      show={props.show}
      onClose={onFinished}
      bgOverlay={!!newTile.modelId}
    >
      <SlideOverCloseButton />
      <SlideOverHeader className="flex items-center space-x-1 p-4">
        <span> Add new visualization</span> <BetaFeatureText />
      </SlideOverHeader>
      <SlideOverBody>
        <div className="flex flex-col space-y-4">
          {!props.modelId && (
            <div>
              <FieldLabel>Base visualization on</FieldLabel>
              <VisualizeSourceType
                setNewModel={(isNew) => {
                  setNewModel(isNew);
                  if (isNew) {
                    setNewTile((p) => {
                      return { ...p, modelId: undefined };
                    });
                  }
                }}
                newModel={newModel}
              />
            </div>
          )}
          {!newModel && !props.modelId && (
            <div className="">
              <FieldLabel>Select model</FieldLabel>
              <ModelOptionsSelecter
                onSelect={handleSelectModel}
                selectedModelId={newTile.modelId}
              />
            </div>
          )}
          <div>
            <FieldLabel>Tile Name</FieldLabel>
            <Input
              value={newTile.name}
              onChange={(e) => {
                setNewTile((p) => {
                  return {
                    ...p,
                    name: e.target.value,
                  };
                });
              }}
            />
          </div>
          {!props.dashboardId && (
            <div>
              <FieldLabel>Save to dashboard</FieldLabel>
              <DashboardOptionsSelecter
                onSelect={(dashboardId) => {
                  setDashboardId(dashboardId);
                }}
                selectedDashboardId={dashboardId}
              />
            </div>
          )}
          {newTile.modelId && (
            <SelectVisualizationType
              originalTile={defaultTile}
              columnOptions={columnOptions}
              setVisualization={(visualization) => {
                setNewTile((p) => {
                  return {
                    ...p,
                    visualization,
                  };
                });
              }}
              visualization={newTile.visualization}
            />
          )}
        </div>
      </SlideOverBody>
      <SlideOverFooter>
        <Button
          onClick={async () => {
            onFinished();
          }}
          variant="outline"
        >
          Cancel
        </Button>
        <Button
          colorScheme="primary"
          onClick={() => {
            handleAddTile();
          }}
          isLoading={loading}
          disabled={!tileValid}
        >
          Add tile
        </Button>
      </SlideOverFooter>

      <PreviewEditedtileVisualization
        tile={newTile}
        show={props.show && !!newTile.modelId}
        usePreviewData={props.usePreviewData}
      />
    </SlideOver>
  );
};

export const VisualizeSourceType = (props: {
  newModel: boolean;
  setNewModel: (newModel: boolean) => void;
}) => {
  return (
    <div className="flex space-x-2">
      <RadioButtonCard
        isChecked={!props.newModel}
        className={classNames("flex w-full shrink items-center p-10")}
        onClick={() => {
          props.setNewModel(false);
        }}
      >
        <div>Published model</div>
      </RadioButtonCard>
      <RadioButtonCard
        className={classNames("flex w-full shrink items-center p-10")}
        isChecked={props.newModel}
        onClick={() => {
          props.setNewModel(true);
        }}
      >
        <div>New model</div>
      </RadioButtonCard>
    </div>
  );
};

const DashboardOptionsSelecter = (props: {
  selectedDashboardId?: string;
  onSelect: (dashboardId: string) => void;
}) => {
  const dashboards = useDashboards();

  const dashboardOption = useMemo(() => {
    if (!dashboards) return [];
    return dashboards.map((d) => {
      return {
        id: d.id,
        label: d.name,
        value: d.id,
      };
    });
  }, [dashboards]);

  return (
    <Select
      options={dashboardOption}
      onChange={(option: (typeof dashboardOption)[0]) => {
        props.onSelect(option.value);
      }}
      isClearable={true}
      placeholder="Select dashboard (optional)"
      value={dashboardOption.find((o) => o.value === props.selectedDashboardId)}
    />
  );
};
