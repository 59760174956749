import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useMemo, useState } from "react";
import { CellProps, Column } from "react-table";

import {
  GetViewDetailsQuery,
  ListModelsQuery,
  useGetViewDetailsQuery,
} from "@/apollo/types";
import AppearTransition from "@/components/elements/AppearTransition";
import { Button, IconButton } from "@/components/elements/Button";
import DataSchemaTable from "@/components/elements/DataSchemaTable";
import { LoadingFull } from "@/components/elements/LoadingComponents";
import Tooltip from "@/components/elements/Tooltip";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import classNames from "@/helpers/classNames";
import useLoadingManager from "@/hooks/useLoadingManager";
import { useDWCaseSensitive } from "@/integrations/hooks/useDataWarehouseSpecifics";
import { useToast } from "@/providers/ToastProvider";
import { Transition } from "@headlessui/react";
import {
  CircleStackIcon,
  ClipboardIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { useSchemaSidebar } from "./ModelEditorStore";
import OpenButton from "./OpenModelButton";
import { useMonacoInstance } from "./QueryEditor/SQLEditor";
import { ModelEmojiPicker } from "./components/EmojiPicker";
import { useListPublishedModels } from "./hooks/useListModels";
import { useQueryRawData } from "./hooks/useRawDataActions";
import { useOpenLineageModal } from "./lineage/RawDataLineageModal";
import { useRawViews } from "./useRawViews";

export const SchemaSidebar = (props: { isMobileSizeViewport: boolean }) => {
  const [schemaSidebar, setSchemaSidebar] = useSchemaSidebar();
  return (
    <Transition
      show={!!schemaSidebar}
      appear
      as="aside"
      className={classNames(
        props.isMobileSizeViewport ? "fixed right-0 h-full" : "relative h-full",
        "z-10 h-full w-screen shrink-0 overflow-y-auto overflow-x-hidden border-l bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200",
      )}
      enter="duration-300"
      entered="max-w-sm"
      enterFrom="max-w-0"
      enterTo="max-w-sm"
      leave="duration-300"
      leaveFrom="max-w-sm"
      leaveTo="max-w-0"
    >
      <SchemaContent onClose={() => setSchemaSidebar(null)} />
    </Transition>
  );
};

export default SchemaSidebar;

const SchemaContent = (props: { onClose: () => void }) => {
  const viewDetails = useViewDetails();

  const [editor] = useMonacoInstance();
  const toast = useToast();

  const caseSensitive = useDWCaseSensitive();

  const openModelInTab = useOpenModelInTab();

  const viewLineage = useOpenLineageModal();

  const queryRawData = useQueryRawData();
  const { onOpen: viewSync } = useViewDataSourceSlideOver();

  const [query, setQuery] = useState("");

  const filteredCols = useMemo(
    () =>
      viewDetails.viewDetails?.dataSchema.filter((col) => {
        if (!query) return true;
        return col.name.toLowerCase().includes(query.toLowerCase());
      }),
    [query, viewDetails.viewDetails?.dataSchema],
  );

  const handleInsertColumns = useCallback(() => {
    if (!editor) return toast("Not inserted", "No editor found", "warning");
    if (!filteredCols?.length) return;

    const schema = filteredCols
      .map((column) => (caseSensitive ? `"${column.name}"` : column.name))
      .join(",\n");
    const model = editor?.getModel();
    if (model) {
      const range = editor?.getSelection();
      if (!range) return;
      // insert at cursor position
      model.pushEditOperations(
        [],
        [
          {
            range,
            text: `${schema}`,
          },
        ],
        () => null,
      );
      editor.focus();
      // select last line
      const lastLine = model.getLineCount();
      const lastColumn = model.getLineContent(lastLine).length + 1;
      editor.setSelection({
        startLineNumber: lastLine,
        startColumn: lastColumn,
        endLineNumber: lastLine,
        endColumn: lastColumn,
      });
    }
  }, [editor, toast, filteredCols, caseSensitive]);

  return (
    <>
      {useLoadingManager(
        {
          loading: viewDetails.loading,
          message: "Loading view",
          LoaderComponent: LoadingFull,
        },
        () => {
          const latestSyncTime =
            viewDetails.latestSyncTime && dayjs(viewDetails.latestSyncTime);

          return (
            <AppearTransition>
              <div className="h-full w-screen max-w-sm overflow-hidden bg-gray-50 px-6 py-6 dark:bg-gray-800">
                <div className="flex h-full flex-col">
                  {/* Header: */}
                  <div className="flex w-full shrink-0 items-center space-x-2 pb-4">
                    <Tooltip
                      content={
                        <div className="flex items-center space-x-1">
                          <ClipboardIcon className="h-3 w-3" />
                          <div>Click to copy SQL reference</div>
                        </div>
                      }
                    >
                      <button
                        onClick={() => {
                          if (!viewDetails.weldTag) return;
                          const reference = `{{${viewDetails.weldTag}}}`;
                          navigator.clipboard.writeText(reference);
                          toast(
                            "Copied reference to clipboard",
                            reference,
                            "success",
                            1500,
                          );
                        }}
                        className="flex grow items-center space-x-1 overflow-x-auto text-sm font-semibold"
                      >
                        <ViewDetailsIcon
                          isRawView={viewDetails.isRawView}
                          model={viewDetails.model}
                        />
                        <div>{viewDetails.weldTag}</div>
                      </button>
                    </Tooltip>
                    <IconButton
                      variant="ghost"
                      className="shrink-0"
                      size={"sm"}
                      icon={<XMarkIcon />}
                      onClick={props.onClose}
                    />
                  </div>

                  {!viewDetails.viewDetails && (
                    <div className="text-xs italic">Error loading schema.</div>
                  )}

                  {viewDetails.viewDetails && (
                    <div className="flex-grow space-y-4 overflow-auto">
                      {viewDetails.model && (
                        <div className="shrink-0">
                          <OpenButton
                            onClickMain={() => {
                              if (!viewDetails.model?.id) return;
                              openModelInTab({ modelId: viewDetails.model.id });
                            }}
                            text="Open in current tab"
                            options={[
                              {
                                text: "Open in new tab",
                                onClick: () => {
                                  if (!viewDetails.model?.id) return;
                                  openModelInTab({
                                    modelId: viewDetails.model?.id,
                                  });
                                },
                              },
                            ]}
                          />
                        </div>
                      )}
                      {!!viewDetails.rawView && (
                        <div className="flex items-center">
                          <OpenButton
                            onClickMain={() => {
                              if (!viewDetails.rawView) return;
                              queryRawData(viewDetails.rawView);
                            }}
                            text="Query data in editor"
                            options={[
                              {
                                text: "View data source",
                                onClick: () => {
                                  if (!viewDetails.rawView?.syncId) return;
                                  viewSync({
                                    syncId: viewDetails.rawView.syncId,
                                  });
                                },
                              },
                              {
                                text: "Open data lineage",
                                onClick: () => {
                                  if (!viewDetails.rawView?.viewId) return;
                                  viewLineage({
                                    focusedId: viewDetails.rawView.viewId,
                                    title: viewDetails.rawView.weldTag,
                                  });
                                },
                              },
                            ]}
                          />
                        </div>
                      )}

                      <div className="shrink-0 space-y-2">
                        {latestSyncTime && (
                          <Timestamp
                            label="Latest updated:"
                            date={latestSyncTime}
                          />
                        )}
                      </div>
                      <div className="space-y-2">
                        <input
                          className="block h-8 w-full shrink-0 rounded-sm border bg-white px-2 text-xs focus:outline-none dark:border-gray-700 dark:bg-gray-800"
                          placeholder="Filter columns..."
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                        />

                        <ViewSchemaTable dataSchema={filteredCols ?? []} />
                      </div>
                      {editor && !!filteredCols?.length && (
                        <div className="shrink-0">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={handleInsertColumns}
                          >
                            Insert columns in editor
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </AppearTransition>
          );
        },
      )}
    </>
  );
};

const Timestamp: React.FC<{ date: Dayjs; label: string }> = (props) => {
  return (
    <div className="flex items-center space-x-2">
      <div className="w-24 text-xs">{props.label}</div>
      <div className="text-xs">{props.date.format("HH:mm DD/MM YYYY")}</div>
    </div>
  );
};

type Row = GetViewDetailsQuery["getViewDetails"]["dataSchema"][0];

const ViewSchemaTable: React.FC<{
  dataSchema: GetViewDetailsQuery["getViewDetails"]["dataSchema"];
}> = ({ dataSchema }) => {
  const columns = React.useMemo<Column<Row>[]>(
    () => [
      {
        Header: "Column name",
        Cell: ({ row }: CellProps<Row>) => {
          return <div className="text-xs">{row.original.name}</div>;
        },
        accessor: "name",
      },
      {
        Header: "Type",
        Cell: ({ row }: CellProps<Row>) => {
          return <div className="truncate">{row.original.type}</div>;
        },
        accessor: "type",
      },
    ],
    [],
  );

  return <DataSchemaTable data={dataSchema} columns={columns} />;
};

const useViewDetails = () => {
  const [schemaSidebar] = useSchemaSidebar();

  const { rawViews } = useRawViews();
  const selectedRawView = useMemo(
    () => rawViews.find((rv) => rv.viewId === schemaSidebar),
    [schemaSidebar, rawViews],
  );

  const publishedModelsQuery = useListPublishedModels();
  const selectedModel = publishedModelsQuery.publishedModels.find(
    (m) => m.id === schemaSidebar,
  );

  const dwReference =
    selectedRawView || selectedModel?.dwSync || selectedModel?.dwTable;

  const viewDetailsQuery = useGetViewDetailsQuery({
    variables: {
      input: {
        path: dwReference?.path ?? [],
      },
    },
    skip: !schemaSidebar,
    fetchPolicy: "network-only",
  });

  return {
    viewDetails: viewDetailsQuery.data?.getViewDetails,
    createdAt: dwReference?.createdAt,
    latestSyncTime: dwReference?.latestSyncTime,
    weldTag: dwReference?.weldTag,
    isRawView: !!selectedRawView,
    model: selectedModel,
    rawView: selectedRawView,
    loading: viewDetailsQuery.loading || publishedModelsQuery.loadingModels,
  };
};

const ViewDetailsIcon = (props: {
  isRawView: boolean;
  model?: ListModelsQuery["models"][0];
}) => {
  if (props.isRawView)
    return <CircleStackIcon className="w-4 shrink-0 text-gray-500" />;
  if (props.model) return <ModelEmojiPicker model={props.model} />;
  return null;
};
