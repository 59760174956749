import { classed } from "@/components/classed.config";

export const Heading = classed.h2("text-base font-medium");

export const SectionHeading = classed.h3("font-medium text-sm");

export const SectionSubHeading = classed.h4(
  "font-medium text-xs text-muted-foreground",
);

export const TextMuted = classed.span("text-muted-foreground");
