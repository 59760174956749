export enum VisualizationType {
  Bar = "bar",
  Line = "line",
  Metric = "metric",
  Table = "table",
}

export type Tile = {
  x: number;
  y: number;
  w: number;
  h: number;
  modelId?: string;
  name: string;
  i: string;
  visualization: Visualization;
};

export type Visualization =
  | ChartVisualization
  | TableVisualization
  | MetricVisualization;

export type ChartVisualization = {
  type: VisualizationType.Bar | VisualizationType.Line;
  keys: {
    x: string;
    y: string[];
  };
};

export type MetricVisualization = {
  type: VisualizationType.Metric;
  metricColumn: string;
  // aggregationType: string;
  unit: string;
  unitPostition: "prefix" | "suffix";
};

type TableVisualization = {
  type: VisualizationType.Table;
};

export type Dashboard = {
  id: string;
  name: string;
  folderId?: string;
  createdAt: string;
  updatedAt: string;
  hidden?: boolean;
  tiles: { [key: string]: Tile };
};
