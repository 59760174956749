import { useAuth0 } from "@auth0/auth0-react";
import { SecondaryButton, UtilityButton } from "@/components/elements/Button";
import Center from "@/components/elements/layout/Center";
import { BlueOneEye2 } from "@/components/icons/graphic";
import SimplePageLayout from "@/layouts/SimplePageLayout";
import { usePersistUTMParamsTemporarily } from "@/pages/Setup/hooks/useUTMSourceTempStorage";

import PageContainer from "../../components/PageContainer";
import { Heading } from "../../components/Typography";

export default function EmailNotVerifiedPage() {
  const { logout } = useAuth0();

  const searchParams = new URLSearchParams(window.location.search);
  usePersistUTMParamsTemporarily(searchParams);

  const handleContinue = () => {
    window.location.href = window.location.origin + "?" + searchParams;
  };

  const handleCancel = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <SimplePageLayout>
      <Center className="h-full">
        <PageContainer size="sm">
          <BlueOneEye2 className="mb-5 w-12" />
          <Heading className="mb-6">Welcome friend</Heading>
          <div className="text-center text-sm leading-relaxed text-gray-600 dark:text-gray-300">
            <p>
              Before we continue setting up your account, we kindly ask you to{" "}
              <strong>verify your email</strong>.
            </p>
            <p className="mt-6">
              Check your email and click the verification link.
            </p>
          </div>
          <div className="mt-8 flex flex-col items-center space-y-8">
            <SecondaryButton onClick={handleContinue}>
              Already verified? continue here
            </SecondaryButton>
            <UtilityButton className="p-2" onClick={handleCancel}>
              Cancel
            </UtilityButton>
          </div>
        </PageContainer>
      </Center>
    </SimplePageLayout>
  );
}
