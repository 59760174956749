import {
  ConnectGraphicIcon,
  DangerGraphicIcon,
} from "@/components/icons/ContextualGraphicIcon";
import { useMountEffect } from "@/hooks/useMountEffect";
import { useRef } from "react";
import { createRoot } from "react-dom/client";

import { PrimaryButton, SecondaryButton } from "./Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "./Modal";

type DefaultProps = {
  title: string;
  message: React.ReactNode;
  type?: "info" | "danger";
  confirmButtonText?: string;
  initialFocus?: "confirm" | "cancel";
};

type ConfirmProps<Props = DefaultProps> = Props & {
  confirm: () => void;
  cancel: () => void;
};

function createConfirmable<T = DefaultProps>(
  Component: React.FC<ConfirmProps<T>>,
) {
  const existingRef = document.getElementById("confirm-modal");

  let ref: HTMLElement;

  if (existingRef === null) {
    // add to dom
    const div = document.createElement("div");
    div.id = "confirm-modal";
    document.body.appendChild(div);
    ref = div;
  } else {
    ref = existingRef;
  }

  return (props: T) =>
    new Promise<boolean>(async (resolve) => {
      const confirmRoot = createRoot(ref);
      confirmRoot.render(
        <Component
          {...props}
          confirm={() => {
            confirmRoot.unmount();
            resolve(true);
          }}
          cancel={() => {
            confirmRoot.unmount();
            resolve(false);
          }}
        />,
      );
    });
}

const ConfirmModal = (props: ConfirmProps) => {
  const confirmButtonText = props.confirmButtonText ?? "Confirm";

  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  useMountEffect(() => {
    if (props.initialFocus === "cancel") {
      cancelButtonRef.current?.focus();
    } else {
      confirmButtonRef.current?.focus();
    }
  });

  return (
    <Modal isOpen onClose={props.cancel}>
      <ModalHeader className="flex items-center gap-4">
        {props.type === "danger" ? (
          <DangerGraphicIcon aria-hidden="true" className="h-8 w-8" />
        ) : (
          <ConnectGraphicIcon aria-hidden="true" className="h-8 w-8" />
        )}
        <span>{props.title}</span>
      </ModalHeader>
      <ModalBody>{props.message}</ModalBody>
      <ModalFooter className="flex-row-reverse justify-start gap-4">
        <PrimaryButton
          ref={confirmButtonRef}
          colorScheme={props.type === "danger" ? "danger" : "primary"}
          onClick={props.confirm}
        >
          {confirmButtonText}
        </PrimaryButton>
        <SecondaryButton
          ref={cancelButtonRef}
          onClick={() => {
            props.cancel();
          }}
        >
          Cancel
        </SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

const confirm = createConfirmable(ConfirmModal);
export default confirm;

function AlertDialog(props: ConfirmProps) {
  const confirmButtonText = props.confirmButtonText ?? "OK";
  return (
    <Modal isOpen onClose={props.cancel}>
      <ModalHeader className="flex items-center gap-4">
        {props.type === "danger" ? (
          <DangerGraphicIcon aria-hidden="true" className="h-8 w-8" />
        ) : (
          <ConnectGraphicIcon aria-hidden="true" className="h-8 w-8" />
        )}
        <span>{props.title}</span>
      </ModalHeader>
      <ModalBody>{props.message}</ModalBody>
      <ModalFooter>
        <PrimaryButton
          onClick={() => {
            props.confirm();
          }}
        >
          {confirmButtonText}
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  );
}

export const alert = createConfirmable(AlertDialog);
