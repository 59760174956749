import {
  Auth,
  inMemoryPersistence,
  signInWithCustomToken,
} from "@firebase/auth";
import * as Sentry from "@sentry/react";
import { useFirebaseTokenLazyQuery } from "@/apollo/types";
import { useCallback } from "react";

export function useSignInWithToken() {
  const [fetchFirebaseToken] = useFirebaseTokenLazyQuery();

  return useCallback(
    async (auth: Auth) => {
      const resp = await fetchFirebaseToken({
        fetchPolicy: "no-cache",
      });
      const token = resp.data?.firebaseToken;
      if (token == null) {
        Sentry.captureException(
          resp.error ??
            new Error("Firebase Token not received - no error response"),
        );
        return;
      }
      await auth.setPersistence(inMemoryPersistence);
      await signInWithCustomToken(auth, token);
    },
    [fetchFirebaseToken],
  );
}
