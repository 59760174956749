import { ReactNode, createContext, useContext, useState } from "react";

import {
  useResolveCountryFromTimeZoneLazyQuery,
  useStripeSubscriptionLazyQuery,
} from "@/apollo/types";
import { useMountEffect } from "@/hooks/useMountEffect";
import { useCurrentAccount } from "@/providers/account";

import { getCurrencyFromCountry } from "../currency/currency-helpers";

type LocaleState = {
  currency: string;
  countryCode: string;
};

export const LocaleContext = createContext<LocaleState | null>(null);

export function useLocaleContext() {
  const ctx = useContext(LocaleContext);
  if (ctx === null) {
    throw new Error("LocaleProvider not found");
  }
  return ctx;
}

export function LocaleProvider(props: {
  defaultValues?: LocaleState | null;
  children: ReactNode;
}) {
  const [state, setState] = useState<LocaleState | null>(() => {
    if (props.defaultValues != null) {
      return props.defaultValues;
    }
    return null;
  });

  const account = useCurrentAccount();

  const [fetchStripeSubscription] = useStripeSubscriptionLazyQuery({
    variables: { slug: account.slug },
  });
  const [resolveCountry] = useResolveCountryFromTimeZoneLazyQuery();

  useMountEffect(async () => {
    if (state?.currency != null) {
      return;
    }

    const [stripeSubscriptionData, countryFromTimeZoneData] = await Promise.all(
      [
        fetchStripeSubscription(),
        resolveCountry({
          variables: {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        }),
      ],
    );

    let defaultLocaleData = {
      countryCode: "US",
      currency: "usd",
    };

    if (countryFromTimeZoneData.data?.resolveCountryFromTimeZone.country) {
      const countryCode =
        countryFromTimeZoneData.data?.resolveCountryFromTimeZone.country.code;
      defaultLocaleData.countryCode = countryCode;
      defaultLocaleData.currency = getCurrencyFromCountry(countryCode);
    }

    if (stripeSubscriptionData.data?.accountBySlug.stripeCustomer?.currency) {
      const { currency, address } =
        stripeSubscriptionData.data?.accountBySlug.stripeCustomer;
      setState({
        currency,
        countryCode: address?.country ?? defaultLocaleData.countryCode,
      });
      return;
    }

    setState({
      currency: defaultLocaleData.currency,
      countryCode: defaultLocaleData.countryCode,
    });
  });

  if (state == null) {
    return null;
  }

  return (
    <LocaleContext.Provider value={state}>
      {props.children}
    </LocaleContext.Provider>
  );
}
