import dayjs from "dayjs";
import { useFirebaseDatabase } from "@/features/firebase";
import { get, push, ref, remove, update } from "firebase/database";
import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCurrentAccount } from "@/providers/account";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useListVals, useObjectVal } from "react-firebase-hooks/database";

import { useEditorState } from "../EditorStore";
import { Dashboard, Tile, VisualizationType } from "./VisualizationType";

export const useCreateDashboard = () => {
  const docs = useDashboardDocs();

  const createDashboard = useCallback(
    (name: string, folderId: string) => {
      const timeStamp = dayjs().toISOString();

      const newDashboard: Omit<Dashboard, "id"> = {
        name: name,
        folderId: folderId,
        createdAt: timeStamp,
        updatedAt: timeStamp,
        tiles: {},
      };

      const doc = docs.getDashboards();
      const reference = push(doc, newDashboard);

      const savedDashboard: Dashboard = {
        ...newDashboard,
        id: reference.key ?? "",
      };
      return savedDashboard;
    },
    [docs],
  );

  return createDashboard;
};

export const useDeleteDashboard = () => {
  const docs = useDashboardDocs();
  const deleteDashboard = useCallback(
    async (dashboardId: string) => {
      const doc = docs.getDashboard(dashboardId);
      await remove(doc);
    },
    [docs],
  );
  return deleteDashboard;
};

export const useRenameDashboard = () => {
  const docs = useDashboardDocs();
  const renameDashboard = useCallback(
    async (dashboardId: string, newName: string) => {
      const doc = docs.getDashboard(dashboardId);
      const updateFields: Partial<Dashboard> = {
        name: newName,
        updatedAt: dayjs().toISOString(),
      };
      await update(doc, updateFields);
    },
    [docs],
  );
  return renameDashboard;
};

export const useInitializeDashboard = (dashboard?: Dashboard) => {
  const [, dispatch] = useEditorState();

  const initialize = useEffect(() => {
    if (!dashboard) return;
    dispatch({
      type: "initialize",
      payload: {
        currentItemId: dashboard.id,
        currentItemName: dashboard.name,
        currentEditorState: "dashboard",
      },
    });
  }, [dispatch, dashboard]);

  return initialize;
};

const DEFAULT_LIST: any[] = [];

export const useDashboards = () => {
  const { visualize: enableVisualize } = useFlags();
  const docs = useDashboardDocs();
  const [values] = useListVals<Dashboard>(docs.getDashboards(), {
    keyField: "id",
  });
  return (enableVisualize && values) || (DEFAULT_LIST as Dashboard[]);
};

export const useDashboard = (
  dashboardId?: string,
  loadedCallback?: {
    onLoaded?: (dashboard: Dashboard) => void;
    onNotFound?: (dashboardId: string) => void;
  },
) => {
  const onLoadedRef = useLatestValueRef(loadedCallback?.onLoaded);
  const onNotFoundRef = useLatestValueRef(loadedCallback?.onNotFound);
  const onLoadCallbacksCalledRef = useRef(false);

  useEffect(() => {
    onLoadCallbacksCalledRef.current = false;
  }, [dashboardId]);

  const docs = useDashboardDocs();
  const [value, loading, error] = useObjectVal<Dashboard | null>(
    dashboardId ? docs.getDashboard(dashboardId) : null,
    {
      keyField: "id",
      transform: useCallback((snapshot: any) => {
        if (snapshot == null) {
          return null;
        }
        const dashboard = snapshot as Dashboard;
        return {
          ...dashboard,
          //Todo: remove this check after migration
          tiles:
            dashboard.tiles == null
              ? {}
              : Object.entries(dashboard.tiles).reduce<
                  (typeof dashboard)["tiles"]
                >((acc, [tileId, tile]) => {
                  if (tile.w === 0 && tile.h === 0) {
                    return acc;
                  }
                  if (
                    tile.visualization != null &&
                    (tile.visualization.type === VisualizationType.Bar ||
                      tile.visualization.type === VisualizationType.Line) &&
                    !Array.isArray(tile.visualization.keys.y)
                  ) {
                    tile.visualization.keys.y = [tile.visualization.keys.y];
                  }
                  tile.name = tile.name ?? ""; //todo: remove unnecessary check after migration
                  acc[tileId] = tile;
                  return acc;
                }, {}),
        };
      }, []),
    },
  );

  useEffect(() => {
    if (onLoadCallbacksCalledRef.current) return;
    if (loading) return;
    if (value != null) {
      onLoadedRef.current?.(value);
      onLoadCallbacksCalledRef.current = true;
      return;
    }
    if (dashboardId && (value === null || error != null)) {
      onNotFoundRef.current?.(dashboardId);
      onLoadCallbacksCalledRef.current = true;
    }
  }, [loading, value, error, onLoadedRef, onNotFoundRef, dashboardId]);
  return { dashboard: value, loading };
};

export const useDashboardTile = (dashboardId: string, tileId: string) => {
  const docs = useDashboardDocs();
  const [tile] = useObjectVal<Tile>(
    docs.getDashboardTile(dashboardId, tileId),
    {
      keyField: "i",
    },
  );
  return tile;
};

export function useGetDashboardTile() {
  const docs = useDashboardDocs();
  return useCallback(
    async (dashboardId: string, tileId: string) => {
      const snapshot = await get(docs.getDashboardTile(dashboardId, tileId));
      if (snapshot.exists()) {
        const tile = snapshot.val() as Tile;
        return {
          ...tile,
          i: tileId,
        };
      }
      return null;
    },
    [docs],
  );
}

export const useUpdateDashboardTile = () => {
  const docs = useDashboardDocs();
  const updateTile = useCallback(
    async (dashboardId: string, tileId: string, updates: Partial<Tile>) => {
      const tileDoc = docs.getDashboardTile(dashboardId, tileId);
      await update(tileDoc, updates);
    },
    [docs],
  );
  return updateTile;
};

function useDashboardDocs() {
  const database = useFirebaseDatabase();
  const account = useCurrentAccount();

  return useMemo(() => {
    return {
      getDashboards: () => ref(database, `workspaces/${account.id}/dashboards`),
      getDashboard: (dashboardId: string) =>
        ref(database, `workspaces/${account.id}/dashboards/${dashboardId}`),
      getDashboardTile: (dashboardId: string, tileId: string) =>
        ref(
          database,
          `workspaces/${account.id}/dashboards/${dashboardId}/tiles/${tileId}`,
        ),
    };
  }, [database, account]);
}
