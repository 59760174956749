import { Button } from "@/components/elements/Button";
import classNames from "@/helpers/classNames";
import React from "react";

export function PaneEmptyState(props: React.ComponentProps<"div">) {
  return <div className="flex flex-col items-center gap-4 p-4" {...props} />;
}

export function PaneEmptyStateText(props: React.ComponentProps<"span">) {
  return <span className="text-center text-xs leading-relaxed" {...props} />;
}

export function PaneEmptyStateButton(
  props: React.ComponentProps<typeof Button>,
) {
  return (
    <Button
      variant="outline"
      colorScheme="secondary"
      {...props}
      className={classNames("max-w-full", props.className)}
    />
  );
}
