import { SVGProps } from "react";

export default function WeldIconSmall(props: SVGProps<any>) {
  return (
    <svg fill="currentColor" viewBox="0 0 28 27" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3058 26.8985C16.7607 26.8981 17.1639 26.6068 17.3041 26.1771L18.6792 21.9638C18.8194 21.534 18.665 21.0634 18.2968 20.7981L14.6864 18.1976C14.3182 17.9322 13.8197 17.9327 13.4517 18.1985L9.8454 20.8044C9.4775 21.0703 9.3239 21.5412 9.4648 21.9708L10.8463 26.1819C10.9872 26.6115 11.3906 26.9021 11.8456 26.9018L16.3058 26.8985Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4903 0.1333C16.9453 0.13277 17.349 0.42322 17.4901 0.85276L18.8734 5.0634C19.0145 5.4929 18.861 5.9638 18.4932 6.2297L14.888 8.8373C14.5202 9.1033 14.0217 9.1039 13.6533 8.8388L10.042 6.2398C9.6735 5.9746 9.5189 5.504 9.659 5.0742L11.0323 0.86036C11.1723 0.43049 11.5752 0.13908 12.0302 0.13854L16.4903 0.1333Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0956 10.2314C27.5284 10.3711 27.8214 10.7715 27.8214 11.2233V15.6523C27.8214 16.1041 27.5284 16.5045 27.0956 16.6442L22.8538 18.0128C22.4211 18.1524 21.947 17.9995 21.6796 17.6339L19.0581 14.0509C18.7905 13.6853 18.7905 13.1903 19.0581 12.8248L21.6796 9.2417C21.947 8.8762 22.4211 8.7232 22.8538 8.8628L27.0956 10.2314Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.58853 17.0608C1.15576 16.9212 0.862789 16.5207 0.862789 16.0689V11.6399C0.862789 11.1882 1.15576 10.7877 1.58853 10.6481L5.8303 9.2795C6.2631 9.1398 6.7371 9.2928 7.0045 9.6583L9.6262 13.2414C9.8936 13.607 9.8936 14.1019 9.6262 14.4675L7.0045 18.0506C6.7371 18.4161 6.2631 18.569 5.8303 18.4294L1.58853 17.0608Z"
      />
    </svg>
  );
}
