import { useMatches } from "@tanstack/react-location";
import { useIntegrationsMap } from "@/integrations";
import { capitalize } from "lodash";
import { invert } from "lodash";
import { useOrchestrationWorkflow } from "@/pages/Orchestration/components/useWorkflow";
import { useCallback } from "react";
import { LocationGenerics } from "routes";

export default function useConvertBreadcrumb() {
  const integrations = useIntegrationsMap();
  const matches = useMatches();
  const match = matches[matches.length - 1];
  const { workflow } = useOrchestrationWorkflow(
    match.params.workflowId ?? undefined,
    false,
  );

  const convertBreadcrumb = useCallback(
    (value: string) => {
      const paramKey =
        (invert(match.params)[value] as keyof LocationGenerics["Params"]) ??
        null;
      switch (paramKey) {
        case "integrationId":
          return integrations.get(value)?.name || value;
        case "syncId":
          return "View sync";
        case "modelId":
          return "View model";
        case "connectionId":
          return "View connector";
        case "userId":
          return "Edit user";
        case "workflowId":
          return workflow?.name ?? "View orchestration";
        default:
          return capitalize(value.replace(/-/g, " "));
      }
    },
    [integrations, match.params, workflow?.name],
  );
  return convertBreadcrumb;
}
