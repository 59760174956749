import { ListModelsQuery } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import Select from "@/components/elements/Select";
import { useCreateDraftModel } from "@/pages/ModelTool/QueryEditor/useModelDraft";
import ModelSVGIcon from "@/pages/ModelTool/components/ModelIcon/ModelSVGIcon";
// import { useCreateDraftModel } from "@/pages/ModelTool/QueryEditor/useModelDraft";
import useModelOptions from "@/pages/ReverseEltSyncs/hooks/useModelOptions";
import { useCurrentAccount } from "@/providers/account";
// import { useNavigate } from "@tanstack/react-location";
import { useNavigate } from "@tanstack/react-location";
// import { useCurrentAccount } from "@/providers/account";

type Props = {
  onSelect: (model: ListModelsQuery["models"][0]) => void;
  selectedModelId?: string;
};

const ModelOptionsSelecter = (props: Props) => {
  const navigate = useNavigate();
  const createDraft = useCreateDraftModel();
  const { slug } = useCurrentAccount();

  const {
    publishedModelsOptions,
    modelsLoading: queriesLoading,
    currentQuery,
  } = useModelOptions(props.selectedModelId);

  const onCreateModel = () => {
    const draft = createDraft();
    navigate({ to: `/${slug}/editor/draft/${draft.id}` });
  };

  return (
    <>
      {(publishedModelsOptions.length > 0 || queriesLoading) && (
        <Select
          isLoading={queriesLoading}
          placeholder="Select a published model..."
          value={currentQuery}
          onChange={(option: (typeof publishedModelsOptions)[0]) =>
            props.onSelect(option.model)
          }
          labelKey="name"
          options={publishedModelsOptions}
          autoSelectOnSingleOption
          isClearable={false}
          iconParser={(option: (typeof publishedModelsOptions)[0]) => {
            return (
              <ModelSVGIcon
                className="h-4 w-4 dark:text-white"
                type={option.type}
              />
            );
          }}
        />
      )}
      {!queriesLoading && publishedModelsOptions.length === 0 && (
        <div className="flex w-full flex-col gap-4 rounded-md border border-dotted border-gray-400 p-6 dark:border-gray-500">
          <p className="text-center text-sm text-muted-foreground">
            You have no published models to select from
          </p>

          <Button
            colorScheme="primary"
            className="w-full"
            onClick={() => onCreateModel()}
          >
            Create a model
          </Button>
        </div>
      )}
    </>
  );
};

export default ModelOptionsSelecter;
