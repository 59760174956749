import { ComponentProps } from "react";

import {
  CurrentUserWithAccountsDocument,
  useDisconnectSlackNotificationsMutation,
} from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import confirm from "@/components/elements/Confirm";
import SlackIcon from "@/components/icons/SlackIcon";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

import { SlackSetupSlideOver } from "./SlackSetupSlideOver";

const SlackNotificationSetup = () => {
  const toast = useToast();
  const currentAccount = useCurrentAccount();
  const slackNotificationInfo = currentAccount.slackNotificationInfo;

  const [disconnectSlack] = useDisconnectSlackNotificationsMutation({
    onCompleted() {
      toast(
        "Success",
        "You have successfully disconnected Slack notifications",
        "success",
      );
    },
    onError(err) {
      toast(
        "Error while disconnecting Slack notifications",
        err.message,
        "error",
      );
    },
    refetchQueries: [CurrentUserWithAccountsDocument],
  });

  async function disconnectChannel(channelName: string) {
    const confirmed = await confirm({
      title: "Disconnecting Slack channel",
      message: `You will stop receiving notifications in your ${channelName} Slack channel. Are you sure you want to disconnect?`,
      type: "danger",
    });

    if (confirmed) {
      disconnectSlack();
    }
  }

  const slideOverDisclosure = useDisclosure();
  return (
    <>
      <SlackSetupSlideOver
        show={slideOverDisclosure.isOpen}
        onClose={slideOverDisclosure.onClose}
      />

      {slackNotificationInfo ? (
        <div className="flex flex-col items-start gap-1">
          <div className="flex gap-2">
            <CheckCircleIcon className="h-5 text-green-500" />
            <div className="text-sm">
              Connected to{" "}
              <em className="font-medium">
                #{slackNotificationInfo.channelName}
              </em>
            </div>
          </div>
          <Button
            onClick={() => {
              disconnectChannel(slackNotificationInfo.channelName);
            }}
            size="sm"
            variant="link"
            className="text-muted-foreground"
          >
            Disconnect Slack channel
          </Button>
        </div>
      ) : (
        <SlackButton
          onClick={() => {
            slideOverDisclosure.onOpen();
          }}
        >
          Connect to Slack
        </SlackButton>
      )}
    </>
  );
};

const SlackButton = (props: ComponentProps<typeof Button>) => {
  return <Button {...props} icon={<SlackIcon />} variant="outline" />;
};

export default SlackNotificationSetup;
