import * as parser from "cron-parser";
import cronstrue from "cronstrue";
import { useMemo } from "react";

export const useValidateCron = (cron?: string) => {
  const cronValid = useMemo(() => {
    // check if cron is valid
    if (!cron) return false;
    try {
      parser.parseExpression(cron);
      return true;
    } catch (e) {
      return false;
    }
  }, [cron]);
  return cronValid;
};

export const useDisplayCron = (cron?: string) => {
  const { cronDisplay, cronDisplayVerbose, cronValid } = useMemo(() => {
    if (!cron)
      return {
        cronDisplay: "Not scheduled",
        cronDisplayVerbose: "Not scheduled",
        cronValid: false,
      };

    try {
      parser.parseExpression(cron);
      return {
        cronDisplay: `${cronstrue.toString(cron, {
          use24HourTimeFormat: true,
        })} (UTC)`,
        cronDisplayVerbose: `${cronstrue.toString(cron, {
          use24HourTimeFormat: true,
          verbose: true,
        })} (UTC)`,
        cronValid: true,
      };
    } catch (error) {
      return {
        cronDisplay: "Invalid cron expression",
        cronDisplayVerbose:
          error instanceof Error
            ? `Error: ${error.message}`
            : typeof error === "string"
              ? error
              : "Invalid cron expression",
        cronValid: false,
      };
    }
  }, [cron]);
  return { cronDisplay, cronDisplayVerbose, cronValid };
};
