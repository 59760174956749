import useConnectionSettings from "@/hooks/useConnectionSettings";
import { useCallback, useMemo, useState } from "react";

import { useSyncContext } from "../modules/SyncContext";
import useOperations from "./useOperations";

export default function useSyncValidated() {
  const [state] = useSyncContext();

  const { currentOperation } = useOperations();
  const connectionSettings = useConnectionSettings(
    state.destinationId,
    "Activate",
  );

  const primaryMapping = useMemo(
    () => state.mapping.find((item) => item.isPrimaryMapping),
    [state.mapping],
  );

  const [connectionSettingsValidated, setConnectionSettingsValidated] =
    useState(true);
  const handleChangeConnectionSettingsValid = useCallback(
    (isValid: boolean) => setConnectionSettingsValidated(isValid),
    [],
  );

  const settingsValidated = useMemo(
    () => !connectionSettings.value || connectionSettingsValidated,
    [connectionSettingsValidated, connectionSettings.value],
  );

  const mappingValidated = useMemo(() => {
    const withoutPrimaryMapping = state.mapping.filter(
      (item) => !item.isPrimaryMapping,
    );

    const hasAtLeastOneValidMapping =
      withoutPrimaryMapping[0]?.sourcePropertyId &&
      withoutPrimaryMapping[0]?.destinationPropertyId;
    if (!hasAtLeastOneValidMapping) return false;

    const hasMappingsWithOneMissingProperty = withoutPrimaryMapping.some(
      ({ sourcePropertyId, destinationPropertyId }) =>
        (sourcePropertyId || destinationPropertyId) &&
        !(sourcePropertyId && destinationPropertyId),
    );
    if (hasMappingsWithOneMissingProperty) return false;

    return true;
  }, [state.mapping]);

  const primaryMappingValidated = useMemo(() => {
    if (!currentOperation?.primaryKeyRequired) return true;

    const primaryMappingValid =
      primaryMapping?.sourcePropertyId && primaryMapping?.destinationPropertyId;
    if (!primaryMappingValid) return false;

    return true;
  }, [
    currentOperation,
    primaryMapping?.destinationPropertyId,
    primaryMapping?.sourcePropertyId,
  ]);

  const validated = useMemo(() => {
    if (!mappingValidated) return false;
    if (!primaryMappingValidated) return false;
    if (!settingsValidated) return false;
    return true;
  }, [mappingValidated, primaryMappingValidated, settingsValidated]);

  return {
    syncValidated: validated,
    settingsValidated,
    handleChangeConnectionSettingsValid,
  };
}
