import { ComponentProps } from "react";
import {
  FieldValues,
  UseFormProps,
  useForm,
  useFormState,
} from "react-hook-form";

import { ActionButton, Button } from "@/components/elements/Button";
import FormFactory from "@/components/modules/FormFactory/FormFactory";
import FormTextInput from "@/components/modules/FormFactory/FormTextInput";
import { IntegrationType, isCustomConnector } from "@/integrations";
import { ShieldCheckIcon } from "@heroicons/react/24/outline";

import { ReactComponent as GoogleIcon } from "./google.svg";

type FormModel = FieldValues & { label: string };

export function generateLabel(input: string) {
  return (
    input
      .toLowerCase()
      .trim()
      // Replace all whitespace with a single underscore
      .replace(/\s+/g, "_")
      // Replace hyphens (-) with underscores (_)
      .replace(/-/g, "_")
      // Remove all non-word chars
      .replace(/[^\w]+/g, "")
      // Replace any consecutive underscores (__) with a single underscore (_)
      .replace(/__+/g, "_")
      // Replace leading underscores
      .replace(/^_/, "")
      // Replace trailing underscores
      .replace(/_$/g, "")
  );
}

export function useConnectionForm(
  integration: IntegrationType,
  options: UseFormProps<FormModel> = {},
) {
  let defaultValues = {
    ...integration.form.defaultValues,
    label: isCustomConnector(integration.id)
      ? generateLabel(integration.name)
      : generateLabel(integration.id),
  };
  if (
    options.defaultValues !== undefined &&
    typeof options.defaultValues !== "function"
  ) {
    defaultValues = {
      ...defaultValues,
      ...options.defaultValues,
    };
  }
  return useForm<FormModel>({
    mode: "onChange",
    ...options,
    defaultValues,
  });
}

type Props = {
  integration: IntegrationType;
  isEditMode?: boolean;
  isLoading: boolean;
};

export default function ConnectionForm({
  integration,
  isEditMode = false,
}: Props) {
  function removeNonEditableFields(
    form: IntegrationType["form"],
  ): IntegrationType["form"] {
    return {
      ...form,
      fields: form.fields
        .map((f) => {
          if (f.type === "sub_form") {
            return removeNonEditableFields(f);
          }

          return f;
        })
        .filter((f) => "editable" in f && f.editable),
    };
  }

  return (
    <>
      {!isEditMode && (
        <FormTextInput
          field={{
            label: "Name",
            required: true,
            name: "label",
            type: "text",
            description:
              "This is the name of the connection which can be used to set up both source and export syncs",
          }}
        />
      )}
      <FormFactory
        form={
          !isEditMode
            ? integration.form
            : removeNonEditableFields(integration.form)
        }
      />
    </>
  );
}

export function ConnectionFormSubmitButton(
  props: ComponentProps<typeof Button> & {
    integration: IntegrationType;
    isLoading: boolean;
    isEditMode?: boolean;
    isDisabled?: boolean;
  },
) {
  const {
    isEditMode = false,
    isLoading,
    integration,
    isDisabled: isDisabledProp,
    ...buttonProps
  } = props;
  const { isValid } = useFormState();
  const isDisabled = !isValid || isLoading || props.isDisabled || false;

  function getSubmitButtonText(isEditMode: boolean, isLoading: boolean) {
    if (isLoading) {
      return isEditMode ? "Updating..." : "Loading...";
    }
    return isEditMode ? "Update" : "Connect";
  }

  if (
    [
      "google-ads",
      "google-drive",
      "google-sheets",
      "google-analytics",
    ].includes(integration.id)
  ) {
    return (
      <GoogleButton
        {...buttonProps}
        form="integration-form"
        type="submit"
        disabled={isDisabled}
      />
    );
  }

  return (
    <ActionButton
      {...buttonProps}
      form="integration-form"
      type="submit"
      disabled={isDisabled}
      icon={integration.form.type === "oauth" ? <ShieldCheckIcon /> : undefined}
    >
      {integration.form.type === "oauth"
        ? "Authorize"
        : getSubmitButtonText(isEditMode, isLoading)}
    </ActionButton>
  );
}

const GoogleButton = (props: ComponentProps<typeof ActionButton>) => {
  return (
    <ActionButton {...props}>
      <div className="mr-3 flex items-center justify-center rounded-full bg-white p-1">
        <GoogleIcon className="h-5 w-5" />
      </div>
      <span>Sign in with Google</span>
    </ActionButton>
  );
};
