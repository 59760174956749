import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useCallback, useMemo, useState } from "react";

import { PrimaryButton } from "@/components/elements/Button";
import Tooltip from "@/components/elements/Tooltip";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";
import { useMatch } from "@tanstack/react-location";

import {
  useModelEditorState,
  useModelEditorTextState,
} from "../ModelEditorStore";
import { useCurrentModel } from "../hooks/useCurrentModel";
import { useModelFolders } from "../hooks/useModelFolder";
import {
  usePublishModel,
  usePublishModelAsync,
} from "../hooks/usePublishModel";
import { useCheckCircularDependencies } from "../lineage/useHasCircularDependency";
import { useQueryDependencies } from "../useQueryDependencies";
import { SaveModelModal } from "./SaveModelModal";
import { useDeleteModelDraft } from "./useModelDraft";

export default function PublishModel(
  props: React.ComponentProps<typeof PrimaryButton>,
) {
  const state = useModelEditorState();
  const editorTextState = useModelEditorTextState();

  const { dataWarehouseConnectionId } = useCurrentAccount();
  const { useAsyncModelPublishing } = useFlags();

  const currentModel = useCurrentModel();
  const [publishingDraftModel, setPublishingDraft] = useState(false);

  const queryDependencies = useQueryDependencies();
  const checkCircularReferences = useCheckCircularDependencies();

  const { modelFolders, loading: loadingModelFolders } = useModelFolders();
  const noFolders = useMemo(() => {
    return modelFolders.length === 0 && !loadingModelFolders;
  }, [modelFolders, loadingModelFolders]);

  const {
    params: { modelId },
  } = useMatch();

  const toast = useToast();
  const mixpanel = useMixpanel();

  const deleteModelDraft = useDeleteModelDraft();
  const openModel = useOpenModelInTab();
  const onFinished = useCallback(
    (modelId?: string) => {
      setPublishingDraft(false);

      if (modelId && state.currentModelType === "draft") {
        openModel({ modelId });
        deleteModelDraft(state.currentModelId);
      }
    },
    [deleteModelDraft, openModel, state.currentModelId, state.currentModelType],
  );

  const {
    handlePublish,
    handleUpdatePublished,
    loading: isPublishingSync,
  } = usePublishModel(modelId, onFinished);

  const {
    publishModel,
    publishDraft,
    loading: isPublishingAsync,
  } = usePublishModelAsync(state.currentModelId, {
    onModelPublished: (model, context) => {
      toast(
        `"${model.name}" published`,
        `The model was succesfully published to the datawarehouse.`,
        "success",
      );
      setPublishingDraft(false);
      if (context.isUnsavedDraft) {
        deleteModelDraft(context.modelId);
        openModel({ modelId: model.id });
      }
      mixpanel.track("Model Published");
    },
    onPublishError: (error, model, context) => {
      setPublishingDraft(false);
      if (model && context.isUnsavedDraft) {
        // Publish may have failed, but the model was still saved as a draft - delete the unsaved draft in localstorage
        deleteModelDraft(context.modelId);
        // Open the model in the editor
        openModel({ modelId: model.id });

        toast(
          `"${model.name}" was not published. Please try again.`,
          "Your model has been saved as a draft. Error: " + error.message,
          "error",
        );
      } else {
        toast(
          `${model ? `"${model.name}"` : "Model"} was not published`,
          error.message,
          "error",
        );
      }
    },
  });

  const isPublishing = isPublishingSync || isPublishingAsync;

  const publishButtonDisabled =
    !dataWarehouseConnectionId ||
    !editorTextState.weldSql ||
    (currentModel?.publishedQuery != null && !editorTextState.isDirty) ||
    isPublishing;

  return (
    <>
      <Tooltip
        disabled={publishButtonDisabled}
        content={`Publish model to data warehouse`}
      >
        <PrimaryButton
          variant="outline"
          disabled={publishButtonDisabled}
          onClick={async () => {
            const circularReferences = await checkCircularReferences(
              currentModel?.id,
              queryDependencies,
            );

            const invalidDependency = queryDependencies.some(
              (dependency) => dependency.type === "missing_reference",
            );

            if (invalidDependency) {
              toast(
                "Unable to publish with invalid reference",
                "Please make sure all references to dependencies are correct",
                "error",
              );
              return;
            }
            if (circularReferences.length > 0) {
              toast(
                "Unable to publish with circular reference",
                "Please make sure none of the model dependencies have circular references.",
                "error",
              );
              return;
            }
            if (noFolders) {
              toast(
                "Unable to publish with no folders",
                "Create a folder to get started publishing your models.",
                "warning",
              );
              return;
            }
            if (!currentModel?.publishedQuery) {
              setPublishingDraft(true);
              return;
            }

            if (useAsyncModelPublishing) {
              await publishModel({
                query: {
                  weldSql: editorTextState.weldSql,
                  dependencies: queryDependencies,
                },
                id: modelId,
              });
            } else {
              const path =
                currentModel.dwSync?.path ?? currentModel.dwTable?.path ?? [];

              handleUpdatePublished(
                {
                  query: {
                    weldSql: editorTextState.weldSql,
                    dependencies: queryDependencies,
                  },
                  id: modelId,
                },
                path,
              );
              mixpanel.track("Model Published", {
                is_update: true,
              });
            }
          }}
          isLoading={isPublishing}
          {...props}
        >
          Publish
        </PrimaryButton>
      </Tooltip>
      <SaveModelModal
        key={`${currentModel?.id}-${currentModel?.name}`}
        defaultName={currentModel?.name}
        show={publishingDraftModel}
        defaultFolder={currentModel?.folder}
        onCancel={() => setPublishingDraft(false)}
        onSave={async (name, folderId) => {
          if (!folderId || !dataWarehouseConnectionId) return;
          if (useAsyncModelPublishing) {
            await publishDraft({
              name: name,
              modelFolderId: folderId,
              query: {
                weldSql: editorTextState.weldSql,
                dependencies: queryDependencies,
              },
              materializationType: state.materializationType,
              materializationSchedule: state.materializationSchedule,
              orchestrationScheduler: state.orchestrationScheduler,
              id: state.currentModelId,
              orchestrationWorkflowId: state.orchestrationWorkflowId,
              isUnsavedModel: state.currentModelType === "draft",
              sourceConnectionId: dataWarehouseConnectionId,
            });
          } else {
            handlePublish({
              name: name,
              modelFolderId: folderId,
              query: {
                weldSql: editorTextState.weldSql,
                dependencies: queryDependencies,
              },
              materializationType: state.materializationType,
              materializationSchedule: state.materializationSchedule,
              orchestrationScheduler: state.orchestrationScheduler,
              id: state.currentModelId,
              orchestrationWorkflowId: state.orchestrationWorkflowId,
              isUnsavedModel: state.currentModelType === "draft",
              sourceConnectionId: dataWarehouseConnectionId,
            });
            mixpanel.track("Model Published", {
              is_update: false, // Draft
              materialization_type: state.materializationType,
              materialization_schedule: state.materializationSchedule,
              orchestration_scheduler: state.orchestrationScheduler,
            });
          }
        }}
        loading={isPublishing}
        mode="publish"
      />
    </>
  );
}
