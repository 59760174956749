import { useMatch } from "@tanstack/react-location";
import {
  FindOneSyncDocument,
  ListSyncsDocument,
  useFindOneSyncQuery,
  useStartSyncMutation,
  useStopSyncMutation,
} from "@/apollo/types";
import { SyncButton } from "@/components/elements/SyncButton";
import { syncsBlockedMessages } from "@/components/modules/SyncsBlocked";
import { useToast } from "@/providers/ToastProvider";
import { useState } from "react";
import { LocationGenerics } from "routes";

export default function ReverseEltSyncButton({
  onChange = (_: boolean): any => null,
}) {
  const [syncButtonLoading, setSyncButtonLoading] = useState(false);

  const {
    params: { syncId },
  } = useMatch<LocationGenerics>();

  const {
    loading,
    data: { findOneSync: { active: syncRunning = false } = {} } = {},
  } = useFindOneSyncQuery({
    variables: { syncId },
  });

  const toast = useToast();

  const useMutation = syncRunning ? useStopSyncMutation : useStartSyncMutation;
  const [mutate] = useMutation({
    onError(error) {
      if (error.message === "SYNC_BLOCKED") {
        toast(
          syncsBlockedMessages.REVERSE_ETL_SYNCS_BLOCKED_HEADING,
          syncsBlockedMessages.REVERSE_ETL_SYNCS_BLOCKED_BODY,
          "warning",
        );
      } else {
        toast(
          `Couldn't ${syncRunning ? "stop" : "start"} sync`,
          error.message,
          "error",
        );
      }
    },
    onCompleted() {
      onChange(!syncRunning);
      toast(
        `${syncRunning ? "Stopped" : "Started"}`,
        `Sync ${syncRunning ? "stopped" : "started"} successfully.`,
        "success",
      );
    },
    refetchQueries: [
      { query: ListSyncsDocument },
      { query: FindOneSyncDocument, variables: { syncId } },
    ],
  });

  const onToggleSync = async () => {
    setSyncButtonLoading(true);
    await mutate({ variables: { syncId } });
    setSyncButtonLoading(false);
  };

  if (loading) return null;

  return (
    <SyncButton
      onClick={onToggleSync}
      isRunning={syncRunning}
      isDisabled={syncButtonLoading}
    />
  );
}
