import { baseThemeDark, baseThemeLight, mergeThemes } from "./baseTheme";

const themeLight = mergeThemes(baseThemeLight, {
  BarChart: {
    barSize: 22,
  },
  Bar: {
    radius: [0, 2, 2, 0],
  },
});

const themeDark = mergeThemes(baseThemeDark, {
  BarChart: {
    barSize: 22,
  },
  Bar: {
    radius: [0, 2, 2, 0],
  },
});

const theme = {
  light: themeLight,
  dark: themeDark,
};

export default theme;
