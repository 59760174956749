import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import baseFolderSorter from "@/pages/ModelTool/utils/baseFolderSorter";
import { useDashboards } from "@/pages/ModelTool/Visualize/useDashboards";
import { useMemo } from "react";

import { useModelFolders } from "../../hooks/useModelFolder";
import {
  DashboardItemType,
  FolderItemType,
  ModelItemType,
  SidebarItemType,
} from "../SidebarFactory";

export const usePublishedItems = () => {
  const { models, loadingModels } = useListModels(true);
  const dashboards = useDashboards();

  const { modelFolders, loading: loadingFolders } = useModelFolders();

  const items = useMemo(() => {
    const allModels: ModelItemType[] = [...models]
      .filter((model) => !!model.publishedQuery)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((model) => {
        return {
          itemType: "model",
          id: model.id,
          type: model.type,
          model: model,
        };
      });

    const dashboardItems: DashboardItemType[] = dashboards.map((dashboard) => ({
      itemType: "dashboard",
      id: dashboard.id,
      dashboard,
    }));

    const sortedFolders = [...modelFolders]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((folder) => {
        const modelItemChildren: ModelItemType[] = allModels.filter(
          (item) => item.model.folder?.id === folder.id,
        );
        const dashboardChildren = dashboardItems.filter(
          (item) => item.dashboard.folderId === folder.id,
        );
        return getFolder(
          folder.id,
          folder.name,
          [...dashboardChildren, ...modelItemChildren],
          folder.parentId || undefined,
          folder.emoji || undefined,
        );
      });

    const baseFolders = sortedFolders
      .filter((folder) => !folder.folder.parentId)
      .map((folderItem) => {
        return {
          ...folderItem,
          children: [
            ...sortedFolders.filter((f) => f.folder.parentId === folderItem.id),
            ...folderItem.children,
          ],
        };
      })
      .sort((a, b) => baseFolderSorter(a.folder.name, b.folder.name));

    return baseFolders;
  }, [models, dashboards, modelFolders]);

  return {
    items,
    loading: loadingModels || loadingFolders,
  };
};

const getFolder = (
  folderId: string,
  folderName: string,
  children: SidebarItemType[],
  parentId?: string,
  emoji?: string,
): FolderItemType => {
  return {
    id: folderId,
    itemType: "folder",
    emoji,
    folder: {
      id: folderId,
      name: folderName,
      parentId: parentId,
      folderType: "model",
    },
    children,
  };
};
