import {
  WorkflowRunStatus,
  useOrchestrationWorkflowHistoryQuery,
  useOrchestrationWorkflowQuery,
} from "@/apollo/types";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

export const useWorkflowHistory = (workflowId: string) => {
  const { data, loading } = useOrchestrationWorkflowHistoryQuery({
    variables: {
      workflowId,
    },
  });

  const workflows = useMemo(() => {
    if (!data || !data.orchestrationWorkflow) return [];
    return data.orchestrationWorkflow.runs.filter(
      (r) => r.status !== WorkflowRunStatus.NotStarted,
    );
  }, [data]);
  return { workflows, loading };
};

export const useOrchestrationWorkflow = (
  workflowId?: string,
  poll: boolean = true,
) => {
  const [pollLatestWorkflow, setPollLatestWorkflow] = useState(true);

  const { data, loading } = useOrchestrationWorkflowQuery({
    variables: {
      workflowId: workflowId ?? "",
    },
    pollInterval: pollLatestWorkflow && poll ? 60000 : undefined,
    skip: !workflowId,
  });

  const nextRunStartingNow = useMemo(() => {
    const nextRunDueAt = data?.orchestrationWorkflow?.nextRun?.dueAt;
    return nextRunDueAt && dayjs(nextRunDueAt).isBefore(dayjs());
  }, [data?.orchestrationWorkflow?.nextRun?.dueAt]);

  useEffect(() => {
    if (!data) {
      setPollLatestWorkflow(true);
      return;
    }

    const status = data?.orchestrationWorkflow?.currentRun?.status;

    if (
      !nextRunStartingNow &&
      (status === WorkflowRunStatus.Done || status === WorkflowRunStatus.Failed)
    ) {
      setPollLatestWorkflow(false);
    } else {
      setPollLatestWorkflow(true);
    }
  }, [data, nextRunStartingNow]);

  return useMemo(() => {
    const workflow = data?.orchestrationWorkflow;
    return {
      workflow,
      loading,
      nextRunStartingNow,
    };
  }, [data?.orchestrationWorkflow, loading, nextRunStartingNow]);
};
