import classNames from "@/helpers/classNames";
import { SetStateAction } from "jotai";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";

type Props = {
  topPaneComponent: JSX.Element;
  bottomPaneComponent: JSX.Element;
  handleComponent: JSX.Element;
  bottomPaneVisible: boolean;
  bottomRatio: number;
  setBottomRatio: (update: SetStateAction<number>) => void;
};

const CustomTwoPaneLayout = ({
  setBottomRatio,
  bottomRatio,
  ...props
}: Props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [adjustingHeight, setAdjustingHeight] = useState(false);
  const startCoordinate = useRef(0);
  const startHeight = useRef(0);

  const [handleRef, setHandleRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!adjustingHeight) return;

    const mousemoveListener = debounce((e: MouseEvent) => {
      const mouseMoveYDiff = e.clientY - startCoordinate.current;
      const containerHeight = containerRef.current?.clientHeight ?? 0;
      const bottomChangePercent = -(mouseMoveYDiff / containerHeight) * 100;

      setBottomRatio(startHeight.current + bottomChangePercent);
    }, 5);

    const mouseUpListener = (e: MouseEvent) => {
      setBottomRatio((p) => (p < 10 ? 0 : p));
      setAdjustingHeight(false);
    };

    window.addEventListener("mousemove", mousemoveListener);
    window.addEventListener("mouseup", mouseUpListener);

    return () => {
      window.removeEventListener("mousemove", mousemoveListener);
      window.removeEventListener("mouseup", mouseUpListener);
    };
  }, [adjustingHeight, setBottomRatio]);

  return (
    <div ref={containerRef} className="flex h-full w-full flex-col">
      <div
        className={classNames(
          "relative grow",
          adjustingHeight ? "" : "transition-all",
        )}
        style={{ paddingBottom: `${handleRef?.clientHeight}px` }}
      >
        {props.topPaneComponent}

        {props.bottomPaneVisible && (
          <>
            <div
              ref={setHandleRef}
              className={
                "absolute inset-x-0 bottom-0 cursor-row-resize select-none"
              }
              onMouseDown={(e) => {
                startCoordinate.current = e.clientY;
                startHeight.current = bottomRatio;
                setAdjustingHeight(true);
              }}
              onMouseUp={() => setAdjustingHeight(false)}
            >
              {props.handleComponent}
            </div>
          </>
        )}
      </div>

      {props.bottomPaneVisible && (
        <div
          className={classNames(adjustingHeight ? "" : "transition-all")}
          style={{ height: `${bottomRatio}%` }}
        >
          {props.bottomPaneComponent}
        </div>
      )}
    </div>
  );
};

export default CustomTwoPaneLayout;
