import { Transition } from "@headlessui/react";
import classNames from "@/helpers/classNames";
import usePrevious from "@/hooks/usePrevious";
import { useEffect, useRef } from "react";

type SkeletonProps = {
  isLoaded?: boolean;
} & React.ComponentPropsWithoutRef<"div">;

export function Skeleton({
  isLoaded,
  className,
  children,
  ...props
}: SkeletonProps) {
  const isFirstRender = useIsFirstRender();
  const wasPreviouslyLoaded = usePrevious(isLoaded);

  if (isLoaded) {
    return isFirstRender || wasPreviouslyLoaded ? (
      <div {...props} className={className}>
        {children}
      </div>
    ) : (
      <Transition
        {...props}
        as="div"
        appear
        show
        enter="transition-all transform pop-up-fade duration-400"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition-all transform pop-up-fade duration-400"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-75"
        className={className}
      >
        {children}
      </Transition>
    );
  }

  return (
    <div
      {...props}
      className={classNames("animation-gradient-loading rounded-sm", className)}
    />
  );
}

export function useIsFirstRender() {
  const isFirstRender = useRef(true);
  useEffect(() => {
    isFirstRender.current = false;
  }, []);
  return isFirstRender.current;
}
