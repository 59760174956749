const sortingOrder = ["analytics", "core", "staging"];

function getOrder(folderName: string) {
  return sortingOrder.findIndex((i) => i === folderName);
}

//for now we hardcode the order of the default base "layers"
export default function baseFolderSorter(aName: string, bName: string) {
  return getOrder(aName) - getOrder(bName);
}
