import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import { useNavigateWithSlug } from "routes";

import {
  DependencyType,
  ListModelsQuery,
  MaterializationType,
  OrchestrationJobFragment,
  QueryDependencyInput,
} from "@/apollo/types";
import { ButtonCard } from "@/components/elements/ButtonCard";
import { CronDisplayReadable } from "@/components/elements/CronDisplayReadable";
import { LoadingFull } from "@/components/elements/LoadingComponents";
import MenuItem from "@/components/elements/MenuItem";
import { Modal, ModalBody, ModalHeader } from "@/components/elements/Modal";
import Tooltip from "@/components/elements/Tooltip";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";
import { PreviewDataModal } from "@/components/modules/PreviewData/PreviewDataModal";
import TableMenu from "@/components/modules/TableMenu";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import { useSetupRevELTSimple } from "@/features/reverse-elt/useSetupRevELTSimple";
import classNames from "@/helpers/classNames";
import { useDoubleClickable } from "@/hooks/useDoubleClickable";
import { useEltSync } from "@/hooks/useSync";
import { IntegrationLogoBox } from "@/integrations";
import { IntegrationLogo } from "@/integrations";
import { EltSyncStatusBadge } from "@/pages/EltSyncs/modules/EltSyncStatusBadge";
import { useOpenMetricsDescription } from "@/pages/Metrics/components/CoreMetricsDescription";
import EltSyncStatusLED, {
  RawViewStatusLED,
} from "@/pages/ModelTool/components/EltSyncStatusLED";
import { ModelIcon } from "@/pages/ModelTool/components/ModelIcon/ModelIcon";
import ModelSVGIcon from "@/pages/ModelTool/components/ModelIcon/ModelSVGIcon";
import { useModel } from "@/pages/ModelTool/hooks/useCurrentModel";
import { WorkflowJobStatusDisplay } from "@/pages/Orchestration/components/WorkflowJobStatusDisplay";
import { JobStatusIcon } from "@/pages/Orchestration/components/WorkflowTable/ItemStatusIcon";
import { useDataWarehouseContext } from "@/providers/DataWarehouseProvider";
import { useCurrentAccount } from "@/providers/account";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { useMatches } from "@tanstack/react-location";

import { useSchemaSidebar } from "../../ModelEditorStore";
import { useFullModelPathRenderer } from "../../hooks/useFullPathNames";
import {
  CoreEltSyncNode,
  CoreRawViewNode,
  EltSyncNode,
  LineageNode,
  OrchestrationNode,
  RawViewNode,
  RevEltNode,
  SourceStreamNode,
  StagingModelNode,
  WebhookNode,
} from "../useLineageDag";
import { WebhookIcon } from "@/components/icons/outline";
import IconWithBG from "@/components/elements/IconWithBG";

export const LineageElement: React.FC<{
  item: LineageNode;
}> = (props) => {
  const isFocused = props.item.depth === 0;
  return (
    <div
      className={classNames(
        `h-full w-full overflow-hidden rounded bg-white text-1xs dark:bg-gray-900 dark:text-white`,
        isFocused
          ? "border-2 border-primary font-medium"
          : "border border-gray-200 dark:border-gray-700",
      )}
    >
      <LineageElementContent item={props.item} />
    </div>
  );
};

const LineageElementContent = (props: { item: LineageNode }) => {
  switch (props.item.type) {
    case "model":
      return <ModelElement model={props.item.model} />;
    case "raw-view":
      return <RawViewElement item={props.item} />;
    case "rev-elt":
      return <RevEltElement item={props.item} />;
    case "elt-sync":
      return <EltElement item={props.item} />;
    case "missing":
      return <MissingElement />;
    case "orchestration-job":
      return <OrchestrationModelElement item={props.item} />;
    case "source-stream":
      return <OrchestrationSourceStreamElement item={props.item} />;
    case "unsaved-draft":
      return <UnsavedDraftElement />;
    case "core-model":
      return <CoreModelElement model={props.item.model} />;
    case "staging-model":
      return <StagingModelElement item={props.item} />;
    case "core-raw-view":
      return <CoreRawViewElement item={props.item} />;
    case "core-elt-sync":
      return <CoreEltElement item={props.item} />;
    case "webhook":
      return <WebhookElement item={props.item} />;
  }
};

const RawViewElement = (props: { item: RawViewNode }) => {
  const dwh = useDataWarehouseContext();
  const [, setSchemaSidebar] = useSchemaSidebar();
  const isInModelEditor = useMatches().some((m) =>
    m.pathname.includes("/editor"),
  );
  const eltSyncId = props.item.rawView.syncId;

  return (
    <div
      onClick={() => {
        if (!isInModelEditor) return;
        setSchemaSidebar(props.item.rawView.viewId);
      }}
      className="flex h-full w-full flex-col items-start justify-center px-4"
    >
      <IntegrationLogo id={dwh.integration.id} className="mb-1 h-4" />

      <div className="flex w-full flex-wrap">
        {props.item.rawView.weldTag.split(".").map((l, index, arr) => (
          <span key={index} className="max-w-full truncate">
            {l}
            {index !== arr.length - 1 && "."}
          </span>
        ))}
      </div>
      {eltSyncId && (
        <div className="absolute right-0 top-0 mr-4 mt-9 flex items-center">
          <RawViewStatusLED
            eltSyncId={eltSyncId}
            sourceStream={props.item.rawView.sourceStream}
          />
        </div>
      )}
    </div>
  );
};

const RevEltElement = (props: { item: RevEltNode }) => {
  const navigate = useNavigateWithSlug();
  const account = useCurrentAccount();
  const { job, revEltSync } = props.item;

  const handleSingleClick = useCallback(() => {}, []);
  const handleDoubleClick = useCallback(() => {
    const slug = account.slug;
    if (!slug) return;

    //go to sync page
    navigate({
      to: `/reverse-etl/${revEltSync.id}`,
    });
  }, [account.slug, navigate, revEltSync.id]);

  const handleClick = useDoubleClickable(handleSingleClick, handleDoubleClick);

  const syncTimeCron =
    revEltSync.orchestrationWorkflow?.cronExpression ?? revEltSync.syncInterval;

  return (
    <div
      onClick={handleClick}
      className={
        "flex h-full w-full flex-col items-start justify-center truncate px-4"
      }
    >
      <IntegrationLogo
        id={revEltSync.destinationIntegrationId}
        className="mb-1 h-4"
      />
      <span className="font-medium">{revEltSync.destinationIntegrationId}</span>
      <div className="space-x-1 text-2xs text-gray-600">
        {["PAUSED", "NOT_STARTED"].includes(revEltSync.status) ? (
          <span>Sync not active</span>
        ) : (
          <>
            <span>Sync active</span>
            {syncTimeCron && (
              <span>
                <CronDisplayReadable cron={syncTimeCron} />
              </span>
            )}
          </>
        )}
      </div>
      <div className="absolute right-0 top-0 mr-4 mt-4 flex items-center space-x-1">
        {job?.status && (
          <Tooltip
            content={
              <div>
                <WorkflowJobStatusDisplay jobStatus={job.status} />
              </div>
            }
          >
            <div>
              <JobStatusIcon status={job.status} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const EltElement = (props: { item: EltSyncNode }) => {
  const { onOpen: viewSync } = useViewDataSourceSlideOver();

  const { eltSync } = props.item;

  const handleViewSync = () => {
    //go to sync page
    viewSync({
      syncId: eltSync.id,
    });
  };

  const syncTimeCron =
    props.item.eltSync.orchestrationWorkflow?.cronExpression ??
    props.item.eltSync.syncInterval;

  return (
    <div
      onClick={handleViewSync}
      className={
        "flex h-full w-full flex-col items-start justify-center truncate px-4"
      }
    >
      <IntegrationLogo id={eltSync.sourceIntegrationId} className="mb-1 h-4" />
      <span className="font-medium">{eltSync.sourceIntegrationId}</span>
      <div className="space-x-1 text-2xs text-gray-600">
        {eltSync.status === "PAUSED" ? (
          <span>Sync stopped</span>
        ) : (
          <>
            <span>Sync active</span>
            {syncTimeCron && (
              <span>
                <CronDisplayReadable cron={syncTimeCron} />
              </span>
            )}
          </>
        )}
      </div>

      <div className="absolute right-0 top-0 mr-4 mt-9 flex items-center">
        <EltSyncStatusLED eltSyncId={eltSync.id} />
      </div>
    </div>
  );
};

const ModelElement = (props: {
  model: ListModelsQuery["models"][0];
  job?: OrchestrationJobFragment;
  isFocused?: boolean;
  circularReferences?: string[];
}) => {
  const isUnnamed = !props.model.name.length;

  const [isHovered, setIsHovered] = useState(false);

  const isDatawarehouseModel = !!props.model?.dwSync || !!props.model?.dwTable;

  const [, setSchemaSidebar] = useSchemaSidebar();

  const fullPath = useFullModelPathRenderer(
    props.model,
    (model, modelName, folders) => {
      return (
        <>
          {folders
            .map((x) => x.name)
            .concat(modelName)
            .map((name, index, arr) => (
              <span
                key={name + index}
                className="max-w-full truncate font-medium"
              >
                {name}
                {index !== arr.length - 1 && "."}
              </span>
            ))}
        </>
      );
    },
  );

  const openModelInTab = useOpenModelInTab();

  const handleSingleClick = useCallback(() => {
    //don't do anything on single click
  }, []);

  const handleDoubleClick = useCallback(() => {
    if (props.model) {
      openModelInTab({ modelId: props.model.id });
    }
  }, [openModelInTab, props.model]);

  const handleClick = useDoubleClickable(handleSingleClick, handleDoubleClick);

  const hasMaterializationError =
    props.model.materializationType === MaterializationType.Table &&
    props.model.dwTable?.status === "FAILED";
  const hasCircularReferences = !!props.circularReferences?.length;
  const showTooltips =
    hasMaterializationError || hasCircularReferences || props.job;

  const latestSyncTime = props.model.dwTable?.latestSyncTime;

  return (
    <div
      onClick={handleClick}
      className={classNames(
        "flex h-full w-full flex-col items-start justify-center px-4",
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ModelIcon model={props.model} />
      <div className="flex flex-wrap">
        {isUnnamed && (
          <span className="font-medium italic text-gray-500">
            Unnamed Model
          </span>
        )}
        {fullPath}
      </div>
      {/* <div className="">{props.item.model.materializationType}</div> */}
      {showTooltips && (
        <div className="absolute right-0 top-0 mr-4 mt-4 flex items-center space-x-1">
          {hasMaterializationError && (
            <Tooltip
              content={`Error during table materialization${
                !latestSyncTime
                  ? ""
                  : `, last successfully created at ${dayjs(
                      latestSyncTime,
                    ).format("YYYY-MM-DD HH:mm")}`
              } `}
            >
              <div>
                <ExclamationCircleIcon className="h-4 w-4 text-red-500" />
              </div>
            </Tooltip>
          )}
          {hasCircularReferences && (
            <Tooltip content={"Model contains circular dependency."}>
              <div>
                <InformationCircleIcon className="h-4 w-4 text-red-500" />
              </div>
            </Tooltip>
          )}
          {props.job && (
            <Tooltip
              content={
                <WorkflowJobStatusDisplay jobStatus={props.job.status} />
              }
            >
              <div>
                <JobStatusIcon status={props.job.status} />
              </div>
            </Tooltip>
          )}
        </div>
      )}
      {isHovered && (
        <div className="absolute right-0 top-0 mr-3 mt-8 flex items-center space-x-1">
          <TableMenu>
            {isDatawarehouseModel && (
              <MenuItem
                text="Open schema"
                size="sm"
                onClick={() => {
                  setSchemaSidebar(props.model.id);
                }}
              />
            )}
            <MenuItem
              text="Go to model"
              size="sm"
              tooltipText="double click"
              onClick={() => {
                openModelInTab({ modelId: props.model.id });
              }}
            />
            <MenuItem
              text="Open in new tab"
              size="sm"
              onClick={() => {
                openModelInTab({ modelId: props.model.id });
              }}
            />
          </TableMenu>
        </div>
      )}
    </div>
  );
};

const CoreModelElement = (props: { model: ListModelsQuery["models"][0] }) => {
  const [isHovered, setIsHovered] = useState(false);

  const fullPath = useFullModelPathRenderer(
    props.model,
    (model, modelName, folders) => {
      return (
        <>
          {folders
            .map((x) => x.name)
            .concat(modelName)
            .map((name, index, arr) => (
              <span
                key={name + index}
                className="max-w-full truncate font-medium"
              >
                {name}
                {index !== arr.length - 1 && "."}
              </span>
            ))}
        </>
      );
    },
  );

  const openModelInTab = useOpenModelInTab();

  const openMetric = useOpenMetricsDescription();
  const { open: openRevELTSidebar } = useSetupRevELTSimple();

  const hasMaterializationError =
    props.model.materializationType === MaterializationType.Table &&
    props.model.dwTable?.status === "FAILED";
  const showTooltips = hasMaterializationError;

  const latestSyncTime = props.model.dwTable?.latestSyncTime;

  return (
    <div
      onClick={() => {
        openMetric({
          open: true,
          selectedModel: props.model,
        });
      }}
      className={classNames(
        "relative flex h-full w-full items-center space-x-4 px-4",
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex h-8 w-8 flex-none items-center justify-center rounded-sm bg-blue-500">
        <ModelIcon model={props.model} className="h-4 w-4 text-white" />
      </div>
      <div>
        <div className="mb-1 flex flex-wrap text-2xs">{fullPath}</div>
        <div className="text-2xs">Click to view metric</div>
      </div>
      {/* <div className="">{props.item.model.materializationType}</div> */}
      {showTooltips && (
        <div className="absolute right-0 top-0 mr-4 mt-4 flex items-center space-x-1">
          {hasMaterializationError && (
            <Tooltip
              content={`Error during table materialization${
                !latestSyncTime
                  ? ""
                  : `, last successfully created at ${dayjs(
                      latestSyncTime,
                    ).format("YYYY-MM-DD HH:mm")}`
              } `}
            >
              <div>
                <ExclamationCircleIcon className="h-4 w-4 text-red-500" />
              </div>
            </Tooltip>
          )}
        </div>
      )}
      {isHovered && (
        <div className="absolute right-2 top-0 mt-5 flex items-center space-x-1">
          <TableMenu>
            <MenuItem
              text="View metric"
              size="sm"
              onClick={() => {
                openMetric({
                  open: true,
                  selectedModel: props.model,
                });
              }}
            />
            <MenuItem
              text="View in editor"
              size="sm"
              onClick={() => {
                openModelInTab({ modelId: props.model.id });
              }}
            />
            <MenuItem
              text="Send to google sheet"
              size="sm"
              onClick={() => {
                openRevELTSidebar(props.model, "google-sheets");
              }}
            />
          </TableMenu>
        </div>
      )}
    </div>
  );
};

const CoreRawViewElement = (props: { item: CoreRawViewNode }) => {
  const dwh = useDataWarehouseContext();

  const [previewData, setPreviewData] = useState<QueryDependencyInput>();
  const [openRawViews, setOpenRawViews] = useState(false);

  return (
    <div
      onClick={() => {
        setOpenRawViews(true);
      }}
      className="flex h-full w-full items-center space-x-4 px-4"
    >
      <Tooltip content={`Raw data in ${dwh.integration.name}`}>
        <div>
          <IntegrationLogoBox id={dwh.integration.id} />
        </div>
      </Tooltip>

      <div>
        <div className="mb-1 flex w-full flex-wrap truncate text-2xs font-medium">
          <span className="max-w-full truncate">
            raw.{props.item.schemaName}
          </span>
          <span className="ml-1">({props.item.rawViews.length} tables)</span>
        </div>
        <div className="text-2xs">click to view tables</div>
      </div>

      <Modal isOpen={openRawViews} onClose={() => setOpenRawViews(false)}>
        <>
          <ModalHeader className="space-y-4">
            <div className="flex items-center space-x-2">
              <IntegrationLogoBox id={props.item.integrationId} size="sm" />
              <div className="">Raw data tables</div>
            </div>
            <div className="rounded bg-gray-100 p-2 text-xs dark:bg-gray-700">
              <div>
                Raw data tables synced into {dwh.integration.name}.{" "}
                <a
                  className="text-primary hover:underline"
                  href="https://weld.app/docs/workspace/data-sources#syncing-data"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Read more about how WELD syncs data.
                </a>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="flex max-h-[32rem] flex-col space-y-2 overflow-auto pb-8">
            {props.item.rawViews.map((rawView) => (
              <ButtonCard
                onClick={() => {
                  setPreviewData({
                    dwItemId: rawView.viewId,
                    type: DependencyType.RawView,
                    weldTag: rawView.weldTag,
                  });
                }}
                key={rawView.viewId}
                className="flex items-center justify-start space-x-2 p-2"
              >
                <IntegrationLogoBox id={dwh.integration.id} size="sm" />
                <div className="text-sm">{rawView.weldTag}</div>
              </ButtonCard>
            ))}
          </ModalBody>
          <PreviewDataModal
            headline={
              <div className="flex items-center space-x-2">
                <IntegrationLogoBox id={dwh.integration.id} size="sm" />
                <div>{previewData?.weldTag}</div>
              </div>
            }
            dataReference={previewData}
            onClose={() => setPreviewData(undefined)}
            limit={1000}
          />
        </>
      </Modal>
    </div>
  );
};

const StagingModelElement = (props: { item: StagingModelNode }) => {
  const [previewData, setPreviewData] = useState<{
    dependency: QueryDependencyInput;
    sql?: string;
  }>();
  const [openRawViews, setOpenRawViews] = useState(false);

  return (
    <div
      onClick={() => {
        setOpenRawViews(true);
      }}
      className="flex h-full w-full items-center space-x-4 px-4"
    >
      <Tooltip content={`Staging model for ${props.item.integrationId}`}>
        <div className="flex h-8 w-8 items-center justify-center rounded-sm bg-gray-200 dark:bg-gray-700">
          <ModelSVGIcon className="h-4 w-4" />
        </div>
      </Tooltip>

      <div>
        <div className="mb-1 flex w-full flex-wrap truncate text-2xs font-medium">
          <span className="max-w-full truncate">
            staging.{props.item.schemaName}
          </span>
          <span className="ml-1">({props.item.models.length} models)</span>
        </div>
        <div className="text-2xs">click to view models</div>
      </div>

      <Modal isOpen={openRawViews} onClose={() => setOpenRawViews(false)}>
        <>
          <ModalHeader className="space-y-4">
            <div className="flex items-center space-x-2">
              <IntegrationLogoBox id={props.item.integrationId} size="sm" />
              <div className="">Staging Models</div>
            </div>
            <div className="rounded bg-gray-100 p-2 text-xs dark:bg-gray-700">
              <div>
                Staging models transform raw data into a format that is ready
                for data modeling.
              </div>
            </div>
          </ModalHeader>

          <ModalBody className="flex flex-col space-y-2 pb-8">
            {props.item.models.map((model) => (
              <ButtonCard
                key={model.id}
                onClick={() => {
                  const weldTag =
                    model.dwTable?.weldTag || model.dwSync?.weldTag;
                  if (!weldTag) return;

                  setPreviewData({
                    dependency: {
                      dwItemId: model.id,
                      type:
                        model.materializationType === MaterializationType.Table
                          ? DependencyType.MaterializedTable
                          : DependencyType.ModelView,
                      weldTag,
                    },
                    sql: model.publishedQuery?.weldSql,
                  });
                }}
                className="flex items-center justify-start space-x-2 p-2"
              >
                <ModelIcon model={model} />
                <div className="text-sm">
                  {model.dwTable?.weldTag || model.dwSync?.weldTag}
                </div>
              </ButtonCard>
            ))}
          </ModalBody>
          <PreviewDataModal
            headline={
              <div className="flex items-center space-x-2">
                <IntegrationLogoBox id={props.item.integrationId} size="sm" />
                <div>{previewData?.dependency.weldTag}</div>
              </div>
            }
            sqlCodeDisplay={previewData?.sql}
            modelId={previewData?.dependency.dwItemId}
            dataReference={previewData?.dependency}
            onClose={() => setPreviewData(undefined)}
            limit={1000}
          />
        </>
      </Modal>
    </div>
  );
};

const CoreEltElement = (props: { item: CoreEltSyncNode }) => {
  const { onOpen: viewSync } = useViewDataSourceSlideOver();
  const { eltSync } = props.item;

  const handleViewSync = () => {
    //go to sync page
    viewSync({
      syncId: eltSync.id,
    });
  };

  return (
    <div
      onClick={handleViewSync}
      className={"flex h-full w-full items-center space-x-2 px-4"}
    >
      <IntegrationLogoBox id={eltSync.sourceIntegrationId} size="sm" />
      <div className="truncate">
        <span className="font-medium">{eltSync.sourceIntegrationId}</span>
        <div className="pt-1">
          <EltSyncStatusBadge size={"sm"} eltSyncId={props.item.eltSync.id} />
        </div>
      </div>
    </div>
  );
};

const WebhookElement = (props: { item: WebhookNode }) => {
  const { webhook } = props.item;

  return (
    <div className={"flex h-full w-full items-center space-x-2 px-4"}>
      <IconWithBG icon={<WebhookIcon id={webhook.webhookId} />} size={"sm"} />
      <div className="truncate">
        <span className="font-medium">{webhook.name}</span>
      </div>
    </div>
  );
};

const OrchestrationModelElement = (props: {
  item: OrchestrationNode;
  isFocused?: boolean;
  circularReferences?: string[];
}) => {
  const { model, loading } = useModel(props.item.id);
  if (loading) return <LoadingFull />;
  if (!model) return <MissingElement />;
  return (
    <ModelElement
      model={model}
      circularReferences={props.circularReferences}
      isFocused={props.isFocused}
      job={props.item.job}
    />
  );
};

const OrchestrationSourceStreamElement = (props: {
  item: SourceStreamNode;
  isFocused?: boolean;
  circularReferences?: string[];
}) => {
  const { job, eltSyncId } = props.item;

  const target = job?.target;

  const { eltSync } = useEltSync(eltSyncId);

  const dwh = useDataWarehouseContext();
  if (target?.__typename !== "SourceStream") return <MissingElement />;

  return (
    <div
      onClick={() => {}}
      className="flex h-full w-full flex-col items-start justify-center px-4"
    >
      <IntegrationLogo id={dwh.integration.id} className="mb-1 h-4" />

      <div className="flex w-full flex-wrap">
        raw.{eltSync?.destinationSchemaName}.{target.name}
      </div>
      <div className="absolute right-0 top-0 mr-4 mt-4 flex items-center space-x-1">
        {job && (
          <Tooltip
            content={
              <div>
                <WorkflowJobStatusDisplay jobStatus={job.status} />
              </div>
            }
          >
            <div>
              <JobStatusIcon status={job.status} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const MissingElement = (props: {}) => {
  return (
    <div
      className={
        "flex h-full w-full items-center justify-center space-x-2 truncate px-4 opacity-75"
      }
    >
      <ExclamationCircleIcon className="h-4 text-red-400" />
      <span className="">Unknown reference</span>
    </div>
  );
};
const UnsavedDraftElement = (props: {}) => {
  return (
    <div
      className={
        "flex h-full w-full items-center justify-center space-x-2 truncate px-4 opacity-75"
      }
    >
      <span className="opacity-50">Unsaved Draft</span>
    </div>
  );
};
