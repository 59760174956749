import { AnimatePresence, motion } from "framer-motion";
import { range } from "lodash";
import { useMemo } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";

import { ButtonPrimitive } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { Heading } from "@/components/elements/Typography";
import { TextArea } from "@/components/primitives/input/TextArea";
import cn from "@/helpers/classNames";
import { RadioGroup } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

export type NPSSurveyFormData = {
  score: number | null;
  feedback: string | null;
};

export function useNPSSurveyForm() {
  return useForm<NPSSurveyFormData>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      score: null,
      feedback: "",
    },
  });
}

function ScoreStep(props: { question: string }) {
  return (
    <div>
      <Heading className="mb-2 text-sm font-normal sm:text-base">
        {props.question}
      </Heading>
      <div className="max-w-md">
        <Controller
          name="score"
          rules={{ required: true }}
          render={({ field }) => (
            <RadioGroup<"div", number | null> {...field}>
              <div className="flex justify-between">
                {range(0, 11).map((value) => (
                  <RadioGroup.Option
                    key={value}
                    value={value}
                    className={({ checked }) =>
                      cn(
                        "flex h-6 w-6 cursor-pointer items-center justify-center justify-self-center rounded bg-gray-100 font-medium text-gray-700 focus:ring-offset-2 xs:h-7 xs:w-7 sm:h-8 sm:w-8",
                        checked
                          ? "bg-indigo-400 text-white"
                          : "hover:bg-gray-300",
                      )
                    }
                  >
                    {value}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          )}
        />
        <div className="mt-1 flex justify-between text-xs">
          <span className="font-medium">0 - Very unlikely</span>
          <span className="font-medium">10 - Very likely</span>
        </div>
      </div>
    </div>
  );
}

function FeedbackStep(props: { score: number }) {
  const { register, formState } = useFormContext<NPSSurveyFormData>();
  const { isSubmitting } = formState;
  return (
    <div>
      <Heading className="mb-2 text-sm font-normal sm:text-base">
        {props.score < 9
          ? "What could we do to improve?"
          : "Nice! What is your favorite thing about WELD?"}
      </Heading>
      <div className="flex max-w-md gap-2">
        <TextArea
          autoFocus
          rows={1}
          className="bg-white/90 text-black dark:bg-white/90"
          style={{ minHeight: "auto" }}
          {...register("feedback")}
        />
        <ButtonPrimitive
          type="submit"
          disabled={!formState.isValid || isSubmitting}
          className="rounded-sm border border-black/30 bg-white/20 px-2 py-1 shadow hover:bg-white/10"
        >
          {isSubmitting ? (
            <LoadingSpinner colorScheme="white" />
          ) : (
            <ChevronRightIcon className="w-6" />
          )}
        </ButtonPrimitive>
      </div>
    </div>
  );
}

export function NPSSurveyForm(props: { question: string }) {
  const { watch } = useFormContext<NPSSurveyFormData>();
  const score = watch("score");

  const step = useMemo(() => {
    if (score == null) {
      return {
        key: "score",
        component: <ScoreStep question={props.question} />,
      };
    }
    return {
      key: "feedback",
      component: <FeedbackStep score={score ?? 0} />,
    };
  }, [score, props.question]);

  return (
    <div className="h-full overflow-hidden">
      <AnimatePresence initial={false} mode="wait">
        <motion.div
          key={step.key}
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{
            y: -100,
            opacity: 0,
            transition: { delay: 0.5, duration: 0.2 },
          }}
          transition={{ duration: 0.2 }}
          className="min-h-[84px]"
        >
          {step.component}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
