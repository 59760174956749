import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { SecondaryButton } from "@/components/elements/Button";
import { InputError } from "@/components/primitives/InputError";
import { InputErrorIndicator } from "@/components/primitives/InputErrorIndicator";
import { FormType, Repeater, SubForm } from "@/integrations/forms";
import { cloneDeep, uniqueId } from "lodash";
import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import FormFactory from "./FormFactory";

export const FormRepeater: FC<{
  form: Repeater;
}> = ({ form }) => {
  const { unregister, setError, setValue } = useFormContext();
  const [values, setValues] = useState(form.value);

  useEffect(() => {
    setError(form.name, { message: form.error });
  }, [setError, form.name, form.error]);

  useEffect(() => {
    // Appolo replaces missing indexes with nulls. We filter them out.
    setValues((prev) =>
      form.value
        .filter((subForm) => subForm !== null)
        .filter(
          (subForm: Record<string, any>) => Object.keys(subForm).length !== 0,
        ),
    );
  }, [form.value, setValues]);

  const add = () => {
    const newItem = cloneDeep(form.template);
    newItem.name = uniqueId();

    newItem.fields = newItem.fields.reduce((acc: FormType[], field) => {
      if (field.type === "hidden" && field.name === "id") {
        field.value = newItem.name;
      }
      field.name = `${form.name}[${newItem.name}][${field.name}]`; // name="ga-custom-reports[1][name]"
      acc.push(field);
      return acc;
    }, []);

    setValues((previous) => [...previous, newItem]);
  };

  const remove = (subForm: SubForm) => {
    subForm.fields.forEach((field) => unregister(field.name));
    setValues((prev) => prev.filter((s) => s.name !== subForm.name));
    setValue("noop", 1); // Force the form's onChange event.
  };

  return (
    <div>
      <div className="flex items-center space-x-4 border-t pt-4 dark:border-gray-800">
        <label className="text-sm text-gray-700 dark:text-gray-300">
          {form.label} ({values.length}/{form.max})
        </label>
      </div>
      {values.map((value) => (
        <div
          key={value.name}
          className="relative my-4 rounded-sm border bg-gray-50 p-4 dark:border-gray-700 dark:bg-gray-700"
        >
          <FormFactory form={value} />
          <XMarkIcon
            className="absolute right-2 top-2 w-4 cursor-pointer text-gray-700 dark:text-gray-300"
            onClick={() => remove(value)}
          />
        </div>
      ))}
      {!!form.error && (
        <div className="flex flex-row items-center gap-1">
          <InputErrorIndicator />
          <InputError>{form.error}</InputError>
        </div>
      )}
      <SecondaryButton
        tabIndex={-1}
        disabled={form.value.length >= form.max}
        className="my-4"
        onClick={(e) => {
          e.preventDefault();
          add();
        }}
        icon={<PlusIcon />}
      >
        New {form.singularLabel}
      </SecondaryButton>
    </div>
  );
};
