import React, { Suspense, useCallback } from "react";

import {
  PlanName,
  RecurrenceInterval,
  useCurrentWeldPlanLazyQuery,
} from "@/apollo/types";
import { Modal } from "@/components/elements/Modal";
import * as Sentry from "@sentry/react";
import { MakeGenerics, useNavigate, useSearch } from "@tanstack/react-location";

import { LocaleProvider, StripeProvider } from "../../../shared/providers";
import { ConfirmSubscriptionContainer } from "../ConfirmSubscriptionContainer";

const CheckoutContainer = React.lazy(() => import("../CheckoutContainer"));

type InitCheckoutParams = {
  billingPeriod: RecurrenceInterval.Year | RecurrenceInterval.Month;
  planName: PlanName;
  numConnectors?: number;
  numUsers?: number;
};

type OpenCheckoutParams = {
  planName: PlanName;
  billingPeriod?: RecurrenceInterval;
  numConnectors?: number;
  numUsers?: number;
};

type LocationGenerics = MakeGenerics<{
  Search: { checkout: string | InitCheckoutParams };
}>;

export function useCheckoutModal() {
  const navigate = useNavigate<LocationGenerics>();
  const search = useSearch<LocationGenerics>();

  const isConfirmSubscription = search.checkout === "confirm_subscription";

  const planName =
    search.checkout && !isConfirmSubscription
      ? Object.values(PlanName).find(
          (x) =>
            x.toLowerCase() ===
            (typeof search.checkout === "string"
              ? search.checkout
              : search.checkout?.planName
            )?.toLowerCase(),
        )
      : undefined;

  const createOpenProps = (params: OpenCheckoutParams) => {
    const recurrenceInterval = params.billingPeriod ?? RecurrenceInterval.Year;
    return {
      search: {
        checkout: {
          planName: params.planName,
          billingPeriod: [
            RecurrenceInterval.Month,
            RecurrenceInterval.Year,
          ].includes(recurrenceInterval)
            ? recurrenceInterval
            : RecurrenceInterval.Year,
          numConnectors: params.numConnectors,
          numUsers: params.numUsers,
        } as InitCheckoutParams,
      },
    };
  };

  const onOpen = useCallback(
    (params: OpenCheckoutParams) => {
      navigate(createOpenProps(params));
    },
    [navigate],
  );

  const onClose = useCallback(() => {
    navigate({
      search: {
        checkout: undefined,
      },
    });
  }, [navigate]);

  const [fetchCurrentPlan] = useCurrentWeldPlanLazyQuery();
  const modifyCurrentPlan = useCallback(async () => {
    const { data: planConfigData } = await fetchCurrentPlan();
    if (!planConfigData?.currentPlan) {
      return;
    }
    const numConnectors =
      planConfigData?.currentPlan.features.limitConnectors.value;
    const numUsers = planConfigData?.currentPlan.features.limitUsers.value;
    onOpen({
      planName: planConfigData?.currentPlan.name,
      billingPeriod: planConfigData?.currentPlan.billingPeriod ?? undefined,
      numConnectors,
      numUsers,
    });
  }, [fetchCurrentPlan, onOpen]);

  return {
    modifyCurrentPlan: modifyCurrentPlan,
    openCheckoutModal: onOpen,
    closeCheckoutModal: onClose,
    getLinkProps: (params: OpenCheckoutParams) => createOpenProps(params),
    isOpen: !!planName || isConfirmSubscription,
    initProps: {
      ...(typeof search.checkout !== "string" ? search.checkout : {}),
      planName,
    },
    isConfirmSubscription,
  };
}

export function CheckoutModalRoot() {
  const { closeCheckoutModal, initProps, isConfirmSubscription } =
    useCheckoutModal();
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Modal
        isOpen={!!initProps.planName}
        size="xl"
        centerOnScreen={false}
        onClose={() => {}}
      >
        <Suspense fallback={<div />}>
          {initProps.planName && (
            <LocaleProvider>
              <CheckoutContainer
                planName={initProps.planName}
                billingPeriod={initProps.billingPeriod}
                connectorCount={initProps.numConnectors}
                userCount={initProps.numUsers}
                onClose={() => {
                  closeCheckoutModal();
                }}
              />
            </LocaleProvider>
          )}
        </Suspense>
      </Modal>
      <Modal
        isOpen={isConfirmSubscription}
        size="md"
        centerOnScreen={false}
        onClose={() => {}}
      >
        <Suspense fallback={<div />}>
          <StripeProvider>
            <ConfirmSubscriptionContainer
              onClose={() => {
                closeCheckoutModal();
              }}
            />
          </StripeProvider>
        </Suspense>
      </Modal>
    </Sentry.ErrorBoundary>
  );
}
