import DashboardIcon from "@/components/icons/DashboardIcon";
import { useOpenDashboardInTab } from "@/components/modules/ModelTabs";
import { Action, Priority, useRegisterActions } from "kbar";
import { useDashboards } from "@/pages/ModelTool/Visualize/useDashboards";
import { useMemo } from "react";

/*
 * Dashboard actions are only avaiable inside the firebase context in the model tool
 * So we have this extra little component to make them available when the model tool is loaded
 */

export const useDashboardKBarActions = () => {
  const dashboards = useDashboards();
  const openDashboardInTab = useOpenDashboardInTab();

  const actions = useMemo<Action[]>(() => {
    return dashboards.map((dashboard) => {
      return {
        id: dashboard.id,
        name: dashboard.name,
        keywords: `dashboard ${dashboard.name}`,
        priority: Priority.NORMAL,
        icon: <DashboardIcon className="w-4" />,
        perform: () => {
          openDashboardInTab(dashboard.id, dashboard.name, true);
        },
        section: "Dashboard",
      };
    });
  }, [dashboards, openDashboardInTab]);

  useRegisterActions(actions, [actions]);
};
