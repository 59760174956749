export function generateColumnId(index: number) {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let columnName = "";

  while (index >= 0) {
    const remainder = index % alphabet.length;
    columnName = alphabet.charAt(remainder) + columnName;
    index = Math.floor(index / alphabet.length) - 1;
  }

  return columnName;
}

export function generateColumnName(columnId: string) {
  return `Column ${columnId} (${columnId}:${columnId})`;
}
