import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useWorkspaceSyncStatsByIntegrationQuery } from "@/apollo/types";
import AppearTransition from "@/components/elements/AppearTransition";
import { Card, CardBody, CardHeader } from "@/components/elements/Card";
import { MonthPicker } from "@/components/elements/MonthPicker";
import { Heading, TextMuted } from "@/components/elements/Typography";
import { ActiveRowTooltipTrigger } from "@/components/modules/ActiveRow";
import {
  BarChartSkeleton,
  ErrorDataPlaceholder,
  NoDataPlaceholder,
  RowsSyncedByIntegrationChart,
} from "@/components/modules/sync-stats-charts";
import {
  UsageStatsProvider,
  useRowCountSelector,
  useUsageStatsContext,
} from "@/features/usage-stats";
import { atom, useAtom } from "jotai";
import React from "react";
import { ResponsiveContainer } from "recharts";
import { startOfMonthUTC } from "@/utils/date-utils";

import MainTabsLayout from "./MainTabsLayout";

const dateAtom = atom(new Date());

export default function ReverseEltSyncsUsagePage() {
  const [date] = useAtom(dateAtom);
  return (
    <MainTabsLayout>
      <UsageStatsProvider referenceDate={date}>
        <AppearTransition>
          <ReverseEltSyncStats />
        </AppearTransition>
      </UsageStatsProvider>
    </MainTabsLayout>
  );
}

const ReverseEltSyncStats = React.forwardRef<HTMLDivElement>((_, ref) => {
  const [date, setDate] = useAtom(dateAtom);

  const { data, loading, error } = useWorkspaceSyncStatsByIntegrationQuery({
    variables: {
      month: startOfMonthUTC(date),
    },
  });

  const { rowCountBasis, enableBillableRows } = useUsageStatsContext();
  const selectRowsSynced = useRowCountSelector();

  const sortedData = (data?.workspaceSyncStatsByIntegration.data ?? [])
    .slice()
    .sort((a, b) => {
      return selectRowsSynced(b.reverseElt) - selectRowsSynced(a.reverseElt);
    });

  const hasError = data == null && error != null;
  const hasNoResults = !loading && sortedData.length === 0;
  return (
    <Card ref={ref}>
      <CardHeader className="flex items-center">
        <Heading className="font-normal">Amount of rows synced</Heading>
        <div className="ml-auto flex items-center gap-8">
          <TextMuted className="flex items-center whitespace-nowrap text-sm">
            <span>Active rows</span>{" "}
            <ActiveRowTooltipTrigger>
              <InformationCircleIcon className="ml-1 h-4" />
            </ActiveRowTooltipTrigger>
          </TextMuted>
          <MonthPicker
            selected={date}
            onChange={(date: Date) => {
              setDate(date);
            }}
          />
        </div>
      </CardHeader>
      {hasError ? (
        <ErrorDataPlaceholder />
      ) : hasNoResults ? (
        <NoDataPlaceholder />
      ) : (
        <CardBody className="h-96 overflow-y-auto">
          <BarChartSkeleton isLoaded={!loading}>
            <ResponsiveContainer width="100%" height="100%">
              <RowsSyncedByIntegrationChart
                data={sortedData}
                rowCountBasis={rowCountBasis}
                isBillableRowsEnabled={enableBillableRows}
                showEltData={false}
              />
            </ResponsiveContainer>
          </BarChartSkeleton>
        </CardBody>
      )}
    </Card>
  );
});
