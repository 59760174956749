import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import ButtonPrimitive from "@/components/primitives/Button";
import FieldLabel from "@/components/primitives/InputLabel";
import classNames from "@/helpers/classNames";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";

import FormTextInput from "./FormTextInput";
import { useToast } from "@/providers/ToastProvider";

export default function AppStoreConnectForm() {
  const { reset } = useFormContext();
  return (
    <div>
      <div className="mb-4">
        <FieldLabel required={true} id="payload">
          Upload your App Store Connect Private Key
        </FieldLabel>
        <div className="mt-1">
          <Dropzone
            onChange={(privateKey) => {
              reset({ privateKey });
            }}
          />
        </div>
      </div>

      <FormTextInput
        field={{
          label: "Private Key ID",
          name: "privateKeyId",
          type: "text",
          required: true,
          inputProps: {
            placeholder: "Private Key ID",
          },
        }}
      />

      <FormTextInput
        field={{
          label: "Issuer ID",
          name: "issuerId",
          type: "text",
          required: true,
          inputProps: {
            placeholder: "Issuer ID",
          },
        }}
      />
    </div>
  );
}

type DropzoneProps = {
  onChange: (privateKey: string) => void;
};

const Dropzone = ({ onChange }: DropzoneProps) => {
  const toast = useToast();
  const [filename, setFilename] = React.useState<string | null>(null);

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        // eslint-disable-next-line no-console
        reader.onabort = () => console.warn("file reading was aborted");
        // eslint-disable-next-line no-console
        reader.onerror = () => console.warn("file reading has failed");
        reader.onload = () => {
          const result = reader.result?.toString();
          if (result && result.includes("BEGIN PRIVATE KEY")) {
            onChange(result);
            setFilename(file.name);
          } else {
            setFilename(null);
            toast(
              "Invalid key file",
              "The provided private key file is not valid.",
              "error",
            );
          }
        };
        reader.readAsText(file, "UTF-8");
      });
    },
    [onChange, toast],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pkcs8": [".p8"],
    },
  });

  return (
    <div
      className={"mt-1 sm:col-span-2 sm:mt-0"}
      {...getRootProps()}
      role="button"
    >
      <input {...getInputProps()} />
      {!filename ? (
        <div
          className={classNames(
            "flex justify-center px-6 pb-6 pt-5",
            "rounded-md border-2 border-dashed border-gray-300 dark:border-gray-500",
            "hover:border-blue-200",
            "hover:bg-blue-50 dark:hover:bg-gray-800",
            isDragActive &&
              "border-blue-200 bg-blue-50 dark:border-blue-500 dark:bg-gray-800",
          )}
        >
          <div className="space-y-1 text-center">
            <DocumentPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
            <div className="text-sm text-gray-600">
              <span
                className={classNames(
                  "font-medium text-blue-500",
                  "focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2",
                )}
              >
                <span>
                  Click to select your App Store Connect Private key file
                </span>
              </span>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">.P8</p>
          </div>
        </div>
      ) : (
        <ButtonPrimitive className="flex h-12 w-full items-center rounded border border-gray-500 px-4 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:hover:bg-gray-700">
          <span className="truncate">{filename}</span>
        </ButtonPrimitive>
      )}
    </div>
  );
};
