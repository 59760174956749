import { groupBy, uniqBy } from "lodash";
import { useMemo, useState } from "react";

import {
  DependencyType,
  MaterializationType,
  ModelListItemFragment,
  QueryDependencyInput,
} from "@/apollo/types";
import { Button, IconButton } from "@/components/elements/Button";
import IconWithBG from "@/components/elements/IconWithBG";
import Toggle from "@/components/elements/Toggle";
import Tooltip from "@/components/elements/Tooltip";
import { TextMuted } from "@/components/elements/Typography";
import ImportIcon from "@/components/icons/ImportIcon";
import cn from "@/helpers/classNames";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useInterval } from "@/hooks/useInterval";
import { IntegrationLogoBox } from "@/integrations";
import { ViewItemType } from "@/pages/ModelTool/Sidebar/SidebarFactory";
import {
  extractWeldTags,
  useGetQueryDependencies,
} from "@/pages/ModelTool/useQueryDependencies";
import { useRawViewsMap } from "@/pages/ModelTool/useRawViews";
import { ArrowUpIcon, PlusIcon } from "@heroicons/react/16/solid";
import { DocumentTextIcon } from "@heroicons/react/24/outline";

import { useAiAssistantModelContext } from "../../providers/AiAssistantModelContextProvider";
import { useChatContext } from "../../providers/ChatProvider";
import { SubmitMessageResult } from "../../useChat";
import { AdvancedMessageInput } from "../AdvancedMessageInput";
import { EmptyContextAlert, SlideInOutTransition } from "./EmptyContextAlert";
import { HowToGuideDialog } from "./HowToGuide";
import { ModelDisplayName } from "./ModelDisplayName";
import { ViewsSelectorDialog } from "./views-selector/ViewsSelectorDialog";

function useDetectEmptyContext(params: {
  promptText: string;
  dataSources: ViewItemType["rawView"][];
  models: ModelListItemFragment[];
  includeModelAsContext: boolean;
}) {
  const modelContext = useAiAssistantModelContext();
  const getQueryDependencies = useGetQueryDependencies();

  const [modelSql, setModelSql] = useState<string | undefined>(
    modelContext.model?.getTextValue(),
  );

  useInterval(() => {
    setModelSql(modelContext.model?.getTextValue());
  }, 1000);

  // Are there any data sources selected?
  if (params.dataSources.length > 0) {
    return false;
  }

  // Are there any models selected?
  if (params.models.length > 0) {
    return false;
  }

  // Does the prompt contain table references?
  const promptDeps = getQueryDependencies(extractWeldTags(params.promptText));
  if (promptDeps.filter((dep) => dep.type !== "missing_reference").length > 0) {
    return false;
  }

  // Is the current model empty?
  if (
    params.includeModelAsContext &&
    modelSql != null &&
    modelSql.trim().length > 0
  ) {
    return false;
  }
  return true;
}

function useTableReferences(params: {
  promptText: string;
  dataSources: ViewItemType["rawView"][];
  models: ModelListItemFragment[];
  includeModelAsContext: boolean;
}): (QueryDependencyInput & { integrationId?: string })[] {
  const modelContext = useAiAssistantModelContext();
  const getQueryDependencies = useGetQueryDependencies();
  const { rawViews } = useRawViewsMap();

  return useMemo(() => {
    const promptDeps = getQueryDependencies(
      extractWeldTags(params.promptText),
    ).map((x) => ({
      ...x,
      integrationId: rawViews.get(x.dwItemId)?.integrationId ?? undefined,
    }));
    const dataSourceDeps = params.dataSources.flatMap((source) => ({
      dwItemId: source.viewId,
      weldTag: source.weldTag,
      type: DependencyType.RawView,
      integrationId: source.integrationId ?? undefined,
    }));

    const modelDeps = params.models
      .map((model) => ({
        dwItemId: model.id,
        weldTag: (model.dwSync ?? model.dwTable)?.weldTag,
        type:
          model.materializationType === MaterializationType.Table
            ? DependencyType.MaterializedTable
            : DependencyType.ModelView,
      }))
      .filter((x): x is QueryDependencyInput => x.weldTag != null);

    const currentModelDeps = (
      params.includeModelAsContext
        ? (modelContext.model?.dependencies ?? [])
        : []
    ).map((x) => ({
      ...x,
      integrationId: rawViews.get(x.dwItemId)?.integrationId ?? undefined,
    }));
    return uniqBy(
      [...promptDeps, ...dataSourceDeps, ...modelDeps, ...currentModelDeps],
      (x) => x.weldTag,
    ).filter((dep) => dep.type !== "missing_reference");
  }, [
    rawViews,
    modelContext,
    getQueryDependencies,
    params.promptText,
    params.dataSources,
    params.models,
    params.includeModelAsContext,
  ]);
}

function useTablesSummary(references: ReturnType<typeof useTableReferences>) {
  return useMemo(() => {
    const models = references.filter(
      (x) =>
        x.type === DependencyType.ModelView ||
        x.type === DependencyType.MaterializedTable,
    );
    const rawViews = references.filter(
      (x) => x.type === DependencyType.RawView,
    );
    return {
      models: models,
      rawViews: Object.values(groupBy(rawViews, (x) => x.integrationId)).map(
        (group) => {
          const integrationId = group[0]?.integrationId;
          return {
            integrationId,
            isImported: integrationId != null,
            tables: group,
          };
        },
      ),
    };
  }, [references]);
}

export function AiChatPrompt(props: {
  advancedInputEnabled: boolean;
  loading?: boolean;
  onSubmit: (params: {
    message: string;
    tables: string[];
    includeModelAsContext: boolean;
  }) => Promise<SubmitMessageResult | undefined>;
}) {
  const [value, setValue] = useState("");
  const [includeModelAsContext, setIncludeModelAsContext] = useState(true);

  const [selectedViews, setSelectedViews] = useState<{
    views: ViewItemType["rawView"][];
    models: ModelListItemFragment[];
  }>({
    views: [],
    models: [],
  });

  const [isEmptyContextDialogDismissed, setIsEmptyContextDialogDismissed] =
    useState(false);

  const { thread } = useChatContext();
  const modelContext = useAiAssistantModelContext();

  const guideDisclosure = useDisclosure();
  const dataSourcesSelectDisclosure = useDisclosure();

  const emptyContextDetected = useDetectEmptyContext({
    dataSources: selectedViews.views,
    models: selectedViews.models,
    promptText: value,
    includeModelAsContext,
  });

  const tableReferences = useTableReferences({
    dataSources: selectedViews.views,
    models: selectedViews.models,
    promptText: value,
    includeModelAsContext,
  });

  const summary = useTablesSummary(
    thread
      ? thread.tableReferences.map((x: any) => ({
          dwItemId: x.id,
          weldTag: x.weldTag,
          type: x.type,
          integrationId: x.integrationId,
        }))
      : tableReferences,
  );

  const hasSelectedContext =
    summary.models.length > 0 || summary.rawViews.length > 0;

  const handleSubmit = async () => {
    if (!hasSelectedContext) {
      dataSourcesSelectDisclosure.onOpen();
      return;
    }
    if (value.trim().length === 0) return;
    const tempValue = value;
    setValue("");

    const tables = selectedViews.views
      .map((x) => x.weldTag)
      .concat(
        selectedViews.models
          .map((x) => x.dwTable?.weldTag ?? x.dwSync?.weldTag)
          .filter((x) => x != null) as string[],
      );

    const result = await props.onSubmit({
      message: value,
      tables: tables,
      includeModelAsContext: props.advancedInputEnabled
        ? includeModelAsContext
        : false,
    });
    if (result?.error) {
      setValue(tempValue);
    }
  };

  return (
    <>
      <HowToGuideDialog {...guideDisclosure} />
      <ViewsSelectorDialog
        modalProps={dataSourcesSelectDisclosure}
        selected={selectedViews}
        onSelected={(sources) => {
          setSelectedViews(sources);
          dataSourcesSelectDisclosure.onClose();
        }}
      />
      <div className="relative flex flex-col gap-2">
        {props.advancedInputEnabled && (
          <>
            {emptyContextDetected && !isEmptyContextDialogDismissed ? (
              <div className="absolute bottom-full mb-2">
                <SlideInOutTransition show>
                  <EmptyContextAlert
                    onLearnMore={() => guideDisclosure.onOpen()}
                    onSelectDataSources={() =>
                      dataSourcesSelectDisclosure.onOpen()
                    }
                    onDismiss={() => setIsEmptyContextDialogDismissed(true)}
                  />
                </SlideInOutTransition>
              </div>
            ) : (
              <div className="relative rounded border px-3 py-2">
                <TextMuted as="div" className="mb-1 text-1xs font-semibold">
                  CONTEXT
                </TextMuted>
                <div className="absolute right-3 top-0">
                  <Button
                    variant="link"
                    size="xs"
                    onClick={() => {
                      guideDisclosure.onOpen();
                    }}
                    className="text-muted-foreground"
                  >
                    Learn more
                  </Button>
                </div>
                <div className="space-y-1 text-sm">
                  {modelContext.model?.id && (
                    <div className="flex w-full items-center gap-2">
                      <Tooltip content="Include SQL of the current model, along with the schemas of its dependencies, in the AI prompt.">
                        <div className="min-w-0 font-medium">
                          <label
                            className="flex items-center space-x-1 overflow-hidden whitespace-nowrap"
                            htmlFor="toggle-model-as-context"
                          >
                            <span>Include</span>
                            <span
                              className={cn("overflow-hidden", {
                                "opacity-60": !includeModelAsContext,
                              })}
                            >
                              <ModelDisplayName
                                model={modelContext.model}
                                className="underline decoration-dashed underline-offset-4 opacity-80"
                              />
                            </span>
                          </label>
                        </div>
                      </Tooltip>
                      <Toggle
                        checked={includeModelAsContext}
                        onChange={() =>
                          setIncludeModelAsContext((prev) => !prev)
                        }
                        size="sm"
                        id="toggle-model-as-context"
                        className="ml-auto"
                      />
                    </div>
                  )}
                  <div>
                    {tableReferences.length === 0 ? (
                      <div className="flex min-h-5">
                        <Button
                          variant="link"
                          size="xs"
                          onClick={() => {
                            dataSourcesSelectDisclosure.onOpen();
                          }}
                          icon={<PlusIcon />}
                        >
                          Add tables to context
                        </Button>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2">
                        <div className="flex items-center">
                          <div className="font-medium">Tables:</div>
                          <div className="ml-2 flex gap-0.5">
                            {summary.models.length > 0 && (
                              <Tooltip
                                content={
                                  <div className="flex flex-col gap-1">
                                    {summary.models.map((x) => (
                                      <span key={x.dwItemId}>{x.weldTag}</span>
                                    ))}
                                  </div>
                                }
                              >
                                <IconWithBG
                                  icon={<DocumentTextIcon className="h-4/5" />}
                                  size="1xs"
                                  color="#e7e8eb"
                                />
                              </Tooltip>
                            )}
                            {summary.rawViews.map((group) => (
                              <Tooltip
                                content={
                                  <div className="flex flex-col gap-1">
                                    {group.tables.map((x) => (
                                      <span key={x.dwItemId}>{x.weldTag}</span>
                                    ))}
                                  </div>
                                }
                                key={group.integrationId}
                              >
                                <div>
                                  {group.integrationId ? (
                                    <IntegrationLogoBox
                                      id={group.integrationId}
                                      size="1xs"
                                    />
                                  ) : (
                                    <IconWithBG
                                      icon={<ImportIcon />}
                                      size="1xs"
                                      className="bg-gray-200 dark:bg-gray-700"
                                    />
                                  )}
                                </div>
                              </Tooltip>
                            ))}
                          </div>
                        </div>
                        <Button
                          variant="link"
                          size="xs"
                          onClick={() => {
                            dataSourcesSelectDisclosure.onOpen();
                          }}
                          className="ml-auto"
                        >
                          Edit tables
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {thread &&
          (thread.tableReferences.length > 0 ||
            thread.initialModelContext != null) && (
            <div className="relative rounded border px-3 py-2">
              <TextMuted as="div" className="mb-1 text-1xs font-semibold">
                CONTEXT
              </TextMuted>
              {thread?.initialModelContext && (
                <div className="flex items-center gap-2 text-sm">
                  <div className="flex items-center gap-1">
                    <div className="font-medium">Model: </div>
                    <Tooltip
                      content={
                        <div className="flex flex-col gap-1">
                          <span>{thread.initialModelContext.name}</span>
                        </div>
                      }
                    >
                      <div className="w-full">
                        <ModelDisplayName model={thread.initialModelContext} />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              )}
              {thread.tableReferences.length > 0 && (
                <div className="flex items-center gap-2 text-sm">
                  <div className="flex items-center">
                    <div className="font-medium">Tables:</div>
                    <div className="ml-2 flex gap-0.5">
                      {summary.models.length > 0 && (
                        <Tooltip
                          content={
                            <div className="flex flex-col gap-1">
                              {summary.models.map((x) => (
                                <span key={x.weldTag}>{x.weldTag}</span>
                              ))}
                            </div>
                          }
                        >
                          <IconWithBG
                            icon={<DocumentTextIcon className="h-4/5" />}
                            size="1xs"
                            color="#e7e8eb"
                          />
                        </Tooltip>
                      )}
                      {summary.rawViews.map((group) => (
                        <Tooltip
                          content={
                            <div className="flex flex-col gap-1">
                              {group.tables.map((x) => (
                                <span key={x.dwItemId}>{x.weldTag}</span>
                              ))}
                            </div>
                          }
                          key={group.integrationId}
                        >
                          <div>
                            {group.integrationId ? (
                              <IntegrationLogoBox
                                id={group.integrationId}
                                size="1xs"
                              />
                            ) : (
                              <IconWithBG
                                icon={<ImportIcon />}
                                size="1xs"
                                className="bg-gray-200 dark:bg-gray-700"
                              />
                            )}
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        <div data-stonly="ai-assistant__user-input" className="relative">
          {props.advancedInputEnabled ? (
            <AdvancedMessageInput
              value={value}
              onChange={setValue}
              onEnterKeyDown={() => {
                handleSubmit();
              }}
              placeholder={`Ask a question about ${
                modelContext.model?.name
                  ? `the ${modelContext.model.name} model or any`
                  : "any"
              } {{raw.datasource}} or {{folder.model}}`}
              className="relative h-24 overflow-hidden rounded-md border focus-within:ring-2 focus:ring-primary dark:border-gray-700"
            />
          ) : (
            <textarea
              autoFocus
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyDownCapture={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              rows={4}
              className={cn(
                "block w-full resize-none rounded-md border bg-white py-2 pl-4 pr-14 text-sm focus-within:ring-2 focus-visible:outline-none dark:border-gray-700 dark:bg-[#1e1e1e]",
              )}
              placeholder=""
            />
          )}
          <Tooltip
            content={
              !hasSelectedContext
                ? "Please select tables to improve SQL query assistance"
                : null
            }
          >
            <div className="absolute bottom-2 right-2 rounded">
              <IconButton
                colorScheme="primary"
                variant="solid"
                size="sm"
                icon={<ArrowUpIcon />}
                onClick={() => handleSubmit()}
                isLoading={props.loading}
                isDisabled={value.trim().length === 0}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  );
}
