import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { EyeIcon } from "@heroicons/react/24/solid";
import { createAction, Priority, useRegisterActions } from "kbar";
import { useFocusMode } from "@/pages/ModelTool/hooks/useFocusMode";
import { useDarkMode } from "@/providers/DarkModeProvider";

export default function useSystemActions() {
  const { setIsDarkModeEnabled, isDarkModeEnabled } = useDarkMode();
  const [, setFocusMode] = useFocusMode();

  useRegisterActions(
    [
      createAction({
        name: `Enable ${isDarkModeEnabled ? "light" : "dark"} mode`,
        keywords: "dark light mode toggle theme",
        perform: () => setIsDarkModeEnabled((prev) => !prev),
        priority: Priority.HIGH,
        section: "Preferences",
        icon: isDarkModeEnabled ? (
          <SunIcon className="w-4" />
        ) : (
          <MoonIcon className="w-4" />
        ),
      }),
      createAction({
        name: `Toggle focus mode`,
        keywords: "focus mode full screen",
        priority: Priority.HIGH,
        icon: <EyeIcon className="w-4" />,
        perform: () => {
          setFocusMode((prev) => !prev);
        },
        section: "Preferences",
      }),
    ],
    [isDarkModeEnabled, setFocusMode],
  );
}
