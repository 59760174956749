export const GreenOneEye1 = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      {...props}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1737_12800"
        style={{ maskType: "alpha" }}
        width="50"
        height="50"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect width="50" height="50" fill="#C4C4C4" rx="2"></rect>
      </mask>
      <g mask="url(#mask0_1737_12800)">
        <ellipse
          cx="24.724"
          cy="25.379"
          fill="#4DE390"
          rx="51.724"
          ry="41.379"
        ></ellipse>
        <ellipse
          cx="38.601"
          cy="24.601"
          fill="#3DB573"
          rx="35.238"
          ry="26.54"
          transform="rotate(-45 38.601 24.601)"
        ></ellipse>
        <path
          stroke="#4DE390"
          strokeWidth="14"
          d="M17.917 31.417l7.22 5.727c3.983 3.158 9.789 2.394 12.819-1.687l18.961-25.54"
        ></path>
        <ellipse
          fill="#fff"
          rx="10.761"
          ry="10.761"
          transform="rotate(-89.606 15.385 .443) skewX(.058)"
        ></ellipse>
        <ellipse
          fill="#32130F"
          rx="5.566"
          ry="5.566"
          transform="rotate(-89.606 15.385 .443) skewX(.058)"
        ></ellipse>
      </g>
    </svg>
  );
};
