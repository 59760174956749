import LoadingSpinner from "@/components/elements/LoadingSpinner";
import CheckboxInput from "@/components/modules/FormFactory/FormCheckboxInput";
import CopyTextInput from "@/components/modules/FormFactory/FormCopyTextInput";
import FormTextInput from "@/components/modules/FormFactory/FormTextInput";
import { FormType } from "@/integrations";
import { FC } from "react";
import { Suspense, lazy } from "react";

import BigQueryForm from "./BigQueryForm";
import FacebookAdsForm from "./FacebookAdsForm/FacebookAdsForm";
import FormHiddenInput from "./FormHiddenInput";
import { FormRepeater } from "./FormRepeater";
import GoogleAnalytics4Form from "./GoogleAnalyticsForm/GoogleAnalytics4Form";
import OAuthForm from "./OAuthForm";
import SelectForm from "./SelectForm";
import SettingsResult from "./SettingsResult";
import { SubForm } from "./SubForm";
import AppStoreConnectForm from "./AppStoreConnectForm";
import GoogleAdsForm from "./GoogleAdsForm/GoogleAdsForm";
import OpenWeatherForm from "./OpenWeatherForm/OpenWeatherForm";
import { GoogleDriveForm } from "./GoogleDriveForm/GoogleDriveForm";
import KlaviyoForm from "./KlaviyoForm/KlaviyoForm";

const GoogleAnalyticsForm = lazy(
  () => import("./GoogleAnalyticsForm/GoogleAnalyticsForm"),
);

interface FormFactoryProps {
  form: FormType;
}

const FormFactory: FC<FormFactoryProps> = ({ form }) => {
  switch (form.type) {
    case "repeater":
      return <FormRepeater form={form} />;
    case "sub_form":
      return <SubForm form={form} />;
    case "text":
    case "email":
    case "password":
    case "number":
      return <FormTextInput field={form} />;
    case "hidden":
      return <FormHiddenInput field={form} />;
    case "copy":
      return <CopyTextInput field={form} />;
    case "checkbox":
      return <CheckboxInput field={form} />;
    case "big-query-form":
      return <BigQueryForm />;
    case "dropdown":
      return <SelectForm field={form} />;
    case "oauth":
      return <OAuthForm field={form} />;
    case "settings-result":
      return <SettingsResult field={form} />;
    case "google-analytics-form":
      return (
        <Suspense
          fallback={
            <div className="flex w-full justify-center">
              <LoadingSpinner />
            </div>
          }
        >
          <GoogleAnalyticsForm field={form} />
        </Suspense>
      );
    case "google-analytics-4-form":
      return (
        <Suspense
          fallback={
            <div className="flex w-full justify-center">
              <LoadingSpinner />
            </div>
          }
        >
          <GoogleAnalytics4Form field={form} />
        </Suspense>
      );
    case "facebook-ads-form":
      return (
        <Suspense
          fallback={
            <div className="flex w-full justify-center">
              <LoadingSpinner />
            </div>
          }
        >
          <FacebookAdsForm field={form} />
        </Suspense>
      );
    case "google-ads-form":
      return (
        <Suspense
          fallback={
            <div className="flex w-full justify-center">
              <LoadingSpinner />
            </div>
          }
        >
          <GoogleAdsForm field={form} />
        </Suspense>
      );
    case "google-drive-form":
      return (
        <Suspense
          fallback={
            <div className="flex w-full justify-center">
              <LoadingSpinner />
            </div>
          }
        >
          <GoogleDriveForm field={form} />
        </Suspense>
      );
    case "openweather-form":
      return (
        <Suspense
          fallback={
            <div className="flex w-full justify-center">
              <LoadingSpinner />
            </div>
          }
        >
          <OpenWeatherForm field={form} />
        </Suspense>
      );
    case "klaviyo-form":
      return (
        <Suspense
          fallback={
            <div className="flex w-full justify-center">
              <LoadingSpinner />
            </div>
          }
        >
          <KlaviyoForm field={form} />
        </Suspense>
      );
    case "app-store-connect-form":
      return <AppStoreConnectForm />;
  }
};

export default FormFactory;
