import {
  CollapsibleAlertTable,
  useEltSyncNotifications,
} from "@/features/notifications";

const EltSyncNotifications = (props: {
  eltSyncs: Parameters<typeof useEltSyncNotifications>[0];
}) => {
  const { notifications } = useEltSyncNotifications(props.eltSyncs);
  if (notifications.count === 0) {
    return null;
  }
  return (
    <CollapsibleAlertTable
      notifications={notifications}
      triggerText={`${
        notifications.count === 1
          ? `${notifications.count} error`
          : `${notifications.count} errors`
      } affecting your syncs`}
    />
  );
};

export default EltSyncNotifications;
