import dayjs from "dayjs";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDrag, useDrop } from "react-dnd";

import {
  EltSyncsQuery,
  GetRawViewsQuery,
  ListModelsQuery,
  ListSyncsQuery,
  MaterializationType,
} from "@/apollo/types";
import {
  useOpenDashboardInTab,
  useOpenDraftInTab,
  useOpenModelInTab,
} from "@/components/modules/ModelTabs";
import { folderHasNewTable } from "@/features/product-guide/guides/FirstEltSynced/useFirstEltSyncedGuide";
import classNames from "@/helpers/classNames";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/24/solid";

import { useDoubleClickable } from "../../../hooks/useDoubleClickable";
import { useSchemaSidebar } from "../ModelEditorStore";
import { SaveModelModal } from "../QueryEditor/SaveModelModal";
import { DraftModel, useHideModelDraft } from "../QueryEditor/useModelDraft";
import { Dashboard } from "../Visualize/VisualizationType";
import EltSyncStatusLED from "../components/EltSyncStatusLED";
import { FolderEmojiPicker, ModelEmojiPicker } from "../components/EmojiPicker";
import { FolderIcon } from "../components/FolderIcon";
import MaterializationInfo from "../components/MaterializationInfo";
import { ModelIcon } from "../components/ModelIcon/ModelIcon";
import ModelSVGIcon from "../components/ModelIcon/ModelSVGIcon";
import { useFullModelPathRenderer } from "../hooks/useFullPathNames";
import { DashboardItem } from "./dashboards/DashboardItem";
import {
  EltStreamClickable,
  EltStreamItem,
  RawViewItem,
} from "./data-sources/DataSourcesItems";
import { RawFolderOptions } from "./data-sources/RawDataFolderOptions";
import { ModelFolderOptions } from "./models/ModelFolderOptions";
import { ModelOptions, useMoveModel } from "./models/ModelOptions";
import NewItem from "./models/NewItem";
import { useNewSidebarItemState } from "./useNewSidebarItemState";

export type FolderItemType = {
  itemType: "folder";
  id: string;
  emoji?: string;
  folder: {
    id: string;
    name: string;
    parentId?: string;
    folderType: "raw" | "model" | "export";
    syncId?: string; // only for folders with sync
    integrationId?: string; // only for data folders with sync
    syncPaused?: boolean; // only for data folders with sync
  };
  children: SidebarItemType[];
  icon?: React.ReactElement;
};

export type ModelItemType = {
  itemType: "model";
  id: string;
  model: ListModelsQuery["models"][0];
};

export type DraftModelItemType = {
  itemType: "draft";
  id: string;
  draftModel: DraftModel;
};

export type ViewItemType = {
  itemType: "view";
  id: string;
  rawView: GetRawViewsQuery["getRawViews"][0];
  isViewImported?: boolean;
};

export type EltStreamItemType = {
  itemType: "elt-stream";
  id: string;
  streamName: string;
  sync: EltSyncsQuery["eltSyncs"][0];
};

export type ExportItemType = {
  itemType: "export";
  id: string;
  sync: ListSyncsQuery["syncs"][0];
  label: string;
};

export type DashboardItemType = {
  itemType: "dashboard";
  id: string;
  dashboard: Dashboard;
};

export type GroupItemType = {
  itemType: "group";
  id: string;
  name: string;
  renderHeader?: (props: {
    open: boolean;
    item: GroupItemType;
  }) => React.ReactNode;
  options?: React.ReactElement;
  children: Exclude<SidebarItemType, GroupItemType>[];
  emptyContent?: React.ReactElement;
};

export type SidebarItemType =
  | FolderItemType
  | ModelItemType
  | ViewItemType
  | EltStreamItemType
  | DraftModelItemType
  | GroupItemType
  | ExportItemType
  | DashboardItemType;

type SidebarFactoryProps = {
  item: SidebarItemType;
  openItems: string[];
  onToggleFolder: (id: string) => void;
  onFocus: (id: string) => void;
  onSelectItem?: (item: SidebarItemType) => void;
  selectedId?: string | undefined;
  highlightedId?: string | undefined;
  setHighlightedId: (id: string) => void;
  level: number;
};

const ROOT_INDENT_REM = 0.25;
const INDENT_REM = 1;

function calculateIndentation(level: number) {
  return ROOT_INDENT_REM + level * INDENT_REM;
}

function IndentedBlock({
  level,
  ...props
}: { level: number } & React.ComponentProps<"div">) {
  const indent = calculateIndentation(level);
  return <div style={{ paddingLeft: `${indent}rem` }} {...props} />;
}

export const SidebarItemFactory = forwardRef<
  HTMLDivElement,
  SidebarFactoryProps
>((props, higlightedRef) => {
  const item: SidebarItemType = props.item;
  const [, setSchemaSidebar] = useSchemaSidebar();
  const { item: newItem } = useNewSidebarItemState();

  const isHighlighted = props.highlightedId === item.id;
  const isSelected = props.selectedId === item.id;

  const openModelInTab = useOpenModelInTab();
  const openDashboardInTab = useOpenDashboardInTab();
  const openDraftInTab = useOpenDraftInTab();

  switch (item.itemType) {
    case "group": {
      const open = props.openItems.includes(item.id);
      const isGroupEmpty = item.children.length === 0;
      return (
        <>
          <SidebarItemContainer
            level={props.level}
            onSelect={() => props.onToggleFolder(item.id)}
            isSelected={isSelected}
            isHighlighted={isHighlighted}
            onFocus={() => props.onFocus(item.id)}
          >
            {typeof item.renderHeader === "function" ? (
              item.renderHeader({ open, item })
            ) : (
              <GroupItem open={open} item={item} />
            )}
          </SidebarItemContainer>
          {isGroupEmpty && <div>{item.emptyContent}</div>}
          {open &&
            item.children.map((item) => (
              <SidebarItemFactory
                key={item.id}
                {...props}
                item={item}
                level={props.level + 1}
                ref={higlightedRef}
              />
            ))}
        </>
      );
    }
    case "folder":
      const open = props.openItems.includes(item.id);
      return (
        <>
          <SidebarItemContainer
            level={props.level}
            isHighlighted={isHighlighted}
            isSelected={isSelected}
            onFocus={() => props.onFocus(item.id)}
            onSelect={() => props.onToggleFolder(item.id)}
            ref={isHighlighted ? higlightedRef : undefined}
            addingNewItem={!!newItem}
          >
            <FolderItem open={open} item={item} />
          </SidebarItemContainer>

          {newItem?.folderId === item.id && (
            <SidebarNewItem level={props.level} newItem={newItem} />
          )}

          {open && !item.children?.length && (
            <IndentedBlock
              level={props.level + 1}
              className="truncate pb-2 pt-1 text-xs text-gray-500"
            >
              No models yet
            </IndentedBlock>
          )}
          {open &&
            item.children?.map((item) => (
              <SidebarItemFactory
                key={item.id}
                {...props}
                item={item}
                level={props.level + 1}
                ref={higlightedRef}
              />
            ))}
        </>
      );
    case "model":
      return (
        <SidebarItemContainer
          level={props.level}
          isHighlighted={isHighlighted}
          isSelected={isSelected}
          onFocus={() => props.onFocus(item.id)}
          onMetaClick={() => {
            openModelInTab({ modelId: item.id });
          }}
          onShiftClick={() => {
            if (item.model.dwSync || item.model.dwTable) {
              setSchemaSidebar(item.id);
            }
          }}
          onSelect={() => {
            openModelInTab({ modelId: item.id });
          }}
          ref={isHighlighted ? higlightedRef : undefined}
          addingNewItem={!!newItem}
        >
          <ModelItem
            isHighlighted={isHighlighted}
            model={item.model}
            isSelected={isSelected}
          />
        </SidebarItemContainer>
      );
    case "view":
      return (
        <SidebarItemContainer
          level={props.level}
          isHighlighted={isHighlighted}
          isSelected={isSelected}
          onFocus={() => props.onFocus(item.id)}
          onSelect={() => {
            setSchemaSidebar(item.rawView.viewId);
          }}
          ref={isHighlighted ? higlightedRef : undefined}
          addingNewItem={!!newItem}
        >
          <RawViewItem item={item} />
        </SidebarItemContainer>
      );
    case "elt-stream":
      return (
        <EltStreamClickable item={item}>
          <SidebarItemContainer
            level={props.level}
            isHighlighted={isHighlighted}
            isSelected={isSelected}
            onFocus={() => props.onFocus(item.id)}
            onSelect={() => {
              //Do nothing, because we have the clickable wrapper handling clicks for elt-stream items
            }}
            ref={isHighlighted ? higlightedRef : undefined}
          >
            <EltStreamItem item={item} />
          </SidebarItemContainer>
        </EltStreamClickable>
      );
    case "draft":
      return (
        <SidebarItemContainer
          level={props.level}
          isHighlighted={isHighlighted}
          isSelected={isSelected}
          onFocus={() => props.onFocus(item.id)}
          onMetaClick={() => {
            openDraftInTab(item.id, item.draftModel.number, true);
          }}
          onSelect={() => {
            openDraftInTab(item.id, item.draftModel.number);
          }}
          ref={isHighlighted ? higlightedRef : undefined}
          addingNewItem={!!newItem}
        >
          <DraftItem
            isHighlighted={isHighlighted}
            isSelected={isSelected}
            draftModel={item.draftModel}
          />
        </SidebarItemContainer>
      );
    case "export":
      return (
        <SidebarItemContainer
          level={props.level}
          isHighlighted={isHighlighted}
          isSelected={isSelected}
          onFocus={() => props.onFocus(item.id)}
          onSelect={() => {
            props.onSelectItem?.(item);
          }}
          ref={isHighlighted ? higlightedRef : undefined}
          addingNewItem={!!newItem}
        >
          <ExportItem item={item} />
        </SidebarItemContainer>
      );
    case "dashboard":
      return (
        <SidebarItemContainer
          level={props.level}
          isHighlighted={isHighlighted}
          isSelected={isSelected}
          onFocus={() => props.onFocus(item.id)}
          onSelect={() => {
            openDashboardInTab(item.id, item.dashboard.name);
          }}
          onMetaClick={() => {
            openDashboardInTab(item.id, item.dashboard.name, true);
          }}
          ref={isHighlighted ? higlightedRef : undefined}
          addingNewItem={!!newItem}
        >
          <DashboardItem
            isHighlighted={isHighlighted}
            dashboard={item.dashboard}
            isSelected={isSelected}
          />
        </SidebarItemContainer>
      );
  }
});

type SideBarItemProps = {
  level: number;
  onSelect: () => void;
  onDoubleClick?: () => void;
  onMetaClick?: () => void;
  onShiftClick?: () => void;
  isSelected: boolean;
  isHighlighted: boolean;
  onFocus: () => void;
  children?: React.ReactNode;
  addingNewItem?: boolean;
};

export const SidebarNewItem = (props: {
  newItem: { itemType: "model" | "folder"; folderId?: string };
  level: number;
}) => {
  return (
    <IndentedBlock
      level={props.level + 1}
      className="relative bg-white dark:bg-gray-800"
    >
      <NewItem newItem={props.newItem} />
    </IndentedBlock>
  );
};

export const SidebarItemContainer = forwardRef<
  HTMLDivElement,
  SideBarItemProps
>((props, forwardRef) => {
  // Scroll selected query into view
  const ref = useRef(forwardRef);
  useEffect(() => {
    const current = ref.current as null | HTMLDivElement;
    if (props.isHighlighted && current && current.scrollIntoView) {
      current.scrollIntoView({
        behavior: "auto",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [props.isHighlighted]);

  const { onSelect, onMetaClick, onDoubleClick, onShiftClick } = props;

  const handleSingleClick = useCallback(
    (e: React.MouseEvent) => {
      if (e.metaKey) onMetaClick?.();
      else if (e.shiftKey) onShiftClick?.();
      else onSelect();
    },
    [onMetaClick, onSelect, onShiftClick],
  );

  const handleClick = useDoubleClickable(handleSingleClick, onDoubleClick);

  return (
    <IndentedBlock
      level={props.level}
      tabIndex={1}
      ref={forwardRef}
      onClick={handleClick}
      className={classNames(
        `flex w-full cursor-pointer items-center space-x-0.5 border-b border-t py-1 pr-4 text-left text-sm hover:bg-gray-100 focus:outline-none dark:hover:bg-gray-700`,
        props.isSelected ? "bg-gray-200 bg-opacity-60 dark:bg-gray-700" : "",
        props.isHighlighted
          ? "border-gray-300 bg-gray-200 bg-opacity-60 dark:border-gray-700 dark:bg-gray-700"
          : "border-gray-50 dark:border-gray-800",
        props.addingNewItem ? "opacity-50" : "",
      )}
      onFocus={props.onFocus}
    >
      {props.children}
    </IndentedBlock>
  );
});

export const FolderOpenStateIcon = ({ open }: { open: boolean }) => {
  return (
    <div style={{ width: `${INDENT_REM}rem` }} className="shrink-0">
      <ChevronRightIcon
        className={classNames(
          "w-2.5 transform transition-transform",
          open && "rotate-90",
        )}
      />
    </div>
  );
};

export const FolderOpenStateWrapper = ({
  open,
  children,
  ...restProps
}: React.PropsWithChildren<
  { open: boolean } & React.ComponentProps<"div">
>) => {
  return (
    <div className="flex w-full items-center text-xs" {...restProps}>
      <FolderOpenStateIcon open={open} />
      {children}
    </div>
  );
};

const GroupItem = ({ open, item }: { open: boolean; item: GroupItemType }) => {
  const { name, options } = item;
  return (
    <FolderOpenStateWrapper open={open}>
      <span className="font-medium">{name}</span>
      {options && <div className="ml-auto">{options}</div>}
    </FolderOpenStateWrapper>
  );
};

export const FolderItem = (props: { open: boolean; item: FolderItemType }) => {
  const [droppedModel, setDroppedModel] =
    useState<ListModelsQuery["models"][0]>();
  const {
    handleMoveModel,
    updateModalOpen,
    loading: loadingMoveModel,
    setUpdateModalOpen,
  } = useMoveModel(droppedModel);

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: "BOX",
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (item: any) => {
      return !!item.model.publishedQuery;
    },
    drop(item: any) {
      if (!item.model) return;
      setDroppedModel(item.model);
      setUpdateModalOpen(true);
    },
  }));

  //Mark as a stonly element if this folder has available tables that where synced with ELT.
  const markForStonly =
    props.item.folder.folderType === "raw" && folderHasNewTable(props.item);

  return (
    <div
      ref={drop}
      style={{ opacity: isOver && canDrop ? 0.5 : 1 }}
      className="w-full grow py-0.5"
      data-stonly={markForStonly ? "available-data-source" : undefined}
    >
      <FolderOpenStateWrapper open={props.open}>
        <div className="flex grow gap-1.5 overflow-hidden">
          {props.item.icon ?? (
            <FolderEmojiPicker item={props.item}>
              <FolderIcon open={props.open} emoji={props.item.emoji} />
            </FolderEmojiPicker>
          )}
          <span className="truncate">{props.item.folder.name}</span>
        </div>
        <div className="flex flex-none grow items-center justify-end gap-1.5 overflow-hidden">
          {props.item.folder.folderType === "model" && (
            <ModelFolderOptions id={props.item.folder.id} item={props.item} />
          )}

          {props.item.folder.folderType === "raw" &&
            props.item.folder.syncId && (
              <EltSyncStatusLED eltSyncId={props.item.folder.syncId} />
            )}
          {props.item.folder.folderType === "raw" && (
            <RawFolderOptions
              id={props.item.folder.id}
              folderName={props.item.folder.name}
              children={
                props.item.children as (ViewItemType | EltStreamItemType)[]
              }
              integrationId={props.item.folder.integrationId}
              syncId={props.item.folder.syncId}
            />
          )}
        </div>
      </FolderOpenStateWrapper>
      {droppedModel && (
        <SaveModelModal
          key={`move-${droppedModel.id}`}
          show={updateModalOpen}
          onCancel={() => {
            setUpdateModalOpen(false);
          }}
          onSave={handleMoveModel}
          defaultFolder={props.item.folder}
          loading={loadingMoveModel}
          mode="move"
          defaultName={droppedModel.name}
        />
      )}
    </div>
  );
};

export const ModelItem = (props: {
  isHighlighted: boolean;
  isSelected: boolean;
  model: ListModelsQuery["models"][0];
}) => {
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    // "type" is required. It is used by the "accept" specification of drop targets.
    type: "BOX",
    // The collect function utilizes a "monitor" instance (see the Overview for what this is)
    // to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { model: props.model },
  }));
  return (
    <div
      ref={dragPreview}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="w-full grow py-0.5"
    >
      <div ref={!!props.model?.publishedQuery ? drag : null}>
        <div className="flex items-center gap-1.5">
          <ModelEmojiPicker model={props.model} />

          <div className="flex grow items-center overflow-hidden">
            <span className="truncate text-xs">
              {props.model.name ||
                dayjs(props.model.createdAt).format("YYYY-MM-DD HH:mm")}
            </span>
            <MaterializationInfo model={props.model} />
          </div>
          <div className="ml-auto">
            <ModelOptions isSelected={props.isSelected} model={props.model} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const DraftItem = (props: {
  isHighlighted: boolean;
  isSelected: boolean;
  draftModel: DraftModel;
}) => {
  const hideDraft = useHideModelDraft();

  return (
    <div className="w-full grow">
      <div>
        <div className="flex items-center gap-1.5 opacity-75">
          <ModelSVGIcon
            type={MaterializationType.View}
            isDraft={true}
            className={classNames(
              "h-4 w-4",
              "text-gray-600 dark:text-gray-200",
            )}
          />
          <div className="flex grow items-center overflow-hidden">
            <span className="truncate text-xs">
              {props.draftModel.name}
              {props.draftModel.number > 0 && ` (${props.draftModel.number})`}
            </span>
          </div>
          <div className="ml-auto">
            <XMarkIcon
              className="h-4 w-4"
              onClick={(e) => {
                hideDraft(props.draftModel.id);
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function ExportItem({ item }: { item: ExportItemType }) {
  const model = item.sync.model;
  const modelPath = useFullModelPathRenderer(model);
  if (model == null) return null;
  return (
    <div className="w-full overflow-hidden">
      <div className="flex items-center gap-1">
        <ModelIcon model={model} className="h-3 w-3" />
        <span className="truncate text-xs">{modelPath}</span>
      </div>
      <div className="flex items-center gap-1 pl-4">
        <ArrowRightIcon className="h-2 w-2 shrink-0" />
        <span className="truncate text-xs text-gray-500">{item.label}</span>
      </div>
    </div>
  );
}
