import { useSessionStartedMutation } from "@/apollo/types";
import { useMountEffect } from "./useMountEffect";
import * as Sentry from "@sentry/react";

const useTrackSessionStarted = () => {
  const [trackSessionStarted] = useSessionStartedMutation();
  useMountEffect(() => {
    try {
      trackSessionStarted();
    } catch (error) {
      Sentry.captureException(error);
    }
  });
};

export default useTrackSessionStarted;
