import { deriveClassed } from "@tw-classed/react";
import { classed } from "@/components/classed.config";

const InputPrimitive = classed.input(
  "form-input w-full rounded-sm outline-0 disabled:cursor-not-allowed disabled:opacity-60 focus:z-10",
  "border-gray-300 invalid:border-red-500 dark:border-gray-700 dark:bg-gray-800 placeholder-gray-400",
  "read-only:bg-gray-50 disabled:bg-gray-50 read-only:dark:bg-gray-800 disabled:dark:bg-gray-800 ",
  {
    variants: {
      size: {
        sm: "h-8",
        md: "h-10",
        lg: "h-12",
      },
    },
    defaultVariants: {
      size: "lg",
    },
  },
);

const Input = deriveClassed<typeof InputPrimitive>((props, ref) => {
  return <InputPrimitive ref={ref} type="text" {...props} />;
});

const InputNamespace = Object.assign(Input, { id: "Input" });
export { InputNamespace as Input };
