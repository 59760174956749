import * as parser from "cron-parser";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export default function cronExpressionToDayjs(
  cronExpression: string,
): dayjs.Dayjs {
  const dateString = parser
    .parseExpression(cronExpression, { utc: true })
    .next()
    .toString();
  return dayjs(dateString);
}
