import { isWindows } from "@/helpers/isWindows";
import { useEffect, useRef, useState } from "react";

export function useKeyPress(
  targetKey: string | ((e: KeyboardEvent) => boolean),
) {
  const tarketKeyRef = useRef(targetKey);
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    function downHandler(e: KeyboardEvent) {
      if (
        typeof tarketKeyRef.current === "function"
          ? tarketKeyRef.current(e)
          : e.key === tarketKeyRef.current
      ) {
        setKeyPressed(true);
      }
    }

    const upHandler = (e: KeyboardEvent) => {
      if (
        typeof tarketKeyRef.current === "function"
          ? tarketKeyRef.current(e)
          : e.key === tarketKeyRef.current
      ) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);
  return keyPressed;
}

export function useIsModKeyPressed() {
  return useKeyPress((e) => {
    if (e.type === "keydown") {
      return isWindows() ? e.ctrlKey : e.metaKey;
    }
    return isWindows() ? e.code === "ControlLeft" : e.code === "MetaLeft";
  });
}
