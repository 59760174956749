import { Transition } from "@headlessui/react";
import { Placement } from "@popperjs/core";
import classNames from "@/helpers/classNames";
import { useState, Fragment, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

const SimplePopperPanel = (
  props: PropsWithChildren<{
    isOpen: boolean;
    onClose: () => void;
    placement?: Placement;
    className?: string;
  }>,
) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "absolute",
    placement: props.placement || "top",
    modifiers: [
      { name: "preventOverflow", enabled: true },
      { name: "offset", options: { offset: [0, 4] } },
    ],
  });

  return (
    <>
      <div
        className="absolute inset-0"
        ref={setReferenceElement}
        onClick={() => props.onClose()}
      />
      {createPortal(
        <>
          <Transition
            show={props.isOpen}
            enter="transition-opacity ease-linear duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            as={Fragment}
          >
            <div
              className={classNames("z-30", props.className)}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              {props.children}
            </div>
          </Transition>
          {props.isOpen && (
            <div
              className="fixed inset-0 z-10"
              onClick={() => props.onClose()}
            />
          )}
        </>,
        document.body,
      )}
    </>
  );
};

export default SimplePopperPanel;
