import MenuItem from "@/components/elements/MenuItem";
import { useSyncScheduleContext } from "@/components/elements/SyncScheduler";
import TableMenu from "@/components/modules/TableMenu";

import { CronOption } from "../WeldSyncScheduler";

export function SyncSchedulerMenu() {
  const {
    timeOffset,
    canSelectTimeOffset,
    showTimeOffsetInput,
    mode,
    onToggleTimeOffsetInput,
    onToggleMode,
    onResetTimeOffset,
  } = useSyncScheduleContext<CronOption>();
  return (
    <TableMenu size="sm">
      {canSelectTimeOffset && !showTimeOffsetInput && (
        <MenuItem
          text="Set start time"
          onClick={() => onToggleTimeOffsetInput()}
        />
      )}
      {(timeOffset.hour !== 0 || timeOffset.minute !== 0) && (
        <MenuItem text="Reset start time" onClick={() => onResetTimeOffset()} />
      )}
      <MenuItem
        onClick={() => onToggleMode()}
        text={mode === "custom" ? "Show simple" : "Custom cron (advanced)"}
      />
    </TableMenu>
  );
}
