export const keywords = [
  "ALTER",
  "ARRAY",
  "AS",
  "ASC",
  "AT",
  "BIGDECIMAL",
  "BIGINT",
  "BIGNUMERIC",
  "BOOL",
  "BY",
  "BYTEINT",
  "BYTES",
  "CASE",
  "CREATE",
  "CURRENT",
  "CURRENT_DATE",
  "DATABASE",
  "DATE",
  "DATETIME",
  "DECIMAL",
  "DEFAULT",
  "DEFINE",
  "DELETE",
  "DESC",
  "DISTINCT",
  "DROP",
  "ELSE",
  "END",
  "ERROR",
  "ESCAPE",
  "EXCLUDE",
  "FLOAT64",
  "FOLLOWING",
  "FOR",
  "FROM",
  "GRANT",
  "GROUP",
  "HASH",
  "HAVING",
  "HOUR",
  "IF",
  "IGNORE",
  "INDEX",
  "INSERT",
  "INT",
  "INT64",
  "INTEGER",
  "INTERVAL",
  "INTO",
  "KEY",
  "LIMIT",
  "MERGE",
  "MINUTE",
  "NO",
  "NUMERIC",
  "OF",
  "OFFSET",
  "ON",
  "OPTION",
  "ORDER",
  "ORDINAL",
  "OVER",
  "PARTITION",
  "PRECEDING",
  "PRIMARY",
  "QUARTER",
  "RANGE",
  "RECURSIVE",
  "RETURN",
  "REVOKE",
  "ROLE",
  "ROWS",
  "SECOND",
  "SELECT",
  "SET",
  "SMALLINT",
  "SPLIT",
  "STRING",
  "STRUCT",
  "TABLESAMPLE",
  "THEN",
  "TIME",
  "TIMESTAMP",
  "TINYINT",
  "TO",
  "UNBOUNDED",
  "UNNEST",
  "USING",
  "VIEW",
  "WEEK",
  "WHEN",
  "WHERE",
  "WITH",
  "WITHIN",
  "FALSE",
  "TRUE",
].map((x) => x.toLowerCase());
export const operators = [
  "ALL",
  "AND",
  "ANY",
  "BETWEEN",
  "CONTAINS",
  "CROSS",
  "EXCEPT",
  "EXISTS",
  "FULL",
  "IN",
  "INNER",
  "INTERSECT",
  "IS",
  "JOIN",
  "LEFT",
  "LIKE",
  "NOT",
  "OR",
  "OUTER",
  "PIVOT",
  "RIGHT",
  "SOME",
  "UNION",
  "UNPIVOT",
].map((x) => x.toLowerCase());
export const builtinFunctions = [
  "ACOS",
  "ACOSH",
  "ANY_VALUE",
  "APPROX_COUNT_DISTINCT",
  "APPROX_QUANTILES",
  "APPROX_TOP_COUNT",
  "APPROX_TOP_SUM",
  "ARRAY_AGG",
  "ARRAY_CONCAT",
  "ARRAY_CONCAT_AGG",
  "ARRAY_LENGTH",
  "ARRAY_REVERSE",
  "ARRAY_TO_STRING",
  "ASCII",
  "ASIN",
  "ASINH",
  "ATAN",
  "ATAN2",
  "ATANH",
  "AVG",
  "BIT_AND",
  "BIT_COUNT",
  "BIT_XOR",
  "BYTE_LENGTH",
  "CAST",
  "CBRT",
  "CEIL",
  "CEILING",
  "CHAR_LENGTH",
  "CHARACTER_LENGTH",
  "CHR",
  "COALESCE",
  "CODE_POINTS_TO_BYTES",
  "CODE_POINTS_TO_STRING",
  "COLLATE",
  "CONCAT",
  "CONTAINS_SUBSTR",
  "CORR",
  "COS",
  "COSH",
  "COT",
  "COTH",
  "COUNT",
  "COUNTIF",
  "COVAR_POP",
  "COVAR_SAMP",
  "CSC",
  "CSCH",
  "CUME_DIST",
  "CURRENT_DATETIME",
  "CURRENT_TIME",
  "CURRENT_TIMESTAMP",
  "DATE_ADD",
  "DATE_DIFF",
  "DATE_FROM_UNIX_DATE",
  "DATE_SUB",
  "DATE_TRUNC",
  "DATETIME_ADD",
  "DATETIME_DIFF",
  "DATETIME_SUB",
  "DATETIME_TRUNC",
  "DENSE_RANK",
  "DETERMINISTIC_DECRYPT_STRING",
  "DETERMINISTIC_ENCRYPT",
  "DIV",
  "ENDS_WITH",
  "EXP",
  "EXTRACT",
  "FIRST_VALUE",
  "FLOOR",
  "FORMAT",
  "FORMAT_DATE",
  "FORMAT_DATETIME",
  "FORMAT_TIME",
  "FORMAT_TIMESTAMP",
  "FROM_BASE32",
  "FROM_BASE64",
  "FROM_HEX",
  "GENERATE_ARRAY",
  "GENERATE_DATE_ARRAY",
  "GENERATE_TIMESTAMP_ARRAY",
  "GENERATE_UUID",
  "GREATEST",
  "GROUPING",
  "IEEE_DIVIDE",
  "IFNULL",
  "INITCAP",
  "INSTR",
  "IS_INF",
  "IS_NAN",
  "JSON_EXTRACT",
  "JSON_EXTRACT_ARRAY",
  "JSON_EXTRACT_SCALAR",
  "JSON_EXTRACT_STRING_ARRAY",
  "JSON_QUERY",
  "JSON_QUERY_ARRAY",
  "JSON_TYPE",
  "JSON_VALUE",
  "JSON_VALUE_ARRAY",
  "JUSTIFY_DAYS",
  "JUSTIFY_HOURS",
  "JUSTIFY_INTERVAL",
  "LAG",
  "LAST_DAY",
  "LAST_VALUE",
  "LEAD",
  "LEAST",
  "LENGTH",
  "LN",
  "LOG",
  "LOG10",
  "LOGICAL_AND",
  "LOGICAL_OR",
  "LOWER",
  "LPAD",
  "LTRIM",
  "MAKE_INTERVAL",
  "MAX",
  "MD5",
  "MIN",
  "MOD",
  "MONTH",
  "NORMALIZE",
  "NORMALIZE_AND_CASEFOLD",
  "NTH_VALUE",
  "NTILE",
  "NULLIF",
  "OCTET_LENGTH",
  "PARSE_BIGNUMERIC",
  "PARSE_DATE",
  "PARSE_DATETIME",
  "PARSE_JSON",
  "PARSE_NUMERIC",
  "PARSE_TIME",
  "PARSE_TIMESTAMP",
  "PERCENT_RANK",
  "PERCENTILE_CONT",
  "PERCENTILE_DISC",
  "POW",
  "POWER",
  "RAND",
  "RANGE_BUCKET",
  "RANK",
  "REGEXP_CONTAINS",
  "REGEXP_EXTRACT",
  "REGEXP_EXTRACT_ALL",
  "REGEXP_INSTR",
  "REGEXP_REPLACE",
  "REGEXP_SUBSTR",
  "REPEAT",
  "REPLACE",
  "REVERSE",
  "ROUND",
  "ROW_NUMBER",
  "RPAD",
  "RTRIM",
  "SAFE_ADD",
  "SAFE_CAST",
  "SAFE_CONVERT_BYTES_TO_STRING",
  "SAFE_DIVIDE",
  "SAFE_MULTIPLY",
  "SAFE_NEGATE",
  "SAFE_OFFSET",
  "SAFE_ORDINAL",
  "SAFE_SUBTRACT",
  "SECH",
  "SESSION_USER",
  "SHA1",
  "SHA256",
  "SHA512",
  "SIGN",
  "SIN",
  "SINH",
  "SOUNDEX",
  "SQRT",
  "STARTS_WITH",
  "STDDEV",
  "STDDEV_POP",
  "STDDEV_SAMP",
  "STRING_AGG",
  "STRPOS",
  "SUBSTR",
  "SUBSTRING",
  "SUM",
  "TAN",
  "TANH",
  "TIME_ADD",
  "TIME_DIFF",
  "TIME_SUB",
  "TIME_TRUNC",
  "TIMESTAMP_ADD",
  "TIMESTAMP_DIFF",
  "TIMESTAMP_MICROS",
  "TIMESTAMP_MILLIS",
  "TIMESTAMP_SECONDS",
  "TIMESTAMP_SUB",
  "TIMESTAMP_TRUNC",
  "TO_BASE32",
  "TO_BASE64",
  "TO_CODE_POINTS",
  "TO_HEX",
  "TO_JSON",
  "TO_JSON_STRING",
  "TRIM",
  "TRUNC",
  "UNICODE",
  "UNIX_DATE",
  "UNIX_MICROS",
  "UNIX_MILLIS",
  "UNIX_SECONDS",
  "UPDATE",
  "UPPER",
  "VAR_POP",
  "VAR_SAMP",
  "VARIANCE",
  "YEAR",
].map((x) => x.toLowerCase());
