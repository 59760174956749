import useWeldAPI from "@/hooks/useWeldAPI";
import { useCallback } from "react";

import { useCompletionStream } from "./useCompletionStream";

export function useGenModelDocsStream(options?: {
  onChunk?: (chunk: string) => void;
}) {
  const fetchApi = useWeldAPI();
  const [processStream, meta] = useCompletionStream({
    onChunk: options?.onChunk,
  });
  return [
    useCallback(
      async (modelId: string) => {
        const response = await fetchApi("/ai-documentation-generator/model", {
          method: "POST",
          body: JSON.stringify({
            modelId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const reader = response.body?.getReader();
        if (reader) {
          processStream(reader);
        }
      },
      [fetchApi, processStream],
    ),
    meta,
  ] as const;
}
