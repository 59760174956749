import dayjs from "dayjs";
import {
  Action,
  Priority,
  VisualState,
  createAction,
  useKBar,
  useRegisterActions,
} from "kbar";
import { useEffect, useMemo, useState } from "react";

import {
  GetRawViewsQuery,
  ListModelFoldersQuery,
  ListModelsQuery,
} from "@/apollo/types";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";
import { useSchemaSidebar } from "@/pages/ModelTool/ModelEditorStore";
import { ModelIcon } from "@/pages/ModelTool/components/ModelIcon/ModelIcon";
import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import { useModelFolders } from "@/pages/ModelTool/hooks/useModelFolder";
import { useRawViews } from "@/pages/ModelTool/useRawViews";
import { useLoading } from "@/providers/LoadingProvider";
import { useCurrentAccount } from "@/providers/account";
import { CircleStackIcon } from "@heroicons/react/24/outline";
import { useMatchRoute, useNavigate } from "@tanstack/react-location";

function useModelActions(isReadyToLoadData: boolean) {
  const { models, loadingModels } = useListModels(false, {
    skip: !isReadyToLoadData,
  });
  const { modelFolders, loading: loadingModelFolders } = useModelFolders({
    skip: !isReadyToLoadData,
  });

  useLoading(loadingModels || loadingModelFolders);

  const navigate = useNavigate();

  const { slug } = useCurrentAccount();

  const matchRoute = useMatchRoute();
  const [, setSchemaSidebar] = useSchemaSidebar();

  const openModelInTab = useOpenModelInTab();

  const getModelName = (model: ListModelsQuery["models"][0]) => {
    if (model.name) return model.name;
    if (model.createdAt)
      return dayjs(model.createdAt).format("YYYY-MM-DD HH:mm");
    return "Model without name";
  };

  const getModelFolderName = (
    model: ListModelsQuery["models"][0],
    folders: ListModelFoldersQuery["listModelFolders"],
  ) => {
    const folder = model.folder;
    if (folder?.parentId)
      return `${folders.find((f) => f.id === folder.parentId)?.name} / ${
        folder.name
      }`;
    if (folder) return folder.name;
    if (!model.publishedQuery) return "Unpublished";
    return "";
  };

  const actions = useMemo(() => {
    return [...models]
      .sort((a, b) => {
        //if both have a published query, sort by publishedQuery.createdAt timestamp
        if (a.publishedQuery && b.publishedQuery) {
          return (
            new Date(b.publishedQuery.createdAt).getTime() -
            new Date(a.publishedQuery.createdAt).getTime()
          );
        }
        //sort by has a published query,
        if (a.publishedQuery) return -1;
        if (b.publishedQuery) return 1;
        //if both have no published query, sort by createdAt
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      })
      .reduce<Action[]>((actions, model) => {
        actions.push({
          id: model.id,
          name: getModelName(model),
          keywords: "",
          subtitle: getModelFolderName(model, modelFolders),
          priority: Priority.HIGH,
          section: "Models",
          icon: <ModelIcon model={model} />,
        });
        actions.push(
          createAction({
            parent: model.id,
            name: "Open in a new tab",
            keywords: model.name,
            priority: Priority.LOW,
            perform: () => {
              openModelInTab({
                modelId: model.id,
              });
            },
            section: "",
          }),
        );
        actions.push(
          createAction({
            parent: model.id,
            name: "Open in current tab",
            priority: Priority.LOW,
            keywords: model.name,
            perform: () => {
              openModelInTab({
                modelId: model.id,
              });
            },
            section: "",
          }),
        );
        if (model.dwSync || model.dwTable) {
          actions.push(
            createAction({
              parent: model.id,
              name: "Open schema",
              priority: Priority.LOW,

              keywords: model.name,
              perform: () => {
                if (
                  !matchRoute({
                    to: `/${slug}/editor`,
                    fuzzy: true,
                  })
                ) {
                  navigate({ to: `/${slug}/editor` });
                }
                setSchemaSidebar(model.id);
              },
              section: "",
            }),
          );
        }
        return actions;
      }, []);
  }, [
    matchRoute,
    modelFolders,
    models,
    navigate,
    openModelInTab,
    setSchemaSidebar,
    slug,
  ]);

  useRegisterActions(actions, [models, modelFolders]);
}

function useRawViewActions(isReadyToLoadData: boolean) {
  const { rawViews, loading } = useRawViews({
    skip: !isReadyToLoadData,
  });

  useLoading(loading);

  const [, setSchemaSidebar] = useSchemaSidebar();
  const matchRoute = useMatchRoute();
  const navigate = useNavigate();
  const { slug } = useCurrentAccount();

  const getViewPath = (view: GetRawViewsQuery["getRawViews"][0]) => {
    const [, ...path] = view.weldTag.split(".");
    path.pop();
    return path.join(".");
  };

  useRegisterActions(
    rawViews.map((view) => ({
      id: view.viewId,
      name: view.weldTag.split(".")[view.weldTag.split(".").length - 1],
      keywords: "",
      subtitle: getViewPath(view),
      priority: Priority.NORMAL,
      icon: <CircleStackIcon className="w-4" />,
      perform: () => {
        setSchemaSidebar(view.viewId);
        if (
          !matchRoute({
            to: `/${slug}/editor`,
            fuzzy: true,
          })
        ) {
          navigate({ to: `/${slug}/editor` });
        }
      },
      section: "Raw Data",
    })),
    [rawViews],
  );
}

/**
 * Will return true once the KBar has been opened and will continue to return to true thereafter
 */
function useIsReadyToLoadData() {
  const { isKBarOpen } = useKBar((state) => ({
    isKBarOpen: state.visualState === VisualState.showing,
  }));
  const [isReady, setIsReady] = useState(isKBarOpen);
  useEffect(() => {
    if (isKBarOpen) {
      setIsReady(true);
    }
  }, [isKBarOpen]);
  return isReady;
}

export default function useModelToolActions() {
  const isReady = useIsReadyToLoadData();
  useModelActions(isReady);
  useRawViewActions(isReady);
}
