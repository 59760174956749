import { datadogRum } from "@datadog/browser-rum";
import { useStonlyWidget } from "@/features/product-guide/hooks/useStonlyWidget";
import { isGuideViewed } from "@/features/product-guide/hooks/useTrackStonlyEvents";
import { useCurrentModel } from "@/pages/ModelTool/hooks/useCurrentModel";
import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import { useBaseFolders } from "@/pages/ModelTool/hooks/useModelFolder";
import { useSidebarFolders } from "@/pages/ModelTool/Sidebar/useSidebarFolders";
import { useCallback, useEffect, useMemo, useRef } from "react";

import guideConfig from "./config";

//Currently we have hardcoded the names of the example models
const exampleModelNames = [
  "customer_growth",
  "monthly_sales",
  "top_ten_products",
  "yearly_revenue",
];

/*
 * This guide triggers an introduction to the model tool when the user first opens an example model.
 */

export const useExampleModelGuide = () => {
  const StonlyWidget = useStonlyWidget();

  const currentModel = useCurrentModel();
  const { models, loadingModels } = useListModels();

  const hasStartedProductTour = useRef(false);

  const openGuidedTourTimeoutRef = useRef<NodeJS.Timeout>();

  const isExampleModel = useMemo(() => {
    if (!currentModel) return false;
    const isEcommerceFolder = currentModel.folder?.name === "ecommerce";
    const isExampleModel = exampleModelNames.includes(currentModel.name);
    return isEcommerceFolder && isExampleModel;
  }, [currentModel]);

  const handleOpenEcommerceFolder = useOpenEcommerceFolder();

  useEffect(() => {
    if (!isExampleModel) return;

    //only show guide once
    const alreadyViewed = isGuideViewed(guideConfig.id);
    if (alreadyViewed) return;

    //don't show this guide if user already saved some models
    // Count the number of models subtracting models in the `ecommerce` folder which is sample data
    const hasModels = models.some(
      (model) => !!model.publishedQuery && model.folder?.name !== "ecommerce",
    );
    if (hasModels || loadingModels) {
      return;
    }

    //Make sure we only trigger starting the tour once:
    if (hasStartedProductTour.current) return;
    hasStartedProductTour.current = true;

    // Start recording session replay in Datadog
    datadogRum.startSessionReplayRecording();

    // Start the Stonly guided tour
    openGuidedTourTimeoutRef.current = setTimeout(() => {
      StonlyWidget("openGuidedTour", { guideId: guideConfig.id });
      handleOpenEcommerceFolder();
    }, 1500);

    return () => {
      clearTimeout(openGuidedTourTimeoutRef.current);
    };
  }, [
    StonlyWidget,
    isExampleModel,
    loadingModels,
    models,
    handleOpenEcommerceFolder,
  ]);
};

const useOpenEcommerceFolder = () => {
  const { baseFolders } = useBaseFolders();
  const { openFolder } = useSidebarFolders();

  const hasOpened = useRef(false);

  return useCallback(() => {
    if (baseFolders && baseFolders.length > 0 && !hasOpened.current) {
      const ecommerceFolder = baseFolders.find(
        (folder) => folder.name === "ecommerce",
      );
      if (ecommerceFolder) {
        openFolder(ecommerceFolder.id);
      }
      hasOpened.current = true;
    }
  }, [baseFolders, openFolder]);
};
