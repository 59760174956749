import { useMemo } from "react";

import { useConnections } from "@/features/connectors";

export const useGoogleSheetsDestinations = () => {
  const data = useConnections("ReverseEltDestinationConnection");

  return useMemo(() => {
    return {
      ...data,
      connections: data.connections.filter(
        (x) => x.integrationId === "google-sheets",
      ),
    };
  }, [data]);
};
