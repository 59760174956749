import Select from "@/components/elements/Select";
import React, { useMemo } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useModelFolders } from "../hooks/useModelFolder";

type Props = {
  form: UseFormReturn<any, any>;
};

const FolderSelecter = (props: Props) => {
  const { modelFolders } = useModelFolders();

  const baseFolderOptions = useMemo(
    () =>
      modelFolders
        .filter((folder) => !folder.parentId)
        .sort((a, b) => baseFolderSorter(a.name, b.name))
        .map((folder) => ({
          id: folder.id,
          label: folder.name,
        })),
    [modelFolders],
  );

  const baseFolderId = props.form.watch("selectedBaseFolder");

  const subFolderOptions = useMemo(
    () =>
      modelFolders
        .filter((folder) => baseFolderId && folder.parentId === baseFolderId)
        .map((folder) => ({
          id: folder.id,
          label: folder.name,
          type: folder.type,
        })),
    [baseFolderId, modelFolders],
  );

  return (
    <div className="space-y-4">
      <div className="w-full">
        <Controller
          control={props.form.control}
          name="selectedBaseFolder"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <Select
              labelKey="label"
              placeholder="Select folder"
              options={baseFolderOptions}
              value={baseFolderOptions.find((folder) => folder.id === value)}
              onChange={(item: { id: string; label: string } | null) => {
                props.form.setValue("selectedSubFolder", "");
                onChange(item?.id);
              }}
            />
          )}
        />
        {props.form.formState.errors.selectedBaseFolder && (
          <div className="text-xs text-red-500">
            {props.form.formState.errors.selectedBaseFolder.message?.toString() ??
              "Error"}
          </div>
        )}
      </div>
      {!!subFolderOptions.length && (
        <div className="w-full">
          <Controller
            control={props.form.control}
            name="selectedSubFolder"
            render={({ field: { onChange, value } }) => (
              <Select
                labelKey="label"
                placeholder="Select subfolder (optional)"
                options={subFolderOptions}
                value={subFolderOptions.find((folder) => folder.id === value)}
                onChange={(item: { id: string; label: string } | null) => {
                  onChange(item?.id);
                }}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default FolderSelecter;

//for now we hardcode the order of the default base "layers"
export const baseFolderSorter = (aName: string, bName: string) => {
  return getOrder(aName) - getOrder(bName);
};
const getOrder = (folderName: string) => {
  return sortingOrder.findIndex((i) => i === folderName);
};
const sortingOrder = ["analytics", "core", "staging"];
