import { PlusIcon } from "@heroicons/react/24/outline";
import { PrimaryButton } from "@/components/elements/Button";
import TabLayout from "@/components/layouts/TabLayout";
import { useDataSourceSlideOver } from "@/components/modules/new-data-source-slideover";
import { useCurrentAccount } from "@/providers/account";
import React from "react";

function useTabs() {
  const account = useCurrentAccount();
  return React.useMemo(() => {
    const basePath = `/${account.slug}/sources`;
    return [
      {
        name: "Syncs",
        href: basePath,
        id: "syncs",
      },
      {
        name: "Usage",
        href: `${basePath}/usage`,
        id: "usage",
      },
    ];
  }, [account]);
}

export default function MainTabsLayout(props: React.PropsWithChildren<{}>) {
  const tabs = useTabs();
  const { onOpen } = useDataSourceSlideOver();
  return (
    <TabLayout
      tabs={tabs}
      action={
        <PrimaryButton
          icon={<PlusIcon className="w-4" />}
          onClick={() => onOpen()}
          tabIndex={-1}
        >
          New
        </PrimaryButton>
      }
    >
      <>{props.children}</>
    </TabLayout>
  );
}
