import { useLDClient } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";

import { Ability } from "@/integrations";

export type FeatureMode = "show" | "hide" | "preview" | "beta";

export function useIntegrationFlagHelper() {
  const ldClient = useLDClient();

  return useMemo(() => {
    const mapTypeToFlagProp = (type: Ability) => {
      switch (type) {
        case "EltSourceConnection":
          return "elt";
        case "ReverseEltDestinationConnection":
          return "reverseElt";
        default:
          return null;
      }
    };
    const checkFlag = (integrationId: string, type: Ability): FeatureMode => {
      const flagValue = ldClient?.variation(integrationId, "show") ?? "show";
      if (typeof flagValue === "object") {
        const flagProp = mapTypeToFlagProp(type);
        return flagProp ? (flagValue[flagProp] ?? "show") : "show";
      } else return flagValue;
    };

    return {
      checkFlag,
    };
  }, [ldClient]);
}
