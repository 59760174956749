import * as Sentry from "@sentry/react";
import { useMatch, useNavigate } from "@tanstack/react-location";
import { PrimaryButton, SecondaryButton } from "@/components/elements/Button";
import { useEffect } from "react";
import { LocationGenerics } from "routes";

export default function LoadingError() {
  const navigate = useNavigate();
  const match = useMatch<LocationGenerics>();
  const errorMessage = (match.error as Error)?.message;
  useEffect(() => {
    Sentry.captureMessage(`A routing error occured ${errorMessage}`);
  }, [errorMessage]);

  return (
    <div className="min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-red-600 sm:text-5xl">
            Error
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                An error occured
              </h1>
              <p className="mt-1 text-base text-gray-500">{errorMessage}</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <PrimaryButton onClick={() => navigate({ to: ".." })}>
                Go back
              </PrimaryButton>
              <SecondaryButton onClick={() => navigate({ to: "/" })}>
                Go to homepage
              </SecondaryButton>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
