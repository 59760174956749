import confirm from "@/components/elements/Confirm";
import MenuItem from "@/components/elements/MenuItem";
import TableMenu from "@/components/modules/TableMenu";
import {
  useDeleteDashboard,
  useRenameDashboard,
} from "@/pages/ModelTool/Visualize/useDashboards";
import { Dashboard } from "@/pages/ModelTool/Visualize/VisualizationType";
import * as Sentry from "@sentry/react";
import {
  useCloseRemovedTab,
  useOpenDashboardInTab,
} from "@/components/modules/ModelTabs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRemoveDashboardModelsMutation } from "@/apollo/types";
import { optionKeyString } from "@/helpers/isWindows";

const DashboardOptions = (props: { dashboard: Dashboard }) => {
  const deleteDashboard = useDeleteDashboard();

  const [renaming, setRenaming] = useState(false);

  const handleCloseRemovedTab = useCloseRemovedTab();
  const openDashboardInTab = useOpenDashboardInTab();

  const [removeDashboardModels, { loading }] =
    useRemoveDashboardModelsMutation();

  const handleDeleteDashboard = async () => {
    if (loading) return;

    const confirmed = await confirm({
      type: "danger",
      title: "Delete dashboard",
      message: "Are you sure you want to delete this dashboard?",
    });
    if (!confirmed) return;
    try {
      await deleteDashboard(props.dashboard.id);
      handleCloseRemovedTab("dashboard", props.dashboard.id);

      //clean up models that are only used by this dashboard:
      await removeDashboardModels({
        variables: {
          dashboardId: props.dashboard.id,
        },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleRenameFinished = useCallback(() => {
    setRenaming(false);
  }, []);

  return (
    <>
      <TableMenu size="sm">
        <MenuItem
          text="Open in new tab"
          size="sm"
          tooltipText={`${optionKeyString} + click`}
          onClick={async () => {
            openDashboardInTab(props.dashboard.id, props.dashboard.name, true);
          }}
        />
        <MenuItem
          text="Rename dashboard"
          size="sm"
          onClick={async () => {
            setRenaming(true);
          }}
        />
        <MenuItem
          text="Delete dashboard"
          size="sm"
          onClick={handleDeleteDashboard}
        />
      </TableMenu>
      {renaming && (
        <EditDashboardName
          dashboardId={props.dashboard.id}
          dashboardName={props.dashboard.name}
          onFinish={handleRenameFinished}
        />
      )}
    </>
  );
};

export default DashboardOptions;

const EditDashboardName = (props: {
  dashboardId: string;
  dashboardName: string;
  onFinish: () => void;
}) => {
  const { onFinish } = props;
  const [newName, setNewName] = useState(props.dashboardName);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 50);
  }, []);

  const renameDashboard = useRenameDashboard();
  const handleUpdateName = useCallback(async () => {
    renameDashboard(props.dashboardId, newName);
    onFinish();
  }, [newName, onFinish, props.dashboardId, renameDashboard]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleUpdateName();
      }
      if (e.key === "Escape") {
        e.preventDefault();
        onFinish();
      }
    };
    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, [handleUpdateName, onFinish]);

  return (
    <div className="absolute inset-0 -mt-0.5 ml-6">
      <input
        className="w-full rounded-sm border px-1 py-px text-xs ring ring-primary focus:outline-none dark:bg-transparent dark:text-white"
        onBlur={() => {
          handleUpdateName();
        }}
        spellCheck={false}
        ref={inputRef}
        value={newName}
        onChange={(e) => {
          setNewName(e.target.value);
        }}
      />
    </div>
  );
};
