import Center from "@/components/elements/layout/Center";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { ConnectionSettingsType } from "@/hooks/useConnectionSettings";
import { createContext } from "react";

import CustomForm from "./CustomForm";

export default function ConnectionSettings(props: {
  onChange: (payload: any) => void;
  connectionSettings: ConnectionSettingsType;
  onChangeValid: (isValid: boolean) => void;
  initialValues: Record<string, any>;
  refetchSettingsOnLoad?: boolean;
}) {
  if (!!props.connectionSettings.error)
    return <div className="text-red-500">{props.connectionSettings.error}</div>;

  if (!props.connectionSettings.required && !props.connectionSettings.loading)
    return null;

  return (
    <div>
      {props.connectionSettings.required &&
        props.connectionSettings.loading && (
          <Center className="absolute inset-0 z-50 bg-white/70 dark:bg-black/30">
            <LoadingSpinner />
          </Center>
        )}
      {!props.connectionSettings.required &&
      props.connectionSettings.loading ? (
        <div className="flex flex-col items-center space-y-2 pb-4">
          <LoadingSpinner />
          <div className="text-xs text-gray-500">
            This sometimes takes a little while
          </div>
        </div>
      ) : (
        props.connectionSettings.required && (
          <CustomForm
            onChange={props.onChange}
            form={props.connectionSettings.value}
            fetchSettings={props.connectionSettings.refetch}
            onChangeValid={props.onChangeValid}
            initialValues={props.initialValues}
            refetchSettingsOnLoad={props.refetchSettingsOnLoad}
          />
        )
      )}
    </div>
  );
}

/**
 * Create a provider that can be wrapped around a connection settings to pass a flag that the form should be displayed in a simplified way.
 */
export const SimplifiedFormContext = createContext<boolean>(false); //Default value is false, so the form will be displayed in the normal way.
export const SimplifiedFormProvider = (props: {
  children: React.ReactNode;
}) => {
  return (
    <SimplifiedFormContext.Provider value={true}>
      {props.children}
    </SimplifiedFormContext.Provider>
  );
};
