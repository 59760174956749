import { Input } from "@/components/primitives/input";
import classNames from "@/helpers/classNames";

import { useSyncScheduleContext } from "../useSyncSchedule";
import { useEffect, useRef } from "react";

export function CronInput(props: React.ComponentProps<typeof Input>) {
  const { value, onChange, mode, error } = useSyncScheduleContext();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) return;
    if (error) {
      inputRef.current.setCustomValidity(error.message);
    } else {
      inputRef.current.setCustomValidity("");
    }
  }, [error]);

  return (
    <Input
      ref={inputRef}
      {...props}
      value={value?.value ?? ""}
      onChange={(e) => {
        onChange({
          value: e.target.value,
          label: "Custom",
        });
      }}
      className={classNames(props.className, mode !== "custom" && "hidden")}
    />
  );
}
