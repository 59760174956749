import { useNavigate } from "@tanstack/react-location";
import {
  InvitationsDocument,
  Role,
  useSendInvitationMutation,
} from "@/apollo/types";
import { ActionButton } from "@/components/elements/Button";
import FormLayout from "@/components/layouts/FormLayout";
import FormTextInput from "@/components/modules/FormFactory/FormTextInput";
import SelectForm from "@/components/modules/FormFactory/SelectForm";
import { TaskAction } from "@/components/modules/TaskActionBar";
import { useToast } from "@/providers/ToastProvider";
import React from "react";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";

export default function NewInvitationPage() {
  const navigate = useNavigate();
  const toast = useToast();

  const [sendInvitation] = useSendInvitationMutation({
    onError(error) {
      toast("Invite not sent", error.message, "error");
    },
    refetchQueries: [{ query: InvitationsDocument }],
    awaitRefetchQueries: true,
    onCompleted() {
      navigate({ to: `..` });
    },
  });

  return (
    <FormLayout>
      <div>
        <div className="my-6 text-2xl">Invite new user</div>
        <InvitationForm
          onSubmit={(values) => {
            return sendInvitation({
              variables: values,
            });
          }}
        >
          <TaskAction>
            <SubmitButton />
          </TaskAction>
        </InvitationForm>
      </div>
    </FormLayout>
  );
}

type InvitationFormModel = {
  email: string;
  firstName: string;
  lastName: string;
  role: {
    value: Role;
    label: string;
  };
};

export type InvitationFormResult = Omit<InvitationFormModel, "role"> & {
  role: Role;
};

type InvitationFormProps = {
  onSubmit: (values: InvitationFormResult) => Promise<unknown>;
};

const InvitationForm = React.forwardRef<
  HTMLFormElement,
  React.PropsWithChildren<InvitationFormProps>
>((props, ref) => {
  const methods = useForm<InvitationFormModel>({
    mode: "onChange",
    defaultValues: {
      role: { value: Role.Analyst, label: "Analyst" },
    },
  });

  const onSubmit: SubmitHandler<InvitationFormModel> = (data) => {
    return props
      .onSubmit({
        email: data.email.replace(/\s/g, "").toLowerCase(),
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        role: data.role.value,
      })
      .then(() => {
        methods.reset();
      });
  };

  return (
    <FormProvider {...methods}>
      <form
        id="invitation-form"
        onSubmit={methods.handleSubmit(onSubmit)}
        ref={ref}
      >
        <FormTextInput
          field={{
            type: "email",
            required: true,
            name: "email",
            label: "Email",
            inputProps: {
              placeholder: "something@domain.com",
              autoFocus: true,
            },
          }}
        />
        <FormTextInput
          field={{
            type: "text",
            required: true,
            name: "firstName",
            label: "First name",
            inputProps: { placeholder: "John" },
          }}
        />
        <FormTextInput
          field={{
            type: "text",
            required: true,
            name: "lastName",
            label: "Last name",
            inputProps: { placeholder: "Doe" },
          }}
        />
        <SelectForm
          field={{
            type: "dropdown",
            dropdownOptions: userRoleOptions,
            label: "Role",
            name: "role",
            required: true,
            placeholder: "Select user role",
          }}
        />
        {props.children}
      </form>
    </FormProvider>
  );
});

const InvitationFormNamespace = Object.assign(InvitationForm, {
  SubmitButton,
});
export { InvitationFormNamespace as InvitationForm };

export function SubmitButton(props: React.ComponentProps<typeof ActionButton>) {
  const { formState } = useFormContext();
  const { isValid, isDirty, isSubmitting } = formState;
  const isDisabled = !isValid || !isDirty;
  return (
    <ActionButton
      form="invitation-form"
      type="submit"
      disabled={isSubmitting || isDisabled}
      {...props}
    >
      {isSubmitting ? "Loading..." : "Send invitation"}
    </ActionButton>
  );
}

export const userRoleOptions: { value: Role; label: string }[] = [
  { value: Role.Admin, label: "Admin" },
  { value: Role.Analyst, label: "Analyst" },
];
