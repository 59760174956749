import { uniqBy } from "lodash";
import { useMemo } from "react";

import { useOnboardingConnectionPreferencesQuery } from "@/apollo/types";
import { Ability, IntegrationType, useIntegrationsMap } from "@/integrations";

export function useOnboardingConnectionPreferences(ability?: Ability) {
  const { data, loading } = useOnboardingConnectionPreferencesQuery();
  const integrationsMap = useIntegrationsMap(ability);
  return {
    integrations: useMemo(() => {
      const options = (data?.getOnboardingConnections ?? []).reduce<
        IntegrationType[]
      >((acc, item) => {
        const integration = integrationsMap.get(item.integrationId);
        if (integration) {
          acc.push(integration);
        }
        return acc;
      }, []);
      return uniqBy(options, (x) => x.id);
    }, [data, integrationsMap]),
    loading,
  };
}
