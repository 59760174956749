import { ChatThreadFragment, useChatThreadQuery } from "@/apollo/types";
import { createContext, useContext } from "react";

import { useChat } from "../useChat";

const ChatContext = createContext<
  | (ReturnType<typeof useChat> & { thread: ChatThreadFragment | undefined })
  | null
>(null);

export function useChatContext() {
  const ctx = useContext(ChatContext);
  if (!ctx) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return ctx;
}

export function ChatProvider(
  props: ReturnType<typeof useChat> & {
    children: React.ReactNode;
  },
) {
  const { data, loading, error } = useChatThreadQuery({
    variables: {
      id: props.threadId ?? "",
    },
    skip: !props.threadId,
  });

  if (props.threadId && !data) {
    if (loading) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          Loading chat
        </div>
      );
    }
    if (error) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          Error loading chat thread
        </div>
      );
    }
    return null;
  }

  const { children, ...chatProps } = props;
  const ctx = {
    ...chatProps,
    thread: data?.chatThread,
  };

  return <ChatContext.Provider value={ctx}>{children}</ChatContext.Provider>;
}
