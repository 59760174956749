import {
  FirebaseApp,
  FirebaseOptions,
  getApps,
  initializeApp,
  registerVersion,
} from "@firebase/app";
import React from "react";

const FirebaseAppContext = React.createContext<FirebaseApp | undefined>(
  undefined,
);

const appName = "weld-editor";

export function FirebaseAppProvider(
  props: React.PropsWithChildren<{
    firebaseConfig: FirebaseOptions;
  }>,
) {
  const { firebaseConfig } = props;

  const firebaseApp = React.useMemo(() => {
    const existingApp = getApps().find((app) => app.name === appName);
    if (existingApp) {
      return existingApp;
    } else {
      const reactVersion = React.version || "unknown";
      registerVersion("react", reactVersion);
      return initializeApp(firebaseConfig, appName);
    }
  }, [firebaseConfig]);

  return (
    <FirebaseAppContext.Provider value={firebaseApp}>
      {props.children}
    </FirebaseAppContext.Provider>
  );
}

export function useFirebaseApp() {
  const firebaseApp = React.useContext(FirebaseAppContext);
  if (!firebaseApp) {
    throw new Error(
      "Cannot call useFirebaseApp unless your component is within a FirebaseAppProvider",
    );
  }
  return firebaseApp;
}
