export const OptionTooltips = {
  midnight: (
    <p>
      Overwrite the whole table every day at midnight. Helps capture deletes
      when the sync runs incrementally. <br />
      <br />
      The operation is quite expensive, so use with care.
    </p>
  ),
  full: (
    <p>
      All data will be re-synced and overwritten every time. <br />
      <br />
      The operation is quite expensive, so use with care.
    </p>
  ),
  fullShort: <p>All data will be re-synced and overwritten every time.</p>,
  protected: (
    <p>
      If enabled, whenever there is a schema change requiring all data to be
      resynchronized, the sync will pause, and you will receive a notification.
      <br />
      If disabled, schema changes will trigger an automatic resynchronization of
      all data.
      <br />
      <br />
      This option is beneficial for safeguarding historical data not retained in
      the data source.
    </p>
  ),
  protectedShort: (
    <p>
      When schema changes require full data resynchronization, syncing pauses,
      and you receive a notification.
    </p>
  ),
  fullWarning: (
    <p>
      All rows will be re-synced and overwritten every time. This operation can
      be expensive for large tables.
      <br />
      <br />
      Click to configure incremental sync for this table.
    </p>
  ),
  incremental: (
    <p>
      Only new rows will be synced in incremental syncs. <br />
      <br />
      This can improve sync times significantly and reduce number of rows per
      sync.
    </p>
  ),
  incrementalShort: <p>Only new rows will be synced in incremental syncs.</p>,
  append: (
    <p>
      Only new rows will be appended. Old rows won't be updated. <br />
      <br />
      This can improve subsequent sync times significantly.
    </p>
  ),
  appendShort: (
    <p>Only new rows will be appended. Old rows won't be updated.</p>
  ),
};
