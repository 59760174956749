import { useEffect, useState } from "react";

function getMatches(query: string) {
  if (typeof window !== "undefined") {
    return window.matchMedia(query).matches;
  }
  return false;
}

function useMediaQuery(query: string) {
  const [matches, setMatches] = useState<boolean>(getMatches(query));

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    const handleChange = () => {
      setMatches(getMatches(query));
    };

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    if (typeof matchMedia.addListener === "function") {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener("change", handleChange);
    }

    return () => {
      if (typeof matchMedia.removeListener === "function") {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener("change", handleChange);
      }
    };
  }, [query]);

  return matches;
}

export default useMediaQuery;
