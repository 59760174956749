import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useMatches } from "@tanstack/react-location";
import {
  MaterializationType,
  OrchestrationSchedulerType,
} from "@/apollo/types";
import { TabButton } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Tooltip from "@/components/elements/Tooltip";
import { atom } from "jotai";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Suspense, lazy, useMemo } from "react";

import { useCurrentModel } from "../hooks/useCurrentModel";
import Lineage from "../lineage/Lineage";
import MaterializationSetting from "../materialization/MaterializationSettings";
import { useModelEditorState } from "../ModelEditorStore";
import { ModelTests } from "../modelTesting/ModelTests";
import Preview from "../Preview/Preview";
import PreviewHeader from "../Preview/PreviewHeader";
import { useQueryDependencies } from "../useQueryDependencies";
import ModelGraphPanel from "../Visualize/ModelVisualizePanel/ModelVisualizePanel";
import { EditorActionButtons } from "./ActionButtons";

/**
 * Lazy load DocumentationEditor until the tab is actually focused.
 * This will also lazy load WYSIWYG editor dependency, tiptap.
 */
const DocumentationViewer = lazy(
  () => import("../documentation/DocumentationViewer"),
);
export enum ModelBarOption {
  QueryResult = "Query result",
  Documentation = "Documentation",
  Schedule = "Schedule",
  Lineage = "Lineage",
  Tests = "Tests",
  History = "History",
  Visualize = "Visualize",
}
type ModelBarSetting = {
  setting: ModelBarOption;
  tooltip?: string | JSX.Element;
  hasWarning?: boolean;
};
export const EditorBottomPane: React.FC<{ setting?: ModelBarOption }> = (
  props,
) => {
  const currentModel = useCurrentModel();

  const state = useModelEditorState();

  const queryDependencies = useQueryDependencies();

  switch (props.setting) {
    case ModelBarOption.QueryResult:
      return (
        <div className="relative h-full pt-8">
          <div className="absolute top-0 h-8 w-full border-b dark:border-gray-700">
            <PreviewHeader />
          </div>
          <Preview />
        </div>
      );
    case ModelBarOption.Documentation:
      return currentModel ? (
        <Suspense fallback={<LoadingSpinner />}>
          <DocumentationViewer key={currentModel.id} model={currentModel} />
        </Suspense>
      ) : (
        <div className="absolute inset-0 flex items-center justify-center">
          <LoadingSpinner />
        </div>
      );
    case ModelBarOption.Schedule:
      return <MaterializationSetting />;
    case ModelBarOption.Lineage:
      return state.initialized ? (
        <Lineage
          focusedId={state.currentModelId}
          dependencies={queryDependencies.map((d) => d.dwItemId)}
        />
      ) : (
        <LoadingSpinner />
      );
    case ModelBarOption.Tests:
      return <ModelTests />;
    case ModelBarOption.Visualize:
      return <ModelGraphPanel />;
    default:
      return null;
  }
};
export const ModelEditorTabBar: React.FC<{
  currentSetting?: ModelBarOption;
  setCurrentSetting: (setting: ModelBarSetting) => void;
  toggleOpen: () => void;
  isOpen: boolean;
  menuItems: ModelBarSetting[];
}> = (props) => {
  return (
    <div className="flex h-11 gap-4 border-t dark:border-gray-700">
      <div className="flex flex-1 items-center space-x-2 overflow-x-auto px-4 lg:space-x-8">
        {props.menuItems.map((item) => (
          <Tooltip key={item.setting} content={item.tooltip ?? item.setting}>
            <TabButton
              key={item.setting}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={() => {
                props.setCurrentSetting(item);
              }}
              active={props.currentSetting === item.setting}
              className="inline-block shrink overflow-hidden"
              data-stonly={`editor__${item.setting}-button`}
            >
              <div className="flex items-center justify-center">
                <span className="truncate">{item.setting}</span>
                {item.hasWarning && (
                  <InformationCircleIcon className="h-4 w-4 text-danger" />
                )}
              </div>
            </TabButton>
          </Tooltip>
        ))}
      </div>
      <div className="flex items-center gap-4 pr-2 lg:pr-4">
        <EditorActionButtons />
      </div>
    </div>
  );
};
export const useEditorMenuOptions = () => {
  const { modelMaterialization: showMaterialization, modelTesting: showTests } =
    useFlags();

  const currentModel = useCurrentModel();
  const isUnsavedDraft = useMatches().some((m) =>
    m.pathname.includes("/editor/draft"),
  );

  const isMissingOrchestrationWorkflow = useMemo(() => {
    return (
      currentModel?.materializationType === MaterializationType.Table &&
      currentModel?.orchestrationScheduler ===
        OrchestrationSchedulerType.Global &&
      !currentModel?.orchestrationWorkflow
    );
  }, [
    currentModel?.materializationType,
    currentModel?.orchestrationScheduler,
    currentModel?.orchestrationWorkflow,
  ]);

  const menuItems = useMemo(() => {
    const items: ModelBarSetting[] = [
      { setting: ModelBarOption.QueryResult, tooltip: "View query results" },
      {
        setting: ModelBarOption.Lineage,
        tooltip: "See how this model is being used",
      },
    ];

    if (!!currentModel) items.push({ setting: ModelBarOption.Visualize });

    const isPublishedModel = !!currentModel?.publishedQuery;
    if (showMaterialization && isPublishedModel) {
      items.push({
        setting: ModelBarOption.Schedule,
        tooltip: isMissingOrchestrationWorkflow
          ? "Orchestration not configured"
          : "Configure publishing schedule",
        hasWarning: isMissingOrchestrationWorkflow,
      });
    }

    if (!isUnsavedDraft)
      items.push({
        setting: ModelBarOption.Documentation,
        tooltip: "View documentation for this model",
      });

    if (showTests && isPublishedModel && !isUnsavedDraft)
      items.push({
        setting: ModelBarOption.Tests,
        tooltip: "Run tests on this model",
      });

    return items;
  }, [
    currentModel,
    isMissingOrchestrationWorkflow,
    isUnsavedDraft,
    showMaterialization,
    showTests,
  ]);

  return menuItems;
};
export const EditorTabBarRatioAtom = atom<number>(0);
export const EditorTabBarSettingAtom = atom<ModelBarOption | undefined>(
  undefined,
);
