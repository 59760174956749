import { usePlanLimitsStatusQuery } from "@/apollo/types";

export function useWeldPlanLimits() {
  const { data } = usePlanLimitsStatusQuery({
    fetchPolicy: "cache-and-network",
  });
  return {
    isConnectorsLimitReached:
      data?.getPlanLimitsStatus.isConnectionsLimitReached ?? false,
    isUsersLimitReached: data?.getPlanLimitsStatus.isUsersLimitReached ?? false,
  };
}
