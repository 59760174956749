import "./date-input.css";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import InputContainerButton from "@/components/elements/InputContainer";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { range } from "lodash";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";

import Button from "../../primitives/Button";

dayjs.extend(localeData);

const years = range(1990, dayjs().year() + 1);

export default function DateInput({
  placeholder = "Pick a date...",
  date,
  setDate,
}: {
  date: Date | null;
  setDate: (date: Date | null) => any;
  placeholder?: string;
}) {
  const [focused, setFocused] = useState(false);
  const Input = forwardRef(({ onClick, value }: any, ref: any) => (
    <div className="relative w-1/2 pr-2">
      <InputContainerButton
        isDisabled={false}
        isFocused={focused}
        className={`py-2 pl-2 text-left text-base ${
          !value ? "text-gray-400" : "text-gray-800 dark:text-white"
        }`}
        onClick={onClick}
        ref={ref}
      >
        {value || placeholder}
      </InputContainerButton>
      {date && (
        <div className="absolute right-0 top-0 flex h-full items-center pr-5">
          <Button
            className="cursor-default text-gray-400 hover:text-gray-500"
            onClick={() => setDate(null)}
          >
            <XMarkIcon className="h-5 w-5" />
          </Button>
        </div>
      )}
    </div>
  ));

  return (
    <DatePicker
      onCalendarOpen={() => setFocused(true)}
      onCalendarClose={() => setFocused(false)}
      value={date ? dayjs(date).format("DD. MMM. YYYY") : ""}
      customInput={<Input />}
      popperModifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ]}
      maxDate={new Date()}
      minDate={dayjs("1990/01/01").toDate()}
      calendarStartDay={1}
      renderDayContents={(x) => <div>{x}</div>}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="flex justify-between">
          <Button
            type="button"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <div className="flex items-center space-x-1">
            <select
              className="form-select block shrink-0 rounded border-gray-300 py-0 pl-1 pr-7 text-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-800 dark:bg-gray-800"
              value={dayjs(date).year()}
              onChange={({ target: { value } }) => changeYear(parseInt(value))}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              className="form-select block shrink-0 rounded border-gray-300 py-0 pl-1 pr-7 text-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-800 dark:bg-gray-800 dark:text-white"
              value={dayjs.months()[dayjs(date).month()]}
              onChange={({ target: { value } }) =>
                changeMonth(dayjs.months().indexOf(value))
              }
            >
              {dayjs.months().map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <Button
            type="button"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </div>
      )}
      selected={date}
      onChange={(date: Date) => setDate(date)}
    />
  );
}
