import { useApolloClient } from "@apollo/client";
import {
  DataSchema,
  GetViewDetailsDocument,
  GetViewDetailsQuery,
} from "@/apollo/types";
import React from "react";

import { DependencyInfo, useDependencyInfo } from "./useDependencyInfo";

export type ModelSchema = {
  weldTag: string;
  schema: DataSchema[];
};

export function useDependencySchemas() {
  const client = useApolloClient();

  const dependencyInfo = useDependencyInfo();
  const [schemas, setSchemas] = React.useState<ModelSchema[]>([]);

  React.useEffect(() => {
    async function fetchViewDetails(path: string[]) {
      return await client.query<GetViewDetailsQuery>({
        query: GetViewDetailsDocument,
        variables: {
          input: {
            path,
          },
        },
        fetchPolicy: "cache-first",
      });
    }

    async function resolveSchemas(references: DependencyInfo[]) {
      const schemaResponses = await Promise.allSettled(
        references.map((x) => fetchViewDetails(x.path)),
      );
      const schemas = schemaResponses.reduce<ModelSchema[]>(
        (acc, resp, idx) => {
          const { weldTag } = references[idx];
          if (resp.status === "rejected") return acc;
          const schema = resp.value.data.getViewDetails.dataSchema;
          if (schema.length === 0) return acc;
          return [
            ...acc,
            {
              weldTag,
              schema,
            },
          ];
        },
        [],
      );
      setSchemas(schemas);
    }
    resolveSchemas(dependencyInfo.length ? dependencyInfo : []);
  }, [client, dependencyInfo]);

  return schemas;
}
