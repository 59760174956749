import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigateWithSlug } from "routes";

import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Center from "@/components/elements/layout/Center";
import { useOpenDashboardInTab } from "@/components/modules/ModelTabs";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useToast } from "@/providers/ToastProvider";
import { useMatch } from "@tanstack/react-location";

import { Dashboard } from "./VisualizationType";
import Visualize from "./Visualize";
import { useDashboard } from "./useDashboards";

export default function VisualizePage() {
  const {
    params: { dashboardId },
  } = useMatch<{
    Params: { dashboardId: string };
  }>();
  const toast = useToast();
  const navigate = useNavigateWithSlug();
  const openDashboardInTab = useOpenDashboardInTab();

  const handleNotFound = (dashboardId: string) => {
    toast(
      "Dashboard not found",
      `A dashboard with id "${dashboardId}" was not found.`,
      "error",
    );
    navigate({ to: "/editor" });
  };

  const { dashboard, loading: loadingDashboard } = useDashboard(dashboardId, {
    onNotFound: handleNotFound,
    onLoaded(dashboard) {
      openDashboardInTab(dashboard.id, dashboard.name);
    },
  });

  useTrackDashboardUsage(dashboard);

  if (loadingDashboard) {
    return (
      <Center className="h-full w-full">
        <div className="flex items-center gap-4">
          <LoadingSpinner />{" "}
          <span className="text-lg text-gray-600">Loading dashboard...</span>
        </div>
      </Center>
    );
  }

  if (!dashboard) return null;

  return (
    <>
      <Helmet>
        <title>{`${dashboard.name} | WELD`}</title>
      </Helmet>
      <Visualize dashboard={dashboard} />
    </>
  );
}

const useTrackDashboardUsage = (dashboard?: Dashboard) => {
  const mixpanel = useMixpanel();
  const trackedDashboardIdRef = useRef<string | null>(null);
  useEffect(() => {
    if (!dashboard || trackedDashboardIdRef.current === dashboard.id) return;

    mixpanel.track("Dashboard Viewed", {
      dashboardId: dashboard.id,
      dashboardName: dashboard.name,
    });
    trackedDashboardIdRef.current = dashboard.id;
  }, [dashboard, mixpanel]);
};
