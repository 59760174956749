import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import { atom, useAtom } from "jotai";

import Lineage from "./Lineage";

type RawDataLineageModalProps = {
  focusedId: string;
  title: string;
};

const LineageModalAtom = atom<RawDataLineageModalProps | null>(null);
export const useLineageModal = () => useAtom(LineageModalAtom);

export const useOpenLineageModal = () => {
  const [, setProps] = useLineageModal();

  return (props: RawDataLineageModalProps) => {
    setProps(props);
  };
};

export function DataLineageModal() {
  const [props, setProps] = useLineageModal();

  return (
    <Modal
      size="lg"
      isOpen={!!props}
      onClose={() => {
        setProps(null);
      }}
    >
      <ModalCloseButton />
      <ModalHeader>{props?.title}</ModalHeader>
      <ModalBody>
        <div style={{ height: "50vh" }}>
          {props && <Lineage focusedId={props.focusedId} />}
        </div>
      </ModalBody>
    </Modal>
  );
}
