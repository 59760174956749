import { SubForm } from "@/integrations";

import { ConnectorOption } from "./useCreateConnectorOptions";

const emptyForm: SubForm = {
  fields: [],
  type: "sub_form",
  name: "",
  required: false,
};

export function createEmptyIntegration(
  id: string,
  name: string,
  abilities: ConnectorOption["abilities"],
) {
  return {
    id,
    name,
    abilities,
    numActive: 0,
    form: emptyForm,
  };
}
