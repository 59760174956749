import { useCallback, useState } from "react";

import { useValidateCustomFormLazyQuery } from "@/apollo/types";
import { Alert, AlertIcon, AlertTitle } from "@/components/elements/Alert";
import { Button, IconButton } from "@/components/elements/Button";
import Select from "@/components/elements/Select";
import { InputError } from "@/components/primitives/InputError";
import FieldLabel from "@/components/primitives/InputLabel";
import { Input } from "@/components/primitives/input";
import { useMountEffect } from "@/hooks/useMountEffect";
import { DropdownOption } from "@/integrations";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { KlaviyoReport, isKlaviyoStream } from "./KlaviyoForm";

type TypeOption = { label: string; value: string };

export default function KlaviyoCustomReport(props: {
  onChangeReport: (report: KlaviyoReport) => void;
  onRemoveReport: (report: KlaviyoReport) => void;
  onReportInvalid: (reportId: string) => void;
  onReportValid: (reportId: string) => void;
  report: KlaviyoReport;
  connectionId: string;
  typeGroups: {
    type: TypeOption;
    statistics: TypeOption[];
    conversionMetrics: TypeOption[];
  }[];
  validationError?: string;
}) {
  const {
    report,
    onChangeReport,
    onRemoveReport,
    validationError,
    connectionId,
    typeGroups,
    onReportValid,
    onReportInvalid,
  } = props;

  const reportTypes = typeGroups.map((item) => item.type);

  const [validationSuccess, setValidationSuccess] = useState<boolean>(false);
  const [validationCallError, setValidationCallError] = useState<string | null>(
    null,
  );

  const [statistics, setStatistics] = useState<
    { label: string; value: string }[]
  >([]);

  const [metrics, setMetrics] = useState<TypeOption[]>([]);

  const [callValidationQuery] = useValidateCustomFormLazyQuery();

  const invalidateReport = useCallback(
    (message?: string) => {
      if (message) {
        setValidationCallError(message);
      }
      setValidationSuccess(false);
      onReportInvalid(report.id);
    },
    [onReportInvalid, report.id],
  );

  const runValidation = useCallback(async () => {
    //Call validation endpoint
    const queryData = report;
    const { data, error } = await callValidationQuery({
      variables: {
        input: {
          connectionId,
          payload: JSON.stringify({
            report: queryData,
          }),
        },
      },
    });
    //If error occurs, show warning and use initial metrics/dimensions
    if (error) {
      setValidationCallError(error.message);
    }

    const result = data?.validateCustomForm.customFormValidationResult;
    if (result) {
      if (result.valid) {
        setValidationSuccess(true);
        onReportValid(report.id);
        setValidationCallError(null);
      } else {
        invalidateReport(result.errorMessage);
      }
    }
  }, [
    callValidationQuery,
    connectionId,
    onReportValid,
    report,
    invalidateReport,
  ]);

  const changeReport = (report: KlaviyoReport) => {
    onChangeReport(report);
    setValidationSuccess(false);
  };

  const changeReportType = (report: KlaviyoReport) => {
    report.statistics = undefined;
    report.conversionMetric = undefined;
    onChangeReport(report);
    setupReportOptions(report.type?.value);
    invalidateReport();
  };

  const setupReportOptions = useCallback(
    (reportType?: string) => {
      if (!reportType) {
        return;
      }
      const selectTypeGroup = typeGroups.find(
        (t) => t.type.value === reportType,
      );
      if (!selectTypeGroup) {
        return;
      }
      setStatistics(selectTypeGroup.statistics);
      setMetrics(selectTypeGroup.conversionMetrics);
    },
    [typeGroups],
  );

  useMountEffect(() => {
    if (report.type) {
      setupReportOptions(report.type.value);
      runValidation();
    }
  });

  return (
    <div className="relative rounded-sm border bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
      <div className="pb-4">
        <FieldLabel id="name" required={true}>
          Name
        </FieldLabel>
        <Input
          placeholder="Name of your report"
          value={report.name}
          onChange={(e) => changeReport({ ...report, name: e.target.value })}
        />
        {isKlaviyoStream(report.name) && (
          <InputError>
            {report.name} is a reserved table name and cannot be used
          </InputError>
        )}
      </div>
      <div className="pb-4">
        <FieldLabel id="name" required={true}>
          Type
        </FieldLabel>
        <Select
          inputId="type"
          placeholder="Select one or more...."
          onChange={(v: DropdownOption<string>) => {
            changeReportType({
              ...report,
              type: v,
            });
          }}
          options={reportTypes}
          value={report.type}
          hideSelectedOptions={false}
          isMulti={false}
        />
      </div>
      <div className="pb-4">
        <div className="flex h-auto justify-between">
          <FieldLabel id="statistics" required={true}>
            Statistics
          </FieldLabel>
          <Button
            onClick={() => {
              onChangeReport({
                ...report,
                statistics: statistics,
              });
            }}
            size="sm"
            variant="link"
            id="statistics-select-all"
          >
            Select all
          </Button>
        </div>

        <Select
          inputId="selectedStatistics"
          placeholder="Select one or more..."
          onChange={(v: DropdownOption<string>[]) => {
            if (!v.length) {
              invalidateReport("Report statistics cannot be empty");
            }
            onChangeReport({
              ...report,
              statistics: v,
            });
          }}
          options={statistics}
          value={report.statistics}
          hideSelectedOptions={true}
          isMulti={true}
          closeMenuOnSelect={false}
        />
      </div>
      {metrics && metrics.length > 0 ? (
        <div className="pb-4">
          <FieldLabel id="query" required>
            Conversion metric
          </FieldLabel>
          <Select
            inputId="selectedMetric"
            placeholder="Select one..."
            onChange={(v: DropdownOption<string>) => {
              if (!v) {
                invalidateReport("Conversion metric is required");
              }
              onChangeReport({
                ...report,
                conversionMetric: v,
              });
            }}
            options={metrics}
            value={report.conversionMetric}
            hideSelectedOptions={false}
            isMulti={false}
          />
        </div>
      ) : null}
      <div className="flex justify-between">
        <Button
          size="sm"
          variant="solid"
          colorScheme="primary"
          onClick={() => runValidation()}
        >
          Validate report
        </Button>
      </div>
      <div className="absolute right-0 top-0 p-2">
        <IconButton
          size="xs"
          icon={<XMarkIcon />}
          variant="ghost"
          onClick={() => onRemoveReport(report)}
        />
      </div>
      {validationSuccess ? (
        <div className="mt-2">
          <Alert status="success">
            <AlertIcon />
            Report is valid
          </Alert>
        </div>
      ) : null}
      {validationError ? (
        <div className="mt-2">
          <Alert status="error">
            <AlertIcon />
            {validationError}
          </Alert>
        </div>
      ) : null}
      {!validationCallError && !validationSuccess ? (
        <div className="mt-2">
          <Alert status="info">
            <AlertIcon />
            Validate your report to continue
          </Alert>
        </div>
      ) : null}
      {validationCallError ? (
        <div className="mt-2">
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Error:</AlertTitle>
            {validationCallError}
          </Alert>
        </div>
      ) : null}
    </div>
  );
}
