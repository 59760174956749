import { InformationCircleIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Tooltip from "@/components/elements/Tooltip";
import classNames from "@/helpers/classNames";

import { ModelName } from "./ModelName";
import { ModelJobBox } from "./SharedComponents";
import { ModelRow } from "./useTableRows";

export const ModelItemColumn = ({ row }: { row: ModelRow }) => {
  const modelId = row.model?.id;

  const isLoading = row.isLoading;

  const notInJob = !row.job;
  const noLongerInOrchestration = !isLoading && !row.model && !row.isHistorical;

  return (
    <div
      className={classNames(
        "flex items-center space-x-4",
        noLongerInOrchestration && "opacity-50",
      )}
    >
      <ModelJobBox materializationType={row.model.materializationType} />
      {isLoading && (
        <div className="flex items-center space-x-2">
          <LoadingSpinner /> <span>Loading model..</span>
        </div>
      )}
      {!isLoading && modelId && <ModelName modelId={modelId} />}
      {notInJob && (
        <Tooltip
          content={
            "This model was recently configured to use orchestration. It will be included in the next run."
          }
        >
          <div className="flex items-center space-x-1 rounded border p-1 text-xs">
            <InformationCircleIcon className="h-4 w-4" />
            <span>Recently added</span>
          </div>
        </Tooltip>
      )}
      {noLongerInOrchestration && (
        <Tooltip
          content={
            "This model is no longer configured to use orchestration. It will not be included in the next run."
          }
        >
          <div className="flex items-center space-x-1 rounded border p-1 text-xs">
            <InformationCircleIcon className="h-4 w-4" />
            <span>Removed</span>
          </div>
        </Tooltip>
      )}
    </div>
  );
};
