import { EltSyncsQuery } from "@/apollo/types";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import { useMixpanel } from "@/monitoring/mixpanel";

import EltSyncNotifications from "../ViewEltSync/EltSyncNotifications";
import { EltJobsCompletionStats } from "./EltJobsCompletionStats";
import { DataTable } from "./EltSyncsDataTable";

export type Row = EltSyncsQuery["eltSyncs"][0];

export default function EltSyncsTable(props: {
  syncs: EltSyncsQuery["eltSyncs"];
}) {
  const mixpanel = useMixpanel();

  const { onOpen: viewSync } = useViewDataSourceSlideOver();
  const onRowClick = (row: Row) => {
    mixpanel.track("View Data Source Sync Clicked", {
      source: "sources list",
    });
    viewSync({ syncId: row.id });
  };

  return (
    <div className="flex flex-col gap-8">
      <EltJobsCompletionStats />
      <EltSyncNotifications eltSyncs={props.syncs} />
      <div className="w-full max-w-screen-xl">
        <DataTable syncs={props.syncs} onRowClick={onRowClick} />
      </div>
    </div>
  );
}
