import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import classNames from "@/helpers/classNames";
import React from "react";

export const InputErrorIndicator = ({
  className,
  ...props
}: React.ComponentProps<"div">) => (
  <div
    className={classNames(
      "pointer-events-none flex shrink-0 items-center",
      className,
    )}
    {...props}
  >
    <ExclamationCircleIcon
      className="h-5 w-5 text-red-500"
      aria-hidden="true"
    />
  </div>
);
