import { PresentationChartLineIcon } from "@heroicons/react/24/outline";
import React from "react";

type Props = { className?: string };

const DashboardIcon = (props: Props) => {
  return <PresentationChartLineIcon className={props.className} />;
};

export default DashboardIcon;
