import { useSearch } from "@tanstack/react-location";

export type CreationContext = {
  name: string;
  integrationId?: string;
  isTest?: boolean;
};

export function useWorkspaceCreationContext() {
  const { creationContext } = useSearch<{
    Search: {
      creationContext: CreationContext;
    };
  }>();
  return creationContext;
}

export function createWorkspaceCreationContext(values: {
  name: string;
  integrationId?: string;
  isTest: boolean;
}): CreationContext {
  const creationContext: CreationContext = {
    name: values.name,
  };
  if (values.integrationId) {
    creationContext.integrationId = values.integrationId;
  }
  if (values.isTest) {
    creationContext.isTest = true;
  }
  return creationContext;
}
