import {
  DependencyType,
  ModelDwReferenceFragmentFragment,
  QueryDependencyInput,
} from "@/apollo/types";
import { uniq } from "lodash";
import { useCallback, useMemo } from "react";

import { useListModels } from "./hooks/useListModels";
import { useModelEditorDependencyReferences } from "./ModelEditorStore";
import { useRawViews } from "./useRawViews";

const weldTagRegex = /{{[\w][\w.-]*}}/g;
const bracketsRegex = /[{}]/g;

export function extractWeldTags(sqlQuery: string) {
  if (sqlQuery.trim() === "") return [];

  const weldTags = removeSqlComments(sqlQuery)
    .match(weldTagRegex)
    ?.map((match) => match.replace(bracketsRegex, ""));

  return uniq(weldTags ?? []);
}

type ReferenceMapValueType =
  | ModelDwReferenceFragmentFragment["dwSync"]
  | ModelDwReferenceFragmentFragment["dwTable"]
  | ReturnType<typeof useRawViews>["rawViews"][number];

function toQueryDependencyInput(weldTag: string, ref: ReferenceMapValueType) {
  if (ref) {
    if (
      ref.__typename === "ModelViewReference" ||
      ref.__typename === "MaterializedTableReference"
    ) {
      return {
        dwItemId: ref.modelId,
        weldTag: weldTag,
        type: ref.type,
      } as QueryDependencyInput;
    }
    if (ref.__typename === "RawViewReference") {
      return {
        dwItemId: ref.viewId,
        weldTag: weldTag,
        type: DependencyType.RawView,
      } as QueryDependencyInput;
    }
  }
  return {
    dwItemId: "@missing",
    type: DependencyType.MissingReference,
    weldTag: weldTag,
  } as QueryDependencyInput;
}

function useReferencesMap() {
  const { rawViews } = useRawViews();
  const { models } = useListModels();

  return useMemo(() => {
    const modelRefs = models
      .map((m) => m.dwSync ?? m.dwTable)
      .filter((m) => !!m);

    const map = new Map<string, ReferenceMapValueType>();

    for (const rawView of rawViews) {
      map.set(rawView.weldTag, rawView);
    }

    for (const modelRef of modelRefs) {
      if (modelRef && modelRef.weldTag) {
        map.set(modelRef.weldTag, modelRef);
      }
    }
    return map;
  }, [rawViews, models]);
}

export const useQueryDependencies = () => {
  const referenceMap = useReferencesMap();
  const weldTags = useModelEditorDependencyReferences();
  return useMemo(() => {
    return weldTags.map((weldTag) => {
      const ref = referenceMap.get(weldTag);
      return toQueryDependencyInput(weldTag, ref);
    });
  }, [weldTags, referenceMap]);
};

export function useGetQueryDependencies() {
  const referenceMap = useReferencesMap();
  return useCallback(
    (weldTags: string[]) => {
      return weldTags.map((weldTag) => {
        const ref = referenceMap.get(weldTag);
        return toQueryDependencyInput(weldTag, ref);
      });
    },
    [referenceMap],
  );
}

/**
 * Removes any SQL comments from a string query.
 *
 * In most SQL dialects, single-line comments are started with "--"
 * and always run until the end of the line.
 *
 * Multi-line comments are enclosed in `/*` and `*\/`, similar to JS,
 * because they can't be nested, we use a non-greedy match.
 *
 * Check tests for specific examples.
 */
export const removeSqlComments = (sql: string) => {
  return sql
    .replace(/(--.*\s?)/g, "") // Single-Line comments
    .replace(/\/\*([\s\S]*?)\*\//gm, ""); // Multi-Line comments
};
