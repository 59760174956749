import React from "react";

import { WorkflowRunStatus } from "@/apollo/types";
import Badge, { BadgeVariant } from "@/components/elements/Badge_Legacy";
import { StatusLED } from "@/components/elements/StatusLED";

const RunStatusConfig: Record<
  WorkflowRunStatus,
  {
    displayText: string;
    badgeVariant: BadgeVariant;
  }
> = {
  [WorkflowRunStatus.Starting]: {
    displayText: "Starting",
    badgeVariant: BadgeVariant.Default,
  },
  [WorkflowRunStatus.NotStarted]: {
    displayText: "Scheduled",
    badgeVariant: BadgeVariant.Inactive,
  },
  [WorkflowRunStatus.Done]: {
    displayText: "Success",
    badgeVariant: BadgeVariant.Success,
  },
  [WorkflowRunStatus.Running]: {
    displayText: "Running",
    badgeVariant: BadgeVariant.Default,
  },
  [WorkflowRunStatus.Failed]: {
    displayText: "Failed",
    badgeVariant: BadgeVariant.Error,
  },
  [WorkflowRunStatus.Canceled]: {
    displayText: "Canceled",
    badgeVariant: BadgeVariant.Inactive,
  },
};

export const WorkflowRunStatusDisplayText = (props: {
  runStatus: WorkflowRunStatus;
}) => {
  const config = RunStatusConfig[props.runStatus];
  return <span>{config.displayText}</span>;
};

export const WorkflowRunStatusBadge = ({
  runStatus,
  children,
}: React.PropsWithChildren<{
  runStatus: WorkflowRunStatus;
}>) => {
  const config = RunStatusConfig[runStatus];
  return (
    <Badge variant={config.badgeVariant}>
      {runStatus === WorkflowRunStatus.Running && (
        <span className="mr-1">
          <StatusLED pulse variant={BadgeVariant.Default} />
        </span>
      )}
      {children}
    </Badge>
  );
};
