import React from "react";

export const BlueTwoEye1 = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 100 100"
    >
      <ellipse cx="50.5" cy="50" fill="#99C5FA" rx="50.5" ry="50"></ellipse>
      <mask
        id="mask0_1928_10504"
        style={{ maskType: "alpha" }}
        width="100"
        height="100"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect width="100" height="100" fill="#C4C4C4" rx="2"></rect>
      </mask>
      <g mask="url(#mask0_1928_10504)">
        <ellipse
          cx="55.724"
          cy="55.171"
          fill="#0070F4"
          rx="104.483"
          ry="82.759"
        ></ellipse>
        <ellipse
          cx="108.706"
          cy="51.724"
          fill="#0059C3"
          rx="66.172"
          ry="65.517"
        ></ellipse>
        <path
          fill="#0070F4"
          d="M88.405 52.687c1.318.773 1.317 2.68-.002 3.45l-2.914 1.705c-53.638 31.37-120.026 31.37-173.664 0l-2.914-1.704c-1.319-.772-1.32-2.678-.002-3.451l2.671-1.569c53.762-31.56 120.392-31.56 174.154 0l2.671 1.569z"
        ></path>
        <ellipse
          fill="#fff"
          rx="21.523"
          ry="21.738"
          transform="rotate(-89.602 24.77 26.475) skewX(.065)"
        ></ellipse>
        <ellipse
          fill="#000"
          rx="11.133"
          ry="11.244"
          transform="rotate(-89.602 24.77 26.475) skewX(.065)"
        ></ellipse>
        <ellipse
          fill="#fff"
          rx="21.523"
          ry="21.738"
          transform="rotate(-89.602 75.27 -24.377) skewX(.065)"
        ></ellipse>
        <ellipse
          fill="#000"
          rx="11.133"
          ry="11.244"
          transform="rotate(-89.602 75.27 -24.377) skewX(.065)"
        ></ellipse>
      </g>
    </svg>
  );
};
