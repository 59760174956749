import { atom, useAtom } from "jotai";
import { useCallback } from "react";

const openFoldersState = atom<string[]>([]);

export function useSidebarFolders() {
  const [openFolders, setOpenFolders] = useAtom(openFoldersState);

  const toggleFolder = useCallback(
    (folderId: string) => {
      setOpenFolders((state) => {
        const openFolders = state.slice();
        const index = openFolders.indexOf(folderId);
        if (index === -1) {
          openFolders.push(folderId);
        } else {
          openFolders.splice(index, 1);
        }
        return openFolders;
      });
    },
    [setOpenFolders],
  );

  const openFolder = useCallback(
    (folderId: string) => {
      setOpenFolders((state) => {
        if (state.includes(folderId)) {
          return state;
        }
        return [...state, folderId];
      });
    },
    [setOpenFolders],
  );

  return {
    openFolders,
    toggleFolder,
    openFolder,
  };
}
