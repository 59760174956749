import { useCallback } from "react";

import { useUpdateFireMonacoText } from "../../hooks/useFireMonaco";
import { useModelEditorState } from "../../ModelEditorStore";
import { useMonacoInstance } from "../SQLEditor";

export const useUpdateEditorText = () => {
  const state = useModelEditorState();

  const updateLiveText = useUpdateFireMonacoText();
  const [editor] = useMonacoInstance();

  const handleUpdateText = useCallback(
    (weldSql: string) => {
      if (state.currentModelType === "model") {
        updateLiveText(weldSql);
      } else {
        const model = editor?.getModel();
        if (model) {
          // This will preserve history undo/redo as opposed to `setValue()`
          model.pushEditOperations(
            [],
            [
              {
                range: model.getFullModelRange(),
                text: weldSql,
                forceMoveMarkers: true,
              },
            ],
            () => null,
          );
        }
      }
    },
    [editor, state.currentModelType, updateLiveText],
  );
  return handleUpdateText;
};
