import classNames from "@/helpers/classNames";
import { isEqual } from "lodash";
import { memo, useCallback } from "react";
import { Column, usePagination, useTable } from "react-table";

import PaginationNav from "./PaginationNav";

const customPadding = {
  left: {
    md: "pl-5",
    lg: "pl-8",
  },
  right: {
    md: "pr-5",
    lg: "pr-8",
  },
};

const PreviewTable = memo(
  ({
    columns,
    data,
    onRowClick,
    rowsPerPage = 100,
    padding = "md",
  }: {
    columns?: Column[];
    data: any;
    onRowClick?: Function | null;
    rowsPerPage?: number;
    padding?: "md" | "lg";
  }) => {
    const handleRowClick = useCallback(
      (id: string) => {
        if (!onRowClick) return;
        onRowClick(id);
      },
      [onRowClick],
    );
    // Use the state and functions returned from useTable to build table UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      nextPage,
      previousPage,
      pageCount,
      canNextPage,
      canPreviousPage,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns: columns ?? [],
        data,
        initialState: {
          pageSize: rowsPerPage,
        },
      },
      usePagination,
    );

    return (
      <div className="flex h-full flex-col bg-white dark:bg-gray-800">
        <div className="relative w-full grow overflow-x-hidden">
          <div className="h-full w-full grow overflow-auto">
            <table
              {...getTableProps()}
              className="min-w-full table-fixed divide-y divide-gray-200 dark:divide-gray-700"
            >
              <thead className="">
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="sticky top-0 bg-white dark:bg-gray-800"
                  >
                    {headerGroup.headers.map((column, i) => {
                      const isLastCell = i === headerGroup.headers.length - 1;

                      return (
                        <th
                          scope="col"
                          className={classNames(
                            "truncate py-1 text-left text-xs font-medium tracking-wider text-gray-500",
                            customPadding["left"][padding],
                            isLastCell && customPadding["right"][padding],
                          )}
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="divide-y divide-gray-200 dark:divide-gray-700"
              >
                <Page
                  page={page}
                  prepareRow={prepareRow}
                  onRowClick={onRowClick}
                  handleRowClick={handleRowClick}
                  data={data}
                  padding={padding}
                />
              </tbody>
            </table>
          </div>
        </div>
        {(canNextPage || canPreviousPage) && (
          <PaginationNav
            {...{
              nextPage,
              previousPage,
              pageCount,
              canNextPage,
              canPreviousPage,
              state: { pageIndex, pageSize },
            }}
          />
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
  },
);

const Page = memo(
  ({
    page,
    prepareRow,
    onRowClick,
    handleRowClick,
    data,
    padding = "md",
  }: {
    page: any;
    prepareRow: Function;
    onRowClick: Function | null | undefined;
    handleRowClick: Function;
    data: any;
    padding?: "md" | "lg";
  }) => {
    return page.map((row: any, i: number) => {
      prepareRow(row);
      return (
        <tr
          onClick={() => handleRowClick(data[i].id)}
          className={`${onRowClick ? "cursor-pointer hover:bg-gray-100" : ""}`}
          {...row.getRowProps()}
        >
          {row.cells.map((cell: any, i: number) => {
            const isLastCell = i === row.cells.length - 1;
            return (
              <td
                className={classNames(
                  "py-1 align-top text-xs",
                  customPadding["left"][padding],
                  isLastCell && customPadding["right"][padding],
                )}
                {...cell.getCellProps()}
              >
                <div className="max-h-24 min-h-full max-w-sm overflow-y-auto break-words">
                  {cell.render("Cell")}
                </div>
              </td>
            );
          })}
        </tr>
      );
    });
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
  },
);

export default PreviewTable;
