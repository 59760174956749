import { useDarkMode } from "@/providers/DarkModeProvider";
import { formatPercent } from "@/shared/formatters";

export default function PercentageCircle(
  props: {
    percentage: number;
    showPercentage?: boolean;
    // The width of the track as a percentage of the circle's diameter
    trackWidthPercentage?: number;
  } & React.ComponentProps<"svg">,
) {
  const {
    percentage,
    showPercentage,
    trackWidthPercentage = 0.1,
    ...svgProps
  } = props;
  const { isDarkModeEnabled } = useDarkMode();

  const trackColor = isDarkModeEnabled ? "#404040" : "#eaeaea";
  const progressColor = props.percentage < 0.98 ? "#60a5fa" : "#f87171";

  const size = 100;
  const center = size / 2;
  const trackWidth = size * trackWidthPercentage;
  const radius = (size - trackWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  return (
    <svg viewBox={`0 0 ${size} ${size}`} {...svgProps}>
      <circle
        cx={center}
        cy={center}
        r={radius}
        fill="none"
        stroke={trackColor}
        strokeWidth={trackWidth}
      />
      <circle
        cx={center}
        cy={center}
        r={radius}
        fill="none"
        stroke={progressColor}
        strokeWidth={trackWidth}
        strokeLinecap="round"
        strokeDasharray={`${
          props.percentage * circumference
        }, ${circumference}`}
        transform="rotate(-90)"
        {...{ "transform-origin": "center center" }}
      />
      {props.showPercentage && (
        <text
          x={center}
          y={center}
          fill={isDarkModeEnabled ? "#F1F2F3" : "#141515"}
          fontSize={radius / 2}
          fontWeight="500"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {formatPercent(props.percentage)}
        </text>
      )}
    </svg>
  );
}
