import React from "react";

declare global {
  interface Window {
    StonlyWidget: (method: string, param?: any, extra?: any) => void;
  }
}

export function useStonlyWidget() {
  return React.useCallback(
    (...args: Parameters<typeof window.StonlyWidget>) => {
      if (!("StonlyWidget" in window)) {
        return;
      }
      window.StonlyWidget(...args);
    },
    [],
  );
}
