import produce from "immer";
import { useCallback, useMemo, useState } from "react";

import { HistoryData } from "./types";

function mergeArrays(
  arr1: HistoryData[] = [],
  arr2: HistoryData[] = [],
): HistoryData[] {
  const uniqueMap = new Map<string, HistoryData>();

  const addToMap = (arr: HistoryData[]) => {
    for (const item of arr) {
      if (item && item.startedAt) {
        uniqueMap.set(item.startedAt, {
          ...(uniqueMap.get(item.startedAt) ?? {}),
          ...item,
        });
      }
    }
  };

  addToMap(arr1);
  addToMap(arr2);

  return Array.from(uniqueMap.values());
}

const producer = (
  prevData: Record<string, { sourceStreamName: string; data: HistoryData[] }>,
  items: HistoryData[],
) => {
  return produce(prevData, (draft) => {
    items.forEach((item) => {
      const streamName = item.sourceStreamName;
      if (!streamName) {
        return;
      }
      if (!draft[streamName]) {
        draft[streamName] = {
          sourceStreamName: streamName,
          data: [item],
        };
        return;
      }
      const existingIdx = draft[streamName].data.findIndex(
        (x) => x.startedAt === item.startedAt,
      );
      if (existingIdx !== -1) {
        draft[streamName].data[existingIdx] = item;
      } else {
        draft[streamName].data.push(item);
      }
    });
  });
};

export function useTimelineState(sourceStreamsNames: string[] = []) {
  const [historicState, setHistoricState] = useState<
    Record<
      string,
      {
        sourceStreamName: string;
        data: HistoryData[];
      }
    >
  >(() => {
    return sourceStreamsNames.reduce(
      (acc, name) => {
        acc[name] = {
          sourceStreamName: name,
          data: [],
        };
        return acc;
      },
      {} as Record<string, { sourceStreamName: string; data: HistoryData[] }>,
    );
  });
  const [recentState, setRecentState] = useState<
    Record<
      string,
      {
        sourceStreamName: string;
        data: HistoryData[];
      }
    >
  >(() => {
    return sourceStreamsNames.reduce(
      (acc, name) => {
        acc[name] = {
          sourceStreamName: name,
          data: [],
        };
        return acc;
      },
      {} as Record<string, { sourceStreamName: string; data: HistoryData[] }>,
    );
  });

  const addHistoricData = useCallback((items: HistoryData[]) => {
    setHistoricState((prevData) => {
      return producer(prevData, items);
    });
  }, []);

  const addRecentData = useCallback((items: HistoryData[]) => {
    setRecentState((prevData) => {
      return producer(prevData, items);
    });
  }, []);

  const data = useMemo(() => {
    const sourceStreamNames = new Set(
      Object.keys(historicState).concat(Object.keys(recentState)),
    );

    const mergedData = Array.from(sourceStreamNames).reduce(
      (acc, sourceStreamName) => {
        const historicSourceStreamData =
          historicState[sourceStreamName]?.data ?? [];
        const recentSourceStreamData =
          recentState[sourceStreamName]?.data ?? [];

        const data = mergeArrays(
          historicSourceStreamData,
          recentSourceStreamData,
        );
        acc[sourceStreamName] = {
          sourceStreamName,
          data,
        };
        return acc;
      },
      {} as Record<
        string,
        {
          sourceStreamName: string;
          data: HistoryData[];
        }
      >,
    );

    return Object.values(mergedData).sort((a, b) =>
      a.sourceStreamName.localeCompare(b.sourceStreamName),
    );
  }, [historicState, recentState]);

  return {
    data,
    addHistoricData,
    addRecentData,
  };
}
