import {
  ScheduleRadioGroup,
  ScheduleRadioOption,
  useSyncScheduleContext,
} from "@/components/elements/SyncScheduler";
import Tooltip from "@/components/elements/Tooltip";
import { useCurrentPlan } from "@/features/subscription";
import { RadioGroup, RadioGroupProps } from "@headlessui/react";

import { CronOption } from "../WeldSyncScheduler";
import { UnavailableCronOptionTooltipContent } from "./UnavailableCronOptionTooltipContent";

export function SyncScheduleRadioGroup(
  props: RadioGroupProps<"div", CronOption>,
) {
  const weldPlan = useCurrentPlan();
  const { options } = useSyncScheduleContext<CronOption>();
  return (
    <ScheduleRadioGroup {...props}>
      {options.map((option) => {
        if (!option.disabled) {
          return (
            <ScheduleRadioOption
              key={option.value}
              value={option}
              disabled={option.disabled}
            >
              <RadioGroup.Label className="block truncate text-center text-sm">
                {option.label}
              </RadioGroup.Label>
            </ScheduleRadioOption>
          );
        }
        return (
          <Tooltip
            key={option.value}
            content={
              <UnavailableCronOptionTooltipContent
                currentPlanName={weldPlan.name}
                targetPlan={option.targetPlan}
              />
            }
          >
            <span>
              <ScheduleRadioOption value={option} disabled={option.disabled}>
                <RadioGroup.Label className="block truncate text-center text-sm">
                  {option.label}
                </RadioGroup.Label>
              </ScheduleRadioOption>
            </span>
          </Tooltip>
        );
      })}
    </ScheduleRadioGroup>
  );
}
