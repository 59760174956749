import { IntegrationLogoBox } from "@/integrations";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "@tanstack/react-location";

type Scoping = { label?: string; link?: string };

const exists = (x: Scoping | undefined): x is Scoping & { label: string } =>
  !!x?.label;

export default function IntegrationCellTitle(props: {
  integrationId: string;
  connectionLabel?: string;
  scopings?: ({ label?: string; link?: string } | undefined)[];
  size?: React.ComponentProps<typeof IntegrationLogoBox>["size"];
}) {
  return (
    <div className="flex items-center space-x-2 whitespace-nowrap">
      <IntegrationLogoBox id={props.integrationId} size={props.size ?? "1xs"} />
      <div>{props.connectionLabel || props.integrationId}</div>
      {props.scopings?.filter(exists).map((scoping) => (
        <div key={scoping.label} className="flex items-center space-x-2">
          <ChevronRightIcon className="w-3 text-gray-400" />
          <div className="text-xs text-gray-400">
            {scoping.link ? (
              <Link className="hover:text-gray-600" to={scoping.link}>
                {scoping.label}
              </Link>
            ) : (
              scoping.label
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
