import { Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";

export default function AppearTransition({
  children,
}: {
  children: JSX.Element;
}) {
  const [state, setState] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setState(true));
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Transition
      as={Fragment}
      appear={true}
      show={state}
      enter="transition-all transform pop-up-fade duration-400"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition-all transform pop-up-fade duration-400"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-75"
    >
      {children}
    </Transition>
  );
}
