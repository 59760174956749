import { atom, useAtom, useAtomValue } from "jotai";

import { RecurrenceInterval } from "@/apollo/types";

const billingPeriodAtom = atom<
  RecurrenceInterval.Month | RecurrenceInterval.Year
>(RecurrenceInterval.Year);

export function useBillingPeriodToggle() {
  const [billingPeriod, setBillingPeriod] = useAtom(billingPeriodAtom);

  const toggleBillingPeriod = () => {
    setBillingPeriod((prev) =>
      prev === RecurrenceInterval.Year
        ? RecurrenceInterval.Month
        : RecurrenceInterval.Year,
    );
  };
  return { billingPeriod, setBillingPeriod, toggleBillingPeriod };
}

export function useBillingPeriodValue() {
  return useAtomValue(billingPeriodAtom);
}
