import { TextMuted } from "@/components/elements/Typography";
import classNames from "@/helpers/classNames";
import React from "react";

export default function HRWithText({
  className,
  children,
  ...restProps
}: React.PropsWithChildren<React.ComponentProps<"div">>) {
  return (
    <div
      className={classNames("relative flex w-full items-center", className)}
      {...restProps}
    >
      <div className="grow border-t border-gray-200 dark:border-gray-700"></div>
      <TextMuted className="mx-4 shrink">{children}</TextMuted>
      <div className="grow border-t border-gray-200 dark:border-gray-700"></div>
    </div>
  );
}
