import { LoadingOverlay } from "@/components/elements/LoadingComponents";
import PreviewTable from "@/components/elements/PreviewTable";
import { useTableSchema } from "@/pages/ModelTool/Preview/Preview";

export function TableComponent(props: { data: any[]; loading?: boolean }) {
  const tableSchema = useTableSchema(props.data ?? []);

  return (
    <div className="absolute inset-0 overflow-hidden pt-4">
      {props.loading ? (
        <LoadingOverlay />
      ) : (
        <PreviewTable data={props.data} columns={tableSchema} padding="lg" />
      )}
    </div>
  );
}
