import classNames from "@/helpers/classNames";

import { Listbox, ListboxProps } from "@headlessui/react";
import { CronOptionBase, useSyncScheduleContext } from "../useSyncSchedule";

export function ScheduleSelect<T extends CronOptionBase = CronOptionBase>(
  props: ListboxProps<"div", T, T> & {
    myRef?: React.Ref<HTMLDivElement>;
  },
) {
  const { selectedOption, onChange, mode } = useSyncScheduleContext<T>();
  return (
    <Listbox
      ref={props.myRef}
      value={selectedOption}
      onChange={(option: T) => {
        if (option == null || option.disabled) {
          return;
        }
        onChange(option);
      }}
      as="div"
      className={classNames(
        "relative",
        props.className,
        mode === "custom" && "hidden",
      )}
      placeholder="Select a schedule"
    >
      {props.children}
    </Listbox>
  );
}
