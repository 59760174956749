import classNames from "@/helpers/classNames";

export default function Content(props: {
  children: JSX.Element | JSX.Element[];
  narrow?: boolean;
}) {
  return (
    <main
      className={`relative z-0 flex flex-1 flex-col overflow-y-auto overflow-x-hidden focus:outline-none`}
    >
      <div
        className={classNames(
          "mx-auto flex w-full grow flex-col p-4 sm:p-6 md:p-8",
          props.narrow ? "max-w-4xl" : "max-w-8xl",
        )}
      >
        {props.children}
      </div>
    </main>
  );
}

export const HRForContent = (props: {}) => {
  return (
    <div className="relative h-px">
      <hr
        className="absolute border-t border-gray-200 dark:border-gray-700"
        style={{
          width: `200%`,
          marginLeft: "-50%",
        }}
      />
    </div>
  );
};
