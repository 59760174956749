import { useCallback, useEffect, useState } from "react";

import { useCallbackRef } from "@/hooks/useCallbackRef";
import { useIntegrationDocumentationResource } from "@/integrations";

export function useDocumentationPane(
  integrationId: string | undefined,
  options: {
    onClose?: () => void;
    onOpen?: () => void;
  },
) {
  const [show, setShow] = useState(true);

  const onOpen = useCallbackRef(options.onOpen);
  const onClose = useCallbackRef(options.onClose);

  const documentationResource =
    useIntegrationDocumentationResource(integrationId);

  const isOpen = !!(show && integrationId && documentationResource.data);

  useEffect(() => {
    if (show && documentationResource.data) {
      onOpen();
    }
  }, [show, documentationResource.data, onOpen]);

  useEffect(() => {
    if (!show) {
      onClose();
    }
  }, [show, onClose]);

  useEffect(() => {
    if (!integrationId) {
      onClose();
    }
  }, [integrationId, onClose]);

  return {
    toggle: useCallback(
      (value?: boolean) =>
        setShow((prev) => {
          if (value === undefined) {
            return !prev;
          }
          return value;
        }),
      [],
    ),
    paneProps: {
      data: documentationResource.data,
      isLoading: documentationResource.loading,
      isOpen,
      onClose: () => setShow(false),
    },
  };
}
