import dayjs from "dayjs";
import minMaxPlugin from "dayjs/plugin/minMax";

dayjs.extend(minMaxPlugin);

export const discontinuationDate = dayjs.utc("2024-12-01T13:00:00");

export function getNextDismissedUntil(
  referenceDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
) {
  const daysUntilEnd = dayjs(endDate).diff(referenceDate, "days");
  if (daysUntilEnd <= 7) {
    return referenceDate.add(1, "days");
  }
  const oneWeekBeforeEnd = endDate.subtract(7, "days");
  const inOneWeek = referenceDate.add(7, "days");
  const dismissedUntil = dayjs.min(oneWeekBeforeEnd, inOneWeek);
  return dismissedUntil;
}
