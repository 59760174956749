import { useMemo } from "react";

import { useIntegrationsMap } from "@/integrations";

import {
  ConnectorOption,
  createConnectorOption,
  useGetIntegrationFlags,
} from "./useCreateConnectorOptions";

const connectors = [
  // Google Drive
  {
    name: "Excel via Google Drive",
    id: "google-drive__excel",
    targetIntegrationId: "google-drive",
  },
  {
    name: "CSV via Google Drive",
    id: "google-drive__csv",
    targetIntegrationId: "google-drive",
  },
  // OneDrive
  {
    name: "Excel via OneDrive",
    id: "onedrive__excel",
    targetIntegrationId: "onedrive",
  },
  {
    name: "CSV via OneDrive",
    id: "onedrive__csv",
    targetIntegrationId: "onedrive",
  },
  {
    name: "Sharepoint",
    id: "sharepoint",
    targetIntegrationId: "onedrive",
  },
  {
    name: "Excel via Sharepoint",
    id: "sharepoint__excel",
    targetIntegrationId: "onedrive",
  },
  {
    name: "CSV via Sharepoint",
    id: "sharepoint__csv",
    targetIntegrationId: "onedrive",
  },
  // FTP
  {
    name: "CSV via FTP",
    id: "ftp__csv",
    targetIntegrationId: "ftp",
  },
  // SFTP
  {
    name: "CSV via SFTP",
    id: "sftp__csv",
    targetIntegrationId: "sftp",
  },
  // AWS S3
  {
    name: "CSV via S3",
    id: "aws-s3__csv",
    targetIntegrationId: "aws-s3",
  },
];

export function useAliasConnectorOptions(): ConnectorOption[] {
  const registry = useIntegrationsMap();
  const getIntegrationFlags = useGetIntegrationFlags();

  return useMemo(() => {
    return connectors
      .map(({ name, id, targetIntegrationId }) => {
        const integration = registry.get(targetIntegrationId);
        if (!integration) {
          return null;
        }
        const flags = getIntegrationFlags(targetIntegrationId);
        const option = createConnectorOption(integration, flags);
        option.label = name;
        option.value = id;
        option.isAlias = true;
        return option;
      })
      .filter((x): x is ConnectorOption => x !== null);
  }, [registry, getIntegrationFlags]);
}
