import React from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useFormContext } from "react-hook-form";

import Select from "@/components/elements/Select";
import ButtonPrimitive from "@/components/primitives/Button";
import FieldLabel from "@/components/primitives/InputLabel";
import classNames from "@/helpers/classNames";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";

import FormTextInput from "./FormTextInput";

type Option = {
  value: string;
  label: string;
};

const america = [
  { region_description: "Columbus, Ohio", region_name: "us-east5" },
  { region_description: "Dallas", region_name: "us-south1" },
  { region_description: "Iowa", region_name: "us-central1" },
  { region_description: "Las Vegas", region_name: "us-west4" },
  { region_description: "Los Angeles", region_name: "us-west2" },
  { region_description: "Montréal", region_name: "northamerica-northeast1" },
  { region_description: "Northern Virginia", region_name: "us-east4" },
  { region_description: "Oregon", region_name: "us-west1" },
  { region_description: "Salt Lake City", region_name: "us-west3" },
  { region_description: "South Carolina", region_name: "us-east1" },
  { region_description: "São Paulo", region_name: "southamerica-east1" },
  { region_description: "Santiago", region_name: "southamerica-west1" },
  { region_description: "Toronto", region_name: "northamerica-northeast2" },
];

const asia = [
  { region_description: "Delhi", region_name: "asia-south2" },
  { region_description: "Hong Kong", region_name: "asia-east2" },
  { region_description: "Jakarta", region_name: "asia-southeast2" },
  { region_description: "Melbourne", region_name: "australia-southeast2" },
  { region_description: "Mumbai", region_name: "asia-south1" },
  { region_description: "Osaka", region_name: "asia-northeast2" },
  { region_description: "Seoul", region_name: "asia-northeast3" },
  { region_description: "Singapore", region_name: "asia-southeast1" },
  { region_description: "Sydney", region_name: "australia-southeast1" },
  { region_description: "Taiwan", region_name: "asia-east1" },
  { region_description: "Tokyo", region_name: "asia-northeast1" },
];

const emea = [
  { region_description: "Belgium", region_name: "europe-west1" },
  { region_description: "Berlin", region_name: "europe-west10" },
  { region_description: "Finland", region_name: "europe-north1" },
  { region_description: "Frankfurt", region_name: "europe-west3" },
  { region_description: "London", region_name: "europe-west2" },
  { region_description: "Madrid", region_name: "europe-southwest1" },
  { region_description: "Milan", region_name: "europe-west8" },
  { region_description: "Netherlands", region_name: "europe-west4" },
  { region_description: "Paris", region_name: "europe-west9" },
  { region_description: "Turin", region_name: "europe-west12" },
  { region_description: "Warsaw", region_name: "europe-central2" },
  { region_description: "Zürich", region_name: "europe-west6" },
  { region_description: "Dammam", region_name: "me-central2" },
  { region_description: "Doha", region_name: "me-central1" },
  { region_description: "Tel Aviv", region_name: "me-west1" },
  { region_description: "Johannesburg", region_name: "africa-south1" },
];

const mapLocations = (
  locations: { region_description: string; region_name: string }[],
) => {
  return locations.map((loc) => ({
    value: loc.region_name,
    label: `${loc.region_description} (${loc.region_name})`,
  }));
};

const groupedOptions: (
  | Option
  | {
      label: string;
      options: Option[];
    }
)[] = [
  { value: "EU", label: "EU" },
  { value: "US", label: "US" },
  {
    label: "Europe, Middle East, and Africa region",
    options: mapLocations(emea),
  },
  {
    label: "America",
    options: mapLocations(america),
  },
  {
    label: "Asia",
    options: mapLocations(asia),
  },
];

export default function BigQueryPage() {
  const { control, reset } = useFormContext();
  return (
    <div>
      <div className="mb-4">
        <FieldLabel required={true} id="payload">
          Upload your Google Service Key
        </FieldLabel>
        <div className="mt-1">
          <Dropzone
            onChange={(json) => {
              reset(json);
            }}
          />
        </div>
      </div>

      <FormTextInput
        field={{
          label: "Project ID",
          name: "project_id",
          type: "text",
          required: true,
          inputProps: {
            disabled: true,
            placeholder: "Your Project ID will show here..",
          },
        }}
      />

      <div>
        <FieldLabel required={true} id="location">
          Data Location
        </FieldLabel>
        <div className="mt-1">
          <Controller
            render={({ field: { onChange, onBlur, value } }) => {
              const selectedOption = groupedOptions
                .flatMap((x) =>
                  "options" in x && Array.isArray(x.options)
                    ? x.options
                    : ([x] as Option[]),
                )
                .find((opt) => opt.value === value);
              return (
                <Select
                  onBlur={onBlur}
                  onChange={(v: Option | null) => {
                    onChange(v?.value);
                  }}
                  value={selectedOption}
                  options={groupedOptions}
                />
              );
            }}
            rules={{ required: true }}
            name={"location"}
            control={control}
            defaultValue={null}
          />
        </div>
      </div>
    </div>
  );
}

type DropzoneProps = {
  onChange: (json: Record<string, any>) => void;
};

const Dropzone = ({ onChange }: DropzoneProps) => {
  const [filename, setFilename] = React.useState("");

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        // eslint-disable-next-line no-console
        reader.onabort = () => console.warn("file reading was aborted");
        // eslint-disable-next-line no-console
        reader.onerror = () => console.warn("file reading has failed");
        reader.onload = () => {
          const resultJSON = JSON.parse(reader.result?.toString() || "");
          if (resultJSON.private_key) {
            onChange(resultJSON);
            setFilename(file.name);
          }
        };
        reader.readAsText(file, "UTF-8");
      });
    },
    [onChange],
  );

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      accept: {
        "application/json": [],
      },
    });

  return (
    <div
      className={"mt-1 sm:col-span-2 sm:mt-0"}
      {...getRootProps()}
      role="button"
    >
      <input {...getInputProps()} />
      {acceptedFiles.length === 0 ? (
        <div
          className={classNames(
            "flex justify-center px-6 pb-6 pt-5",
            "rounded-md border-2 border-dashed border-gray-300 dark:border-gray-500",
            "hover:border-blue-200",
            "hover:bg-blue-50 dark:hover:bg-gray-800",
            isDragActive &&
              "border-blue-200 bg-blue-50 dark:border-blue-500 dark:bg-gray-800",
          )}
        >
          <div className="space-y-1 text-center">
            <DocumentPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
            <div className="text-sm text-gray-600">
              <span
                className={classNames(
                  "font-medium text-blue-500",
                  "focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2",
                )}
              >
                <span>Click to select your Google Service Account file</span>
              </span>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">.JSON</p>
          </div>
        </div>
      ) : (
        <ButtonPrimitive className="flex h-12 w-full items-center rounded border border-gray-500 px-4 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:hover:bg-gray-700">
          <span className="truncate">{filename}</span>
        </ButtonPrimitive>
      )}
    </div>
  );
};
