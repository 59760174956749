import React, { useCallback, useState } from "react";
import { useNavigateWithSlug } from "routes";

import {
  DependencyType,
  HasDeletedModelsDocument,
  ListModelsQuery,
  MaterializationType,
  useMoveModelMutation,
  useRemoveModelMutation,
  useUnpublishModelMutation,
  useUpdateDraftModelNameMutation,
} from "@/apollo/types";
import ConfirmDeleteModal from "@/components/elements/ConfirmDeleteModal";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import MenuItem from "@/components/elements/MenuItem";
import {
  useCloseRemovedTab,
  useOpenModelInTab,
} from "@/components/modules/ModelTabs";
import TableMenu from "@/components/modules/TableMenu";
import { useSetupRevELTSimple } from "@/features/reverse-elt/useSetupRevELTSimple";
import { optionKeyString } from "@/helpers/isWindows";
import { useIntegration } from "@/integrations";
import { useSchemaSidebar } from "@/pages/ModelTool/ModelEditorStore";
import { usePreview } from "@/pages/ModelTool/Preview/usePreview";
import { useCreateDraftModel } from "@/pages/ModelTool/QueryEditor/useModelDraft";
import { useToast } from "@/providers/ToastProvider";
import { useDataWarehouse } from "@/providers/account";

import { useSafeState } from "../../../../hooks/useSafeState";
import { SaveModelModal } from "../../QueryEditor/SaveModelModal";

export const ModelOptions: React.FC<{
  isSelected: boolean;
  model: ListModelsQuery["models"][0];
}> = (props) => {
  const { integration } = useDatawarehouseInfo();

  const openModelInTab = useOpenModelInTab();
  const createDraftModel = useCreateDraftModel();
  const preview = usePreview();

  const [, setSchemaSidebar] = useSchemaSidebar();
  const { open: openRevELTSidebar } = useSetupRevELTSimple();

  const {
    handleMoveModel,
    updateModalOpen,
    loading: loadingMoveModel,
    setUpdateModalOpen,
  } = useMoveModel(props.model);

  const {
    removeModel,
    removing,
    setShowConfirmDeleteModal,
    showConfirmDeleteModal,
  } = useDeleteModel(props.isSelected);

  const {
    handleUpdateDraft,
    loading: loadingDraftUpdate,
    setUpdateDraftModalOpen,
    updateDraftModalOpen,
  } = useUpdateDraft(props.model);

  const toast = useToast();
  const [unpublishModel, unpublishMutation] = useUnpublishModelMutation({
    onError(error) {
      toast(`Error unpublishing model`, error.message, "error");
    },
    onCompleted() {
      toast(
        `Unpublished model`,
        `The model is no longer in the data warehouse`,
        "success",
      );
    },
  });

  function handleUnpublishModel(model: ListModelsQuery["models"][0]) {
    unpublishModel({
      variables: {
        modelId: model.id,
      },
    });
  }

  if (removing || unpublishMutation.loading) return <LoadingSpinner />;
  return (
    <>
      <TableMenu size="sm">
        {(!!props.model.dwSync || !!props.model.dwTable) && (
          <>
            <MenuItem
              text="Query data"
              size="sm"
              onClick={() => {
                const weldTag =
                  props.model.dwTable?.weldTag || props.model.dwSync?.weldTag;
                if (!weldTag) return;
                const sqlQuery = `SELECT * FROM {{${weldTag}}} LIMIT 100;`;

                const draft = createDraftModel(sqlQuery);

                openModelInTab({ modelId: draft.id, type: "draft" });
                preview({
                  sqlQuery: sqlQuery,
                  dependencies: [
                    {
                      dwItemId: props.model.id,
                      weldTag: weldTag,
                      type:
                        props.model.materializationType ===
                        MaterializationType.View
                          ? DependencyType.ModelView
                          : DependencyType.MaterializedTable,
                    },
                  ],
                  modelId: draft.id,
                  isTextSelection: false,
                  modelName: `${draft.name} (${draft.number})`,
                  modelType: "draft",
                });
              }}
            />

            <MenuItem
              text="View schema"
              size="sm"
              tooltipText="⇧ + click"
              onClick={() => {
                setSchemaSidebar(props.model.id);
              }}
            />
          </>
        )}
        <>
          <MenuItem
            text="Open in current tab"
            size="sm"
            onClick={() => {
              openModelInTab({ modelId: props.model.id });
            }}
          />
        </>
        <>
          <MenuItem
            text="Open in new tab"
            size="sm"
            tooltipText={`${optionKeyString} + click`}
            onClick={() => {
              openModelInTab({ modelId: props.model.id });
            }}
          />
        </>
        {props.model.publishedQuery && (
          <MenuItem
            text="Export to Google Sheets"
            size="sm"
            onClick={() => openRevELTSidebar(props.model, "google-sheets")}
          />
        )}

        <>
          {props.model.publishedQuery && (
            <MenuItem
              text="Move / rename model"
              size="sm"
              disabled={integration?.restrictMovingModels}
              onClick={() => {
                !integration?.restrictMovingModels && setUpdateModalOpen(true);
              }}
            />
          )}
          {!props.model.publishedQuery && (
            <MenuItem
              text="Rename model"
              size="sm"
              onClick={() => {
                setUpdateDraftModalOpen(true);
              }}
            />
          )}
          {props.model.publishedQuery && (
            <MenuItem
              text="Unpublish model"
              size="sm"
              onClick={() => {
                handleUnpublishModel(props.model);
              }}
            />
          )}
        </>
        <MenuItem
          text="Delete model"
          size="sm"
          onClick={() => {
            setShowConfirmDeleteModal(true);
          }}
        />
      </TableMenu>

      <ConfirmDeleteModal
        title="model"
        onConfirm={async () => {
          await removeModel({ variables: { modelId: props.model.id } });
        }}
        show={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
      />
      <SaveModelModal
        key={`move-${props.model.id}`}
        show={updateModalOpen}
        onCancel={() => {
          setUpdateModalOpen(false);
        }}
        onSave={handleMoveModel}
        defaultFolder={props.model.folder ?? undefined}
        loading={loadingMoveModel}
        mode="move"
        defaultName={props.model.name}
      />
      <SaveModelModal
        key={`edit-name-${props.model.id}`}
        show={updateDraftModalOpen}
        onCancel={() => {
          setUpdateDraftModalOpen(false);
        }}
        onSave={handleUpdateDraft}
        loading={loadingDraftUpdate}
        mode="edit_draft_name"
        defaultName={props.model.name}
      />
    </>
  );
};

const useDeleteModel = (isSelected: boolean) => {
  const toast = useToast();
  const navigate = useNavigateWithSlug();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
    useSafeState(false);

  const closeRemovedTab = useCloseRemovedTab();

  const [removeModel, { loading: removing }] = useRemoveModelMutation({
    refetchQueries: [HasDeletedModelsDocument],
    onError(error) {
      toast(`Error removing model`, error.message, "error");
    },
    onCompleted({ removeModel: { id } }) {
      toast(`Model removed`, `The model was successfully removed`, "success");
      closeRemovedTab("model", id);
      if (isSelected) {
        navigate({ to: "/editor" });
      }
    },
    update(cache, { data }) {
      if (!data) return;
      const { removeModel } = data;
      const normalizedId = cache.identify(removeModel);
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  return {
    removeModel,
    showConfirmDeleteModal,
    setShowConfirmDeleteModal,
    removing,
  };
};

export const useMoveModel = (model?: ListModelsQuery["models"][0]) => {
  const toast = useToast();
  const { dataWarehouseConnectionId } = useDatawarehouseInfo();

  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [moveModel, { loading }] = useMoveModelMutation({
    onError(error) {
      toast(`Model not updated`, error.message, "error");
      setUpdateModalOpen(false);
    },
    onCompleted() {
      toast(`Model updated`, `The model was succesfully updated`, "success");
      setUpdateModalOpen(false);
    },
    awaitRefetchQueries: true,
  });
  const handleMoveModel = useCallback(
    async (name: string, folderId?: string) => {
      if (!model) return;
      if (!folderId) {
        toast("Error moving model", "You must select a folder", "error");
        return;
      }
      if (folderId === model.folder?.id && name === model.name) {
        setUpdateModalOpen(false);
        return;
      }
      if (!dataWarehouseConnectionId || loading) return;

      await moveModel({
        variables: {
          id: model.id,
          modelFolderId: folderId,
          name,
        },
        refetchQueries: ["FindQueryHistory"],
      });
    },
    [model, dataWarehouseConnectionId, loading, moveModel, toast],
  );

  return { handleMoveModel, loading, updateModalOpen, setUpdateModalOpen };
};

const useUpdateDraft = (model: ListModelsQuery["models"][0]) => {
  const toast = useToast();
  const { dataWarehouseConnectionId } = useDatawarehouseInfo();

  const [updateDraftModalOpen, setUpdateDraftModalOpen] = useState(false);
  const [updateDraft, { loading }] = useUpdateDraftModelNameMutation({
    onError(error) {
      toast(`Model not updated`, error.message, "error");
      setUpdateDraftModalOpen(false);
    },
    onCompleted() {
      toast(`Model updated`, `The model was succesfully updated`, "success");
      setUpdateDraftModalOpen(false);
    },
  });
  const handleUpdateDraft = useCallback(
    async (name: string) => {
      if (!dataWarehouseConnectionId || loading) return;

      await updateDraft({
        variables: {
          id: model.id,
          name,
        },
      });
    },
    [dataWarehouseConnectionId, loading, model.id, updateDraft],
  );

  return {
    handleUpdateDraft,
    loading,
    updateDraftModalOpen,
    setUpdateDraftModalOpen,
  };
};

const useDatawarehouseInfo = () => {
  const dw = useDataWarehouse();
  const integration = useIntegration(dw?.integrationId);
  const dataWarehouseConnectionId = dw?.connectionId;
  return { dataWarehouseConnectionId, integration };
};
