import { Auth } from "@firebase/auth";
import { Database } from "@firebase/database";
import React from "react";

const FirebaseAuthContext = React.createContext<Auth | undefined>(undefined);
const FirebaseDatabaseContext = React.createContext<Database | undefined>(
  undefined,
);

type FirebaseSdk = Auth | Database;

function createSdkProvider<Sdk extends FirebaseSdk>(
  SdkContext: React.Context<Sdk | undefined>,
) {
  return function SdkProvider(props: React.PropsWithChildren<{ sdk: Sdk }>) {
    return <SdkContext.Provider value={props.sdk} {...props} />;
  };
}

function useSdk<Sdk extends FirebaseSdk>(
  SdkContext: React.Context<Sdk | undefined>,
): Sdk {
  const sdk = React.useContext(SdkContext);
  if (!sdk) {
    throw new Error(
      "SDK not found. useSdk must be called from within a provider",
    );
  }
  return sdk;
}

export const FirebaseAuthProvider = createSdkProvider(FirebaseAuthContext);
export const FirebaseDatabaseProvider = createSdkProvider(
  FirebaseDatabaseContext,
);

export const useFirebaseAuth = () => useSdk(FirebaseAuthContext);
export const useFirebaseDatabase = () => useSdk(FirebaseDatabaseContext);
