import { useGetCustomFormValuesQuery } from "@/apollo/types";
import { SecondaryButton } from "@/components/elements/Button";
import Select from "@/components/elements/Select";
import FieldLabel from "@/components/primitives/InputLabel";
import produce from "immer";
import { FormType } from "@/integrations";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";

import { dimensionLabelOverrides } from "./google-analytics-4.constants";
import GoogleAnalytics4CustomReport from "./GoogleAnalytics4CustomReport";

export type GoogleAnalytics4ReportOption = {
  label: string;
  value: string;
};

export type GoogleAnalytics4Report = {
  name: string;
  id: string;
  metrics: GoogleAnalytics4ReportOption[];
  dimensions: GoogleAnalytics4ReportOption[];
};

const useCustomFormFieldsQuery = (
  propertyId: string | undefined,
  connectionId: string,
) => {
  const { data, loading, refetch } = useGetCustomFormValuesQuery({
    variables: {
      input: {
        connectionId: connectionId,
        dependencies: JSON.stringify({
          propertyId,
        }),
      },
    },
    onError(error) {
      return [];
    },
    skip: !propertyId,
  });
  return { data: data?.getCustomFormValues.customFormValues, loading, refetch };
};

export const stringToOption = (input: string) => {
  if (dimensionLabelOverrides[input])
    return { label: dimensionLabelOverrides[input], value: input };
  return { label: input, value: input };
};

export default function GoogleAnalytics4Form(props: {
  field: Extract<FormType, { type: "google-analytics-4-form" }>;
}) {
  const context = useFormContext();
  const [property, setProperty] = useState<
    { label: string; value: string } | undefined
  >(context.getValues()["property"]);

  const prevPropertyRef = useRef<{ label: string; value: string } | undefined>(
    context.getValues()["property"],
  );
  const accounts = props.field.accounts;

  const { data: customFieldsData } = useCustomFormFieldsQuery(
    property?.value,
    context.getValues()["connectionId"],
  );

  const [reportErrors, setReportErrors] = useState<Record<string, string>>({});
  const [invalidCombinationReportIds, setInvalidCombinationReportIds] =
    useState<string[]>([]);

  const dimensionOptions: any[] = customFieldsData?.dimensions || [];
  const metricOptions: any[] = customFieldsData?.metrics || [];

  //const profilesFilter = (profile: any) => !!profile.name && !!profile.id;

  const propertyOptions: any[] = useMemo(() => {
    return accounts.reduce<any[]>((acc, account) => {
      acc.push({
        label: `${account.displayName} (${account.account})`,
        value: "",
        disabled: true,
      });
      account.propertySummaries.forEach((property) => {
        acc.push({
          label: `${property.displayName} (${property.property})`,
          value: property.property,
          disabled: false,
          indent: 1,
        });
      });
      return acc;
    }, []);
  }, [accounts]);

  useEffect(() => {
    context.register("ga4-custom-reports", { required: false });
    context.register("property", { required: true });
  }, [context]);

  const getInitialReports = () => {
    const values = context.getValues()["ga4-custom-reports"];
    if (values === "NO_REPORTS") return [];
    return values?.filter((v: any) => !!v) ?? [];
  };
  const [reports, setReports] =
    useState<GoogleAnalytics4Report[]>(getInitialReports());

  const changeReport = (report: GoogleAnalytics4Report) => {
    setReports((prev) =>
      produce(prev, (draft) => {
        const cur = draft.find((rep) => rep.id === report.id);
        if (cur) {
          cur.name = report.name;
          cur.dimensions = report.dimensions;
          cur.metrics = report.metrics;
        }
      }),
    );
  };

  const removeReport = (report: GoogleAnalytics4Report) => {
    setReports((prev) =>
      produce(prev, (draft) => {
        draft = draft.filter((rep) => rep.id !== report.id);
        return draft;
      }),
    );
  };

  const onReportInvalid = useCallback((reportId: string) => {
    setInvalidCombinationReportIds((prev) => [...prev, reportId]);
  }, []);

  const onReportValid = useCallback((reportId: string) => {
    setInvalidCombinationReportIds((prev) =>
      prev.filter((x) => x !== reportId),
    );
  }, []);

  const setValue = context.setValue;
  const setError = context.setError;

  useEffect(() => {
    if (reports.length === 0) {
      setValue("ga4-custom-reports", [], {
        shouldValidate: false,
      });
    }
  }, [reports.length, setValue]);

  useEffect(() => {
    //If there is any report where the combination is invalid, set report and don't evaluate further
    if (invalidCombinationReportIds.length > 0) {
      setError("ga4-custom-reports", {
        message: "Validation error",
      });
      return;
    }

    //Only evaluate when all reports are valid
    const getReports = () => {
      if (!reports.length) return [];
      return reports.every(
        (r) => !!r.name && !!r.dimensions.length && !!r.metrics.length,
      )
        ? reports
        : undefined;
    };

    const currentReports = getReports();

    if (currentReports && Array.isArray(currentReports)) {
      //Check if all report names are unique
      const duplicateReportNames = currentReports.filter(
        (x) => currentReports.filter((y) => y.name === x.name).length > 1,
      );

      const _reportErrors: Record<string, string> = {};
      for (const duplicateReport of duplicateReportNames) {
        _reportErrors[duplicateReport.id] = "Report names must be unique";
      }

      setReportErrors(_reportErrors);

      if (duplicateReportNames.length > 0) {
        setError("ga4-custom-reports", {
          message: "Validation error",
        });
      } else {
        setValue("ga4-custom-reports", currentReports, {
          shouldValidate: true,
        });
      }
    }
  }, [reports, setValue, setError, invalidCombinationReportIds.length]);

  useEffect(() => {
    setValue("property", property, {
      shouldValidate: true,
    });

    //If the previously selected property wasn't the same, clear reports
    if (prevPropertyRef.current?.value !== property?.value) {
      prevPropertyRef.current = property;
      setReports([]);
    }
  }, [property, setValue]);

  return (
    <div className="space-y-8">
      <div>
        <FieldLabel id="name" required={true}>
          App / Website
        </FieldLabel>
        <Select
          isMulti={false}
          isLoading={false}
          placeholder="Select one"
          onChange={(v: any) => setProperty(v)}
          options={propertyOptions}
          value={property}
          hideSelectedOptions={false}
        />
      </div>
      {!!reports.length && (
        <div className="text-sm">Custom reports ({reports.length}/20)</div>
      )}
      {reports.map((r) => {
        return (
          <GoogleAnalytics4CustomReport
            report={r}
            key={r.id}
            onChangeReport={changeReport}
            onRemoveReport={removeReport}
            initialDimensionOptions={dimensionOptions}
            initialMetricOptions={metricOptions}
            validationError={reportErrors[r.id]}
            connectionId={context.getValues()["connectionId"]}
            propertyId={property?.value}
            invalidCombination={invalidCombinationReportIds.includes(r.id)}
            onReportInvalid={onReportInvalid}
            onReportValid={onReportValid}
          />
        );
      })}
      <SecondaryButton
        onClick={() =>
          setReports((prev) => [
            ...prev,
            { id: uuid(), metrics: [], dimensions: [], name: "" },
          ])
        }
      >
        Add custom report
      </SecondaryButton>
    </div>
  );
}
export const toValue = (option: any) => option.value;
export const AnalyticsReport = [
  "adwords_campaigns",
  "adwords_hourly_stats",
  "adwords_keyword",
  "audience_overview",
  "browser_and_operating_system_overview",
  "campaign_performance",
  "channel_traffic",
  "events_overview",
  "social_media_acquisitions",
  "traffic",
] as const;
export type AnalyticsReportType = (typeof AnalyticsReport)[number];

export const isAnalyticsReportType = (s: string): s is AnalyticsReportType =>
  AnalyticsReport.includes(s as AnalyticsReportType);
