import { DocumentTextIcon } from "@heroicons/react/24/outline";
import {
  MaterializationType,
  OrchestrationSchedulerType,
} from "@/apollo/types";
import { LoadingFull } from "@/components/elements/LoadingComponents";
import { InformationIconTooltip } from "@/components/elements/Tooltip";
import { LocalScheduleIcon } from "@/components/icons/OrchestrateIcon";
import TableIcon from "@/components/icons/TableIcon";
import {
  SchedulerSelecter,
  useSchedulerOptions,
} from "@/components/modules/SchedulerSelecter";
import { AdvancedSyncScheduler } from "@/components/modules/WeldSyncScheduler";
import FieldLabel from "@/components/primitives/InputLabel";
import { useOrchestrationFeatureGuard } from "@/features/feature-guards";
import { isEqual } from "lodash";
import { useCurrentAccount } from "@/providers/account";
import { useToast } from "@/providers/ToastProvider";
import { useCallback, useMemo } from "react";

import { SelectorButton } from "../../../components/elements/SelectorButton";
import { useCurrentModel } from "../hooks/useCurrentModel";
import {
  useModelEditorDispatch,
  useModelEditorState,
} from "../ModelEditorStore";
import { useQueryDependencies } from "../useQueryDependencies";
import { OrchestrationInfo } from "./OrchestrationInfo";
import { TableMaterilizationInfo } from "./TableMaterilizationInfo";
import ViewInfo from "./ViewInfo";

type Props = {};

const MaterializationSetting = (props: Props) => {
  const model = useCurrentModel();

  return (
    <div className="relative h-full overflow-auto bg-gray-50 dark:border-gray-700 dark:bg-gray-800">
      {!model && <LoadingFull />}

      {model?.publishedQuery &&
        model?.materializationType === MaterializationType.Table && (
          <TableMaterilizationInfo
            model={model}
            modelId={model.id}
            workflowId={model.orchestrationWorkflow?.id}
            tableReference={model.dwTable ?? undefined}
          />
        )}
      {model?.publishedQuery &&
        model?.materializationType === MaterializationType.View && (
          <ViewInfo
            model={model}
            workflowId={model.orchestrationWorkflow?.id}
          />
        )}
    </div>
  );
};

export default MaterializationSetting;

export const ConfigureMaterializationType = () => {
  const state = useModelEditorState();

  return (
    <div className="mx-auto max-w-5xl space-y-8">
      <div className="flex flex-col">
        <FieldLabel>Publish model as</FieldLabel>
        <MaterializationTypeSelector />
      </div>
      {state.materializationType === MaterializationType.Table && (
        <OrchestrationSchedulerSelector />
      )}
    </div>
  );
};

const MaterializationTypeSelector: React.FC<{}> = () => {
  const account = useCurrentAccount();
  const state = useModelEditorState();
  const dispatch = useModelEditorDispatch();
  const handleHandleUpdateType = (materializationType: MaterializationType) => {
    dispatch({
      type: "materialization_type_changed",
      payload: {
        materializationType,
      },
    });
  };
  const toast = useToast();
  return (
    <div className="flex space-x-4">
      <div className="flex-1">
        <SelectorButton
          headline="View"
          description="Publish this model as a view"
          icon={<DocumentTextIcon className="h-6 w-6" />}
          selected={state.materializationType === MaterializationType.View}
          onClick={() => {
            handleHandleUpdateType(MaterializationType.View);
          }}
          className="w-full"
        />
      </div>
      <div
        className="flex-1"
        onClick={
          account.accountSettings.deactivateELTSyncs
            ? () => {
                toast(
                  "Table materialization is currently deactivated for your account.",
                  "Please book a call with us to activate it.",
                  "warning",
                );
              }
            : undefined
        }
      >
        <SelectorButton
          headline="Table"
          description="Publish this model as a table"
          icon={<TableIcon className="h-6 w-6" />}
          selected={state.materializationType === MaterializationType.Table}
          onClick={() => {
            handleHandleUpdateType(MaterializationType.Table);
          }}
          className="w-full"
          disabled={account.accountSettings.deactivateELTSyncs}
        />
      </div>
    </div>
  );
};

type OrchestrationConfig = {
  orchestrationScheduler?: OrchestrationSchedulerType | undefined;
  orchestrationWorkflowId?: string | undefined;
};

const OrchestrationSchedulerSelector = () => {
  const { isEnabled: isOrchestrationEnabled } = useOrchestrationFeatureGuard();

  const state = useModelEditorState();
  const dispatch = useModelEditorDispatch();
  const dependencies = useQueryDependencies();

  const scheduleOptions = useSchedulerOptions({
    allowNoSchedule: true,
  });

  const selectedOption = useMemo(() => {
    const config: OrchestrationConfig = {
      orchestrationScheduler: state.orchestrationScheduler,
      orchestrationWorkflowId: state.orchestrationWorkflowId,
    };
    return scheduleOptions.find((o) => isEqual(o.value, config));
  }, [
    scheduleOptions,
    state.orchestrationScheduler,
    state.orchestrationWorkflowId,
  ]);

  const isAddingWorkflow = selectedOption?.id === "new-workflow";

  const handleUpdateScheduler = (updated: OrchestrationConfig) => {
    dispatch({
      type: "orchestration_scheduler_changed",
      payload: updated,
    });
  };

  const handleUpdateLocalCron = useCallback(
    (cron?: string) => {
      dispatch({
        type: "materialization_schedule_cron_changed",
        payload: cron,
      });
    },
    [dispatch],
  );

  return (
    <>
      {isOrchestrationEnabled && (
        <div className="flex flex-col space-y-2">
          <SchedulerSelecter
            configuration={{ allowNoSchedule: true }}
            onUpdate={handleUpdateScheduler}
            orchestrationScheduler={state.orchestrationScheduler}
            orchestrationWorkflowId={state.orchestrationWorkflowId}
            onUpdateNewWorkflowName={(name) => {
              dispatch({
                type: "set_new_workflow_name",
                payload: name,
              });
            }}
            onUpdateNewWorkflowCron={(cron) => {
              if (!cron) return;
              dispatch({
                type: "set_new_workflow_cron",
                payload: cron,
              });
            }}
          />
        </div>
      )}

      {!isOrchestrationEnabled && (
        <div className="flex flex-col space-y-2">
          <FieldLabel>
            <div className="flex items-center space-x-2">
              <span>Select scheduler</span>
              <InformationIconTooltip content="The selected scheduler can always be changed later." />
            </div>
          </FieldLabel>
          <div className="flex space-x-2">
            <SelectorButton
              headline="Scheduled"
              description="Recreate table on an independent schedule. This allows the model to be updated on a customized schedule, without dependending on other models."
              icon={<LocalScheduleIcon className="h-6 w-6" />}
              selected={
                state.orchestrationScheduler ===
                OrchestrationSchedulerType.Local
              }
              onClick={() => {
                handleUpdateScheduler({
                  orchestrationScheduler: OrchestrationSchedulerType.Local,
                });
              }}
              className="flex-1"
            />
            <SelectorButton
              headline="Not scheduled"
              description="Create table once, and don't update it afterwards."
              icon={<div className="h-6 w-6" />}
              selected={!state.orchestrationScheduler}
              onClick={() => {
                handleUpdateScheduler({ orchestrationScheduler: undefined });
              }}
              className="flex-1"
            />
          </div>
        </div>
      )}
      {state.orchestrationScheduler === OrchestrationSchedulerType.Local && (
        <div>
          <FieldLabel>Schedule</FieldLabel>
          <AdvancedSyncScheduler
            cron={state.materializationSchedule}
            onChange={handleUpdateLocalCron}
          />
        </div>
      )}
      {state.orchestrationScheduler === OrchestrationSchedulerType.Global &&
        state.orchestrationWorkflowId &&
        !isAddingWorkflow && (
          <OrchestrationInfo
            workflowId={state.orchestrationWorkflowId}
            modelId={state.currentModelId ?? ""}
            dependencies={dependencies}
          />
        )}
    </>
  );
};
