import { useMemo } from "react";

import { Notification, NotificationType } from "@/apollo/types";
import { IntegrationLogoBox } from "@/integrations";

export const NotificationIntegrationIcon = (
  props: { notification: Notification } & Omit<
    React.ComponentProps<typeof IntegrationLogoBox>,
    "id"
  >,
) => {
  const integrationId = useMemo(() => {
    switch (props.notification.type) {
      case NotificationType.ActivateQuarantine:
        return props.notification.detail.destinationIntegrationId;
      case NotificationType.GoogleSheetsBadNamedRange:
      case NotificationType.AirtableTableNotFound:
      case NotificationType.ApiNotEnoughPermissions:
      case NotificationType.GoogleInvalidGrant:
      case NotificationType.Quarantine:
      case NotificationType.ConnectorBadOauth:
      case NotificationType.PostgresFailedConnection:
      case NotificationType.PostgresDatabaseInsufficientPrivilege:
      case NotificationType.PostgresTableInsufficientPrivilege:
      case NotificationType.ConnectorBadApiKey:
      case NotificationType.ChargebeeApiRestricted:
      case NotificationType.DatabaseTableNotFound:
        return props.notification.detail.sourceIntegrationId;
      default:
        return undefined;
    }
  }, [
    props.notification.detail.destinationIntegrationId,
    props.notification.detail.sourceIntegrationId,
    props.notification.type,
  ]);

  return <IntegrationLogoBox id={integrationId} {...props} />;
};
