const stepsMap: Record<string, string> = {
  "2457730": "First Data Source available",
  "2469077": "Available table(s)",
  "2469078": "Query data",
};

const guide = {
  id: "ORcczqtzcx",
  name: "First ELT sync",
  stepsMap,
};

export default guide;
