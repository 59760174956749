import { merge } from "lodash";
import { identity } from "lodash";
import { LabelProps } from "recharts";

import { ChartThemeDef } from ".";

export type FirstLayerPartial<T> = {
  [P in keyof T]?: T[P] extends object ? Partial<T[P]> : T[P];
};

export const mergeThemes: (
  base: ChartThemeDef,
  overwrites: FirstLayerPartial<ChartThemeDef>,
) => ChartThemeDef = (base, overwrites) =>
  merge<{}, ChartThemeDef, FirstLayerPartial<ChartThemeDef>>(
    {},
    base,
    overwrites,
  );

const base: ChartThemeDef = {
  BarChart: {
    margin: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  LineChart: {
    margin: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  CartesianGrid: {
    strokeDasharray: "3 3",
  },
  YAxis: {
    tickLine: false,
    interval: 0,
  },
  XAxis: {
    tickLine: false,
  },
  Tooltip: {},
  Bar: {
    getLabelProps: identity,
  },
  BarSecondary: {
    getLabelProps: identity,
  },
  Line: {
    getLabelProps: identity,
    dot: false,
  },
  Area: {
    dot: false,
  },
  text: "#000",
};

export const baseThemeLight: ChartThemeDef = mergeThemes(base, {
  BarChart: {},
  CartesianGrid: {
    stroke: "rgba(212 214 218)", // gray-300
  },
  YAxis: {
    stroke: "rgba(212 214 218)", // gray-300
  },
  XAxis: {
    stroke: "rgba(212 214 218)", // gray-300
  },
  Tooltip: {
    cursor: {
      fill: "rgb(0 0 0 / 0.05)",
    },
  },
  Bar: {
    fill: "rgba(50 140 246 / 0.8)", // blue-400
    getLabelProps: (props: LabelProps) => ({
      fill: "rgb(109 113 122)", // gray-500
      fontSize: "0.8em",
      position: "right",
      ...props,
    }),
  },
  BarSecondary: {
    fill: "rgb(251 146 60 / 0.8)", // orange-400
    getLabelProps: (props: LabelProps) => ({
      fill: "rgb(109 113 122)", // gray-500
      fontSize: "0.8em",
      position: "right",
      ...props,
    }),
  },
  Area: {
    fill: "rgba(96 165 250 / 0.8)", // blue-400
    stroke: "rgba(59 130 246 / 1)", // blue-500
    strokeWidth: 2,
  },
  Line: {
    stroke: "rgba(59 130 246 / 1)", // blue-500
    strokeWidth: 2,
  },
  text: "rgb(38 38 39)", // gray-800
});

export const baseThemeDark: ChartThemeDef = mergeThemes(base, {
  CartesianGrid: {
    stroke: "rgba(81 88 98)", // gray-600
  },
  YAxis: {
    stroke: "rgba(81 88 98)",
  },
  XAxis: {
    stroke: "rgba(81 88 98)",
  },
  Tooltip: {
    cursor: {
      fill: "rgb(255 255 255 / 0.05)",
    },
  },
  Bar: {
    fill: "rgba(59 130 246 / 0.6)", // blue-500
    getLabelProps: (props: LabelProps) => ({
      fill: "rgb(159 163 171)", // gray-400
      fontSize: "0.8em",
      position: "right",
      ...props,
    }),
  },
  BarSecondary: {
    fill: "rgb(251 146 60 / 0.8)", // orange-400
    getLabelProps: (props: LabelProps) => ({
      fill: "rgb(109 113 122)", // gray-500
      fontSize: "0.8em",
      position: "right",
      ...props,
    }),
  },
  Area: {
    fill: "rgba(96 165 250 / 0.6)", // blue-400
    stroke: "rgba(59 130 246 / 1)",
    strokeWidth: 2,
  },
  Line: {
    stroke: "rgba(59 130 246 / 1)",
    strokeWidth: 2,
  },
  text: "white",
});

const baseTheme = {
  light: baseThemeLight,
  dark: baseThemeDark,
};

export default baseTheme;
