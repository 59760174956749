import { PauseIcon, PlayIcon } from "@heroicons/react/24/outline";
import { Button, ButtonProps } from "@/components/elements/Button";
import { forwardRef } from "react";

type SyncButtonProps = Pick<
  ButtonProps,
  "isLoading" | "isFullWidth" | "isDisabled" | "size"
> & {
  isRunning: boolean;
  actionName?: string;
};

export const SyncButton = forwardRef<
  HTMLButtonElement,
  SyncButtonProps & React.ComponentPropsWithoutRef<"button">
>(({ isRunning, actionName, ...restProps }, ref) => {
  return (
    <Button
      ref={ref}
      variant="solid"
      colorScheme={isRunning ? "danger" : "success"}
      icon={isRunning ? <PauseIcon /> : <PlayIcon />}
      loadingText={isRunning ? "Stopping" : "Starting"}
      {...restProps}
    >
      {isRunning
        ? `Stop ${actionName || "Sync"}`
        : `Start ${actionName || "Sync"}`}
    </Button>
  );
});
