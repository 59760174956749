import { Portal, Transition } from "@headlessui/react";
import { useMemo } from "react";

import { usePreviewData } from "../ModelVisualizePanel/usePreviewData";
import { Tile } from "../VisualizationType";
import { TileVisualization, useTileData } from "../Visualize";

export const PreviewEditedtileVisualization = (props: {
  tile: Tile;
  show: boolean;
  usePreviewData?: boolean;
}) => {
  const skipTileData = props.usePreviewData ?? false;
  const {
    data: tileData,
    error: tileError,
    loading: tileDataLoading,
  } = useTileData(props.tile, skipTileData);

  const {
    data: previewData,
    loading: previewLoading,
    error: previewError,
  } = usePreviewData(props.tile.modelId ?? "");

  const { data, loading, error } = useMemo(() => {
    return {
      data: props.usePreviewData ? previewData : tileData,
      loading: props.usePreviewData ? previewLoading : tileDataLoading,
      error: props.usePreviewData ? previewError : tileError?.message,
    };
  }, [
    previewData,
    previewError,
    previewLoading,
    props.usePreviewData,
    tileData,
    tileDataLoading,
    tileError?.message,
  ]);

  return (
    <Portal>
      <div
        className="fixed inset-0 flex items-center justify-center p-4"
        style={{ right: "36rem" }} //to
      >
        <Transition
          as={"div"}
          show={props.show}
          appear
          className="relative z-50 flex h-full w-full max-w-2xl flex-col rounded  border bg-white dark:border-gray-700 dark:bg-gray-800"
          style={{ maxHeight: "32rem" }}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="text-md flex-shrink-0 px-8 pt-8 font-medium text-gray-800 dark:text-gray-100">
            {props.tile.name || "unnamed tile"}
          </div>
          <div className="relative flex-grow overflow-auto">
            <TileVisualization
              loading={loading}
              visualization={props.tile.visualization}
              data={data ?? []}
              error={error}
            />
          </div>
        </Transition>
      </div>
    </Portal>
  );
};
