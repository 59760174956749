import "./highlight-weld-tags.css";

import { QueryDependencyInput } from "@/apollo/types";
import classNames from "@/helpers/classNames";
import { useIsModKeyPressed } from "@/hooks/useKeyPress";
import { useMountEffect } from "@/hooks/useMountEffect";
import { debounce } from "lodash";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { useRawViewsMap } from "@/pages/ModelTool/useRawViews";
import { useEffect, useRef } from "react";

import {
  extractWeldTags,
  useGetQueryDependencies,
} from "../../../useQueryDependencies";
import { getAllWeldTagsByName } from "../getWeldTagAtPosition";

export function useHighlightWeldTags(
  editor: monaco.editor.IStandaloneCodeEditor | undefined,
) {
  const decorationIdentifiers = useRef<string[]>([]);
  const getQueryDependencies = useGetQueryDependencies();
  const { rawViews } = useRawViewsMap();
  const isCmdKeyPressed = useIsModKeyPressed();

  useMountEffect(async () => {
    try {
      const { generateIntegrationCSS } = await import(
        "./generate-integration-css"
      );
      generateIntegrationCSS();
    } catch {}
  });

  useEffect(() => {
    if (!editor) return;

    function createRawViewDecorationOptions(dependency: QueryDependencyInput) {
      const rawView = rawViews.get(dependency.dwItemId);
      const hasSync = rawView?.syncId != null;
      return {
        isWholeLine: false,
        beforeContentClassName: hasSync
          ? classNames(
              "weld-tag__raw--before-content",
              rawView && `ii-${rawView.integrationId}`,
            )
          : undefined,
        className: "weld-tag weld-tag__raw",
        inlineClassName: "weld-tag-inline weld-tag-inline__raw",
      };
    }

    function createModelDecorationOptions() {
      return {
        isWholeLine: false,
        className: "weld-tag weld-tag__model",
        inlineClassName: classNames(
          "weld-tag-inline weld-tag-inline__model",
          isCmdKeyPressed && "weld-tag-inline__model--active",
        ),
      };
    }

    function applyHighlights() {
      if (!editor) return;
      const model = editor.getModel();
      if (!model) return;

      let newDecorations: monaco.editor.IModelDeltaDecoration[] = [];

      const currentWeldTags = extractWeldTags(model.getValue());
      const modelDependencies = getQueryDependencies(currentWeldTags);
      modelDependencies.forEach((dependency) => {
        if (dependency.dwItemId !== "@missing") {
          const tags = getAllWeldTagsByName(dependency.weldTag, model);
          const isRawView = dependency.weldTag.startsWith("raw.");
          newDecorations = newDecorations.concat(
            tags.map((tag) => {
              return {
                range: tag.range,
                options: isRawView
                  ? createRawViewDecorationOptions(dependency)
                  : createModelDecorationOptions(),
              };
            }),
          );
        }
      });
      // Replace existing decorations with the new decorations and save the new identifiers
      decorationIdentifiers.current = editor.deltaDecorations(
        decorationIdentifiers.current,
        newDecorations,
      );
    }

    applyHighlights();
    const debouncedApplyHighlights = debounce(applyHighlights, 150);

    const disposable = editor.onDidChangeModelContent(() => {
      debouncedApplyHighlights();
    });

    const currentDecorationIdentifiers = decorationIdentifiers.current;
    return () => {
      editor.deltaDecorations(currentDecorationIdentifiers, []);
      disposable.dispose();
    };
  }, [editor, getQueryDependencies, isCmdKeyPressed, rawViews]);
}
