export const RedOneEye1 = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 50 50"
    >
      <circle cx="25" cy="25" r="25" fill="#FDBFB7"></circle>
      <mask
        id="mask0_1737_12739"
        style={{ maskType: "alpha" }}
        width="50"
        height="50"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect width="50" height="50" fill="#C4C4C4" rx="2"></rect>
      </mask>
      <g mask="url(#mask0_1737_12739)">
        <ellipse
          cx="27.586"
          cy="27.585"
          fill="#C94C3D"
          rx="51.724"
          ry="41.379"
        ></ellipse>
        <path
          fill="#FC604D"
          d="M56.636 55.475a1.013 1.013 0 01-1.233 1.231 87.334 87.334 0 01-62.81-62.81 1.013 1.013 0 011.23-1.234 87.087 87.087 0 0162.813 62.813z"
        ></path>
        <ellipse
          fill="#fff"
          rx="10.761"
          ry="10.761"
          transform="rotate(-44.606 42.46 -17.717) skewX(.058)"
        ></ellipse>
        <ellipse
          fill="#32130F"
          rx="5.566"
          ry="5.566"
          transform="rotate(-44.606 42.461 -17.716) skewX(.058)"
        ></ellipse>
        <path
          fill="#FC604D"
          d="M55.475-7.338a1.013 1.013 0 011.231 1.234 87.335 87.335 0 01-62.81 62.81 1.013 1.013 0 01-1.234-1.231A87.087 87.087 0 0155.475-7.338z"
        ></path>
        <ellipse
          fill="#fff"
          rx="10.761"
          ry="10.761"
          transform="rotate(-134.606 17.507 7.145) skewX(.058)"
        ></ellipse>
        <ellipse
          fill="#32130F"
          rx="5.566"
          ry="5.566"
          transform="rotate(-134.606 17.507 7.145) skewX(.058)"
        ></ellipse>
      </g>
    </svg>
  );
};
