import { useSourcePropertiesQuery } from "@/apollo/types";
import { keyBy } from "lodash";
import { useMemo } from "react";

import { useSyncContext } from "../modules/SyncContext";

export type SourceProperty = {
  propertyId: string;
  propertyName: string;
  propertyType: string;
};

export default function useSourceProperties() {
  const [state] = useSyncContext();

  const { data, loading: sourcePropertiesLoading } = useSourcePropertiesQuery({
    variables: {
      modelId: state.queryId,
    },
    skip: !state.queryId,
  });

  return useMemo(() => {
    const dataSchema = (
      data?.sourceProperties.dataSchema ?? []
    ).map<SourceProperty>((x) => ({
      propertyId: x.name,
      propertyName: x.name,
      propertyType: x.type,
    }));
    return {
      sourceProperties: dataSchema,
      sourcePropertiesLoading,
      sourcePropertiesObject: keyBy(dataSchema, "propertyId"),
    };
  }, [data, sourcePropertiesLoading]);
}
