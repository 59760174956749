import { useEltSyncsQuery } from "@/apollo/types";
import EltSyncsTable from "@/pages/EltSyncs/modules/EltSyncsTable";

import MainTabsLayout from "./MainTabsLayout";
import { EltSyncsEmptyState } from "./modules/EltSyncsEmptyState";

export default function EltSyncsPage() {
  const { data, loading } = useEltSyncsQuery();

  if (loading) return <div>Loading...</div>;
  return (
    <MainTabsLayout>
      {data?.eltSyncs.length === 0 ? (
        <EltSyncsEmptyState />
      ) : (
        <EltSyncsTable syncs={data?.eltSyncs ?? []} />
      )}
    </MainTabsLayout>
  );
}
