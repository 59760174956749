import React, { createContext, useCallback, useContext } from "react";
import { useState } from "react";

import AppearTransition from "@/components/elements/AppearTransition";
import Center from "@/components/elements/layout/Center";
import { Overlay } from "@/components/elements/layout/Overlay";
import { useToast } from "@/providers/ToastProvider";
import { connectDWRoute } from "@/router";
import { useNavigate } from "@tanstack/react-location";

import LoadingScreenQuestionnaire from "../components/LoadingScreenQuestionnaire";
import { WELD_BIGQUERY_ID } from "../constants";
import { useCreateWorkspaceWithManagedDataWarehouse } from "../hooks/useConnectManagedDW";
import { useCreateWorkspaceContext } from "./CreateWorkspaceContext";

const ManagedDataWarehouseContext = createContext<
  | ((options: {
      workspaceName: string;
      dataWarehouseLocation: string;
      isTest?: boolean;
    }) => Promise<void>)
  | null
>(null);

export function useManagedDataWarehouseContext() {
  const ctx = useContext(ManagedDataWarehouseContext);
  if (ctx === null) {
    throw new Error(
      "useManagedDataWarehouseContext must be used within a ManagedDataWarehouseContext",
    );
  }
  return ctx;
}

export function ManagedDataWarehouseProvider(props: {
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoadingQuestionnaireOngoing, setLoadingQuestionnaireOngoing] =
    useState(false);

  const createWorkspaceContext = useCreateWorkspaceContext();

  const navigateOnSetupComplete = (accountId?: string) => {
    navigate({
      to: "/setup/connectors",
      search: {
        accountId,
      },
    });
  };

  const [mutation, { isConnected, isConnecting, data }] =
    useCreateWorkspaceWithManagedDataWarehouse({
      onAccountCreated(account) {
        createWorkspaceContext.onAccountCreated(account);
      },
      onCompleted(account) {
        if (!isLoadingQuestionnaireOngoing) {
          navigateOnSetupComplete(account.id);
        }
      },
      onError(error, account) {
        if (account == null) {
          toast(
            "Account creation failed, please try again.",
            error.message,
            "error",
          );
        } else {
          toast(
            "We were unable to connect to the data warehouse",
            error.message,
            "error",
          );
          navigate({
            to: connectDWRoute(account.slug),
            search: {
              integrationId: WELD_BIGQUERY_ID,
            },
          });
        }
      },
    });

  const create = useCallback(
    async (options: {
      workspaceName: string;
      dataWarehouseLocation: string;
      isTest?: boolean;
    }) => {
      await mutation({
        variables: {
          workspaceName: options.workspaceName,
          dataWarehouseLocation: options.dataWarehouseLocation,
          isTest: options.isTest ?? false,
        },
      });
    },
    [mutation],
  );

  // Once `connectDatawarehouse` is called we show the loading screen.
  // Only if the API call fails we show the form again
  const showWeldBQLoadingScreen = isConnecting || isLoadingQuestionnaireOngoing;
  return (
    <>
      <ManagedDataWarehouseContext.Provider value={create} {...props} />
      {showWeldBQLoadingScreen && (
        <AppearTransition>
          <Overlay>
            <Center className="h-full bg-white dark:bg-gray-800">
              <LoadingScreenQuestionnaire
                isLoadingComplete={isConnected}
                onQuestionnaireStart={() => {
                  setLoadingQuestionnaireOngoing(true);
                }}
                onQuestionnaireComplete={() => {
                  setLoadingQuestionnaireOngoing(false);
                  if (isConnected) {
                    navigateOnSetupComplete(data?.id);
                  }
                }}
                onQuestionnaireSkipped={() => {
                  setLoadingQuestionnaireOngoing(false);
                  navigateOnSetupComplete(data?.id);
                }}
              />
            </Center>
          </Overlay>
        </AppearTransition>
      )}
    </>
  );
}
