import {
  Connection,
  useConnectionLazyQuery,
  useConnectionsLazyQuery,
  useCreateConnectionMutation,
} from "@/apollo/types";
import { useRef } from "react";

import { useOAuth } from "../oauth";
import { IntegrationType } from "../types";

type ConnectionOptions = {
  onSuccess: (
    connection: Pick<Connection, "id" | "label" | "integrationId">,
  ) => void;
  onError: (error: Error, integrationId: string | undefined) => void;
};

export function useCreateConnection(options: ConnectionOptions) {
  const integrationIdContextRef = useRef<string>();
  const [fetchConnections] = useConnectionsLazyQuery();

  const [createConnection, { loading }] = useCreateConnectionMutation({
    awaitRefetchQueries: true,
    onCompleted: async (data) => {
      await fetchConnections({
        fetchPolicy: "network-only",
      });
      options.onSuccess(data.createConnection);
    },
    onError: (e) => {
      options.onError(e, integrationIdContextRef.current);
    },
  });

  const [fetchConnection] = useConnectionLazyQuery();

  const { handleOAuth, oAuthLoading } = useOAuth({
    onSuccess: async (connectionId) => {
      await fetchConnections({
        fetchPolicy: "network-only",
      });
      const { data } = await fetchConnection({
        variables: {
          id: connectionId,
        },
      });
      if (data?.connection == null) {
        options.onError(
          new Error("Connection not found"),
          integrationIdContextRef.current,
        );
        return;
      }
      options.onSuccess(data.connection);
    },
    onError: (error) => {
      options.onError(error, integrationIdContextRef.current);
    },
  });

  return {
    loading: loading || oAuthLoading,
    createConnection: async (
      integration: IntegrationType,
      data: Record<string, any> & { label: string },
    ) => {
      integrationIdContextRef.current = integration.id;
      const { label, ...rest } = data;

      if (integration.form.type === "oauth") {
        return handleOAuth({ integrationId: integration.id, data });
      }

      return createConnection({
        variables: {
          integrationId: integration.id,
          label,
          payload: btoa(JSON.stringify(rest)),
        },
      });
    },
  };
}
