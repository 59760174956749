import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";

import { TextMuted } from "@/components/elements/Typography";
import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import { useMixpanel } from "@/monitoring/mixpanel";

import {
  SignupQuestionnaire,
  questions,
  useSignupQuestionnaire,
} from "../questionnaire/SignupQuestionnaire";
import {
  ConnectDataWarehouseLoadingScreen,
  managedBQProgressSteps,
} from "./ConnectDataWarehouseLoadingScreen";

const transition = {
  delay: 2,
  duration: 1,
  type: "spring",
};

const stepsVariant = {
  scaleDown: {
    y: -50,
    scale: 0.7,
    transition,
  },
  initial: {
    y: 0,
    scale: 1,
    transition,
  },
};

const questionnaireVariants = {
  hidden: {
    opacity: 0,
    y: 300,
    transition,
  },
  visible: {
    opacity: 1,
    y: stepsVariant.scaleDown.y,
    transition,
  },
};

export function LoadingScreenQuestionnaire(props: {
  isLoadingComplete: boolean;
  questions: typeof questions;
  onAnswersChange: (questionId: string, answers: Record<string, any>) => void;
  onQuestionnaireStart: () => void;
  onQuestionnaireComplete: () => void;
  onQuestionnaireSkipped: () => void;
}) {
  const [isQuestionnaireCompleted, setQuestionnaireCompleted] = useState(false);

  const hasQuestions = props.questions.length > 0;
  const isQuestionnaireOngoing = hasQuestions && !isQuestionnaireCompleted;

  const onQuestionnaireStartRef = useLatestValueRef(props.onQuestionnaireStart);
  React.useEffect(() => {
    if (isQuestionnaireOngoing) {
      onQuestionnaireStartRef.current();
    }
  }, [isQuestionnaireOngoing, onQuestionnaireStartRef]);

  return (
    <div className="flex flex-col items-center justify-center">
      <motion.div
        variants={stepsVariant}
        animate={isQuestionnaireOngoing ? "scaleDown" : "initial"}
        initial="initial"
      >
        <ConnectDataWarehouseLoadingScreen
          progressSteps={managedBQProgressSteps}
          isComplete={props.isLoadingComplete}
        />
      </motion.div>
      <div className="px-2 sm:px-4">
        <AnimatePresence>
          {hasQuestions && (
            <motion.div
              initial="hidden"
              variants={questionnaireVariants}
              animate={isQuestionnaireOngoing ? "visible" : "hidden"}
            >
              <div className="w-full max-w-xl rounded border shadow-lg">
                <div className="px-5 pt-2 text-sm">
                  <TextMuted>
                    While you wait, please answer a few questions to help us get
                    to know you better.
                  </TextMuted>
                </div>
                <div className="px-5 py-6">
                  <SignupQuestionnaire
                    questions={props.questions}
                    onAnswersChange={props.onAnswersChange}
                    onComplete={() => {
                      setQuestionnaireCompleted(true);
                      props.onQuestionnaireComplete();
                    }}
                  />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default function LoadingScreenQuestionnaireContainer(
  props: Omit<
    React.ComponentProps<typeof LoadingScreenQuestionnaire>,
    "onAnswersChange" | "questions"
  >,
) {
  const mixpanel = useMixpanel();
  const { result, saveResult } = useSignupQuestionnaire();

  const uncompletedQuestions = React.useMemo(() => {
    if (result === undefined) {
      // We don't know yet if the user has completed the questionnaire
      return [];
    }
    if (result === null) {
      // The user has not completed the questionnaire
      return questions;
    }
    const completedQuestions = Object.keys(result?.result || {});
    const uncompletedQuestions = questions.filter(
      (question) => !completedQuestions.includes(question.id),
    );
    return uncompletedQuestions;
  }, [result]);

  return (
    <LoadingScreenQuestionnaire
      {...props}
      questions={uncompletedQuestions}
      onAnswersChange={(questionId, answers) => {
        saveResult(answers);
        mixpanel.track("Signup Questionnaire Question Answered", {
          question_id: questionId,
          answer: answers[questionId],
        });
      }}
      onQuestionnaireComplete={() => {
        props.onQuestionnaireComplete();
        mixpanel.track("Signup Questionnaire Completed");
      }}
    />
  );
}
