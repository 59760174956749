import { PlayIcon } from "@heroicons/react/24/solid";
import { useMatch } from "@tanstack/react-location";
import {
  OrchestrationWorkflowDocument,
  WorkflowRunStatus,
  useCancelCurrentWorkflowRunMutation,
  useStartWorkflowNowMutation,
} from "apollo/types";
import { SecondaryButton } from "components/elements/Button";
import { LoadingFull } from "components/elements/LoadingComponents";
import MainPageLayout from "components/layouts/MainPageLayout";

import classNames from "@/helpers/classNames";
import { useToast } from "@/providers/ToastProvider";
import dayjs from "dayjs";
import { LocationGenerics } from "routes";

import { useOrchestrationWorkflow } from "./components/useWorkflow";
import { Workflow } from "./components/Workflow";
import { OrchestrationTabsLayout } from "./OrchestrationTabsLayout";

const duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const OrchestrationWorkflow = (props: {}) => {
  const match = useMatch<LocationGenerics>();

  const { workflow, nextRunStartingNow } = useOrchestrationWorkflow(
    match.params.workflowId,
  );

  if (!workflow) {
    return (
      <MainPageLayout>
        <LoadingFull />
      </MainPageLayout>
    );
  }

  return (
    <OrchestrationTabsLayout
      current="orchestration"
      action={
        <WorkflowActionButton
          workflowId={workflow.id}
          nextRunStartingNow={nextRunStartingNow}
          workflowStatus={
            workflow.currentRun?.status ?? WorkflowRunStatus.NotStarted
          }
        />
      }
    >
      <Workflow
        workflowId={workflow.id}
        isCurrentWorkflow={true}
        nextRunStartingNow={nextRunStartingNow}
        currentRun={workflow.currentRun}
        nextRun={workflow.nextRun}
        cronExpression={workflow.cronExpression}
      />
    </OrchestrationTabsLayout>
  );
};

export default OrchestrationWorkflow;

const WorkflowActionButton = (props: {
  workflowId: string;
  workflowStatus: WorkflowRunStatus;
  nextRunStartingNow: boolean;
}) => {
  const toast = useToast();

  const [runWorkflow, { loading }] = useStartWorkflowNowMutation({
    refetchQueries: [
      {
        query: OrchestrationWorkflowDocument,
        variables: { workflowId: props.workflowId },
      },
    ],
    onCompleted: () => {
      toast(
        "Orchestration starting",
        "Orchestration will start now",
        "success",
      );
    },
    onError: (error) => {
      toast("Orchestration failed to start", error.message, "error");
    },
  });

  const [cancelWorkflow, { loading: cancelLoading }] =
    useCancelCurrentWorkflowRunMutation({
      refetchQueries: [
        {
          query: OrchestrationWorkflowDocument,
          variables: { workflowId: props.workflowId },
        },
      ],
      onCompleted: () => {
        toast(
          "Orchestration canceled",
          "Orchestration run was canceled.",
          "warning",
        );
      },
      onError: (error) => {
        toast("Failed to cancel Orchestration", error.message, "error");
      },
    });

  const running = props.workflowStatus === WorkflowRunStatus.Running;

  if (running) {
    return (
      <SecondaryButton
        onClick={() => {
          if (cancelLoading) return;
          cancelWorkflow({
            variables: { workflowId: props.workflowId },
          });
        }}
        disabled={cancelLoading}
        className={classNames(cancelLoading && "opacity-50")}
      >
        Cancel orchestration
      </SecondaryButton>
    );
  }

  return (
    <>
      <SecondaryButton
        disabled={loading || running || props.nextRunStartingNow}
        onClick={() => {
          if (loading || props.nextRunStartingNow) return;
          runWorkflow({ variables: { workflowId: props.workflowId } });
        }}
        icon={<PlayIcon />}
      >
        Run orchestration now
      </SecondaryButton>
    </>
  );
};
