import dayjs from "dayjs";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useContext, useEffect, useMemo, useRef } from "react";

import { useCurrentUser } from "@/providers/UserProvider";
import { AccountContext } from "@/providers/account";
import { createStorageKey } from "@/utils/storage";

const INTERCOM_APP_ID = "g5ykt7c2";

export const INTERCOM_ONBOARDING_CHECKLIST = 38964974;

export const intercomControls = {
  openIntercom: () => {
    // To open the Intercom messenger
    window.Intercom("show");
  },

  closeIntercom: () => {
    // To close the Intercom messenger
    window.Intercom("hide");
  },
};

export function useIntercom() {
  return useMemo(() => {
    return {
      show: () => {
        window.Intercom?.("show");
      },
      hide: () => {
        window.Intercom?.("hide");
      },
      openChat: (prefilledMessage?: string) => {
        window.Intercom?.("showNewMessage", prefilledMessage);
      },
    };
  }, []);
}

export const useStartIntercomForUser = (workspaceInitiated?: boolean) => {
  const user = useCurrentUser();
  const account = useContext(AccountContext)?.account;

  const isBooted = useRef(false);

  // Start Intercom for new accounts:
  const checklistReady = isBooted.current && !!workspaceInitiated;

  useStartIntercomChecklistForNewAccounts(checklistReady);

  useEffect(() => {
    const userInfo = {
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      user_id: user.id,
      workspace_id: account?.id,
      workspace_name: account?.name,
    };
    if (!isBooted.current) {
      window.Intercom("boot", {
        app_id: INTERCOM_APP_ID,
        api_base: "https://api-iam.intercom.io",
        hide_default_launcher: workspaceInitiated,
        ...userInfo,
      });
      isBooted.current = true;
    } else {
      window.Intercom("update", {
        ...userInfo,
        hide_default_launcher: workspaceInitiated,
      });
    }
  }, [user, account, workspaceInitiated]);
};

const checklistLastTriggeredAtom = atomWithStorage<string | null>(
  createStorageKey("onboarding-checklist-last-triggered"),
  null,
);

const useStartIntercomChecklistForNewAccounts = (ready: boolean) => {
  const [lastTriggered, setLastTriggered] = useAtom(checklistLastTriggeredAtom);
  const account = useContext(AccountContext)?.account;
  const triggered = useRef(false);

  useEffect(() => {
    if (!ready || !account || triggered.current) return;

    //only autodisplay checklist once
    const latestTriggerDate = lastTriggered ? dayjs(lastTriggered) : null;
    if (latestTriggerDate) {
      return;
    }

    //Only show if account was created in the last 21 days:
    const accountCreated = dayjs(account.createdAt);
    if (accountCreated.isBefore(dayjs().subtract(21, "day"))) {
      return;
    }

    //show checklist (after a short delay to avoid users closing it right away)
    triggered.current = true;
    const timeout = setTimeout(() => {
      window.Intercom("startChecklist", INTERCOM_ONBOARDING_CHECKLIST);
      setLastTriggered(dayjs().toISOString());
    }, 10_000);
    return () => clearTimeout(timeout);
  }, [account, lastTriggered, ready, setLastTriggered]);
};
