import {
  EltSyncsQuery,
  ListModelsQuery,
  ListSyncsQuery,
  ListWebhooksQuery,
  OrchestrationWorkflowQuery,
  WorkflowJobStatus,
  useListSyncsQuery,
  useListWebhooksQuery,
} from "@/apollo/types";
import { useEltSyncs } from "@/hooks/useSync";
import { groupBy } from "lodash";
import { useMemo } from "react";

import { useAllOrchestratedModels } from "../useAllOrchestratedModels";

export type OrchestrationJob = NonNullable<
  NonNullable<OrchestrationWorkflowQuery["orchestrationWorkflow"]>["currentRun"]
>["jobs"][number];

type JobRow = {
  order: number;
  type:
    | "elt-sync"
    | "elt-source-stream"
    | "model"
    | "reverse-elt-sync"
    | "webhook";
  status?: WorkflowJobStatus;
  isLoading: boolean;
  isHistorical: boolean;
  isRemoved?: boolean;
};

export type EltSyncRow = JobRow & {
  type: "elt-sync";
  jobs: (OrchestrationJob & {
    target?: { __typename: "SourceStream" };
  })[];
  sync: EltSyncsQuery["eltSyncs"][0];
};

export type EltSourceStreamRow = JobRow & {
  type: "elt-source-stream";
  job?: OrchestrationJob & {
    target?: { __typename: "SourceStream" };
  };
  sync: EltSyncsQuery["eltSyncs"][0];
};

export type ModelRow = JobRow & {
  type: "model";
  job?: OrchestrationJob & {
    target: { __typename: "MaterializedTableReference" | "ModelViewReference" };
  };
  model: ListModelsQuery["models"][0];
};

export type ReverseEltSyncRow = JobRow & {
  type: "reverse-elt-sync";
  job?: OrchestrationJob & {
    target: { __typename: "Sync" };
  };
  sync: ListSyncsQuery["syncs"][0];
};

export type WebhookRow = JobRow & {
  type: "webhook";
  job?: OrchestrationJob & {
    target: { __typename: "Webhook" };
  };
  webhook: ListWebhooksQuery["listWebhoooks"][0];
};

export type Row =
  | EltSyncRow
  | EltSourceStreamRow
  | ModelRow
  | ReverseEltSyncRow
  | WebhookRow;

export const isEltSyncRow = (row: Row): row is EltSyncRow => {
  return row.type === "elt-sync";
};

export const isEltSourceStreamRow = (row: Row): row is EltSourceStreamRow => {
  return row.type === "elt-source-stream";
};

export const isReverseEltSyncRow = (row: Row): row is ReverseEltSyncRow => {
  return row.type === "reverse-elt-sync";
};

export const isModelRow = (row: Row): row is ModelRow => {
  return row.type === "model";
};

export const isWebhookRow = (row: Row): row is WebhookRow => {
  return row.type === "webhook";
};

export const isSourceStreamJob = (
  job: OrchestrationJob,
): job is OrchestrationJob & {
  target: { __typename: "SourceStream" };
} => {
  return job.target?.__typename === "SourceStream";
};

export const isReverseEltJob = (
  job: OrchestrationJob,
): job is OrchestrationJob & {
  target: { __typename: "Sync" };
} => {
  return job.target?.__typename === "Sync";
};

export const isWebhook = (
  job: OrchestrationJob,
): job is OrchestrationJob & {
  target: { __typename: "Webhook" };
} => {
  return job.target?.__typename === "Webhook";
};

export const isModelJob = (
  job: OrchestrationJob,
): job is OrchestrationJob & {
  target: { __typename: "MaterializedTableReference" | "ModelViewReference" };
} => {
  return (
    job.target?.__typename === "MaterializedTableReference" ||
    job.target?.__typename === "ModelViewReference"
  );
};

type UseTableRowsOptions = {
  workflowId: string;
  jobs?: OrchestrationJob[];
  isHistorical?: boolean;
  groupSourceStreams?: boolean;
};

export const useTableRows = ({
  workflowId,
  jobs = [],
  isHistorical = false,
  groupSourceStreams = true,
}: UseTableRowsOptions) => {
  const { modelRows, modelsNotInCurrentRun } = useModelRows({
    workflowId,
    jobs,
    isHistorical,
  });

  const { eltSyncRows, eltSyncsNotInCurrentRun } = useEltSyncRows({
    workflowId,
    jobs,
    isHistorical,
    groupSourceStreams,
  });

  const { reverseEltSyncRows, reverseEltSyncsNotInCurrentRun } =
    useReverseEltSyncRows({
      workflowId,
      jobs,
      isHistorical,
    });

  const { webhookRows, webhooksNotInCurrentRun } = useWebhookRows({
    workflowId,
    jobs,
    isHistorical,
  });

  const tableRows: Row[] = useMemo(() => {
    const rowsFromCurrentRun: Row[] = [
      ...modelRows,
      ...eltSyncRows,
      ...reverseEltSyncRows,
      ...webhookRows,
    ].sort((a, b) => {
      //sort so that rows with type "elt-source-stream" are first
      //then "model"
      //"reverse-elt-sync"
      //for items with the same type, sort by order
      if (a.type === b.type) return a.order - b.order;
      if (a.type === "elt-source-stream" || a.type === "elt-sync") return -1;
      if (b.type === "elt-source-stream" || b.type === "elt-sync") return 1;
      if (a.type === "model") return -1;
      if (b.type === "model") return 1;
      return 0;
    });

    if (isHistorical) return rowsFromCurrentRun;

    return [
      ...rowsFromCurrentRun,
      ...modelsNotInCurrentRun,
      ...eltSyncsNotInCurrentRun,
      ...reverseEltSyncsNotInCurrentRun,
      ...webhooksNotInCurrentRun,
    ];
  }, [
    isHistorical,
    modelRows,
    modelsNotInCurrentRun,
    eltSyncRows,
    eltSyncsNotInCurrentRun,
    reverseEltSyncRows,
    reverseEltSyncsNotInCurrentRun,
    webhookRows,
    webhooksNotInCurrentRun,
  ]);

  return tableRows;
};

function useModelRows({
  workflowId,
  jobs,
  isHistorical,
}: Required<
  Pick<UseTableRowsOptions, "workflowId" | "jobs" | "isHistorical">
>) {
  const { allOrchestratedModels, loadingModels: isLoading } =
    useAllOrchestratedModels(workflowId);

  const modelJobs = [...jobs].filter(isModelJob);

  return useMemo(() => {
    let rows = modelJobs.reduce<ModelRow[]>((acc, job) => {
      const { target, order } = job;
      if (!target) {
        return acc;
      }
      const modelId = "modelId" in target ? target?.modelId : undefined;
      const model = allOrchestratedModels.find((m) => m.id === modelId);
      if (model) {
        const row: ModelRow = {
          order,
          type: "model",
          status: job.status,
          job: job as ModelRow["job"],
          model: model,
          isHistorical,
          isLoading,
        };
        acc.push(row);
      }
      return acc;
    }, []);

    //find models that are configured to be orchestrated but are not in the current run.
    const modelsNotInCurrentRun: Row[] = allOrchestratedModels
      .filter(
        (model) =>
          !rows.find(
            (row) => row.type === "model" && row.model?.id === model.id,
          ),
      )
      .map(
        (m) =>
          ({
            type: "model",
            order: Number.MAX_SAFE_INTEGER,
            status: WorkflowJobStatus.NotReady,
            isHistorical,
            isLoading,
            model: m,
          }) as ModelRow,
      );

    return {
      modelRows: rows,
      modelsNotInCurrentRun,
    };
  }, [allOrchestratedModels, isHistorical, isLoading, modelJobs]);
}

function includesStatus(
  statuses: WorkflowJobStatus[],
  status: WorkflowJobStatus,
) {
  return statuses.some((x) => x === status);
}

// Derive a single status based on the statuses of a list of orchestration jobs
function getCombinedJobStatus(jobs: OrchestrationJob[]) {
  const statuses = jobs.map((x) => x.status);

  // If all job statuses are the same, the combined job status is that status
  const allEqual = statuses.every((x) => x === statuses[0]);
  if (allEqual) {
    return statuses[0];
  }

  // If at least one is running, the combined job status is running
  if (includesStatus(statuses, WorkflowJobStatus.Running)) {
    return WorkflowJobStatus.Running;
  }

  const endStatuses = new Set([
    WorkflowJobStatus.Done,
    WorkflowJobStatus.Failed,
  ]);

  // Check if all jobs are in an end state
  const allInEndState = statuses.every((x) => endStatuses.has(x));
  if (allInEndState) {
    // If at least one is failed, the combined job status is failed
    if (includesStatus(statuses, WorkflowJobStatus.Failed)) {
      return WorkflowJobStatus.Failed;
    }
    return WorkflowJobStatus.Done;
  }
}

// Create rows based on elt source stream jobs.
function useEltSyncRows({
  workflowId,
  jobs,
  isHistorical,
  groupSourceStreams,
}: Required<UseTableRowsOptions>) {
  const { eltSyncs, loading: isLoading } = useEltSyncs();

  return useMemo(() => {
    let rows: (EltSyncRow | EltSourceStreamRow)[] = [];

    const sourceStreams = [...jobs].filter(isSourceStreamJob);
    const sourceStreamsBySyncId = groupBy(
      sourceStreams,
      (x) => x.target.eltSync.id,
    );

    if (!groupSourceStreams) {
      rows = sourceStreams.reduce<EltSourceStreamRow[]>((acc, job) => {
        const { target, order } = job;
        const sync = eltSyncs.find((s) => s.id === target.eltSync.id);
        if (sync) {
          const row: EltSourceStreamRow = {
            order,
            type: "elt-source-stream",
            status: job.status,
            job: job as EltSourceStreamRow["job"],
            isHistorical,
            isLoading,
            sync,
            isRemoved: !sync?.orchestrationWorkflow,
          };
          acc.push(row);
        }
        return acc;
      }, []);
    } else {
      rows = Object.entries(sourceStreamsBySyncId).reduce<EltSyncRow[]>(
        (acc, [eltSyncId, jobs]) => {
          const order = Math.min(...jobs.map((x) => x.order));
          const sync = eltSyncs.find((s) => s.id === eltSyncId);
          if (sync) {
            const row: EltSyncRow = {
              order,
              type: "elt-sync",
              status: getCombinedJobStatus(jobs),
              jobs,
              isHistorical,
              isLoading,
              sync,
              isRemoved: !sync?.orchestrationWorkflow,
            };
            acc.push(row);
          }
          return acc;
        },
        [],
      );
    }

    //find syncs that are configured to be orchestrated but are not in the current run.
    const eltSyncsNotInCurrentRun: EltSyncRow[] = eltSyncs
      .filter(
        (sync) =>
          sync.orchestrationWorkflow?.id === workflowId &&
          !rows.find(
            (row) => row.type === "elt-sync" && row.sync.id === sync.id,
          ),
      )
      .map((s) => ({
        type: "elt-sync",
        order: Number.MAX_SAFE_INTEGER,
        status: WorkflowJobStatus.NotReady,
        sync: s,
        isHistorical,
        isLoading,
        jobs: [],
      }));

    return {
      eltSyncRows: rows,
      eltSyncsNotInCurrentRun,
    };
  }, [eltSyncs, jobs, isHistorical, isLoading, workflowId, groupSourceStreams]);
}

function useReverseEltSyncRows({
  workflowId,
  jobs,
  isHistorical,
}: Required<
  Pick<UseTableRowsOptions, "workflowId" | "jobs" | "isHistorical">
>) {
  const { data, loading: isLoading } = useListSyncsQuery();

  const reverseEltJobs = [...jobs].filter(isReverseEltJob);

  return useMemo(() => {
    const syncs = data?.syncs ?? [];
    let rows = reverseEltJobs.reduce<ReverseEltSyncRow[]>((acc, job) => {
      const { target, order } = job;
      const sync = syncs.find((s) => s.id === target.id);
      if (sync) {
        const row: ReverseEltSyncRow = {
          order,
          type: "reverse-elt-sync",
          status: job.status,
          job: job as ReverseEltSyncRow["job"],
          isHistorical,
          isLoading,
          sync,
        };
        acc.push(row);
      }
      return acc;
    }, []);

    //find syncs that are configured to be orchestrated but are not in the current run.
    const reverseEltSyncsNotInCurrentRun: ReverseEltSyncRow[] = syncs
      .filter(
        (sync) =>
          sync.orchestrationWorkflow?.id === workflowId &&
          !rows.find(
            (row) => row.type === "reverse-elt-sync" && row.sync.id === sync.id,
          ),
      )
      .map((s) => ({
        type: "reverse-elt-sync",
        order: Number.MAX_SAFE_INTEGER,
        status: WorkflowJobStatus.NotReady,
        isHistorical,
        isLoading,
        sync: s,
      }));

    return {
      reverseEltSyncRows: rows,
      reverseEltSyncsNotInCurrentRun,
    };
  }, [data, reverseEltJobs, isHistorical, isLoading, workflowId]);
}

function useWebhookRows({
  workflowId,
  jobs,
  isHistorical,
}: Required<
  Pick<UseTableRowsOptions, "workflowId" | "jobs" | "isHistorical">
>) {
  const { data, loading: isLoading } = useListWebhooksQuery({
    variables: { orchestrationWorkflowId: workflowId },
  });

  const webhookJobs = [...jobs].filter(isWebhook);
  return useMemo(() => {
    const allWebhooks = data?.listWebhoooks ?? [];
    let rows = webhookJobs.reduce<WebhookRow[]>((acc, job) => {
      const { target, order } = job;

      const webhook = allWebhooks.find((s) => s.webhookId === target.id);
      if (webhook) {
        const row: WebhookRow = {
          order,
          type: "webhook",
          status: job.status,
          job: job as WebhookRow["job"],
          isHistorical,
          isLoading,
          webhook: webhook,
        };

        acc.push(row);
      }
      return acc;
    }, []);

    //find syncs that are configured to be orchestrated but are not in the current run.
    const webhooksNotInCurrentRun: WebhookRow[] = allWebhooks
      .filter(
        (webhook) =>
          webhook.orchestrationWorkflowId === workflowId &&
          !rows.find(
            (row) =>
              row.type === "webhook" &&
              row.webhook.webhookId === webhook.webhookId,
          ),
      )
      .map((s) => ({
        type: "webhook",
        order: Number.MAX_SAFE_INTEGER,
        status: WorkflowJobStatus.NotReady,
        isHistorical,
        isLoading,
        webhook: s,
      }));

    return {
      webhookRows: rows,
      webhooksNotInCurrentRun,
    };
  }, [data, webhookJobs, isHistorical, isLoading, workflowId]);
}
