import { AnimatePresence, motion } from "framer-motion";
import { useMountEffect } from "@/hooks/useMountEffect";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";

import {
  OperationIssuesBanner,
  useOperationIssues,
} from "./banners/OperationIssuesBanner";

const showGlobalBannersAtom = atom(true);

export const useHideGlobalBanners = () => {
  const hide = useSetAtom(showGlobalBannersAtom);
  useMountEffect(hide);
};

export function GlobalBanners() {
  const showGlobalBanners = useAtomValue(showGlobalBannersAtom);
  const operationIssues = useOperationIssues();
  const [dismissedIds, setDismissedIds] = useState<string[]>([]);

  if (showGlobalBanners && operationIssues.length > 0) {
    return (
      <div className="z-50">
        <AnimatePresence initial={false}>
          {operationIssues
            .filter((alert) => !dismissedIds.includes(alert.id))
            .map((issue) => (
              <motion.div
                key={issue.id}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{
                  opacity: 0,
                  scale: 0.9,
                  transition: { duration: 0.15 },
                }}
              >
                <OperationIssuesBanner
                  {...issue}
                  onDismiss={() => {
                    setDismissedIds((bannerIds) =>
                      bannerIds.concat([issue.id]),
                    );
                  }}
                />
              </motion.div>
            ))}
        </AnimatePresence>
      </div>
    );
  }
  return null;
}
