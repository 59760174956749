import { LocationGenerics } from "routes";

import {
  GetRawViewsDocument,
  ListModelFoldersDocument,
  ListModelsDocument,
} from "@/apollo/types";
import LoadingError from "@/components/elements/routing/LoadingError";
import { AppUnknownExceptionViewRenderer } from "@/components/modules/Error/AppUnknownExceptionView";
import { ApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Route } from "@tanstack/react-location";

import ModelEditorPage from "./ModelEditor";
import { DraftEditor, ModelLiveEditor } from "./QueryEditor/Editor";
import EditorWithBar from "./QueryEditor/EditorWithBar";
import VisualizePage from "./Visualize/VisualizePage";
import { EditorEmptyState } from "./components/EditorEmptyState";

export const editorRoute = (
  client: ApolloClient<object>,
): Route<LocationGenerics> => {
  return {
    path: "editor",
    loader: async (_match, { parentMatch }) => {
      if (!parentMatch?.loaderPromise) return {};
      const accountId = (await parentMatch.loaderPromise).accountId;
      await Promise.allSettled([
        client.query({
          query: ListModelsDocument,
          context: { accountId },
        }),
        client.query({
          query: ListModelFoldersDocument,
          context: { accountId },
        }),
        client.query({
          query: GetRawViewsDocument,
          context: { accountId },
        }),
      ]);
      return {};
    },

    element: (
      <Sentry.ErrorBoundary fallback={AppUnknownExceptionViewRenderer}>
        <ModelEditorPage />
      </Sentry.ErrorBoundary>
    ),
    children: [
      {
        path: "draft",
        children: [
          {
            path: ":draftId",
            errorElement: <LoadingError />,
            element: (
              <EditorWithBar>
                <DraftEditor />
              </EditorWithBar>
            ),
          },
        ],
      },
      {
        path: "dashboard",
        children: [
          {
            path: ":dashboardId",
            errorElement: <LoadingError />,
            element: <VisualizePage />,
          },
        ],
      },
      {
        path: ":modelId",
        errorElement: <LoadingError />,
        element: (
          <EditorWithBar>
            <ModelLiveEditor />
          </EditorWithBar>
        ),
      },
      {
        element: <EditorEmptyState />,
      },
    ],
  };
};
