import { Dispatch, SetStateAction, useCallback, useState } from "react";

import { useIsMounted } from "./useIsMounted";

/**
 * Like `useState` but avoids calling setState in unmounted components
 */
export function useSafeState<T>(
  initialState: T | (() => T),
): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(initialState);
  const isMounted = useIsMounted();
  return [
    state,
    useCallback(
      (value) => {
        if (isMounted()) {
          setState(value);
        }
      },
      [isMounted, setState],
    ),
  ];
}
