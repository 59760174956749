import { FC, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";

import Toggle from "@/components/elements/Toggle";
import { InputError } from "@/components/primitives/InputError";
import { InputErrorIndicator } from "@/components/primitives/InputErrorIndicator";
import { useUnmountEffect } from "@/hooks/useUnmountEffect";
import { FormType } from "@/integrations/forms";

import FormFactory from "./FormFactory";

export const SubForm: FC<{
  form: Extract<FormType, { type: "sub_form" }>;
}> = ({ form }) => {
  const [visible, setVisible] = useState<boolean>(form.required);

  const methods = useFormContext();
  const handleToggle = useCallback(
    (value: boolean) => {
      setVisible(value);
      methods.trigger();
      if (!value) {
        form.fields.forEach((field) => {
          methods.unregister(field.name);
        });
      }
    },
    [methods, form],
  );

  useUnmountEffect(() => {
    if (Array.isArray(form.fields)) {
      form.fields.forEach((field) => {
        methods.unregister(field.name);
      });
    }
  });

  if (!form.fields?.length) return null;

  return (
    <div>
      {!form.required && (
        <div className="flex items-center space-x-4 border-t py-8 dark:border-gray-700">
          <Toggle checked={visible} onChange={handleToggle} />
          <label className="text-sm text-gray-700 dark:text-gray-400">
            {form.label}
          </label>
        </div>
      )}
      {visible && form.fields?.map((f, i) => <FormFactory key={i} form={f} />)}
      {!!form.error && (
        <div className="flex flex-row items-center gap-1">
          <InputErrorIndicator />
          <InputError>{form.error}</InputError>
        </div>
      )}
    </div>
  );
};
