import { useGetViewDetailsQuery } from "@/apollo/types";

export const useViewDetails = (path?: string[], skip?: boolean) => {
  const { data, loading } = useGetViewDetailsQuery({
    variables: {
      input: {
        path: path ?? [],
      },
    },
    skip: !path || skip,
  });

  return { viewDetails: data?.getViewDetails, loading };
};
