import { useMatch, useNavigate } from "@tanstack/react-location";
import {
  ConnectionWithCredentialsQuery,
  useConnectionWithCredentialsQuery,
} from "@/apollo/types";
import Centered from "@/components/elements/layout/Centered";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import TabTaskLayout from "@/components/layouts/TabTaskLayout";
import { TaskAction } from "@/components/modules/TaskActionBar";
import { useIntegration, useUpdateConnection } from "@/integrations";
import { useToast } from "@/providers/ToastProvider";
import { FormProvider } from "react-hook-form";
import { LocationGenerics } from "routes";
import invariant from "tiny-invariant";

import ConnectionForm, {
  ConnectionFormSubmitButton,
  useConnectionForm,
} from "./ConnectionForm/ConnectionForm";

export default function EditConnectionPageWrapper() {
  const {
    params: { connectionId },
  } = useMatch<LocationGenerics>();

  const { data } = useConnectionWithCredentialsQuery({
    variables: {
      id: connectionId,
    },
  });
  if (data?.connection == null) {
    return <LoadingSpinner />;
  }
  return <EditConnectionPage connection={data.connection} />;
}

function EditConnectionPage({
  connection,
}: {
  connection: ConnectionWithCredentialsQuery["connection"];
}) {
  const integration = useIntegration(connection.integrationId);
  invariant(integration, "Integration not found");

  const navigate = useNavigate();
  const toast = useToast();

  const formMethods = useConnectionForm(integration, {
    defaultValues: {
      ...connection.credentials,
      label: connection.label,
    },
  });

  const { loading, updateConnection } = useUpdateConnection(
    connection.id,
    integration,
    {
      onSuccess() {
        toast(
          "Connection updated",
          "The connection has been succesfully updated",
          "success",
        );
        navigate({ to: ".." });
      },
      onError(e) {
        toast("Connection not updated", e.message, "error");
      },
    },
  );

  return (
    <TabTaskLayout
      tabs={[
        { href: `..`, id: "overview", name: "Overview" },
        {
          href: `.`,
          id: "configuration",
          name: "Configuration",
        },
      ]}
    >
      <Centered>
        <FormProvider {...formMethods}>
          <form
            id="integration-form"
            onSubmit={formMethods.handleSubmit((data) =>
              updateConnection(data),
            )}
          >
            <ConnectionForm
              integration={integration}
              isEditMode
              isLoading={loading}
            />
            <TaskAction>
              <ConnectionFormSubmitButton
                integration={integration}
                isLoading={loading}
              />
            </TaskAction>
          </form>
        </FormProvider>
      </Centered>
    </TabTaskLayout>
  );
}
