import { useApolloClient } from "@apollo/client";
import {
  FindQueryHistoryDocument,
  GetViewDetailsDocument,
  ListModelsDocument,
  ModelBaseFragment,
  ModelBaseFragmentDoc,
  ModelListItemFragmentDoc,
  ModelPublishedFragmentFragment,
  PublishModelMutationVariables,
  UpdatePublishedModelMaterializationMutationVariables,
  UpdatePublishedModelQueryMutationVariables,
  useCreateDraftModelMutation,
  useFindQueryHistoryLazyQuery,
  useGetViewDetailsLazyQuery,
  useModelLazyQuery,
  usePublishModelAsyncMutation,
  usePublishModelMutation,
  useUpdatePublishedModelMaterializationMutation,
  useUpdatePublishedModelQueryAsyncMutation,
  useUpdatePublishedModelQueryMutation,
} from "@/apollo/types";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentUser } from "@/providers/UserProvider";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSocketEvent } from "@/socket/SocketContext";

import { useDeleteModelDraft } from "../QueryEditor/useModelDraft";

type ModelType = ModelPublishedFragmentFragment & ModelBaseFragment;

export function usePublishModelAsync(
  modelOrUnsavedDraftId: string,
  options: {
    onModelPublished?: (
      model: ModelType,
      context: {
        modelId: string;
        isUnsavedDraft: boolean;
      },
    ) => void;
    onPublishError?: (
      error: Error,
      model: ModelType | undefined,
      context: {
        modelId: string;
        isUnsavedDraft: boolean;
      },
    ) => void;
  } = {},
) {
  const [isPublishing, setIsPublishing] = useState(false);
  const unsavedDraftIdRef = useRef<string>();

  const [publishDraftMutation] = usePublishModelAsyncMutation();
  const [publishModelMutation] = useUpdatePublishedModelQueryAsyncMutation();

  const [fetchModel] = useModelLazyQuery();
  const [fetchModelQueryHistory] = useFindQueryHistoryLazyQuery();
  const [fetchViewDetails] = useGetViewDetailsLazyQuery();

  const client = useApolloClient();
  const currentUser = useCurrentUser();

  useEffect(() => {
    unsavedDraftIdRef.current = undefined;
    setIsPublishing(false);
  }, [modelOrUnsavedDraftId]);

  const writeToModelListCache = (model: ModelType) => {
    client.cache.modify({
      fields: {
        // Update `ListModels` cache optimistically due to heavy load on DB
        models(existingModels = []) {
          const newModelRef = client.cache.writeFragment({
            data: model,
            fragment: ModelListItemFragmentDoc,
            fragmentName: "ModelListItem",
          });
          return [...existingModels, newModelRef];
        },
      },
    });
  };

  useSocketEvent("model:published", {
    onMessage: async (message) => {
      const { payload } = message;
      if (payload.userId !== currentUser.id) {
        // Ignore messages generated by other users
        return;
      }
      const isUnsavedDraft = payload.modelId === unsavedDraftIdRef.current;
      if (payload.modelId !== modelOrUnsavedDraftId && !isUnsavedDraft) {
        // Ignore messages for other models
        return;
      }
      setIsPublishing(false);
      // Fetch updated model from server
      const resp = await fetchModel({
        fetchPolicy: "network-only",
        variables: {
          modelId: payload.modelId,
        },
      });
      const publishedModel = resp.data?.model;

      if (publishedModel) {
        options.onModelPublished?.(publishedModel, {
          modelId: modelOrUnsavedDraftId,
          isUnsavedDraft,
        });

        if (isUnsavedDraft) {
          // A draft was published
          writeToModelListCache(publishedModel);
        }
      }
      // Fetch the query history for the model
      fetchModelQueryHistory({
        fetchPolicy: "network-only",
        variables: {
          modelId: payload.modelId,
        },
      });
      // Fetch the view details for the model
      fetchViewDetails({
        fetchPolicy: "network-only",
        variables: {
          input: {
            path:
              publishedModel?.dwSync?.path ??
              publishedModel?.dwTable?.path ??
              [],
          },
        },
      });
      // Invalidate any results of a model execution results in the apollo cache (Dashboard)
      client.cache.evict({
        id: client.cache.identify({
          __typename: "QueryModelResponse",
          id: payload.modelId,
        }),
      });
    },
  });

  useSocketEvent("model:publish-failed", {
    onMessage: async (message) => {
      const { payload } = message;
      if (payload.userId !== currentUser.id) {
        // Ignore messages generated by other users
        return;
      }
      const isUnsavedDraft = payload.modelId === unsavedDraftIdRef.current;
      if (payload.modelId !== modelOrUnsavedDraftId && !isUnsavedDraft) {
        // Ignore messages for other models
        return;
      }
      setIsPublishing(false);

      const resp = await fetchModel({
        fetchPolicy: "network-only",
        variables: {
          modelId: payload.modelId,
        },
      });

      const model = resp.data?.model;

      if (model && isUnsavedDraft) {
        // Publish failed, but the model was still saved as a draft - update the model list cache
        writeToModelListCache(model);
      }

      options.onPublishError?.(
        new Error(payload.errorMessage),
        resp.data?.model,
        {
          modelId: modelOrUnsavedDraftId,
          isUnsavedDraft,
        },
      );
    },
  });

  const publishDraft = useCallback(
    async (variables: PublishModelMutationVariables) => {
      if (isPublishing) return;
      setIsPublishing(true);
      unsavedDraftIdRef.current = undefined;
      const resp = await publishDraftMutation({
        variables,
      });
      if (variables.isUnsavedModel) {
        unsavedDraftIdRef.current = resp.data?.publishModelAsync;
      }
    },
    [publishDraftMutation, isPublishing],
  );

  const publishModel = useCallback(
    async (variables: UpdatePublishedModelQueryMutationVariables) => {
      if (isPublishing) return;
      setIsPublishing(true);
      unsavedDraftIdRef.current = undefined;
      await publishModelMutation({
        variables,
      });
    },
    [publishModelMutation, isPublishing],
  );

  return {
    loading: isPublishing,
    publishDraft,
    publishModel,
  };
}

export const usePublishModel = (
  modelId?: string,
  onSettled?: (modelId?: string) => void,
) => {
  const toast = useToast();

  const [publishModel, { loading: publishing, client }] =
    usePublishModelMutation({
      awaitRefetchQueries: true,
    });

  const [updateModelQuery, { loading: updating }] =
    useUpdatePublishedModelQueryMutation({
      awaitRefetchQueries: true,
    });

  const loading = useMemo(() => publishing || updating, [publishing, updating]);

  const onCompleted = useCallback(() => {
    toast(
      `Model published`,
      `The model was succesfully published to datawarehouse.`,
      "success",
    );
  }, [toast]);

  const handlePublish = useCallback(
    async (variables: PublishModelMutationVariables) => {
      if (loading) return;

      const res = await publishModel({
        variables,
        onError: (error) => {
          toast(`Failed to publish model`, error.message, "error");
        },
        onCompleted,
        update: (cache, { data }) => {
          if (!data?.publishModel || !variables.isUnsavedModel) return;
          cache.modify({
            fields: {
              models(existingModels = []) {
                const newModelRef = cache.writeFragment({
                  data: data.publishModel,
                  fragment: ModelListItemFragmentDoc,
                  fragmentName: "ModelListItem",
                });
                return [...existingModels, newModelRef];
              },
            },
          });
        },
        refetchQueries: modelId
          ? [
              {
                query: FindQueryHistoryDocument,
                variables: { modelId },
              },
            ]
          : undefined,
      });

      onSettled?.(res.data?.publishModel.id);
    },
    [loading, publishModel, onCompleted, modelId, onSettled, toast],
  );

  const handleUpdatePublished = useCallback(
    async (
      variables: UpdatePublishedModelQueryMutationVariables,
      path: string[],
    ) => {
      if (loading) return;
      await updateModelQuery({
        variables,

        onError: (error) => {
          toast(`Model not synced`, error.message, "error");
          client.refetchQueries({ include: [ListModelsDocument] });
        },
        onCompleted,
        refetchQueries: [
          {
            query: FindQueryHistoryDocument,
            variables: { modelId },
          },
          {
            query: GetViewDetailsDocument,
            variables: { input: { path: path } },
          },
        ],
        update: (cache, { data }) => {
          cache.evict({
            id: cache.identify({
              __typename: "QueryModelResponse",
              id: data?.updatePublishedModelQuery.id,
            }),
          });
        },
      });
    },
    [loading, updateModelQuery, onCompleted, modelId, toast, client],
  );

  return {
    handlePublish,
    handleUpdatePublished,
    loading,
  };
};

export const useUpdateMaterialization = (
  modelId?: string,
  onSettled?: () => void,
) => {
  const toast = useToast();

  const [updateMaterialization, { loading, client }] =
    useUpdatePublishedModelMaterializationMutation({
      onError(error) {
        toast(`Model not synced`, error.message, "error");
        client.refetchQueries({ include: [ListModelsDocument] });
      },
      onCompleted(res) {
        toast(
          `Model updated`,
          `Materializaton settings was successfully updated.`,
          "success",
        );
      },
    });

  const handleUpdateMaterialization = useCallback(
    async (variables: UpdatePublishedModelMaterializationMutationVariables) => {
      if (loading) return;
      await updateMaterialization({
        variables,
      });
      onSettled?.();
    },
    [loading, updateMaterialization, onSettled],
  );

  return { handleUpdateMaterialization, loading };
};

export const useSaveUnpublishedModel = (draftModelId?: string) => {
  const deleteModelDraft = useDeleteModelDraft();
  const toast = useToast();

  const [createDraftModel, { loading }] = useCreateDraftModelMutation({
    update: (cache, { data }) => {
      if (!data?.createDraftModel) return;
      cache.modify({
        fields: {
          models(existingModels = []) {
            const newModelRef = cache.writeFragment({
              data: data.createDraftModel,
              fragment: ModelBaseFragmentDoc,
            });
            return [...existingModels, newModelRef];
          },
        },
      });
    },
    onCompleted: () => {
      if (draftModelId) deleteModelDraft(draftModelId);
    },
    onError: (error) => {
      toast("Error saving model", error.message, "error");
    },
  });
  return {
    createDraftModel,
    loading,
  };
};
