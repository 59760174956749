import {
  FolderPlusIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useMatches } from "@tanstack/react-location";
import { BetaFeatureText } from "@/components/elements/FeatureStateText";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { useReflexAccordionItemContext } from "@/components/elements/ReflexAccordion/ReflexAccordionItem";
import Tooltip from "@/components/elements/Tooltip";
import DashboardIcon from "@/components/icons/DashboardIcon";
import classNames from "@/helpers/classNames";
import { isWindows } from "@/helpers/isWindows";
import { useArrowKeyFocusNavigation } from "@/hooks/useArrowKeyFocusNavigation";
import { useKBar } from "kbar";
import { useMixpanel } from "@/monitoring/mixpanel";
import { AddNewDashboard } from "@/pages/ModelTool/Visualize/AddNewDashboard";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { LocationGenerics } from "routes";

import { PaneActionButton } from "../components/PaneActionButton";
import {
  PaneEmptyState,
  PaneEmptyStateButton,
  PaneEmptyStateText,
} from "../components/PaneEmptyState";
import { PaneContent, PaneHeader } from "../components/SidebarPane";
import {
  GroupItemType,
  SidebarItemFactory,
  SidebarItemType,
  SidebarNewItem,
} from "../SidebarFactory";
import { useNewSidebarItemState } from "../useNewSidebarItemState";
import { useSidebarFolders } from "../useSidebarFolders";
import { usePublishedItems } from "./usePublishedItems";

const KbarSearchButton = () => {
  const kbar = useKBar();
  const mixpanel = useMixpanel();
  return (
    <Tooltip content={`${isWindows() ? "Ctrl" : "⌘"} + K`}>
      <PaneActionButton
        onClick={() => {
          kbar.query.toggle();
          mixpanel.track("Kbar Opened", {
            source: "sidebar",
          });
        }}
        icon={<MagnifyingGlassIcon />}
      />
    </Tooltip>
  );
};

export const PublishedPane = React.memo(function PublishedPaneMemo() {
  const matches = useMatches<LocationGenerics>();
  const selectedItemId = useMemo(
    () => matches[1]?.params.modelId || matches[2]?.params.dashboardId,
    [matches],
  );
  const mixpanel = useMixpanel();

  const { items, loading } = usePublishedItems();

  const { isOpen, toggle: togglePane } = useReflexAccordionItemContext();
  const { item: newItem, createNewFolderItem } = useNewSidebarItemState();

  const handleAddFolder = () => {
    togglePane(true);
    createNewFolderItem();
    mixpanel.track("Add Model Folder Clicked");
  };

  const [addingDashboard, setAddingDashboard] = useState(false);

  return (
    <>
      <PaneHeader
        open={isOpen}
        toggle={togglePane}
        actions={
          <>
            <Tooltip content="Add new folder">
              <PaneActionButton
                icon={<FolderPlusIcon />}
                onClick={() => handleAddFolder()}
              />
            </Tooltip>
            <Tooltip
              content={
                <div className="flex items-center space-x-1">
                  <span>Add new dashboard</span> <BetaFeatureText />
                </div>
              }
            >
              <PaneActionButton
                icon={<DashboardIcon />}
                onClick={() => setAddingDashboard(true)}
              />
            </Tooltip>
            <KbarSearchButton />
          </>
        }
      >
        <div className="truncate">Published</div>
      </PaneHeader>
      <PaneContent>
        {loading ? (
          <div className="flex items-center justify-center px-4 py-2">
            <LoadingSpinner />
          </div>
        ) : items.length === 0 && !newItem ? (
          <PaneEmptyState>
            <PaneEmptyStateText>
              No folders set up yet. Add a folder to start publishing models.
            </PaneEmptyStateText>
            <PaneEmptyStateButton
              icon={<FolderPlusIcon />}
              onClick={() => handleAddFolder()}
            >
              <span className="truncate">Add folder</span>
            </PaneEmptyStateButton>
          </PaneEmptyState>
        ) : (
          <Published
            publishedItems={items}
            selectedId={selectedItemId}
            isAddingNew={!!newItem}
          />
        )}
      </PaneContent>
      <AddNewDashboard
        open={addingDashboard}
        onFinish={() => {
          setAddingDashboard(false);
        }}
      />
    </>
  );
});

const Published: React.FC<{
  headline?: string;
  publishedItems: Exclude<SidebarItemType, GroupItemType>[];
  selectedId?: string;
  isAddingNew?: boolean;
}> = (props) => {
  const { openFolders, toggleFolder } = useSidebarFolders();
  const { item: newItem } = useNewSidebarItemState();

  const [highlightedId, setHighlightedId] = useState("");

  const containerRef = useRef<HTMLDivElement>(null);
  const currentHightlightedRef = useRef<HTMLDivElement>(null);

  useArrowKeyFocusNavigation(containerRef, currentHightlightedRef);

  useEffect(() => {
    const currentContainerRef = containerRef.current;
    if (!highlightedId || !currentContainerRef) return;

    const enterDownListener = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        currentHightlightedRef.current?.click();
      }
    };

    currentContainerRef.addEventListener("keydown", enterDownListener);
    return () =>
      currentContainerRef.removeEventListener("keydown", enterDownListener);
  }, [highlightedId]);

  const publishedGroup: GroupItemType = React.useMemo(() => {
    return {
      id: "published_models",
      itemType: "group",
      name: "Published",
      children: props.publishedItems,
    };
  }, [props.publishedItems]);

  return (
    <div
      className={classNames(
        "flex h-full select-none flex-col",
        props.isAddingNew && "bg-gray-200 dark:bg-gray-700",
      )}
      onBlur={() => setHighlightedId("")}
    >
      <div ref={containerRef}>
        {newItem?.itemType === "folder" && !newItem.folderId && (
          <SidebarNewItem level={0} newItem={newItem} />
        )}
        {publishedGroup.children.map((item) => (
          <SidebarItemFactory
            key={item.id}
            level={1}
            item={item}
            openItems={openFolders}
            onToggleFolder={toggleFolder}
            onFocus={(id) => {
              setHighlightedId(id);
            }}
            setHighlightedId={setHighlightedId}
            selectedId={props.selectedId}
          />
        ))}
      </div>
    </div>
  );
};
