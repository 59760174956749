import { useMemo } from "react";

import { ConnectionBaseFragment, useConnectionsQuery } from "@/apollo/types";

export function useConnectionsByIntegrationId() {
  const { data } = useConnectionsQuery();
  return useMemo(() => {
    if (data === undefined) {
      return new Map<string, ConnectionBaseFragment[]>();
    }
    return data.connections.reduce((acc, connection) => {
      const connectionList = acc.get(connection.integrationId) ?? [];
      connectionList.push(connection);
      acc.set(connection.integrationId, connectionList);
      return acc;
    }, new Map<string, ConnectionBaseFragment[]>());
  }, [data]);
}
