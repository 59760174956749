import { Popover } from "@headlessui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import * as PopperJS from "@popperjs/core";
import { PlanName, RecurrenceInterval, Tier } from "@/apollo/types";
import { Heading, TextMuted } from "@/components/elements/Typography";
import cn from "@/helpers/classNames";
import React, {
  ComponentProps,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { CellProps, Column, useTable } from "react-table";
import { formatRowCountCompact } from "@/shared/formatters";

import { formatPlanName } from "../../plans";
import { usePrice } from "../../pricing/api";
import { FormattedPrice } from "./FormattedPrice";

type PriceTiersOverviewProps = {
  planName: PlanName;
  focusedTier?: Tier;
  price?: ReturnType<typeof usePrice>["price"];
  currency: string;
};

function PriceTiersOverview(props: PriceTiersOverviewProps) {
  return (
    <div className="flex h-full flex-col divide-y overflow-hidden dark:divide-black/50">
      <div className="px-4 py-2">
        <Heading className="">
          Pricing tiers for {formatPlanName(props.planName)} plan
        </Heading>
        <TextMuted
          as="a"
          className="block text-xs leading-tight underline"
          href="https://weld.app/pricing"
          target="_blank"
        >
          Learn more <ArrowTopRightOnSquareIcon className="inline h-4" />
        </TextMuted>
        <TextMuted as="div" className="mt-2 text-xs">
          {props.price?.recurring?.interval === RecurrenceInterval.Year && (
            <>Prices are per month, billed annually</>
          )}
          {props.price?.recurring?.interval === RecurrenceInterval.Month && (
            <>Prices are per month</>
          )}
        </TextMuted>
      </div>
      <div className="grow overflow-auto">
        <TieredPlanTable
          price={props.price}
          focusedTier={props.focusedTier}
          currency={props.currency}
        />
      </div>
    </div>
  );
}

const LT_EQ = "\u2264";
const GT = "\u003E";

function TieredPlanTable(
  props: Pick<PriceTiersOverviewProps, "price" | "focusedTier" | "currency">,
) {
  const tiers =
    props.price?.currencyOptions.find((x) => x.currency === props.currency)
      ?.tiers ?? [];

  const focusedRowRef = useRef<HTMLTableRowElement>(null);
  useEffect(() => {
    if (focusedRowRef.current) {
      focusedRowRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedRowRef.current]);

  const columns = useMemo<Column<Tier>[]>(
    () => [
      {
        Header: "Rows",
        Cell: ({ value, row, rows }: CellProps<Tier, Tier["upTo"]>) => {
          if (value == null) {
            const previousValue = rows[row.index - 1].original.upTo;
            if (previousValue) {
              return (
                <span>
                  {GT} {formatRowCountCompact(previousValue)}
                </span>
              );
            }
            return <span>?</span>;
          }
          return (
            <span>
              {LT_EQ} {formatRowCountCompact(value)}
            </span>
          );
        },
        accessor: "upTo",
      },
      {
        Header: "Price",
        Cell: ({ value, row }: CellProps<Tier, Tier["price"]>) => {
          if (value == null) return <span>?</span>;
          const p: ComponentProps<typeof FormattedPrice>["price"] = {
            currency: props.currency,
            recurring: props.price?.recurring,
            price: value,
            currencyOptions: [
              {
                currency: props.currency,
                price: value,
              },
            ],
          };
          return (
            <FormattedPrice price={p} convertBillingInterval="Month">
              <FormattedPrice.Price options={{ maximumFractionDigits: 0 }} />
            </FormattedPrice>
          );
        },
        accessor: "price",
        align: "center",
      },
    ],
    [props.currency, props.price],
  );

  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data: tiers,
    });

  return (
    <table {...getTableProps()} className="min-w-full">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                scope="col"
                className={cn(
                  "z-1 dark:text sticky top-0 truncate bg-white px-6 py-2 text-left text-sm font-normal shadow-[inset_0_-1px_0_rgba(0,0,0,0.2)] dark:bg-gray-700 dark:text-gray-400",
                  {
                    "text-center":
                      "align" in column && column.align === "center",
                  },
                )}
                {...column.getHeaderProps()}
              >
                <TextMuted>{column.render("Header")}</TextMuted>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="divide-y divide-black/20">
        {rows.map((row) => {
          prepareRow(row);
          const isFocused =
            props.focusedTier !== undefined &&
            row.original.upTo === props.focusedTier.upTo;
          return (
            <tr
              ref={isFocused ? focusedRowRef : undefined}
              {...row.getRowProps({
                className: cn("even:bg-black/5 dark:even:bg-white/5"),
              })}
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    className={cn("px-6 py-3 text-left text-sm", {
                      "bg-blue-500 text-white dark:bg-blue-600": isFocused,
                      "text-center":
                        "align" in cell.column &&
                        cell.column.align === "center",
                    })}
                    {...cell.getCellProps({
                      style: {
                        maxWidth: cell.column.maxWidth,
                        minWidth: cell.column.minWidth,
                        width: cell.column.width,
                      },
                    })}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export const PriceTiersPopover = ({
  placement,
  children,
  ...props
}: ComponentProps<typeof PriceTiersOverview> & {
  planName: PlanName;
  focusedTier?: Tier;
  placement?: PopperJS.Placement;
  children: React.ReactNode;
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "absolute",
    placement: placement ?? "auto-start",
    modifiers: [
      { name: "preventOverflow", enabled: true },
      { name: "offset", options: { offset: [5, 5] } },
    ],
  });

  const Trigger = React.Children.only(children);
  return (
    <Popover>
      <Popover.Button as="div" className="flex">
        {React.isValidElement<any>(Trigger)
          ? React.cloneElement(Trigger, {
              ref: setReferenceElement,
              role: "button",
            })
          : null}
      </Popover.Button>
      {createPortal(
        <Popover.Panel
          ref={setPopperElement}
          className="absolute z-50 h-72 w-64 overflow-hidden rounded-md border border-gray-200 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:border-gray-900 dark:bg-gray-700"
          style={styles.popper}
          {...attributes.popper}
        >
          <PriceTiersOverview {...props} />
        </Popover.Panel>,
        document.body,
      )}
    </Popover>
  );
};
