import { XMarkIcon } from "@heroicons/react/24/solid";
import { DestinationPropertiesQuery, Property } from "@/apollo/types";
import Select from "@/components/elements/Select";
import SyncItem from "@/components/elements/SyncItem";
import { useMemo } from "react";

import useDestinationProperties from "../hooks/useDestinationProperties";
import useObjectTypes from "../hooks/useObjectTypes";
import useSourceProperties, {
  SourceProperty,
} from "../hooks/useSourceProperties";
import { useSyncContext } from "./SyncContext";
import { MappingType } from "./syncReducer";

export default function MappingItem({
  item: mappingItem,
  unmappedSourceProperties,
  unmappedDestinationProperties,
}: {
  item: MappingType;
  unmappedSourceProperties: SourceProperty[];
  unmappedDestinationProperties: DestinationPropertiesQuery["destinationProperties"];
}): JSX.Element {
  const [state, dispatch] = useSyncContext();

  const { destinationPropertiesObject, destinationPropertiesLoading } =
    useDestinationProperties();
  const { sourcePropertiesObject, sourcePropertiesLoading } =
    useSourceProperties();
  const { currentObjectType } = useObjectTypes();

  const supportsCustom =
    currentObjectType?.supportedDestinationMapping.includes("custom");

  const destinationValue = useMemo(() => {
    if (!mappingItem.destinationPropertyId) return null;
    const destinationProperty =
      destinationPropertiesObject[mappingItem.destinationPropertyId];
    if (destinationProperty) return destinationProperty;

    return {
      propertyId: mappingItem.destinationPropertyId,
      propertyName: mappingItem.destinationPropertyId,
      propertyType: "Custom",
    };
  }, [destinationPropertiesObject, mappingItem.destinationPropertyId]);

  const destinationIsRequired = useMemo(
    () =>
      !!mappingItem.destinationPropertyId &&
      destinationPropertiesObject[mappingItem.destinationPropertyId]
        ?.isRequired,
    [destinationPropertiesObject, mappingItem.destinationPropertyId],
  );

  return (
    <div className="flex items-center space-x-4 py-4 lg:py-0">
      <div className="grow">
        <SyncItem.MappingRow fullWidth>
          <SyncItem.Column>
            <Select
              isLoading={sourcePropertiesLoading}
              autoFocus={state.autoFocus}
              placeholder="Select your source property"
              disabled={!state.queryId}
              value={
                mappingItem.sourcePropertyId
                  ? sourcePropertiesObject[mappingItem.sourcePropertyId]
                  : null
              }
              labelKey="propertyName"
              badgeKey="propertyType"
              options={unmappedSourceProperties}
              onChange={(item: Property | null) => {
                dispatch({
                  type: "change_mapping_source_property",
                  payload: {
                    propertyId: item?.propertyId,
                    uuid: mappingItem.uuid,
                  },
                });
              }}
            />
          </SyncItem.Column>
          <SyncItem.Arrow />
          <SyncItem.Column>
            <Select
              isLoading={destinationPropertiesLoading}
              creatable={supportsCustom}
              placeholder={`Select ${
                supportsCustom ? "or create" : ""
              } your destination property`}
              value={destinationValue}
              labelKey="propertyName"
              badgeKey="propertyType"
              options={unmappedDestinationProperties}
              onChange={(
                item: (Property & { label: string; value: string }) | null,
                actionMeta: any,
              ) => {
                if (
                  item &&
                  supportsCustom &&
                  actionMeta.action === "create-option"
                ) {
                  // These haven't been set when the user defines a new
                  item.propertyType = "string";
                  item.readonly = false;
                  item.propertyName = item.label || item.value;
                  item.propertyId = item?.value;
                }
                dispatch({
                  type: "change_mapping_destination_property",
                  payload: {
                    propertyId: item?.propertyId,
                    uuid: mappingItem.uuid,
                  },
                });
              }}
              disabled={destinationIsRequired}
            />
          </SyncItem.Column>
        </SyncItem.MappingRow>
      </div>

      <button
        type="button"
        disabled={destinationIsRequired}
        className={`${
          !destinationIsRequired ? "text-gray-400" : "text-gray-200"
        } w-6 shrink-0`}
        onClick={() =>
          dispatch({
            type: "remove_mapping_row",
            payload: mappingItem.uuid,
          })
        }
      >
        <XMarkIcon />
      </button>
    </div>
  );
}
