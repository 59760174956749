import { LoadingTable } from "@/components/elements/LoadingComponents";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export default function useLoadingManager(
  {
    loading: realLoading,
    time = 1000,
    LoaderComponent = LoadingTable,
    message,
  }: {
    loading: boolean;
    time?: number;
    LoaderComponent?: React.ComponentType<React.PropsWithChildren<{}>>;
    message: string;
  },
  callback: Function,
): JSX.Element {
  const [state, setState] = useState("hidden");

  const startFakeLoading = useCallback(() => {
    if (!realLoading) return;
    setState("loading");
    setTimeout(() => {
      setState("fakeLoaded");
    }, 1000);
  }, [realLoading]);

  useEffect(() => {
    const t = setTimeout(() => startFakeLoading(), 200);
    return () => clearTimeout(t);
  }, [time, startFakeLoading]);

  useEffect(() => {
    if (!realLoading) setState("loaded");
  }, [realLoading]);

  const value = useMemo(() => {
    switch (state) {
      case "hidden":
        return "hide";
      case "loading":
        return "show";
      case "fakeLoaded":
        return !realLoading ? "loaded" : "show";
      default:
        return "loaded";
    }
  }, [realLoading, state]);

  if (value === "show" || value === "hide")
    return (
      <div className={`${value === "hide" ? "invisible" : ""}`}>
        <LoaderComponent>{message}</LoaderComponent>
      </div>
    );

  return callback();
}
