import { Link, useMatchRoute } from "@tanstack/react-location";
import { MouseEvent } from "react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export type Tab = {
  href?: string;
  name: string;
  id: string;
  current?: boolean;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
};

export default function Tabs({
  tabs,
  action: Action,
}: {
  tabs: Tab[];
  action?: JSX.Element;
}) {
  const matchRoute = useMatchRoute();

  function isSelected(currentPath: string | undefined) {
    if (currentPath === undefined) return false;
    return matchRoute({ to: currentPath });
  }

  return (
    <div className="mb-4 flex h-12 items-center space-x-8 sm:mb-6 md:mb-8">
      <div className="grow overflow-x-auto">
        <nav className="flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={tab.href}
              className={classNames(
                isSelected(tab.href)
                  ? "cursor-default border-b border-primary text-primary"
                  : "cursor-pointer border-b border-transparent hover:border-primary hover:text-primary dark:text-white dark:hover:text-primary",
                "py-2 text-sm font-medium",
              )}
              aria-current={tab.current ? "page" : undefined}
              onClick={tab.onClick}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
      {Action && <div className="shrink-0">{Action}</div>}
    </div>
  );
}
