import React from "react";

import cn from "@/helpers/classNames";
import { ComponentProps, VariantProps, deriveClassed } from "@tw-classed/react";

import { classed } from "../../classed.config";
import LoadingSpinner from "../LoadingSpinner";
import { ButtonGroupOptions, useButtonGroupContext } from "./ButtonGroup";

export const ButtonPrimitive = classed.button(
  "shrink-0 outline-none ring-offset-white focus-within:ring-2 focus:outline-none focus:ring-2 dark:ring-offset-gray-900",
  {
    variants: {
      disabled: {
        true: "pointer-events-none cursor-default opacity-30",
      },
    },
  },
);

export const ButtonBase = classed.button(
  ButtonPrimitive,
  "relative inline-flex items-center justify-center",
  "rounded-sm border border-transparent",
  "focus:ring-offset-2",
  "text-center font-medium",
  "transition-colors",
  {
    variants: {
      variant: {
        solid: "shadow-sm",
        outline: "shadow-sm",
        ghost: "",
        link: "font-semibold underline",
      },
      colorScheme: {
        primary: "",
        secondary: "",
        danger: "",
        success: "",
      },
      size: {
        xs: "text-xs px-1.5 h-5 min-w-[20px]",
        sm: "text-xs px-3 h-8 min-w-[32px]",
        md: "text-sm px-4 h-10 min-w-[40px]",
        lg: "text-sm px-6 h-12 min-w-[48px]",
      },
      isFullWidth: {
        true: "w-full",
      },
    },
    defaultVariants: {
      colorScheme: "secondary",
      variant: "outline",
      size: "md",
    },
    compoundVariants: [
      // Solid
      {
        variant: "solid",
        colorScheme: "primary",
        className: "bg-primary text-primary-foreground hover:bg-primary/85",
      },
      {
        variant: "solid",
        colorScheme: "secondary",
        className:
          "bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-white",
      },
      {
        variant: "solid",
        colorScheme: "success",
        className: "bg-green-500 hover:bg-green-400 text-white",
      },
      {
        variant: "solid",
        colorScheme: "danger",
        className: "bg-danger hover:bg-danger/80 text-white",
      },
      // Outline
      {
        variant: "outline",
        colorScheme: "primary",
        className:
          "bg-primary/0 hover:bg-primary/10 border-primary text-primary",
      },
      {
        variant: "outline",
        colorScheme: "secondary",
        className:
          "bg-gray-100/0 hover:bg-gray-100/100 dark:bg-gray-700/0 dark:hover:bg-gray-700/75 border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-100",
      },
      {
        variant: "outline",
        colorScheme: "success",
        className:
          "bg-green-500 bg-opacity-0 hover:bg-opacity-20 border-green-500 text-green-500",
      },
      {
        variant: "outline",
        colorScheme: "danger",
        className:
          "bg-danger bg-opacity-0 hover:bg-opacity-20 border-danger text-danger",
      },
      // Ghost
      {
        variant: "ghost",
        colorScheme: "primary",
        className:
          "bg-primary/0 hover:bg-primary/15 dark:hover:bg-primary/30 text-primary dark:hover:text-blue-400",
      },
      {
        variant: "ghost",
        colorScheme: "secondary",
        className:
          "bg-gray-200 dark:bg-gray-700 bg-opacity-0 dark:bg-opacity-0 hover:bg-opacity-100 dark:hover:bg-opacity-80",
      },
      {
        variant: "ghost",
        colorScheme: "success",
        className:
          "bg-green-100 dark:bg-green-500 bg-opacity-0 dark:bg-opacity-0 hover:bg-opacity-100 dark:hover:bg-opacity-30 text-green-500 dark:hover:text-green-400",
      },
      {
        variant: "ghost",
        colorScheme: "danger",
        className:
          "bg-red-100 dark:bg-danger bg-opacity-0 dark:bg-opacity-0 hover:bg-opacity-100 dark:hover:bg-opacity-30 text-danger dark:hover:text-red-400",
      },
      // Link
      {
        variant: "link",
        colorScheme: "primary",
        className: "text-primary p-0 h-auto",
      },
      {
        variant: "link",
        colorScheme: "secondary",
        className: "p-0 h-auto",
      },
      {
        variant: "link",
        colorScheme: "success",
        className: "text-green-500 p-0 h-auto",
      },
      {
        variant: "link",
        colorScheme: "danger",
        className: "text-danger p-0 h-auto",
      },
    ],
  },
);

export type ButtonProps = ComponentProps<typeof ButtonBase> & {
  icon?: React.ReactElement;
  iconRight?: React.ReactElement;
  isLoading?: boolean;
  isDisabled?: boolean;
  loadingText?: string;
};

export function useButtonProps<T extends ButtonGroupOptions>(
  props: T,
): ButtonProps {
  const groupProps = useButtonGroupContext();
  return {
    ...groupProps,
    ...props,
  };
}

export const Button = deriveClassed<typeof ButtonBase, ButtonProps>(
  (propsArg, ref) => {
    const props = useButtonProps(propsArg);
    const {
      children,
      icon,
      iconRight,
      isLoading,
      loadingText,
      isDisabled,
      size = "md",
      ...rest
    } = props;
    return (
      <ButtonBase
        ref={ref}
        type="button"
        {...rest}
        size={size}
        disabled={isLoading || isDisabled || props.disabled}
      >
        {isLoading ? (
          <div className="flex h-full w-full items-center justify-center gap-2">
            <ButtonIcon
              size={size}
              icon={
                <LoadingSpinner
                  colorScheme={getLoadingSpinnerColorScheme(props)}
                />
              }
            />
            {loadingText && <span>{loadingText}</span>}
          </div>
        ) : (
          <>
            {icon && <ButtonIcon size={size} icon={icon} className="mr-2" />}
            {children}
            {iconRight && (
              <ButtonIcon size={size} icon={iconRight} className="ml-2" />
            )}
          </>
        )}
      </ButtonBase>
    );
  },
);

function getLoadingSpinnerColorScheme(
  props: ComponentProps<typeof ButtonBase>,
) {
  if (props.variant !== "solid") {
    return props.colorScheme;
  }
  return "white";
}

export function ButtonIcon({
  icon,
  size,
  className,
}: {
  icon: React.ReactElement;
  size: NonNullable<VariantProps<typeof ButtonBase>["size"]>;
  className?: string;
}) {
  const sizeVariants = {
    xs: "h-3.5 w-3.5",
    sm: "h-4 w-4",
    md: "h-5 w-5",
    lg: "h-6 w-6",
  };

  return React.isValidElement<any>(icon) ? (
    React.cloneElement(icon, {
      className: cn(
        "shrink-0",
        sizeVariants[size],
        className,
        icon.props.className,
      ),
      "aria-hidden": true,
      focusable: false,
    })
  ) : (
    <span />
  );
}
