import { useMemo } from "react";

import { QueryDependencyInput, usePreviewQueryQuery } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import { CodeDisplay } from "@/components/elements/CodeDisplay";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import PreviewTable from "@/components/elements/PreviewTable";
import { EditorIcon } from "@/components/icons/outline";
import { useTableSchema } from "@/pages/ModelTool/Preview/Preview";
import { useCreateDraftModel } from "@/pages/ModelTool/QueryEditor/useModelDraft";
import { useCurrentAccount } from "@/providers/account";

import { useOpenModelInTab } from "../ModelTabs";
import { QueryErrorMessages } from "./QueryErrorMessages";

export const PreviewDataModal = (props: {
  onClose: () => void;
  headline?: JSX.Element | string;
  dataReference?: QueryDependencyInput;
  modelId?: string;
  sqlCodeDisplay?: string;
  limit?: number;
}) => {
  const limit = props.limit || 100;

  const createDraftModel = useCreateDraftModel();
  const openModelInTab = useOpenModelInTab();

  const handleCreateModelFromData = async (weldTag: string) => {
    const draft = createDraftModel(
      `SELECT * FROM {{${weldTag}}} LIMIT ${limit};`,
    );

    openModelInTab({
      modelId: draft.id,
      type: "draft",
    });
  };

  const handleGoToModel = async (modelId: string) => {
    openModelInTab({
      modelId,
    });
  };

  return (
    <Modal isOpen={!!props.dataReference} onClose={props.onClose} size="xl">
      <ModalCloseButton />
      <ModalHeader>{props.headline}</ModalHeader>
      <ModalBody className="pb-6">
        <div className="relative mb-6">
          <div className="max-h-60 overflow-auto">
            <CodeDisplay
              language="sql"
              showLineNumber={false}
              code={
                props.sqlCodeDisplay ||
                `SELECT * FROM {{${props.dataReference?.weldTag}}} LIMIT ${limit}`
              }
            />
          </div>
          <Button
            className="absolute right-0 top-0 mr-1 mt-2 flex items-center space-x-1"
            size="sm"
            variant="outline"
            colorScheme="primary"
            onClick={() => {
              if (props.modelId) handleGoToModel(props.modelId);
              else
                handleCreateModelFromData(props.dataReference?.weldTag ?? "");
            }}
          >
            <EditorIcon className="h-4 w-4" />
            <div>open in editor</div>
          </Button>
        </div>
        {props.dataReference && (
          <PreviewDWData dataReference={props.dataReference} limit={limit} />
        )}
      </ModalBody>
    </Modal>
  );
};

export const PreviewDWData = (props: {
  dataReference: QueryDependencyInput;
  limit?: number;
}) => {
  const account = useCurrentAccount();
  const dataWarehouseConnectionId = account.dataWarehouseConnectionId;
  const limit = props.limit || 100;

  const previewQueryQuery = usePreviewQueryQuery({
    variables: {
      sourceConnectionId: dataWarehouseConnectionId ?? "",
      weldSql: `SELECT * FROM {{${props.dataReference.weldTag}}} LIMIT ${limit}`,
      dependencies: [props.dataReference],
    },
    fetchPolicy: "network-only",
  });

  const tableSchema = useTableSchema(
    previewQueryQuery.data?.previewQuery.response ?? [],
  );

  const hasData =
    previewQueryQuery.data &&
    previewQueryQuery.data?.previewQuery.response.length > 0;

  const dependencies = useMemo(
    () => [props.dataReference],
    [props.dataReference],
  );

  return (
    <>
      <div style={{ height: `32rem` }} className="border">
        {previewQueryQuery.loading ? (
          <div className="flex h-full w-full items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : hasData ? (
          <PreviewTable
            data={previewQueryQuery.data?.previewQuery.response}
            columns={tableSchema}
          />
        ) : (
          <div className="flex h-full items-center justify-center p-4">
            <div className="text-sm">
              <QueryErrorMessages
                dataReferences={dependencies}
                errorMsg={previewQueryQuery.error?.message}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
