import { PlanName } from "@/apollo/types";
import cn from "@/helpers/classNames";
import { StarIcon } from "@heroicons/react/20/solid";

export function WeldPlanBadge(props: { name: PlanName }) {
  return (
    <span
      className={cn(
        "inline-flex items-center rounded-sm bg-gray-600 px-1 py-0.5 align-middle text-1xs font-bold uppercase leading-none tracking-wider text-white",
        {
          "bg-blue-500": props.name === PlanName.Basic,
          "bg-green-600": props.name === PlanName.Premium,
        },
      )}
    >
      <StarIcon className="mr-0.5 h-2" />
      <span>{props.name}</span>
    </span>
  );
}
