import { Skeleton } from "@/components/elements/Skeleton";

export function CheckoutModalSkeletonLoader() {
  return (
    <div className="pb-24">
      <div className="px-8 py-5">
        <Skeleton className="h-5 w-24" />
      </div>
      <div className="grid grid-cols-2 gap-16 px-8">
        <div>
          <div className="flex gap-4 p-5">
            <Skeleton className="h-12 w-12" />
            <div className="flex items-center">
              <div>
                <Skeleton className="h-4 w-24" />
                <Skeleton className="mt-2 h-3 w-48" />
              </div>
            </div>
          </div>
          <Skeleton className="mt-8 h-4 w-32" />
          <div className="mt-4 flex flex-col gap-4">
            <Skeleton className="h-5 w-full" />
            <Skeleton className="h-5 w-full" />
            <Skeleton className="h-5 w-full" />
            <Skeleton className="h-5 w-full" />
          </div>
        </div>
        <div>
          <Skeleton className="h-4 w-40" />
          <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <div className="flex h-6 items-center">
                <Skeleton className="h-3 w-24" />
              </div>
              <div className="flex h-12 items-center">
                <Skeleton className="h-6 w-full" />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex h-6 items-center">
                <Skeleton className="h-3 w-20" />
              </div>
              <div className="flex h-12 items-center">
                <Skeleton className="h-6 w-full" />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex h-6 items-center">
                <Skeleton className="h-3 w-24" />
              </div>
              <div className="flex h-12 items-center">
                <Skeleton className="h-6 w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
