import {
  EltSyncsQuery,
  GetRawViewsQuery,
  ListModelsQuery,
  ListSyncsQuery,
  ListWebhooksQuery,
  OrchestrationJobFragment,
  useEltSyncsLazyQuery,
  useGetRawViewsLazyQuery,
  useListModelsLazyQuery,
  useListSyncsLazyQuery,
} from "@/apollo/types";
import { useCallback, useEffect, useState } from "react";

type BasicNode = {
  id: string;
  dependencies: string[];
  dependents: string[];
};

export type MissingNode = BasicNode & {
  type: "missing";
};
export type ModelNode = BasicNode & {
  type: "model";
  model: ListModelsQuery["models"][0];
};

export type OrchestrationNode = BasicNode & {
  type: "orchestration-job";
  job: OrchestrationJobFragment;
};

export type SourceStreamNode = BasicNode & {
  type: "source-stream";
  job?: OrchestrationJobFragment;
  eltSyncId: string;
};

export type RevEltNode = BasicNode & {
  type: "rev-elt";
  job?: OrchestrationJobFragment;
  revEltSync: ListSyncsQuery["syncs"][0];
};

export type RawViewNode = BasicNode & {
  type: "raw-view";
  rawView: GetRawViewsQuery["getRawViews"][0];
};

export type WebhookNode = BasicNode & {
  type: "webhook";
  webhook: ListWebhooksQuery["listWebhoooks"][0];
  job?: OrchestrationJobFragment;
};

export type EltSyncNode = BasicNode & {
  type: "elt-sync";
  eltSync: EltSyncsQuery["eltSyncs"][0];
};

export type UnSavedDraftNode = BasicNode & {
  type: "unsaved-draft";
};

export type CoreEltSyncNode = BasicNode & {
  type: "core-elt-sync";
  eltSync: EltSyncsQuery["eltSyncs"][0];
};

export type CoreModelNode = BasicNode & {
  type: "core-model";
  model: ListModelsQuery["models"][0];
};
export type StagingModelNode = BasicNode & {
  type: "staging-model";
  models: ListModelsQuery["models"];
  integrationId: string;
  schemaName: string;
};

export type CoreRawViewNode = BasicNode & {
  type: "core-raw-view";
  integrationId: string;
  schemaName: string;
  rawViews: GetRawViewsQuery["getRawViews"];
};

type NodeItem =
  | ModelNode
  | RevEltNode
  | RawViewNode
  | EltSyncNode
  | MissingNode
  | OrchestrationNode
  | SourceStreamNode
  | UnSavedDraftNode
  | CoreModelNode
  | CoreRawViewNode
  | CoreEltSyncNode
  | StagingModelNode
  | WebhookNode;

export type LineageNode = NodeItem & {
  depth: number;
  circularReferences?: string[];
};

const useLineageNodes = (): [
  (
    focusedItemId?: string,
    draftDependencies?: string[] | null, //for drafts, the dependencies can update when changing the query
  ) => Promise<NodeItem[]>,
  {
    loading: boolean;
    data?: NodeItem[];
  },
] => {
  const [fetchRawViews, { loading: loadingViews }] = useGetRawViewsLazyQuery();
  const [fetchModels, { loading: loadingModels }] = useListModelsLazyQuery();
  const [fetchRevEltSyncs, { loading: loadingRevElt }] =
    useListSyncsLazyQuery();
  const [fetchEltSyncs, { loading: loadingElt }] = useEltSyncsLazyQuery();

  const isLoadingData =
    loadingElt || loadingModels || loadingRevElt || loadingViews;

  const [nodes, setNodes] = useState<NodeItem[]>();

  const getLineageNodes = useCallback(
    async function getLineageNodes(
      focusedItemId?: string,
      draftDependencies?: string[] | null, //for drafts, the dependencies can update when changing the query
    ) {
      setNodes(undefined);

      const [
        { data: rawViewsData },
        { data: modelsData },
        { data: revEltSyncsData },
        { data: eltSyncsData },
      ] = await Promise.all([
        fetchRawViews(),
        fetchModels(),
        fetchRevEltSyncs(),
        fetchEltSyncs(),
      ]);

      const rawViews = rawViewsData?.getRawViews ?? [];
      const models = modelsData?.models ?? [];
      const syncs = revEltSyncsData?.syncs ?? [];
      const eltSyncs = eltSyncsData?.eltSyncs ?? [];

      const syncItems = eltSyncs.map((eltSync) => {
        const dependents = rawViews
          .filter((r) => r.syncId === eltSync.id)
          .map((r) => r.viewId);

        const node: EltSyncNode = {
          id: eltSync.id,
          type: "elt-sync",
          dependencies: [],
          dependents: dependents,
          eltSync,
        };
        return node;
      });
      const modelItems = models.map((model) => {
        const dependentSyncs = syncs
          .filter((s) => s.model?.id === model.id)
          .map((s) => s.id);

        const dependentsModels = models
          .filter((m) =>
            m.publishedQuery?.dependencies?.some(
              (d) => d.dwItemId === model.id,
            ),
          )
          .map((m) => m.id);

        //if the draft dependencies point to this model, add them to the dependencies
        if (draftDependencies?.includes(model.id)) {
          dependentsModels.push(model.id);
        }

        let dependencies =
          model.publishedQuery?.dependencies?.map((d) => d.dwItemId) ?? [];

        //if model is focused, override dependencies with draft dependencies if available
        const isFocusedModel = focusedItemId === model.id;
        if (isFocusedModel && draftDependencies) {
          dependencies = draftDependencies;
        }

        const node: ModelNode = {
          id: model.id,
          dependencies: [...new Set(dependencies)], //remove duplicates
          dependents: [...new Set([...dependentsModels, ...dependentSyncs])], //remove duplicates
          type: "model",
          model,
        };
        return node;
      });

      const rawViewItems = rawViews.map((rw) => {
        const dependentModels = modelItems.filter((m) =>
          m.dependencies.some((id) => id === rw.viewId),
        );
        const eltSync = eltSyncs.find((s) => s.id === rw.syncId);

        const node: RawViewNode = {
          id: rw.viewId,
          dependencies: eltSync ? [eltSync.id] : [],
          dependents: dependentModels.map((m) => m.id),
          type: "raw-view",
          rawView: rw,
        };
        return node;
      });

      const revEltSyncItems = syncs.map((revEltSync) => {
        const node: RevEltNode = {
          id: revEltSync.id,
          dependencies: revEltSync.model ? [revEltSync.model.id] : [],
          dependents: [],
          type: "rev-elt",
          revEltSync,
        };
        return node;
      });

      const unsavedDraftItem: UnSavedDraftNode[] = (() => {
        if (!focusedItemId || modelItems.some((m) => m.id === focusedItemId))
          return [];

        return [
          {
            id: focusedItemId,
            type: "unsaved-draft",
            dependencies: draftDependencies ?? [],
            dependents: [],
          },
        ];
      })();

      const allNodes = [
        ...syncItems,
        ...rawViewItems,
        ...modelItems,
        ...revEltSyncItems,
        ...unsavedDraftItem,
      ];

      setNodes(allNodes);
      return allNodes;
    },
    [fetchEltSyncs, fetchModels, fetchRawViews, fetchRevEltSyncs],
  );

  return [
    getLineageNodes,
    {
      loading: isLoadingData,
      data: nodes,
    },
  ];
};

//get full lineage nodes from a start of a focused item ID.
//generated by recursively traversing backward from the focused item and then repeating forwards
export const useLineageGraphNodes = (
  focusedId: string,
  draftDependencies?: string[], //for drafts, the dependencies can update when changing the query
) => {
  const [getLineageNodes, { loading }] = useLineageNodes();

  const [lineageNodes, setLineageNodes] = useState<LineageNode[]>([]);

  useEffect(() => {
    async function generateLineageNodes() {
      const allNodes = await getLineageNodes(focusedId, draftDependencies);
      const lineageNodes = getTreeForNode(allNodes, focusedId);
      setLineageNodes(lineageNodes);
    }

    generateLineageNodes();
  }, [getLineageNodes, focusedId, draftDependencies]);

  return {
    lineageNodes,
    loading,
  };
};

const getTreeForNode = (
  allNodes: NodeItem[],
  focusedItemId: string,
): LineageNode[] => {
  const selectedNodeItem = allNodes.find((n) => n.id === focusedItemId);
  if (!selectedNodeItem) return [];

  const dependencies = dfTraverseDependencies(
    allNodes,
    [],
    selectedNodeItem,
  ).filter((n) => n.id !== focusedItemId); //remove the selected node from the dag to avoid having it twice

  const arrangedDependencies = reArrangeDependencies(dependencies);

  return dfTraverseDependents(allNodes, arrangedDependencies, selectedNodeItem);
};

export function useGetLineageDependencies() {
  const [getLineageNodes] = useLineageNodes();

  return useCallback(
    async (focusedItemId?: string, draftDependencies?: string[]) => {
      const allNodes = await getLineageNodes(focusedItemId, draftDependencies);
      const selectedNodeItem = allNodes.find((n) => n.id === focusedItemId);
      if (!selectedNodeItem) {
        return [];
      }
      const dependencies = dfTraverseDependencies(
        allNodes,
        [],
        selectedNodeItem,
      ).filter((n) => n.id !== focusedItemId);
      return dependencies;
    },
    [getLineageNodes],
  );
}

//traverse the graph from a starting point
const dfTraverseDependents = (
  allNodes: NodeItem[],
  prevNodes: LineageNode[], //previous in current tree!
  current: NodeItem,
  depth = 0,
): LineageNode[] => {
  const currentNode: LineageNode = {
    ...current,
    depth,
  };

  //check for circular dependencies
  const circularDependents = prevNodes
    .map((d) => d.id)
    .filter((id) => currentNode.dependents.includes(id));
  //if found circular dependent, mark it and remove as dependent:
  if (circularDependents.length > 0) {
    currentNode.circularReferences = circularDependents;
    currentNode.dependents = currentNode.dependents.filter(
      (id) => !circularDependents.includes(id),
    );
  }

  //push the nodes to the graph
  const updatedNodes = [...prevNodes, currentNode];

  const nextNodes = allNodes.filter((n) =>
    currentNode.dependents.includes(n.id),
  );

  //if no more nodes to traverse, return
  //(base case for recursive function)
  if (!nextNodes.length) {
    return updatedNodes;
  }

  //return the tree in a flat list without duplicates
  return flattenBranches(
    nextNodes.map((nextNode) => {
      //traverse each node recursively to generate a branch
      return dfTraverseDependents(allNodes, updatedNodes, nextNode, depth + 1);
    }),
  );
};

const dfTraverseDependencies = (
  allNodes: NodeItem[],
  prevNodes: LineageNode[], //previous reachable nodes in current branch of graph.
  current: NodeItem,
  depth = 0,
): LineageNode[] => {
  const currentNode: LineageNode = {
    ...current,
    depth: depth,
  };

  //check for circular dependencies on current node:
  const circularDependencies = prevNodes
    .map((d) => d.id)
    .filter((id) => currentNode.dependencies.includes(id));

  if (circularDependencies.length > 0) {
    currentNode.circularReferences = circularDependencies;
    currentNode.dependencies = currentNode.dependencies.filter(
      (id) => !circularDependencies.includes(id),
    );
  }

  //push the current Node to the graph
  const updatedNodes = [...prevNodes, currentNode];

  //get next nodes
  const nextNodes: NodeItem[] = [];
  currentNode.dependencies.forEach((id) => {
    const nextNode = allNodes.find((n) => n.id === id);
    if (nextNode) {
      nextNodes.push(nextNode);
    } else {
      nextNodes.push({
        id,
        type: "missing",
        dependencies: [],
        dependents: [current.id],
      });
    }
  });

  //if no more nodes to traverse, return
  //(base case for recursive function)
  if (!nextNodes.length) {
    return updatedNodes;
  }

  return flattenBranches(
    nextNodes.map((nextNode) =>
      dfTraverseDependencies(allNodes, updatedNodes, nextNode, depth - 1),
    ),
  );
};

//combine multiple branches (result of a traverse) into a flat list
const flattenBranches = (branches: LineageNode[][]): LineageNode[] => {
  const traversedNodes = new Map<string, LineageNode>();

  branches.forEach((branch) => {
    branch.forEach((node) => {
      //Make sure to not include duplicates
      const existingNode = traversedNodes.get(node.id);
      if (existingNode) {
        //little hack to work with both positive and negative depths:
        const deepestDepth =
          Math.abs(existingNode.depth) > Math.abs(node.depth)
            ? existingNode.depth
            : node.depth;

        existingNode.depth = deepestDepth;
      } else {
        traversedNodes.set(node.id, node);
      }
    });
  });
  return [...traversedNodes.values()];
};

const reArrangeDependencies = (nodes: LineageNode[]) => {
  const deepest = nodes.reduce((prev, cur) => {
    if (cur.depth < prev) return cur.depth;
    return prev;
  }, 0);

  //Move the syncs and raw views to the "deepest" layer, to get a more clean graph:
  return nodes.map((n) => {
    return {
      ...n,
      depth:
        n.type === "elt-sync"
          ? deepest
          : n.type === "raw-view" && n.depth > deepest
            ? deepest + 1
            : n.depth,
    };
  });
};

//start from multiple starting nodes and traverse the graph:
export const dfTraverseDependentsFromMultiple = (
  allNodes: NodeItem[],
  startNodes: NodeItem[], //all the starting nodes
) => {
  return flattenBranches(
    startNodes.map((startNode) => {
      //traverse each node recursively to generate a branch
      return dfTraverseDependents(allNodes, [], startNode, 1);
    }),
  );
};
