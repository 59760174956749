import { useCallback, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  MaterializationType,
  ModelFolder,
  OrchestrationSchedulerType,
} from "@/apollo/types";
import { Alert, AlertIcon } from "@/components/elements/Alert";
import {
  ButtonGroup,
  PrimaryButton,
  SecondaryButton,
} from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import FieldLabel from "@/components/primitives/InputLabel";
import { Input } from "@/components/primitives/input";

import { useModelEditorState } from "../ModelEditorStore";
import FolderSelecter from "../components/FolderSelecter";
import { ConfigureMaterializationType } from "../materialization/MaterializationSettings";
import { ValidationErrorDisplay } from "../utils/dwNamingValidation";

type FormType = {
  name: string;
  selectedBaseFolder: string;
  selectedSubFolder: string;
};

export const SaveModelModal: React.FC<{
  onSave: (
    name: string,
    folderId?: string,
    workflowId?: string,
  ) => Promise<any>;
  onCancel: () => void;
  show: boolean;
  defaultFolder?: Pick<ModelFolder, "id" | "name" | "parentId"> | null;
  loading: boolean;
  defaultName?: string;
  mode: "edit_draft_name" | "publish" | "move";
}> = ({ onSave, ...props }) => {
  const state = useModelEditorState();

  const form = useForm<FormType>({
    defaultValues: {
      selectedBaseFolder:
        props.defaultFolder?.parentId ?? props.defaultFolder?.id,
      selectedSubFolder:
        (!!props.defaultFolder?.parentId && props.defaultFolder.id) ||
        undefined,
      name: props.defaultName,
    },
    mode: "onChange",
  });

  const handleSave: SubmitHandler<FormType> = useCallback(
    async (values) => {
      if (props.loading) return;
      if (props.mode === "edit_draft_name") {
        await onSave(values.name);
        return;
      }

      const folder = values.selectedSubFolder || values.selectedBaseFolder;
      if (!folder) return;

      await onSave(values.name, folder);
    },
    [onSave, props.loading, props.mode],
  );

  const isValid = useMemo(() => {
    const missingMaterializationSchedule =
      state.materializationType === MaterializationType.Table &&
      state.orchestrationScheduler === OrchestrationSchedulerType.Local &&
      !state.materializationSchedule;

    return form.formState.isValid && !missingMaterializationSchedule;
  }, [
    form.formState.isValid,
    state.materializationSchedule,
    state.materializationType,
    state.orchestrationScheduler,
  ]);

  /* 
    FIXME: ButtonGroup is a temporary hack to reset ButtonGroup context props inside this modal.  
    Due to the SaveModelModal being rendered inside the PublishModel component, which
    in turn is rendered inside a ButtonGroup the ButtonGroup context props are affecting
    all buttons inside the modal as well.
  */
  return (
    <ButtonGroup>
      <Modal isOpen={props.show} onClose={props.onCancel} size="lg">
        <ModalCloseButton />
        <ModalHeader>Save Model</ModalHeader>
        <ModalBody className="flex flex-col gap-8">
          <div>
            <FieldLabel>Model name</FieldLabel>
            <Input
              autoFocus
              autoComplete="off"
              {...form.register("name", {
                required: true,
                validate: {
                  invalidCharacters: (value) => /^[\w_ -]*$/.test(value),
                  hasNoDoubleUnderscore: (value) => !/__/.test(value),
                },
                onChange: (e) => {
                  form.setValue("name", e.target.value.replaceAll(" ", "_"));
                  form.trigger("name");
                },
              })}
            />
            {form.formState.errors.name && (
              <ValidationErrorDisplay error={form.formState.errors.name} />
            )}
          </div>

          {props.mode !== "edit_draft_name" && (
            <>
              <FolderSelecter form={form} />
              <div className="w-full">
                {props.mode === "publish" && <ConfigureMaterializationType />}
              </div>
            </>
          )}
          {props.mode === "move" && (
            <Alert status="warning">
              <AlertIcon />
              Moving/renaming a published model will change where it is saved in
              the datawarehouse. If you're referencing the model with external
              tools outside WELD, you might need update these.
            </Alert>
          )}
        </ModalBody>
        <ModalFooter className="flex-row-reverse justify-start gap-4">
          <PrimaryButton
            onClick={form.handleSubmit(handleSave)}
            className="flex-1"
            isLoading={props.loading}
            disabled={!isValid}
          >
            {props.mode === "edit_draft_name" ? "Save" : "Publish"}
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              form.reset();
              props.onCancel();
            }}
            className="flex-1"
          >
            Cancel
          </SecondaryButton>
        </ModalFooter>
      </Modal>
    </ButtonGroup>
  );
};
