import FormFactory from "@/components/modules/FormFactory/FormFactory";
import { useMountEffect } from "@/hooks/useMountEffect";
import { FormType, hasError } from "@/integrations";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

const formToFilteredArray = <T,>(
  form: FormType,
  filterFn: (form: FormType) => boolean,
  mapFn: (form: FormType) => T,
): T[] => {
  if (!form) return [];

  const filteredFields: T[] = [];
  if (filterFn(form)) filteredFields.push(mapFn(form));

  if ("fields" in form)
    return (
      (form.fields as FormType[])?.reduce<T[]>(
        (prev, field) => [
          ...prev,
          ...formToFilteredArray<T>(field, filterFn, mapFn),
        ],
        filteredFields,
      ) || []
    );

  return filteredFields;
};

export const getAllRequiredFormFieldNames = (form: FormType): string[] =>
  formToFilteredArray<string>(
    form,
    (form) => "required" in form && form.required,
    (form) => ("name" in form ? (form.name ?? "") : ""),
  ).filter(Boolean);

const findDependentFields = (fieldName: string, form: FormType): FormType[] =>
  formToFilteredArray<FormType>(
    form,
    (form) =>
      "dependsOnFieldsWithNames" in form &&
      !!form.dependsOnFieldsWithNames?.includes(fieldName),
    (form) => form,
  );

const hasAnyError = (form: FormType): boolean =>
  formToFilteredArray<FormType>(form, hasError, (form) => form).length > 0;

export default function Custom(props: {
  onChange: (setting: { key: string; value: any }) => void;
  form: FormType;
  fetchSettings: (formValues: Record<string, any>) => any;
  onChangeValid: (isValid: boolean) => void;
  initialValues: Record<string, any>;
  refetchSettingsOnLoad?: boolean;
}) {
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: props.initialValues,
  });

  const { fetchSettings } = props;
  const { getValues, setValue } = methods;

  const handleFetchServerDataDebounced = useMemo(
    () => debounce(() => fetchSettings(getValues()), 400),
    [fetchSettings, getValues],
  );

  const handleFieldChanged = useCallback(
    (name: string) => {
      const dependentFields = findDependentFields(name, props.form);

      dependentFields.forEach(async (field) => {
        if (!field.name) return;
        // reset fields
        if (getValues(field.name)) {
          setValue(field.name, undefined);
        }
      });
    },
    [getValues, setValue, props.form],
  );

  useMountEffect(() => {
    // if this component mounts, start out by making custom settings invalid
    props.onChangeValid(false);
    if (props.refetchSettingsOnLoad) {
      handleFetchServerDataDebounced();
    }
  });

  useEffect(() => {
    props.onChangeValid(methods.formState.isValid && !hasAnyError(props.form));
  }, [methods.formState.isValid, props]);

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (!name) return;

      if (shouldFetchConnectionSettings(props.form, name)) {
        handleFetchServerDataDebounced();
      }
      methods.trigger().then(() => {
        /**
         * Normally, input field name is primitive `account-id`
         * and we find it within a form value with `value[name]`:
         * `{ account-id: "1", account-key: "2"} => "1"`.
         *
         * Repeaters use standard HTTP naming scheme for lists
         * with input names like `accounts[0][id]`. Whereas the form value
         * will be properly nested: `{accounts: [ {id: "marko"} ]}`.
         */
        const [keyName] = name.split("[", 1);
        props.onChange({ key: keyName, value: value[keyName] });
      });
      handleFieldChanged(name);
    });

    return () => subscription.unsubscribe();
  }, [handleFetchServerDataDebounced, handleFieldChanged, methods, props]);

  return (
    <FormProvider {...methods}>
      <FormFactory form={props.form} />
    </FormProvider>
  );
}

/**
 * This is a temporary solution to prevent fetching connection settings
 * for certain fields. The current setup is not very flexible and will
 * fetch connection settings for every change in the form.
 */
function shouldFetchConnectionSettings(form: FormType, fieldName: string) {
  if (form.type === "google-ads-form" && fieldName === "custom_reports") {
    return false;
  }
  return true;
}
