import { Link, useRouter } from "@tanstack/react-location";
import classNames from "@/helpers/classNames";
import { LocationGenerics } from "routes";

export default function Breadcrumbs() {
  const matches = useRouter<LocationGenerics>().state.matches;

  return (
    <div className="flex shrink-0 items-center">
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex h-6 items-center gap-3 overflow-hidden text-lg">
          {matches
            // skip routes that don't have a breadcrumb, like is the case of our '/' route
            .filter((match) => match.route.meta?.breadcrumb)
            .map((match, i, arr) => (
              <li key={match.pathname} className="flex items-center gap-3">
                <Link
                  to={match.pathname}
                  search
                  className={classNames(
                    "whitespace-nowrap focus:outline-none",
                    "text-black hover:underline dark:text-white dark:hover:text-gray-300",
                    i < arr.length - 1 && "opacity-50",
                  )}
                >
                  {match.route.meta!.breadcrumb(match.params)}
                </Link>
                {i < arr.length - 1 && <Slash />}
              </li>
            ))}
        </ol>
      </nav>
    </div>
  );
}

const Slash = () => (
  <svg
    className="h-5 w-5 shrink-0 fill-current text-gray-300"
    viewBox="0 0 20 20"
    aria-hidden="true"
  >
    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
  </svg>
);
