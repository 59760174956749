import { ChatRole } from "@/apollo/types";
import { ReactComponent as EdBlackBoxIcon } from "@/assets/ed-black-box.svg";
import classNames from "@/helpers/classNames";

export const DialogBox = (props: {
  role: ChatRole;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={classNames(
        "flex items-start space-x-3",
        props.role === ChatRole.Assistant ? "justify-start" : "justify-end",
      )}
    >
      {props.role === ChatRole.Assistant && (
        <div className="py-3">
          <EdBlackBoxIcon className="block h-6 w-6 flex-none" />
        </div>
      )}

      <TextBlock role={props.role}>{props.children}</TextBlock>
    </div>
  );
};

const TextBlock = (props: { children: React.ReactNode; role: ChatRole }) => {
  return (
    <div
      className={classNames(
        "inline-block overflow-hidden truncate whitespace-pre-line rounded-xl border border-gray-100 p-3 dark:border-gray-700",
        props.role === ChatRole.Assistant
          ? "bg-gray-100 dark:bg-gray-700 dark:text-gray-100"
          : "bg-white dark:bg-gray-800 dark:text-gray-100",
      )}
    >
      <div className="text-sm">{props.children}</div>
    </div>
  );
};
