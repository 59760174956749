import { useEffect, useMemo, useState } from "react";

import { Connection } from "@/apollo/types";
import { SecondaryButton } from "@/components/elements/Button";
import { LoadingOverlay } from "@/components/elements/LoadingComponents";
import Select from "@/components/elements/Select";
import FieldLabel from "@/components/primitives/InputLabel";
import { useDisclosure } from "@/hooks/useDisclosure";
import { IntegrationLogo } from "@/integrations";
import { NewConnectionSlideOver } from "@/pages/Connections/NewConnectionSlideOver";
import { PlusIcon } from "@heroicons/react/20/solid";

import { useGoogleSheetsDestinations } from "./hooks/useGoogleSheetsDestinations";

const NEW_CONNECTOR_SELECTION_ID = "new_connector";

export const GoogleSheetsConnectionSelector = (props: {
  onDestinationSelected?: (destination?: Connection) => void;
}) => {
  const [currentSelection, setCurrentSelection] = useState<
    Connection | undefined
  >();

  useEffect(() => {
    props.onDestinationSelected?.(currentSelection);
  }, [currentSelection, props]);

  const { connections: destinations, loading: destinationsLoading } =
    useGoogleSheetsDestinations();

  useEffect(() => {
    if (destinations.length > 0) {
      setCurrentSelection((prev) => prev ?? (destinations[0] as Connection));
    }
  }, [destinations]);

  const addConnectionSlideOverDisclosure = useDisclosure();

  const options = useMemo(() => {
    return [
      ...destinations,
      { id: NEW_CONNECTOR_SELECTION_ID, label: "Add new connector" },
    ];
  }, [destinations]);

  return (
    <>
      {destinationsLoading && <LoadingOverlay className="z-20" />}
      <div className="flex items-center space-x-2">
        <div className="grow">
          <FieldLabel>Connection *</FieldLabel>
          {destinations.length > 0 ? (
            <Select
              autoFocus
              placeholder="Select an existing connector..."
              value={currentSelection}
              iconParser={(item: Connection) => {
                return item.id === NEW_CONNECTOR_SELECTION_ID ? (
                  <PlusIcon className="h-6 dark:text-white" />
                ) : (
                  <IntegrationLogo id={item.integrationId} className="h-8" />
                );
              }}
              options={options}
              onChange={(item?: Connection) => {
                if (item?.id === NEW_CONNECTOR_SELECTION_ID) {
                  addConnectionSlideOverDisclosure.onOpen();
                } else {
                  setCurrentSelection(item);
                }
              }}
              autoSelectOnSingleOption
            />
          ) : (
            <SecondaryButton
              className="w-full"
              tabIndex={-1}
              icon={<PlusIcon />}
              onClick={() => {
                addConnectionSlideOverDisclosure.onOpen();
              }}
            >
              Connect to Google Sheets
            </SecondaryButton>
          )}
        </div>
      </div>

      <NewConnectionSlideOver
        show={addConnectionSlideOverDisclosure.isOpen}
        onClose={addConnectionSlideOverDisclosure.onClose}
        integrationId="google-sheets"
        abilityFilter="ReverseEltDestinationConnection"
        onConnectionAdded={(connection) => {
          setCurrentSelection(connection as Connection);
        }}
      />
    </>
  );
};
