import { EltSyncQuery, EltSyncsQuery } from "@/apollo/types";
import { useMemo } from "react";

import {
  getSourceStreamJobIds,
  useFilteredNotifications,
} from "./filterNotifications";

/**
 * Returns a flat list of all the notifications that may affect the EltSync
 * plus all the notifications belonging to each of its streams.
 *
 * This includes:
 * - Account notifications
 * - Connector notifications
 * - Sync notifications
 * - Stream notifications
 */
export const useEltSyncNotifications = (
  eltSyncs:
    | EltSyncsQuery["eltSyncs"]
    | EltSyncQuery["eltSync"][]
    | EltSyncQuery["eltSync"],
) => {
  const notificationsFilters = useMemo(() => {
    const list = Array.isArray(eltSyncs) ? eltSyncs : [eltSyncs];
    const sourceStreamIds = list.flatMap((sync) => {
      return getSourceStreamJobIds({
        eltSyncId: sync.id,
        sourceIntegrationId: sync.sourceIntegrationId,
        sourceStreamNames: sync.sourceStreams.map((stream) => stream.name),
      });
    });

    return {
      connectionIds: list.map((eltSync) => eltSync.sourceConnectionId),
      syncIds: list.map((eltSync) => eltSync.id),
      scheduledJobIds: sourceStreamIds,
    };
  }, [eltSyncs]);

  return useFilteredNotifications(notificationsFilters);
};
