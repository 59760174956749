import { useFlags } from "launchdarkly-react-client-sdk";
import { ComponentProps } from "react";

import { PlanName } from "@/apollo/types";
import { FeatureGuard } from "@/components/elements/FeatureGuard";
import { Heading, TextMuted } from "@/components/elements/Typography";
import { UpgradeNowButton } from "@/components/modules/UpgradeNowButton";
import { formatPlanName } from "@/features/billing";
import { useCurrentPlan } from "@/features/subscription";
import cn from "@/helpers/classNames";

export function useOrchestrationFeatureGuard() {
  const { features, loading } = useCurrentPlan();
  const { orchestration: isOrchestrationFlagEnabled } = useFlags();
  const isEnabled = features.orchestrationEnabled || isOrchestrationFlagEnabled;
  return {
    isEnabled,
    isLoading: loading,
  };
}

export function OrchestrationFeatureGuard(
  props: {
    children: React.ReactNode;
  } & Pick<ComponentProps<typeof FeatureGuard>, "fallback">,
) {
  const { isEnabled, isLoading } = useOrchestrationFeatureGuard();
  return (
    <FeatureGuard test={isLoading || isEnabled} fallback={props.fallback}>
      {props.children}
    </FeatureGuard>
  );
}

export function OrchestrationTeaser(props: ComponentProps<"div">) {
  return (
    <div {...props} className={cn("flex flex-col gap-4", props.className)}>
      <Heading className="text-center text-xl">
        Organize your workflows with Orchestration
      </Heading>
      <TextMuted className="text-sm leading-snug">
        Orchestration enables efficient management of data source syncs, SQL
        models, and Reverse ETL syncs, ensuring reliable and orderly data
        movement and processing.{" "}
        <a
          href="https://weld.app/docs/workspace/orchestration"
          target="_blank"
          rel="noreferrer"
          className="text-blue-500 underline"
        >
          Learn more
        </a>{" "}
        about Orchestration.
      </TextMuted>
      <div className="flex w-full flex-col gap-4 text-center">
        <span>
          Orchestration is available on the{" "}
          <strong>{formatPlanName(PlanName.Premium)}</strong> and{" "}
          <strong>{formatPlanName(PlanName.Business)}</strong> plans.
        </span>
        <div>
          <UpgradeNowButton source="feature guard - orchestration" />
        </div>
      </div>
    </div>
  );
}
