import React, { PropsWithChildren } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

type Props = {
  headline: string;
  icon?: JSX.Element;
};

const FormInfoBox = (props: PropsWithChildren<Props>) => {
  return (
    <div className="mb-4 mt-6 rounded-sm border bg-gray-50 py-4 pl-3 pr-5 dark:border-gray-600 dark:bg-gray-700">
      <div className="flex items-center space-x-3">
        {props.icon || (
          <InformationCircleIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        )}
        <div className="font-medium">{props.headline}</div>
      </div>
      <div className="pl-8 pt-1 text-sm">{props.children}</div>
    </div>
  );
};

export default FormInfoBox;
