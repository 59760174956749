import WeldIconSmall from "@/components/icons/WeldIconSmall";
import classNames from "@/helpers/classNames";

import AppearTransition from "./AppearTransition";
import LoadingSpinner from "./LoadingSpinner";

export function LoadingTable({ children }: React.PropsWithChildren<{}>) {
  return (
    <AppearTransition>
      <div className="flex flex-col items-center justify-center bg-white text-gray-500 dark:bg-gray-800">
        <WeldIconSmall className="w-6 animate-spin-slow dark:text-white" />
        <span>{children}</span>
      </div>
    </AppearTransition>
  );
}

export function LoadingFull({ children }: React.PropsWithChildren<{}>) {
  return (
    <AppearTransition>
      <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-500">
        <WeldIconSmall className="w-6 animate-spin-slow" />
        <span>{children}</span>
      </div>
    </AppearTransition>
  );
}

export const LoadingOverlay = (props: { className?: string }) => {
  return (
    <div
      className={classNames(
        "absolute inset-0 flex items-center justify-center bg-gray-200 opacity-75 dark:bg-gray-700",
        props.className,
      )}
    >
      <LoadingSpinner className="h-6 w-6 dark:text-white" />
    </div>
  );
};
