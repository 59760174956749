import React, { useEffect, useState } from "react";

import classNames from "@/helpers/classNames";
import { isWindows } from "@/helpers/isWindows";

type Props = {};

const AutoSaveNotification = (props: Props) => {
  const [show, setShow] = useState(false);
  const [hasDisplayed, setHasDisplayed] = useState(false);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      const modifier = isWindows() ? e.ctrlKey : e.metaKey;
      if (modifier && e.key === "s") {
        e.preventDefault();
        if (hasDisplayed) return;
        setShow(true);
        setHasDisplayed(true);
        setTimeout(() => setShow(false), 3000);
      }
    };
    window.addEventListener("keydown", listener);
    return () => window.removeEventListener("keydown", listener);
  }, [hasDisplayed]);

  return (
    <div
      className={classNames(
        "absolute bottom-0 left-0 z-30 ml-4 border bg-white px-3 py-2 transition-all dark:border-gray-700 dark:bg-gray-800 dark:text-white",
        show ? "mb-4 opacity-100" : "-mb-12 opacity-0",
      )}
    >
      <div className="flex items-center space-x-2 text-sm">
        <span>👋</span>
        <span>WELD autosaves your changes</span>
      </div>
    </div>
  );
};

export default AutoSaveNotification;
