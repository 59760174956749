import { atom, useAtom } from "jotai";
import { useCallback } from "react";

export type EditorState = "model" | "draft" | "dashboard" | "tile" | "empty";

type CurrentEditorState = {
  initialized: boolean;
  currentItemId: string;
  currentItemName: string;
  currentEditorState: EditorState;
  dashboardId?: string;
  tileId?: string;
  //todo: move editor state not related to models to this store, eg:
  // newItem: {
  //   itemType: "folder" | "model";
  //   folderId?: string;
  // } | null;
  // openFolders: string[];
};

const initialState: CurrentEditorState = {
  initialized: false,
  currentItemId: "",
  currentItemName: "",
  currentEditorState: "empty",
  // newItem: null,
  // openFolders: [BASE_FOLDER_PUBLISHED_MODELS, BASE_FOLDER_UNPUBLISHED_MODELS],
};

export type EditorActions =
  | {
      type: "initialize";
      payload?: {
        currentItemId: string;
        currentItemName: string;
        currentEditorState: EditorState;
        dashboardId?: string;
        tileId?: string;
      };
    }
  | {
      type: "set_empty";
    };

const currentEditorStateAtom = atom(initialState);
const useEditorStateAtom = () => useAtom(currentEditorStateAtom);

export const useEditorState = (): [
  CurrentEditorState,
  (action: EditorActions) => void,
] => {
  const [state, setState] = useEditorStateAtom();

  const dispatch = useCallback(
    (action: EditorActions) => {
      setState((state) => {
        switch (action.type) {
          case "initialize":
            return { ...state, initialized: true, ...action.payload };
          case "set_empty":
            return {
              ...state,
              initialized: false,
              currentItemId: "",
              currentItemName: "",
              currentEditorState: "empty",
            };
          default:
            return state;
        }
      });
    },
    [setState],
  );

  return [state, dispatch];
};
