import { useCallback, useMemo } from "react";

import {
  CurrencyOption,
  PlanName,
  useProductPriceByLookupKeyLazyQuery,
  useProductPriceByLookupKeyQuery,
  useProductPriceQuery,
} from "@/apollo/types";

function getTiersForCurrency(options: CurrencyOption[], currency: string) {
  return options.find((x) => x.currency === currency)?.tiers ?? undefined;
}

export function usePrice(options: {
  priceId: string | undefined;
  currency: string;
  planName?: PlanName;
}) {
  const { data, loading } = useProductPriceQuery({
    variables: {
      priceId: options.priceId ?? "",
      currency: options.currency,
    },
    skip: options.priceId == null,
  });

  const price = useMemo(() => {
    if (data?.getProductPrice === undefined) {
      return undefined;
    }
    const price = data.getProductPrice;
    let tiers = getTiersForCurrency(price.currencyOptions, options.currency);
    return {
      ...price,
      currencyOptions: price.currencyOptions.map((option) =>
        option.currency === options.currency ? { ...option, tiers } : option,
      ),
      tiers,
    };
  }, [data?.getProductPrice, options.currency]);

  if (price === undefined) {
    return {
      price: undefined,
      loading,
    };
  }

  return {
    price,
    loading,
  };
}

export function usePriceByLookupKey(options: {
  lookupKey: string | undefined;
  currency: string;
}) {
  const { data, loading } = useProductPriceByLookupKeyQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      lookupKey: options.lookupKey ?? "",
      currency: options.currency,
    },
    skip: options.lookupKey == null,
  });

  const price = useMemo(() => {
    if (data?.getProductPriceByLookupKey === undefined) {
      return undefined;
    }
    const price = data.getProductPriceByLookupKey;
    let tiers = getTiersForCurrency(price.currencyOptions, options.currency);
    return {
      ...price,
      tiers,
    };
  }, [data?.getProductPriceByLookupKey, options.currency]);

  if (price === undefined) {
    return {
      price: undefined,
      loading,
    };
  }

  return {
    price,
    loading,
  };
}

export function useGetPriceByLookupKey() {
  const [fetchProductPrice] = useProductPriceByLookupKeyLazyQuery();
  return useCallback(
    async (options: { lookupKey: string; currency: string }) => {
      const { data } = await fetchProductPrice({
        variables: {
          lookupKey: options.lookupKey,
          currency: options.currency,
        },
      });
      if (data === undefined) {
        return undefined;
      }
      const price = {
        ...data.getProductPriceByLookupKey,
        tiers: getTiersForCurrency(
          data.getProductPriceByLookupKey.currencyOptions,
          options.currency,
        ),
      };
      return price;
    },
    [fetchProductPrice],
  );
}
