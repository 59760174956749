import "./lineage.css";

import { Button } from "@/components/elements/Button";
import { LoadingOverlay } from "@/components/elements/LoadingComponents";
import { nodeTypes } from "@/components/elements/reactflow/CustomReactFlowNodes";
import { atom, useAtom } from "jotai";
import { useDarkMode } from "@/providers/DarkModeProvider";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, {
  Background,
  Edge,
  Node,
  Position,
  ReactFlowProvider,
  Viewport,
  getConnectedEdges,
  useReactFlow,
  useViewport,
} from "react-flow-renderer";

import { LineageElement } from "./components/LineageComponents";
import { LineageNode, useLineageGraphNodes } from "./useLineageDag";

type LineageProps = {
  focusedId: string;
  dependencies?: string[];
};

const DefaultViewport = { x: 250, y: 200, zoom: 1 };
const LineageAtom = atom<Viewport>(DefaultViewport);

const Lineage = (props: LineageProps) => {
  return (
    <ReactFlowProvider>
      <LineageFlow {...props} />
    </ReactFlowProvider>
  );
};

const LineageFlow = (props: LineageProps) => {
  const { nodeElements, edgeElements, loading } =
    useLineageGraphElements(props);
  const { isDarkModeEnabled } = useDarkMode();

  const containerRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useAtom(LineageAtom);
  const viewPort = useViewport();
  useEffect(() => {
    setPosition(viewPort);
  }, [setPosition, viewPort]);

  const isOutOfBounds = useOutOfBoundsDetecter(nodeElements, containerRef);

  const focusIdRef = useRef<string>(props.focusedId);
  useEffect(() => {
    if (focusIdRef.current === props.focusedId) return;
    focusIdRef.current = props.focusedId;

    if (isOutOfBounds) {
      setPosition(DefaultViewport);
    }
  }, [isOutOfBounds, props.focusedId, setPosition]);

  //Give the element a new key to force rerendering everytime the lineage changes
  const key = useMemo(() => {
    return nodeElements.reduce((prev, cur) => prev + cur.id, "");
  }, [nodeElements]);

  const [highlighedEdges, setHighlightedEdges] = useState<Edge[]>([]);

  const styledEdges: Edge[] = edgeElements.map((e) =>
    highlighedEdges.some((h) => h.id === e.id)
      ? {
          ...e,
          style: {
            stroke: "#0070F4",
            strokeWidth: 4,
            opacity: 0.7,
          },
          zIndex: 1000,
          className: "stroke-current",
        }
      : e,
  );

  const instance = useReactFlow();
  return (
    <div className="relative h-full bg-gray-50">
      <div
        ref={containerRef}
        className="absolute inset-0 h-full w-full bg-white dark:bg-gray-800"
      >
        <ReactFlow
          key={key}
          nodesDraggable={false}
          panOnScroll
          nodesConnectable={false}
          nodes={nodeElements}
          edges={styledEdges}
          nodeTypes={nodeTypes}
          snapGrid={[18, 18]}
          minZoom={0.1}
          defaultZoom={position.zoom}
          defaultPosition={[position.x, position.y]}
          onNodeMouseEnter={(_event, node) => {
            const edges = getConnectedEdges([node], edgeElements);
            setHighlightedEdges(edges);
          }}
          onNodeMouseLeave={() => setHighlightedEdges([])}
          fitView
        >
          <Background size={0.7} color={isDarkModeEnabled ? "#555" : "#ddd"} />
        </ReactFlow>
        <div className="absolute bottom-1 left-1 z-10">
          <Button
            size="sm"
            colorScheme="secondary"
            variant="outline"
            onClick={() => {
              instance.setViewport(DefaultViewport);
            }}
          >
            re-center
          </Button>
        </div>
      </div>
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default Lineage;

const useLineageGraphElements = (props: LineageProps) => {
  const { lineageNodes, loading } = useLineageGraphNodes(
    props.focusedId,
    props.dependencies,
  );

  return useMemo(() => {
    const { edgeElements, nodeElements } =
      getReactFlowLineageElements(lineageNodes);
    return {
      nodeElements,
      edgeElements,
      loading,
    };
  }, [lineageNodes, loading]);
};

type Settings = {
  spaceX?: number;
  spaceY?: number;
};

export function getReactFlowLineageElements(
  nodes: LineageNode[],
  settings?: Settings,
) {
  let nodeElements: Node[] = [];
  let edgeElements: Edge[] = [];

  //a map to keep track of how many was added to the lineage
  //stage: nr of nodes added
  const addedCount = new Map<number, number>();

  //shift the depth of the node to always draft from 0 and up
  const maxDepth = nodes.reduce((prev, cur) => {
    if (cur.depth < prev) return cur.depth;
    return prev;
  }, 0);

  nodes.forEach((node) => {
    const totalStageItems = nodes.filter((n) => n.depth === node.depth).length;

    //keep count of how many items have been added to the stage previously
    const number = addedCount.get(node.depth) || 0;
    addedCount.set(node.depth, number + 1);

    //add the element:
    const element = getElement(
      node,
      number,
      totalStageItems,
      maxDepth,
      settings?.spaceX,
      settings?.spaceY,
    );
    nodeElements.push(element);
    // lineageBoundry.x = Math.max(lineageBoundry.x, element.position.x);
    // lineageBoundry.y = Math.max(lineageBoundry.y, element.position.y);

    //draw lines
    node.dependencies.forEach((id) => {
      edgeElements.push(getConnection(id, node.id));
    });
  });

  return { nodeElements, edgeElements };
}

const getElement = (
  item: LineageNode,
  itemNr: number,
  totalSiblings: number,
  maxDepth = 0, //shift the depth of the node to always draft from 0 and up
  spaceX: number = 260,
  spaceY: number = 100,
): Node => {
  //let marginTop = totalSiblings * 50; //if we want to center the nodes

  return {
    id: item.id,
    type: getNodeType(item),
    data: {
      children: <LineageElement item={item} />,
    },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    position: {
      x: spaceX * item.depth - maxDepth * 260,
      y: itemNr * spaceY,
    },
  };
};

const getConnection = (sourceId: string, targetId: string): Edge => {
  return {
    id: `edge-${sourceId}-${targetId}`,
    source: sourceId,
    target: targetId,
    type: "smoothstep",
  };
};

type LineageNodeType = keyof typeof nodeTypes;
const getNodeType = (item: LineageNode): LineageNodeType => {
  const isRawViewWithSync = item.type === "raw-view" && !!item.rawView.syncId;
  const hasDependents = !!item.dependents.length || isRawViewWithSync;
  const hasDependencies = !!item.dependencies.length;

  if (hasDependents && hasDependencies) return "node";
  if (hasDependents) return "output_node";
  if (hasDependencies) return "input_node";
  return "independent_node";
};

const useOutOfBoundsDetecter = (
  lineageElements: Node[],
  container: React.RefObject<HTMLDivElement>,
) => {
  const [position] = useAtom(LineageAtom);

  const lineageBoundry = useMemo(() => {
    if (lineageElements.length === 0) {
      return null;
    }
    return lineageElements.reduce(
      (prev, node) => {
        prev.x = Math.max(prev.x, node.position.x + 500);
        prev.y = Math.max(prev.y, node.position.y + 50);
        return prev;
      },
      { x: 0, y: 0 },
    );
  }, [lineageElements]);

  //check if the viewport has been positioned so that nothing is visible
  const isOutOFBounds = useMemo(() => {
    const boundingRect = container.current?.getBoundingClientRect();

    if (!boundingRect || !lineageBoundry) {
      return;
    }

    const xBoundry = position.x > 0 ? boundingRect.width : lineageBoundry.x;
    const yBoundry = position.y > 0 ? boundingRect.height : lineageBoundry.y;

    const outOfBoundsX = Math.abs(position.x) > xBoundry;
    const outOfBoundsY = Math.abs(position.y) > yBoundry;

    const isOutOFBounds = outOfBoundsX || outOfBoundsY;

    return isOutOFBounds;
  }, [container, lineageBoundry, position.x, position.y]);

  return isOutOFBounds;
};
