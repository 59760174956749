import {
  Survey,
  useDismissProductNpsMutation,
  useSubmitProductNpsMutation,
} from "@/apollo/types";
import { useMixpanel } from "@/monitoring/mixpanel";

import { NPSSurveyFormData } from "./NPSSurveyForm";

export function useNPSSurvey(survey: Pick<Survey, "id" | "name" | "question">) {
  const mixpanel = useMixpanel();

  const [submitNPS, { data }] = useSubmitProductNpsMutation();
  const [dismissSurvey] = useDismissProductNpsMutation();

  const handleSubmit = async (values: NPSSurveyFormData) => {
    if (values.score == null) {
      return;
    }
    mixpanel.track("Survey Submitted", {
      surveyId: survey.id,
      surveyName: survey.name,
      surveyQuestion: survey.question,
      score: values.score,
      feedback: values.feedback,
    });
    return await submitNPS({
      variables: {
        input: {
          surveyId: survey.id,
          score: values.score,
          feedback: values.feedback,
        },
      },
    });
  };

  const handleDismiss = async () => {
    mixpanel.track("Survey Dismissed", {
      surveyId: survey.id,
      surveyName: survey.name,
      surveyQuestion: survey.question,
    });
    return await dismissSurvey();
  };

  return {
    submitResponse: handleSubmit,
    dismissSurvey: handleDismiss,
    surveyResponse: data?.submitProductNPS,
  };
}
