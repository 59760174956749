import { useMemo } from "react";

import { usePreviewsAtom } from "../../Preview/Preview";

export const usePreviewData = (modelId: string) => {
  const [previews] = usePreviewsAtom();
  return useMemo(() => {
    const preview = previews.find((p) => p.modelId === modelId);
    const data = preview?.rows;
    const loading =
      preview?.state === "loading" || preview?.state === "pending";
    return { data, loading, error: preview?.message };
  }, [previews, modelId]);
};
