import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import {
  ActivateConnectionSettingsDocument,
  ActivateConnectionSettingsQuery,
  EltConnectionSettingsDocument,
  EltConnectionSettingsQuery,
} from "@/apollo/types";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useLatestValueRef } from "./useLatestValueRef";

export type ConnectionSettingsType = {
  required: boolean;
  loading: boolean;
  value: any;
  refetch: (formValues?: Record<string, any>) => Promise<void>;
  error: string | undefined;
};

export default function useConnectionSettings(
  id: string,
  connectionType: "ELT" | "Activate",
  formValuesProp?: Record<string, any>,
): ConnectionSettingsType {
  const client = useApolloClient();
  const [value, setValue] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const formValuesRef = useLatestValueRef(formValuesProp);

  const handleFetchSettings = useCallback(
    async (formValues?: Record<string, any>) => {
      const ConnectionSettingsDocument =
        connectionType === "ELT"
          ? EltConnectionSettingsDocument
          : ActivateConnectionSettingsDocument;

      setLoading(true);
      setError(undefined);
      try {
        const {
          data,
        }: ApolloQueryResult<
          EltConnectionSettingsQuery & ActivateConnectionSettingsQuery
        > = await client.query({
          query: ConnectionSettingsDocument,
          variables: {
            input: {
              id,
              formValues: {
                ...formValuesRef.current,
                ...formValues,
              },
            },
          },
        });
        setLoading(false);
        if (connectionType === "ELT") {
          setValue(data.getEltSettings.connectionSettings);
        } else if (connectionType === "Activate") {
          setValue(data.getActivateSettings.connectionSettings);
        } else {
          throw new Error(
            `useConnectionSettings - Connection type: (${connectionType}) is invalid`,
          );
        }
      } catch (err: any) {
        setLoading(false);
        setError(err?.message ?? err);
      }
    },
    [client, id, connectionType, formValuesRef],
  );
  useEffect(() => {
    if (!id) return;
    handleFetchSettings();
  }, [handleFetchSettings, id]);

  const required = !!Object.keys(value || {}).length;

  const object = useMemo(
    () => ({
      required,
      loading,
      value,
      refetch: handleFetchSettings,
      error,
    }),
    [error, handleFetchSettings, loading, required, value],
  );
  return object;
}
