export const ETL_SYNCS_BLOCKED_HEADING =
  "Data source syncs have been deactivated for this account.";
export const ETL_SYNCS_BLOCKED_BODY =
  "Please book a call with us to continue syncing data to this account.";

export const REVERSE_ETL_SYNCS_BLOCKED_HEADING =
  "Reverse ETL syncs have been deactivated for this account.";
export const REVERSE_ETL_SYNCS_BLOCKED_BODY =
  "Please book a call with us to continue exporting data from this account.";

export const ALL_SYNCS_BLOCKED_HEADING =
  "All syncs have been deactivated for this account.";
export const ALL_SYNCS_BLOCKED_BODY =
  "Please book a call with us to continue syncing data to and from this account.";
