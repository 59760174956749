import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

export function getAllWeldTags(model: monaco.editor.ITextModel) {
  // Find all weld tags in the model
  const matches = model.findMatches(
    "\\{\\{[\\w][\\w.-]*\\}\\}",
    false,
    true,
    false,
    null,
    true,
  );

  return matches.reduce<
    {
      range: monaco.IRange;
      tag: string;
    }[]
  >((memo, match) => {
    const singleMatch = match.matches?.find((match) => match !== undefined);
    if (singleMatch === undefined) {
      return memo;
    }
    memo.push({
      range: match.range,
      tag: singleMatch,
    });
    return memo;
  }, []);
}

function excludeMatchesWithinComments(
  matches: monaco.editor.FindMatch[],
  model: monaco.editor.ITextModel,
) {
  // Find all comments in the model
  const comments = model.findMatches(
    "(--.*)|(((\\/\\*)+?[\\w\\W]+?(\\*\\/)+))",
    false,
    true,
    false,
    null,
    true,
  );

  // Filter out matches that are inside comments
  return matches.filter((match) => {
    return comments.every((comment) => {
      return !monaco.Range.containsRange(comment.range, match.range);
    });
  });
}

export function getAllWeldTagsByName(
  name: string,
  model: monaco.editor.ITextModel,
  excludeComments = true,
) {
  // Match all instances of the tag
  const matches = model.findMatches(
    `{{${name}}}`,
    false,
    false,
    false,
    null,
    false,
  );

  const filteredMatches = excludeComments
    ? excludeMatchesWithinComments(matches, model)
    : matches;
  return filteredMatches.map((match) => {
    return {
      range: match.range,
      tag: `{{${name}}}`,
    };
  });
}

export function getWeldTagAtPosition(
  model: monaco.editor.ITextModel,
  position: monaco.Position,
) {
  // Find all weld tags in the model
  const tags = getAllWeldTags(model);

  // Find the first match that contains the position
  const match = tags.find((tag) => {
    const range = tag.range;
    return (
      range.startLineNumber === position.lineNumber &&
      range.startColumn <= position.column &&
      range.endColumn >= position.column
    );
  });

  if (match === undefined) {
    return null;
  }
  return {
    weldTag: match.tag.replaceAll(/[{}]/g, ""),
    range: match.range,
  };
}
