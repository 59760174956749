import { LinkProps } from "@tanstack/react-location";
import { Notification, NotificationType } from "@/apollo/types";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import { useIsInBackOffice } from "@/pages/BackOffice/useIsInBackOffice";
import { MouseEvent, useCallback } from "react";
import { ButtonProps } from "@/components/elements/Button";
import confirm from "@/components/elements/Confirm";
import { useRetryNotification } from "@/features/notifications";
import { useRemoveProtectionNotification } from "./useRemoveProtectionNotification";
import { useRunFullSyncNotification } from "./useRunFullSyncNotification";

export const useNotificationAction = (options?: {
  onCompleted: (message: string) => void;
  onError: (message: string) => void;
}) => {
  const { isBackOffice } = useIsInBackOffice();
  const getNotificationRedirectUrlBo = useNotificationRedirectUrlBo();
  const getNotificationActionProps = useNotificationActionHandler(options);
  return {
    getActionProps: isBackOffice
      ? getNotificationRedirectUrlBo
      : getNotificationActionProps,
  };
};

type NotificationActionHandler = Pick<
  LinkProps & ButtonProps,
  "to" | "search" | "hash" | "replace" | "colorScheme"
> & {
  label: string;
  handler?: (e: MouseEvent<any>) => Promise<void>;
};

function useNotificationActionHandler(options?: {
  onCompleted: (message: string) => void;
  onError: (message: string) => void;
}) {
  const { onOpen: viewSync } = useViewDataSourceSlideOver();
  const { retry, loading: retryLoading } = useRetryNotification(options);
  const { removeProtection, loading: removeProtectionLoading } =
    useRemoveProtectionNotification(options);
  const { runFullSync, loading: runFullSyncLoading } =
    useRunFullSyncNotification(options);

  return useCallback(
    (notification: Notification): NotificationActionHandler[] => {
      const syncId =
        notification.detail.syncId ?? notification.detail.eltSyncId;
      switch (notification.type) {
        case NotificationType.ActivateQuarantine:
          return [
            {
              label: "Go to sync",
              to: `/reverse-etl/${syncId}`,
            },
          ];
        case NotificationType.ActivateBadQuery:
          return [
            {
              label: "Open model",
              to: `/editor/${notification.detail.modelId}`,
            },
          ];
        case NotificationType.AirtableTableNotFound:
          return [
            {
              label: "Go to sync",
              to: `/sources/${syncId}/configuration`,
            },
          ];
        case NotificationType.GoogleSheetsBadNamedRange:
        case NotificationType.Quarantine:
        case NotificationType.PostgresTableInsufficientPrivilege:
        case NotificationType.ChargebeeApiRestricted:
        case NotificationType.DatabaseTableNotFound:
        case NotificationType.CustomConnectorFailedRequest:
          return [
            {
              label: "Go to sync",
              handler: async (e) => {
                viewSync({ syncId: syncId });
              },
            },
          ];
        case NotificationType.GoogleInvalidGrant:
        case NotificationType.ConnectorBadOauth:
        case NotificationType.PostgresFailedConnection:
        case NotificationType.PostgresDatabaseInsufficientPrivilege:
        case NotificationType.ConnectorBadApiKey:
        case NotificationType.ApiNotEnoughPermissions:
          return [
            {
              label: "Configure connection",
              to: `/settings/connections/${notification.detail.sourceConnectionId}/configuration`,
            },
          ];
        case NotificationType.OrchestrationWorkflowRunFailed:
          return [
            {
              label: "Go to orchestration",
              to: `/orchestration/${notification.entityId}`,
            },
          ];
        case NotificationType.MaterialisationFailure:
          return [
            {
              label: "Go to model",
              to: `/editor/${notification.detail.modelId}`,
            },
          ];
        case NotificationType.ProtectedStreamFullSync:
          return [
            {
              label: "Remove protection",
              colorScheme: "danger",
              handler: async (e) => {
                const confirmed = await confirm({
                  title: "Warning",
                  type: "danger",
                  message:
                    "Removing protection will run full syncs automatically without notifying you, that may cause data losses. Are you sure you want to proceed?",
                });
                if (!confirmed) {
                  return e.stopPropagation();
                }
                if (!removeProtectionLoading) {
                  const sourceStreamId = notification.detail.sourceStreamId;
                  removeProtection(notification, sourceStreamId);
                }
              },
            },
            {
              label: "Run full sync once",
              colorScheme: "danger",
              handler: async (e) => {
                const confirmed = await confirm({
                  title: "Warning",
                  type: "danger",
                  message:
                    "Running a full sync may cause data loss without a backup. Are you sure you want to proceed?",
                });
                if (!confirmed) {
                  return e.stopPropagation();
                }
                if (!runFullSyncLoading) {
                  const sourceStreamId = notification.detail.sourceStreamId;
                  runFullSync(notification, sourceStreamId);
                }
              },
            },
            {
              label: "Retry sync",
              colorScheme: "primary",
              handler: async (e) => {
                if (!retryLoading) {
                  retry(notification);
                }
              },
            },
          ];
        default:
          return [];
      }
    },
    [
      viewSync,
      removeProtectionLoading,
      removeProtection,
      runFullSyncLoading,
      runFullSync,
      retryLoading,
      retry,
    ],
  );
}

function useNotificationRedirectUrlBo() {
  return useCallback(
    (notification: Notification): NotificationActionHandler[] => {
      const syncId =
        notification.detail.syncId ?? notification.detail.eltSyncId;
      switch (notification.type) {
        case NotificationType.ActivateQuarantine:
          return [
            {
              label: "Go to sync",
              to: `../elt-errors/details/${notification.accountId}/reverse-etl/${syncId}`,
            },
          ];
        case NotificationType.Quarantine:
        case NotificationType.GoogleInvalidGrant:
        case NotificationType.GoogleSheetsBadNamedRange:
        case NotificationType.ConnectorBadOauth:
        case NotificationType.PostgresFailedConnection:
        case NotificationType.PostgresDatabaseInsufficientPrivilege:
        case NotificationType.PostgresTableInsufficientPrivilege:
        case NotificationType.ConnectorBadApiKey:
        case NotificationType.ChargebeeApiRestricted:
        case NotificationType.DatabaseTableNotFound:
          return [
            {
              label: "Go to sync",
              to: `../elt-errors/details/${notification.accountId}/sources/${syncId}`,
            },
          ];
        default:
          return [];
      }
    },
    [],
  );
}
