import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

const Title = ({ children }: { children: string }) => (
  <div className="text-lg dark:text-white">{children}</div>
);
const Description = ({ children }: { children: string }) => (
  <p className="text-xs text-gray-400">{children}</p>
);
const Row = ({
  children,
  fullWidth,
}: {
  children: JSX.Element[];
  fullWidth?: boolean;
}) => (
  <div className="w-full items-stretch space-y-8 lg:flex lg:space-y-0 lg:space-x-8">
    {fullWidth
      ? children
      : children.map((Child, idx) => (
          <div key={idx} className="flex-1 rounded border bg-white p-4">
            {Child}
          </div>
        ))}
  </div>
);
const MappingRow = ({
  children,
  fullWidth,
}: {
  children: JSX.Element[];
  fullWidth?: boolean;
}) => (
  <div className="w-full items-center space-y-2 lg:flex lg:space-y-0 lg:space-x-8">
    {fullWidth
      ? children
      : children.map((Child, idx) => (
          <div key={idx} className="flex-1 rounded border bg-white p-4">
            {Child}
          </div>
        ))}
  </div>
);
const Container = ({
  children,
  fullWidth = false,
}: {
  children: JSX.Element | JSX.Element[];
  fullWidth?: boolean;
}) => (
  <div
    className={`${
      fullWidth
        ? "rounded border bg-white p-4 dark:border-gray-700 dark:bg-gray-800"
        : ""
    } space-y-4`}
  >
    {children}
  </div>
);
const Column = ({ children }: { children: ReactNode }) => (
  <div className="flex h-full flex-1 flex-col space-y-4">{children}</div>
);

const Arrow = () => (
  <div className="">
    <ChevronRightIcon className="mx-auto h-4 w-4 rotate-90 transform text-gray-400 lg:mx-0 lg:rotate-0" />
  </div>
);

const SyncItem = {
  Title,
  Description,
  Container,
  Column,
  Row,
  Arrow,
  MappingRow,
};

export default SyncItem;
