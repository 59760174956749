import { useChartTheme } from "@/features/charts/themes";
import { getChartColor } from "@/features/charts/themes/visualizeTheme";
import { useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ChartVisualization } from "../VisualizationType";
import { TooltipContent } from "./TooltipContent";
import { ChartBarIcon } from "@heroicons/react/24/outline";
import { useTimeFormater } from "./useTimeFormater";

type Props = { chart: ChartVisualization; data: any[] };

export function BarChartComponent(props: Props) {
  const [focusBar, setFocusBar] = useState(null);

  const chartTheme = useChartTheme("visualize");

  const timeFormatter = useTimeFormater(props.data, props.chart.keys.x);

  const isValidKeys = useMemo(() => {
    const xKey = props.chart.keys?.x;
    const yKey = props.chart.keys?.y;
    return (
      typeof xKey === "string" &&
      xKey.length > 0 &&
      Array.isArray(yKey) &&
      yKey.length > 0
    );
  }, [props.chart.keys?.x, props.chart.keys?.y]);

  if (!isValidKeys)
    return (
      <div className="flex h-full w-full px-8 py-8">
        <div className="flex h-full w-full items-center justify-center space-x-2 rounded bg-gray-100 dark:bg-gray-700">
          <ChartBarIcon className="h-6 w-6" />
          <span className="italic">No columns selected</span>
        </div>
      </div>
    );
  return (
    <div className="relative h-full w-full overflow-hidden">
      <div className="absolute inset-0">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            onMouseMove={(state: any) => {
              if (state.isTooltipActive) {
                setFocusBar(state.activeTooltipIndex);
              } else {
                setFocusBar(null);
              }
            }}
            {...chartTheme.BarChart}
            data={props.data}
          >
            <CartesianGrid {...chartTheme.CartesianGrid} />
            <XAxis
              {...chartTheme.XAxis}
              dataKey={props.chart?.keys?.x}
              tickFormatter={timeFormatter}
            />
            <YAxis
              tickFormatter={(value) => {
                const formatter = Intl.NumberFormat("en", {
                  notation: "compact",
                });
                if (isNaN(value)) {
                  return value;
                }
                return formatter.format(value);
              }}
              {...chartTheme.YAxis}
            />
            <Tooltip
              {...chartTheme.Tooltip}
              cursor={false}
              wrapperStyle={{ outline: "none" }}
              content={<TooltipContent />}
            />
            {props.chart?.keys?.y?.map((item, i) => (
              <Bar
                {...chartTheme.Bar}
                fill={getChartColor(i)}
                key={item}
                name={item}
                dataKey={item}
              >
                {props.data.map((entry, index) => {
                  return (
                    <Cell
                      key={index}
                      strokeOpacity={0.2}
                      stroke={focusBar === index ? getChartColor(i) : undefined}
                      strokeWidth={10}
                    />
                  );
                })}
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
