import { useState } from "react";

import {
  ConnectionDocument,
  EltSyncsDocument,
  GetRawViewsDocument,
  RemoveEltSyncInput,
  useRemoveEltSyncMutation,
  useStartEltSyncMutation,
  useStopEltSyncMutation,
} from "@/apollo/types";
import ConfirmDeleteModal from "@/components/elements/ConfirmDeleteModal";
import MenuItem from "@/components/elements/MenuItem";
import Toggle from "@/components/elements/Toggle";
import { InformationIconTooltip } from "@/components/elements/Tooltip";
import TableMenu from "@/components/modules/TableMenu";
import useDeleteItem from "@/hooks/useDeleteItem";
import { useToast } from "@/providers/ToastProvider";

export const SyncMenu = ({
  syncId,
  active,
  sourceId,
}: {
  syncId: string;
  active: boolean;
  sourceId: string;
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [removeData, setRemoveData] = useState(true);
  const handleDelete = useDeleteItem<RemoveEltSyncInput>({
    title: "sync",
    variables: { eltSyncId: syncId, removeDataReferences: removeData },
    mutation: useRemoveEltSyncMutation,
    refetchQueries: [
      { query: EltSyncsDocument },
      { query: ConnectionDocument, variables: { id: sourceId } },
      {
        query: GetRawViewsDocument,
      },
    ],
  });
  return (
    <>
      <ConfirmDeleteModal
        title="sync"
        onConfirm={handleDelete}
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        extraConfigurations={
          <RemoveTableToggle
            removeTables={removeData}
            setRemoveTables={setRemoveData}
          />
        }
      />
      <TableMenu>
        <ToggleSyncMenuItem syncId={syncId} active={active} />
        <div className="mx-4 my-1 border-b dark:border-gray-700" />
        <MenuItem text={"Delete"} onClick={() => setShowConfirmModal(true)} />
      </TableMenu>
    </>
  );
};

const RemoveTableToggle = (props: {
  removeTables: boolean;
  setRemoveTables: (removeTables: boolean) => void;
}) => {
  return (
    <div
      className="flex items-center space-x-1"
      onClick={(e) => e.stopPropagation()}
    >
      <Toggle
        checked={props.removeTables}
        onChange={props.setRemoveTables}
        size="sm"
      />
      <div className="flex items-center">
        <label>Also remove tables</label>
      </div>
      <InformationIconTooltip
        content={
          "This will remove the tables produced by this sync from the WELD Model tool."
        }
      />
    </div>
  );
};

const ToggleSyncMenuItem = ({
  syncId,
  active,
}: {
  syncId: string;
  active: boolean;
}) => {
  const toast = useToast();

  const useMutation = active ? useStopEltSyncMutation : useStartEltSyncMutation;
  const [toggleSync] = useMutation({
    onError(error) {
      toast(
        `Sync not ${active ? "stopped" : "started"}`,
        error.message,
        "error",
      );
    },
    onCompleted() {
      toast(
        `Sync ${active ? "stopped" : "started"}`,
        `Your sync has been succesfully ${active ? "stopped" : "started"}.`,
        "success",
      );
    },
    refetchQueries: [
      { query: EltSyncsDocument, variables: { eltSyncId: syncId } },
    ],
  });

  return (
    <MenuItem
      text={active ? "Stop" : "Start"}
      onClick={() => {
        toggleSync({
          variables: { eltSyncId: syncId },
        });
      }}
    />
  );
};
