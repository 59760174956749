import { useChartTheme } from "@/features/charts/themes";
import { useInterval } from "@/hooks/useInterval";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const values = [10, 2, 7, 6, 4, 8, 5, 4, 6, 9, 5, 2, 7, 6, 1];
const dataset = values.map((v, i) => ({ date: new Date(i), value: v }));

const barSize = 30;

const preAnimationDelay = 100;
const animationDuration = 1600;
const postAnimationDelay = 100;

const totalAnimationDuration =
  preAnimationDelay + animationDuration + postAnimationDelay;

export type BarChartSkeletonProps = {
  isLoaded?: boolean;
  // If `barCount` is set, the skeleton will only render the `barCount` amount of bars.
  // This is useful for when you want to render a skeleton chart with a fixed height or have
  // The amount of bars match with the previously loaded data which makes the transition
  // from skeleton to real data look smoother.
  barCount?: number;
  // If `maxBarCount` is set, the skeleton will only render up to `maxBarCount` amount of bars.
  maxBarCount?: number;

  repeatAnimation?: boolean;
};
export function BarChartSkeleton({
  isLoaded,
  barCount,
  maxBarCount = 15,
  children,
  repeatAnimation = true,
}: React.PropsWithChildren<BarChartSkeletonProps>) {
  /**
   * Recharts has a nice animation when rendering elements of a chart initially.
   * We repeat this initial animation by forcing a remount of the skeleton chart
   * by changing its `key` prop in intervals.
   * It's a bit of a hack but works quite well and recharts won't repeat the animation any other way.
   */
  const [forceUpdateKey, setState] = React.useState(0);
  useInterval(
    () => setState((x) => x + 1),
    isLoaded || !repeatAnimation ? null : totalAnimationDuration,
  );

  const chartTheme = useChartTheme("skeleton");

  const filteredDataset = barCount
    ? dataset.slice(0, Math.min(barCount, maxBarCount))
    : dataset;

  if (isLoaded) {
    return <>{children}</>;
  }

  return (
    <div className="h-full w-full" style={{ maxHeight: maxBarCount * barSize }}>
      <ResponsiveContainer
        width="100%"
        height="100%"
        minWidth={100}
        minHeight={Math.max(filteredDataset.length * barSize, 120)}
      >
        <BarChart
          {...chartTheme.BarChart}
          layout="vertical"
          data={filteredDataset}
          key={forceUpdateKey}
        >
          <CartesianGrid {...chartTheme.CartesianGrid} horizontal={false} />
          <XAxis type="number" hide />
          <YAxis
            {...chartTheme.YAxis}
            dataKey="date"
            type="category"
            tickFormatter={() => ""}
            width={0}
          />
          <Bar
            {...chartTheme.Bar}
            dataKey="value"
            isAnimationActive
            animationEasing="ease"
            animationBegin={preAnimationDelay}
            animationDuration={animationDuration}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
