import { useNavigate } from "@tanstack/react-location";
import { ActionButton } from "@/components/elements/Button";
import ActionBar from "@/components/elements/layout/ActionBar";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { createPortal } from "react-dom";

type ContextType = [HTMLDivElement, Dispatch<SetStateAction<HTMLDivElement>>];
const ActionRefContext = createContext<ContextType>(
  [] as unknown as ContextType,
);
export const TaskAction = ({ children }: { children: JSX.Element }) => {
  const [ref] = useContext<ContextType>(ActionRefContext);

  if (!ref) return null;

  return createPortal(children, ref);
};

export const ActionContextProvider = (props: {
  children: JSX.Element | JSX.Element[];
}) => {
  const state = useState<HTMLDivElement>(document.createElement("div"));
  return (
    <ActionRefContext.Provider value={state}>
      {props.children}
    </ActionRefContext.Provider>
  );
};

export const TaskActionBar = (props: {
  mode: "narrow" | "full" | "normal";
  noCancel?: boolean;
  onCancelClicked?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const [, setActionRef] = useContext<ContextType>(ActionRefContext);
  const navigate = useNavigate();
  return (
    <ActionBar mode={props.mode}>
      {!props.noCancel && (
        <ActionButton
          variant="ghost"
          colorScheme="secondary"
          onClick={(e) =>
            typeof props.onCancelClicked === "function"
              ? props.onCancelClicked(e)
              : navigate({ to: ".." })
          }
        >
          Cancel
        </ActionButton>
      )}
      <div className="shrink-0" ref={(ref) => ref && setActionRef(ref)} />
    </ActionBar>
  );
};
