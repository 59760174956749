import Tooltip from "@/components/elements/Tooltip";

export const ActiveRowDescription = () => {
  return (
    <div className="max-w-xs">
      <p>
        <strong>Active Rows</strong>, for which you are billed, are determined
        by the unique rows updated or inserted through ETL or Reverse ETL in any
        destination. Active rows are calculated monthly based on workspace
        connector usage, considering:
      </p>
      <ul className="my-1 list-inside list-disc">
        <li>new data rows</li>
        <li>updated rows</li>
        <li>manual full syncs</li>
      </ul>
      <p>
        If a database connector does not support incremental syncing, one full
        sync per month is counted towards Active rows.
        <br />
        The initial 14 days of setting up any connector are excluded from the
        Active row count.
      </p>
    </div>
  );
};

export const ActiveRowTooltipTrigger = (props: { children: JSX.Element }) => {
  return <Tooltip content={<ActiveRowDescription />}>{props.children}</Tooltip>;
};
