import {
  Notification,
  SyncJobStatus,
  useFindEltSyncLatestHistoryQuery,
} from "@/apollo/types";
import { useEltSyncNotifications } from "@/features/notifications";
import { useEltSync } from "@/hooks/useSync";

export enum ELTSyncStatus {
  NOT_STARTED = "NOT_STARTED",
  PAUSED = "PAUSED",
  NO_SYNC = "No sync yet",
  ALL_SYNCED = "ALL_SYNCED",
  PARTIALLY_SYNCED = "PARTIALLY_SYNCED",
  ALL_FAILING = "ALL_FAILING",
  PARTIAL_FAILING = "PARTIAL_FAILING",
  NEEDS_USER_INTERACTION = "NEEDS_USER_INTERACTION",
}

function useNotifications(eltSync: ReturnType<typeof useEltSync>["eltSync"]) {
  const notificationsQuery = useEltSyncNotifications(eltSync ? [eltSync] : []);
  const latestSyncHistoryQuery = useFindEltSyncLatestHistoryQuery({
    variables: {
      syncId: eltSync?.id ?? "",
    },
    skip: !eltSync?.id,
  });

  const syncNotifications = [
    ...notificationsQuery.notifications.account,
    ...notificationsQuery.notifications.connector,
    ...notificationsQuery.notifications.sync,
  ].filter((x) => x.isLock);

  const sourceStreamNotifications =
    notificationsQuery.notifications.eltStream.filter((x) => x.isLock);

  return {
    loading: notificationsQuery.loading || latestSyncHistoryQuery.loading,
    syncNotifications,
    sourceStreamNotifications,
    history: latestSyncHistoryQuery.data?.findEltSyncLatestHistory ?? [],
  };
}

/*
 * This hook is used to get the status of an eltSync
 * It uses the notifications to see if the sync is failing
 * It also checks if the sync does not have any sync history yet
 */
export const useEltSyncStatus = (
  eltSyncId: string,
): {
  status?: ELTSyncStatus;
  nrFailingStreams?: number;
  loading?: boolean;
  notifications?: Notification[];
} => {
  const { eltSync } = useEltSync(eltSyncId);

  const { syncNotifications, sourceStreamNotifications, history, loading } =
    useNotifications(eltSync);

  if (loading) {
    return { loading: true };
  }

  if (eltSync?.status === "NOT_STARTED") {
    return { status: ELTSyncStatus.NOT_STARTED };
  }

  if (eltSync?.status === "PAUSED") {
    return { status: ELTSyncStatus.PAUSED };
  }

  if (!history.length) {
    return { status: ELTSyncStatus.NO_SYNC };
  }

  if (
    history.length &&
    eltSync?.sourceStreams.length &&
    history.length < eltSync?.sourceStreams.length
  ) {
    return { status: ELTSyncStatus.PARTIALLY_SYNCED };
  }

  const hasFailingSyncHistory = history.some(
    (syncHistory) => syncHistory.status === SyncJobStatus.Failed,
  );

  if (syncNotifications.length === 0 && !hasFailingSyncHistory) {
    return { status: ELTSyncStatus.ALL_SYNCED };
  }

  if (syncNotifications.length > 0) {
    return {
      status: ELTSyncStatus.NEEDS_USER_INTERACTION,
      notifications: syncNotifications,
    };
  }

  const allTablesFailed =
    sourceStreamNotifications.length === eltSync?.sourceStreams.length;
  if (allTablesFailed) {
    return {
      status: ELTSyncStatus.ALL_FAILING,
      nrFailingStreams: eltSync?.sourceStreams.length,
    };
  }

  return {
    status: ELTSyncStatus.PARTIAL_FAILING,
    nrFailingStreams: sourceStreamNotifications.length,
  };
};
