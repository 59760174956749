import { ListModelsQuery, QueryDependencyInput } from "@/apollo/types";
import { useCallback } from "react";

import { useGetLineageDependencies } from "./useLineageDag";

export const useCheckCircularDependencies = () => {
  const getDependencies = useGetLineageDependencies();

  return useCallback(
    async (modelId?: string, draftDependencies?: QueryDependencyInput[]) => {
      const dependencies = await getDependencies(
        modelId,
        draftDependencies?.map((d) => d.dwItemId),
      );

      const modelsWithCircularReferences = dependencies
        .filter((d) => d.circularReferences && d.circularReferences.length > 0)
        .map((d) => (d.type === "model" ? d.model : null))
        .filter((m): m is ListModelsQuery["models"][0] => m !== null);

      return modelsWithCircularReferences;
    },
    [getDependencies],
  );
};
