export const SuccessIcon = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      {...props}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="#B7F3D2" />
      <circle cx="25" cy="25" r="15" fill="#4DE390" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.7071 20.2929C32.0976 20.6834 32.0976 21.3166 31.7071 21.7071L23.7071 29.7071C23.3166 30.0976 22.6834 30.0976 22.2929 29.7071L18.2929 25.7071C17.9024 25.3166 17.9024 24.6834 18.2929 24.2929C18.6834 23.9024 19.3166 23.9024 19.7071 24.2929L23 27.5858L30.2929 20.2929C30.6834 19.9024 31.3166 19.9024 31.7071 20.2929Z"
        fill="white"
      />
    </svg>
  );
};
