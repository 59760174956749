import { Tier } from "@/apollo/types";

export function getPriceTierFromUsageLimit(
  limit: number | undefined,
  tiers: Tier[],
) {
  if (limit === undefined) {
    return undefined;
  }
  const sortedTiers = tiers
    .slice()
    .sort((a, b) => (a.upTo ?? Infinity) - (b.upTo ?? Infinity));
  const targetTier = sortedTiers.find((x) => {
    if (x.upTo == null) {
      return true;
    }
    return x.upTo >= limit;
  });
  return targetTier;
}
