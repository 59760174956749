import { Tab } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import Tooltip from "@/components/elements/Tooltip";
import classNames from "@/helpers/classNames";
import { PropsWithChildren, ReactNode } from "react";

import { Step, useStep, useStepsContext } from "../../stepper/useSteps";
import { useDataSourceStateContext } from "../step-state";

export function StepList(props: { children: ReactNode }) {
  return (
    <>
      <div className="absolute top-1/2 h-px w-full -translate-y-1/2 border-b dark:border-gray-700" />
      <div className="flex justify-between">{props.children}</div>
    </>
  );
}

export function StepItem(
  props: PropsWithChildren<{
    step: Step;
  }>,
) {
  const { step } = props;
  const { currentStep, steps } = useStepsContext();
  const { state } = useDataSourceStateContext();

  const { index, isFirstStep, isLastStep } = useStep(step.id);

  const statesArr = Object.values(state);
  const isValid = statesArr[index]?.isValid;
  const isDisabled = step.isDisabled === true;

  // Step can be selected if all previous steps are valid
  const canSelectStep = statesArr
    .slice(0, index)
    .every((stepState, index) => steps[index].isDisabled || stepState.isValid);

  const isDisabledOrInaccessible =
    isDisabled || !canSelectStep || currentStep.id === "setup-complete";
  return (
    <Tab
      key={step.id}
      className={({ selected }) =>
        classNames(
          "relative",
          isDisabledOrInaccessible && "cursor-auto text-gray-400",
          !isDisabledOrInaccessible && selected && "font-medium",
          step.isHidden && "hidden",
        )
      }
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        if (isDisabledOrInaccessible) {
          e.preventDefault();
        }
      }}
    >
      {({ selected }) => (
        <>
          <Tooltip content={step.tooltip ?? null}>
            <div
              className={classNames(
                "absolute bottom-0 rounded pb-4",
                !isFirstStep && !isLastStep && "left-1/2 -translate-x-1/2",
                isFirstStep && "left-0",
                isLastStep && "right-0",
                !isDisabledOrInaccessible &&
                  "outline outline-0 hover:bg-black/5 hover:outline-4 hover:outline-black/5 dark:hover:bg-white/10 dark:hover:outline-white/10",
              )}
            >
              <span className="block whitespace-nowrap text-xs">
                {step.title}
              </span>
            </div>
          </Tooltip>
          <div
            className={classNames(
              "relative flex h-3 w-3 items-center justify-center rounded-full border bg-white dark:bg-gray-800",
              "border-current dark:border-current",
              (selected || isValid) && !step.isDisabled
                ? "text-blue-500"
                : "border-gray-200 dark:border-gray-700",
            )}
          >
            {step.isDisabled ? (
              <CheckIcon className="h-3 w-3" />
            ) : selected ? (
              <div className="h-1.5 w-1.5 rounded-full bg-current" />
            ) : (
              isValid && <CheckIcon className="h-3 w-3" />
            )}
          </div>
        </>
      )}
    </Tab>
  );
}
