import React from "react";

import { MaterialisationStatus, WorkflowJobStatus } from "@/apollo/types";
import Badge, { BadgeVariant } from "@/components/elements/Badge_Legacy";
import { StatusLED } from "@/components/elements/StatusLED";
import classNames from "@/helpers/classNames";

import { getMaterializationStatusColor } from "./utility/getJobStatusColor";

const JobStatusConfig: Record<
  WorkflowJobStatus,
  {
    displayText: string;
    badgeVariant: BadgeVariant;
  }
> = {
  [WorkflowJobStatus.Ready]: {
    displayText: "Queued",
    badgeVariant: BadgeVariant.Info,
  },
  [WorkflowJobStatus.NotReady]: {
    displayText: "Not ready",
    badgeVariant: BadgeVariant.Inactive,
  },
  [WorkflowJobStatus.Done]: {
    displayText: "Done",
    badgeVariant: BadgeVariant.Success,
  },
  [WorkflowJobStatus.Running]: {
    displayText: "Running",
    badgeVariant: BadgeVariant.Default,
  },
  [WorkflowJobStatus.Failed]: {
    displayText: "Failed",
    badgeVariant: BadgeVariant.Error,
  },
  [WorkflowJobStatus.Canceled]: {
    displayText: "Canceled",
    badgeVariant: BadgeVariant.Inactive,
  },
};

export const WorkflowJobStatusDisplay = (props: {
  jobStatus: WorkflowJobStatus;
}) => {
  const config = JobStatusConfig[props.jobStatus];
  return <span>{config.displayText}</span>;
};

type WorkflowJobStatusBadgeProps = React.PropsWithChildren<{
  jobStatus: WorkflowJobStatus;
}>;

export const WorkflowJobStatusBadge = ({
  jobStatus,
  children,
}: WorkflowJobStatusBadgeProps) => {
  const config = JobStatusConfig[jobStatus];
  return (
    <Badge variant={config.badgeVariant}>
      {jobStatus === WorkflowJobStatus.Running && (
        <span className="mr-1">
          <StatusLED pulse variant={BadgeVariant.Default} />
        </span>
      )}
      {children}
    </Badge>
  );
};

export const MaterializationStatusDisplay = (props: {
  materializationStatus: MaterialisationStatus;
}) => {
  const renderStatusText = (materializationStatus: MaterialisationStatus) => {
    switch (materializationStatus) {
      case MaterialisationStatus.Succeeded:
        return "Table updated";
      case MaterialisationStatus.Failed:
        return "Failed";
      case MaterialisationStatus.Running:
        return "Running";
      default:
        return null;
    }
  };

  return (
    <span
      className={classNames(
        getMaterializationStatusColor(props.materializationStatus, "text"),
      )}
    >
      {renderStatusText(props.materializationStatus)}
    </span>
  );
};
