import { datadogRum } from "@datadog/browser-rum";
import { useStonlyWidget } from "@/features/product-guide/hooks/useStonlyWidget";
import { isGuideViewed } from "@/features/product-guide/hooks/useTrackStonlyEvents";
import { useEffect, useRef } from "react";
import guideConfig from "./config";
import { useListModels } from "@/pages/ModelTool/hooks/useListModels";

/*
 * This guide is an introduction to the model tool for empty models.
 * It leads to the AI assistant
 */
export const useDraftModelGuide = () => {
  const StonlyWidget = useStonlyWidget();

  const { models, loadingModels } = useListModels();

  const hasStartedTour = useRef(false);

  useEffect(() => {
    //only show guide once
    const alreadyViewed = isGuideViewed(guideConfig.id);

    if (alreadyViewed) return;

    //don't show this guide if user already saved some models
    // Count the number of models subtracting models in the `ecommerce` folder which is sample data
    const hasModels = models.some(
      (model) => !!model.publishedQuery && model.folder?.name !== "ecommerce",
    );
    if (hasModels || loadingModels) {
      return;
    }

    //Make sure we only trigger starting the tour once:
    if (hasStartedTour.current) return;
    hasStartedTour.current = true;

    // Start recording session replay in Datadog
    datadogRum.startSessionReplayRecording();

    // Start the Stonly guided tour
    StonlyWidget("openGuidedTour", { guideId: guideConfig.id });
  }, [StonlyWidget, loadingModels, models]);
};
