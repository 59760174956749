import { OrchestrationSchedulerType } from "@/apollo/types";
import React from "react";

type SVGProps = React.ComponentProps<"svg">;

const OrchestrateIcon = (props: SVGProps) => {
  return (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.3 12.878A9.505 9.505 0 0010.5 20c-1.854 0-3.46-2.126-4.243-5.226M1.3 12.878c-.2-.777-.3-1.576-.3-2.378 0-1.694.443-3.286 1.221-4.663M1.3 12.878c1.535.851 3.2 1.496 4.957 1.896M10.5 1c-2.623 0-4.75 4.254-4.75 9.5 0 1.537.183 2.99.507 4.274M10.5 1a9.497 9.497 0 018.279 4.837M10.5 1a9.497 9.497 0 00-8.279 4.837M10.5 1c2.005 0 3.72 2.486 4.417 6m3.862-1.163A12.61 12.61 0 0117.203 7m1.576-1.163c.447.792.776 1.64.979 2.52M2.22 5.837a12.617 12.617 0 008.279 3.08m-4.243 5.857c.631.145.59.147 1.243.226"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.403 9.468v4.935h3.702m3.701 0a7.402 7.402 0 11-14.804 0 7.402 7.402 0 0114.804 0z"
      ></path>
    </svg>
  );
};

export default OrchestrateIcon;

export const LocalScheduleIcon = (props: SVGProps) => {
  return (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 17.063h2.953m2.954 0H3.953M1 14.337v4.775c0 .235.09.461.251.628.16.166.379.26.606.26h2.096M1 14.337V12.89c0-.236.09-.462.251-.629a.842.842 0 01.606-.26h2.394c.114 0 .223.047.303.13l2.129 2.207m-5.683 0h2.953m0 0h2.73m-2.73 0v2.726m0 2.937h2.19a.842.842 0 00.606-.26A.906.906 0 007 19.11v-4.26a.453.453 0 00-.126-.315l-.191-.199M3.953 20v-2.937M1 6.063h2.953m2.954 0H3.953M1 3.337v4.775c0 .235.09.461.251.628.16.166.379.26.606.26h2.096M1 3.337V1.89c0-.236.09-.462.251-.629A.842.842 0 011.857 1h2.394c.114 0 .223.047.303.13l2.129 2.207M1 3.337h2.953m0 0h2.73m-2.73 0v2.726m0 2.937h2.19a.842.842 0 00.606-.26A.906.906 0 007 8.11v-4.26a.453.453 0 00-.126-.315l-.191-.199M3.953 9V6.063M10 5.984h2.953m2.954 0h-2.954M10 8V3.301m0 0V1.875c0-.232.09-.455.251-.619A.848.848 0 0110.857 1h2.394c.114 0 .223.046.303.128l2.128 2.173M10 3.3h2.953m0 0h2.729m-2.729 0v2.683m2.73-2.683l.191.196c.08.082.126.193.126.31v2.177m-3.047 0v.266M15.403 9.468v4.935h3.702m3.701 0a7.402 7.402 0 11-14.804 0 7.402 7.402 0 0114.804 0z"
      ></path>
    </svg>
  );
};

export const OrchestrationTypeIcon = ({
  schedulerType,
  ...props
}: SVGProps & { schedulerType: OrchestrationSchedulerType }) => {
  if (schedulerType === OrchestrationSchedulerType.Global)
    return <OrchestrateIcon {...props} />;
  else return <LocalScheduleIcon {...props} />;
};
