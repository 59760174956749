import { useGetCustomFormValuesQuery } from "@/apollo/types";
import { SecondaryButton } from "@/components/elements/Button";
import Select from "@/components/elements/Select";
import { SimplifiedFormContext } from "@/components/modules/ConnectionSettings";
import FieldLabel from "@/components/primitives/InputLabel";
import produce from "immer";
import { DropdownOption, FormType } from "@/integrations";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";

import GoogleAdsCustomReport from "./GoogleAdsCustomReport";

export type GoogleAdsReportOption = {
  label: string;
  value: string;
};

export type GoogleAdsReport = {
  name: string;
  id: string;
  query: string;
};

const useCustomFormFieldsQuery = (connectionId: string) => {
  const { data, loading, refetch } = useGetCustomFormValuesQuery({
    variables: {
      input: {
        connectionId: connectionId,
        dependencies: "{}",
      },
    },
    onError(error) {
      return [];
    },
  });
  return { data: data?.getCustomFormValues.customFormValues, loading, refetch };
};

export default function GoogleAdsForm(props: {
  field: Extract<FormType, { type: "google-ads-form" }>;
}) {
  const context = useFormContext();
  const [adaccounts, setAdAccounts] = useState<
    { label: string; value: string }[] | undefined
  >(() => context.getValues()["adaccounts"]);

  const accounts = props.field.accounts;

  const displaySimplified = useContext(SimplifiedFormContext);

  const [reportErrors, setReportErrors] = useState<Record<string, string>>({});
  const [invalidCombinationReportIds, setInvalidCombinationReportIds] =
    useState<string[]>([]);

  useCustomFormFieldsQuery(context.getValues()["connectionId"]);

  const adAccountOptions: DropdownOption[] = useMemo(() => {
    return accounts.map((account) => ({
      label: account.label,
      value: account.value,
      disabled: account.disabled,
      indent: account.indent,
    }));
  }, [accounts]);

  useEffect(() => {
    context.register("custom_reports", { required: false });
    context.register("adaccounts", { required: true });
  }, [context]);

  const getInitialReports = () => {
    const values = context.getValues()["custom_reports"];
    if (values === "NO_REPORTS") return [];
    return values?.filter((v: any) => !!v) ?? [];
  };
  const [reports, setReports] =
    useState<GoogleAdsReport[]>(getInitialReports());

  const changeReport = (report: GoogleAdsReport) => {
    setReports((prev) =>
      produce(prev, (draft) => {
        const cur = draft.find((rep) => rep.id === report.id);
        if (cur) {
          cur.name = report.name;
          cur.query = report.query;
        }
      }),
    );
  };

  const removeReport = (report: GoogleAdsReport) => {
    setReports((prev) =>
      produce(prev, (draft) => {
        draft = draft.filter((rep) => rep.id !== report.id);
        return draft;
      }),
    );
  };

  const onReportInvalid = useCallback((reportId: string) => {
    setInvalidCombinationReportIds((prev) => [...prev, reportId]);
  }, []);

  const onReportValid = useCallback((reportId: string) => {
    setInvalidCombinationReportIds((prev) =>
      prev.filter((x) => x !== reportId),
    );
  }, []);

  const setValue = context.setValue;
  const setError = context.setError;

  useEffect(() => {
    if (reports.length === 0) {
      setValue("custom_reports", [], {
        shouldValidate: false,
      });
    }
  }, [reports.length, setValue]);

  useEffect(() => {
    //If there is any report where the combination is invalid, set report and don't evaluate further
    if (invalidCombinationReportIds.length > 0) {
      setError("custom_reports", {
        message: "Validation error",
      });
      return;
    }

    //Only evaluate when all reports are valid
    const getReports = () => {
      if (!reports.length) {
        return [];
      }
      const foundReports = reports.every((r) => {
        return !!r.name && !!r.query.length;
      })
        ? reports
        : undefined;
      return foundReports;
    };

    const currentReports = getReports();
    if (currentReports && Array.isArray(currentReports)) {
      //Check if all report names are unique
      const duplicateReportNames = currentReports.filter(
        (x) => currentReports.filter((y) => y.name === x.name).length > 1,
      );

      const _reportErrors: Record<string, string> = {};
      for (const duplicateReport of duplicateReportNames) {
        _reportErrors[duplicateReport.id] = "Report names must be unique";
      }

      setReportErrors(_reportErrors);
      if (duplicateReportNames.length > 0) {
        setError("custom_reports", {
          message: "Validation error",
        });
      } else {
        setValue("custom_reports", currentReports, {
          shouldValidate: true,
        });
      }
    }
  }, [reports, setValue, setError, invalidCombinationReportIds.length]);

  useEffect(() => {
    setValue("adaccounts", adaccounts, {
      shouldValidate: true,
    });
  }, [adaccounts, setValue]);
  return (
    <div className="space-y-8">
      <div>
        <FieldLabel id="name" required={true}>
          Ad Accounts
        </FieldLabel>
        <Select
          isMulti={true}
          isLoading={false}
          placeholder="Select one"
          onChange={(v: typeof adAccountOptions) => setAdAccounts(v)}
          options={adAccountOptions}
          value={adaccounts}
          hideSelectedOptions={false}
        />
      </div>
      {!!reports.length && (
        <div>
          <FieldLabel>Custom reports ({reports.length}/20)</FieldLabel>
          <div className="flex flex-col gap-4">
            {reports.map((r) => {
              return (
                <GoogleAdsCustomReport
                  report={r}
                  key={r.id}
                  onChangeReport={changeReport}
                  onRemoveReport={removeReport}
                  validationError={reportErrors[r.id]}
                  connectionId={context.getValues()["connectionId"]}
                  adAccounts={adaccounts}
                  onReportInvalid={onReportInvalid}
                  onReportValid={onReportValid}
                />
              );
            })}
          </div>
        </div>
      )}
      {!displaySimplified && (
        <SecondaryButton
          onClick={() =>
            setReports((prev) => [
              ...prev,
              {
                id: uuid(),
                query: "",
                name: "",
              },
            ])
          }
        >
          Add custom report
        </SecondaryButton>
      )}
    </div>
  );
}
const GoogleAdsReportNames = [
  "account",
  "account_stats",
  "account_hourly_stats",
  "ad_group",
  "ad_group_country_stats",
  "ad_group_province_stats",
  "ad_group_stats",
  "ad_group_hourly_stats",
  "ad",
  "ad_stats",
  "audience_stats",
  "campaign_budget",
  "campaign_criterion",
  "campaign",
  "campaign_stats",
  "campaign_hourly_stats",
  "geo_target",
  "label",
  "mobile_device",
  "operating_system_version",
  "topic",
  "user_interest",
  "user_list",
] as const;
type GoogleAdsReportType = (typeof GoogleAdsReportNames)[number];

export const isGoogleAdsReportType = (s: string): s is GoogleAdsReportType =>
  GoogleAdsReportNames.includes(s as GoogleAdsReportType);
