import FieldLabel from "@/components/primitives/InputLabel";
import { FormType } from "@/integrations";

export default function SettingsResult({
  field,
}: {
  field: Extract<FormType, { type: "settings-result" }>;
}) {
  const { name, label, value } = field;

  return (
    <div className="pb-4">
      <FieldLabel id={name}>{label}</FieldLabel>
      <div dangerouslySetInnerHTML={{ __html: value }}></div>
    </div>
  );
}
