import { useChartTheme } from "@/features/charts/themes";
import { getIntegrationLogo } from "@/integrations";
import { useDarkMode } from "@/providers/DarkModeProvider";

export default function SyncChartCategoryTick(props: {
  x?: number;
  y?: number;
  textAnchor?: string;
  stroke?: string;
  payload?: any;
  width?: number;
  index?: number;
  getLabel?: (index: number) => string;
  getIntegrationId?: (index: number) => string;
}) {
  const { isDarkModeEnabled } = useDarkMode();
  const chartTheme = useChartTheme("stats");

  const { y, payload, index, width, getLabel, getIntegrationId } = props;
  if (payload == null || y == null || index == null || width == null) {
    return <g></g>;
  }

  const integrationId =
    typeof getIntegrationId === "function"
      ? getIntegrationId(index)
      : payload.value;

  const label =
    typeof getLabel === "function" ? getLabel(index) : payload.value;
  let truncatedLabel = label;

  const integrationLogo = getIntegrationLogo(integrationId);
  const bg = isDarkModeEnabled ? integrationLogo?.bg_dark : integrationLogo?.bg;

  const labelX = 30;
  const labelFontSize = 12;

  if (props.width) {
    const labelMaxWidth = props.width - labelX - 10;
    const approximateCharWidth = labelFontSize / 1.6;
    const maxLabelChars = Math.floor(labelMaxWidth / approximateCharWidth);
    if (label.length > maxLabelChars) {
      truncatedLabel = label.slice(0, maxLabelChars) + "…";
    }
  }
  return (
    <g transform={`translate(${0},${y})`}>
      <g>
        <rect x="0" y={-9} width={18} height={18} fill={bg} rx={2} />
        <image x={3} y={-6} width={12} height={12} href={integrationLogo.url} />
      </g>
      <text
        x={30}
        dominantBaseline="central"
        textAnchor="start"
        fill={chartTheme.text}
        fontSize={labelFontSize}
      >
        <tspan>{truncatedLabel}</tspan>
        <title>{label}</title>
      </text>
    </g>
  );
}
