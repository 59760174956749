import Badge, { BadgeVariant } from "./Badge_Legacy";

export type Mode =
  | "NOT_STARTED"
  | "RUNNING"
  | "FAILED"
  | "PAUSED"
  | "RETRYING"
  | "DELAYED";

export default function SyncStatusLabel(props: { mode: Mode }) {
  switch (props.mode) {
    case "NOT_STARTED":
      return <Badge variant={BadgeVariant.Inactive}>Not Started</Badge>;
    case "RUNNING":
      return <Badge variant={BadgeVariant.Success}>Active</Badge>;
    case "FAILED":
      return <Badge variant={BadgeVariant.Error}>Failed</Badge>;
    case "RETRYING":
    case "DELAYED":
      return <Badge variant={BadgeVariant.Info}>Delayed</Badge>;
    case "PAUSED":
      return <Badge variant={BadgeVariant.Info}>Stopped</Badge>;
  }
}
