import {
  ListModelsQuery,
  MaterializationType,
  OrchestrationSchedulerType,
} from "@/apollo/types";
import { atom, useAtom } from "jotai";
import { uniq } from "lodash";
import React, {
  ContextType,
  createContext,
  useContext,
  useReducer,
  useRef,
} from "react";

import { DraftModel } from "./QueryEditor/useModelDraft";
import { extractWeldTags } from "./useQueryDependencies";

const schemaSidebarAtom = atom<string | null>(null);
export const useSchemaSidebar = () => useAtom(schemaSidebarAtom);

export type ModelType = "model" | "draft";

type CurrentModelState = {
  initialized: boolean;
  currentModelId: string;
  currentModelType: ModelType;
  modelName: string;
  selectedHistoryItem: number;
  materializationType: MaterializationType;
  orchestrationScheduler?: OrchestrationSchedulerType;
  orchestrationWorkflowId?: string;
  materializationSchedule?: string;
  newWorkflow: { name: string; cron?: string };
};

export type ActionType =
  | { type: "initialize_model"; payload?: ListModelsQuery["models"][0] }
  | {
      type: "initialize_draft";
      payload?: DraftModel;
    }
  | {
      type: "reset";
    }
  | { type: "set_initial_sql"; payload: string }
  | { type: "change_sql_query"; payload: string }
  | { type: "change_selected_history_item"; payload: number }
  | {
      type: "materialization_type_changed";
      payload: {
        materializationType: MaterializationType;
      };
    }
  | {
      type: "orchestration_scheduler_changed";
      payload: {
        orchestrationScheduler?: OrchestrationSchedulerType;
        orchestrationWorkflowId?: string;
      };
    }
  | {
      type: "materialization_schedule_cron_changed";
      payload?: string; //cron
    }
  | {
      type: "set_new_workflow_name";
      payload: string;
    }
  | {
      type: "set_new_workflow_cron";
      payload: string;
    };

const modelReducer = (
  state: CurrentModelState,
  action: ActionType,
): CurrentModelState => {
  switch (action.type) {
    case "initialize_model":
      if (!action.payload)
        return {
          ...initialModelState,
        };
      return {
        ...state,
        initialized: true,
        currentModelId: action.payload.id,
        currentModelType: "model",
        modelName: action.payload.name,
        materializationType: action.payload.materializationType,
        materializationSchedule:
          action.payload.materializationSchedule || undefined,
        orchestrationScheduler:
          action.payload.orchestrationScheduler ||
          OrchestrationSchedulerType.Local,
        orchestrationWorkflowId:
          action.payload.orchestrationWorkflow?.id || undefined,
        selectedHistoryItem: -1,
        newWorkflow: { name: "" },
      };
    case "initialize_draft":
      if (!action.payload)
        return {
          ...initialModelState,
        };

      return {
        ...initialModelState,
        initialized: true,
        currentModelId: action.payload.id,
        currentModelType: "draft",
        modelName:
          action.payload.name +
          `${action.payload.number ? ` (${action.payload.number})` : ""}`,
      };
    case "reset":
      return {
        ...initialModelState,
      };
    case "change_selected_history_item": {
      return { ...state, selectedHistoryItem: action.payload };
    }
    case "materialization_type_changed":
      return {
        ...state,
        materializationType: action.payload.materializationType,
      };
    case "materialization_schedule_cron_changed":
      return {
        ...state,
        materializationSchedule: action.payload,
      };
    case "orchestration_scheduler_changed":
      return {
        ...state,
        orchestrationScheduler: action.payload.orchestrationScheduler,
        orchestrationWorkflowId: action.payload.orchestrationWorkflowId,
      };
    case "set_new_workflow_name":
      return {
        ...state,
        newWorkflow: { ...state.newWorkflow, name: action.payload },
      };

    case "set_new_workflow_cron":
      return {
        ...state,
        newWorkflow: { ...state.newWorkflow, cron: action.payload },
      };
    default:
      return state;
  }
};

const initialModelState: CurrentModelState = {
  initialized: false,
  currentModelId: "",
  currentModelType: "draft",
  modelName: "",
  selectedHistoryItem: -1,
  materializationType: MaterializationType.View,
  newWorkflow: { name: "" },
  orchestrationScheduler: OrchestrationSchedulerType.Local,
  materializationSchedule: "0 0 * * *",
};

type ModelTextState = {
  weldSql: string;
  defaultSql: string;
  dependencyReferences: string[];
};

const initialTextState: ModelTextState = {
  weldSql: "",
  defaultSql: "",
  dependencyReferences: [],
};

const textStateReducer = (
  state: ModelTextState,
  action: ActionType,
): ModelTextState => {
  const dependencyReferencesReducer = (state: string[], weldSql: string) => {
    const newReferences = extractWeldTags(weldSql);
    if (
      state.length === newReferences.length &&
      state.every((ref) => newReferences.includes(ref))
    ) {
      // nothing changed
      return state;
    }
    return newReferences;
  };

  switch (action.type) {
    case "initialize_model":
      if (!action.payload) {
        return {
          ...initialTextState,
        };
      }
      return {
        ...state,
        defaultSql: "",
        weldSql: "",
        dependencyReferences: uniq(
          action.payload.publishedQuery?.dependencies?.map((x) => x.weldTag) ??
            [],
        ),
      };
    case "initialize_draft":
      if (!action.payload) {
        return { ...initialTextState };
      }

      return {
        ...initialTextState,
        defaultSql: "",
        weldSql: action.payload.weldSql,
        dependencyReferences: dependencyReferencesReducer(
          state.dependencyReferences,
          action.payload.weldSql,
        ),
      };
    case "reset":
      return {
        ...initialTextState,
      };
    case "set_initial_sql":
      return {
        ...state,
        defaultSql: action.payload,
      };

    case "change_sql_query":
      return {
        ...state,
        weldSql: action.payload,
        dependencyReferences: dependencyReferencesReducer(
          state.dependencyReferences,
          action.payload,
        ),
      };
    default:
      return state;
  }
};

const ModelEditorContext = createContext<CurrentModelState | null>(null);

const ModelEditorTextContext = createContext<
  (ModelTextState & { isDirty: boolean }) | null
>(null);

const ModelEditorTextGetterContext = createContext<
  (() => ModelTextState & { isDirty: boolean }) | null
>(null);

const ModelEditorStateDispatchContext =
  createContext<React.Dispatch<ActionType> | null>(null);

const ModelEditorDependencyReferencesContext = createContext<string[] | null>(
  null,
);
const ModelEditorDirtyStateContext = createContext<boolean | null>(null);

export const useModelEditorState = () => {
  const model = useContext(ModelEditorContext);
  if (model === null) {
    throw new Error("ModelEditorContext not found");
  }
  return model;
};

// Use with caution, will cause re-renders on every change to the model
export const useModelEditorTextState = () => {
  const state = useContext(ModelEditorTextContext);
  if (!state) {
    throw new Error("ModelEditorTextContext not found");
  }
  return state;
};

export const useGetEditorTextState = () => {
  const getTextState = useContext(ModelEditorTextGetterContext);
  if (!getTextState) {
    throw new Error("ModelEditorTextGetterContext not found");
  }
  return getTextState;
};

export const useModelEditorDispatch = () => {
  const dispatch = useContext(ModelEditorStateDispatchContext);
  if (!dispatch) {
    throw new Error("ModelEditorStateDispatchContext not found");
  }
  return dispatch;
};

export const useModelEditorDependencyReferences = () => {
  const value = useContext(ModelEditorDependencyReferencesContext);
  if (value === null) {
    throw new Error("ModelEditorDependencyReferencesContext not found");
  }
  return value;
};

export const useModelEditorDirtyState = () => {
  const value = useContext(ModelEditorDirtyStateContext);
  if (value === null) {
    throw new Error("ModelEditorDirtyStateContext not found");
  }
  return value;
};

function useStableTextStateGetter(state: ModelTextState) {
  const textStateRef = useRef<ModelTextState & { isDirty: boolean }>({
    ...state,
    isDirty: false,
  });

  textStateRef.current = {
    ...state,
    isDirty: state.weldSql !== state.defaultSql,
  };

  const getTextState =
    useRef<ContextType<typeof ModelEditorTextGetterContext>>();
  if (!getTextState.current) {
    getTextState.current = () => {
      return textStateRef.current;
    };
  }

  return {
    textStateRef,
    getTextState: getTextState as React.MutableRefObject<
      () => ModelTextState & { isDirty: boolean }
    >,
  };
}

export function ModelEditorStoreProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [textState, textDispatch] = useReducer(
    textStateReducer,
    initialTextState,
  );
  const [modelState, modelDispatch] = useReducer(
    modelReducer,
    initialModelState,
  );

  const { getTextState, textStateRef } = useStableTextStateGetter(textState);

  const dispatchRef = useRef<React.Dispatch<ActionType>>();
  if (!dispatchRef.current) {
    dispatchRef.current = (action: ActionType) => {
      textDispatch(action);
      modelDispatch(action);
    };
  }

  return (
    <ModelEditorContext.Provider value={modelState}>
      <ModelEditorTextContext.Provider value={textStateRef.current}>
        <ModelEditorTextGetterContext.Provider value={getTextState.current}>
          <ModelEditorStateDispatchContext.Provider value={dispatchRef.current}>
            <ModelEditorDependencyReferencesContext.Provider
              value={textStateRef.current.dependencyReferences}
            >
              <ModelEditorDirtyStateContext.Provider
                value={textStateRef.current.isDirty}
              >
                {children}
              </ModelEditorDirtyStateContext.Provider>
            </ModelEditorDependencyReferencesContext.Provider>
          </ModelEditorStateDispatchContext.Provider>
        </ModelEditorTextGetterContext.Provider>
      </ModelEditorTextContext.Provider>
    </ModelEditorContext.Provider>
  );
}
