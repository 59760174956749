import { ComponentProps } from "react";
import invariant from "tiny-invariant";

import {
  PlanName,
  useBestFitPlanSuspenseQuery,
  useStaticCheckoutPreviewByLookupKeySuspenseQuery,
  useStripeSubscriptionSuspenseQuery,
} from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import { Skeleton } from "@/components/elements/Skeleton";
import { Heading } from "@/components/elements/Typography";
import { WELD_EXISTING_CUSTOMER_CALL_URL } from "@/constants/external-urls/hubspot";
import { WELD_PRICING_URL } from "@/constants/external-urls/weld-website";
import cn from "@/helpers/classNames";
import { IntegrationLogo } from "@/integrations";
import { useCurrentAccount } from "@/providers/account";
import { formatCurrency } from "@/shared/formatters";
import {
  ArrowTopRightOnSquareIcon,
  XMarkIcon,
} from "@heroicons/react/16/solid";
import { CalendarDaysIcon, RocketLaunchIcon } from "@heroicons/react/20/solid";

import { formatPlanName, useCheckoutModal } from "../../../billing";

export function BestFitLoader() {
  return (
    <div className="flex flex-col">
      <PrimaryBox>
        <Heading className="">Best Fit Plan</Heading>
        <div className="mt-3">
          <div className="flex h-9 items-center">
            <Skeleton className="h-6 w-32 opacity-40" />
          </div>
        </div>
        <div className="mt-3 space-y-2 text-sm">
          <Skeleton className="h-4 w-full opacity-40" />
          <Skeleton className="h-4 w-2/3 opacity-40" />
        </div>
      </PrimaryBox>
      <SecondaryBox>
        <Heading>Current usage</Heading>
        <div className="mt-2 space-y-2">
          <Skeleton className="h-4 w-36" />
          <Skeleton className="h-4 w-28" />
        </div>
      </SecondaryBox>
    </div>
  );
}

function useBestFitPlanProjection() {
  const { data } = useBestFitPlanSuspenseQuery({
    fetchPolicy: "cache-and-network",
  });
  const { plan, workspaceStats, integrationIds } = data.bestFitPlan;

  const account = useCurrentAccount();

  const {
    data: {
      accountBySlug: { stripeCustomer },
    },
  } = useStripeSubscriptionSuspenseQuery({
    variables: {
      slug: account.slug,
    },
  });

  const currency = stripeCustomer?.currency;

  invariant(currency, "Currency should be defined");

  const { data: staticPreviewData } =
    useStaticCheckoutPreviewByLookupKeySuspenseQuery({
      variables: {
        input: {
          currency,
          numConnectors: workspaceStats.numConnections,
          numUsers: workspaceStats.numUsers,
          priceLookupKey: plan.priceLookupKeys?.monthly ?? "",
        },
      },
      skip: plan.name === PlanName.Business,
    });

  const planPrice: number | undefined =
    staticPreviewData?.getStaticPreviewByLookupKey.total;
  return {
    plan,
    workspaceStats,
    integrationIds,
    planPrice,
    currency,
  };
}

export function BestFitPlanProjection() {
  const { openCheckoutModal } = useCheckoutModal();

  const { plan, workspaceStats, integrationIds, planPrice, currency } =
    useBestFitPlanProjection();

  return (
    <div className="flex flex-col">
      <PrimaryBox>
        <Heading className="">Best Fit Plan</Heading>
        <div className="mt-3 text-3xl font-semibold">
          {formatPlanName(plan?.name)}
        </div>
        <div className="mt-3 space-y-2 text-sm">
          <p>
            The best fit plan is tailored to your current usage, focusing on the
            connectors you’ve set up and their plan requirements.
          </p>
          <p>
            Our new plans offer <strong>fixed, predictable pricing</strong>{" "}
            without considering data volume. Instead, we've introduced a{" "}
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href="https://weld.app/fair-usage"
              className="inline-flex items-center whitespace-nowrap underline"
              target="_blank"
            >
              fair usage policy
              <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
            </a>{" "}
            to accommodate most needs.
          </p>
        </div>
      </PrimaryBox>
      <SecondaryBox>
        <div className="grid gap-4 md:grid-cols-2">
          <div>
            <Heading>Current usage</Heading>
            <ul className="mt-2 space-y-2">
              <li className="flex flex-col gap-1">
                <div className="inline-flex">
                  <span className="inline-flex w-7">
                    <strong>{workspaceStats.numConnections} </strong>
                    <XMarkIcon className="mt-[2px] h-4 w-4 self-center" />
                  </span>
                  <span>Connectors</span>
                </div>
                <div className="flex flex-wrap gap-2 pl-7">
                  {integrationIds.map((id) => (
                    <IntegrationLogo id={id} key={id} className="h-4 w-4" />
                  ))}
                </div>
              </li>
              <li className="inline-flex">
                <div className="inline-flex">
                  <span className="inline-flex w-7">
                    <strong>{workspaceStats.numUsers}</strong>
                    <XMarkIcon className="mt-[2px] h-4 w-4 self-center" />
                  </span>
                  <span>Users</span>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <Heading>Price on {formatPlanName(plan.name)}</Heading>
            <div className="mt-2">
              {plan.name === PlanName.Business ? (
                <>
                  <div>Get a custom plan tailored to your business</div>
                  <Button
                    className="mt-4"
                    variant="solid"
                    colorScheme="primary"
                    as="a"
                    href={WELD_EXISTING_CUSTOMER_CALL_URL}
                    target="_blank"
                    size="lg"
                    icon={<CalendarDaysIcon />}
                  >
                    Get in touch with us
                  </Button>
                </>
              ) : (
                <>
                  <div className="flex items-center gap-2">
                    <div className="text-2xl font-semibold">
                      {formatCurrency(planPrice, {
                        currency,
                      })}
                      <span className="text-sm font-normal"> per month</span>
                    </div>
                  </div>
                  <div className="mt-2 text-sm text-muted-foreground">
                    Get <strong>20% off</strong> when you pay annually
                  </div>
                  <Button
                    className="mt-4"
                    colorScheme="primary"
                    variant="solid"
                    onClick={() => openCheckoutModal({ planName: plan.name })}
                    icon={<RocketLaunchIcon />}
                  >
                    Upgrade to {formatPlanName(plan.name)}
                  </Button>
                </>
              )}
              <div className="mt-2">
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                  href={WELD_PRICING_URL}
                  target="_blank"
                  className="inline-flex items-center whitespace-nowrap text-sm text-muted-foreground underline"
                >
                  Explore our plans{" "}
                  <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </SecondaryBox>
    </div>
  );
}

function PrimaryBox(props: ComponentProps<"div">) {
  return (
    <div
      {...props}
      className={cn(
        "flex-1 rounded-t-lg border border-b-0 bg-blue-600 p-5 text-white",
        props.className,
      )}
    />
  );
}

function SecondaryBox(props: ComponentProps<"div">) {
  return (
    <div
      {...props}
      className={cn("flex-1 rounded-b-lg border p-5", props.className)}
    />
  );
}
