import * as parser from "cron-parser";

import { Resolution } from "./types";

export function resolutionFromCronExpression(
  cronExpression?: string | null,
): Resolution {
  if (!cronExpression) {
    return "day";
  }
  const cron = parser.parseExpression(cronExpression);
  const duration = cron.next().getTime() - cron.prev().getTime();
  if (duration <= 60 * 60 * 1000) {
    return "hour";
  }
  if (duration <= 24 * 60 * 60 * 1000) {
    return "day";
  }
  return "week";
}
