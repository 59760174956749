import { useMemo } from "react";
import { Tile, VisualizationType } from "../VisualizationType";

export const useValidateTile = (tile: Tile) => {
  const isValid = useMemo(() => {
    if (
      tile.visualization.type === VisualizationType.Bar ||
      tile.visualization.type === VisualizationType.Line
    ) {
      //x value must be a non empty string
      const xValid =
        typeof tile.visualization?.keys.x === "string" &&
        tile.visualization?.keys.x.length > 0;

      //y value must be an non empty array of strings
      const yValid =
        Array.isArray(tile.visualization?.keys.y) &&
        tile.visualization?.keys.y.length > 0;

      return xValid && yValid;
    }
    if (tile.visualization.type === VisualizationType.Metric) {
      return !!tile.visualization?.metricColumn;
    }
    return true;
  }, [tile]);
  return isValid;
};
