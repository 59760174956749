import { WeldAddOnType, useAvailableAddOnProductsQuery } from "@/apollo/types";

export function useAvailablePlanAddOns() {
  const { data } = useAvailableAddOnProductsQuery();
  const addOns = data?.getAvailableAddOnProducts ?? [];
  return {
    addOns,
    getByType(type: WeldAddOnType) {
      return addOns.filter((addOn) => addOn.addOnType === type);
    },
  };
}
