import { ComponentProps } from "react";

import { useModelQuery } from "@/apollo/types";
import cn from "@/helpers/classNames";
import { ModelIcon } from "@/pages/ModelTool/components/ModelIcon/ModelIcon";
import { useFullFolderPath } from "@/pages/ModelTool/hooks/useFullPathNames";

export function ModelDisplayName<
  T extends { id: string; type: "model" | "draft"; name: string },
>(props: { model: T } & ComponentProps<"span">) {
  const { model: modelProp, ...spanProps } = props;

  const { data } = useModelQuery({
    variables: {
      modelId: props.model.id ?? "",
    },
    skip: !props.model.id || props.model.type !== "model",
  });
  const model = data?.model;
  const folderPath = useFullFolderPath(model?.folder?.id);
  const modelName = model?.name ?? props.model.name ?? "?";
  return (
    <span
      {...spanProps}
      className={cn(
        "flex min-w-0 select-none items-center",
        spanProps.className,
      )}
      title={folderPath.concat(modelName).join(".")}
    >
      {model && <ModelIcon model={model} />}
      {folderPath.length > 0 && (
        <span className="truncate">{folderPath.join(".")}.</span>
      )}
      <span className="truncate">{modelName}</span>
    </span>
  );
}
