import {
  ConnectionWithCredentialsQuery,
  ConnectionsQuery,
} from "@/apollo/types";
import {
  CollapsibleAlertTable,
  useFilteredNotifications,
} from "@/features/notifications";

export const ConnectionsNotifications = (props: {
  connections: ConnectionsQuery["connections"];
}) => {
  return (
    <NotificationsByConnectionIds
      connectionIds={props.connections.map((c) => c.id)}
    />
  );
};

export const ConnectionNotifications = (props: {
  connection: ConnectionWithCredentialsQuery["connection"];
}) => {
  return <NotificationsByConnectionIds connectionIds={props.connection.id} />;
};

const NotificationsByConnectionIds = (props: {
  connectionIds: string | string[];
}) => {
  const { notifications } = useFilteredNotifications({
    connectionIds: Array.isArray(props.connectionIds)
      ? props.connectionIds
      : [props.connectionIds],
  });
  if (notifications.count === 0) {
    return null;
  }
  return (
    <CollapsibleAlertTable
      notifications={notifications}
      triggerText={`${
        notifications.count === 1
          ? `${notifications.count} error`
          : `${notifications.count} errors`
      } affecting your ${
        props.connectionIds.length === 1 ? "connection" : "connections"
      }`}
    />
  );
};

export default ConnectionNotifications;
