import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  ChatThreadFragment,
  DependencyType,
  useChatThreadsQuery,
} from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import { List } from "@/components/elements/List";
import Tooltip from "@/components/elements/Tooltip";
import { TextMuted } from "@/components/elements/Typography";
import cn from "@/helpers/classNames";
import { IntegrationLogoBox } from "@/integrations";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { ComponentProps } from "@tw-classed/react";

import { ChatThreadTableReference } from "../types";

dayjs.extend(isSameOrAfter);

type TimeBucket = {
  name: string;
  predicate: (timestamp: dayjs.ConfigType) => boolean;
};

const timeBuckets: TimeBucket[] = [
  {
    name: "Today",
    predicate: (timestamp) => {
      return dayjs(timestamp).isSame(dayjs(), "day");
    },
  },
  {
    name: "Yesterday",
    predicate: (timestamp) => {
      const yesterday = dayjs().subtract(1, "day");
      return dayjs(timestamp).isSame(yesterday, "day");
    },
  },
  {
    name: "Last 7 Days",
    predicate: (timestamp) => {
      const oneWeekAgo = dayjs().subtract(7, "day").startOf("day");
      return dayjs(timestamp).isSameOrAfter(oneWeekAgo, "day");
    },
  },
  {
    name: "Last 30 Days",
    predicate: (timestamp) => {
      const oneMonthAgo = dayjs().subtract(30, "day").startOf("day");
      return dayjs(timestamp).isSameOrAfter(oneMonthAgo, "day");
    },
  },
];

const PAGINATION_LIMIT = 20;

export function ChatHistory(props: {
  onSelectThread: (thread: ChatThreadFragment) => void;
  emptyState?: React.ReactNode;
}) {
  const {
    data,
    fetchMore,
    loading: isLoadingChats,
  } = useChatThreadsQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      offset: 0,
      limit: PAGINATION_LIMIT,
    },
  });

  const hasMoreChats =
    !data?.chatThreads ||
    (data.chatThreads.length !== 0 &&
      data.chatThreads.length % PAGINATION_LIMIT === 0);

  const groups = useMemo(() => {
    if (!data?.chatThreads) return [];

    const groups = data.chatThreads.reduce<{
      [key: string]: ChatThreadFragment[];
    }>(
      (acc, thread) => {
        const bucket = timeBuckets.find((bucket) =>
          bucket.predicate(thread.updatedAt),
        );
        if (bucket) {
          acc[bucket.name].push(thread);
        } else {
          const isOtherYear = dayjs(thread.updatedAt).year() !== dayjs().year();
          const bucketName = dayjs(thread.updatedAt).format(
            isOtherYear ? "MMMM YYYY" : "MMMM",
          );
          if (!acc[bucketName]) {
            acc[bucketName] = [];
          }
          acc[bucketName].push(thread);
        }
        return acc;
      },
      {
        Today: [],
        Yesterday: [],
        "Last 7 Days": [],
        "Last 30 Days": [],
        Older: [],
      },
    );
    return Object.entries(groups)
      .filter(([, threads]) => threads.length > 0)
      .map(([name, items]) => ({
        name,
        items,
      }));
  }, [data?.chatThreads]);

  const containerRef = useRef<HTMLDivElement>(null);
  const stickyHeaderId = useStickyHeader(containerRef, "[id^=ch-]");
  return (
    <div ref={containerRef} className="isolate h-full grow overflow-auto">
      {groups.length === 0 && props.emptyState && <>{props.emptyState}</>}
      <List as="div" className="overflow-visible p-0">
        {groups.map((group) => (
          <div
            key={group.name}
            className="border-b border-gray-100 pb-5 last-of-type:border-b-0 dark:border-gray-700"
          >
            <TextMuted
              id={"ch-" + group.name}
              as="h3"
              className={cn(
                "sticky top-0 z-[1] mt-2 bg-white px-4 py-2 text-center text-xs font-semibold uppercase dark:bg-gray-800",
                {
                  "shadow-md shadow-black/10 dark:shadow dark:shadow-black/80":
                    stickyHeaderId === "ch-" + group.name,
                },
              )}
            >
              {group.name}
            </TextMuted>
            <List className="p-0">
              {group.items.map((thread) => (
                <List.Item key={thread.id} className="p-0">
                  <ChatThreadListItem
                    key={thread.id}
                    onClick={() => props.onSelectThread(thread)}
                    thread={thread}
                  />
                </List.Item>
              ))}
            </List>
          </div>
        ))}
      </List>
      {hasMoreChats && (
        <div className="flex justify-center py-4">
          <Button
            variant="solid"
            size="sm"
            isLoading={isLoadingChats}
            loadingText="Loading..."
            onClick={() =>
              fetchMore({ variables: { offset: data?.chatThreads.length } })
            }
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  );
}

function useStickyHeader(
  containerRef: React.RefObject<HTMLElement>,
  selector: string,
) {
  const [stickyItem, setStickyItem] = useState<string>();

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const children = Array.from(
          container.querySelectorAll(selector),
        ) as HTMLElement[];
        const containerRect = container.getBoundingClientRect();

        let stickyItemFound = false;
        for (const child of children) {
          const childRect = child.getBoundingClientRect();
          const isSticky = childRect.top === containerRect.top;
          if (isSticky) {
            stickyItemFound = true;
            setStickyItem(child.id);
            break;
          }
        }
        if (!stickyItemFound) {
          setStickyItem(undefined);
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    handleScroll();
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [containerRef, selector]);
  return stickyItem;
}

function ChatThreadListItem(
  props: ComponentProps<typeof List.ItemButton> & {
    thread: ChatThreadFragment;
  },
) {
  const { thread, ...restProps } = props;
  const tableReferences: ChatThreadTableReference[] =
    thread.tableReferences ?? [];

  const referencesByIntegration = tableReferences.reduce(
    (acc, ref) => {
      if (!(ref.type === DependencyType.RawView && ref.integrationId)) {
        return acc;
      }
      if (!acc[ref.integrationId]) {
        acc[ref.integrationId] = {
          integrationId: ref.integrationId,
          tables: [],
        };
      }
      acc[ref.integrationId].tables.push(ref);
      return acc;
    },
    {} as {
      [integrationId: string]: {
        integrationId: string;
        tables: ChatThreadTableReference[];
      };
    },
  );
  const referencesByIntegrationArray = Object.values(referencesByIntegration);

  const models = tableReferences.filter((x) => {
    return (
      x.type === DependencyType.MaterializedTable ||
      x.type === DependencyType.ModelView
    );
  });

  const modelCount = models.length + (thread.initialModelContext ? 1 : 0);
  return (
    <List.ItemButton
      className="w-full flex-col items-start gap-2 py-3 text-left"
      {...restProps}
    >
      <div className="flex w-full items-center gap-2">
        <span className="truncate text-sm">{thread.title}</span>
        <div className="ml-auto flex gap-2">
          {modelCount > 0 && (
            <Tooltip
              content={
                <div className="flex flex-col gap-1">
                  {thread.initialModelContext && (
                    <span>{thread.initialModelContext.name}</span>
                  )}
                  {models.map((x) => (
                    <span key={x.id}>{x.weldTag}</span>
                  ))}
                </div>
              }
            >
              <div className="flex h-4 items-center justify-center rounded-sm bg-gray-200 px-0.5 dark:bg-gray-600">
                {modelCount > 1 && (
                  <span className="mr-0.5 text-xs tabular-nums text-gray-800 dark:text-gray-300">
                    {modelCount}
                  </span>
                )}
                <DocumentTextIcon className="h-3/4" />{" "}
              </div>
            </Tooltip>
          )}
          {referencesByIntegrationArray.length > 0 && (
            <div className="ml-auto flex gap-1">
              {referencesByIntegrationArray.map((x) => (
                <Tooltip
                  content={
                    <>
                      {x.tables.map((t) => (
                        <React.Fragment key={t.weldTag}>
                          <span>{t.weldTag}</span>
                          <br />
                        </React.Fragment>
                      ))}
                    </>
                  }
                  key={x.integrationId}
                >
                  <span>
                    <IntegrationLogoBox size="1xs" id={x.integrationId} />
                  </span>
                </Tooltip>
              ))}
            </div>
          )}
        </div>
      </div>
    </List.ItemButton>
  );
}
