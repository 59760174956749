import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "@tanstack/react-location";
import {
  ListOrchestrationWorkflowsDocument,
  useCreateWorkflowMutation,
} from "@/apollo/types";
import { ActionButton } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import FormLayout from "@/components/layouts/FormLayout";
import { TaskAction } from "@/components/modules/TaskActionBar";
import {
  AdvancedSyncScheduler,
  DefaultOptions,
} from "@/components/modules/WeldSyncScheduler";
import { Input } from "@/components/primitives/input";
import FieldLabel from "@/components/primitives/InputLabel";
import { ValidationErrorDisplay } from "@/pages/ModelTool/utils/dwNamingValidation";
import { useToast } from "@/providers/ToastProvider";
import { useCallback } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

type Props = {};

type NewWorkflowForm = {
  name: string;
  cronExpression: string;
};

const SetupWorkflow = (props: Props) => {
  // const { models } = useListModels();
  // // const amountOfMaterializedTables = useMemo(
  // //   () =>
  // //     models.filter(
  // //       (m) =>
  // //         m.materializationType === MaterializationType.Table &&
  // //         m.orchestrationScheduler === OrchestrationSchedulerType.Global
  // //     ).length,
  // //   [models]
  // // );

  const navigate = useNavigate();

  const toast = useToast();
  const form = useForm<NewWorkflowForm>({
    defaultValues: {
      name: "",
      cronExpression: DefaultOptions["1d"].value,
    },
    reValidateMode: "onChange",
  });

  const [createWorkflow, createWorkflowMutation] = useCreateWorkflowMutation({
    onCompleted: (data) => {
      navigate({ to: `../${data.createWorkflow.id}` });
    },
    onError: (error) => {
      toast(
        "Orchestration setup error",
        "Error setting up new orchestration.",
        "error",
      );
    },
    refetchQueries: [{ query: ListOrchestrationWorkflowsDocument }],
    awaitRefetchQueries: true,
  });

  const handleSubmit: SubmitHandler<NewWorkflowForm> = useCallback(
    async (values) => {
      if (createWorkflowMutation.loading) return;

      createWorkflow({
        variables: {
          name: values.name,
          cronExpression: values.cronExpression,
        },
      });
    },
    [createWorkflow, createWorkflowMutation.loading],
  );

  const handleChangeCron = useCallback(
    (cron: string | undefined) => {
      if (cron) {
        form.setValue("cronExpression", cron);
        form.trigger("cronExpression");
      }
    },
    [form],
  );

  return (
    <FormLayout>
      <div className="py-8">
        <div className="mb-4 text-lg font-medium">New Orchestration</div>
        <div className="space-y-8">
          <div>
            <FieldLabel>Orchestration Name</FieldLabel>
            <Input
              autoFocus
              autoComplete="off"
              placeholder="Main workflow..."
              {...form.register("name", {
                required: true,
                onChange: (e) => {
                  form.setValue("name", e.target.value);
                  form.trigger("name");
                },
              })}
            />
            {form.formState.errors.name && (
              <ValidationErrorDisplay error={form.formState.errors.name} />
            )}
          </div>

          <Controller
            control={form.control}
            name="cronExpression"
            rules={{
              required: true,
            }}
            render={({ field: { value } }) => (
              <div>
                <FieldLabel>Frequency</FieldLabel>
                <AdvancedSyncScheduler
                  onChange={handleChangeCron}
                  cron={value}
                />
              </div>
            )}
          />

          {/* <WorkflowTableSelecter
            amountOfMaterializedTables={amountOfMaterializedTables}
          /> */}
        </div>

        <TaskAction>
          <ActionButton
            onClick={form.handleSubmit(handleSubmit)}
            className="flex items-center justify-center space-x-2"
            disabled={!form.formState.isValid}
          >
            <span>Create orchestration</span>
            {createWorkflowMutation.loading && <LoadingSpinner />}
          </ActionButton>
        </TaskAction>
      </div>
    </FormLayout>
  );
};

export default SetupWorkflow;

// this one is depreceated since multiple workflow changes ..
// we should probably add a selecter for what to include in the workflow instead
export const WorkflowTableSelecter = (props: {
  amountOfMaterializedTables: number;
}) => {
  return (
    <div>
      <FieldLabel>Included models in orchestration</FieldLabel>
      <div className="flex space-x-8 bg-gray-100 px-6 py-4 text-sm dark:bg-gray-700">
        <div className="flex-1 space-y-1">
          <div className="text-gray-700 dark:text-gray-300">
            Materializations
          </div>
          <div className="font-medium">
            {props.amountOfMaterializedTables} tables
          </div>
        </div>
        <div className="flex-1 space-y-1">
          <div className="text-gray-700 dark:text-gray-300">Tests</div>
          <div className="inline-block rounded border bg-gray-300 px-3 py-1 font-medium opacity-50 dark:border-gray-500 dark:bg-gray-600">
            Coming soon
          </div>
        </div>
      </div>
      <div className="flex space-x-2 py-4">
        <InformationCircleIcon className="h-5 w-5 flex-none" />
        <div className="text-sm">
          Includes all models configured to use the orchestrated scheduler. New
          models published will automatically be included in the next update.
        </div>
      </div>
    </div>
  );
};
