import { Suspense, lazy, useState } from "react";

import { IconButton } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { SlideOver } from "@/components/elements/SlideOver";
import Tooltip from "@/components/elements/Tooltip";
import Center from "@/components/elements/layout/Center";
import {
  ArrowLeftStartOnRectangleIcon,
  ArrowRightEndOnRectangleIcon,
} from "@heroicons/react/24/outline";

import { useViewDataSourceSlideOver } from "./useViewDataSource";

const ViewDataSource = lazy(() => import("./ViewDataSource"));

export function ViewDataSourcSlideOverContainer({
  show,
  syncId,
}: {
  show?: boolean;
  syncId?: string;
}) {
  const { onClose } = useViewDataSourceSlideOver();
  const [view, setView] = useState<"minimal" | "expanded">("minimal");

  let config =
    view === "minimal"
      ? {
          size: "xl" as const,
          icon: <ArrowLeftStartOnRectangleIcon />,
          text: "Expand View",
        }
      : {
          size: "4xl" as const,
          icon: <ArrowRightEndOnRectangleIcon />,
          text: "Minimize View",
        };

  return (
    <SlideOver show={!!show} onClose={onClose} size={config.size} bgOverlay>
      <Suspense
        fallback={
          <Center className="h-full w-full">
            <LoadingSpinner />
          </Center>
        }
      >
        {syncId && <ViewDataSource syncId={syncId} onClose={onClose} />}
      </Suspense>
      <div className="absolute left-0 top-6">
        <Tooltip content={config.text}>
          <IconButton
            icon={config.icon}
            className="-translate-x-1/2 rounded-full bg-white dark:bg-gray-800"
            variant="outline"
            onClick={() =>
              setView((prev) => (prev === "minimal" ? "expanded" : "minimal"))
            }
          />
        </Tooltip>
      </div>
      {/* <div className="absolute right-2 top-2">
        <div className="flex items-center gap-8">
          <Button
            icon={config.icon}
            onClick={() =>
              setView((prev) => (prev === "minimal" ? "expanded" : "minimal"))
            }
            size="sm"
          >
            {config.text}
          </Button>
          <SlideOverCloseButton className="static" />
        </div>
      </div> */}
    </SlideOver>
  );
}
