import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import { useCallback, useRef, useState } from "react";

async function* processReadableStream(
  reader: ReadableStreamDefaultReader,
  signal: AbortSignal,
) {
  const decoder = new TextDecoder();
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      return;
    }

    const token = decoder.decode(value);
    yield token;

    if (signal?.aborted) {
      await reader.cancel();
      return;
    }
  }
}

type CompletionStreamOptions = {
  onChunk?: (chunk: string) => void;
  onCompleted?: (value: string) => void;
  onError?: (error: unknown) => void;
};

export function useCompletionStream(options?: CompletionStreamOptions) {
  const [data, setDataResult] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const hookOptionsRef = useLatestValueRef(options);
  const abortControllerRef = useRef<AbortController | null>(null);

  const mutate = useCallback(
    async (
      reader: ReadableStreamDefaultReader<Uint8Array>,
      options?: CompletionStreamOptions,
    ) => {
      setIsLoading(true);
      setDataResult(undefined);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const controller = new AbortController();
      abortControllerRef.current = controller;
      const signal = controller.signal;

      try {
        if (!reader) throw new Error("No reader returned from fetch");

        let chunks: string[] = [];
        for await (const chunk of processReadableStream(reader, signal)) {
          options?.onChunk?.(chunk);
          hookOptionsRef.current?.onChunk?.(chunk);
          chunks.push(chunk);
        }
        const message = chunks.join("");
        options?.onCompleted?.(message);
        hookOptionsRef.current?.onCompleted?.(message);
        setDataResult(message);
        return message;
      } catch (err) {
        if (err instanceof Error && err.name === "AbortError") {
          return; // abort errors are expected
        }
        options?.onError?.(err);
        hookOptionsRef.current?.onError?.(err);
        setError(err);
      } finally {
        setIsLoading(false);
        abortControllerRef.current = null;
      }
    },
    [hookOptionsRef],
  );

  return [mutate, { data, isLoading, error }] as const;
}
