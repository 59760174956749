import React from "react";
import { twMerge } from "tailwind-merge";

import { MaterializationType } from "@/apollo/types";
import IconWithBG from "@/components/elements/IconWithBG";
import Tooltip from "@/components/elements/Tooltip";
import TableIcon from "@/components/icons/TableIcon";
import {
  DataSourceIcon,
  ReverseEtlIcon,
  WebhookIcon,
} from "@/components/icons/outline";
import { DocumentTextIcon } from "@heroicons/react/24/outline";

export const IconBox = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof IconWithBG>
>((props, ref) => (
  <IconWithBG
    ref={ref}
    className={twMerge("bg-gray-100 dark:bg-gray-700", props.className)}
    size="sm"
    {...props}
  />
));

export const EtlJobBox = (props: { tooltipContent?: string | undefined }) => (
  <Tooltip content={props.tooltipContent || "Data source"}>
    <IconBox
      icon={<DataSourceIcon />}
      className="bg-orange-100 dark:bg-orange-500/50"
    />
  </Tooltip>
);

export const ReverseEtlJobBox = (props: {
  tooltipContent?: string | undefined;
}) => (
  <Tooltip content={props.tooltipContent || "Reverse ELT"}>
    <IconBox
      icon={<ReverseEtlIcon />}
      className="bg-rose-100 dark:bg-rose-500/50"
    />
  </Tooltip>
);

export const WebhookJobBox = (props: {
  tooltipContent?: string | undefined;
}) => (
  <Tooltip content={props.tooltipContent || "Webhook"}>
    <IconBox
      icon={<WebhookIcon />}
      className="bg-green-100 dark:bg-green-500/50"
    />
  </Tooltip>
);

export const ModelJobBox = ({
  materializationType,
  tooltipContent,
}: {
  materializationType: MaterializationType;
  tooltipContent?: string | undefined;
}) => (
  <Tooltip
    content={
      `${
        materializationType === MaterializationType.Table
          ? "Model Materialization job"
          : "Model test job"
        // eslint-disable-next-line no-useless-concat
      }` + `${tooltipContent ? `- ${tooltipContent}` : ""}`
    }
  >
    <IconBox
      icon={
        materializationType === MaterializationType.Table ? (
          <TableIcon />
        ) : (
          <DocumentTextIcon />
        )
      }
    />
  </Tooltip>
);
