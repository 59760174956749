import * as parser from "cron-parser";

export function parseCronExpression(expression: string) {
  expression = expression.replace(/\?/g, "*");
  const result = parser.parseExpression(expression);
  const tokens = tokenizeCronExpression(expression);

  const minuteParseResult = parseTokenExpression(
    tokens.minute,
    Array.from(result.fields.minute),
    60,
  );
  const hourParseResult = parseTokenExpression(
    tokens.hour,
    Array.from(result.fields.hour),
    24,
  );

  const baseExpression = [
    minuteParseResult.expression,
    hourParseResult.expression,
    tokens.dayOfMonth,
    tokens.month,
    tokens.dayOfWeek,
  ].join(" ");

  return {
    result,
    baseExpression,
    timeOffset: {
      hour: hourParseResult.offset,
      minute: minuteParseResult.offset,
      maxHour: hourParseResult.periodLength
        ? hourParseResult.periodLength - 1
        : 0,
      maxMinute: minuteParseResult.periodLength === 60 ? 59 : 0,
    },
  };
}

function parseTokenExpression(
  token: string,
  fields: number[],
  periodMaxLength: number,
) {
  const periodLength = getPeriodLength(fields, periodMaxLength);

  // handle fx. `*` or `*/30` or `0`
  if (/^\*(\/\d+)?$/.test(token) || token === "0" || token === "") {
    return {
      expression: token,
      offset: 0,
      periodLength,
    };
  }

  // handle single digit fx. `2`, i.e. { expression: '0', offset: 2 }
  if (periodLength !== undefined && periodLength === periodMaxLength) {
    return {
      expression: "0",
      offset: parseInt(token, 10),
      periodLength,
    };
  }

  // handle `2, 10, 18`, i.e. { expression: '*/3', offset: 2 }
  if (periodLength !== undefined) {
    return {
      expression: `*/${periodLength}`,
      offset: fields[0],
      periodLength,
    };
  }

  return {
    expression: token,
    offset: 0,
    periodLength,
  };
}

export function tokenizeCronExpression(expression: string) {
  let tokens = expression.split(" ");
  if (tokens.length > 5) {
    // Discard seconds
    tokens = tokens.slice(1);
  }
  const [minute, hour, dayOfMonth, month, dayOfWeek] = tokens;
  return {
    minute,
    hour,
    dayOfMonth,
    month,
    dayOfWeek,
  };
}

export function getPeriodLength(numbers: number[], periodMaxLength: number) {
  if (periodMaxLength % 2 !== 0) {
    return undefined;
  }
  if (numbers.length === 0) {
    return undefined;
  }
  if (numbers.length === 1) {
    return periodMaxLength;
  }
  if (
    numbers.length > periodMaxLength ||
    periodMaxLength % numbers.length !== 0
  ) {
    return undefined;
  }

  const spacings = numbers.reduce<number[]>((acc, n, i, arr) => {
    if (i === 0) {
      return acc;
    }
    return acc.concat(n - arr[i - 1]);
  }, []);

  // Test all elements are the same
  const allNumbersAreEvenlySpaced = spacings.every((n) => n === spacings[0]);
  const hasEvenSpacing =
    allNumbersAreEvenlySpaced &&
    periodMaxLength === spacings[0] * numbers.length;

  if (!hasEvenSpacing) {
    return undefined;
  }
  return spacings[0];
}
