import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import { useRequestConnectorMutation } from "@/apollo/types";
import { useCallbackRef } from "@/hooks/useCallbackRef";

export type FormValues = {
  integrationName: string;
  elt: boolean;
  reverseEtl: boolean;
  destination: boolean;
};

export function useRequestConnectorForm(props: {
  defaultValues?: Partial<FormValues>;
  onSubmitted: (values: FormValues, status: "success" | "duplicate") => void;
}) {
  const onSubmitted = useCallbackRef(props.onSubmitted);

  const [eltRequests, setEltRequests] = useState<string[]>([]);
  const [reverseEtlRequests, setReverseEtlRequests] = useState<string[]>([]);
  const [destinationRequests, setDestinationRequests] = useState<string[]>([]);

  const [requestConnector] = useRequestConnectorMutation();

  const methods = useForm<FormValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      integrationName: "",
      elt: props.defaultValues?.elt ?? true,
      reverseEtl: props.defaultValues?.reverseEtl ?? false,
      destination: props.defaultValues?.destination ?? false,
    },
  });

  const submit = useCallback(
    (values: FormValues) => {
      const integrationName = values.integrationName.toLowerCase().trim();

      const hasRequestedELT = eltRequests.includes(integrationName);
      const hasRequestedReverseETL =
        reverseEtlRequests.includes(integrationName);
      const hasRequestedDestination =
        destinationRequests.includes(integrationName);

      let types: string[] = [];
      if (!hasRequestedELT && values.elt) {
        types.push("elt");
        setEltRequests((p) => [...p, integrationName]);
      }
      if (!hasRequestedReverseETL && values.reverseEtl) {
        types.push("reverse-etl");
        setReverseEtlRequests((p) => [...p, integrationName]);
      }
      if (!hasRequestedDestination && values.destination) {
        types.push("destination");
        setDestinationRequests((p) => [...p, integrationName]);
      }

      if (types.length === 0) {
        onSubmitted(values, "duplicate");
        methods.reset();
        return;
      }

      void requestConnector({
        variables: { integrationName, integrationTypes: types },
      });

      onSubmitted(values, "success");
      methods.reset();
    },
    [
      onSubmitted,
      methods,
      eltRequests,
      reverseEtlRequests,
      destinationRequests,
      requestConnector,
    ],
  );

  return {
    methods,
    submit,
  };
}
