import { DependencyType } from "@/apollo/types";
import { LoadingOverlay } from "@/components/elements/LoadingComponents";
import { nodeTypesWide } from "@/components/elements/reactflow/CustomReactFlowNodes";
import produce from "immer";
import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import { getReactFlowLineageElements } from "@/pages/ModelTool/lineage/Lineage";
import {
  CoreEltSyncNode,
  CoreModelNode,
  CoreRawViewNode,
  LineageNode,
  StagingModelNode,
} from "@/pages/ModelTool/lineage/useLineageDag";
import { useRawViews } from "@/pages/ModelTool/useRawViews";
import { useDarkMode } from "@/providers/DarkModeProvider";
import { useMemo, useState } from "react";
import ReactFlow, {
  Background,
  Edge,
  getConnectedEdges,
  ReactFlowProvider,
} from "react-flow-renderer";
import { useTemplatesSyncs } from "../SelectedMetric";
import { NotConfiguredView } from "./NotConfiguredView";
import { useCurrentTemplate } from "./TemplatesProvider";
import { useTemplateConfig } from "./useTemplateConfig";

type Props = {};

const DefaultViewport = { x: 250, y: 200, zoom: 1 };

const CoreMetricsLineage = (props: Props) => {
  return (
    <ReactFlowProvider>
      <LineageGraph {...props} />
    </ReactFlowProvider>
  );
};

export default CoreMetricsLineage;

const LineageGraph = (props: Props) => {
  const { edgeElements, nodeElements, loading } = useTemplateNodes();

  const { isDarkModeEnabled } = useDarkMode();

  //Give the element a new key to force rerendering everytime the lineage changes
  const key = useMemo(() => {
    return nodeElements.reduce((prev, cur) => prev + cur.id, "");
  }, [nodeElements]);

  const [highlighedEdges, setHighlightedEdges] = useState<Edge[]>([]);
  const styledEdges: Edge[] = edgeElements.map((e) =>
    highlighedEdges.some((h) => h.id === e.id)
      ? {
          ...e,
          style: {
            stroke: "#0070F4",
            strokeWidth: 4,
            opacity: 0.7,
          },
          zIndex: 1000,
          className: "stroke-current",
        }
      : e,
  );

  const showNotConfiguredView = useMemo(
    () => nodeElements.length === 0 && !loading,
    [nodeElements, loading],
  );

  if (showNotConfiguredView) {
    return <NotConfiguredView />;
  }

  return (
    <div className="relative h-full bg-gray-50">
      <div className="absolute inset-0 h-full w-full bg-white dark:bg-gray-800">
        <ReactFlow
          key={key}
          nodesDraggable={false}
          nodesConnectable={false}
          panOnScroll
          nodes={nodeElements}
          edges={styledEdges}
          nodeTypes={nodeTypesWide}
          snapGrid={[18, 18]}
          minZoom={0.1}
          defaultZoom={DefaultViewport.zoom}
          defaultPosition={[DefaultViewport.x, DefaultViewport.y]}
          onNodeMouseEnter={(_event, node) => {
            const edges = getConnectedEdges([node], edgeElements);
            setHighlightedEdges(edges);
          }}
          onNodeMouseLeave={() => setHighlightedEdges([])}
          fitView
        >
          <Background size={0.7} color={isDarkModeEnabled ? "#555" : "#ddd"} />
        </ReactFlow>
      </div>
      {loading && <LoadingOverlay />}
    </div>
  );
};

const useTemplateNodes = () => {
  const { templateSyncs, loading: syncsLoading } = useTemplatesSyncs();
  const { rawViews, loading: rawViewsLoading } = useRawViews();
  const { models, loading: loadingModels } = useTemplateModels();
  const { config, loading: loadingConfig } = useTemplateConfig();

  return useMemo(() => {
    if (loadingModels || syncsLoading || rawViewsLoading || loadingConfig)
      return { nodeElements: [], edgeElements: [], loading: true };

    const rawNodes: LineageNode[] = [];

    const stagingModelNodes = getStagingModelNodes(models, config);
    const coreModelLineageNodes = getCoreModelNodes(
      models,
      config,
      stagingModelNodes.length > 0 ? 4 : 3,
    );
    rawNodes.push(...stagingModelNodes, ...coreModelLineageNodes);

    //add ELT sync nodes and tables:
    templateSyncs.forEach((sync) => {
      //Tables referenced by the sync and model:
      const views = rawViews.filter((r) => r.syncId === sync.id);

      const node: CoreEltSyncNode = {
        id: sync.id,
        dependencies: [],
        dependents: views.map((r) => r.viewId),
        type: "core-elt-sync",
        eltSync: sync,
      };
      rawNodes.push({ ...node, depth: 1 });

      //add raw views as nodes:
      const dependents: string[] = [];
      views.forEach((view) => {
        const d = rawNodes
          .filter((m) => m.dependencies.some((id) => id === view.viewId))
          .map((d) => d.id);
        dependents.push(...d);
      });

      const rawNode: CoreRawViewNode = {
        id: `raw-views-${sync.id}`,
        dependencies: [sync.id],
        dependents: dependents,
        type: "core-raw-view",
        rawViews: views,
        integrationId: sync.sourceIntegrationId,
        schemaName: sync.destinationSchemaName || sync.sourceIntegrationId,
      };
      rawNodes.push({ ...rawNode, depth: 2 });
    });

    //Fix that edges can point to an abstracted version of the node:
    const lineageNodes = mapToAbstractedDependencies([...rawNodes]);

    //order nodes by integrationId so that fewer lines cross each other:
    const orderedNodes = orderNodesByIntegrationId(lineageNodes);

    const elements = getReactFlowLineageElements(orderedNodes, {
      spaceX: 350,
      spaceY: 100,
    });
    return {
      nodeElements: elements.nodeElements,
      edgeElements: elements.edgeElements,
      loading: false,
    };
  }, [
    config,
    loadingConfig,
    loadingModels,
    models,
    rawViews,
    rawViewsLoading,
    syncsLoading,
    templateSyncs,
  ]);
};

const useTemplateModels = () => {
  const template = useCurrentTemplate();
  const { models, loadingModels } = useListModels();

  return useMemo(() => {
    if (!template || loadingModels)
      return {
        models: [],
        loading: loadingModels,
      };

    return {
      models: models.filter((model) => {
        return model.templateId === template.id;
      }),
      loading: loadingModels,
    };
  }, [template, models, loadingModels]);
};

/**
 *
 * @param models All models that are included in a template
 * @returns Lineage nodes for all ready to be used in the lineage graph, and a list of all raw tables referenced by a models.
 *
 */
const getCoreModelNodes = (
  models: ReturnType<typeof useTemplateModels>["models"],
  config: ReturnType<typeof useTemplateConfig>["config"],
  startDepth: number,
) => {
  let coreModelNodes: { node: CoreModelNode; modelDependencies: string[] }[] =
    [];

  models.forEach((model) => {
    //Only include models that are part of a metric:
    const modelMetric = config?.metrics.find(
      (metric) => metric.config.templateItemId === model.templateItemId,
    );

    if (!modelMetric) {
      return;
    }

    //Dependencies on raw tables:
    const rawDependencies =
      model.publishedQuery?.dependencies
        ?.filter((d) => d.type === DependencyType.RawView)
        .map((d) => d.dwItemId) ?? [];

    //Dependencies on other models:
    const modelDependencies =
      model.publishedQuery?.dependencies
        ?.filter(
          (d) =>
            d.type === DependencyType.ModelView ||
            d.type === DependencyType.MaterializedTable,
        )
        .map((d) => d.dwItemId) ?? [];

    //Dependents:
    const dependents = models
      .filter(
        (m) =>
          !!m.publishedQuery?.dependencies?.some(
            (d) => d.dwItemId === model.id,
          ),
      )
      .map((m) => m.id);

    const node: CoreModelNode = {
      id: model.id,
      dependencies: [...new Set([...rawDependencies, ...modelDependencies])], //remove duplicates
      dependents: [...dependents],
      type: "core-model",
      model,
    };
    coreModelNodes.push({ node, modelDependencies });
  });

  const lineageNodes: LineageNode[] = [];

  //organize nodes by depth so that we start with ones that don't depend on other models:
  let depth = startDepth;
  while (coreModelNodes.length > 0) {
    const curDepth = depth;
    const newNodes: LineageNode[] = [];
    const remaining = coreModelNodes.filter(({ node, modelDependencies }) => {
      const remainingDependencies = modelDependencies.filter(
        (id) => !lineageNodes.some((n) => n.id === id),
      );

      if (remainingDependencies.length === 0) {
        newNodes.push({ ...node, depth: curDepth });
        return false;
      } else {
        return true;
      }
    });
    lineageNodes.push(...newNodes);

    if (remaining.length === coreModelNodes.length) {
      //we are stuck, just add the remaining nodes:
      remaining.forEach(({ node }) => {
        lineageNodes.push({ ...node, depth: curDepth });
      });
      break;
    } else {
      //we made progress, continue:
      coreModelNodes = [...remaining];
      depth = curDepth + 1;
    }
  }

  return lineageNodes;
};
/**
 *
 * @param models All models that are included in a template
 * @returns Staging model Lineage nodes for all ready to be used in the lineage graph
 * These are grouped by integrationId:
 *
 */
const getStagingModelNodes = (
  models: ReturnType<typeof useTemplateModels>["models"],
  config: ReturnType<typeof useTemplateConfig>["config"],
): LineageNode[] => {
  let stagingModelNodes: { [integrationId: string]: StagingModelNode } = {};

  models.forEach((model) => {
    //Dependencies on raw tables:
    const rawDependencies =
      model.publishedQuery?.dependencies
        ?.filter((d) => d.type === DependencyType.RawView)
        .map((d) => d.dwItemId) ?? [];

    //Dependencies on other models:
    const modelDependencies =
      model.publishedQuery?.dependencies
        ?.filter(
          (d) =>
            d.type === DependencyType.ModelView ||
            d.type === DependencyType.MaterializedTable,
        )
        .map((d) => d.dwItemId) ?? [];

    //Dependents:
    const dependents = models
      .filter(
        (m) =>
          !!m.publishedQuery?.dependencies?.some(
            (d) => d.dwItemId === model.id,
          ),
      )
      .map((m) => m.id);

    //check if model is a staging model:
    const stagingIntegration = config?.integrations.find((integration) =>
      integration.models.some((m) => m.templateItemId === model.templateItemId),
    );
    if (!stagingIntegration) {
      return;
    }

    stagingModelNodes = produce(stagingModelNodes, (draft) => {
      const id = stagingIntegration.sourceIntegationId;
      if (!draft[id]) {
        draft[id] = {
          id,
          dependencies: [],
          dependents: [],
          type: "staging-model",
          integrationId: stagingIntegration.sourceIntegationId,
          models: [model],
          schemaName:
            model.folder?.name || stagingIntegration.sourceIntegationId,
        };
      } else {
        draft[id].models.push(model);
        draft[id].dependents = [
          ...new Set([...draft[id].dependents, ...dependents]),
        ];
        draft[id].dependencies = [
          ...new Set([
            ...draft[id].dependencies,
            ...rawDependencies,
            ...modelDependencies,
          ]),
        ];
      }
    });
  });
  return Object.values(stagingModelNodes).map((node) => ({
    ...node,
    depth: 3,
  }));
};

/**
 *
 * @param nodes All finished lineage nodes
 * @returns nodes where depedencies are pointed to the abstracted version of the node.
 */
const mapToAbstractedDependencies = (nodes: LineageNode[]) => {
  return nodes.map((node) => {
    //fix node dependencies to point to the abstracted version of the node:
    node.dependencies = mapToAbstractEdges(node.dependencies, nodes);
    node.dependents = mapToAbstractEdges(node.dependents, nodes);
    return node;
  });
};
const mapToAbstractEdges = (originalEdges: string[], nodes: LineageNode[]) => {
  const abstractedEdges: string[] = [];
  originalEdges.forEach((id) => {
    const coreRawViewTarget = nodes.find(
      (n) =>
        n.type === "core-raw-view" && n.rawViews.some((r) => r.viewId === id),
    );
    if (coreRawViewTarget) {
      abstractedEdges.push(coreRawViewTarget.id);
      return;
    }
    const coreModelTarget = nodes.find(
      (n) => n.type === "staging-model" && n.models.some((m) => m.id === id),
    );
    if (coreModelTarget) {
      abstractedEdges.push(coreModelTarget.id);
      return;
    }
    abstractedEdges.push(id);
  });

  return [...new Set(abstractedEdges)];
};

const orderNodesByIntegrationId = (nodes: LineageNode[]) => {
  return nodes.sort((a, b) => {
    const aId = getIntegrationId(a);
    const bId = getIntegrationId(b);
    return aId.localeCompare(bId);
  });
};
const getIntegrationId = (node: LineageNode) => {
  if (node.type === "staging-model") {
    return node.integrationId;
  }
  if (node.type === "core-raw-view") {
    return node.schemaName;
  }
  if (node.type === "core-elt-sync") {
    return node.eltSync.sourceIntegrationId;
  }
  return "";
};
