import { Navigate } from "@tanstack/react-location";
import { TemplatesEnum, useGetTemplatesQuery } from "@/apollo/types";
import { LoadingOverlay } from "@/components/elements/LoadingComponents";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";

export const useTemplate = () => {
  const { data, loading } = useGetTemplatesQuery();

  const availableTemplates = useMemo(() => data?.getTemplates || [], [data]);

  const marketingTemplate = useMemo(() => {
    if (!availableTemplates?.length) return null;

    return (
      availableTemplates.find((t) => t.name === TemplatesEnum.Marketing) || null
    );
  }, [availableTemplates]);

  return {
    availableTemplates,
    marketingTemplate,
    loading,
  };
};

export type CurrentTemplate = NonNullable<
  ReturnType<typeof useTemplate>["marketingTemplate"]
>;

//@ts-ignore
const TemplateContext = createContext<CurrentTemplate>(null);
export const useCurrentTemplate = () => useContext(TemplateContext);

export const TemplateProvider = (props: PropsWithChildren) => {
  const { loading, marketingTemplate: currentTemplate } = useTemplate();

  if (loading) return <LoadingOverlay />;
  if (!currentTemplate) return <Navigate to=".." />;

  return (
    <TemplateContext.Provider value={currentTemplate}>
      {props.children}
    </TemplateContext.Provider>
  );
};
