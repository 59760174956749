import { PaneState } from "./useReflexAccordion";

export function toRelativeValue(containerValue: number, value: number) {
  return value / containerValue;
}

export function flexSum(panes: PaneState[]) {
  return panes.reduce((sum, x) => sum + x.flex, 0);
}

export function getAllOpen(panes: PaneState[]) {
  return panes.filter((x) => !x.isCollapsed);
}

export function getAvailableSpace(targetPane: PaneState, minPaneFlex: number) {
  return targetPane.flex - minPaneFlex;
}

export function getMaxAvailableSpace(
  targetPanes: PaneState[],
  minPaneFlex: number,
) {
  return targetPanes.reduce(
    (sum, x) => sum + getAvailableSpace(x, minPaneFlex),
    0,
  );
}

export function getAllOpenBefore(index: number, panes: PaneState[]) {
  return panes.filter((x) => x.index < index && !x.isCollapsed);
}

export function getAllOpenAfter(index: number, panes: PaneState[]) {
  return panes.filter((x) => x.index > index && !x.isCollapsed);
}

export function getClosestOpenBefore(index: number, panes: PaneState[]) {
  return panes
    .filter((x) => x.index < index)
    .reverse()
    .find((x) => !x.isCollapsed);
}

export function isEqual(v1: number, v2: number, epsilon: number = 0.001) {
  return Math.abs(v1 - v2) < epsilon;
}
