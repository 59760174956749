import { FindOneSyncQuery, ListSyncsQuery } from "@/apollo/types";
import { useFilteredNotifications } from "./filterNotifications";
import { useMemo } from "react";

/**
 * Returns a flat list of all the notifications that may affect the RevEltSync
 *
 * This includes:
 * - Account notifications
 * - Connector notifications
 * - Sync notifications
 */
export const useReverseEltSyncNotifications = (
  syncs: ListSyncsQuery["syncs"] | FindOneSyncQuery["findOneSync"][],
) => {
  const filters = useMemo(() => {
    return {
      connectionIds: syncs.map((sync) => sync.sourceConnectionId),
      syncIds: syncs.map((sync) => sync.id),
    };
  }, [syncs]);

  return useFilteredNotifications(filters);
};
