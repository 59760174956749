import { DocumentChartBarIcon } from "@heroicons/react/24/outline";
import { ListModelsQuery } from "@/apollo/types";
import { EmojiIcon } from "@/components/elements/EmojiIcon";
import classNames from "@/helpers/classNames";

import { isDraftModel, isPendingTable } from "../../utils/modelUtils";
import ModelSVGIcon from "./ModelSVGIcon";

type ModelType = ListModelsQuery["models"][0];

type ModelIconProps = {
  model: Pick<
    ModelType,
    | "emoji"
    | "materializationType"
    | "publishedQuery"
    | "dwTable"
    | "dashboardId"
  >;
  className?: string;
};

function getEmojiCharCode(emoji?: string | null) {
  if (!emoji) return null;
  const code = parseInt(emoji, 16);
  return isNaN(code) ? null : code;
}

export const ModelIcon = ({ model, className }: ModelIconProps) => {
  const baseClasses = isPendingTable(model) ? "opacity-50" : "";
  const emojiCharCode = getEmojiCharCode(model.emoji);
  if (emojiCharCode) {
    return (
      <EmojiIcon
        symbol={emojiCharCode}
        className={classNames(baseClasses, className)}
      />
    );
  }
  if (!!model.dashboardId) {
    return (
      <DocumentChartBarIcon
        className={classNames("h-4 w-4 shrink-0", className)}
      />
    );
  }
  return (
    <ModelSVGIcon
      type={model.materializationType}
      isDraft={isDraftModel(model)}
      className={classNames("h-4 w-4 shrink-0", baseClasses, className)}
    />
  );
};
