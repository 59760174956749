import { RocketLaunchIcon } from "@heroicons/react/24/outline";
import { NavButton } from "@/components/elements/Sidebar";
import dayjs from "dayjs";
import { INTERCOM_ONBOARDING_CHECKLIST } from "@/hooks/useIntercom";
import { useEltSyncs } from "@/hooks/useSync";
import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import PercentageCircle from "@/pages/Settings/Billing/Usage/PercentageCircle";
import { useCurrentAccount } from "@/providers/account";
import React, { useMemo } from "react";

type Props = {};

const OnboardingChecklistButton = (props: Props) => {
  const account = useCurrentAccount();
  const accountCreated = dayjs(account.createdAt);

  const { percent, loading, isComplete } = useChecklistStatus();

  //Only show if account was created in the last 21 days:
  if (accountCreated.isBefore(dayjs().subtract(21, "days"))) return null;

  if (loading) return null;

  if (isComplete) return null;

  return (
    <NavButton
      icon={
        <div className="relative flex items-center justify-center rounded-full">
          <PercentageCircle
            percentage={percent / 100}
            className="absolute left-0 top-0 -ml-[25%] -mt-[25%] h-[150%] w-[150%]"
          />
          <RocketLaunchIcon className="h-4 w-4" />
        </div>
      }
      onClick={() => {
        window.Intercom("startChecklist", INTERCOM_ONBOARDING_CHECKLIST);
      }}
    >
      Onboarding guide
    </NavButton>
  );
};

export default OnboardingChecklistButton;

const useChecklistStatus = () => {
  const { eltSyncs, loading: loadingElt } = useEltSyncs();
  const { models, loadingModels } = useListModels();

  return useMemo(() => {
    const loading = loadingElt || loadingModels;

    if (eltSyncs.length === 0) {
      return { step: 1, percent: 0, loading, isComplete: false };
    }
    if (models.length === 0) {
      return { step: 2, percent: 50, loading, isComplete: false };
    }
    return { step: 3, percent: 100, loading, isComplete: true };
  }, [eltSyncs.length, loadingElt, loadingModels, models.length]);
};
