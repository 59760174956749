import { atom, useAtom } from "jotai";
import { debounce, noop } from "lodash";
import React, { MutableRefObject, useRef } from "react";
import { FormProvider } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

import { Connection, SubscriptionStatus } from "@/apollo/types";
import { ActionButton } from "@/components/elements/Button";
import confirm from "@/components/elements/Confirm";
import {
  SlideOver,
  SlideOverBody,
  SlideOverCloseButton,
  SlideOverFooter,
  SlideOverHeader,
} from "@/components/elements/SlideOver";
import { SectionHeading, TextMuted } from "@/components/elements/Typography";
import { ConnectorsLimitAlert } from "@/components/modules/PlanAlerts";
import {
  ConnectorOption as ConnectorOptionType,
  useConnectorOptions,
} from "@/features/connectors";
import {
  useConnectorAdditionValidationUpgradeDialog,
  useSubscriptionStatus,
} from "@/features/subscription";
import { useMountEffect } from "@/hooks/useMountEffect";
import {
  IntegrationLogo,
  IntegrationType,
  useCreateConnection,
  useIntegrationDocumentationUrl,
  useIntegrationsMap,
} from "@/integrations";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useDataWarehouseContext } from "@/providers/DataWarehouseProvider";
import { useToast } from "@/providers/ToastProvider";
import {
  ConnectorOption,
  Connectors,
  SearchFilter,
} from "@/widgets/connectors";
import {
  RecommendedConnections,
  RecommendedConnectorOption,
} from "@/widgets/recommended-connectors";
import { RequestConnector } from "@/widgets/request-connector";
import {
  ArrowTopRightOnSquareIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";

import ConnectionForm, {
  ConnectionFormSubmitButton,
  useConnectionForm,
} from "./ConnectionForm/ConnectionForm";

export const ConnectionSlideOverContext = React.createContext<{
  integration: IntegrationType | null;
  setIntegration: (integration: IntegrationType | null) => void;
  resetIntegration: () => void;
  isConfigurationDirty: boolean;
  setIsConfigurationDirty: (dirty: boolean) => void;
  initialFocusRef?: React.MutableRefObject<HTMLElement | null>;
}>({
  integration: null,
  setIntegration: noop,
  resetIntegration: noop,
  isConfigurationDirty: false,
  setIsConfigurationDirty: noop,
});

export function ConnectionSlideOverContextProvider({
  integrationId,
  abilityFilter,
  children,
  initialFocusRef,
}: Pick<NewConnectionProps, "abilityFilter"> & {
  integrationId?: string;
} & {
  children: React.ReactNode;
  initialFocusRef?: React.MutableRefObject<HTMLElement | null>;
}) {
  const integrationsRegistry = useIntegrationsMap(abilityFilter);
  const [integration, setIntegration] = React.useState<IntegrationType | null>(
    () => {
      // Preselect integration if provided
      if (integrationId) {
        return integrationsRegistry.get(integrationId) ?? null;
      }
      return null;
    },
  );

  const [isConfigurationDirty, setIsConfigurationDirty] = React.useState(false);

  const resetIntegration = React.useCallback(() => {
    setIntegration(null);
  }, []);

  const contextValue = React.useMemo(() => {
    return {
      integration,
      setIntegration,
      resetIntegration,
      isConfigurationDirty,
      setIsConfigurationDirty,
      initialFocusRef,
    };
  }, [
    integration,
    resetIntegration,
    isConfigurationDirty,
    setIsConfigurationDirty,
    initialFocusRef,
  ]);

  return (
    <ConnectionSlideOverContext.Provider value={contextValue}>
      {children}
    </ConnectionSlideOverContext.Provider>
  );
}

type NewConnectionProps = React.ComponentProps<typeof Connectors>;

type SlideOverProps = Pick<
  React.ComponentProps<typeof SlideOver>,
  "show" | "onClose"
>;

type AbilityFilterProps = Pick<NewConnectionProps, "abilityFilter">;

type NewConnectionSlideOverProps = AbilityFilterProps & {
  integrationId?: string;
  resetOnClose?: boolean;
  onConnectionAdded: (
    connection: Pick<Connection, "id" | "label" | "integrationId">,
  ) => void;
  onConnectionFailed?: (error: Error) => void;
};

export function NewConnectionSlideOver(
  props: NewConnectionSlideOverProps & SlideOverProps,
) {
  const mixpanel = useMixpanel();
  const toast = useToast();

  const handleConnectionAdded = (
    connection: Pick<Connection, "id" | "label" | "integrationId">,
    usingExistingConnection: boolean = false,
  ) => {
    props.onConnectionAdded(connection);
    mixpanel.track("Connector Setup Succeeded", {
      connection,
      integration_id: connection.integrationId,
      using_existing_connection: usingExistingConnection,
    });
    props.onClose();
  };

  const { loading, createConnection } = useCreateConnection({
    onSuccess(connection) {
      handleConnectionAdded(connection);
    },
    onError(error, integrationId) {
      props.onConnectionFailed?.(error);
      toast("Connector not created", error.message, "error");
      mixpanel.track("Connector Setup Failed", {
        error: error.message,
        integration_id: integrationId,
      });
    },
  });

  const searchInputRef = useRef<HTMLInputElement>(null);

  return (
    <ConnectionSlideOverContextProvider
      {...props}
      initialFocusRef={searchInputRef}
    >
      <ConnectionSlideOverContext.Consumer>
        {({
          integration,
          resetIntegration,
          isConfigurationDirty,
          setIsConfigurationDirty,
        }) => {
          const onClose = () => {
            setIsConfigurationDirty(false);
            props.onClose();
          };

          return (
            <SlideOver
              show={props.show}
              onClose={async () => {
                if (isConfigurationDirty) {
                  const confirmed = await confirm({
                    title: "Unsaved Changes",
                    message: "Are you sure you want to discard your changes?",
                    type: "danger",
                  });
                  if (confirmed) {
                    onClose();
                    mixpanel.track("Connector Setup Canceled", {
                      integration_id: integration?.id,
                    });
                  }
                } else {
                  onClose();
                  mixpanel.track("Connector Setup Canceled", {
                    integration_id: integration?.id,
                  });
                }
              }}
              afterLeave={() => {
                if (props.resetOnClose) {
                  resetIntegration();
                }
              }}
              size="lg"
              initialFocus={searchInputRef}
            >
              <SlideOverCloseButton />
              {integration === null ? (
                <SelectConnecterView
                  {...props}
                  onConnectionAdded={(connection) => {
                    handleConnectionAdded(connection, true);
                  }}
                />
              ) : (
                <ConfigureConnectorView
                  integration={integration}
                  onSubmit={(data) => {
                    createConnection(integration, data);
                  }}
                  isLoading={loading}
                  onCancel={() =>
                    props.resetOnClose ? resetIntegration() : props.onClose()
                  }
                />
              )}
            </SlideOver>
          );
        }}
      </ConnectionSlideOverContext.Consumer>
    </ConnectionSlideOverContextProvider>
  );
}

function SelectConnecterView({
  abilityFilter,
  onConnectionAdded,
}: NewConnectionSlideOverProps) {
  const { setIntegration, initialFocusRef } = React.useContext(
    ConnectionSlideOverContext,
  );
  const mixpanel = useMixpanel();
  const trackDebounced = useDebouncedCallback(mixpanel.track, 500);

  const dwh = useDataWarehouseContext();
  const options = useConnectorOptions({ dwIntegration: dwh.integration });

  const { validateConnectionAddition, validationDialog } =
    useConnectorAdditionValidationUpgradeDialog();

  const { status } = useSubscriptionStatus();

  const handleIntegrationSelected = (option: ConnectorOptionType) => {
    validateConnectionAddition(option.integration.id, () => {
      setIntegration(option.integration);
    });
  };

  return (
    <>
      <SlideOverHeader>Add Connection</SlideOverHeader>

      <Connectors
        options={options}
        enableSelectExistingConnection
        abilityFilter={abilityFilter}
        // Return existing connection immediately
        onSelectConnection={onConnectionAdded}
        // Otherwise, open the connection configuration
        onSelectIntegration={handleIntegrationSelected}
        onOptionsFiltered={(filteredOptions, filters) => {
          if (filters.searchText.trim() === "") {
            return;
          }
          trackDebounced("Connection Options Filtered", {
            resultCount: filteredOptions.length,
            abilityFilter: filters.abilityFilter,
            searchText: filters.searchText.trim().toLowerCase(),
          });
        }}
      >
        {({ options, filters }) => (
          <SlideOverBody className="flex flex-col overflow-hidden px-0">
            <div className="mb-4">
              <ConnectorsLimitAlert />
            </div>
            <div className="flex flex-1 flex-col gap-3 overflow-hidden">
              <div className="px-6 py-1">
                <SearchFilter
                  ref={
                    initialFocusRef as
                      | MutableRefObject<HTMLInputElement | null>
                      | undefined
                  }
                />
              </div>
              <div className="flex flex-col gap-6 overflow-auto px-6 pb-8 pt-1">
                {!filters.searchText && (
                  <RecommendedConnections
                    abilityFilter={abilityFilter}
                    onSelectIntegration={handleIntegrationSelected}
                  >
                    {({ options }) => (
                      <div>
                        {options.length > 0 && (
                          <>
                            <SectionHeading className="mb-2">
                              Recommendations
                            </SectionHeading>
                            <div className="grid gap-4 xs:grid-cols-2 md:grid-cols-3">
                              {options.map((option) => {
                                return (
                                  <RecommendedConnectorOption
                                    key={option.value}
                                    option={option}
                                    showPlanRequirement={
                                      status === SubscriptionStatus.Freemium
                                    }
                                  />
                                );
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </RecommendedConnections>
                )}
                <div>
                  <SectionHeading className="mb-2">
                    All Connectors
                  </SectionHeading>
                  <div className="grid gap-4 sm:grid-cols-2">
                    {options.map((option) => (
                      <ConnectorOption
                        key={option.value}
                        option={option}
                        showAbilityLabels={false}
                        showPlanRequirement={
                          status === SubscriptionStatus.Freemium
                        }
                      />
                    ))}
                  </div>
                </div>
                <div className="my-8 flex flex-col items-center">
                  <TextMuted className="mb-2 block text-center">
                    Didn't find what you are looking for?
                  </TextMuted>
                  <RequestConnector
                    defaultValues={{ elt: false, reverseEtl: true }}
                  />
                </div>
              </div>
            </div>
            {validationDialog()}
          </SlideOverBody>
        )}
      </Connectors>
    </>
  );
}

function ConfigureConnectorView({
  integration,
  onSubmit,
  isLoading,
  onCancel,
}: {
  integration: IntegrationType;
  onSubmit: (data: any) => void;
  isLoading: boolean;
  onCancel: () => void;
}) {
  const mixpanel = useMixpanel();
  const { setIsConfigurationDirty } = React.useContext(
    ConnectionSlideOverContext,
  );
  const { setConfig, getConfig } =
    useConnectionConfigCache<Record<string, any>>();
  const formMethods = useConnectionForm(integration, {
    defaultValues: getConfig(integration.id),
  });

  const { watch } = formMethods;
  const { isDirty } = formMethods.formState;

  React.useEffect(() => {
    setIsConfigurationDirty(isDirty);
  }, [isDirty, setIsConfigurationDirty]);

  const setConnectionConfigDebounced = React.useMemo(() => {
    return debounce((integrationId: string, data: any) => {
      setConfig(integrationId, data);
    }, 200);
  }, [setConfig]);

  React.useEffect(() => {
    const subscription = watch((data) => {
      setConnectionConfigDebounced(integration.id, data);
    });
    return () => subscription.unsubscribe();
  }, [setConnectionConfigDebounced, integration.id, watch, setConfig]);

  const documentationUrl = useIntegrationDocumentationUrl(
    integration.id,
    false,
  );

  useMountEffect(() => {
    mixpanel.time_event("Connector Setup Succeeded");
    mixpanel.track("Connector Setup Form Viewed", {
      integration_id: integration.id,
    });
  });

  return (
    <FormProvider {...formMethods}>
      <SlideOverHeader className="flex items-center">
        <div className="flex items-center space-x-2">
          <IntegrationLogo id={integration.id} className="h-5 w-5" />
          <span>{integration.name}</span>
        </div>
        {documentationUrl != null && (
          <a
            href={documentationUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              mixpanel.track("Connector Setup Documentation Viewed", {
                integration_id: integration.id,
              });
            }}
            className="ml-10 inline-flex items-center text-sm underline"
          >
            View setup guide
            <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
          </a>
        )}
      </SlideOverHeader>
      <SlideOverBody>
        <form
          id="integration-form"
          onSubmit={formMethods.handleSubmit(onSubmit)}
          className="flex h-full flex-col"
        >
          <ConfigureConnection
            integration={integration}
            isLoading={isLoading}
            onSubmit={(data) => onSubmit(data)}
          />
        </form>
      </SlideOverBody>
      <SlideOverFooter>
        <ActionButton
          colorScheme="secondary"
          onClick={() => onCancel()}
          icon={<ChevronLeftIcon />}
        >
          Back
        </ActionButton>
        <ConnectionFormSubmitButton
          integration={integration}
          isLoading={isLoading}
        />
      </SlideOverFooter>
    </FormProvider>
  );
}

/**
 * Small cache to store connection configuration data
 * added by the user. This is used to prefill the form
 * when the user goes back to the connection configuration
 */
const connectionConfigCache = atom<Record<string, any>>({});

function useConnectionConfigCache<T>(): {
  setConfig: (integrationId: string, config: T) => void;
  getConfig: (integrationId: string) => T | undefined;
} {
  const [cache, setCache] = useAtom(connectionConfigCache);

  const setConfig = React.useCallback(
    (integrationId: string, config: T) => {
      setCache((prev) => ({ ...prev, [integrationId]: config }));
    },
    [setCache],
  );

  const getConfig = React.useCallback(
    (integrationId: string) => {
      return cache[integrationId];
    },
    [cache],
  );

  return { setConfig, getConfig };
}

function ConfigureConnection(props: {
  integration: IntegrationType;
  isLoading: boolean;
  onSubmit: (data: any) => void;
}) {
  return (
    <>
      <ConnectionForm
        integration={props.integration}
        isLoading={props.isLoading}
      />
    </>
  );
}
