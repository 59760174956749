import { PropsWithChildren, useMemo } from "react";

type Props = PropsWithChildren<{ mode: "narrow" | "full" | "normal" }>;

export default function ActionBar({ children, mode }: Props) {
  const maxWidthClassName = useMemo(() => {
    if (mode === "normal") {
      return "max-w-7xl";
    }

    if (mode === "narrow") {
      return "max-w-2xl";
    }

    return "";
  }, [mode]);

  return (
    <div
      className={`${
        mode === "full" ? "h-16" : "h-20"
      } border-t bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:text-white`}
    >
      <div
        className={`${maxWidthClassName} mx-auto flex h-full items-center justify-end space-x-4 pl-4 pr-16`}
      >
        {children}
      </div>
    </div>
  );
}
