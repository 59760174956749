import { ContextType, createContext, useContext } from "react";

import { QueryDependencyInput } from "@/apollo/types";

import { CreateChatMessageInput } from "../types";

const AiAssistantModelContextContext = createContext<{
  model:
    | (Omit<NonNullable<CreateChatMessageInput["modelContext"]>, "sql"> & {
        getTextValue: () => string;
        dependencies: QueryDependencyInput[];
      })
    | null;
} | null>(null);

export function AiAssistantModelContextProvider({
  children,
  ...props
}: ContextType<typeof AiAssistantModelContextContext> & {
  children: React.ReactNode;
}) {
  return (
    <AiAssistantModelContextContext.Provider value={props}>
      {children}
    </AiAssistantModelContextContext.Provider>
  );
}

export function useAiAssistantModelContext() {
  const context = useContext(AiAssistantModelContextContext);
  if (!context) {
    throw new Error(
      "useAiAssistantModelContext must be used within an AiAssistantModelContextProvider",
    );
  }
  return context;
}
