import { useMatch } from "@tanstack/react-location";
import {
  CreateWebhookInput,
  GetWebhookDataQuery,
  ListWebhooksDocument,
  useCreateWebhookMutation,
  useGetWebhookDataLazyQuery,
} from "apollo/types";
import { PrimaryButton } from "components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "components/elements/Modal";
import Select from "components/elements/Select";
import { Input } from "components/primitives/input";
import FieldLabel from "components/primitives/InputLabel";
import { ComponentProps, useState } from "react";

import { useToast } from "@/providers/ToastProvider";
import { SelectableJob } from "./OrchestrationWebHooks";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { LocationGenerics } from "@/routes";

export const CreateWebhookModal = ({
  onClose,
  isOpen,
  loadingWorkflows,
  selectableJobs,
}: ComponentProps<typeof Modal> & {
  loadingWorkflows: boolean;
  selectableJobs: (SelectableJob | null)[];
}) => {
  const match = useMatch<LocationGenerics>();
  const orchestrationWorkflowId = match.params.workflowId;
  const [externalSystem, setExternalSystem] = useState({
    label: "",
    value: "",
  });
  const [webhookName, setWebhookName] = useState("");
  const [externalWebhookData, setWebhookData] = useState<
    GetWebhookDataQuery | undefined
  >();

  const [selectedJobs, setSelectedJobs] = useState<SelectableJob[]>([]);

  const toast = useToast();
  const [createWebHook] = useCreateWebhookMutation({
    onError(error) {
      toast(`Failed setting up webhook`, error.message, "error");
    },
    onCompleted() {
      toast(
        `Webhook created`,
        `Your webhook has been succesfully created.`,
        "success",
      );
      onClose();
    },
    refetchQueries: [
      {
        query: ListWebhooksDocument,
        variables: { orchestrationWorkflowId },
      },
    ],
  });

  const [selectedWebHook, selectWebhook] = useState<
    { data: any | undefined; label: string } | undefined
  >();
  const [callDbtFormData, { loading: dbtFormDataLoading }] =
    useGetWebhookDataLazyQuery();

  const changeExternalSystem = async (externalSystem: any) => {
    setExternalSystem(externalSystem);
    const dbtData = await callDbtFormData({
      variables: { type: "dbt_cloud" },
    });
    setWebhookData(dbtData.data);
  };

  const triggerSave = (e: any) => {
    const inputData: CreateWebhookInput = {
      name: webhookName,
      data: selectedWebHook?.data,
      externalSystem: externalSystem.value,
      dependencies: selectedJobs.map((s: SelectableJob) => ({
        name: s.label ?? "no-label",
        type: s.type as string,
        id: s.id,
      })),
      orchestrationWorkflowId,
    };

    createWebHook({
      variables: {
        data: inputData,
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalCloseButton />
      <ModalHeader>Create a webhook</ModalHeader>
      <ModalBody className="flex flex-col gap-4">
        <FieldLabel htmlFor="webhook-name" required={true}>
          Name
        </FieldLabel>
        <Input
          id="webhook-name"
          type="text"
          placeholder="Name"
          value={webhookName}
          onChange={(val) => {
            setWebhookName(val.target.value);
          }}
        />
        <FieldLabel htmlFor="external-system" required={true}>
          External System
        </FieldLabel>
        <Select
          inputId="external-system"
          isMulti={false}
          isLoading={false}
          creatable={false}
          placeholder={"Select external system"}
          disabled={false}
          onChange={changeExternalSystem}
          options={[{ label: "DBT Cloud", value: "dbt_cloud" }]}
          disableCheckmark={false}
          hideSelectedOptions={false}
          value={externalSystem}
        />

        {externalSystem.value === "dbt_cloud" &&
          (dbtFormDataLoading ? (
            <LoadingSpinner />
          ) : (
            <div>
              <FieldLabel htmlFor="trigger" required={true}>
                Select dbt job
              </FieldLabel>
              <Select
                inputId="trigger"
                isMulti={false}
                isLoading={false}
                creatable={false}
                placeholder={"Select dbt job"}
                disabled={false}
                onChange={selectWebhook}
                options={(externalWebhookData?.getWebhookData.data ?? []).map(
                  (trig: {
                    account_name: string;
                    account_id: string;
                    job_id: string;
                    job_name: string;
                  }) => ({
                    data: trig,
                    label: `${trig.job_name} (${trig.account_name})`,
                  }),
                )}
                disableCheckmark={false}
                hideSelectedOptions={false}
                value={selectedWebHook}
              />
            </div>
          ))}

        <div>
          <FieldLabel htmlFor="workflow" required={true}>
            Select job(s) to run after
          </FieldLabel>
          <Select
            inputId="workflow"
            isMulti={true}
            isLoading={loadingWorkflows}
            creatable={false}
            placeholder={"Select workflow"}
            disabled={false}
            options={selectableJobs}
            disableCheckmark={false}
            hideSelectedOptions={true}
            iconParser={(option: any) => {
              return option.icon || null;
            }}
            onChange={(val: SelectableJob[]) => {
              setSelectedJobs(val);
            }}
            value={selectedJobs}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <PrimaryButton
          onClick={(e) => {
            triggerSave(e);
          }}
        >
          {"Save"}
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  );
};
