import { OrchestrationSchedulerType } from "@/apollo/types";
import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import { useMemo } from "react";

export const useAllOrchestratedModels = (workflowId: string) => {
  const { models, loadingModels } = useListModels();

  const allOrchestratedModels = useMemo(
    () =>
      models.filter(
        (m) =>
          !!m.publishedQuery &&
          m.orchestrationScheduler === OrchestrationSchedulerType.Global &&
          m.orchestrationWorkflow?.id === workflowId,
      ),
    [models, workflowId],
  );

  return { allOrchestratedModels, loadingModels };
};
