import { DangerButton } from "@/components/elements/Button";
import { DeleteGraphicIcon } from "@/components/icons/ContextualGraphicIcon";
import TabLayout from "@/components/layouts/TabLayout";
import { DeleteAccountDialog } from "@/features/delete-account";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useSettingsTabs } from "@/pages/Settings/hooks/useSettingsTabs";

export default function DeleteWorkspacePage() {
  const tabs = useSettingsTabs();
  const disclosure = useDisclosure();
  return (
    <>
      <TabLayout tabs={tabs}>
        <div className="flex w-full items-center justify-center">
          <div className="mt-6 flex w-96 flex-col items-center justify-center text-center dark:text-white">
            <DeleteGraphicIcon className="mb-6 w-20" />
            <h2 className="mb-6 text-2xl font-medium leading-tight">
              Delete your account
            </h2>
            <p className="mb-6">
              <span>All your account data will be permanently deleted.</span>
            </p>
            <p className="mb-6">
              <span>
                We will not delete any data you have already synced to your
                warehouse
              </span>
            </p>
            <DangerButton onClick={() => disclosure.onOpen()}>
              Delete this account
            </DangerButton>
          </div>
        </div>
      </TabLayout>
      <DeleteAccountDialog {...disclosure} />
    </>
  );
}
