import { useDisclosure } from "@/hooks/useDisclosure";
import { ExportsSlideOver } from "@/pages/ModelTool/Sidebar/exports/ExportsSlideOver";
import React, { useState } from "react";

import { FolderItemType, SidebarItemFactory } from "../SidebarFactory";
import { useSidebarFolders } from "../useSidebarFolders";

export function ExportItems({ items }: { items: FolderItemType[] }) {
  const { openFolders, toggleFolder } = useSidebarFolders();
  const [highlightedId, setHighlightedId] = useState("");
  return (
    <div
      className="flex select-none flex-col"
      onBlur={() => setHighlightedId("")}
    >
      <div className="max-h-full grow">
        {items.map((item) => {
          return (
            <ExportItem
              level={1}
              key={item.id}
              item={item}
              onFocus={(id) => {
                setHighlightedId(id);
              }}
              openItems={openFolders}
              onToggleFolder={toggleFolder}
              highlightedId={highlightedId}
              setHighlightedId={setHighlightedId}
            />
          );
        })}
      </div>
    </div>
  );
}

function ExportItem(props: React.ComponentProps<typeof SidebarItemFactory>) {
  const disclosure = useDisclosure<string>();
  return (
    <>
      <SidebarItemFactory
        {...props}
        onSelectItem={(item) => {
          if (item.itemType === "export") {
            disclosure.onOpen(item.sync.id);
          }
        }}
      />
      <ExportsSlideOver
        syncId={disclosure.data}
        show={disclosure.isOpen}
        onClose={disclosure.onClose}
      />
    </>
  );
}
