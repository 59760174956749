import produce from "immer";
import { useCallback, useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";

import { useGetCustomFormValuesQuery } from "@/apollo/types";
import { SecondaryButton } from "@/components/elements/Button";
import { SimplifiedFormContext } from "@/components/modules/ConnectionSettings";
import FieldLabel from "@/components/primitives/InputLabel";
import { DropdownOption, FormType } from "@/integrations";

import KlaviyoCustomReport from "./KlaviyoCustomReport";

export type KlaviyoReport = {
  name: string;
  id: string;
  type?: DropdownOption;
  statistics?: DropdownOption[];
  conversionMetric?: DropdownOption;
};

const useCustomFormFieldsQuery = (connectionId: string) => {
  const { data, loading, refetch } = useGetCustomFormValuesQuery({
    variables: {
      input: {
        connectionId: connectionId,
        dependencies: "{}",
      },
    },
  });
  return {
    data: data?.getCustomFormValues.customFormValues ?? [],
    loading,
    refetch,
  };
};

export default function KlaviyoForm(props: {
  field: Extract<FormType, { type: "klaviyo-form" }>;
}) {
  const context = useFormContext();
  const connectionId = context.getValues("connectionId");
  const displaySimplified = useContext(SimplifiedFormContext);

  const [reportErrors, setReportErrors] = useState<Record<string, string>>({});
  const [invalidReportIds, setInvalidReportIds] = useState<string[]>([]);

  const { data: customFieldsData } = useCustomFormFieldsQuery(connectionId);

  useEffect(() => {
    context.register("reports", { required: false });
  }, [context]);

  const getInitialReports = () => {
    const values: KlaviyoReport[] = context.getValues("reports");
    return values?.filter((v) => !!v) ?? [];
  };
  const [reports, setReports] = useState<KlaviyoReport[]>(getInitialReports());

  const changeReport = (report: KlaviyoReport) => {
    setReports((prev) =>
      produce(prev, (draft) => {
        const cur = draft.find((rep) => rep.id === report.id);
        if (cur) {
          cur.name = report.name;
          cur.conversionMetric = report.conversionMetric;
          cur.statistics = report.statistics;
          cur.type = report.type;
        }
      }),
    );
  };

  const removeReport = (report: KlaviyoReport) => {
    setReports((prev) =>
      produce(prev, (draft) => {
        draft = draft.filter((rep) => rep.id !== report.id);
        return draft;
      }),
    );
    setInvalidReportIds((prev) => prev.filter((x) => x !== report.id));
  };

  const onReportInvalid = useCallback((reportId: string) => {
    setInvalidReportIds((prev) => [...prev, reportId]);
  }, []);

  const onReportValid = useCallback((reportId: string) => {
    setInvalidReportIds((prev) => prev.filter((x) => x !== reportId));
  }, []);

  const setValue = context.setValue;
  const setError = context.setError;

  useEffect(() => {
    if (reports.length === 0) {
      setValue("reports", [], {
        shouldValidate: false,
      });
    }
  }, [reports.length, setValue]);

  useEffect(() => {
    //If there is any report where the combination is invalid, set report and don't evaluate further
    if (invalidReportIds.length > 0) {
      setError("reports", {
        message: "Validation error",
      });
      return;
    }

    const currentReports = reports;
    if (currentReports && Array.isArray(currentReports)) {
      //Check if all report names are unique
      const duplicateReportNames = currentReports.filter(
        (x) => currentReports.filter((y) => y.name === x.name).length > 1,
      );

      const _reportErrors: Record<string, string> = {};
      for (const duplicateReport of duplicateReportNames) {
        _reportErrors[duplicateReport.id] = "Report names must be unique";
      }

      setReportErrors(_reportErrors);
      if (duplicateReportNames.length > 0) {
        setError("reports", {
          message: "Validation error",
        });
      } else {
        setValue("reports", currentReports, {
          shouldValidate: true,
        });
      }
    }
  }, [reports, setValue, setError, invalidReportIds.length]);

  return (
    <div className="space-y-8">
      {!!reports.length && customFieldsData?.types && (
        <div>
          <FieldLabel>Custom reports ({reports.length}/20)</FieldLabel>
          <div className="flex flex-col gap-4">
            {reports.map((r) => {
              return (
                <KlaviyoCustomReport
                  report={r}
                  key={r.id}
                  onChangeReport={changeReport}
                  onRemoveReport={removeReport}
                  validationError={reportErrors[r.id]}
                  connectionId={connectionId}
                  typeGroups={customFieldsData?.types ?? []}
                  onReportInvalid={onReportInvalid}
                  onReportValid={onReportValid}
                />
              );
            })}
          </div>
        </div>
      )}
      {!displaySimplified && (
        <SecondaryButton
          onClick={() => {
            const id = uuid();
            setReports((prev) => [
              ...prev,
              {
                id,
                statistics: [],
                conversionMetric: undefined,
                name: "",
                type: undefined,
              },
            ]);
            setInvalidReportIds((prev) => [...prev, id]);
          }}
        >
          Add custom report
        </SecondaryButton>
      )}
    </div>
  );
}
const KlaviyoStreamNames = [
  "campaign",
  "email_template",
  "list",
  "metric",
  "event",
  "flow",
  "profile",
  "segment",
] as const;
type KlaviyoStreamType = (typeof KlaviyoStreamNames)[number];

export const isKlaviyoStream = (s: string): s is KlaviyoStreamType =>
  KlaviyoStreamNames.includes(s as KlaviyoStreamType);
