import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Alert, AlertIcon } from "@/components/elements/Alert";
import { IconButton, SecondaryButton } from "@/components/elements/Button";
import FieldLabel from "@/components/primitives/InputLabel";
import { useMountEffect } from "@/hooks/useMountEffect";
import produce from "immer";
import { FormType } from "@/integrations";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";

import OpenWeatherLocationInput, { Location } from "./OpenWeatherLocationInput";

const isValidLocation = (location: Partial<Location>): location is Location => {
  return !!location.city && !!location.lat && !!location.lon;
};

export default function OpenWeatherForm(props: {
  field: Extract<FormType, { type: "openweather-form" }>;
}) {
  const context = useFormContext();

  const [reportErrors, setReportErrors] = useState<Record<string, string>>({});

  useMountEffect(() => {
    context.register("locations", { required: true });
  });

  const [locations, setLocations] = useState<
    ({ id: string } & Partial<Location>)[]
  >(() => {
    const values = context.getValues()["locations"];
    return (
      values?.filter(Boolean) ?? [
        {
          id: uuid(),
        },
      ]
    );
  });

  const changeLocation = (id: string, location: Location) => {
    setLocations((prev) =>
      produce(prev, (draft) => {
        const cur = draft.find((loc) => loc.id === id);
        if (cur) {
          cur.city = location.city;
          cur.lat = location.lat;
          cur.lon = location.lon;
          cur.country = location.country;
        }
      }),
    );
  };

  const removeLocation = (id: string) => {
    setLocations((prev) =>
      produce(prev, (draft) => {
        draft = draft.filter((loc) => loc.id !== id);
        return draft;
      }),
    );
  };

  const setValue = context.setValue;
  const setError = context.setError;

  useEffect(() => {
    //Only evaluate when all locations are valid
    const getLocations = () => {
      if (!locations.length) {
        return [];
      }
      const foundReports = locations.every(isValidLocation) ? locations : [];

      return foundReports;
    };

    const currentLocations = getLocations();
    if (currentLocations.length > 0) {
      //Check if all location names are unique
      const duplicateLocationNames = currentLocations.filter(
        (x) =>
          currentLocations.filter(
            (y) => y.city === x.city && y.country === x.country,
          ).length > 1,
      );

      const _reportErrors: Record<string, string> = {};
      for (const duplicateLocation of duplicateLocationNames) {
        _reportErrors[duplicateLocation.id] = "Locations must be unique";
      }

      setReportErrors(_reportErrors);
      if (duplicateLocationNames.length > 0) {
        setError("locations", {
          message: "Validation error",
        });
      } else {
        setValue("locations", currentLocations, {
          shouldValidate: true,
        });
      }
    }
  }, [locations, setValue, setError]);

  return (
    <div className="flex flex-col space-y-8">
      <span>Add the locations you would like to get weather data for.</span>
      {!!locations.length && (
        <div>
          <FieldLabel>Locations</FieldLabel>
          <div className="flex flex-col gap-4">
            {locations.map((l) => {
              const { id, ...rest } = l;
              const location = isValidLocation(rest) ? rest : null;
              const validationError = reportErrors[id];
              return (
                <div
                  key={l.id}
                  className="relative rounded-sm border bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700/40"
                >
                  <div className="max-w-sm">
                    <OpenWeatherLocationInput
                      value={location}
                      onChangeLocation={(location) =>
                        changeLocation(id, location)
                      }
                      connectionId={context.getValues()["connectionId"]}
                    />
                  </div>
                  <div className="absolute right-0 top-0 p-2">
                    <IconButton
                      size="sm"
                      icon={<XMarkIcon />}
                      variant="ghost"
                      onClick={() => removeLocation(id)}
                      tabIndex={-1}
                    />
                  </div>
                  {validationError && (
                    <div className="mt-2">
                      <Alert status="error">
                        <AlertIcon />
                        {validationError}
                      </Alert>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div>
        <SecondaryButton
          icon={<PlusIcon />}
          onClick={() =>
            setLocations((prev) => [
              ...prev,
              {
                id: uuid(),
              },
            ])
          }
        >
          Add location
        </SecondaryButton>
      </div>
    </div>
  );
}
