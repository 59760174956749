import React from "react";

export const BlueOneEye3 = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 100 100"
    >
      <circle cx="50.5" cy="50.5" r="50.5" fill="#FBFBFC"></circle>
      <mask
        id="mask0_1928_11306"
        style={{ maskType: "alpha" }}
        width="100"
        height="100"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect width="100" height="100" fill="#C4C4C4" rx="2"></rect>
      </mask>
      <g mask="url(#mask0_1928_11306)">
        <ellipse
          cx="55.724"
          cy="55.722"
          fill="#0070F4"
          rx="104.483"
          ry="83.586"
        ></ellipse>
        <circle cx="87.5" cy="74.5" r="97.5" fill="#0059C3"></circle>
        <path
          fill="#0070F4"
          d="M70.646 69.458a1.639 1.639 0 01-1.52 1.517c-85.604 6.02-156.731-65.107-150.71-150.71a1.639 1.639 0 011.516-1.52C5.601-87.442 76.832-16.211 70.646 69.458z"
        ></path>
        <ellipse
          fill="#fff"
          rx="19.884"
          ry="19.864"
          transform="matrix(.99998 .00688 -.00587 .99998 72 72)"
        ></ellipse>
        <circle
          r="10"
          fill="#000"
          transform="rotate(.397 -10448.394 10542.26) skewX(.057)"
        ></circle>
        <rect
          width="81"
          height="81"
          x="-40"
          y="-40"
          fill="#0059C3"
          rx="3"
        ></rect>
        <ellipse
          fill="#0070F4"
          rx="20.25"
          ry="20.25"
          transform="rotate(.397 -92.556 90.188) skewX(.058)"
        ></ellipse>
      </g>
    </svg>
  );
};
