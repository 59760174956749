import { useMatch } from "@tanstack/react-location";
import { useMemo } from "react";
import { LocationGenerics } from "routes";

import { useListModels } from "./useListModels";

export const useCurrentModel = () => {
  const {
    params: { modelId },
  } = useMatch<LocationGenerics>();
  const { models } = useListModels();

  const currentModel = useMemo(() => {
    return models.find((m) => m.id === modelId);
  }, [models, modelId]);
  return currentModel;
};

export const useModel = (modelId: string) => {
  const { models, loadingModels } = useListModels();

  const model = useMemo(() => {
    return models.find((m) => m.id === modelId);
  }, [models, modelId]);
  return { model, loading: loadingModels };
};
