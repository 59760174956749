import classNames from "@/helpers/classNames";
import React from "react";

import { FolderOpenStateWrapper } from "../SidebarFactory";

export function PaneHeader({
  open,
  toggle,
  actions,
  children,
}: React.PropsWithChildren<{
  open: boolean;
  toggle: () => void;
  actions?: React.ReactElement;
}>) {
  const actionsRef = React.useRef<HTMLDivElement>(null);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (!actionsRef.current?.contains(event.target as Node)) {
      toggle();
    }
  };

  return (
    <FolderOpenStateWrapper
      open={open}
      onClick={handleClick}
      className={classNames(
        "flex w-full items-center",
        "h-9 py-2 pl-1 pr-2",
        "select-none text-xs font-medium",
        "cursor-pointer",
      )}
    >
      <span className="truncate text-1xs font-bold uppercase text-gray-600 dark:text-gray-400">
        {children}
      </span>
      {actions !== undefined && (
        <div className="ml-auto flex shrink-0 space-x-2" ref={actionsRef}>
          {actions}
        </div>
      )}
    </FolderOpenStateWrapper>
  );
}

export function PaneContent({
  children,
  ...props
}: React.ComponentProps<"div">) {
  return (
    <div className="max-h-full grow overflow-hidden" {...props}>
      <div className="flex h-full flex-col overflow-y-auto">{children}</div>
    </div>
  );
}
