import React from "react";

import classNames from "@/helpers/classNames";

export default function WeldLogo({
  className,
  ...props
}: React.ComponentProps<"img">) {
  return (
    <img
      className={classNames("filter dark:invert", className)}
      src="https://weld.app/weldLogo.svg"
      alt="WELD"
      {...props}
    />
  );
}
