import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import HistoryIcon from "@/components/icons/HistoryIcon";
import { useStoreLatestPreviewForTab } from "@/components/modules/ModelTabs";
import { Fragment, useMemo } from "react";

import { usePreviewOpenAtom, usePreviewsAtom } from "./Preview";

type Props = {};

const PreviewHistorySelector = (props: Props) => {
  const [previewOpenId, setPreviewOpen] = usePreviewOpenAtom();
  const [previews] = usePreviewsAtom();
  const storeLatestPreviewForTab = useStoreLatestPreviewForTab();

  const previewOptions = useMemo(
    () =>
      previews
        .map((preview) => ({
          id: preview.id,
          label: preview.displayName,
          time: preview.startTimestamp,
        }))
        .sort((a, b) => b.time - a.time),
    [previews],
  );
  const selectedOption = useMemo(
    () => previewOptions.find((option) => option.id === previewOpenId),
    [previewOptions, previewOpenId],
  );

  if (previews.length <= 1) return null;

  return (
    <Listbox
      value={selectedOption}
      onChange={(selected) => {
        setPreviewOpen(selected?.id ?? null);
        if (selected) {
          storeLatestPreviewForTab(selected.id);
        }
      }}
    >
      <Listbox.Button className="flex items-center space-x-1 border-l px-1 py-1 text-xs dark:border-gray-700">
        <HistoryIcon className="h-4 w-4" />
        <span className="font-medium">
          execution history ({previews.length})
        </span>
      </Listbox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options
          className={
            "absolute z-10 -ml-px -mt-px max-h-64 overflow-auto rounded border bg-white shadow-lg focus:outline-none dark:border-gray-700 dark:bg-gray-800"
          }
        >
          {previewOptions.map((preview) => (
            <Listbox.Option
              key={preview.id}
              value={preview}
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 text-xs ${
                  active ? "bg-gray-100 dark:bg-gray-700" : ""
                }`
              }
            >
              {({ selected }) => (
                <>
                  <span
                    className={`block truncate ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {preview.label}
                  </span>
                  {selected ? (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
};

export default PreviewHistorySelector;
