import "./weld-flags";

import {
  asyncWithLDProvider,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import { useCallback } from "react";

type UserContext = {
  key: string;
  email?: string;
  accountId?: string;
  slug?: string;
};

export async function createFeatureFlagProvider() {
  const clientId = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID;
  if (!clientId) {
    throw new Error("Launch Darkly client ID is not set");
  }
  return asyncWithLDProvider({
    clientSideID: clientId,
    reactOptions: {
      useCamelCaseFlagKeys: true,
    },
  });
}

export function useFeatureFlagContext() {
  const ldClient = useLDClient();
  const updateContext = useCallback(
    (context: UserContext) => {
      return ldClient?.identify({
        kind: "user",
        ...context,
      });
    },
    [ldClient],
  );
  return {
    updateContext,
  };
}
