import * as icons from "./graphic";

export const ExportGraphicIcon = icons.BlueOneEye1;
export const ConnectGraphicIcon = icons.BlueTwoEye1;
export const DeleteGraphicIcon = icons.DeleteIcon;
export const SourceGraphicIcon = icons.BlueOneEye2;
export const ModelGraphicIcon = icons.BlueOneEye3;
export const UserGraphicIcon = icons.BlueTwoEye2;
export const InfoGraphicIcon = icons.InfoIcon;
export const SuccessGraphicIcon = icons.SuccessIcon;
export const WarningGraphicIcon = icons.WarningIcon;
export const DangerGraphicIcon = icons.ErrorIcon;
