import React from "react";

type ConnectFn = (
  integrationId: string,
  configuration: { [key: string]: any },
) => Promise<void>;

export const ConnectContext = React.createContext<ConnectFn>(Promise.reject);

export function useConnectContext() {
  return React.useContext(ConnectContext);
}
