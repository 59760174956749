import { useCallback, useRef } from "react";

export const useDoubleClickable = (
  onClick: (e: any) => any,
  onDoubleClick?: (e: any) => void,
  delay = 300,
) => {
  const prevClicks = useRef(0);
  const singleClickTimeout = useRef<null | NodeJS.Timeout>(null);

  const handleClick = useCallback(
    (e: any) => {
      if (!onDoubleClick) {
        onClick(e);
        return;
      }
      if (prevClicks.current === 1) {
        onDoubleClick(e);
        prevClicks.current = 0;
        singleClickTimeout.current && clearTimeout(singleClickTimeout.current);
      } else {
        const timeout = setTimeout(() => {
          onClick(e);
          prevClicks.current = 0;
        }, delay);

        singleClickTimeout.current = timeout;
        prevClicks.current = 1;
      }
    },
    [onClick, onDoubleClick, delay],
  );
  return handleClick;
};
