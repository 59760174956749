import {
  StripeCustomerFragment,
  useResolveCountryFromTimeZoneLazyQuery,
  useTaxIdsDataLazyQuery,
} from "@/apollo/types";
import { merge } from "lodash";
import { UseFormProps, useForm } from "react-hook-form";

import { BillingInfoFormData } from "./BillingInfoForm";

export function useBillingInfoForm(
  customerInput?:
    | StripeCustomerFragment
    | null
    | (() => Promise<StripeCustomerFragment | null | undefined>),
  options: UseFormProps<BillingInfoFormData> = {},
) {
  const [resolveCountry] = useResolveCountryFromTimeZoneLazyQuery();
  const [fetchTaxIdData] = useTaxIdsDataLazyQuery();
  return useForm<BillingInfoFormData>({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: async () => {
      const { data: countryData } = await resolveCountry({
        variables: {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
      const country = countryData?.resolveCountryFromTimeZone.country;

      const { data: taxIdData } = await fetchTaxIdData();
      const taxIdFromBillingCountry = taxIdData?.taxIdsData.find(
        (x) => x.countryCode.toLowerCase() === country?.code?.toLowerCase(),
      );

      const customer =
        typeof customerInput === "function"
          ? await customerInput()
          : customerInput;

      const values: BillingInfoFormData = merge(
        {
          email: "",
          companyName: "",
          address: {
            country: country?.code ?? "",
            city: "",
            line1: "",
            postalCode: "",
          },
          taxIdData: {
            type: "",
            value: "",
          },
        },
        options.defaultValues !== undefined &&
          typeof options.defaultValues !== "function"
          ? options.defaultValues
          : {},
        {
          email: customer?.email,
          companyName: customer?.companyName,
          address:
            customer?.address != null
              ? {
                  country: customer.address.country,
                  city: customer.address.city,
                  line1: customer.address.line1,
                  postalCode: customer.address.postalCode,
                }
              : undefined,
          taxIdData:
            customer?.taxId != null
              ? {
                  type: customer.taxId.type,
                  value: customer.taxId.value,
                  country: customer.taxId.country,
                }
              : {
                  type: taxIdFromBillingCountry?.enum ?? "",
                  value: "",
                  country: taxIdFromBillingCountry?.countryCode ?? "",
                },
        },
      );
      return values;
    },
  });
}
