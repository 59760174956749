import classNames from "@/helpers/classNames";

export const variants = [
  "primary",
  "secondary",
  "success",
  "danger",
  "white",
] as const;

type ColorScheme = (typeof variants)[number];

type LoadingSpinnerProps = React.ComponentProps<"svg"> & {
  colorScheme?: ColorScheme;
};

const colors: Record<ColorScheme, string> = {
  primary: "text-primary",
  secondary: "text-gray-600 dark:text-gray-400",
  danger: "text-red-500 dark:text-red-400",
  success: "text-green-500 dark:text-green-400",
  white: "text-white",
};

export default function LoadingSpinner({
  colorScheme,
  className,
  ...props
}: LoadingSpinnerProps) {
  const colorClass = colorScheme ? colors[colorScheme] : "text-gray-600";
  return (
    <svg
      className={classNames(
        "animate-spin",
        colorClass,
        className ? className : "h-5 w-5",
      )}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="fill-current opacity-75"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
}
