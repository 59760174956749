import Editor from "@monaco-editor/react";
import { useDarkMode } from "@/providers/DarkModeProvider";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { useCallback, useRef } from "react";
import { useFireMonacoReadMode } from "../hooks/useFireMoacoReadMode";
import { LoadingOverlay } from "@/components/elements/LoadingComponents";

const ReadOnlyModelEditor = (props: { modelId: string }) => {
  const { isDarkModeEnabled } = useDarkMode();

  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

  const { connect: connectToFirebase, isConnected } = useFireMonacoReadMode();
  const handleMountEditor = useCallback(
    (editor: monaco.editor.IStandaloneCodeEditor) => {
      editorRef.current = editor;
      connectToFirebase(editor, props.modelId);
    },
    [connectToFirebase, props.modelId],
  );

  return (
    <>
      <Editor
        path={props.modelId}
        theme={isDarkModeEnabled ? "vs-dark" : "vs"}
        defaultValue={""}
        loading={<div />}
        language="sql"
        options={editorProps}
        onMount={handleMountEditor}
      />
      {!isConnected && <LoadingOverlay />}
    </>
  );
};
const editorProps: monaco.editor.IEditorOptions &
  monaco.editor.IDiffEditorBaseOptions = {
  readOnly: true,
  minimap: {
    enabled: false,
  },
  automaticLayout: true,
  scrollbar: {
    verticalScrollbarSize: 0,
  },
  hideCursorInOverviewRuler: true,
  renderLineHighlight: "none",
  scrollBeyondLastLine: false,
  padding: { top: 20 },
  renderOverviewRuler: false,
  enableSplitViewResizing: false,
  renderIndicators: false,
};

export default ReadOnlyModelEditor;
