import { OperationModes, OperationTypes } from "@/apollo/types";
import { generateColumnId, generateColumnName } from "./columnNameHelper";

export function generateMappings(
  sourceProperties: {
    sourceProperties: { dataSchema: any[] };
  },
  operationMode: OperationModes,
) {
  return (
    sourceProperties.sourceProperties.dataSchema.map((field, index) => {
      let destinationPropertyId = generateColumnId(index);
      return {
        type: OperationTypes.Primary,
        operation: operationMode,
        object: "sheet",
        readonly: false,
        sourcePropertyId: field.name,
        sourcePropertyType: field.type,
        sourcePropertyName: field.name,
        destinationPropertyId: destinationPropertyId,
        destinationPropertyType: "Array",
        destinationPropertyName: generateColumnName(destinationPropertyId),
        primaryKey: false,
      };
    }) ?? []
  );
}
