import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { ModelListItemFragment } from "@/apollo/types";
import { Alert, AlertIcon } from "@/components/elements/Alert";
import {
  Button,
  PrimaryButton,
  SecondaryButton,
} from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";
import FieldLabel from "@/components/primitives/InputLabel";
import { Input } from "@/components/primitives/input";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useCurrentAccount } from "@/providers/account";

import {
  useGetEditorTextState,
  useModelEditorState,
} from "../ModelEditorStore";
import { useSaveUnpublishedModel } from "../hooks/usePublishModel";
import { ValidationErrorDisplay } from "../utils/dwNamingValidation";

export const SaveModelDraft = (props: React.ComponentProps<typeof Button>) => {
  const [savingDraft, setSavingDraft] = useState(false);
  const mixpanel = useMixpanel();
  const openModel = useOpenModelInTab();
  return (
    <>
      <Button
        variant="outline"
        colorScheme="secondary"
        onClick={() => {
          setSavingDraft(true);
        }}
        {...props}
      >
        Save Draft
      </Button>
      <SaveModelDraftModal
        show={savingDraft}
        onFinished={(model) => {
          setSavingDraft(false);
          mixpanel.track("Draft Saved");
          if (model) {
            openModel({ modelId: model.id });
          }
        }}
      />
    </>
  );
};

const SaveModelDraftModal = (props: {
  show: boolean;
  onFinished: (model?: ModelListItemFragment) => void;
}) => {
  const state = useModelEditorState();
  const getTextState = useGetEditorTextState();

  const { dataWarehouseConnectionId } = useCurrentAccount();

  const form = useForm<{ name: string }>({
    defaultValues: {
      name: "",
    },
    mode: "onChange",
  });

  const { createDraftModel, loading } = useSaveUnpublishedModel(
    state.currentModelId,
  );

  const saveDraft = async () => {
    if (!form.formState.isValid || !dataWarehouseConnectionId) return;

    await createDraftModel({
      variables: {
        name: form.getValues().name,
        weldSql: getTextState().weldSql,
        sourceConnectionId: dataWarehouseConnectionId,
      },
      onCompleted(res) {
        props.onFinished(res.createDraftModel);
      },
    });
  };

  return (
    <Modal isOpen={props.show} onClose={props.onFinished}>
      <ModalCloseButton />
      <ModalHeader>Save Draft</ModalHeader>
      <ModalBody className="flex flex-col gap-8">
        <div>
          <FieldLabel>Model name</FieldLabel>
          <Input
            autoFocus
            autoComplete="off"
            {...form.register("name", {
              required: true,
              validate: {
                invalidCharacters: (value) => /^[\w_ -]*$/.test(value),
                hasNoDoubleUnderscore: (value) => !/__/.test(value),
              },
              onChange: (e) => {
                form.setValue("name", e.target.value.replaceAll(" ", "_"));
                form.trigger("name");
              },
            })}
          />
          {form.formState.errors.name && (
            <ValidationErrorDisplay error={form.formState.errors.name} />
          )}
        </div>
        <Alert status="info">
          <AlertIcon />
          Saved drafts are persisted, and shared with everyone in your account.
        </Alert>
      </ModalBody>
      <ModalFooter className="flex-row-reverse justify-start gap-4">
        <PrimaryButton
          size="md"
          disabled={!form.formState.isValid}
          onClick={(e) => {
            e.stopPropagation();
            saveDraft();
          }}
          isLoading={loading}
          className="flex-1"
        >
          Save
        </PrimaryButton>
        <SecondaryButton
          size="md"
          className="flex-1"
          onClick={() => props.onFinished()}
        >
          Cancel
        </SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};
