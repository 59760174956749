import {
  FolderOpenIcon,
  FolderIcon as FolderSVGIcon,
} from "@heroicons/react/24/outline";
import { EmojiIcon } from "@/components/elements/EmojiIcon";
import classNames from "@/helpers/classNames";

function getEmojiCharCode(emoji?: string) {
  if (!emoji) return null;
  const code = parseInt(emoji, 16);
  return isNaN(code) ? null : code;
}

type FolderIconProps = {
  emoji?: string;
  open?: boolean;
  className?: string;
};

export const FolderIcon = ({ className, emoji, open }: FolderIconProps) => {
  const emojiCharCode = getEmojiCharCode(emoji);
  if (emojiCharCode) {
    return <EmojiIcon symbol={emojiCharCode} className={className} />;
  }
  const clx = classNames("shrink-0 w-4 h-4", className);
  return open ? (
    <FolderOpenIcon className={clx} />
  ) : (
    <FolderSVGIcon className={clx} />
  );
};
