import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  MaterialisationStatus,
  WorkflowJobStatus,
  WorkflowRunStatus,
} from "@/apollo/types";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import classNames from "@/helpers/classNames";
import React from "react";

import {
  getJobStatusColor,
  getMaterializationStatusColor,
} from "../utility/getJobStatusColor";

export const JobStatusIcon = (props: { status: WorkflowJobStatus }) => {
  switch (props.status) {
    case WorkflowJobStatus.Done:
      return (
        <CheckIcon
          className={classNames(
            "h-4 w-4",
            getJobStatusColor(props.status, "text"),
          )}
        />
      );
    case WorkflowJobStatus.Failed:
      return (
        <XMarkIcon
          className={classNames(
            "h-4 w-4",
            getJobStatusColor(props.status, "text"),
          )}
        />
      );
    case WorkflowJobStatus.Running:
      return (
        <LoadingSpinner
          className={classNames(
            "h-4 w-4",
            getJobStatusColor(props.status, "text"),
          )}
        />
      );
    case WorkflowJobStatus.NotReady:
    case WorkflowJobStatus.Ready:
      return <div className="h-4 w-4 animate-pulse rounded-full bg-gray-500" />;
    default:
      return null;
  }
};

export const MaterializationStatusIcon = (props: {
  status?: MaterialisationStatus | null;
}) => {
  return (
    <>
      {props.status === MaterialisationStatus.Succeeded && (
        <CheckIcon
          className={classNames(
            "h-4 w-4",
            getMaterializationStatusColor(props.status, "text"),
          )}
        />
      )}
      {props.status === MaterialisationStatus.Failed && (
        <XMarkIcon
          className={classNames(
            "h-4 w-4",
            getMaterializationStatusColor(props.status, "text"),
          )}
        />
      )}
      {props.status === MaterialisationStatus.Running && (
        <LoadingSpinner
          className={classNames(
            "h-4 w-4",
            getMaterializationStatusColor(props.status, "text"),
          )}
        />
      )}
    </>
  );
};

export const WorkflowStatusIcon = (props: {
  status: WorkflowRunStatus;
  className?: string;
}) => {
  switch (props.status) {
    case WorkflowRunStatus.Done:
      return <CheckIcon className={props.className} />;
    case WorkflowRunStatus.Failed:
      return <XMarkIcon className={props.className} />;
    case WorkflowRunStatus.Running:
      return (
        <LoadingSpinner colorScheme={"white"} className={props.className} />
      );
    default:
      return null;
  }
};
