import plugin from "dayjs/plugin/duration";
import { useMemo } from "react";

export const useDurationDisplay = (duration: plugin.Duration) => {
  const durationString = useMemo(() => {
    return getDurationDisplay(duration);
  }, [duration]);
  return durationString;
};

export const getDurationDisplay = (duration: plugin.Duration) => {
  let display = ``;
  if (duration.hours() > 0) display += `${duration.hours()}h `;
  if (duration.minutes() > 0) display += `${duration.minutes()}m `;
  if (duration.seconds() > 0) display += `${duration.seconds()}s `;

  return display;
};
