import { useDarkMode } from "@/providers/DarkModeProvider";
import { ComponentProps as CP, PropsWithoutRef } from "react";
import {
  Area,
  Bar,
  BarChart,
  CartesianGrid,
  LabelProps,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import baseTheme from "./baseTheme";
import skeletonTheme from "./skeletonTheme";
import statsTheme from "./statsTheme";
import visualizeTheme from "./visualizeTheme";

export type ChartThemeDef = {
  BarChart: CP<typeof BarChart>;
  LineChart: CP<typeof LineChart>;
  CartesianGrid: PropsWithoutRef<CP<typeof CartesianGrid>>;
  YAxis: CP<typeof YAxis>;
  XAxis: CP<typeof XAxis>;
  Tooltip: CP<typeof Tooltip>;
  Bar: Partial<PropsWithoutRef<CP<typeof Bar>>> & {
    getLabelProps: (props: LabelProps) => LabelProps;
  };
  BarSecondary: ChartThemeDef["Bar"];
  Line: Partial<PropsWithoutRef<CP<typeof Line>>> & {
    getLabelProps: (props: LabelProps) => LabelProps;
  };
  Area: Partial<PropsWithoutRef<CP<typeof Area>>>;
  text: string;
};

const themes = {
  default: baseTheme,
  stats: statsTheme,
  visualize: visualizeTheme,
  skeleton: skeletonTheme,
} as const;

export function useChartTheme(themeName?: keyof typeof themes): ChartThemeDef {
  const { isDarkModeEnabled } = useDarkMode();
  const theme = themes[themeName ?? "default"];
  return isDarkModeEnabled ? theme.dark : theme.light;
}
