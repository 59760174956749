import React, { useMemo, useState } from "react";

import {
  ListModelFoldersDocument,
  ListModelsDocument,
  useDeleteFolderWithContentMutation,
  useDeleteModelFolderMutation,
} from "@/apollo/types";
import { DangerButton, SecondaryButton } from "@/components/elements/Button";
import MenuItem from "@/components/elements/MenuItem";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import TableMenu from "@/components/modules/TableMenu";
import useDeleteItem from "@/hooks/useDeleteItem";
import { useDWDisplayName } from "@/integrations/hooks/useDataWarehouseSpecifics";
import { FolderIcon } from "@/pages/ModelTool/components/FolderIcon";
import { ModelIcon } from "@/pages/ModelTool/components/ModelIcon/ModelIcon";
import { useToast } from "@/providers/ToastProvider";
import { useSocketEvent } from "@/socket/SocketContext";
import { ApolloClient, useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/react";

import { FolderItemType, ModelItemType } from "../SidebarFactory";
import { useNewSidebarItemState } from "../useNewSidebarItemState";
import { useSidebarFolders } from "../useSidebarFolders";

export const ModelFolderOptions: React.FC<{
  id: string;
  item: FolderItemType;
}> = (props) => {
  const isEmpty = !props.item.children?.length;
  const isBaseFolder = !props.item.folder.parentId;

  const { openFolder } = useSidebarFolders();
  const { createNewFolderItem } = useNewSidebarItemState();

  const handleDeleteEmptyFolder = useDeleteItem({
    title: "folder",
    variables: { folderId: props.id },
    mutation: useDeleteModelFolderMutation,
    refetchQueries: [{ query: ListModelFoldersDocument }],
  });

  const [deletingFolder, setDeletingFolder] = useState(false);

  return (
    <>
      <TableMenu size="sm">
        <>
          {isBaseFolder && (
            <MenuItem
              text="New folder"
              size="sm"
              onClick={() => {
                openFolder(props.id);
                createNewFolderItem(props.id);
              }}
            />
          )}
          <MenuItem
            text="Delete folder"
            size="sm"
            onClick={() => {
              if (isEmpty) {
                handleDeleteEmptyFolder();
              } else {
                setDeletingFolder(true);
              }
            }}
          />
        </>
      </TableMenu>
      <DeleteFullFolderModal
        open={deletingFolder}
        onFinished={() => setDeletingFolder(false)}
        folderId={props.id}
        item={props.item}
      />
    </>
  );
};

/*
Delete a folder and all its content with an asyncronous delete operation.
*/
const DeleteFullFolderModal: React.FC<{
  open: boolean;
  onFinished: () => void;
  folderId: string;
  item: FolderItemType;
}> = (props) => {
  const [deleting, setDeleting] = useState(false);

  return (
    <Modal
      isOpen={props.open}
      onClose={() => {
        if (deleting) return;
        props.onFinished();
      }}
    >
      <DeleteModelFolder
        open={props.open}
        deleting={deleting}
        setDeleting={setDeleting}
        onFinished={props.onFinished}
        folderId={props.folderId}
        item={props.item}
      />
    </Modal>
  );
};

const DeleteModelFolder = (props: {
  open: boolean;
  deleting: boolean;
  setDeleting: (deleting: boolean) => void;
  onFinished: () => void;
  folderId: string;
  item: FolderItemType;
}) => {
  const toast = useToast();

  const dwDisplayName = useDWDisplayName();
  const client = useApolloClient();

  const modelCount = useMemo(() => {
    return getModelCount(props.item);
  }, [props.item]);

  const [deleteFolderWithContent] = useDeleteFolderWithContentMutation({
    variables: { folderId: props.folderId },
    onError: (e) => {
      toast("Error deleting folder", e.message, "error");
      props.setDeleting(false);
    },
    onCompleted: () => {
      props.setDeleting(true);
    },
  });

  useSocketEvent("model-folder:deleted", {
    onMessage: async (message) => {
      //show success:
      toast("Folder deleted", "The folder was deleted successfully", "success");

      //Clean up apollo cache from deleted folder contents:
      evictFolderAndContentFromCache(props.item, client);
      client.cache.gc();

      props.setDeleting(false);
      props.onFinished();
    },
  });
  useSocketEvent("model-folder:delete-failed", {
    onMessage: async (message) => {
      //show error:
      toast("Error deleting folder", message.payload.errorMessage, "error");

      try {
        //refetch models and folders to get latest data in case partial deletion happened:
        client.refetchQueries({
          include: [ListModelsDocument, ListModelFoldersDocument],
        });
      } catch (err: any) {
        //ignore errors for refetching to avoid too many messagesz
        Sentry.captureMessage(
          `Error refetching on folder deletion ${err.message}`,
        );
      }

      props.setDeleting(false);
    },
  });

  return (
    <>
      <ModalCloseButton />
      <ModalHeader className="flex items-center space-x-2">
        <span>Delete</span>
        <span className="flex items-center space-x-1">
          <FolderIcon emoji={props.item.emoji} />
          <span>{props.item.folder.name}</span>
        </span>
        <span>and all its contents?</span>
      </ModalHeader>
      <ModalBody>
        <div className="mb-2 text-sm">
          This will remove {modelCount} model{modelCount > 1 ? "s" : ""} from
          WELD and {dwDisplayName}
        </div>
        <div className="mb-4 max-h-96 overflow-auto bg-gray-100 p-2 dark:bg-gray-900">
          <FolderContentDisplay item={props.item} />
        </div>
      </ModalBody>
      <ModalFooter className="flex-row-reverse justify-start gap-4">
        <DangerButton
          onClick={() => {
            if (props.deleting) return;
            props.setDeleting(true);
            deleteFolderWithContent();
          }}
          isLoading={props.deleting}
          disabled={props.deleting}
        >
          Delete
        </DangerButton>
        <SecondaryButton
          onClick={() => {
            props.onFinished();
          }}
        >
          Cancel
        </SecondaryButton>
      </ModalFooter>
    </>
  );
};

const FolderContentDisplay = (props: { item: FolderItemType }) => {
  const subfolders = props.item.children.filter(
    (c): c is FolderItemType => c.itemType === "folder",
  );
  const modelItems = props.item.children.filter(
    (c): c is ModelItemType => c.itemType === "model",
  );

  return (
    <div className="flex flex-col space-y-1 pl-2">
      <div className="flex items-center space-x-1">
        <FolderIcon open={true} />
        <span>{props.item.folder.name}</span>
      </div>
      {subfolders.map((f) => (
        <FolderContentDisplay key={f.id} item={f} />
      ))}

      {modelItems.map((modelItem) => (
        <div key={modelItem.id} className="flex items-center space-x-2 pl-2">
          <ModelIcon model={modelItem.model} />
          <span>{modelItem.model.name}</span>
        </div>
      ))}
      {modelItems.length === 0 && subfolders.length === 0 && (
        <div className="pl-4 text-sm italic">{`(empty)`}</div>
      )}
    </div>
  );
};

//Remove folder and all its contents from apollo cache
const evictFolderAndContentFromCache = (
  item: FolderItemType,
  apolloClient: ApolloClient<object>,
) => {
  const subfolders = item.children.filter(
    (c): c is FolderItemType => c.itemType === "folder",
  );
  const modelItems = item.children.filter(
    (c): c is ModelItemType => c.itemType === "model",
  );

  //Remove folder from cache
  apolloClient.cache.evict({
    id: apolloClient.cache.identify({
      __typename: "ModelFolder",
      id: item.id,
    }),
  });

  //Remove models from cache
  modelItems.forEach((modelItem) => {
    apolloClient.cache.evict({
      id: apolloClient.cache.identify(modelItem.model),
    });
  });

  //Remove subfolders from cache
  subfolders.forEach((f) => {
    evictFolderAndContentFromCache(f, apolloClient);
  });
};

//Get total number of models within a folder including subfolders
const getModelCount = (item: FolderItemType): number => {
  const subfolders = item.children.filter(
    (c): c is FolderItemType => c.itemType === "folder",
  );
  const modelItems = item.children.filter(
    (c): c is ModelItemType => c.itemType === "model",
  );

  return (
    modelItems.length + subfolders.reduce((acc, f) => acc + getModelCount(f), 0)
  );
};
