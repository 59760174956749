import { ComponentProps } from "react";

import { ActionButton } from "@/components/elements/Button";
import { useMixpanel } from "@/monitoring/mixpanel";

import { useStepsContext } from "../../stepper/useSteps";

export function NavButton(props: ComponentProps<typeof ActionButton>) {
  return <ActionButton {...props} />;
}

export function NextButton(props: ComponentProps<typeof NavButton>) {
  const { currentStep, nextStep } = useStepsContext();
  const mixpanel = useMixpanel();

  const label = props.children || "Next";
  return (
    <ActionButton
      colorScheme="primary"
      type="submit"
      disabled={!nextStep}
      {...props}
      onClick={(e) => {
        props.onClick?.(e);
        mixpanel.track("Next Data Source Step Button Clicked", {
          step: currentStep.id,
          nextStep: nextStep?.id,
        });
      }}
    >
      {label}
    </ActionButton>
  );
}

export function PrevButton(props: ComponentProps<typeof NavButton>) {
  const { onGoToStep, prevStep, currentStep } = useStepsContext();
  const mixpanel = useMixpanel();
  return (
    <ActionButton
      colorScheme="secondary"
      variant="ghost"
      disabled={!prevStep}
      onClick={(e) => {
        if (!prevStep) return;
        onGoToStep(prevStep.id);
        props.onClick?.(e);
        mixpanel.track("Prev Data Source Step Button Clicked", {
          step: currentStep.id,
          prevStep: prevStep?.id,
        });
      }}
      {...props}
    >
      Back
    </ActionButton>
  );
}
