import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { useRef } from "react";

import cn from "@/helpers/classNames";
import { isWindows } from "@/helpers/isWindows";
import { useCallbackRef } from "@/hooks/useCallbackRef";
import { useHighlightWeldTags } from "@/pages/ModelTool/QueryEditor/monaco/useHighlighWeldTags";
import { useWeldTagHoverDialog } from "@/pages/ModelTool/QueryEditor/monaco/useWeldTagHoverDialog";
import { EDITOR_TAB_SIZE } from "@/pages/ModelTool/constants/editor";
import { useDarkMode } from "@/providers/DarkModeProvider";
import Editor from "@monaco-editor/react";

import useAssitantCompletionItems from "./useAssistantCompletionItems";

const editorProps: monaco.editor.IEditorOptions &
  monaco.editor.IDiffEditorBaseOptions = {
  minimap: {
    enabled: false,
  },
  automaticLayout: true,
  scrollbar: {
    verticalScrollbarSize: 4,
    horizontalScrollbarSize: 4,
  },
  hideCursorInOverviewRuler: true,
  renderLineHighlight: "none",
  scrollBeyondLastLine: false,
  padding: { top: 8, bottom: 8 },
  fontFamily: `ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
  fontSize: 14,
  renderOverviewRuler: false,
  enableSplitViewResizing: false,
  renderIndicators: false,
  fixedOverflowWidgets: true,
  lineNumbers: "off",
  contextmenu: false,
  quickSuggestionsDelay: 300,
  wordWrap: "on",
  glyphMargin: false,
  lineDecorationsWidth: 0,
  overviewRulerBorder: false,
  folding: false,
  lineNumbersMinChars: 0,
};

const AdvancedMessageInput = (props: {
  value: string;
  onChange: (updated: string) => void;
  onEnterKeyDown?: (e: KeyboardEvent) => void;
  placeholder?: string;
  className?: string;
}) => {
  const { isDarkModeEnabled } = useDarkMode();
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

  const onEnterKeyDown = useCallbackRef(props.onEnterKeyDown);

  useAssitantCompletionItems();
  useHighlightWeldTags(editorRef.current);
  useWeldTagHoverDialog(editorRef.current, "plaintext");

  const handleMountEditor = (editor: monaco.editor.IStandaloneCodeEditor) => {
    // Use line feed (\n) as the end of line character.
    // This is important for collaborative editing to prevent sync-issues between different browsers & platforms.
    editor.getModel()?.setEOL(monaco.editor.EndOfLineSequence.LF);
    // Set editor tab size so that it aligns with our formatter config.
    editor.getModel()?.updateOptions({ tabSize: EDITOR_TAB_SIZE });
    editor.focus();
    editorRef.current = editor;

    editor.onKeyDown((e) => {
      const modKey = isWindows() ? e.ctrlKey : e.metaKey;
      if (e.code === "KeyF" && modKey) {
        // Prevent "find" widget from opening
        e.preventDefault();
        e.stopPropagation();
      }

      const suggestController = editorRef.current?.getContribution(
        "editor.contrib.suggestController",
      ) as any;

      const isSuggestWidgetVisible =
        suggestController &&
        suggestController.model &&
        suggestController.model.state !== 0; // 0 means not visible
      if (isSuggestWidgetVisible) return;

      if (e.code === "Enter" && !e.shiftKey && !modKey) {
        e.preventDefault();
        // Stop propagation so that the monaco editor does not add a line break
        e.stopPropagation();
        onEnterKeyDown?.(e.browserEvent);
      }
    });
  };

  return (
    <div
      className={cn(
        "relative block w-full bg-white pl-3 pr-12 dark:bg-[#1e1e1e]",
        props.className,
      )}
    >
      <Editor
        path={"sql_assistant"}
        theme={isDarkModeEnabled ? "vs-dark" : "vs"}
        value={props.value}
        language="plaintext"
        options={editorProps}
        onMount={handleMountEditor}
        onChange={(value) => {
          if (typeof value === "undefined") return;
          props.onChange(value);
        }}
      />
      {!props.value.length && (
        <div className="pointer-events-none absolute left-0 top-0 z-10 px-3 py-2 text-sm text-gray-400 dark:text-gray-500">
          {props.placeholder}
        </div>
      )}
    </div>
  );
};

export { AdvancedMessageInput };
