export const keywords = [
  "ALTER",
  "ARRAY",
  "AS",
  "AT",
  "BEFORE",
  "BEGIN",
  "BIGINT",
  "BINARY",
  "BOOLEAN",
  "BREAK",
  "BY",
  "BYTEINT",
  "CALL",
  "CASE",
  "CHANGES",
  "CHAR",
  "CHARACTER",
  "CHECK",
  "CLOSE",
  "COLUMN",
  "COMMIT",
  "CONNECT",
  "CONSTRAINT",
  "CONTINUE",
  "CREATE",
  "CURRENT",
  "CURRENT_DATE",
  "DATABASE",
  "DATE",
  "DATETIME",
  "DAY",
  "DAYOFYEAR",
  "DECIMAL",
  "DECLARE",
  "DELETE",
  "DISTINCT",
  "DOUBLE",
  "DROP",
  "ELSE",
  "END",
  "EXECUTE",
  "FETCH",
  "FLOAT",
  "FLOAT4",
  "FLOAT8",
  "FOLLOWING",
  "FOR",
  "FOREIGN",
  "FROM",
  "GEOGRAPHY",
  "GEOMETRY",
  "GET",
  "GRANT",
  "GROUP",
  "GSCLUSTER",
  "HASH",
  "HAVING",
  "HOUR",
  "IF",
  "INCREMENT",
  "INSERT",
  "INT",
  "INTEGER",
  "INTO",
  "KEY",
  "LATERAL",
  "LIMIT",
  "LIST",
  "LOOP",
  "MERGE",
  "MICROSECOND",
  "MILLISECOND",
  "MINUTE",
  "MONTH",
  "NAN",
  "NANOSECOND",
  "NORMAL",
  "NUMBER",
  "NUMERIC",
  "OBJECT",
  "OF",
  "ON",
  "OPEN",
  "ORDER",
  "PARTITION",
  "PRIMARY",
  "PUT",
  "QUALIFY",
  "QUARTER",
  "REAL",
  "REMOVE",
  "RETURN",
  "REVOKE",
  "ROLE",
  "ROLLBACK",
  "ROW",
  "ROWS",
  "SAMPLE",
  "SCHEMA",
  "SECOND",
  "SELECT",
  "SET",
  "SMALLINT",
  "SPLIT",
  "START",
  "STRING",
  "TABLE",
  "TABLESAMPLE",
  "TEXT",
  "THEN",
  "TIME",
  "TIMESTAMP",
  "TINYINT",
  "TO",
  "TOP",
  "TRIGGER",
  "TRUNCATE",
  "UNIQUE",
  "USE",
  "USING",
  "VALUES",
  "VARBINARY",
  "VARCHAR",
  "VARIANT",
  "VIEW",
  "WEEK",
  "WHEN",
  "WHERE",
  "WHILE",
  "WITH",
  "FALSE",
  "TRUE",
].map((x) => x.toLowerCase());
export const operators = [
  "ALL",
  "AND",
  "ANY",
  "BETWEEN",
  "CONTAINS",
  "CROSS",
  "EXCEPT",
  "EXISTS",
  "FULL",
  "IN",
  "INNER",
  "INTERSECT",
  "IS",
  "JOIN",
  "LEFT",
  "LIKE",
  "NOT",
  "OR",
  "PIVOT",
  "RIGHT",
  "SOME",
  "UNION",
  "UNPIVOT",
].map((x) => x.toLowerCase());
export const builtinFunctions = [
  "ACOS",
  "ASCII",
  "ASIN",
  "ATAN",
  "AVG",
  "CAST",
  "CHAR",
  "CHARINDEX",
  "COALESCE",
  "COLLATE",
  "CONCAT",
  "COS",
  "COT",
  "COUNT",
  "CUME_DIST",
  "CURRENT_USER",
  "DATEADD",
  "DATEDIFF",
  "DAY",
  "DEGREES",
  "DENSE_RANK",
  "EXP",
  "FIRST_VALUE",
  "FLOOR",
  "GROUPING",
  "GROUPING_ID",
  "LAG",
  "LAST_VALUE",
  "LEAD",
  "LEN",
  "LOG",
  "LOWER",
  "LTRIM",
  "MAX",
  "MIN",
  "MONTH",
  "NTILE",
  "NULLIF",
  "PERCENT_RANK",
  "PERCENTILE_CONT",
  "PERCENTILE_DISC",
  "PI",
  "POWER",
  "RADIANS",
  "RANK",
  "REPLACE",
  "REVERSE",
  "ROUND",
  "ROW_NUMBER",
  "RTRIM",
  "SIGN",
  "SIN",
  "SOUNDEX",
  "SPACE",
  "SQUARE",
  "SUM",
  "TAN",
  "TRY_CAST",
  "UNICODE",
  "UPDATE",
  "UPPER",
  "YEAR",
  "ACOSH",
  "ADD_MONTHS",
  "ALL_USER_NAMES",
  "ANY_VALUE",
  "APPROX_COUNT_DISTINCT",
  "APPROX_PERCENTILE",
  "APPROX_PERCENTILE_ACCUMULATE",
  "APPROX_PERCENTILE_COMBINE",
  "APPROX_PERCENTILE_ESTIMATE",
  "APPROX_TOP_K",
  "APPROX_TOP_K_ACCUMULATE",
  "APPROX_TOP_K_COMBINE",
  "APPROX_TOP_K_ESTIMATE",
  "APPROXIMATE_JACCARD_INDEX",
  "APPROXIMATE_SIMILARITY",
  "ARRAY_AGG",
  "ARRAY_APPEND",
  "ARRAY_CAT",
  "ARRAY_COMPACT",
  "ARRAY_CONSTRUCT",
  "ARRAY_CONSTRUCT_COMPACT",
  "ARRAY_CONTAINS",
  "ARRAY_DISTINCT",
  "ARRAY_EXCEPT",
  "ARRAY_INSERT",
  "ARRAY_INTERSECTION",
  "ARRAY_POSITION",
  "ARRAY_PREPEND",
  "ARRAY_SIZE",
  "ARRAY_SLICE",
  "ARRAY_TO_STRING",
  "ARRAY_UNION_AGG",
  "ARRAY_UNIQUE_AGG",
  "ARRAYS_OVERLAP",
  "AS_ARRAY",
  "AS_BINARY",
  "AS_BOOLEAN",
  "AS_CHAR",
  "AS_DATE",
  "AS_DECIMAL",
  "AS_DOUBLE",
  "AS_INTEGER",
  "AS_NUMBER",
  "AS_OBJECT",
  "AS_REAL",
  "AS_TIME",
  "AS_TIMESTAMP",
  "AS_VARCHAR",
  "ASINH",
  "ATAN2",
  "ATANH",
  "BASE64_DECODE_BINARY",
  "BASE64_DECODE_STRING",
  "BASE64_ENCODE",
  "BIT_LENGTH",
  "BITAND",
  "BITAND_AGG",
  "BITMAP_BIT_POSITION",
  "BITMAP_BUCKET_NUMBER",
  "BITMAP_CONSTRUCT_AGG",
  "BITMAP_COUNT",
  "BITMAP_OR_AGG",
  "BITNOT",
  "BITOR",
  "BITOR_AGG",
  "BITSHIFTLEFT",
  "BITSHIFTRIGHT",
  "BITXOR",
  "BITXOR_AGG",
  "BOOLAND",
  "BOOLAND_AGG",
  "BOOLNOT",
  "BOOLOR",
  "BOOLOR_AGG",
  "BOOLXOR",
  "BOOLXOR_AGG",
  "BUILD_SCOPED_FILE_URL",
  "BUILD_STAGE_FILE_URL",
  "CBRT",
  "CEIL",
  "CHECK_JSON",
  "CHECK_XML",
  "CHR",
  "COLLATION",
  "COMPRESS",
  "CONCAT_WS",
  "CONVERT_TIMEZONE",
  "CORR",
  "COSH",
  "COUNT_IF",
  "COVAR_POP",
  "COVAR_SAMP",
  "DATE_FROM_PARTS",
  "DATE_PART",
  "DATE_TRUNC",
  "DAYNAME",
  "DAYOFWEEK",
  "DAYOFWEEKISO",
  "DECODE",
  "DECOMPRESS_BINARY",
  "DECOMPRESS_STRING",
  "DECRYPT",
  "DECRYPT_RAW",
  "DIV0",
  "DIV0NULL",
  "EDITDISTANCE",
  "ENCRYPT",
  "ENCRYPT_RAW",
  "ENDSWITH",
  "EPOCH_MICROSECOND",
  "EPOCH_MILLISECOND",
  "EPOCH_NANOSECOND",
  "EPOCH_SECOND",
  "EQUAL_NULL",
  "EXTRACT",
  "FACTORIAL",
  "FLATTEN",
  "GREATEST",
  "HASH_AGG",
  "HAVERSINE",
  "HEX_DECODE_BINARY",
  "HEX_DECODE_STRING",
  "HEX_ENCODE",
  "HLL",
  "HLL_ACCUMULATE",
  "HLL_COMBINE",
  "HLL_ESTIMATE",
  "HLL_EXPORT",
  "HLL_IMPORT",
  "IFF",
  "IFNULL",
  "ILIKE",
  "INITCAP",
  "IS_ARRAY",
  "IS_BINARY",
  "IS_BOOLEAN",
  "IS_CHAR",
  "IS_VARCHAR",
  "IS_DATE",
  "IS_DATE_VALUE",
  "IS_DECIMAL",
  "IS_DOUBLE",
  "IS_REAL",
  "IS_GRANTED_TO_INVOKER_ROLE",
  "IS_INTEGER",
  "IS_NULL_VALUE",
  "IS_OBJECT",
  "IS_ROLE_IN_SESSION",
  "IS_TIME",
  "JAROWINKLER_SIMILARITY",
  "JSON_EXTRACT_PATH_TEXT",
  "KURTOSIS",
  "LAST_DAY",
  "LAST_QUERY_ID",
  "LAST_SUCCESSFUL_SCHEDULED_TIME",
  "LAST_TRANSACTION",
  "LEAST",
  "LENGTH",
  "LISTAGG",
  "LN",
  "LOCALTIME",
  "LOCALTIMESTAMP",
  "LPAD",
  "MD5",
  "MD5_BINARY",
  "MD5_HEX",
  "MD5_NUMBER_LOWER64",
  "MD5_NUMBER_UPPER64",
  "MEDIAN",
  "MINHASH",
  "MINHASH_COMBINE",
  "MOD",
  "MODE",
  "MONTHNAME",
  "MONTHS_BETWEEN",
  "NEXT_DAY",
  "NTH_VALUE",
  "NULLIFZERO",
  "NVL",
  "NVL2",
  "OBJECT_AGG",
  "OBJECT_CONSTRUCT",
  "OBJECT_CONSTRUCT_KEEP_NULL",
  "OBJECT_DELETE",
  "OBJECT_INSERT",
  "OBJECT_KEYS",
  "OBJECT_PICK",
  "OCTET_LENGTH",
  "PARSE_IP",
  "PARSE_JSON",
  "PARSE_URL",
  "PARSE_XML",
  "POSITION",
  "POW",
  "PREVIOUS_DAY",
  "RANDOM",
  "RANDSTR",
  "REGEXP",
  "REGEXP_COUNT",
  "REGEXP_INSTR",
  "REGEXP_LIKE",
  "REGEXP_REPLACE",
  "REGEXP_SUBSTR",
  "REGEXP_SUBSTR_ALL",
  "REGR_AVGX",
  "REGR_AVGY",
  "REGR_COUNT",
  "REGR_INTERCEPT",
  "REGR_R2",
  "REGR_SLOPE",
  "REGR_SXX",
  "REGR_SXY",
  "REGR_SYY",
  "REGR_VALX",
  "REGR_VALY",
  "REPEAT",
  "RLIKE",
  "RPAD",
  "RTRIMMED_LENGTH",
  "SEQ1",
  "SEQ2",
  "SEQ4",
  "SEQ8",
  "SHA1",
  "SHA1_BINARY",
  "SHA1_HEX",
  "SHA2",
  "SHA2_BINARY",
  "SHA2_HEX",
  "SINH",
  "SKEW",
  "SOUNDEX_P123",
  "SPLIT_PART",
  "SPLIT_TO_TABLE",
  "STARTSWITH",
  "STDDEV",
  "STDDEV_POP",
  "STDDEV_SAMP",
  "STRIP_NULL_VALUE",
  "STRTOK",
  "STRTOK_SPLIT_TO_TABLE",
  "STRTOK_TO_ARRAY",
  "SUBSTR",
  "SUBSTRING",
  "TANH",
  "TIME_FROM_PARTS",
  "TIME_SLICE",
  "TIMEADD",
  "TIMEDIFF",
  "TIMESTAMP_FROM_PARTS",
  "TIMESTAMP_LTZ",
  "TIMESTAMP_NTZ",
  "TIMESTAMP_TZ",
  "TIMESTAMPADD",
  "TIMESTAMPDIFF",
  "TIMEZONE_HOUR",
  "TIMEZONE_MINUTE",
  "TO_ARRAY",
  "TO_BINARY",
  "TO_BOOLEAN",
  "TO_CHAR",
  "TO_DATE",
  "TO_DECIMAL",
  "TO_DOUBLE",
  "TO_GEOGRAPHY",
  "TO_GEOMETRY",
  "TO_JSON",
  "TO_NUMBER",
  "TO_NUMERIC",
  "TO_OBJECT",
  "TO_TIME",
  "TO_TIMESTAMP",
  "TO_VARCHAR",
  "TO_VARIANT",
  "TO_XML",
  "TRANSLATE",
  "TRIM",
  "TRY_BASE64_DECODE_BINARY",
  "TRY_BASE64_DECODE_STRING",
  "TRY_HEX_DECODE_BINARY",
  "TRY_HEX_DECODE_STRING",
  "TRY_PARSE_JSON",
  "TRY_TO_BINARY",
  "TRY_TO_BOOLEAN",
  "TRY_TO_DATE",
  "TRY_TO_DECIMAL",
  "TRY_TO_NUMBER",
  "TRY_TO_NUMERIC",
  "TRY_TO_DOUBLE",
  "TRY_TO_GEOGRAPHY",
  "TRY_TO_GEOMETRY",
  "TRY_TO_TIME",
  "TRY_TO_TIMESTAMP",
  "TYPEOF",
  "UNIFORM",
  "UUID_STRING",
  "VALIDATE",
  "VALIDATE_PIPE_LOAD",
  "VAR_POP",
  "VAR_SAMP",
  "VARIANCE",
  "VARIANCE_POP",
  "VARIANCE_SAMP",
  "WEEK_OF_YEAR_POLICY",
  "WEEK_START",
  "WEEKISO",
  "XMLGET",
  "YEAROFWEEK",
  "YEAROFWEEKISO",
  "ZEROIFNULL",
  "ZIPF",
  "CURRENT_TIMESTAMP",
  "SQRT",
].map((x) => x.toLowerCase());
