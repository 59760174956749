import classNames from "@/helpers/classNames";
import { twMerge } from "tailwind-merge";

type UserAvatarProps = {
  firstName?: string | null;
  lastName?: string | null;
  size?: "lg";
} & React.ComponentProps<"span">;

export default function UserAvatar({
  firstName,
  lastName,
  size,
  className,
  ...props
}: UserAvatarProps) {
  const fullName = `${firstName} ${lastName}`;
  const userAcronym =
    firstName && lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`
      : firstName?.charAt(0);

  return (
    <span
      className={twMerge(
        classNames(
          "flex flex-none items-center justify-center rounded-md font-semibold uppercase text-white",
          size === "lg" ? "h-9 w-9 text-xs" : "h-4 w-4 text-2xs",
          className,
        ),
      )}
      style={{
        backgroundColor: randomColorFromString(fullName),
      }}
      aria-label={fullName}
      {...props}
    >
      {userAcronym || ":)"}
    </span>
  );
}

function randomColorFromString(str: string) {
  if (str.length === 0) return "#9fa3ab";

  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let j = 0; j < 3; j += 1) {
    const value = (hash >> (j * 8)) & 255;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}
