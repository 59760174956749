import { ChatRole } from "@/apollo/types";

import { ChatMessage } from "./types";

export const InitialMessages: Omit<ChatMessage, "id">[] = [
  {
    content:
      "Hey there! I'm Ed, your AI assistant. I can help you with SQL questions, and more. Try asking me a question about a model or a data source.",
    role: ChatRole.Assistant,
  },
  {
    content: "What can I do for you?",
    role: ChatRole.Assistant,
  },
];
