import classNames from "@/helpers/classNames";
import React, { PropsWithChildren } from "react";
import { Handle, Position } from "react-flow-renderer";

type NodeProps = React.PropsWithChildren<{
  hasDependents?: boolean;
  hasDependencies?: boolean;
  className?: string;
  wide?: boolean;
}>;

function Node(props: NodeProps) {
  return (
    <div
      className={classNames(
        "cursor-pointer",
        props.wide ? "h-16 w-64" : "h-20 w-48",
      )}
    >
      {props.hasDependencies && (
        <Handle
          type="target"
          position={Position.Left}
          style={{ borderRadius: 10 }}
        />
      )}
      {props.children}
      {props.hasDependents && (
        <Handle
          type="source"
          position={Position.Right}
          id="a"
          style={{ top: "50%", borderRadius: 10 }}
        />
      )}
    </div>
  );
}

type ReactFlowNodeProps = PropsWithChildren<{
  data: { children: React.ReactNode };
}>;

export const nodeTypes = {
  input_node: (props: ReactFlowNodeProps) => {
    return <Node hasDependencies>{props.data.children}</Node>;
  },
  output_node: (props: ReactFlowNodeProps) => {
    return <Node hasDependents>{props.data.children}</Node>;
  },
  node: (props: ReactFlowNodeProps) => {
    return (
      <Node hasDependents hasDependencies>
        {props.data.children}
      </Node>
    );
  },
  independent_node: (props: ReactFlowNodeProps) => {
    return <Node>{props.data.children}</Node>;
  },
};

export const nodeTypesWide = {
  input_node: (props: ReactFlowNodeProps) => {
    return (
      <Node wide hasDependencies>
        {props.data.children}
      </Node>
    );
  },
  output_node: (props: ReactFlowNodeProps) => {
    return (
      <Node wide hasDependents>
        {props.data.children}
      </Node>
    );
  },
  node: (props: ReactFlowNodeProps) => {
    return (
      <Node wide hasDependents hasDependencies>
        {props.data.children}
      </Node>
    );
  },
  independent_node: (props: ReactFlowNodeProps) => {
    return <Node wide>{props.data.children}</Node>;
  },
};
