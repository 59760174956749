import { ChevronUpDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import Tooltip from "@/components/elements/Tooltip";
import { default as classNames, default as cn } from "@/helpers/classNames";
import { DropdownOption } from "@/integrations/forms";
import { useDarkMode } from "@/providers/DarkModeProvider";
import {
  ComponentType,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactSelect, {
  ControlProps,
  IndicatorProps,
  InputProps,
  MenuProps,
  MultiValueProps,
  OptionProps,
  OptionTypeBase,
  PlaceholderProps,
  SingleValueProps,
  ValueContainerProps,
  components,
} from "react-select";
import ReactSelectAsync, {
  Props as ReactSelectAsyncProps,
} from "react-select/async";
import CreatableSelect from "react-select/creatable";
import {
  MultiValueGenericProps,
  MultiValueRemoveProps,
} from "react-select/src/components/MultiValue";
import mergeRefs from "@/utils/mergeRefs";

import InputContainerButton from "./InputContainer";

const Control = ({ children, ...props }: ControlProps<any, false>) => {
  const {
    selectProps: { descriptionKey },
    isDisabled,
  } = props;
  const hasDescription = useMemo(
    () => !!props.options?.some((v) => v[descriptionKey]),
    [descriptionKey, props.options],
  );
  return (
    <components.Control {...props}>
      <InputContainerButton
        isDisabled={isDisabled}
        className={cn(`py-2.5`, {
          "h-16": hasDescription,
          "h-auto": props.isMulti,
        })}
        isFocused={props.isFocused}
      >
        <>{children}</>
      </InputContainerButton>
    </components.Control>
  );
};

const DropdownIndicator = (props: IndicatorProps<any, false>) => {
  return (
    <components.DropdownIndicator {...props} className="pr-2">
      <ChevronUpDownIcon
        className="h-5 w-5 text-gray-400 transition-all duration-300 group-hover:text-gray-500"
        aria-hidden="true"
      />
    </components.DropdownIndicator>
  );
};

const SelectContainer = ({ children, ...props }: any) => (
  <components.SelectContainer {...props}>{children}</components.SelectContainer>
);

const ValueContainer = (props: ValueContainerProps<any, false>) => (
  <components.ValueContainer
    {...props}
    className={`flex grow flex-wrap gap-1 pl-2`}
  />
);

const Badge = ({ children }: { children: string }) => {
  if (!children) return null;
  return (
    <Tooltip content={children.toLowerCase()}>
      <span className="truncate rounded bg-gray-100 px-1 text-xs text-gray-900">
        {children.toLowerCase()}
      </span>
    </Tooltip>
  );
};

const SelectItemContent = (props: any) => {
  const {
    data,
    selectProps: { descriptionKey, descriptionParser, badgeKey, iconParser },
    isSelected,
    isFocused,
    label,
  } = props;

  return (
    <>
      {iconParser && data && (
        <div className="shrink-0 pr-2">{iconParser(data)}</div>
      )}

      <span
        className={`${
          isSelected ? "font-medium" : "font-normal"
        } flex flex-col truncate text-sm`}
      >
        <Tooltip content={label}>
          <span className="truncate dark:text-white">{label}</span>
        </Tooltip>

        {descriptionKey && (
          <span
            className={`text-sm ${
              isFocused ? "text-white" : "text-gray-400"
            } truncate`}
          >
            {descriptionParser
              ? descriptionParser(data[descriptionKey])
              : data[descriptionKey]}
          </span>
        )}
      </span>

      <Badge>{data[badgeKey]}</Badge>

      {data?.readonly && <Badge>readonly</Badge>}

      {data?.isRequired && <Badge>Required</Badge>}
    </>
  );
};

const SingleValue = ({ children, ...props }: SingleValueProps<any>) => {
  return (
    <components.SingleValue {...props}>
      <div className="flex items-center space-x-1 text-sm text-gray-800">
        <SelectItemContent
          {...props}
          label={props.selectProps.getOptionLabel?.(props.data)}
        />
      </div>
    </components.SingleValue>
  );
};

const Menu = ({ children, ...props }: MenuProps<any, false>) => (
  <components.Menu {...props}>
    {props.selectProps.isLoading ? (
      <div className="p-2 text-center text-base leading-6 text-gray-400 dark:bg-gray-700">
        Loading...
      </div>
    ) : (
      children
    )}
  </components.Menu>
);

const Option = ({ innerProps, ...props }: OptionProps<any, false>) => {
  const {
    data,
    isFocused,
    isDisabled,
    selectOption,
    isSelected,
    selectProps: { disableCheckmark },
  } = props;
  const addIndentToLabel = (data: DropdownOption): string => {
    return data.indent
      ? `\u2514${"\u2500".repeat(data.indent || 0)} ${props.label}`
      : props.label;
  };

  return (
    <div
      {...innerProps}
      className={` ${isSelected && isFocused ? "text-white" : ""} ${isSelected && !isFocused ? "text-blue-500" : ""} ${isFocused ? "bg-blue-500 text-white" : "text-gray-900"} ${
        isDisabled
          ? "cursor-not-allowed opacity-25 hover:bg-blue-200 dark:hover:bg-blue-500"
          : ""
      } relative cursor-default select-none px-4 py-2`}
    >
      <div
        className={classNames(
          `flex items-center space-x-1 truncate`,
          isSelected ? "font-medium" : "font-normal",
        )}
        onClick={() => selectOption(data)}
      >
        <SelectItemContent {...props} label={addIndentToLabel(data)} />
        <div className="grow" />
        {isSelected && !disableCheckmark ? (
          <span
            className={`${isFocused ? "text-white" : "text-blue-500"} flex items-center`}
          >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        ) : null}
      </div>
    </div>
  );
};

const Placeholder = (props: PlaceholderProps<any, false>) => {
  return (
    <div className="absolute inset-0 flex flex-col justify-center truncate px-2 text-sm text-gray-400">
      <div className="truncate">{props.children}</div>
    </div>
  );
};

const ClearIndicator = (props: IndicatorProps<any, false>) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      <XMarkIcon className={`h-4 w-4 text-gray-400 hover:text-gray-500`} />
    </div>
  );
};

const MultiValue = (props: MultiValueProps<any>) => (
  <components.MultiValue
    {...props}
    className={`${
      props.isFocused ? "bg-blue-300" : "bg-black dark:bg-gray-700"
    } flex items-center overflow-hidden rounded-sm text-xs text-white`}
  />
);

const MultiValueContainer = ({
  children,
  ...props
}: MultiValueGenericProps<any>) => (
  <components.MultiValueContainer {...props}>
    <div
      className={`flex cursor-pointer items-center space-x-1 py-0.5 pl-2 pr-1 transition-opacity hover:opacity-80`}
    >
      {children}
    </div>
  </components.MultiValueContainer>
);

const MultiValueRemove = ({
  children,
  ...props
}: MultiValueRemoveProps<any>) => (
  <components.MultiValueRemove {...props}>
    <XMarkIcon className={`h-3 w-3`} />
  </components.MultiValueRemove>
);

const Input = ({
  isMulti,
  hasValue,
  ...props
}: InputProps & { isMulti: boolean; hasValue: boolean }) => (
  <components.Input {...props} className="h-5" />
);

const Select = forwardRef(
  (
    {
      autoFocus,
      onChange = () => null,
      options = [],
      disabled = false,
      labelKey = "label",
      descriptionKey = "",
      badgeKey = "badge",
      creatable = false,
      isClearable = true,
      value,
      placeholder,
      getOptionLabel,
      autoSelectOnSingleOption = false,
      disableCheckmark = false,
      styles,
      components,
      ...rest
    }: any,
    forwardRef,
  ) => {
    const { isDarkModeEnabled } = useDarkMode();
    const ref = useRef<CreatableSelect<any>>();

    // Fix from https://github.com/JedWatson/react-select/issues/165 (makes headlessui transitions and isSearchable work together)
    const [isSearchable, setIsSearchable] = useState(false);
    useEffect(() => {
      setTimeout(() => {
        setIsSearchable(rest.isSearchable === false ? false : true);
      }, 1);
    }, [rest.isSearchable]);

    useEffect(() => {
      if (!autoFocus) return;

      setTimeout(() => {
        ref.current?.focus();
      });
    }, [autoFocus]);

    useAutoSelectSingleOption(
      options,
      onChange,
      autoSelectOnSingleOption,
      rest.isMulti,
    );

    const Select = {
      creatable: CreatableSelect,
      default: ReactSelect,
    }[creatable ? "creatable" : "default"];

    return (
      // @ts-ignore
      <Select
        ref={mergeRefs(forwardRef, ref)}
        onChange={onChange}
        descriptionKey={descriptionKey}
        badgeKey={badgeKey}
        labelKey={labelKey}
        options={options}
        disableCheckmark={disableCheckmark}
        styles={{
          menuList: (base: any) => ({
            ...base,
            textSize: "10px",
            padding: 0,
            ...(isDarkModeEnabled && {
              background: "rgb(62, 63, 65)",
            }),
          }),
          menu: (base: any) => ({
            ...base,
            overflow: "hidden",
            minWidth: "100%",
            width: "fit-content",
          }),
          control: () => null,
          input: () => ({
            ...(isDarkModeEnabled && { color: "#fff" }),
          }),
          valueContainer: () => null,
          indicatorsContainer: (base: any) => ({ ...base, padding: undefined }),
          dropdownIndicator: (base: any) => ({ ...base, padding: undefined }),
          clearIndicator: (base: any) => ({ ...base, padding: undefined }),
          loadingIndicator: (base: any) => ({ ...base, padding: undefined }),
          multiValue: () => null,
          multiValueLabel: () => null,
          multiValueRemove: () => null,
          ...(styles || {}),
        }}
        menuPlacement="auto"
        isSearchable={isSearchable}
        isOptionDisabled={(option: any) => option.disabled}
        isDisabled={disabled}
        isClearable={isClearable}
        getOptionLabel={getOptionLabel || ((o: any) => o[labelKey] || o.label)}
        getOptionValue={(o: any) => o.id || o.value}
        menuPortalTarget={document.querySelector("#selecter_drawer")}
        components={{
          Option,
          Control,
          SelectContainer,
          ValueContainer,
          SingleValue,
          Placeholder,
          Menu,
          DropdownIndicator,
          IndicatorSeparator: () => null,
          ClearIndicator,
          MultiValue,
          MultiValueContainer,
          MultiValueRemove,
          Input,
          ...(components || {}),
        }}
        value={value || null}
        placeholder={placeholder || "Select or create..."}
        {...rest}
      />
    );
  },
);

export default Select;

export function AsyncSelect<OptionType extends OptionTypeBase>(
  props: ReactSelectAsyncProps<OptionType, false>,
) {
  const { isDarkModeEnabled } = useDarkMode();
  return (
    <ReactSelectAsync
      {...props}
      styles={{
        menuList: (base: any) => ({
          ...base,
          textSize: "10px",
          padding: 0,
          ...(isDarkModeEnabled && {
            background: "rgb(62, 63, 65)",
          }),
        }),
        menu: (base: any) => ({
          ...base,
          overflow: "hidden",
          minWidth: "100%",
          width: "fit-content",
        }),
        control: () => ({}),
        input: () => ({
          ...(isDarkModeEnabled && { color: "#fff" }),
        }),
        valueContainer: () => ({}),
        indicatorsContainer: (base: any) => ({ ...base, padding: undefined }),
        dropdownIndicator: (base: any) => ({ ...base, padding: undefined }),
        clearIndicator: (base: any) => ({ ...base, padding: undefined }),
        loadingIndicator: (base: any) => ({ ...base, padding: undefined }),
        multiValue: () => ({}),
        multiValueLabel: () => ({}),
        multiValueRemove: () => ({}),
        ...(props.styles || {}),
      }}
      components={{
        Option,
        Control,
        SelectContainer,
        ValueContainer,
        SingleValue,
        Placeholder,
        Menu,
        DropdownIndicator,
        IndicatorSeparator: () => null,
        ClearIndicator,
        MultiValue,
        MultiValueContainer,
        MultiValueRemove,
        Input: Input as ComponentType<InputProps>,
        ...(props.components || {}),
      }}
    />
  );
}

const useAutoSelectSingleOption = (
  options: any,
  onChange: (val: any) => void,
  enableAutoSelect: boolean,
  isMulti?: boolean,
) => {
  const latestValue = useRef<any>();

  useEffect(() => {
    if (!enableAutoSelect) return;

    if (options?.length === 1) {
      const value = options[0];
      if (value !== latestValue.current) {
        latestValue.current = value;
        onChange(isMulti ? [value] : value);
      }
    }
  }, [options, onChange, enableAutoSelect, isMulti]);
};
