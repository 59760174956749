import useAuthorized, { Permission } from "@/hooks/useAuthorized";

import { FeatureGuard } from "./FeatureGuard";

export function AuthFeatureGuard(props: {
  children: JSX.Element | JSX.Element[];
  permissions?: Permission[];
  notAuthorizedComponent?: JSX.Element;
}) {
  const authorized = useAuthorized(props.permissions);
  return (
    <FeatureGuard test={authorized} fallback={props.notAuthorizedComponent}>
      {props.children}
    </FeatureGuard>
  );
}
