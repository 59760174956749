import useSize from "@/hooks/useSize";
import React from "react";
import {
  ReflexContainer,
  ReflexContainerProps,
  ReflexElement,
  ReflexElementProps,
  ReflexSplitter,
} from "react-reflex";

import {
  ReflexAccordionItemProps,
  isReflexAccordionItem,
} from "./ReflexAccordionItem";
import {
  ReflexAccordionContext,
  useReflexAccordion,
} from "./useReflexAccordion";
import { getAllOpenAfter, getAllOpenBefore } from "./utils";

export type ReflexAccordionProps = ReflexContainerProps & {
  collapsedPaneSize: number;
  minPaneSize: number;
};

export function ReflexAccordion(
  props: React.PropsWithChildren<ReflexAccordionProps>,
) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const containerSize = useSize(containerRef);

  const context = useReflexAccordion(props, containerSize?.height);
  const minContainerHeight = props.minPaneSize * context.panes.length;

  return (
    <ReflexAccordionContext.Provider value={context}>
      <div
        className="h-full w-full"
        style={{ minHeight: minContainerHeight }}
        ref={containerRef}
      >
        <ReflexContainer orientation="horizontal" windowResizeAware {...props}>
          {React.Children.toArray(props.children)
            .reduce<React.ReactNode[]>((elements, child, index) => {
              const pane = context.panes[index];
              if (pane != null && isReflexAccordionItem(child)) {
                const isFirstOpenPane =
                  !pane.isCollapsed &&
                  getAllOpenBefore(index, context.panes).length === 0;
                const isInLeadingCollapseStack =
                  pane.isCollapsed &&
                  getAllOpenBefore(index, context.panes).length === 0;
                const isInTrailingCollapseStack =
                  pane.isCollapsed &&
                  getAllOpenAfter(index, context.panes).length === 0;

                const direction: ReflexElementProps["direction"] =
                  index === 0 ? 1 : [1, -1];

                if (index !== 0) {
                  elements.push(
                    <ReflexSplitter
                      key={`splitter-${index}`}
                      propagate
                      style={{ height: 0, border: 0 }}
                    />,
                  );
                }
                elements.push(
                  React.cloneElement<ReflexAccordionItemProps>(child, {
                    key: pane.name,
                    name: pane.name,
                    direction,
                    minSize: pane.isCollapsed
                      ? props.collapsedPaneSize
                      : props.minPaneSize,
                    maxSize: pane.isCollapsed
                      ? props.collapsedPaneSize
                      : Number.MAX_VALUE,
                    flex: pane.flex,
                    hasResizeHandle:
                      !isInLeadingCollapseStack &&
                      !isFirstOpenPane &&
                      !isInTrailingCollapseStack,
                    onStartResize: context.onStartResizePane,
                    onStopResize: context.onStopResizePane,
                    onResize: context.onResizePane,
                  }),
                );
              }
              return elements;
            }, [])
            // When all panes are collapsed let a hidden ReflexElement expand on the unused area
            // Otherwise the ReflexContainer will contract to the height of the collapsed elements.
            .concat([
              <ReflexSplitter
                key="remainder-splitter"
                propagate
                style={{ height: 0, border: 0 }}
              />,
              <ReflexElement
                direction={-1}
                minSize={0}
                maxSize={context.remainderFlex > 0 ? Number.MAX_VALUE : 0}
                flex={context.remainderFlex}
                key="remainder-element"
                style={{ display: "none" }}
              />,
            ])}
        </ReflexContainer>
      </div>
    </ReflexAccordionContext.Provider>
  );
}
