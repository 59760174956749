import { CronDisplayReadable } from "@/components/elements/CronDisplayReadable";
import { CronInput, TimeInput } from "@/components/elements/SyncScheduler";
import { ComponentProps } from "react";

import { SyncScheduleListbox } from "./components/SyncScheduleListboxOption";
import { SyncSchedulerMenu } from "./components/SyncSchedulerMenu";
import { WeldSyncScheduler } from "./WeldSyncScheduler";

type AdvancedSyncSchedulerProps = Pick<
  ComponentProps<typeof WeldSyncScheduler>,
  "cron" | "onChange"
> & {
  startNowDescription?: string;
};

export function AdvancedSyncScheduler(props: AdvancedSyncSchedulerProps) {
  return (
    <WeldSyncScheduler {...props}>
      {({ value, mode }) => (
        <div className="space-y-4">
          <div className="relative flex flex-col gap-4">
            <CronInput />
            <SyncScheduleListbox />
            <TimeInput />
          </div>
          <div className="text-sm">
            {props.startNowDescription && (
              <div className="mb-1 font-medium">
                {props.startNowDescription}
              </div>
            )}
            <div className="flex space-x-2">
              {mode === "custom" && !value && (
                <div>Write a cron expression to see update frequency here</div>
              )}
              {value && (
                <div>
                  <CronDisplayReadable verbose cron={value.value} />
                </div>
              )}
              <SyncSchedulerMenu />
            </div>
          </div>
        </div>
      )}
    </WeldSyncScheduler>
  );
}
