import { CurrentModelContext } from "@/features/ai-assistant";
import { createContext, useCallback, useContext, useRef } from "react";
import { v4 as uuid } from "uuid";

const AiAssistantSidebarContext = createContext<{
  setInitOptions: (options: InitChatOptions) => string;
  readInitOptionsOnce: (token: string) => InitChatOptions | undefined;
} | null>(null);

export type InitChatOptions = {
  threadId?: string;
  context?: CurrentModelContext;
  input?: string;
};

export function AiAssistantSidebarProvider(props: {
  children: React.ReactNode;
}) {
  const initOptionsDictRef = useRef<{ [key: string]: InitChatOptions }>({});

  const setInitOptions = useCallback((options: InitChatOptions) => {
    const token = uuid();
    initOptionsDictRef.current[token] = options;
    return token;
  }, []);

  const readInitOptionsOnce = useCallback(
    (token: string): InitChatOptions | undefined => {
      const options = initOptionsDictRef.current[token];
      const result = { ...options };
      delete initOptionsDictRef.current[token];
      return result;
    },
    [],
  );

  return (
    <AiAssistantSidebarContext.Provider
      value={{ setInitOptions, readInitOptionsOnce }}
    >
      {props.children}
    </AiAssistantSidebarContext.Provider>
  );
}

export function useAiAssistantSidebarContext() {
  const context = useContext(AiAssistantSidebarContext);
  if (!context) {
    throw new Error(
      "useAiAssistantSidebarContext must be used within an AiAssistantSidebarProvider",
    );
  }
  return context;
}
