import { ActionContextProvider } from "@/components/modules/TaskActionBar";
import { PropsWithChildren } from "react";

export default function Container({ children }: PropsWithChildren<{}>) {
  return (
    <ActionContextProvider>
      <div className="absolute inset-0 flex overflow-hidden bg-white dark:bg-gray-800">
        {children}
      </div>
    </ActionContextProvider>
  );
}
