import { useFirebaseDatabase } from "@/features/firebase";
import { ref } from "firebase/database";
import { useCurrentAccount } from "@/providers/account";
import { useMemo } from "react";

export const useDashboardDoc = (dashboardId: string) => {
  const database = useFirebaseDatabase();

  const account = useCurrentAccount();
  const doc = useMemo(() => {
    if (!account.id) return null;
    return ref(database, `workspaces/${account.id}/dashboards/${dashboardId}`);
  }, [account.id, database, dashboardId]);
  return doc;
};

export const useTileDoc = (
  tileId: string,
  dashboardId?: string,
  modelId?: string,
) => {
  const database = useFirebaseDatabase();

  const account = useCurrentAccount();

  const tilesDoc = useMemo(() => {
    if (!account.id) return null;
    if (dashboardId)
      return ref(
        database,
        `workspaces/${account.id}/dashboards/${dashboardId}/tiles/${tileId}`,
      );

    if (modelId)
      return ref(
        database,
        `workspaces/${account.id}/models/${modelId}/tiles/${tileId}`,
      );

    return null;
  }, [account.id, dashboardId, database, modelId, tileId]);

  return tilesDoc;
};
