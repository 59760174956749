import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import classNames from "@/helpers/classNames";
import { PropsWithChildren, useMemo } from "react";

export default function Pagination({
  canNextPage,
  canPreviousPage,
  gotoPage,
  pageCount,
  pageIndex,
}: {
  canNextPage: boolean;
  canPreviousPage: boolean;
  gotoPage: (n: number) => void;
  pageCount: number;
  pageIndex: number;
}) {
  const pages = useMemo(() => {
    const pages = [...Array(pageCount).keys()].map((pageIndex) => ({
      value: pageIndex,
      display: `${pageIndex + 1}`,
    }));

    if (pageCount <= 5) {
      return pages;
    }

    // First page.
    if (pageIndex === 0)
      return [
        ...pages.slice(0, 3),
        { value: null, display: "..." },
        ...pages.slice(pages.length - 1),
      ];

    // Last two pages.
    if (pageIndex > pageCount - 3)
      return [
        ...pages.slice(0, 1),
        { value: null, display: "..." },
        ...pages.slice(pages.length - 3),
      ];

    return [
      ...pages.slice(pageIndex - 1, pageIndex + 2),
      { value: null, display: "..." },
      ...pages.slice(pages.length - 1),
    ];
  }, [pageCount, pageIndex]);

  return (
    <div className="mt-2 flex justify-end">
      <div className="flex divide-x border bg-white dark:divide-gray-700 dark:border-gray-700 dark:bg-gray-800">
        <PaginationButton
          disabled={!canPreviousPage}
          onClick={() => gotoPage(pageIndex - 1)}
        >
          <ChevronLeftIcon />
        </PaginationButton>
        {pages.map((page) => (
          <PaginationButton
            key={page.display}
            active={page.value === pageIndex}
            onClick={() => {
              if (typeof page.value !== "number") {
                return;
              }

              gotoPage(page.value);
            }}
            disabled={typeof page.value !== "number"}
          >
            {page.display}
          </PaginationButton>
        ))}
        <PaginationButton
          disabled={!canNextPage}
          onClick={() => gotoPage(pageIndex + 1)}
        >
          <ChevronRightIcon />
        </PaginationButton>
      </div>
    </div>
  );
}

function PaginationButton({
  active = false,
  disabled = false,
  children,
  onClick,
}: PropsWithChildren<{
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
}>) {
  return (
    <button
      className={classNames(
        active
          ? "bg-gray-100 dark:bg-gray-700 dark:text-white"
          : "dark:text-gray-200",
        (disabled || active) && "pointer-events-none",
        "flex h-8 w-8 items-center justify-center p-2 text-gray-600",
        "select-none text-sm focus:outline-none",
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
