import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import { SecondaryButton } from "./Button";

export default function PaginationNav({
  nextPage,
  previousPage,
  pageCount,
  canNextPage,
  canPreviousPage,
  state: { pageIndex, pageSize },
}: any) {
  return (
    <nav
      className="flex shrink-0 justify-end border-t border-gray-200 bg-gray-100 p-1 dark:border-gray-600 dark:bg-gray-800 dark:text-white"
      aria-label="Pagination"
    >
      <div className="flex items-center gap-3">
        <div>
          <p className="text-sm text-gray-700 dark:text-gray-400">
            Page <span className="font-medium">{pageIndex + 1}</span> of{" "}
            <span className="font-medium">{pageCount}</span>
          </p>
        </div>
        <div className="flex justify-between gap-2">
          <SecondaryButton
            variant="ghost"
            size="sm"
            onClick={previousPage}
            disabled={!canPreviousPage}
            icon={<ChevronLeftIcon />}
            className="h-7 px-2 text-sm font-medium"
          >
            Previous
          </SecondaryButton>
          <SecondaryButton
            size="sm"
            variant="ghost"
            onClick={nextPage}
            disabled={!canNextPage}
            iconRight={<ChevronRightIcon />}
            className="h-7 px-2 text-sm font-medium"
          >
            Next
          </SecondaryButton>
        </div>
      </div>
    </nav>
  );
}
