import { useOrchestrationWorkflowRunQuery } from "@/apollo/types";
import { DateAndTimeShort } from "constants/timeStringDisplay";
import dayjs from "dayjs";

export function WorkflowRunBreadcrumb(props: { workflowRunId: string }) {
  const { workflowRunId } = props;
  const { data, loading } = useOrchestrationWorkflowRunQuery({
    variables: { runId: workflowRunId },
  });

  if (loading) return null;
  if (data == null) return null;

  const timestamp = data.orchestrationWorkflowRun.startedAt;
  if (!timestamp) return <span>Historic run</span>;
  else return <span>{dayjs(timestamp).format(DateAndTimeShort)}</span>;
}
