import { Transition } from "@headlessui/react";
import { UtilityButton } from "@/components/elements/Button";
import Container from "@/components/elements/layout/Container";
import Content from "@/components/elements/layout/Content";
import ContentContainer from "@/components/elements/layout/ContentContainer";
import FlexContainer from "@/components/elements/layout/FlexContainer";
import PageHeader from "@/components/elements/PageHeader";
import Breadcrumbs from "@/components/modules/Breadcrumbs";
import { TaskActionBar } from "@/components/modules/TaskActionBar";
import useMediaQuery from "@/hooks/useMediaQuery";
import { noop } from "lodash";
import React, { useState } from "react";

const DocumentationColumnLayoutContext = React.createContext<{
  isOpen: boolean;
  toggle: (val?: boolean) => void;
}>({
  isOpen: false,
  toggle: noop,
});

type DocumentationColumnLayoutProps = {
  children:
    | JSX.Element
    | ((
        ctx: React.ContextType<typeof DocumentationColumnLayoutContext>,
      ) => JSX.Element);
  documentation?: React.ReactNode;
};

function DocumentationColumnLayout(props: DocumentationColumnLayoutProps) {
  const shouldShowDocumentation = useMediaQuery(`(min-width: 1024px)`);

  const [isOpen, setIsOpen] = useState(shouldShowDocumentation);

  React.useEffect(() => {
    setIsOpen(shouldShowDocumentation);
  }, [shouldShowDocumentation]);

  const ctx = React.useMemo(
    () => ({
      isOpen: isOpen,
      toggle: (val?: boolean) => {
        if (val === undefined) {
          setIsOpen((x) => !x);
        } else {
          setIsOpen(val);
        }
      },
    }),
    [isOpen],
  );
  return (
    <DocumentationColumnLayoutContext.Provider value={ctx}>
      <Container>
        <ContentContainer>
          <FlexContainer>
            <ContentContainer>
              <Content narrow>
                <PageHeader.Container>
                  <Breadcrumbs />
                </PageHeader.Container>
                {typeof props.children === "function"
                  ? props.children(ctx)
                  : props.children}
              </Content>
              <TaskActionBar mode="narrow" />
            </ContentContainer>
            {props.documentation && (
              <CollapsableSideColumn>
                {props.documentation}
              </CollapsableSideColumn>
            )}
          </FlexContainer>
        </ContentContainer>
      </Container>
    </DocumentationColumnLayoutContext.Provider>
  );
}

export default DocumentationColumnLayout;

export function CollapsableSideColumn({
  children,
}: React.PropsWithChildren<{}>) {
  const { isOpen, toggle } = React.useContext(DocumentationColumnLayoutContext);
  return (
    <>
      <Transition
        show={isOpen}
        appear
        as="aside"
        className="fixed right-0 z-10 h-full w-screen overflow-y-auto overflow-x-hidden border-l bg-white dark:border-gray-700 dark:bg-gray-800 dark:text-white md:relative"
        enter="duration-300"
        entered="max-w-lg"
        enterFrom="max-w-0"
        enterTo="max-w-lg"
        leave="duration-300"
        leaveFrom="max-w-lg"
        leaveTo="max-w-0"
      >
        <div className={`w-screen max-w-lg px-10 py-8 2xl:px-14`}>
          <UtilityButton onClick={() => toggle()}>Hide guide</UtilityButton>
          {children}
        </div>
      </Transition>
    </>
  );
}
