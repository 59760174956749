import { Account } from "@/apollo/types";
import { noop } from "lodash";
import { createContext, useContext } from "react";

type AccountInfo = Pick<Account, "id" | "slug" | "name">;

type CreateWorkspaceContextType = {
  onAccountCreated: (account: AccountInfo) => void;
};

export const CreateWorkspaceContext = createContext<CreateWorkspaceContextType>(
  {
    onAccountCreated: noop,
  },
);

export const useCreateWorkspaceContext = () =>
  useContext(CreateWorkspaceContext);
