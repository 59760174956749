import {
  CheckIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/outline";
import Tooltip from "@/components/elements/Tooltip";
import {
  Input,
  InputAddonRight,
  InputGroup,
} from "@/components/primitives/input";
import { InputDescription } from "@/components/primitives/InputDescription";
import FieldLabel from "@/components/primitives/InputLabel";
import { FormType } from "@/integrations";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

export default function CopyTextInput({
  field,
}: {
  field: Extract<FormType, { type: "copy" }>;
}) {
  const { name: id, value, required, label, description } = field;

  const { trigger, unregister, register } = useFormContext();

  useEffect(() => {
    trigger(id);
  }, [id, trigger]);

  useEffect(() => {
    return () => unregister(id);
  }, [id, unregister]);

  return (
    <div className="pb-4">
      <FieldLabel id={id} required={required}>
        {label}
      </FieldLabel>
      <InputGroup>
        <Input
          readOnly
          id={id}
          {...register(id, {
            required,
          })}
          defaultValue={value}
        />
        <InputAddonRight>
          <CopyButton copyText={value} />
        </InputAddonRight>
      </InputGroup>
      {!!description && (
        <InputDescription className="m-1">
          <>{description}</>
        </InputDescription>
      )}
    </div>
  );
}

const CopyButton = ({ copyText }: { copyText?: string }) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (!copied) return;
    const t = setTimeout(() => setCopied(false), 3000);
    return () => clearTimeout(t);
  }, [copied]);
  return (
    <Tooltip content={copied ? "Copied" : "Copy to clipboard"}>
      <button
        type="button"
        className="flex shrink-0 items-center justify-center text-gray-700 dark:text-gray-300"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!navigator.clipboard) return;
          try {
            navigator.clipboard.writeText(copyText || "");
            setCopied(true);
          } catch {
            // nevermind
          }
        }}
      >
        <span className="w-5">
          {copied ? <CheckIcon /> : <ClipboardDocumentCheckIcon />}
        </span>
      </button>
    </Tooltip>
  );
};
