import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { DwMigrationStatus } from "@/apollo/types";
import { Overlay, OverlayBackdrop } from "@/components/elements/layout/Overlay";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { Heading } from "@/components/elements/Typography";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useDataWarehouseMigrationContext } from "./DataWarehouseMigrationProvider";

const statusMessage: Record<DwMigrationStatus, string> = {
  [DwMigrationStatus.Started]: "Migration started",
  [DwMigrationStatus.SyncingData]: "Syncing data",
  [DwMigrationStatus.SyncingModels]: "Syncing models",
  [DwMigrationStatus.Failed]: "Migration failed",
  [DwMigrationStatus.Completed]: "Migration complete",
};

export const ActiveMigrationOverlay = () => {
  const { migration, migrationIsActive } = useDataWarehouseMigrationContext();
  const { dataWarehouseMigrationEnabled } = useFlags();

  if (!dataWarehouseMigrationEnabled) return null;
  if (!migrationIsActive) {
    return null;
  }
  return (
    <Overlay className="absolute">
      <OverlayBackdrop />
      <div className="relative flex h-full w-full items-center justify-center">
        <div className="m-4 max-w-lg rounded bg-white p-8 shadow dark:border dark:border-gray-700 dark:bg-gray-800">
          <OverlayContent status={migration.status} />
        </div>
      </div>
    </Overlay>
  );
};

function OverlayContent(props: { status: DwMigrationStatus }) {
  return (
    <div className="flex flex-col gap-4">
      <Heading>Data Warehouse migration in progress</Heading>
      <div className="flex items-center gap-4">
        {props.status === DwMigrationStatus.Failed ? (
          <XCircleIcon className="h-6 text-red-500" />
        ) : props.status === DwMigrationStatus.Completed ? (
          <CheckCircleIcon className="h-6 text-green-500" />
        ) : (
          <LoadingSpinner />
        )}
        <span>{statusMessage[props.status]}</span>
      </div>
      <div className="text-sm">
        Your data warehouse is currently being migrated. This may take a while.
      </div>
    </div>
  );
}
