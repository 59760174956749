import { useEffect, useRef, useState } from "react";

import cn from "@/helpers/classNames";

import { InitialMessages } from "../constants";
import { useScrollState } from "../hooks/useScrollState";
import { useAiAssistantModelContext } from "../providers/AiAssistantModelContextProvider";
import { useChatContext } from "../providers/ChatProvider";
import { ChatMessages } from "./ChatMessages";
import { DialogBox } from "./DialogBox";
import { AiChatPrompt } from "./prompt/AiChatPrompt";

export const AiChatAssistant = () => {
  const { threadId, messages, submitMessage, isStreamingResponse } =
    useChatContext();
  const { model } = useAiAssistantModelContext();

  // if (thread && model && model.id !== thread?.initialModelContext?.id) {
  //   console.log("Model context mismatch");
  // }

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { isScrollAtTop, isScrollAtBottom } =
    useScrollState(scrollContainerRef);
  const [isUserScrolledUp, setIsUserScrolledUp] = useState(false);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const handleSubmit = async (params: {
    message: string;
    tables: string[];
    includeModelAsContext: boolean;
  }) => {
    const modelContext =
      params.includeModelAsContext && model
        ? {
            id: model.id,
            name: model.name,
            type: model.type,
            sql: model.getTextValue(),
          }
        : undefined;
    return submitMessage({
      threadId,
      input: params.message,
      modelContext,
      tables: params.tables,
    });
  };

  const handleScroll = () => {
    if (!scrollContainerRef.current) return;
    const { scrollTop, clientHeight, scrollHeight } =
      scrollContainerRef.current;
    // Check if the user is scrolled to the bottom
    setIsUserScrolledUp(scrollTop + clientHeight < scrollHeight - 20);
  };

  useEffect(() => {
    // Scroll to bottom when a new message is added
    if (!isUserScrolledUp && isStreamingResponse) {
      messagesEndRef.current?.scrollIntoView();
    }
  }, [messages, isUserScrolledUp, isStreamingResponse]);

  return (
    <div className="relative flex h-full w-full flex-col overflow-hidden">
      {!isScrollAtTop && (
        <div className="absolute -top-4 left-0 z-[1] h-4 w-full shadow-md shadow-black/10 dark:shadow dark:shadow-black/80" />
      )}
      <div
        className="flex grow flex-col gap-8 overflow-auto px-6 pt-4"
        ref={scrollContainerRef}
        onScroll={handleScroll}
      >
        <>
          {InitialMessages.map((m, i) => (
            <DialogBox key={i} role={m.role}>
              {m.content}
            </DialogBox>
          ))}
        </>
        <ChatMessages
          messages={messages}
          isStreamingResponse={isStreamingResponse}
          chatThreadId={threadId}
        />
        <div ref={messagesEndRef} />
      </div>
      <div
        className={cn("w-full p-4", {
          "shadow-[0_-4px_6px_-1px_var(--tw-shadow-color),0_-2px_4px_-2px_var(--tw-shadow-color)] shadow-black/10 dark:shadow-[0_-1px_3px_0_var(--tw-shadow-color),_0_-1px_2px_-1px_var(--tw-shadow-color)] dark:shadow-black/80":
            !isScrollAtBottom,
        })}
      >
        <AiChatPrompt
          onSubmit={handleSubmit}
          advancedInputEnabled={messages.length === 0}
          loading={isStreamingResponse}
        />
      </div>
    </div>
  );
};
