import React, { createElement } from "react";

function LinkComp({ children, ...props }: React.ComponentProps<"a">) {
  return (
    <a className="text-blue-600 underline" {...props}>
      {children}
    </a>
  );
}

function H1({ children, ...props }: React.ComponentProps<"h1">) {
  return (
    <h1 className="py-4 text-2xl font-bold" {...props}>
      {children}
    </h1>
  );
}

function H2({ children, ...props }: React.ComponentProps<"h2">) {
  return (
    <h2 className="py-4 text-xl" {...props}>
      {children}
    </h2>
  );
}

function H3({ children, ...props }: React.ComponentProps<"h3">) {
  return (
    <h3 className="py-4 text-lg" {...props}>
      {children}
    </h3>
  );
}

function P(props: React.ComponentProps<"p">) {
  return <p className="my-2 text-sm" {...props} />;
}

function Code({ children, ...props }: React.ComponentProps<"code">) {
  return (
    <code {...props}>
      <p className="inline-block bg-gray-200 px-1 text-xs text-gray-800 dark:bg-gray-900 dark:text-white">
        {children}
      </p>
    </code>
  );
}
function Pre(props: React.ComponentProps<"pre">) {
  return (
    <div className="overflow-x-auto rounded border border-gray-300 bg-gray-200 p-4 dark:border-gray-600 dark:bg-gray-900 dark:text-white">
      <pre {...props} />
    </div>
  );
}

function BlockQuote(props: React.ComponentProps<"blockquote">) {
  return (
    <blockquote
      className="my-2 ml-2 border-l-4 border-gray-300 bg-gray-200 px-2 py-1 dark:border-gray-600 dark:bg-gray-700"
      {...props}
    />
  );
}

function Ul(props: React.ComponentProps<"ul">) {
  return <ul className="list-outside list-disc pl-6 text-sm" {...props} />;
}

function Ol(props: React.ComponentProps<"ol">) {
  return <ol className="list-outside list-decimal pl-6 text-sm" {...props} />;
}

function Li(props: React.ComponentProps<"li">) {
  return <li {...props} />;
}

function Img({ alt, ...props }: React.ComponentProps<"img">) {
  return <img alt={alt} {...props} />;
}

export const elements = {
  createElement: createElement,
  components: {
    a: LinkComp,
    h1: H1,
    h2: H2,
    h3: H3,
    p: P,
    code: Code,
    pre: Pre,
    blockquote: BlockQuote,
    ul: Ul,
    li: Li,
    ol: Ol,
    img: Img,
  },
};
