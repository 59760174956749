import { useGetCurrentUserPermissionsQuery } from "@/apollo/types";
import { useMemo } from "react";

export type Permission = "manage:all" | "read:User" | "update:User";

const useAuthorized = (permissions?: Permission[]) => {
  const { data: { current: userPermissions } = {} } =
    useGetCurrentUserPermissionsQuery();

  const isAuthorized = useMemo(
    () =>
      !permissions ||
      permissions.some((permission) =>
        userPermissions?.permissions?.includes(permission),
      ),
    [permissions, userPermissions?.permissions],
  );

  return isAuthorized;
};

export default useAuthorized;
