import useMediaQuery from "./useMediaQuery";

export const MOBILE_SIZE_VIEWPORT_THRESHOLD = 768;

export const useIsMobileSizeViewport = () => {
  const isMobileSizeViewport = useMediaQuery(
    `(max-width: ${MOBILE_SIZE_VIEWPORT_THRESHOLD}px)`,
  );
  return isMobileSizeViewport;
};
