import {
  ReflexAccordion,
  ReflexAccordionItem,
} from "@/components/elements/ReflexAccordion";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { DataSourcesPane } from "./data-sources/DataSourcesPane";
import { ExportsPane } from "./exports/ExportsPane";
import { DraftsPane } from "./models/DraftsPane";
import { PublishedPane } from "./models/PublishedModelsPane";

export default function ModelSidebarWithDnd() {
  return (
    <DndProvider backend={HTML5Backend}>
      <ModelSidebar />
    </DndProvider>
  );
}

function ModelSidebar() {
  return (
    <aside className="relative max-h-full min-h-full overflow-hidden bg-gray-50 dark:bg-gray-800 dark:text-gray-300">
      <div className="absolute inset-0 overflow-hidden">
        <ReflexAccordion
          minPaneSize={100}
          collapsedPaneSize={36}
          className="divide-y dark:divide-gray-700"
        >
          <ReflexAccordionItem>
            <PublishedPane />
          </ReflexAccordionItem>
          <ReflexAccordionItem>
            <DraftsPane />
          </ReflexAccordionItem>
          <ReflexAccordionItem>
            <DataSourcesPane />
          </ReflexAccordionItem>
          <ReflexAccordionItem initiallyCollapsed>
            <ExportsPane />
          </ReflexAccordionItem>
        </ReflexAccordion>
      </div>
    </aside>
  );
}
