import { useAuth0 } from "@auth0/auth0-react";
import { Link, useSearch } from "@tanstack/react-location";
import { Button } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { GreenOneEye1, RedOneEye1 } from "@/components/icons/graphic";
import { useMountEffect } from "@/hooks/useMountEffect";
import React from "react";

async function getErrorMessage(response: Response) {
  let errorMessage = response.statusText;
  try {
    errorMessage = (await response.json()).message;
  } catch {
    errorMessage = await response.text();
  }
  return errorMessage;
}

export function OAuthCallbackPage() {
  const { getAccessTokenSilently } = useAuth0();
  const params = useSearch<{
    Search: {
      url: string;
    };
  }>();

  const [fallbackState, setFallbackState] = React.useState<
    | {
        statusMessage: string;
        isResponseError: boolean;
        isNetworkError?: never;
      }
    | {
        statusMessage: string;
        isResponseError?: never;
        isNetworkError: boolean;
      }
    | null
  >(null);

  useMountEffect(async () => {
    try {
      if (!params.url) {
        return;
      }
      const response = await fetch(params.url, {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      });

      if (!response.ok) {
        const errorMessage = await getErrorMessage(response);
        if (window.opener) {
          window.opener.postMessage({
            type: "oauth_callback",
            error: errorMessage,
          });
        } else {
          setFallbackState({
            statusMessage: errorMessage,
            isResponseError: true,
          });
        }
      } else {
        const data = await response.json();
        if (typeof data.id === "string") {
          if (window.opener) {
            window.opener.postMessage({
              type: "oauth_callback",
              payload: data.id,
            });
            window.close();
          } else {
            setFallbackState({
              statusMessage: "Connection created, you can close this window",
              isResponseError: false,
            });
          }
        }
      }
    } catch (e) {
      setFallbackState({
        statusMessage: e instanceof Error ? e.message : "Unknown error",
        isNetworkError: true,
      });
    }
  });

  return (
    <div className="flex h-screen items-center justify-center">
      {fallbackState ? (
        <div className="flex max-w-sm flex-col items-center gap-4">
          {fallbackState.isResponseError || fallbackState.isNetworkError ? (
            <RedOneEye1 className="h-16 w-16" />
          ) : (
            <GreenOneEye1 className="h-16 w-16" />
          )}
          <span>{fallbackState.statusMessage}</span>
          {fallbackState.isNetworkError && (
            <span className="text-center text-sm">
              This issue might be caused by a browser extension such as an{" "}
              <span className="font-bold">adblocker</span>. Please pause the
              extension and try again.
            </span>
          )}
          <Button as={Link} to={"/"} variant="ghost" size={"sm"}>
            Back to account
          </Button>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
}
