import { FireMonacoEditor, IFireMonacoEditor } from "@otjs/firebase-monaco";
import { EditorClientEvent, TEditorClientError } from "@otjs/plaintext-editor";
import * as Sentry from "@sentry/react";
import { useFirebaseDatabase } from "@/features/firebase";
import { ref } from "firebase/database";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { useCurrentAccount } from "@/providers/account";
import { useCurrentUser } from "@/providers/UserProvider";
import React, { useState } from "react";

export function useFireMonacoReadMode() {
  const database = useFirebaseDatabase();
  const currentUser = useCurrentUser();
  const currentAccount = useCurrentAccount();
  const accountId = currentAccount.id;
  const userId = currentUser.id;
  const userEmail = currentUser.email;
  const [fireMonacoInstance, setFireMonacoInstance] =
    useState<IFireMonacoEditor | null>(null);

  const [isConnected, setIsConnected] = React.useState(false);

  const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(
    null,
  );

  const connect = React.useCallback(
    (editor: monaco.editor.IStandaloneCodeEditor, modelId: string) => {
      if (!accountId || !userEmail || !userId) {
        return;
      }
      const dbRef = ref(
        database,
        `workspaces-flattened/${accountId}/models/${modelId}`,
      );
      const fireMonaco = new FireMonacoEditor({
        announcementDuration: 1000,
        databaseRef: dbRef,
        editor: editor,
        userId: userId,
        userName: userEmail,
        userColor: "#ea5545",
      });
      editorRef.current = editor;
      setFireMonacoInstance(fireMonaco);
    },
    [database, accountId, userEmail, userId, setFireMonacoInstance],
  );

  React.useEffect(() => {
    if (fireMonacoInstance === null) {
      return;
    }
    if (fireMonacoInstance.disposed) {
      return;
    }
    const readyHandler = (isReady: boolean) => {
      if (!isReady) return;
      setIsConnected(true);
    };
    const errorHandler = ({ err, operation }: TEditorClientError) => {
      Sentry.captureException(err, {
        extra: {
          operation,
        },
      });
    };
    fireMonacoInstance.on(EditorClientEvent.Ready, readyHandler);
    fireMonacoInstance.on(EditorClientEvent.Error, errorHandler);

    return () => {
      setIsConnected(false);
      setFireMonacoInstance(null);
      fireMonacoInstance.off(EditorClientEvent.Ready, readyHandler);
      fireMonacoInstance.off(EditorClientEvent.Error, errorHandler);
      fireMonacoInstance.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fireMonacoInstance]);
  return {
    connect,
    isConnected,
  };
}
