import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import { ReactComponent as EdIcon } from "@/assets/ed.svg";
import { Button, ButtonGroup } from "@/components/elements/Button";
import { CloseButton } from "@/components/elements/CloseButton";

import { useAiAssistantModelContext } from "../../providers/AiAssistantModelContextProvider";
import { useChatContext } from "../../providers/ChatProvider";

const sqlRegex = /select\s+[\s\S]*?\s+from/i;

export function useDetectEmptyContext() {
  const { threadId } = useChatContext();
  const isNewChat = threadId == null;

  const previousModelId = useRef<string>();
  const { model } = useAiAssistantModelContext();

  const [isEmptyContext, setIsEmptyContext] = useState(false);

  useEffect(() => {
    if (model?.id !== previousModelId.current) {
      setIsEmptyContext(false);
      previousModelId.current = model?.id;
    }

    const timer = setTimeout(() => {
      const sql = model?.getTextValue();
      const isBlankModel = !model?.id || !sql || sqlRegex.test(sql) === false;

      if (isBlankModel && isNewChat) {
        setIsEmptyContext(true);
      } else {
        setIsEmptyContext(false);
      }
    }, 50);
    return () => clearTimeout(timer);
  }, [model, isNewChat]);

  return {
    isEmptyContext,
  };
}

export function EmptyContextAlert(props: {
  onLearnMore: () => void;
  onSelectDataSources: () => void;
  onDismiss: () => void;
}) {
  return (
    <div className="relative flex flex-col gap-2 rounded border border-blue-300 bg-blue-50 px-3 py-2 shadow-md dark:border-blue-600/50 dark:bg-blue-600/10">
      <div className="flex items-center gap-2">
        <EdIcon className="inline-block h-5 w-5" />
        <span>Hey, I need some data!</span>
        <CloseButton
          onClick={() => {
            props.onDismiss();
          }}
          className="absolute right-1 top-1"
          size="sm"
        />
      </div>
      <div className="text-sm">
        To assist with your SQL query, please provide details about your data.
        You can mention tables from Data Sources and Models in your message or
        select them below.​
      </div>
      <ButtonGroup
        size="sm"
        colorScheme="primary"
        variant="outline"
        className="flex justify-end gap-4"
      >
        <Button variant="solid" onClick={() => props.onSelectDataSources()}>
          Select tables
        </Button>
        <Button onClick={() => props.onLearnMore()}>Referencing guide</Button>
      </ButtonGroup>
    </div>
  );
}

export function SlideInOutTransition(props: {
  show: boolean;
  children: React.ReactNode;
}) {
  return (
    <AnimatePresence>
      {props.show && (
        <motion.div
          initial={{ x: "100%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{
            opacity: 0,
            transition: { duration: 0.1 },
          }}
          transition={{ duration: 0.2, ease: "backInOut" }}
        >
          {props.children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
