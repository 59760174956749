import { useConnections } from "@/features/connectors";
import Center from "components/elements/layout/Center";
import { Overlay } from "components/elements/layout/Overlay";
import LoadingSpinner from "components/elements/LoadingSpinner";
import {
  SlideOver,
  SlideOverBody,
  SlideOverCloseButton,
  SlideOverHeader,
} from "components/elements/SlideOver";
import {
  IntegrationType,
  useCreateConnection,
  useIntegrationsMap,
} from "integrations";
import ConnectionForm, {
  ConnectionFormSubmitButton,
  useConnectionForm,
} from "pages/Connections/ConnectionForm/ConnectionForm";
import { useToast } from "providers/ToastProvider";
import { FormProvider } from "react-hook-form";
import invariant from "tiny-invariant";

type DbtSetupSlideOverProps = {} & Pick<
  React.ComponentProps<typeof SlideOver>,
  "show" | "onClose"
>;
const dbtIntegrationId = "dbt-cloud";

export function DbtSetupSlideOver(props: DbtSetupSlideOverProps) {
  const integrations = useIntegrationsMap();
  const dbtIntegration = integrations.get(dbtIntegrationId);
  invariant(dbtIntegration, "dbt integration not found");

  return (
    <SlideOver show={props.show} onClose={props.onClose} bgOverlay>
      <SlideOverCloseButton />
      <SlideOverHeader>Setup dbt cloud</SlideOverHeader>
      <SlideOverBody className="p-0">
        <DbtSetupContainer
          integration={dbtIntegration}
          onComplete={() => props.onClose()}
        />
      </SlideOverBody>
    </SlideOver>
  );
}

function DbtSetupContainer(props: {
  integration: IntegrationType;
  onComplete: () => void;
}) {
  const toast = useToast();

  const {
    connections,
    loading: isLoadingConnections,
    refetch: refetchConnections,
  } = useConnections("UtilityConnection");

  const dbtConnections = connections.filter(
    (x) => x.integrationId === dbtIntegrationId,
  );
  const hasDbtConnection = dbtConnections.length > 0;

  const { loading, createConnection } = useCreateConnection({
    onSuccess() {
      refetchConnections();
      props.onComplete();
    },
    onError(error) {
      toast("Connection not created", error.message, "error");
    },
  });

  return (
    <div className="relative flex h-full flex-col px-6">
      {isLoadingConnections && (
        <Overlay className="absolute bg-white/80">
          <Center className="h-full w-full">
            <LoadingSpinner />
          </Center>
        </Overlay>
      )}
      {hasDbtConnection ? (
        <>dbt connection has been successfully set up</>
      ) : (
        <SetupDbtConnection
          onSubmit={(data) => {
            return createConnection(props.integration, data);
          }}
          integration={props.integration}
          isLoading={loading}
        />
      )}
    </div>
  );
}

function SetupDbtConnection(props: {
  integration: IntegrationType;
  isLoading: boolean;
  onSubmit: (data: any) => void;
}) {
  const formMethods = useConnectionForm(props.integration);
  return (
    <FormProvider {...formMethods}>
      <div className="grow">
        <div className="flex flex-col gap-8">
          <form
            id="integration-form"
            onSubmit={formMethods.handleSubmit(props.onSubmit)}
            className="flex h-full flex-col"
          >
            <ConnectionForm
              integration={props.integration}
              isLoading={props.isLoading}
            />
          </form>
        </div>
      </div>
      <div className="flex shrink-0 grow-0 flex-row-reverse gap-4 py-4">
        <ConnectionFormSubmitButton
          integration={props.integration}
          isLoading={props.isLoading}
        />
      </div>
    </FormProvider>
  );
}
