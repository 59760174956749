import dayjs from "dayjs";
import classNames from "@/helpers/classNames";
import { TooltipProps } from "recharts";

export const TooltipContent = (props: TooltipProps<number, string>) => {
  const date = dayjs(props.label);

  return (
    <div
      className={classNames(
        "bg-white dark:bg-gray-800",
        "rounded-sm border dark:border-gray-700",
        "space-y-2 p-4",
        "shadow-lg",
        "focus-visible:outline-none",
      )}
      tabIndex={-1}
    >
      <div className="text-sm text-gray-700 dark:text-gray-100">
        {date.isValid() ? date.format("DD MMM YYYY") : props.label}
      </div>
      {props.payload?.map((item) => (
        <div
          key={item.dataKey}
          className="flex w-full flex-row items-center gap-3 text-sm text-gray-900 dark:text-white"
        >
          <div
            style={{ backgroundColor: item.color }}
            className="h-3 w-3 rounded-sm"
          ></div>
          <div className="flex-grow">{item.name}</div>
          <div className="font-medium">{item.value}</div>
        </div>
      ))}
    </div>
  );
};
