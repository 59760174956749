import { useAtom } from "jotai";
import { atomFamily, atomWithStorage } from "jotai/utils";
import {
  useModelEditorState,
  useModelEditorTextState,
} from "@/pages/ModelTool/ModelEditorStore";
import { createModelToolStorageKey } from "@/pages/ModelTool/utils/createLocalStorageKey";
import { useCurrentAccount } from "@/providers/account";
import { useEffect, useRef } from "react";
import { useDebouncedCallback } from "use-debounce";

type UnixTimestamp = number;
type ModelId = string;

const recentModelsAtom = atomFamily((accountId: string) =>
  atomWithStorage<{ [key: ModelId]: UnixTimestamp }>(
    createModelToolStorageKey("recently-opened-models", accountId),
    {},
  ),
);

const useRecentModelsAtom = (accountId: string) =>
  useAtom(recentModelsAtom(accountId));

/**
 * FIXME: this hook updates local storage on every change to the model editor text state, i.e. every keystroke.
 * Additionally, it has no limit on the number of models it tracks, so it will grow indefinitely.
 *
 * Find a better way to track changes, and implement a limit on the number of models tracked, i.e. 5 or 10 most recent models.
 */
const useTrackRecentModels = () => {
  const modelState = useModelEditorState();
  const modelTextState = useModelEditorTextState();

  const currentAccountId = useCurrentAccount().id;
  const [, setRecentModels] = useRecentModelsAtom(currentAccountId);

  const prevVal = useRef<string | undefined>(undefined);
  const prevModelId = useRef<string | undefined>(undefined);

  const trackModel = useDebouncedCallback(
    (modelId: string, timestamp: number) => {
      setRecentModels((recentModels) => {
        return {
          ...recentModels,
          [modelId]: timestamp,
        };
      });
    },
    500,
  );

  useEffect(() => {
    if (
      !modelState.currentModelId ||
      !currentAccountId ||
      !modelTextState.weldSql
    )
      return;

    //Only track if the value has changed and the model has not changed
    //This prevents tracking the change when model is mounted
    if (
      prevModelId.current === modelState.currentModelId &&
      prevVal.current !== modelTextState.weldSql
    ) {
      trackModel(modelState.currentModelId, Date.now());
    }

    prevModelId.current = modelState.currentModelId;
    prevVal.current = modelTextState.weldSql;
  }, [
    modelTextState,
    modelState.currentModelId,
    currentAccountId,
    setRecentModels,
    trackModel,
  ]);
};

// This is just a wrapper component to call the hook
// It was previously in the <SQLEditor /> component but since it will update
// on every keystroke it has been implemented in separate leaf component.
export function TrackRecentModels_Detached() {
  useTrackRecentModels();
  return null;
}

export const useRecentModels = () => {
  const currentAccountId = useCurrentAccount().id;
  const [recentModels] = useRecentModelsAtom(currentAccountId);

  const recentModelsArray = Object.entries(recentModels).map(
    ([modelId, timestamp]) => ({
      modelId,
      timestamp,
    }),
  );
  return recentModelsArray.sort((a, b) => b.timestamp - a.timestamp);
};
