import { merge } from "lodash";
import { LabelProps } from "recharts";

import {
  FirstLayerPartial,
  baseThemeDark,
  baseThemeLight,
  mergeThemes,
} from "./baseTheme";
import { ChartThemeDef } from ".";

const chartColorScheme = [
  "#2B5193", //blue
  "#73AC97", //green
  "#D39688", //red
  "#F4DD88", //yellow
  "#6B2B93", //purple
  "#B283D3", //light purple
  "#93B22B", //olive green
  "#DD882B", //orange
  "#882BDD", //violet
  "#2B8893", //teal
];

export const getChartColor = (index: number) =>
  chartColorScheme[index % chartColorScheme.length];

const visualizeBase: FirstLayerPartial<ChartThemeDef> = {
  Bar: {
    fill: "#2B5193", // default fill
    radius: 4,
  },
  BarChart: {
    barCategoryGap: "15%",
    maxBarSize: 80,
    margin: {
      bottom: 10,
      left: 40,
      right: 40,
      top: 50,
    },
  },
  LineChart: {
    margin: {
      bottom: 10,
      left: 40,
      right: 40,
      top: 50,
    },
  },
  Tooltip: {
    offset: 10,
    animationDuration: 100,
    cursor: { fill: "rgba(179, 153, 254, 0.2)" },
  },
  CartesianGrid: {
    vertical: false,
    strokeDasharray: 0,
  },
  YAxis: {
    style: {
      fontSize: "12",
      fontWeight: 500,
    },
    width: 30,
    tickMargin: 10,
    axisLine: false,
  },
  XAxis: {
    axisLine: false,
    tickMargin: 5,
    style: {
      fontSize: "11",
      fontWeight: 500,
      letterSpacing: 0.3,
    },
  },
};

const themeLight = merge({}, visualizeBase, {
  Line: {
    fill: "rgba(50 140 246 / 0.8)", // blue-400
    getLabelProps: (props: LabelProps) => ({
      fill: "rgb(109 113 122)", // gray-500
      fontSize: "0.8em",
      position: "right",
      ...props,
    }),
  },
  CartesianGrid: {
    stroke: "#f1f2f3",
  },
  XAxis: {
    stroke: "#AAA",
  },
  text: "rgb(38 38 39)", // gray-800
});

const themeDark = merge({}, visualizeBase, {
  Bar: {
    fill: "#66A9F8", //
  },

  Line: {
    fill: "rgba(59 130 246 / 0.6)", // blue-500
    getLabelProps: (props: LabelProps) => ({
      fill: "rgb(159 163 171)", // gray-400
      fontSize: "0.8em",
      position: "right",
      ...props,
    }),
  },
  XAxis: {
    stroke: "#AAA",
  },
  CartesianGrid: {
    stroke: "#4a4a4a",
  },
});

const theme = {
  light: mergeThemes(baseThemeLight, themeLight),
  dark: mergeThemes(baseThemeDark, themeDark),
};

export default theme;
