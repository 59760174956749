import { useMountEffect } from "@/hooks/useMountEffect";
import { noop } from "lodash";
import React from "react";
import { ReflexElement, ReflexElementProps, ReflexHandle } from "react-reflex";

import { useReflexAccordionContext } from "./useReflexAccordion";

export function isReflexAccordionItem(
  element: React.ReactNode,
): element is React.ReactElement<ReflexAccordionItemProps> {
  if (React.isValidElement<ReflexAccordionItemProps>(element)) {
    if (process.env.NODE_ENV === "development") {
      return (
        element.type === React.createElement(ReflexAccordionItem, null).type
      );
    }
    return element.type === ReflexAccordionItem;
  }
  return false;
}

function useReflexAccordionItem(props: ReflexAccordionItemProps) {
  const { panes, togglePane } = useReflexAccordionContext();
  const { name } = props;

  const pane = React.useMemo(
    () => panes.find((x) => x.name === name),
    [panes, name],
  );

  return React.useMemo(() => {
    return {
      isOpen: !pane?.isCollapsed,
      toggle: (shouldOpenPane?: boolean) => {
        if (name == null) return;
        togglePane(name, shouldOpenPane);
      },
    };
  }, [pane, name, togglePane]);
}

const ReflexAccordionItemContext = React.createContext<
  ReturnType<typeof useReflexAccordionItem>
>({
  isOpen: true,
  toggle: noop,
});

export const useReflexAccordionItemContext = () =>
  React.useContext(ReflexAccordionItemContext);

export type ReflexAccordionItemProps = ReflexElementProps & {
  toggle?: (name: string, minSize?: number) => void;
  isCollapsed?: boolean;
  hasResizeHandle?: boolean;
  initiallyCollapsed?: boolean;
};

export const ReflexAccordionItem = React.forwardRef<
  HTMLDivElement,
  ReflexAccordionItemProps
>((props, ref) => {
  const {
    children,
    hasResizeHandle,
    name,
    initiallyCollapsed,
    ...reflexElementProps
  } = props;
  const context = useReflexAccordionItem(props);

  useMountEffect(() => {
    if (initiallyCollapsed) {
      context.toggle(false);
    }
  });

  return (
    <ReflexAccordionItemContext.Provider value={context}>
      <ReflexElement
        name={name}
        style={{ overflow: "hidden" }}
        {...reflexElementProps}
        {...{ innerRef: ref }}
      >
        <div className="flex h-full flex-col">{children}</div>
        {hasResizeHandle && (
          <ReflexHandle
            style={{ cursor: "row-resize" }}
            className="absolute left-0 top-0 h-1.5 w-full border-t border-transparent hover:border-gray-400 dark:hover:border-gray-500"
          />
        )}
      </ReflexElement>
    </ReflexAccordionItemContext.Provider>
  );
});
