import classNames from "@/helpers/classNames";
import { ComponentProps } from "react";

export function Content(props: ComponentProps<"div">) {
  return (
    <main
      {...props}
      className={classNames("grow overflow-auto px-6 py-2", props.className)}
    />
  );
}

export function Footer(props: ComponentProps<"div">) {
  return (
    <footer
      {...props}
      className={classNames(
        "flex shrink-0 grow-0 gap-4 px-6 py-4",
        props.className,
      )}
    />
  );
}

export function Heading({ children, ...props }: ComponentProps<"h3">) {
  return (
    <h3
      {...props}
      className={classNames("mb-4 text-sm font-medium", props.className)}
    >
      {children}
    </h3>
  );
}

export function SubHeading({ children, ...props }: ComponentProps<"h3">) {
  return (
    <h3
      {...props}
      className={classNames(
        "text-xs text-gray-700 dark:text-gray-500",
        props.className,
      )}
    >
      {children}
    </h3>
  );
}
