import { TextMuted } from "@/components/elements/Typography";
import classNames from "@/helpers/classNames";
import React from "react";

export const InputDescription = ({
  className,
  ...props
}: React.ComponentProps<"p">) => (
  <TextMuted as="p" className={classNames("text-xs", className)} {...props} />
);
