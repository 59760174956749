import { useAuth0 } from "@auth0/auth0-react";
import { useCurrentAccount } from "@/providers/account";
import { useCallback } from "react";

import { BACKEND_URL } from "../constants";

export default function useWeldAPI() {
  const { getAccessTokenSilently } = useAuth0();
  const account = useCurrentAccount();

  return useCallback(
    async (path: string, init: RequestInit = {}) => {
      const url = `${BACKEND_URL}/${path.replace(/^\//, "")}`;
      return fetch(url, {
        ...init,
        headers: {
          ...(init.headers ?? {}),
          "x-account-id": account.id,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      });
    },
    [getAccessTokenSilently, account.id],
  );
}
