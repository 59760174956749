import { OperationMode, SyncJobStatus } from "@/apollo/types";

import { formatSyncStatus, getSyncJobColorProps } from "./utils";

export function ColorLegend() {
  return (
    <div className="flex gap-8 text-sm">
      <div className="flex items-center gap-2">
        <div
          className="h-2.5 w-4 rounded-sm"
          style={{
            backgroundColor: getSyncJobColorProps({
              status: SyncJobStatus.Completed,
            }).fill,
          }}
        />
        <div>{formatSyncStatus(SyncJobStatus.Completed)}</div>
      </div>
      <div className="flex items-center gap-2">
        <div
          className="h-2.5 w-4 rounded-sm"
          style={{
            backgroundColor: getSyncJobColorProps({
              status: SyncJobStatus.Completed,
              operationMode: OperationMode.Full,
            }).fill,
          }}
        />
        <div>{formatSyncStatus(SyncJobStatus.Completed)} (Full sync)</div>
      </div>
      <div className="flex items-center gap-2">
        <div
          className="h-2.5 w-4 rounded-sm"
          style={{
            backgroundColor: getSyncJobColorProps({
              status: SyncJobStatus.Running,
            }).fill,
          }}
        />
        <div>{formatSyncStatus(SyncJobStatus.Running)}</div>
      </div>
      <div className="flex items-center gap-2">
        <div
          className="h-2.5 w-4 rounded-sm"
          style={{
            backgroundColor: getSyncJobColorProps({
              status: SyncJobStatus.Failed,
            }).fill,
          }}
        />
        <div>{formatSyncStatus(SyncJobStatus.Failed)}</div>
      </div>
    </div>
  );
}
