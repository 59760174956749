import { Menu } from "@headlessui/react";
import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import { Placement } from "@popperjs/core";
import { IconButton } from "@/components/elements/Button";
import React, { useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

export const TableOptionsMenu = (props: {
  placement?: Placement;
  children: React.ReactNode;
}) => {
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "absolute",
    placement: props.placement ?? "auto-start",
    modifiers: [
      { name: "preventOverflow", enabled: false },
      { name: "offset", options: { offset: [5, 10] } },
    ],
  });

  return (
    <Menu>
      <Menu.Button
        as={IconButton}
        size="xs"
        variant="ghost"
        icon={<Cog6ToothIcon className="h-4 w-4" />}
        ref={setReferenceElement}
      />
      {createPortal(
        <Menu.Items
          className="z-50 w-56 rounded-md border border-gray-200 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:border-gray-700 dark:bg-gray-800"
          style={styles.popper}
          {...attributes.popper}
          ref={setPopperElement}
        >
          {props.children}
        </Menu.Items>,
        document.body,
      )}
    </Menu>
  );
};
