import { useMemo } from "react";
import useConnectionSettings from "@/hooks/useConnectionSettings";

export const useGoogleSheetsConnectionSettings = (
  id: string,
  currentSettings?: any,
) => {
  const connectionSettings = useConnectionSettings(id, "Activate", {
    objectType: "sheet",
  });

  return useMemo(() => {
    return {
      ...connectionSettings,
      refetch: async (formValues?: Record<string, any>) => {
        if (
          currentSettings?.spreadsheet?.value === formValues?.spreadsheet?.value
        ) {
          // Only refetch if the spreadsheet has changed
          return;
        }
        return connectionSettings.refetch(formValues);
      },
      value: {
        ...connectionSettings.value,
        fields:
          connectionSettings.value.fields?.filter(
            (x: any) => !["templateChoice", "templateInfo"].includes(x.name),
          ) ?? [],
      },
    };
  }, [connectionSettings, currentSettings]);
};
