import { atom, useAtom } from "jotai";
import { useCallback } from "react";

const newItemAtom = atom<{
  itemType: "folder" | "model";
  folderId?: string;
} | null>(null);

export function useNewSidebarItemState() {
  const [item, setItem] = useAtom(newItemAtom);

  const createNewFolderItem = useCallback(
    (folderId?: string) => {
      setItem({
        itemType: "folder",
        folderId,
      });
    },
    [setItem],
  );

  const clearNewItem = useCallback(() => {
    setItem(null);
  }, [setItem]);

  return {
    item,
    createNewFolderItem,
    clearNewItem,
  };
}
