import { ComponentProps } from "react";

import { IntegrationId, getIntegrationLogo } from "./registry";

export function IntegrationLogo({
  id,
  ...props
}: Omit<ComponentProps<"img">, "id"> & { id: IntegrationId | (string & {}) }) {
  const { url } = getIntegrationLogo(id) ?? getIntegrationLogo("weld");
  return <img {...props} alt={`${id} logo`} src={url} />;
}
