import { useCallback, useMemo } from "react";

import { AvailableIntegrationFragment, DevStatus } from "@/apollo/types";
import {
  Ability,
  FeatureMode,
  IntegrationType,
  useIntegrationFlagHelper,
} from "@/integrations";

export type ConnectorAbility = Extract<
  Ability,
  "EltSourceConnection" | "ReverseEltDestinationConnection"
>;

export type ConnectorOption = {
  value: string;
  label: string;
  integration: IntegrationType;
  isBeta?: boolean;
  isAccessRequestable?: boolean;
  isPreview?: boolean;
  isCommunity?: boolean;
  // `isAlias` is used to indicate that this connector is an alias for another connector
  isAlias?: boolean;
  abilities: ConnectorAbility[];
  metadata?: AvailableIntegrationFragment;
};

export function createConnectorOption(
  integration: IntegrationType,
  flags: Record<"elt" | "reverseElt", FeatureMode>,
): ConnectorOption {
  const isBeta =
    flags.elt === "beta" ||
    flags.reverseElt === "beta" ||
    [
      integration.metadata?.eltSource,
      integration.metadata?.activateDestination,
    ].some((x) => x?.status === DevStatus.Beta);

  const isPreview = flags.elt === "preview" || flags.reverseElt === "preview";

  const enabledAbilities = integration.abilities.filter(
    (a): a is ConnectorAbility => {
      return (
        (a === "EltSourceConnection" && flags.elt !== "hide") ||
        (a === "ReverseEltDestinationConnection" && flags.reverseElt !== "hide")
      );
    },
  );
  return {
    value: integration.id,
    label: integration.name,
    isBeta,
    isPreview,
    abilities: enabledAbilities,
    integration,
    metadata: integration.metadata,
  };
}

export function useGetIntegrationFlags() {
  const integrationFlagHelper = useIntegrationFlagHelper();
  return useCallback(
    (integrationId: string) => {
      return {
        elt: integrationFlagHelper.checkFlag(
          integrationId,
          "EltSourceConnection",
        ),
        reverseElt: integrationFlagHelper.checkFlag(
          integrationId,
          "ReverseEltDestinationConnection",
        ),
      };
    },
    [integrationFlagHelper],
  );
}

export function useCreateConnectorOptions(
  integrations: IntegrationType[],
): ConnectorOption[] {
  const getIntegrationFlags = useGetIntegrationFlags();
  const options = useMemo(() => {
    return integrations.map((integration) => {
      const flags = getIntegrationFlags(integration.id);
      return createConnectorOption(integration, flags);
    });
  }, [integrations, getIntegrationFlags]);
  return options;
}
