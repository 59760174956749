import { ModelIcon } from "@/pages/ModelTool/components/ModelIcon/ModelIcon";
import { useModel } from "@/pages/ModelTool/hooks/useCurrentModel";
import { useFullModelPathRenderer } from "@/pages/ModelTool/hooks/useFullPathNames";

export function ModelBreadcrumb(props: { modelId: string }) {
  const { model } = useModel(props.modelId);
  const modelPath = useFullModelPathRenderer(model);
  if (model == null) return null;
  return (
    <div className="flex items-center gap-2">
      <ModelIcon model={model} className="h-5 w-5" />
      <span className="truncate">{modelPath}</span>
    </div>
  );
}
