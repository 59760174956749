import TabLayout from "components/layouts/TabLayout";
import { PropsWithChildren } from "react";

type Tabs = "orchestration" | "webhooks" | "history" | "settings";

type Props = {
  action?: JSX.Element | undefined;
  current: Tabs;
};

export const OrchestrationTabsLayout = (props: PropsWithChildren<Props>) => {
  const navigation = props.current === "orchestration" ? "./" : "../";
  return (
    <TabLayout
      tabs={[
        {
          href: `${navigation}`,
          id: "orchestration",
          name: "Current run",
          current: props.current === "orchestration",
        },
        {
          href: `${navigation}webhooks`,
          id: "webhooks",
          name: "Webhooks",
          current: props.current === "webhooks",
        },
        {
          href: `${navigation}history`,
          id: "history",
          name: "History",
          current: props.current === "history",
        },
        {
          href: `${navigation}settings`,
          id: "settings",
          name: "Settings",
          current: props.current === "settings",
        },
      ]}
      action={props.action ?? <></>}
    >
      <div className="relative">{props.children}</div>
    </TabLayout>
  );
};
