export const DeleteIcon = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      {...props}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="#FDBFB7" />
      <circle cx="25" cy="25" r="15" fill="#FC604D" />
      <path
        d="M20 30L30 20M20 20L30 30"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
