import { PlusIcon } from "@heroicons/react/24/outline";
import { Link } from "@tanstack/react-location";
import { Button } from "@/components/elements/Button";
import TabLayout from "@/components/layouts/TabLayout";
import { useSettingsTabs } from "@/pages/Settings/hooks/useSettingsTabs";

import UsersTable from "./UsersTable";

export default function UsersPage() {
  const tabs = useSettingsTabs();

  return (
    <TabLayout
      tabs={tabs}
      action={
        <Button
          as={Link}
          to="../invitations/new"
          tabIndex={-1}
          icon={<PlusIcon className="w-4" />}
          variant="solid"
          colorScheme="primary"
        >
          Invite user
        </Button>
      }
    >
      <UsersTable />
    </TabLayout>
  );
}
