import {
  EltSyncDocument,
  EltSyncsDocument,
  useEltSyncQuery,
  useStartEltSyncMutation,
  useStopEltSyncMutation,
} from "@/apollo/types";
import { useCallback } from "react";

export function useToggleEltSync(
  syncId: string,
  options: {
    onError?: (error: Error) => void;
    onCompleted?: (isRunning: boolean) => void;
  } = {},
): [
  () => Promise<void>,
  {
    isLoadingSync: boolean;
    isSyncRunning: boolean;
    isUpdating: boolean;
  },
] {
  const {
    loading: isLoadingSync,
    data,
    refetch,
  } = useEltSyncQuery({
    variables: { eltSyncId: syncId },
  });

  const isSyncRunning = data?.eltSync.active ?? false;
  const useMutation = isSyncRunning
    ? useStopEltSyncMutation
    : useStartEltSyncMutation;

  const [mutate, { loading }] = useMutation({
    onError: (error) => {
      options.onError?.(error);
      refetch();
    },
    async onCompleted(data) {
      const res = "startEltSync" in data ? data.startEltSync : data.stopEltSync;
      options.onCompleted?.(res.active);
    },
    refetchQueries: [
      { query: EltSyncsDocument },
      { query: EltSyncDocument, variables: { eltSyncId: syncId } },
    ],
  });

  return [
    useCallback(async () => {
      await mutate({ variables: { eltSyncId: syncId } });
    }, [mutate, syncId]),
    {
      isLoadingSync,
      isSyncRunning,
      isUpdating: loading,
    },
  ];
}
