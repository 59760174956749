import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import classNames from "@/helpers/classNames";
import { Fragment, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

export type SelectorItem<T = {}> = {
  id: string;
  label: string;
  description?: string;
  icon?: React.ReactNode;
  value: T;
};

export function ComboItemSelector(props: {
  options: SelectorItem[];
  onChange: (updated: SelectorItem) => void;
  selectedId?: string;
  placeholder?: string;
}) {
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "absolute",
    placement: "bottom-start",
    modifiers: [
      { name: "preventOverflow", enabled: false },
      { name: "offset", options: { offset: [0, 10] } },
    ],
  });

  const width = useMemo(
    () => referenceElement?.getBoundingClientRect().width || 0,
    [referenceElement],
  );

  const [searchTerm, setSearchTerm] = useState("");

  const filteredOptions = useMemo(() => {
    if (searchTerm === "") {
      return props.options;
    } else {
      return props.options.filter(
        (option) =>
          !!option.label.toLowerCase().match(searchTerm.toLowerCase()),
      );
    }
  }, [searchTerm, props.options]);

  const selected = useMemo(
    () => props.options.find((option) => option.id === props.selectedId),
    [props.selectedId, props.options],
  );

  return (
    <>
      <div className="relative w-full" ref={setReferenceElement}>
        <Combobox value={selected} onChange={props.onChange}>
          <Combobox.Button as="div">
            <Combobox.Input
              className={classNames(
                "flex w-full items-center space-x-2 rounded-sm border px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary dark:border-gray-600 dark:bg-gray-800 dark:text-white",
                //props.disabled && "opacity-50",
              )}
              onChange={(event) => setSearchTerm(event.target.value)}
              placeholder={props.placeholder}
              displayValue={(v: any) => v?.label}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </Combobox.Button>

          {createPortal(
            <Combobox.Options
              ref={setPopperElement}
              style={{ ...styles.popper, width, zIndex: 60 }}
              {...attributes.popper}
              className="absolute left-0 z-50 max-h-96 w-full origin-top-right overflow-auto rounded-sm border bg-white shadow-lg focus:outline-none dark:border-gray-600 dark:bg-gray-700"
              //className="max-h-96 relative overflow-hidden overflow-y-auto rounded-sm bg-white py-1 text-base ring-1 ring-gray-200 focus:outline-none sm:text-sm"
            >
              {filteredOptions.length === 0 && searchTerm !== "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredOptions.map((option) => (
                  <Combobox.Option
                    key={option.id}
                    className={({ active, selected }) =>
                      classNames(
                        "hover:bg-primary hover:text-white",
                        "flex w-full cursor-default items-center space-x-2 px-3 py-3",
                        selected && !active
                          ? "text-primary dark:text-white"
                          : "dark:text-white",
                        selected && active && "bg-primary text-white",
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        {option.icon && (
                          <div className="flex h-6 w-6 flex-none items-center justify-center">
                            {option.icon}
                          </div>
                        )}
                        <div className="flex-grow">
                          <div
                            className={`truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {option.label}
                          </div>
                          {option.description && (
                            <div className="text-xs">{option.description}</div>
                          )}
                        </div>
                        {selected ? (
                          <span
                            className={` ${
                              active ? "text-white" : "text-blue-500"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>,
            document.body,
          )}
        </Combobox>
      </div>
    </>
  );
}
