import { datadogRum } from "@datadog/browser-rum";
import dayjs from "dayjs";
import { isGuideViewed, useStonlyWidget } from "@/features/product-guide";
import { FolderItemType } from "@/pages/ModelTool/Sidebar/SidebarFactory";
import { useEffect, useRef } from "react";

import guideConfig from "./config";

export const useFirstEltSyncedGuide = (
  folders: FolderItemType[],
  onGuideStarted: (folders: FolderItemType[]) => void,
) => {
  const StonlyWidget = useStonlyWidget();

  const hasStartedGuide = useRef(false);

  useEffect(() => {
    //check guide is able to start:
    const foldersAvailable = getFoldersWithSyncedData(folders);
    if (foldersAvailable.length === 0) return;

    //only show guide once
    const alreadyViewed = isGuideViewed(guideConfig.id);
    if (alreadyViewed) return;

    //Make sure we only trigger starting the tour once:
    if (hasStartedGuide.current) return;
    hasStartedGuide.current = true;

    onGuideStarted(foldersAvailable);

    // Start recording session replay in Datadog
    datadogRum.startSessionReplayRecording();

    // Start the Stonly guided tour
    StonlyWidget("openGuidedTour", { guideId: guideConfig.id });
  }, [StonlyWidget, folders, onGuideStarted]);
};

/*
 * Get all folders that have a raw view that is not imported.
 */
const getFoldersWithSyncedData = (folders: FolderItemType[]) => {
  const foldersWithNewData = folders.filter(folderHasNewTable);
  return foldersWithNewData;
};

export const folderHasNewTable = (folder: FolderItemType) => {
  const hasSyncId = !!folder.folder.syncId;
  const hasRecentlySyncedData = folder.children.some(
    (child) =>
      child.itemType === "view" &&
      !!child.rawView &&
      !child.isViewImported &&
      child.rawView.firstSyncCompleted &&
      isNewTable(child.rawView.createdAt),
  );

  return hasSyncId && hasRecentlySyncedData;
};

const isNewTable = (tableCreated: string) => {
  const now = dayjs();
  const dateToCheck = dayjs(tableCreated);
  const diff = now.diff(dateToCheck, "day");
  return diff <= 2;
};
