import {
  ModelTestsByModelDocument,
  TestType,
  useCreateModelTestMutation,
} from "@/apollo/types";
import { PrimaryButton, SecondaryButton } from "@/components/elements/Button";
import {
  ComboItemSelector,
  SelectorItem,
} from "@/components/elements/input/ComboItemSelector";
import { LoadingFull } from "@/components/elements/LoadingComponents";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import FieldLabel from "@/components/primitives/InputLabel";
import { useToast } from "@/providers/ToastProvider";
import { useMemo, useState } from "react";

import { useCurrentModel } from "../hooks/useCurrentModel";
import { useViewDetails } from "../hooks/useViewDetails";

export const AddTest = (props: {
  modelId: string;
  addingTest: boolean;
  onFinish: () => void;
}) => {
  const [selectedColumn, setSelectedColumn] = useState<string>("");

  const [selectedType, setSelectedType] = useState<TestType | null>(null);

  const allCols = useModelColumnOptions();

  const selecterOptions = useMemo(() => {
    return allCols.columnOptions.map(
      (col) =>
        ({
          id: col.value,
          label: col.label,
          description: col.type,
        }) as SelectorItem,
    );
  }, [allCols.columnOptions]);

  const toast = useToast();

  const [createModelTest, createModelTestMutation] = useCreateModelTestMutation(
    {
      onCompleted: () => {
        toast("Test created", "New test was successfully created", "success");
        setSelectedColumn("");
        setSelectedType(null);
      },
      onError: (error) => {
        toast(
          "Error creating test",
          error.message ?? "An error occurred while creating the test",
          "error",
        );
      },
      refetchQueries: [ModelTestsByModelDocument],
    },
  );

  const handleAddTest = async () => {
    if (!selectedType || createModelTestMutation.loading) return;

    await createModelTest({
      variables: {
        createModelTestInput: {
          modelId: props.modelId,
          column: selectedColumn,
          testType: selectedType,
        },
      },
    });
    props.onFinish();
  };

  const isValid = selectedColumn.length && selectedType !== null;

  return (
    <Modal onClose={props.onFinish} isOpen={props.addingTest}>
      <ModalCloseButton />
      <ModalHeader>Add new test</ModalHeader>
      <ModalBody className="flex flex-col gap-6">
        <div>
          <FieldLabel>Select a column</FieldLabel>

          <ComboItemSelector
            options={selecterOptions}
            onChange={(option) => setSelectedColumn(option.id)}
            selectedId={selectedColumn}
          />
        </div>
        {selectedColumn.length !== 0 && (
          <div>
            <TestTypeSelector
              onSelect={setSelectedType}
              selectedType={selectedType}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className="flex-row-reverse justify-start gap-4">
        <PrimaryButton
          className={"relative flex-1"}
          disabled={!isValid}
          onClick={() => {
            handleAddTest();
          }}
        >
          Add test
          {createModelTestMutation.loading && <LoadingFull />}
        </PrimaryButton>
        <SecondaryButton
          className={"flex-1"}
          onClick={() => {
            props.onFinish();
          }}
        >
          Cancel
        </SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};
const typeOptions: { type: TestType; name: string; description: string }[] = [
  {
    type: TestType.Uniqueness,
    name: "Uniqueness",
    description:
      "Check if all values for a column is unique, to avoid having duplicates.",
  },
  {
    type: TestType.NotNull,
    name: "Not null",
    description: "Verified that no values for a column are null.",
  },
];
const TestTypeSelector = (props: {
  selectedType: TestType | null;
  onSelect: (type: TestType | null) => void;
}) => {
  return (
    <fieldset>
      <legend className="sr-only">Test type</legend>
      <div className="space-y-5">
        {typeOptions.map((option) => (
          <div key={option.type} className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                id={option.type}
                aria-describedby={`${option.type}-description`}
                type="radio"
                checked={props.selectedType === option.type}
                onChange={(e) => {
                  props.onSelect(option.type);
                }}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor={option.type}
                className="font-medium text-gray-700 dark:text-white"
              >
                {option.name}
              </label>
              <p id={`${option.type}-description`} className="text-gray-500">
                {option.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

function useModelColumnOptions() {
  const current = useCurrentModel();

  const path = current?.dwSync?.path ?? current?.dwTable?.path;
  const { viewDetails, loading } = useViewDetails(path);
  const columnOptions = useMemo(() => {
    return (
      viewDetails?.dataSchema?.map((col) => {
        return {
          value: col.name,
          label: col.name,
          type: col.type,
        };
      }) ?? []
    );
  }, [viewDetails]);
  return { columnOptions, loading };
}
