import { XMarkIcon } from "@heroicons/react/24/outline";
import { useValidateCustomFormLazyQuery } from "@/apollo/types";
import { Alert, AlertIcon, AlertTitle } from "@/components/elements/Alert";
import { Button, IconButton } from "@/components/elements/Button";
import { Input } from "@/components/primitives/input";
import FieldLabel from "@/components/primitives/InputLabel";
import { useCallback, useState } from "react";

import { GoogleAdsReport, isGoogleAdsReportType } from "./GoogleAdsForm";
import { TextArea } from "@/components/primitives/input/TextArea";
import { InputError } from "@/components/primitives/InputError";
import { CodeBracketIcon } from "@heroicons/react/20/solid";

const NO_AD_ACCOUNT_MESSAGE = "No ad account selected";
const INVALID_REPORT_MESSAGE = "Invalid report";

type CustomReport = {
  id: string;
  name: string;
  query: string;
};

export default function GoogleAdsCustomReport(props: {
  onChangeReport: (report: CustomReport) => void;
  onRemoveReport: (report: CustomReport) => void;
  onReportInvalid: (reportId: string) => void;
  onReportValid: (reportId: string) => void;
  report: CustomReport;
  connectionId: string;
  adAccounts?: { label: string; value: string }[];
  validationError?: string;
}) {
  const {
    report,
    onChangeReport,
    onRemoveReport,
    validationError,
    connectionId,
    adAccounts,
    onReportValid,
    onReportInvalid,
  } = props;

  const [validationSuccess, setValidationSuccess] = useState<boolean>(false);
  const [validationCallError, setValidationCallError] = useState<string | null>(
    null,
  );

  const [callValidationQuery] = useValidateCustomFormLazyQuery();

  const runValidation = useCallback(
    async (validateViaApi: boolean = false) => {
      //Call validation endpoint
      const queryData = {
        query: report.query,
        id: report.id,
      };
      const { data, error } = await callValidationQuery({
        variables: {
          input: {
            connectionId,
            payload: JSON.stringify({
              report: queryData,
              adAccounts,
              validateViaApi,
            }),
          },
        },
      });
      //If error occurs, show warning and use initial metrics/dimensions
      if (error) {
        if (error.message === NO_AD_ACCOUNT_MESSAGE) {
          setValidationCallError(
            "No ad accounts selected. Please select ad accounts before validating your query",
          );
        } else if (error.message === INVALID_REPORT_MESSAGE) {
          onReportInvalid(report.id);
        } else {
          setValidationCallError(error.message);
        }
      }

      const result = data?.validateCustomForm.customFormValidationResult;
      if (result) {
        if (result.valid) {
          setValidationSuccess(true);
          onReportValid(report.id);
          setValidationCallError(null);
        } else {
          setValidationCallError(result.message);
          onReportInvalid(report.id);
        }
      }
    },
    [
      adAccounts,
      callValidationQuery,
      connectionId,
      onReportInvalid,
      onReportValid,
      report.id,
      report.query,
    ],
  );

  const changeReport = (report: GoogleAdsReport) => {
    onChangeReport(report);
    setValidationSuccess(false);
  };

  return (
    <div className="relative rounded-sm border bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
      <div className="pb-4">
        <FieldLabel id="name" required={true}>
          Name
        </FieldLabel>
        <Input
          placeholder="Name of your report"
          value={report.name}
          onChange={(e) => changeReport({ ...report, name: e.target.value })}
        />
        {isGoogleAdsReportType(report.name) && (
          <InputError>
            {report.name} is a reserved report name and cannot be used.
          </InputError>
        )}
      </div>
      <div className="pb-4">
        <FieldLabel id="query" required={true}>
          GAQL Query
        </FieldLabel>
        <TextArea
          placeholder={`Click the "GAQL builder" button to generate a query. Choose a resource, build the report, then paste the query here.`}
          className="h-48 font-mono text-sm"
          value={report.query}
          onChange={(e) => changeReport({ ...report, query: e.target.value })}
        />
      </div>
      <div className="flex justify-between">
        <Button
          size="sm"
          variant="solid"
          colorScheme="primary"
          onClick={() => runValidation(true)}
        >
          Validate report
        </Button>
        <Button
          size="sm"
          variant="solid"
          colorScheme="primary"
          icon={<CodeBracketIcon />}
          as="a"
          href="https://developers.google.com/google-ads/api/fields/v13/overview_query_builder"
          target="_blank"
          rel="noreferrer"
        >
          GAQL builder
        </Button>
      </div>
      <div className="absolute right-0 top-0 p-2">
        <IconButton
          size="xs"
          icon={<XMarkIcon />}
          variant="ghost"
          onClick={() => onRemoveReport(report)}
        />
      </div>
      {validationSuccess ? (
        <div className="mt-2">
          <Alert status="success">
            <AlertIcon />
            Report is valid
          </Alert>
        </div>
      ) : null}
      {validationError ? (
        <div className="mt-2">
          <Alert status="error">
            <AlertIcon />
            {validationError}
          </Alert>
        </div>
      ) : null}
      {!validationCallError && !validationSuccess ? (
        <div className="mt-2">
          <Alert status="info">
            <AlertIcon />
            Validate your report to continue
          </Alert>
        </div>
      ) : null}
      {validationCallError ? (
        <div className="mt-2">
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Error:</AlertTitle>
            {validationCallError}
          </Alert>
        </div>
      ) : null}
    </div>
  );
}
