import { keyBy } from "lodash";
import { useMemo } from "react";

export default function useChainedObject<Type>(
  array: Type[] | undefined,
  keyByProperty: string,
  currentKey: string,
) {
  const object = useMemo(
    () => keyBy(array, keyByProperty),
    [array, keyByProperty],
  );
  const currentObject = useMemo(() => {
    if (!currentKey) return null;
    return object[currentKey] || null;
  }, [object, currentKey]);

  return { object, currentObject };
}
