const stepsMap: Record<string, string> = {
  "2599917": "Try the example model",
  "2599923": "Run the query",
  "2599924": "Query results",
  "2600136": "Data lineage",
  "2600201": "Lineage view",
  "2599926": "Add own data source",
  "2599925": "Set up data source",
};

const guide = {
  id: "ZHz8u9eBY5",
  name: "Example model welcome guide",
  stepsMap,
};

export default guide;
