import dayjs from "dayjs";
import { PropsWithChildren } from "react";
import { LocationGenerics, useNavigateWithSlug } from "routes";

import { User, useConnectionWithCredentialsQuery } from "@/apollo/types";
import { PrimaryButton } from "@/components/elements/Button";
import DataBox, { DetailItem, Empty } from "@/components/elements/DataBox";
import IntegrationCellTitle from "@/components/elements/IntegrationCellTitle";
import SyncStatusLabel, { Mode } from "@/components/elements/SyncStatusLabel";
import TabLayout from "@/components/layouts/TabLayout";
import { useDataSourceSlideOver } from "@/components/modules/new-data-source-slideover";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import { IntegrationLogoBox } from "@/integrations";
import { Ability, useIntegration } from "@/integrations";
import { useMatch } from "@tanstack/react-location";

import ConnectionNotifications from "./ConnectionNotifications";

export default function ViewConnectionPage() {
  const { params } = useMatch<LocationGenerics>();
  const { data } = useConnectionWithCredentialsQuery({
    variables: {
      id: params.connectionId,
    },
  });
  const connection = data?.connection;
  const integration = useIntegration(connection?.integrationId);
  const navigate = useNavigateWithSlug();

  const dataSourceSlideOver = useDataSourceSlideOver();
  const { onOpen: viewSync } = useViewDataSourceSlideOver();

  function stringifyUser(user?: Partial<User>) {
    if (!user) {
      return "Unknown";
    }

    if (!user.firstName) {
      return user.email;
    }

    return `${user.firstName} ${user.lastName} (${user.email})`;
  }

  if (!connection || !integration) {
    return null;
  }

  const hasAbility = (ability: Ability) => {
    return integration && integration.abilities.includes(ability);
  };
  const hasSync =
    connection.eltSyncs.length > 0 || connection.reverseEltSyncs.length > 0;

  return (
    <TabLayout
      tabs={[
        { href: `.`, id: "overview", name: "Overview" },
        {
          href: `./configuration`,
          id: "configuration",
          name: "Configuration",
        },
      ]}
    >
      <div>
        <div className="flex flex-col space-y-8">
          <ConnectionNotifications connection={connection} />
          <DataBox header="Details">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <div className="flex flex-col space-y-4">
                <DetailItem title="Connector">
                  <IntegrationCellTitle
                    integrationId={connection.integrationId}
                    connectionLabel={integration?.name}
                  />
                </DetailItem>
                <DetailItem title="Name">{connection.label}</DetailItem>
                <DetailItem title="Last connected by">
                  {stringifyUser(connection?.user ?? undefined)}
                </DetailItem>
                <DetailItem title="Last connected at">
                  {dayjs(connection.connectedAt).format("MMMM D, YYYY")}
                </DetailItem>
                {connection.credentials?.client_email ? (
                  <DetailItem title="E-mail">
                    {connection.credentials.client_email}
                  </DetailItem>
                ) : null}
              </div>
            </div>
          </DataBox>
          <DataBox header="Syncs">
            <>
              {hasSync && (
                <SyncTable>
                  {connection.eltSyncs.map((sync) => (
                    <SyncItem
                      integrationId={
                        sync?.destinationConnection?.integrationId || ""
                      }
                      key={sync.id}
                      label={sync?.destinationSchemaName || ""}
                      {...sync}
                      onClick={() => viewSync({ syncId: sync.id })}
                      type="Data source"
                    />
                  ))}
                  {connection.reverseEltSyncs.map((sync) => (
                    <SyncItem
                      label={sync?.primaryObject || ""}
                      integrationId={
                        sync?.destinationConnection?.integrationId || ""
                      }
                      key={sync.id}
                      {...sync}
                      onClick={() =>
                        navigate({ to: `/reverse-etl/${sync.id}` })
                      }
                      type="Export"
                    />
                  ))}
                </SyncTable>
              )}

              {!hasSync && (
                <Empty>
                  <p>There are currently no syncs using this connector.</p>
                  <div className="my-4 flex flex-row gap-4">
                    {hasAbility("EltSourceConnection") && (
                      <PrimaryButton
                        onClick={() => {
                          dataSourceSlideOver.onOpen({
                            connectionId: connection.id,
                          });
                        }}
                      >
                        + New sync (ELT)
                      </PrimaryButton>
                    )}
                    {hasAbility("ReverseEltDestinationConnection") && (
                      <PrimaryButton
                        onClick={() =>
                          navigate({
                            to: "/reverse-etl/new",
                            search: {
                              connection: connection.id,
                            },
                          })
                        }
                      >
                        + New export sync (Reverse ETL)
                      </PrimaryButton>
                    )}
                  </div>
                </Empty>
              )}
            </>
          </DataBox>
        </div>
      </div>
    </TabLayout>
  );
}

const SyncItem = (props: {
  status: string;
  integrationId: string;
  createdAt: string;
  label: string;
  type: string;
  onClick: () => void;
}) => (
  <tr
    className="h-16 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700"
    onClick={props.onClick}
  >
    <td className="flex h-16 items-center justify-start text-sm dark:text-white">
      <IntegrationLogoBox id={props.integrationId} size="sm" />
      <span className="ml-3">{props.label}</span>
    </td>
    <td className="text-sm dark:text-white">
      <span>{props.type}</span>
    </td>
    <td className="text-sm dark:text-white">
      <SyncStatusLabel mode={props.status as Mode} />
    </td>
    <td className="text-sm">
      <div className="flex dark:text-white">
        {dayjs(props.createdAt).fromNow()}
      </div>
    </td>
  </tr>
);

const SyncTable = (props: PropsWithChildren<{}>) => (
  <table className="mt-0 w-full table-auto">
    <thead>
      <tr className="text-left text-xs font-light text-gray-600 dark:text-gray-400">
        <th className="font-light">Connector</th>
        <th className="font-light">Type</th>
        <th className="font-light">Status</th>
        <th className="font-light">Created</th>
      </tr>
    </thead>
    <tbody>{props.children}</tbody>
  </table>
);
