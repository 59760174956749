import { useState } from "react";
import { useNavigateWithSlug } from "routes";

import {
  GetRawViewsDocument,
  ListModelFoldersDocument,
  ListModelsDocument,
  SeededModel,
  useSeedDataWarehouseMutation,
} from "@/apollo/types";
import { ActionButton } from "@/components/elements/Button";
import FormInfoBox from "@/components/elements/FormInfoBox";
import { LoadingOverlay } from "@/components/elements/LoadingComponents";
import {
  SlideOver,
  SlideOverBody,
  SlideOverCloseButton,
  SlideOverHeader,
} from "@/components/elements/SlideOver";
import Center from "@/components/elements/layout/Center";
import { Checkbox } from "@/components/primitives/Checkbox";
import classNames from "@/helpers/classNames";
import { IntegrationLogoBox } from "@/integrations";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useToast } from "@/providers/ToastProvider";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

import { FolderIcon } from "./components/FolderIcon";
import ModelSVGIcon from "./components/ModelIcon/ModelSVGIcon";

type Props = { isOpen: boolean; onClose: () => void };

const DemoDataSetup = (props: Props) => {
  const toast = useToast();

  const [successfullyLoadedData, setsetsuccessfullyLoadedData] =
    useState(false);

  const [exampleModel, setExampleModel] = useState<
    Pick<SeededModel, "id" | "name"> | undefined
  >();

  const [seedDatawarehouse, { loading }] = useSeedDataWarehouseMutation({
    onCompleted: (response) => {
      const models = response.seedDataWarehouse.models;
      setExampleModel(models[0]);
      setsetsuccessfullyLoadedData(true);
    },
    onError: (error) => {
      toast(
        "Error loading demo data",
        "An error occured while loading demo data.",
        "error",
      );
    },
    refetchQueries: [
      GetRawViewsDocument,
      ListModelsDocument,
      ListModelFoldersDocument,
    ],
    awaitRefetchQueries: true,
  });

  const mixpanel = useMixpanel();
  const navigate = useNavigateWithSlug();

  return (
    <SlideOver
      size="lg"
      show={props.isOpen}
      onClose={() => {
        if (loading) return;
        props.onClose();
      }}
      bgOverlay
    >
      <SlideOverCloseButton />
      <SlideOverHeader className="box-content flex basis-11 items-center pb-6">
        <div className="flex gap-3">
          <IntegrationLogoBox id={"shopify"} size="md" />
          <Center>
            <div>
              <h3 className="text-xs text-gray-700 dark:text-gray-500">
                Set up a data source
              </h3>
              <p>{"shopify_example"}</p>
            </div>
          </Center>
        </div>
      </SlideOverHeader>
      <SlideOverBody>
        {!successfullyLoadedData && (
          <div className="relative">
            {loading && <LoadingOverlay />}
            {/* View only table selecter to show what data is being added */}
            <div>
              <h3 className="text-sm font-medium">Data source tables </h3>
              <p className="mb-4 text-xs">
                Tables that will be loaded into your data warehouse
              </p>
              <TableDisplayer />
            </div>
            <FormInfoBox headline="Demo data">
              <p>
                The example Shopify data will load simulated sample Shopify data
                into your data warehouse. It contains a small subset of the data
                you would typically encounter in a real Shopify store.
              </p>
            </FormInfoBox>
            <hr className="mt-10 dark:border-gray-700" />
            <div className="py-8">
              <h3 className="text-sm font-medium">Data models</h3>
              <p className="mb-4 text-xs">
                Demo data model examples that will be published to your data
                warehouse
              </p>
              <DemoModelsDisplayer />
            </div>
          </div>
        )}
        {successfullyLoadedData && <SuccessScreen onClose={props.onClose} />}
      </SlideOverBody>
      <footer className="flex shrink-0 grow-0 justify-end gap-4 px-6 py-4">
        {!successfullyLoadedData && (
          <ActionButton
            colorScheme="primary"
            type="submit"
            disabled={loading}
            isLoading={loading}
            loadingText="Loading demo data..."
            onClick={(e) => {
              if (loading) return;
              mixpanel.track("Load demo data");
              seedDatawarehouse();
            }}
          >
            {"Load demo data"}
          </ActionButton>
        )}
        {successfullyLoadedData && (
          <>
            <ActionButton
              variant="outline"
              colorScheme="secondary"
              onClick={() => {
                props.onClose();
              }}
            >
              Close
            </ActionButton>

            <ActionButton
              variant="solid"
              colorScheme="primary"
              onClick={() => {
                navigate({
                  to: `/editor/${exampleModel?.id}`,
                  search: {
                    productTour: true,
                  },
                });
                props.onClose();
              }}
            >
              Explore data model
            </ActionButton>
          </>
        )}
      </footer>
    </SlideOver>
  );
};

export default DemoDataSetup;

const TableDisplayer = () => {
  return (
    <div className="space-y-3">
      <TableSelecter
        tableName={"customer"}
        colums={[
          "id",
          "first_name",
          "last_name",
          "email",
          "phone",
          "updated_at",
          "created_at",
        ]}
      />
      <TableSelecter
        tableName={"order"}
        colums={[
          "id",
          "customer_id",
          "shipping_address_country",
          "shipping_address_city",
          "shipping_address_street",
          "shipping_address_postal_code",
          "billing_address_country",
          "currency",
          "fulfillment_status",
          "processed_at",
          "updated_at",
          "created_at",
        ]}
      />

      <TableSelecter
        tableName={"order_line"}
        colums={["id", "order_id", "title", "quantity", "price"]}
      />
    </div>
  );
};

const TableSelecter = (props: { tableName: string; colums: string[] }) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <label className={"flex grow cursor-default items-center space-x-2"}>
        <Checkbox checked={true} className="opacity-75" readOnly />
        <button
          type="button"
          className="flex items-center space-x-2"
          disabled={false}
          onClick={() => setOpen(!open)}
        >
          <ChevronRightIcon
            className={classNames("w-4 transform", open && "rotate-90")}
          />
          <div>{props.tableName}</div>
        </button>
      </label>
      {open && (
        <div className="space-y-1 py-1 pl-6">
          {props.colums.map((col) => (
            <label
              key={col}
              className={classNames("flex grow items-center space-x-2")}
            >
              <Checkbox className="opacity-75" checked={true} readOnly={true} />
              <div>{col}</div>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

const DemoModelsDisplayer = () => {
  return (
    <div className="space-y-2">
      <div className="flex items-center space-x-2">
        <FolderIcon />
        <div>Ecommerce</div>
      </div>
      <DemoModel modelName="customer_growth" />
      <DemoModel modelName="monthly_sales" />
      <DemoModel modelName="top_ten_products" />
      <DemoModel modelName="yearly_revenue" />
    </div>
  );
};

const DemoModel = (props: { modelName: string }) => {
  return (
    <div className="ml-6 flex items-center space-x-2">
      <ModelSVGIcon className="h-4 w-4" />
      <div>{props.modelName}</div>
    </div>
  );
};

const SuccessScreen = (props: { onClose: () => void }) => {
  return (
    <Center className="h-full w-full">
      <div className="flex flex-col items-center gap-4 text-center">
        <IntegrationLogoBox id={"shopify"} size="xl" />
        <p className="max-w-sm text-lg">
          Shopify example data successfully loaded
        </p>
        <p className="max-w-xs text-sm text-gray-700 dark:text-gray-500">
          You can now explore the data in the WELD modeling tool and start
          building data models.
        </p>
      </div>
    </Center>
  );
};
