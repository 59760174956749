import { Ability, IntegrationType } from "./types";

export const hasAbility = (
  integration: IntegrationType | null,
  ...abilities: Ability[]
) => {
  if (integration === null) return false;
  return abilities.every((x) => integration.abilities.includes(x));
};

export const isCustomConnector = (integrationId: string) =>
  integrationId.startsWith("custom-integration");
