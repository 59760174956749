import classNames from "@/helpers/classNames";
import React from "react";

type PageContainerProps = React.ComponentProps<"div"> & {
  size?: "md" | "xs" | "sm" | "lg" | "xl" | "2xl";
};

const PageContainer = React.forwardRef<HTMLDivElement, PageContainerProps>(
  ({ className, size, ...props }, ref) => {
    const sizeClasses: Record<
      NonNullable<PageContainerProps["size"]>,
      string
    > = {
      "2xl": "max-w-5xl",
      xl: "max-w-4xl",
      lg: "max-w-lg",
      md: "max-w-md",
      sm: "max-w-sm",
      xs: "max-w-xs",
    };
    return (
      <div
        ref={ref}
        className={classNames(
          "relative mx-auto flex flex-col items-center px-4 py-4 sm:px-6 md:py-6 lg:py-8",
          size && sizeClasses[size],
          className,
        )}
        {...props}
      />
    );
  },
);

export default PageContainer;
