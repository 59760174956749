import { useCountriesQuery } from "@/apollo/types";
import { useState } from "react";

export function useCountryOptions() {
  const [options, setOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  useCountriesQuery({
    onCompleted(data) {
      setOptions(
        data.countries.map((country) => ({
          label: country.name,
          value: country.code,
        })),
      );
    },
  });

  return options;
}
