import Badge, { BadgeVariant } from "@/components/elements/Badge_Legacy";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Tooltip from "@/components/elements/Tooltip";
import { ELTSyncStatus, useEltSyncStatus } from "@/features/elt";

export const EltSyncStatusBadge = (props: {
  eltSyncId: string;
  size?: "sm" | "md";
}) => {
  const { loading, status, nrFailingStreams, notifications } = useEltSyncStatus(
    props.eltSyncId,
  );

  switch (status) {
    case ELTSyncStatus.NOT_STARTED:
      return (
        <Badge size={props.size} variant={BadgeVariant.Inactive}>
          Not Started
        </Badge>
      );
    case ELTSyncStatus.PAUSED:
      return (
        <Badge size={props.size} variant={BadgeVariant.Info}>
          Paused
        </Badge>
      );
    case ELTSyncStatus.NO_SYNC:
      return (
        <Badge size={props.size} variant={BadgeVariant.Default}>
          <LoadingSpinner
            colorScheme="primary"
            className="mr-2 h-3 w-3 shrink-0"
          />
          Running initial sync
        </Badge>
      );
    case ELTSyncStatus.ALL_SYNCED:
      return (
        <Badge size={props.size} variant={BadgeVariant.Success}>
          All synced
        </Badge>
      );
    case ELTSyncStatus.PARTIALLY_SYNCED:
      return (
        <Badge size={props.size} variant={BadgeVariant.Default}>
          <LoadingSpinner
            colorScheme="primary"
            className="mr-2 h-2 w-2 shrink-0"
          />
          Partially synced
        </Badge>
      );
    case ELTSyncStatus.ALL_FAILING:
      return (
        <Badge size={props.size} variant={BadgeVariant.Error}>
          All failing
        </Badge>
      );
    case ELTSyncStatus.NEEDS_USER_INTERACTION:
      return (
        <Tooltip
          content={
            notifications && notifications.length > 0 ? (
              <>
                <p className="mb-3">You need to take action to fix the sync.</p>
                <ul className="mb-2 flex flex-col gap-3">
                  {notifications.map((notification) => (
                    <li key={notification.id}>{notification.message}</li>
                  ))}
                </ul>
              </>
            ) : null
          }
        >
          <Badge size={props.size} variant={BadgeVariant.Error}>
            Interaction needed
          </Badge>
        </Tooltip>
      );
    case ELTSyncStatus.PARTIAL_FAILING:
      return (
        <Badge size={props.size} variant={BadgeVariant.Error}>
          {nrFailingStreams} failed
        </Badge>
      );
    default:
      return loading ? <LoadingSpinner /> : null;
  }
};
