import Select from "@/components/elements/Select";
import { InputDescription } from "@/components/primitives/InputDescription";
import { InputError } from "@/components/primitives/InputError";
import { InputErrorIndicator } from "@/components/primitives/InputErrorIndicator";
import FieldLabel from "@/components/primitives/InputLabel";
import { FormType } from "@/integrations";
import { Controller, useFormContext } from "react-hook-form";

export default function SelectForm({
  field,
  loading,
  ...rest
}: {
  loading?: boolean;
  field: Extract<FormType, { type: "dropdown" }>;
  descriptionKey?: string;
}) {
  const { name: id, required, label, dropdownOptions, description } = field;

  const {
    formState: { errors },
    control,
  } = useFormContext();
  const error = errors?.[id]?.message;
  return (
    <div className="pb-4">
      <FieldLabel id={id} required={required}>
        {label}
      </FieldLabel>

      <div className="flex items-center">
        <div className="grow">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              let selectedValue = value;
              if (typeof value === "string") {
                const dropdownOption = dropdownOptions.find(
                  (x) => x.value === value,
                );
                selectedValue = dropdownOption;
              }
              return (
                <Select
                  isMulti={field.isMulti}
                  isLoading={loading}
                  creatable={field.creatable}
                  placeholder={field.placeholder}
                  disabled={field.disabled}
                  onBlur={onBlur}
                  onChange={(v: any) => onChange(v)}
                  options={dropdownOptions}
                  disableCheckmark={
                    field.disableCheckmark === undefined
                      ? true
                      : field.disableCheckmark
                  }
                  hideSelectedOptions={field.hideSelectedOptions}
                  value={selectedValue}
                  {...rest}
                />
              );
            }}
            rules={{ required }}
            name={id}
          />
        </div>

        {!!error && <InputErrorIndicator className="ml-2" />}
      </div>
      {!!description && (
        <InputDescription className="m-1">{description}</InputDescription>
      )}
      {!!error && <InputError className="mt-1">{String(error)}</InputError>}
    </div>
  );
}
