import { groupBy } from "lodash";
import { Suspense, lazy, useMemo, useState } from "react";

import { ModelListItemFragment } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { WeldTab } from "@/components/elements/tabs/Tab";
import { ViewItemType } from "@/pages/ModelTool/Sidebar/SidebarFactory";
import { Tab } from "@headlessui/react";
import { ErrorBoundary } from "@sentry/react";

const DataSourceViewsSelector = lazy(() => import("./DataSourceViewsSelector"));
const ModelsSelector = lazy(() => import("./ModelsSelector"));

export default function ViewsSelector(props: {
  selected: {
    views: ViewItemType["rawView"][];
    models: ModelListItemFragment[];
  };
  onSelected: (selection: {
    views: ViewItemType["rawView"][];
    models: ModelListItemFragment[];
  }) => void;
  onClose: () => void;
}) {
  const [selectedViews, setSelectedViews] = useState<ViewItemType["rawView"][]>(
    props.selected.views,
  );
  const [selectedModels, setSelectedModels] = useState<ModelListItemFragment[]>(
    props.selected.models,
  );

  const resetState = () => {
    setSelectedModels([]);
    setSelectedViews([]);
  };

  const handleDone = () => {
    props.onSelected({
      views: selectedViews,
      models: selectedModels,
    });
  };

  const summary = useMemo(() => {
    const totalDataSources = groupBy(selectedViews, (x) => x.schema);
    const totalRawViews = selectedViews.length;
    const totalModels = selectedModels.length;

    const totalSelection = totalRawViews + totalModels;

    const summaryTextParts: string[] = [];
    if (totalSelection === 0) {
      summaryTextParts.push("No tables selected");
    } else {
      if (totalRawViews > 0) {
        summaryTextParts.push(
          `${totalRawViews} ${totalRawViews === 1 ? "table" : "tables"} selected from ${Object.keys(totalDataSources).length} ${Object.keys(totalDataSources).length === 1 ? "Data Source" : "Data Sources"}`,
        );
      }
      if (totalModels > 0) {
        summaryTextParts.push(
          `${totalModels} ${totalModels === 1 ? "model" : "models"}${totalRawViews > 0 ? "" : " selected"}`,
        );
      }
    }

    return {
      totalDataSources: Object.keys(totalDataSources).length,
      totalRawViews,
      totalModels,
      totalSelection,
      text: summaryTextParts.join(" and "),
    };
  }, [selectedViews, selectedModels]);

  return (
    <div className="flex grow flex-col">
      <Tab.Group>
        <Tab.List className="mb-4 flex gap-8">
          <WeldTab>Data Sources</WeldTab>
          <WeldTab>Models</WeldTab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <ErrorBoundary>
              <Suspense
                fallback={
                  <div className="my-8 flex justify-center">
                    <LoadingSpinner className="h-6 w-6" />
                  </div>
                }
              >
                <DataSourceViewsSelector
                  value={selectedViews}
                  onChange={setSelectedViews}
                />
              </Suspense>
            </ErrorBoundary>
          </Tab.Panel>
          <Tab.Panel>
            <ErrorBoundary>
              <Suspense
                fallback={
                  <div className="my-8 flex justify-center">
                    <LoadingSpinner className="h-6 w-6" />
                  </div>
                }
              >
                <ModelsSelector
                  value={selectedModels}
                  onChange={setSelectedModels}
                />
              </Suspense>
            </ErrorBoundary>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      <div className="mt-3 text-sm">
        <div className="font-medium">Selection Summary:</div>
        <div>
          <em>{summary.text}</em>
        </div>
      </div>
      <div className="flex flex-none items-center justify-between pb-4 pt-6">
        <div>
          <Button onClick={() => resetState()}>Reset</Button>
        </div>
        <div className="ml-auto flex gap-2">
          <Button onClick={() => props.onClose()}>Cancel</Button>
          <Button
            variant="solid"
            colorScheme="primary"
            onClick={() => handleDone()}
          >
            Save selection
          </Button>
        </div>
      </div>
    </div>
  );
}
