import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import { Link } from "@tanstack/react-location";
import { ComponentProps } from "@tw-classed/react";
import { Button } from "@/components/elements/Button";
import { useCurrentAccount } from "@/providers/account";
import { useCurrentUser } from "@/providers/UserProvider";
import { forwardRef } from "react";

export function useBookMeetingLinkProps({
  message = "",
}: {
  message?: string;
}) {
  const account = useCurrentAccount();
  const user = useCurrentUser();

  const searchParams = new URLSearchParams({
    company: account.name,
    firstname: user.firstName ?? "",
    lastname: user.lastName ?? "",
    email: user.email,
    message,
  });

  return {
    href: `https://weld.app/demo?${searchParams.toString()}`,
    target: "_blank",
  };
}

export const BookMeetingButton = forwardRef<
  HTMLButtonElement,
  {
    message?: string;
  } & ComponentProps<typeof Button>
>(({ message = "", ...props }, ref) => {
  const linkProps = useBookMeetingLinkProps({ message });
  return (
    <Button
      icon={<CalendarDaysIcon />}
      ref={ref as any}
      as={Link as any}
      {...linkProps}
      variant="solid"
      colorScheme="primary"
      {...props}
    >
      Book meeting
    </Button>
  );
});
