import { uniqBy } from "lodash";

import { Ability } from "@/integrations";

import { useOnboardingConnectionPreferences } from "../onboarding-connection-preferences/useOnboardingConnectionPreferences";
import { useRecommendedIntegrationsByBusinessDepartment } from "./useRecommendedIntegrationsByBusinessDepartment";

export function useConnectorRecommendations(options: {
  ability: Ability;
  limit?: number;
}) {
  const { integrations: onboardingConnectionPreferences } =
    useOnboardingConnectionPreferences(options.ability);

  const { integrations: integrationsByBusinessDepartment } =
    useRecommendedIntegrationsByBusinessDepartment();

  let integrations = uniqBy(
    onboardingConnectionPreferences.concat(integrationsByBusinessDepartment),
    (x) => x.id,
  );
  return integrations.slice(0, options.limit ?? 3);
}
