const stepsMap = {
  "2219111": "Welcome",
  "2219116": "View the chart model",
  "2222412": "Run the query",
  "2222413": "Run results",
  "2293860": "Try the AI SQL Assistant",
  "2222497": "Add own data source",
  "2222429": "Set up data source",
} as const;

const guide = {
  id: "Qi0MP6svEj",
  name: "Welcome to WELD",
  stepsMap,
};

export default guide;
