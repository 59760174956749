import { SparklesIcon } from "@heroicons/react/24/solid";
import { Property } from "@/apollo/types";
import { SecondaryButton, UtilityButton } from "@/components/elements/Button";
import DataBox from "@/components/elements/DataBox";
import Select from "@/components/elements/Select";
import SyncItem from "@/components/elements/SyncItem";
import nextTick from "@/helpers/nextTick";
import { keyBy } from "lodash";
import MappingIcons from "@/pages/ReverseEltSyncs/modules/MappingIcons";
import { useMemo } from "react";
import { useCallback } from "react";

import useDestinationProperties from "../hooks/useDestinationProperties";
import useOperations from "../hooks/useOperations";
import useSourceProperties from "../hooks/useSourceProperties";
import MappingItem from "./MappingItem";
import { useSyncContext } from "./SyncContext";
import useAutoMapping from "./useAutoMapping";

export default function MappingStep() {
  const [state, dispatch] = useSyncContext();
  const primaryMapping = useMemo(
    () => state.mapping.find((item) => item.isPrimaryMapping),
    [state.mapping],
  );

  const { currentOperation } = useOperations();

  const {
    destinationProperties,
    destinationPropertiesObject,
    destinationPropertiesLoading,
  } = useDestinationProperties();

  const destinationPropertyHasPrimaryKey = destinationProperties.some(
    (d) => d.isPrimaryKey,
  );

  const unmappedDestinationProperties = useMemo(() => {
    const selectedDestinationProperties = keyBy(
      state.mapping,
      "destinationPropertyId",
    );
    return destinationProperties
      .filter((it) => !it.readonly)
      .filter((it) => !selectedDestinationProperties[it.propertyId])
      .sort((it) => (it.isRequired ? -1 : 1));
  }, [destinationProperties, state.mapping]);

  const { sourceProperties, sourcePropertiesObject, sourcePropertiesLoading } =
    useSourceProperties();

  const unmappedSourceProperties = useMemo(() => {
    const selectedSourceProperties = keyBy(state.mapping, "sourcePropertyId");

    return sourceProperties.filter(
      (sp) => !selectedSourceProperties[sp.propertyId],
    );
  }, [sourceProperties, state.mapping]);

  const { handleAddRest, handleAutoMap, autoMapablePairs } = useAutoMapping();

  const handleChangePrimaryDestinationProperty = useCallback(
    (item: Property | null) => {
      const didNotChange =
        item?.propertyId === primaryMapping?.destinationPropertyId;
      if (didNotChange) return;

      const existingMappingItem =
        item &&
        state.mapping
          .filter((item) => !item.isPrimaryMapping)
          .find(
            ({ destinationPropertyId }) =>
              destinationPropertyId === item.propertyId,
          );

      if (
        existingMappingItem &&
        existingMappingItem.sourcePropertyId &&
        existingMappingItem.destinationPropertyId
      ) {
        const confirmedRemoveExistingMapping = window.confirm(
          `You already mapped ${
            destinationPropertiesObject[
              existingMappingItem.destinationPropertyId
            ]?.propertyName
          } to ${
            sourcePropertiesObject[existingMappingItem.sourcePropertyId]
              ?.propertyName
          }. If you continue, this mapping will be removed.`,
        );
        if (!confirmedRemoveExistingMapping) return;
      }

      if (existingMappingItem) {
        dispatch({
          type: "remove_mapping_row",
          payload: existingMappingItem.uuid,
        });
      }

      const primaryMappingDestinationPropertyIsRequired =
        primaryMapping?.destinationPropertyId &&
        destinationPropertiesObject[primaryMapping.destinationPropertyId]
          ?.isRequired;

      if (primaryMapping && primaryMappingDestinationPropertyIsRequired) {
        dispatch({
          type: "add_mapping_row",
          payload: {
            destinationPropertyId: primaryMapping.destinationPropertyId,
          },
        });
      }

      dispatch({
        type: "change_mapping_destination_property",
        payload: {
          uuid: primaryMapping?.uuid,
          propertyId: item?.propertyId,
        },
      });

      const shouldStealSourceProperty =
        !primaryMapping?.sourcePropertyId &&
        existingMappingItem?.sourcePropertyId;

      if (shouldStealSourceProperty) {
        dispatch({
          type: "change_mapping_source_property",
          payload: {
            uuid: primaryMapping?.uuid,
            propertyId: existingMappingItem?.sourcePropertyId,
          },
        });
      }
    },
    [
      destinationPropertiesObject,
      dispatch,
      primaryMapping,
      sourcePropertiesObject,
      state.mapping,
    ],
  );

  return (
    <div className="space-y-8">
      {(currentOperation?.primaryKeyRequired ||
        destinationPropertyHasPrimaryKey) && (
        <DataBox
          header="Primary key"
          subheader="Select the unique identifier fields to match your record between
            your source and destination."
        >
          <MappingIcons hasDeleteButton={false} />
          <SyncItem.MappingRow fullWidth>
            <SyncItem.Column>
              <Select
                placeholder="Select your source property"
                disabled={!state.queryId}
                value={
                  sourcePropertiesObject[
                    primaryMapping?.sourcePropertyId || -1
                  ] || null
                }
                isLoading={sourcePropertiesLoading}
                labelKey="propertyName"
                badgeKey="propertyType"
                options={unmappedSourceProperties}
                onChange={(item: Property | null) =>
                  dispatch({
                    type: "change_mapping_source_property",
                    payload: {
                      uuid: primaryMapping?.uuid,
                      propertyId: item?.propertyId,
                    },
                  })
                }
              />
            </SyncItem.Column>
            <SyncItem.Arrow />
            <SyncItem.Column>
              <Select
                placeholder="Select your destination property"
                value={
                  destinationPropertiesObject[
                    primaryMapping?.destinationPropertyId || -1
                  ]
                }
                isLoading={destinationPropertiesLoading}
                options={destinationProperties
                  .filter((it) => it.isPrimaryKey)
                  .sort((it) => (it.isRequired ? -1 : 1))}
                labelKey="propertyName"
                badgeKey="propertyType"
                onChange={handleChangePrimaryDestinationProperty}
              />
            </SyncItem.Column>
          </SyncItem.MappingRow>
        </DataBox>
      )}

      <DataBox
        header="Mapping"
        subheader="Choose which of your fields should be matched in your destination."
      >
        <div className="space-y-4">
          <MappingIcons hasDeleteButton />
          <div className="divide-y divide-gray-300 dark:divide-gray-600 lg:space-y-4 lg:divide-y-0">
            {state.mapping
              .filter((item) => !item.isPrimaryMapping)
              .sort((item) => {
                const destinationPropertyIsRequired =
                  item.destinationPropertyId &&
                  destinationPropertiesObject[item.destinationPropertyId]
                    ?.isRequired;
                return destinationPropertyIsRequired ? -1 : 1;
              })
              .map((item) => (
                <MappingItem
                  key={item.uuid}
                  item={item}
                  unmappedSourceProperties={unmappedSourceProperties}
                  unmappedDestinationProperties={unmappedDestinationProperties}
                />
              ))}
          </div>
          <div className="flex justify-between">
            <div className={"flex items-center space-x-4"}>
              <SecondaryButton
                disabled={state.mapping.length >= sourceProperties.length}
                onClick={() => {
                  dispatch({
                    type: "add_mapping_row",
                    payload: { source: "button" },
                  });
                  nextTick(() => dispatch({ type: "added_mapping_row" }));
                }}
              >
                Add new row
              </SecondaryButton>

              <UtilityButton
                disabled={!unmappedSourceProperties.length}
                onClick={handleAddRest}
              >
                Add all {unmappedSourceProperties.length} source properties
              </UtilityButton>
            </div>
            <button
              type="button"
              disabled={!state.queryId || !autoMapablePairs.length}
              onClick={handleAutoMap}
              className={`shrink-0 ${
                !state.queryId || !autoMapablePairs.length
                  ? "cursor-default border-gray-200 bg-gray-100 text-gray-800 opacity-40"
                  : "border-yellow-200 bg-yellow-100 text-yellow-800 transition-all hover:bg-yellow-300"
              } flex items-center space-x-1 rounded border p-2`}
            >
              <SparklesIcon className="h-5 shrink-0" />
              <span className="text-xs uppercase">
                Auto map ({autoMapablePairs.length})
              </span>
            </button>
          </div>
        </div>
      </DataBox>
    </div>
  );
}
