import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { startOfMonthUTC } from "@/utils/date-utils";

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

export type RowCountBasis = "billable-rows" | "total-rows";

const UsageStatsContext = createContext<{
  enableBillableRows: boolean;
  referenceDate: Date;
  rowCountBasis: RowCountBasis;
  setRowCountBasis: (basis: RowCountBasis) => void;
} | null>(null);

export function UsageStatsProvider(
  props: PropsWithChildren<{
    referenceDate?: Date | string;
  }>,
) {
  const { children } = props;

  const parentContext = useContext(UsageStatsContext);
  const referenceDateValue =
    props.referenceDate ?? parentContext?.referenceDate;

  const [selectedRowCountBasis, setSelectedRowCountBasis] =
    useState<RowCountBasis>();

  const context = useMemo(() => {
    const referenceDate = referenceDateValue
      ? new Date(referenceDateValue)
      : new Date();
    const enableBillableRows = isAfterBillableRowsRelease(referenceDate);

    let rowCountBasis: RowCountBasis = "total-rows";
    if (enableBillableRows) {
      rowCountBasis = selectedRowCountBasis ?? "billable-rows";
    }
    return {
      enableBillableRows,
      referenceDate,
      rowCountBasis,
      setRowCountBasis: setSelectedRowCountBasis,
    };
  }, [referenceDateValue, selectedRowCountBasis, setSelectedRowCountBasis]);

  return (
    <UsageStatsContext.Provider value={context}>
      {children}
    </UsageStatsContext.Provider>
  );
}

export function useUsageStatsContext() {
  const ctx = useContext(UsageStatsContext);
  if (ctx === null) {
    throw new Error(
      "useRowCountBasis must be used within a UsageStatsProvider",
    );
  }
  return ctx;
}

export function useBillableRows() {
  return useUsageStatsContext().rowCountBasis === "billable-rows";
}

export function useRowCountSelector() {
  const { rowCountBasis } = useUsageStatsContext();
  const selectRowCount = useCallback(
    function <T extends { numBillableRows: number; numRowsSynced: number }>(
      stats: T,
    ) {
      return rowCountBasis === "billable-rows"
        ? stats.numBillableRows
        : stats.numRowsSynced;
    },
    [rowCountBasis],
  );

  return selectRowCount;
}

function isAfterBillableRowsRelease(referenceDate: Date | string) {
  const date = startOfMonthUTC(new Date(referenceDate));
  const billableRowsReleaseMonth = dayjs.utc(new Date("2023-06-01"));
  return dayjs(date).isSameOrAfter(billableRowsReleaseMonth, "month");
}
