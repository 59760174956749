import { ComponentProps, useState } from "react";

import {
  CurrentUserWithAccountsDocument,
  DeleteAccountFeedback,
  SubscriptionStatus,
  useDeleteAccountMutation,
} from "@/apollo/types";
import { PrimaryButton, SecondaryButton } from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import { TextArea } from "@/components/primitives/input/TextArea";
import { useSubscriptionStatus } from "@/features/subscription";
import { useIntercom } from "@/hooks/useIntercom";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";
import { workspacesRoute } from "@/router";
import { RadioGroup } from "@headlessui/react";
import { useNavigate } from "@tanstack/react-location";

const feedbackOptions = [
  {
    value: DeleteAccountFeedback.TooExpensive,
    label: "It's too expensive",
  },
  {
    value: DeleteAccountFeedback.MissingFeatures,
    label: "Lacking some essential features",
  },
  {
    value: DeleteAccountFeedback.SwitchedService,
    label: "I found a better service",
  },
  {
    value: DeleteAccountFeedback.Unused,
    label: "I don't use the service enough",
  },
  {
    value: DeleteAccountFeedback.CustomerService,
    label: "Customer service was less than expected",
  },
  {
    value: DeleteAccountFeedback.TooComplex,
    label: "Ease of use was less than expected",
  },
  {
    value: DeleteAccountFeedback.LowQuality,
    label: "Too many technical problems",
  },
  {
    value: DeleteAccountFeedback.Other,
    label: "Other reason",
  },
];

export function DeleteAccountDialog(props: ComponentProps<typeof Modal>) {
  const { status } = useSubscriptionStatus();

  if (status === SubscriptionStatus.Active) {
    return <PayingCustomerDialog {...props} />;
  }

  return <TrialingCustomerDialog {...props} />;
}

function TrialingCustomerDialog(props: ComponentProps<typeof Modal>) {
  const currentAccount = useCurrentAccount();

  const [feedback, setFeedback] = useState<DeleteAccountFeedback | null>(null);
  const [comment, setComment] = useState<string>("");

  const toast = useToast();
  const navigate = useNavigate();

  const [deleteAccount, { loading: isDeletingAccount }] =
    useDeleteAccountMutation({
      onError(error: { message: string }) {
        toast(
          `${currentAccount.name ?? "Your account"} was not deleted`,
          error.message,
          "error",
        );
      },
      refetchQueries: [CurrentUserWithAccountsDocument],
      onCompleted() {
        navigate({ to: workspacesRoute() });
      },
    });

  return (
    <Modal {...props}>
      <ModalHeader>Delete account</ModalHeader>
      <ModalCloseButton />
      <ModalBody className="flex flex-col gap-6">
        <div className="flex flex-col gap-2 text-sm">
          <p>
            If confirmed, your account will be deleted immediately.
            Additionally, any active subscriptions will be canceled.
          </p>
          <p>
            We're sorry to see you go! In our continuous effort to enhance our
            services,{" "}
            <span className="font-semibold">
              could you please share more details about your decision to leave?
            </span>{" "}
            Your valuable feedback will help us better understand your needs and
            improve our offerings for the future.
          </p>
        </div>
        <div className="">
          <RadioGroup value={feedback} onChange={setFeedback}>
            <div className="flex flex-col gap-2">
              {feedbackOptions.map((option) => (
                <RadioGroup.Option value={option.value} key={option.value}>
                  {({ checked }) => (
                    <div key={option.value} className="flex items-center gap-2">
                      <input
                        type="radio"
                        value={option.value}
                        checked={checked}
                        className="form-radio"
                        readOnly
                      />
                      <RadioGroup.Label>{option.label}</RadioGroup.Label>
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
        <div>
          <TextArea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Please share any feedback you have"
          />
        </div>
      </ModalBody>
      <ModalFooter className="flex-row-reverse justify-start gap-4">
        <PrimaryButton
          colorScheme="danger"
          onClick={() => {
            deleteAccount({
              variables: {
                input: {
                  id: currentAccount.id,
                  deletionDetails: {
                    feedback,
                    comment,
                  },
                },
              },
            });
          }}
          isDisabled={feedback == null}
          isLoading={isDeletingAccount}
          loadingText="Deleting ..."
        >
          Delete my workspace
        </PrimaryButton>
        <SecondaryButton onClick={() => props.onClose()}>
          Cancel
        </SecondaryButton>
      </ModalFooter>
    </Modal>
  );
}

function PayingCustomerDialog(props: ComponentProps<typeof Modal>) {
  const intercom = useIntercom();
  return (
    <Modal {...props}>
      <ModalHeader>Delete account</ModalHeader>
      <ModalCloseButton />
      <ModalBody className="flex flex-col gap-6">
        It looks like you're currently on a paid plan. To proceed with deleting
        your account, you'll need to cancel your subscription first. Please
        contact our support team via chat to cancel your subscription.
      </ModalBody>
      <ModalFooter className="flex-row-reverse justify-start gap-4">
        <PrimaryButton
          colorScheme="danger"
          onClick={() => {
            intercom.openChat(
              "I want to cancel my subscription and delete my account.",
            );
          }}
        >
          Contact Support
        </PrimaryButton>
        <SecondaryButton onClick={() => props.onClose()}>
          Cancel
        </SecondaryButton>
      </ModalFooter>
    </Modal>
  );
}
