import { PlusIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "@tanstack/react-location";
import { Button } from "@/components/elements/Button";
import TabLayout from "@/components/layouts/TabLayout";
import { UsersLimitReachedAlert } from "@/components/modules/PlanAlerts/UsersLimitReachedAlert";
import { useUsersLimitReachedUpgradeDialog } from "@/features/subscription";
import { useSettingsTabs } from "@/pages/Settings/hooks/useSettingsTabs";
import InvitationTable from "@/pages/Settings/InvitationTable";

export default function InvitationsPage() {
  const tabs = useSettingsTabs();
  const navigate = useNavigate();
  const { validateLimitReached, limitReachedDialog } =
    useUsersLimitReachedUpgradeDialog();
  return (
    <TabLayout
      tabs={tabs}
      action={
        <Button
          as={Link}
          to="./new"
          tabIndex={-1}
          onClick={(e) => {
            e.preventDefault();
            validateLimitReached(() => {
              navigate({
                to: "./new",
              });
            });
          }}
          icon={<PlusIcon className="w-4" />}
          variant="solid"
          colorScheme="primary"
        >
          Invite user
        </Button>
      }
    >
      <>
        <UsersLimitReachedAlert />
        <InvitationTable />
        {limitReachedDialog()}
      </>
    </TabLayout>
  );
}
