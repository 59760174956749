import { remove, update } from "firebase/database";
import { useState } from "react";

import {
  ListModelsQuery,
  useRemoveModelMutation,
  useUpdateDraftModelNameMutation,
} from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import { ButtonCard } from "@/components/elements/ButtonCard";
import confirm from "@/components/elements/Confirm";
import {
  SlideOver,
  SlideOverBody,
  SlideOverCloseButton,
  SlideOverFooter,
  SlideOverHeader,
} from "@/components/elements/SlideOver";
import ModelOptionsSelecter from "@/components/modules/ModelOptionsSelecter";
import {
  useCloseRemovedTab,
  useOpenModelInTab,
} from "@/components/modules/ModelTabs";
import FieldLabel from "@/components/primitives/InputLabel";
import { Input } from "@/components/primitives/input";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useModel } from "@/pages/ModelTool/hooks/useCurrentModel";
import { useSaveUnpublishedModel } from "@/pages/ModelTool/hooks/usePublishModel";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";
import { DocumentChartBarIcon } from "@heroicons/react/24/outline";

import { Tile, VisualizationType } from "../VisualizationType";
import { VisualizeSourceType } from "./AddNewVisualization";
import { PreviewEditedtileVisualization } from "./PreviewEditedtileVisualization";
import { SelectVisualizationType } from "./SelectVisualizationType";
import { useTileDoc } from "./useDashboardDoc";
import { useTileColumnOptions } from "./useTileColumnOptions";
import { useValidateTile } from "./useValidateTile";

type UpdateObject = Record<string, number | string | null | Array<any>>;

type Props = {
  dashboardId?: string;
  modelId?: string;
  tile?: Tile;
  onFinished: () => void;
  isOpen: boolean;
  usePreviewData?: boolean;
  disableChangingModel?: boolean;
};

export const EditVisualizationSidebar = (props: Props) => {
  return (
    <SlideOver show={props.isOpen} onClose={props.onFinished} bgOverlay>
      <SlideOverCloseButton />
      <SlideOverHeader>Edit visualization</SlideOverHeader>
      {props.isOpen && props.tile && (
        <EditVisualization
          onFinished={props.onFinished}
          tile={props.tile}
          dashboardId={props.dashboardId}
          modelId={props.modelId}
          usePreviewData={props.usePreviewData}
          disableChangingModel={props.disableChangingModel}
        />
      )}
    </SlideOver>
  );
};

const EditVisualization = (props: {
  dashboardId?: string;
  modelId?: string;
  tile: Tile;
  onFinished: () => void;
  usePreviewData?: boolean;
  disableChangingModel?: boolean;
}) => {
  const [editedTile, setEditedTile] = useState(props.tile);

  const [newModel, setNewModel] = useState(false);

  const { model: currentModel } = useModel(props.tile.modelId ?? "");

  const openModelInTab = useOpenModelInTab();

  const isValid = useValidateTile(editedTile);

  const mixpanel = useMixpanel();

  const { handleSave, saving: loading } = useSaveEditedTile(
    {
      editedTile,
      original: props.tile,
      dashboardId: props.dashboardId,
      modelId: props.modelId,
      newModel,
    },
    props.onFinished,
  );

  const handleSelectModel = (model: ListModelsQuery["models"][0]) => {
    setEditedTile((p) => {
      return {
        ...p,
        modelId: model.id,
        name: p.name ?? model.name,
      };
    });
  };

  const { handleRemoveTile, removing } = useRemoveTile(
    {
      dashboardId: props.dashboardId,
      tileId: props.tile.i,
      modelId: props.modelId,
    },
    props.onFinished,
  );

  const { columnOptions } = useTileColumnOptions(
    editedTile,
    props.usePreviewData,
  );

  return (
    <>
      <SlideOverBody>
        <div className="flex flex-col space-y-4">
          {!props.disableChangingModel && (
            <div className="">
              <FieldLabel>Base visualization on</FieldLabel>
              {!!currentModel?.dashboardId ? (
                <ButtonCard
                  onClick={() => {
                    openModelInTab({
                      modelId: currentModel.id,
                      type: "model",
                      dashboardId: props.dashboardId,
                      tileId: props.tile.i,
                    });
                  }}
                  className="flex h-20 w-full flex-col items-center space-y-1"
                >
                  <DocumentChartBarIcon className="h-6 w-6" />
                  <span>Custom SQL model</span>
                </ButtonCard>
              ) : (
                <VisualizeSourceType
                  newModel={newModel}
                  setNewModel={setNewModel}
                />
              )}
            </div>
          )}
          {!props.disableChangingModel &&
            !newModel &&
            !currentModel?.dashboardId && (
              <div className="">
                <FieldLabel>Select model</FieldLabel>
                <ModelOptionsSelecter
                  onSelect={handleSelectModel}
                  selectedModelId={editedTile.modelId}
                />
              </div>
            )}

          <div>
            <FieldLabel>Tile Name</FieldLabel>
            <Input
              value={editedTile.name}
              onChange={(e) => {
                setEditedTile((p) => {
                  return {
                    ...p,
                    name: e.target.value,
                  };
                });
              }}
            />
          </div>
          <SelectVisualizationType
            originalTile={props.tile}
            columnOptions={columnOptions}
            setVisualization={(visualization) => {
              setEditedTile((p) => {
                return {
                  ...p,
                  visualization,
                };
              });
            }}
            visualization={editedTile.visualization}
          />
        </div>
      </SlideOverBody>
      <SlideOverFooter>
        <Button
          colorScheme="primary"
          onClick={() => {
            handleSave();
            mixpanel.track("Dashboard Tile Visualization Updated", {
              dashboardId: props.dashboardId,
              tileId: editedTile.i,
              tileName: editedTile.name,
              modelId: editedTile.modelId,
            });
          }}
          isLoading={loading}
          disabled={removing || loading || !isValid}
        >
          {newModel ? "Save and open" : "Save changes"}
        </Button>
        <Button
          isLoading={removing}
          disabled={loading || removing}
          onClick={async () => {
            const confirmed = await confirm({
              message: "Are you sure you want to delete this visualization?",
              title: "Delete visualization",
              type: "danger",
            });
            if (confirmed) {
              handleRemoveTile();
            }
          }}
          variant="outline"
          colorScheme="danger"
        >
          Delete
        </Button>
      </SlideOverFooter>
      <PreviewEditedtileVisualization
        tile={editedTile}
        show={!!editedTile.modelId}
        usePreviewData={props.usePreviewData}
      />
    </>
  );
};

export const useSaveEditedTile = (
  input: {
    editedTile: Tile;
    original: Tile;
    dashboardId?: string;
    modelId?: string;
    newModel?: boolean;
  },
  onFinished?: () => void,
) => {
  const { editedTile, original, dashboardId, modelId, newModel } = input;
  const { model: currentModel } = useModel(original.modelId ?? "");
  const { dataWarehouseConnectionId } = useCurrentAccount();

  const doc = useTileDoc(original.i, dashboardId, modelId);

  const [updateModelName, { loading: updateNameLoading }] =
    useUpdateDraftModelNameMutation();

  const openModelInTab = useOpenModelInTab();

  const { createDraftModel, loading } = useSaveUnpublishedModel();

  const saveNewModel = async () => {
    if (!dataWarehouseConnectionId) return;
    const prevModelRef = currentModel?.dwSync ?? currentModel?.dwTable;
    const sql = prevModelRef ? `select * from {{${prevModelRef.weldTag}}}` : ``;
    const response = await createDraftModel({
      variables: {
        name: editedTile.name,
        weldSql: sql,
        sourceConnectionId: dataWarehouseConnectionId,
        dashboardId: dashboardId,
      },
    });
    return response.data?.createDraftModel?.id;
  };

  const handleSave = async () => {
    if (!doc || loading || !dataWarehouseConnectionId || updateNameLoading)
      return;

    let modelId = editedTile.modelId;

    if (newModel) {
      modelId = await saveNewModel();
    }

    const nameChanged = editedTile.name !== original.name;

    if (!newModel && !!currentModel?.dashboardId && nameChanged) {
      await updateModelName({
        variables: {
          id: currentModel.id,
          name: editedTile.name,
        },
      });
    }

    let updates: UpdateObject = {};
    if (
      editedTile.visualization.type === VisualizationType.Bar ||
      editedTile.visualization.type === VisualizationType.Line
    ) {
      updates[`/visualization/keys/x`] = editedTile.visualization?.keys.x;
      updates[`/visualization/keys/y`] = editedTile.visualization?.keys.y;
    } else {
      updates[`/visualization/keys`] = null;
    }
    if (editedTile.visualization.type === VisualizationType.Metric) {
      updates[`/visualization/metricColumn`] =
        editedTile.visualization?.metricColumn;
      updates[`/visualization/unit`] = editedTile.visualization?.unit;
      updates[`/visualization/unitPostition`] =
        editedTile.visualization?.unitPostition || "suffix";
    } else {
      updates[`/visualization/metricColumn`] = null;
      updates[`/visualization/unit`] = null;
      updates[`/visualization/unitPostition`] = null;
    }
    updates[`/visualization/type`] = editedTile.visualization.type;

    updates[`/name`] = editedTile.name;
    updates[`/modelId`] = modelId ?? null;

    update(doc, updates);

    if (newModel && modelId) {
      openModelInTab({
        modelId,
        type: "model",
        dashboardId,
        tileId: original.i,
      });
    }

    onFinished?.();
  };

  return { handleSave, saving: loading || updateNameLoading };
};

export const useRemoveTile = (
  input: {
    dashboardId?: string;
    tileId: string;
    modelId: string | undefined;
  },
  onFinished?: () => any,
) => {
  const { dashboardId, tileId, modelId } = input;

  const closeRemovedTab = useCloseRemovedTab();
  const toast = useToast();

  const { model } = useModel(modelId ?? "");

  const doc = useTileDoc(tileId, dashboardId, modelId);

  const [removeModel, { loading: removing }] = useRemoveModelMutation({
    onCompleted({ removeModel: { id } }) {
      closeRemovedTab("model", id);
    },
    update(cache, { data }) {
      if (!data) return;
      const { removeModel } = data;
      const normalizedId = cache.identify(removeModel);
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const handleRemoveTile = async () => {
    if (!doc || removing) return;

    //remove the tile
    remove(doc);
    if (!!model?.dashboardId) {
      await removeModel({
        variables: {
          modelId: model.id,
        },
      });
    }

    toast(
      `Visualization removed`,
      `The Visualisation was successfully removed`,
      "success",
    );

    onFinished?.();
  };

  return {
    handleRemoveTile,
    removing,
  };
};
