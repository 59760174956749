import dayjs from "dayjs";
import { useCallback, useMemo } from "react";

export const useTimeFormater = (data: any[], xKey: string) => {
  const timeFormat = useMemo(() => {
    const allTimes = data.map((row) => row[xKey]);

    const firstTime = dayjs(allTimes[0]);
    const nextTime = dayjs(allTimes[1]);
    const lastTime = dayjs(allTimes[allTimes.length - 1]);

    if (!firstTime.isValid() || !nextTime.isValid()) return undefined;

    const interval = Math.abs(nextTime.diff(firstTime, "hours"));
    const totalDiff = Math.abs(lastTime.diff(firstTime, "hours"));

    //Try to catch the case where the user has a column of numbers/strings that look like dates.
    //we can probably do better than this.
    if (firstTime.year() === 1970 && totalDiff < 24) return undefined;

    if (interval < 1 * 24 && totalDiff <= 24) return "HH:mm";
    if (interval < 1 * 24) return "HH:mm D MMM";
    if (interval < 28 * 24) return "D MMM YY";
    if (interval < 364 * 24) return "MMM YY";
    if (interval < 366 * 24) return "YYYY";

    return undefined;
  }, [data, xKey]);

  return useCallback(
    (time: any) => {
      if (!timeFormat) return time;
      const formattedTime = dayjs(time);
      if (!formattedTime.isValid()) return time;
      else return formattedTime.format(timeFormat);
    },
    [timeFormat],
  );
};
