import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import React from "react";
import { useDebouncedCallback } from "use-debounce";

import { calcFlexValues, flexSumInvariant } from "./helpers";
import { PaneState } from "./useReflexAccordion";
import { flexSum } from "./utils";

type RecalibratePaneFlexValuesOptions = {
  containerHeight: number | undefined;
  minPaneSize: number;
  collapsedPaneSize: number;
};

type UseRecalibrateOnContainerResizeOptions =
  RecalibratePaneFlexValuesOptions & {
    state: Record<string, PaneState>;
    onChange: (state: Record<string, PaneState>) => void;
    recalibrateRateMs: number;
  };

export function useRecalibrateOnContainerResize({
  state,
  collapsedPaneSize,
  containerHeight,
  minPaneSize,
  onChange,
  recalibrateRateMs,
}: UseRecalibrateOnContainerResizeOptions) {
  const onChangeRef = useLatestValueRef(onChange);

  const handleRecalibrateFlexValues: typeof recalibratePaneFlexValues =
    React.useCallback(
      (state, options) => {
        const recalibratedState = recalibratePaneFlexValues(state, options);
        if (recalibratedState !== undefined) {
          onChangeRef.current(recalibratedState);
        }
        return recalibratedState;
      },
      [onChangeRef],
    );

  const debouncedRecalibratePaneFlexValues = useDebouncedCallback(
    handleRecalibrateFlexValues,
    recalibrateRateMs,
  );

  React.useEffect(() => {
    // When the window is resized we need to recalibrate the flex values
    // of the absolute values `minPaneSixe` and `collapsedPaneSize` and recalibrate
    // the state.
    if (containerHeight == null) return;
    debouncedRecalibratePaneFlexValues(state, {
      containerHeight,
      minPaneSize,
      collapsedPaneSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerHeight, minPaneSize, collapsedPaneSize]);
}

function recalibratePaneFlexValues(
  state: Record<string, PaneState>,
  options: RecalibratePaneFlexValuesOptions,
) {
  const { collapsedPaneFlex, minPaneFlex } = calcFlexValues(options);

  const newState = { ...state };

  let flexPanes: PaneState[] = [];
  let fixedPaneFlexSum = 0;
  for (const name in newState) {
    const pane = newState[name];
    if (pane.isCollapsed) {
      pane.flex = collapsedPaneFlex;
      fixedPaneFlexSum += collapsedPaneFlex;
    } else if (pane.flex <= minPaneFlex) {
      pane.flex = minPaneFlex;
      fixedPaneFlexSum += minPaneFlex;
    } else {
      flexPanes.push(pane);
    }
  }

  const flexRemainder = 1 - fixedPaneFlexSum;
  const flexPanesFlexSum = flexSum(flexPanes);
  flexPanes.forEach((x) => {
    newState[x.name].flex =
      flexRemainder * (newState[x.name].flex / flexPanesFlexSum);
  });
  flexSumInvariant(Object.values(newState));
  return newState;
}
