import { range } from "lodash";

import { ActionButton } from "@/components/elements/Button";
import IconWithBG from "@/components/elements/IconWithBG";
import { List } from "@/components/elements/List";
import { Skeleton } from "@/components/elements/Skeleton";
import { Heading, TextMuted } from "@/components/elements/Typography";
import { DataSourceIcon } from "@/components/icons/outline";
import { useDataSourceSlideOver } from "@/components/modules/new-data-source-slideover";
import { useRecommendedIntegrationsByBusinessDepartment } from "@/features/connectors";
import { useOnboardingConnectionPreferences } from "@/features/connectors/onboarding-connection-preferences/useOnboardingConnectionPreferences";
import cn from "@/helpers/classNames";
import { IntegrationLogoBox } from "@/integrations";
import { IntegrationType } from "@/integrations";
import { ComponentProps } from "@tw-classed/react";

export function EltSyncsEmptyState() {
  const { onOpen } = useDataSourceSlideOver();
  const { integrations: onboardingConnectionPreferences, loading } =
    useOnboardingConnectionPreferences("EltSourceConnection");

  const { integrations: recommendedIntegrations } =
    useRecommendedIntegrationsByBusinessDepartment();

  let integrations = onboardingConnectionPreferences;
  if (
    !onboardingConnectionPreferences ||
    onboardingConnectionPreferences.length === 0
  ) {
    integrations = recommendedIntegrations;
  }

  return (
    <div className="flex w-full justify-center">
      <div className="flex flex-col items-center gap-6">
        <div className="text-center">
          <Heading className="mb-4 text-xl">
            Connect Your First Data Source
          </Heading>
          <TextMuted className="">
            Start synchronizing your data by connecting a data source.
          </TextMuted>
        </div>
        <div className="flex flex-1 flex-col gap-6">
          {loading ? (
            <div className="divide-y rounded border">
              {range(4).map((i) => (
                <div key={i} className="flex h-14 items-center gap-3 px-4">
                  <div className="flex h-10 w-10 items-center">
                    <Skeleton className="h-4 w-full" />
                  </div>
                  <Skeleton className="h-4 grow" />
                </div>
              ))}
            </div>
          ) : (
            <div className="max-h-[21.4rem] overflow-hidden">
              <DataSourceSuggestionsList
                className="h-full"
                integrations={integrations}
                onSelectItem={(integration) =>
                  onOpen({ integrationId: integration.id })
                }
              />
            </div>
          )}
          <div className="shrink-0">
            <ExploreMoreButton />
          </div>
        </div>
      </div>
    </div>
  );
}

function ExploreMoreButton() {
  const { onOpen } = useDataSourceSlideOver();
  return (
    <ActionButton
      variant="outline"
      colorScheme="secondary"
      onClick={() => onOpen()}
      className="flex w-full gap-3 px-4 shadow"
    >
      <IconWithBG icon={<DataSourceIcon />} size="md" />
      <span>Explore More Data Sources</span>
    </ActionButton>
  );
}

function DataSourceSuggestionsList({
  integrations,
  onSelectItem,
  ...props
}: {
  integrations: IntegrationType[];
  onSelectItem: (integration: IntegrationType) => void;
} & ComponentProps<typeof List>) {
  return (
    <List
      {...props}
      className={cn(
        "w-auto gap-2 divide-y rounded border p-0",
        props.className,
      )}
    >
      {integrations.map((integration) => (
        <List.ItemButton
          key={integration.id}
          className="flex w-full items-center gap-3"
          onClick={() => onSelectItem(integration)}
        >
          <IntegrationLogoBox id={integration.id} size="md" />
          <div className="text-sm">{integration.name}</div>
        </List.ItemButton>
      ))}
    </List>
  );
}
