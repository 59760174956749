import React from "react";

export const BlueTwoEye2 = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 100 100"
    >
      <circle cx="50.5" cy="50.5" r="50.5" fill="#FBFBFC"></circle>
      <mask
        id="mask0_1953_9237"
        style={{ maskType: "alpha" }}
        width="100"
        height="100"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect width="100" height="100" fill="#C4C4C4" rx="2"></rect>
      </mask>
      <g mask="url(#mask0_1953_9237)">
        <ellipse
          cx="55.724"
          cy="55.722"
          fill="#0070F4"
          rx="104.483"
          ry="83.586"
        ></ellipse>
        <path
          fill="#0059C3"
          d="M40 103a3 3 0 01-3 3h-54a3 3 0 01-3-3V35.196a3 3 0 01.918-2.16l27-26.029a3 3 0 014.164 0l27 26.029a3 3 0 01.918 2.16V103z"
        ></path>
        <path
          fill="#0059C3"
          d="M67 103a3 3 0 01-3 3H10a3 3 0 01-3-3V35.196a3 3 0 01.918-2.16l27-26.029a3 3 0 014.164 0l27 26.029a3 3 0 01.918 2.16V103z"
        ></path>
        <path
          fill="#0059C3"
          d="M94 103a3 3 0 01-3 3H37a3 3 0 01-3-3V35.196a3 3 0 01.918-2.16l27-26.029a3 3 0 014.164 0l27 26.029a3 3 0 01.918 2.16V103z"
        ></path>
        <path
          fill="#0059C3"
          d="M121 103a3 3 0 01-3 3H64a3 3 0 01-3-3V35.196a3 3 0 01.918-2.16l27-26.029a3 3 0 014.164 0l27 26.029c.587.565.918 1.345.918 2.16V103z"
        ></path>
      </g>
      <ellipse
        fill="#fff"
        rx="19.884"
        ry="19.864"
        transform="matrix(.99998 .00688 -.00587 .99998 32 51)"
      ></ellipse>
      <circle
        r="10"
        fill="#000"
        transform="rotate(.397 -7413.457 4712.066) skewX(.057)"
      ></circle>
      <ellipse
        fill="#fff"
        rx="19.884"
        ry="19.864"
        transform="matrix(.99998 .00688 -.00587 .99998 70 51)"
      ></ellipse>
      <circle
        r="10"
        fill="#000"
        transform="rotate(.397 -7394.153 10240.574) skewX(.057)"
      ></circle>
    </svg>
  );
};
