import { Notification, useUpdateSourceStreamMutation } from "@/apollo/types";
import { useRetryNotification } from "@/features/notifications";
import { useCallback } from "react";

export const useRemoveProtectionNotification = (options?: {
  onCompleted: (message: string) => void;
  onError: (message: string) => void;
}) => {
  const [updateSourceStream, { loading: loadingUpdate }] =
    useUpdateSourceStreamMutation();
  const { retry, loading: retryLoading } = useRetryNotification({
    onCompleted: () =>
      options?.onCompleted("Protection has been successfully removed"),
    onError: () =>
      options?.onError(
        "Protection has been successfully removed from sync but an error occurred while queueing the sync for retry",
      ),
  });

  const handleRemoveProtection = useCallback(
    (notification: Notification, sourceStreamId: string) => {
      if (loadingUpdate || retryLoading) return;

      void updateSourceStream({
        variables: {
          sourceStreamId: sourceStreamId,
          input: { protectedFromFullSync: false },
        },
        onCompleted: () => {
          retry(notification);
        },
        onError: () =>
          options?.onError(
            "An error occurred while removing the protection from sync",
          ),
      });
    },
    [loadingUpdate, retryLoading, updateSourceStream, retry, options],
  );

  return {
    removeProtection: handleRemoveProtection,
    loading: retryLoading || loadingUpdate,
  };
};
