import ConnectionSettings from "@/components/modules/ConnectionSettings";
import { classNames } from "@/helpers/classNames";
import { Connection, Operation, OperationModes } from "@/apollo/types";
import syncOperationExplainers from "@/helpers/syncOperationExplainers";
import FieldLabel from "@/components/primitives/InputLabel";
import {
  DefaultOptions,
  SyncScheduleRadioGroup,
  WeldSyncScheduler,
} from "@/components/modules/WeldSyncScheduler";
import { useEffect, useState } from "react";
import { useGoogleSheetsConnectionSettings } from "./hooks/useGoogleSheetsConnectionSettings";
import { useGoogleSheetsOperations } from "./hooks/useGoogleSheetsOperations";

export interface GoogleSheetsSettingsState {
  valid: boolean;
  connectionSettings: any;
  operation?: Operation;
  cron: string;
}

export const GoogleSheetsSettings = (props: {
  destination: Connection;
  onChange?: (value: GoogleSheetsSettingsState) => void;
}) => {
  const [currentState, setState] = useState<GoogleSheetsSettingsState>({
    valid: false,
    connectionSettings: {},
    cron: DefaultOptions["1d"].value,
  });

  useEffect(() => {
    if (currentState) {
      props.onChange?.(currentState);
    }
  }, [currentState, props]);

  const connectionSettings = useGoogleSheetsConnectionSettings(
    props.destination.id,
    currentState.connectionSettings,
  );

  const { operations } = useGoogleSheetsOperations({
    connectionId: props.destination.id,
    skip:
      !props.destination.id || !Object.keys(connectionSettings.value).length,
  });

  useEffect(() => {
    const mirrorOperation = operations.find(
      (x) => x.operationMode === OperationModes.Mirror,
    ) as Operation;
    setState((prev) => ({
      ...prev,
      operation: prev.operation ?? mirrorOperation,
    }));
  }, [operations]);

  return (
    <>
      <ConnectionSettings
        onChange={(payload: { key: string; value: any }) =>
          setState((prev) => ({
            ...prev,
            connectionSettings: {
              ...prev.connectionSettings,
              [payload.key]: payload.value,
            },
          }))
        }
        connectionSettings={connectionSettings}
        onChangeValid={(valid) => setState((prev) => ({ ...prev, valid }))}
        initialValues={{}}
      />
      <div className="max-w-2xl">
        <FieldLabel>Operation mode *</FieldLabel>
        <div className="grid grid-cols-2 gap-8">
          {operations.map((item) => (
            <button
              key={item.id}
              className={classNames(
                "flex flex-col items-center justify-start rounded-sm border p-4 transition-colors hover:border-primary dark:hover:border-primary",
                currentState.operation?.operationMode === item.operationMode
                  ? "border-primary"
                  : "border-gray-200 dark:border-gray-700",
              )}
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  operation: item as Operation,
                }))
              }
            >
              <p className="capitalize">{item.operationMode}</p>
              <p>
                <span className="text-xs text-gray-600 dark:text-gray-400">
                  {syncOperationExplainers(item.operationMode)}
                </span>
              </p>
            </button>
          ))}
        </div>
      </div>
      <div>
        <FieldLabel>Sync interval *</FieldLabel>
        <WeldSyncScheduler
          cron={currentState.cron}
          onChange={(cron) => setState((prev) => ({ ...prev, cron }))}
        >
          <SyncScheduleRadioGroup className="grid grid-cols-2 gap-x-4 gap-y-3" />
        </WeldSyncScheduler>
      </div>
    </>
  );
};
