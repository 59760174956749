import { InputHTMLAttributes } from "react";
import { ValidationRule } from "react-hook-form";

export interface DropdownOption<T = string> {
  value: T;
  label: string;
  disabled?: boolean;
  indent?: number;
}

type ServerProps<Type> = {
  asyncProps?: Array<
    keyof Omit<Extract<FormType, { type: Type }>, "serverProps">
  >;
  dependsOnFieldsWithNames?: Array<string>;
};

export type Input = {
  name: string;
  type: "text" | "email" | "number" | "password";
  label: string;
  required: boolean;
  description?: string;
  editable?: boolean;
  inputProps?: Omit<InputHTMLAttributes<HTMLInputElement>, "pattern" | "size">;
  pattern?: ValidationRule<RegExp>;
  value?: string;
} & ServerProps<"text">;

export type SettingsResult = {
  name: string;
  type: "settings-result";
  label: string;
  value: string;
} & ServerProps<"text">;

export type Hidden = {
  name: string;
  type: "hidden";
  inputProps?: Omit<InputHTMLAttributes<HTMLInputElement>, "pattern" | "size">;
  value?: string;
} & ServerProps<"hidden">;

type Dropdown = {
  name: string;
  type: "dropdown";
  placeholder?: string;
  description?: string;
  isMulti?: boolean;
  creatable?: boolean;
  label: string;
  required: boolean;
  value?: DropdownOption;
  dropdownOptions: DropdownOption[];
  disabled?: boolean;
  disableCheckmark?: boolean;
  hideSelectedOptions?: boolean;
} & ServerProps<"dropdown">;

type Checkbox = {
  name: string;
  type: "checkbox";
  label: string;
  required: boolean;
  value?: boolean;
  inputProps?: Omit<InputHTMLAttributes<HTMLInputElement>, "pattern">;
  description?: string;
} & ServerProps<"checkbox">;

type CopyInput = {
  type: "copy";
  value: string;
  editable?: boolean;
  required: boolean;
  name: string;
  label: string;
  description?: string;
} & ServerProps<"copy">;

type BigQuery = {
  name: string;
  type: "big-query-form";
  label: string;
  required: boolean;
  value?: string;
  inputProps?: Omit<InputHTMLAttributes<HTMLInputElement>, "pattern">;
} & ServerProps<"big-query-form">;

export type SubForm = {
  type: "sub_form";
  name: string;
  editable?: boolean;
  required: false;
  label?: string;
  fields: FormType[];
  defaultValues?: object;
  error?: string;
} & ServerProps<"sub_form">;

export type Repeater = {
  type: "repeater";
  name: string;
  label: string;
  singularLabel: string;
  template: SubForm;
  editable: boolean;
  min: number;
  max: number;
  value: SubForm[];
  error: string;
};

type OAuthForm = {
  type: "oauth";
  name?: string;
  label: string;
  fields: FormType[];
  slug?: string;
  defaultValues?: object;
};

type GoogleAnalytics4Form = {
  type: "google-analytics-4-form";
  name: "google-analytics-4-form";
  accounts: {
    displayName: string;
    account: string;
    propertySummaries: {
      displayName: string;
      property: string;
      propertyType: string;
    }[];
  }[];
};

type GoogleAnalyticsForm = {
  type: "google-analytics-form";
  name: "google-analytics-form";
  accounts: {
    name: string;
    id: string;
    webProperties: {
      name: string;
      id: string;
      profiles: {
        name: string;
        id: string;
      }[];
    }[];
  }[];
};

type FacebookAdsForm = {
  type: "facebook-ads-form";
  name: "facebook-ads-form";
  accounts: {
    label: string;
    value: string;
  }[];
};

type GoogleAdsForm = {
  type: "google-ads-form";
  name: "google-ads-form";
  accounts: DropdownOption[];
};

type KlaviyoForm = {
  type: "klaviyo-form";
  name: "klaviyo-form";
};

export type GoogleDriveForm = {
  type: "google-drive-form";
  name: "google-drive-form";
  fileType: DropdownOption[];
  syncMode: DropdownOption[];
};

type OpenWeatherForm = {
  type: "openweather-form";
  name: "openweather-form";
};

type AppStoreConnect = {
  name: string;
  type: "app-store-connect-form";
  label: string;
  required: boolean;
  value?: string;
  inputProps?: Omit<InputHTMLAttributes<HTMLInputElement>, "pattern">;
} & ServerProps<"app-store-connect-form">;

export type FormType =
  | SubForm
  | Repeater
  | OAuthForm
  | Input
  | Hidden
  | Dropdown
  | Checkbox
  | CopyInput
  | BigQuery
  | GoogleAnalyticsForm
  | GoogleAnalytics4Form
  | FacebookAdsForm
  | SettingsResult
  | AppStoreConnect
  | GoogleAdsForm
  | GoogleDriveForm
  | OpenWeatherForm
  | KlaviyoForm;

const isSubForm = (form: FormType): form is SubForm => form.type === "sub_form";

export const hasError = (form: FormType) => isSubForm(form) && !!form.error;
