import { ApolloClient } from "@apollo/client";
import { Navigate, Outlet, Route } from "@tanstack/react-location";
import { ModelBreadcrumb } from "@/components/elements/breadcrumbs/ModelBreadcrumb";
import { WorkflowBreadcrumb } from "@/components/elements/breadcrumbs/WorkflowBreadcrumb";
import { WorkflowRunBreadcrumb } from "@/components/elements/breadcrumbs/WorkflowRunBreadcrumb";
import { FeatureGuardFallbackWithOverlay } from "@/components/elements/FeatureGuard";
import { LocationGenerics } from "routes";

import {
  OrchestrationFeatureGuard,
  OrchestrationTeaser,
} from "@/features/feature-guards";
import HistoricGlobalOrchestrationRun from "./HistoricGlobalOrchestrationRun";
import LocalJobs from "./LocalJobs";
import ModelDetails from "./ModelDetails";
import OrchestrationHistory from "./OrchestrationHistory";
import OrchestrationOverview from "./OrchestrationOverview";
import OrchestrationWorkflow from "./OrchestrationWorkflow";
import SetupWorkflow from "./SetupWorkflow";
import WorkflowSettings from "./WorkflowSettings";
import { OrchestrationWebHooks } from "./Webhook/OrchestrationWebHooks";

export const workflowRoute = (
  client: ApolloClient<object>,
): Route<LocationGenerics> => {
  return {
    path: "orchestration",
    element: (
      <OrchestrationFeatureGuard
        fallback={
          <FeatureGuardFallbackWithOverlay>
            <OrchestrationTeaser />
          </FeatureGuardFallbackWithOverlay>
        }
      >
        <Outlet />
      </OrchestrationFeatureGuard>
    ),
    meta: {
      breadcrumb: () => "Orchestration",
    },
    children: [
      {
        path: "setup",
        element: <SetupWorkflow />,
        meta: {
          breadcrumb: () => "New",
        },
      },

      {
        path: "independent",
        element: <LocalJobs />,
        meta: {
          breadcrumb: () => "Local Jobs",
        },
      },
      {
        path: ":workflowId",
        meta: {
          breadcrumb: ({ workflowId }) => (
            <WorkflowBreadcrumb workflowId={workflowId} />
          ),
        },
        children: [
          {
            path: "history",
            meta: {
              breadcrumb: () => "History",
            },
            children: [
              {
                path: ":workflowRunId",
                element: <HistoricGlobalOrchestrationRun />,
                meta: {
                  breadcrumb: ({ workflowRunId }) => (
                    <WorkflowRunBreadcrumb workflowRunId={workflowRunId} />
                  ),
                },
              },
              { element: <OrchestrationHistory /> },
            ],
          },
          {
            path: "webhooks",
            meta: {
              breadcrumb: () => "Webhooks",
            },
            children: [{ element: <OrchestrationWebHooks /> }],
          },
          {
            path: "settings",
            element: <WorkflowSettings />,
            meta: {
              breadcrumb: () => "Settings",
            },
          },
          {
            path: "model",
            children: [
              {
                path: ":modelId",
                element: <ModelDetails />,
                meta: {
                  breadcrumb: ({ modelId }) => (
                    <ModelBreadcrumb modelId={modelId} />
                  ),
                },
              },
              {
                // TODO: this is a hack to have the <BreadCrumbs /> component work
                // The model path without a :modelId does not exist, but will be added
                // to the breadcrumbs regardless.
                element: <Navigate to=".." replace search />,
              },
            ],
          },
          { element: <OrchestrationWorkflow /> },
        ],
      },
      { element: <OrchestrationOverview /> },
    ],
  };
};
