import {
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarResults,
  KBarSearch,
  useMatches as useKBarMatches,
} from "kbar";
import { PropsWithChildren } from "react";

import LoadingSpinner from "@/components/elements/LoadingSpinner";
import classNames from "@/helpers/classNames";
import LoadingProvider, {
  useLoadingContext,
} from "@/providers/LoadingProvider";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

import useModelToolActions from "./actions/useModelToolActions";
import useSystemActions from "./actions/useSystemActions";

function RenderResults() {
  const { results } = useKBarMatches();
  const { loading } = useLoadingContext();
  return (
    <>
      {loading && (
        <div className="pointer-events-none flex items-center gap-4 px-4 text-gray-500">
          <LoadingSpinner className="h-4" />
          <span>Loading ...</span>
        </div>
      )}
      <KBarResults
        items={results}
        onRender={({ item, active }) =>
          typeof item === "string" ? (
            item ? (
              <div className="px-4 py-2 text-xs uppercase text-gray-500">
                {item}
              </div>
            ) : (
              <div />
            )
          ) : (
            <div
              className={classNames(
                "px-4 py-3",
                active
                  ? "bg-gray-50 dark:bg-gray-800"
                  : "bg-white dark:bg-gray-700",
              )}
            >
              <div className="flex items-center space-x-2">
                {item.icon && <div>{item.icon}</div>}
                <div>
                  <div>
                    <div className="flex items-center space-x-1">
                      {item.parent && (
                        <div className="flex items-center space-x-1 text-muted-foreground">
                          <span>{item.parentActionImpl.name}</span>
                          <ChevronRightIcon className="w-3" />
                        </div>
                      )}

                      <div>{item.name}</div>
                    </div>
                  </div>
                  {item.subtitle && (
                    <div className="text-xs text-gray-400">{item.subtitle}</div>
                  )}
                </div>
              </div>
            </div>
          )
        }
      />
    </>
  );
}

export function KBar(props: PropsWithChildren<{}>) {
  return (
    <KBarProvider options={{ enableHistory: true }}>
      <LoadingProvider>
        <KBarContent {...props} />
      </LoadingProvider>
    </KBarProvider>
  );
}

export function KBarContent(props: PropsWithChildren<{}>) {
  useSystemActions();
  useModelToolActions();
  return (
    <>
      <KBarPortal>
        <KBarPositioner className="z-50">
          <KBarAnimator>
            <div
              className="overflow-hidden rounded-xl bg-white dark:bg-gray-700"
              style={{
                boxShadow: "0px 6px 20px rgb(0 0 0 / 20%)",
              }}
            >
              <KBarSearch className="h-12 w-screen max-w-2xl px-4 focus:outline-none dark:border-gray-800 dark:bg-gray-700" />

              <RenderResults />
            </div>
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      {props.children}
    </>
  );
}
