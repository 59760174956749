import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { useEffect, useRef } from "react";

import { getWeldTagAtPosition } from "./getWeldTagAtPosition";

export function useWeldTagClickHandler(
  editor: monaco.editor.IStandaloneCodeEditor | undefined,
  onClickHandler: (
    e: monaco.editor.IEditorMouseEvent["event"],
    weldTag: NonNullable<ReturnType<typeof getWeldTagAtPosition>>,
  ) => void,
) {
  const onClickHandlerRef = useRef(onClickHandler);

  useEffect(() => {
    if (!editor) return;
    editor.onMouseDown(({ event, target }) => {
      const model = editor.getModel();
      const position = target.position;
      if (model && position) {
        const weldTag = getWeldTagAtPosition(model, position);
        if (weldTag != null) {
          onClickHandlerRef.current(event, weldTag);
        }
      }
    });
  }, [editor]);
}
