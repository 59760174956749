import { Bars3Icon } from "@heroicons/react/24/outline";
import { IconButton } from "@/components/elements/Button";
import { useSidebar } from "@/components/elements/Sidebar";
import WeldLogo from "@/components/elements/Weldlogo";
import classNames from "@/helpers/classNames";
import React from "react";

export default function MobileHeader({
  className,
  ...props
}: React.ComponentProps<"div">) {
  const { toggleSidebar } = useSidebar();
  return (
    <div
      className={classNames(
        "flex items-center gap-4 border-b bg-white py-2 pl-3 pr-4 dark:border-gray-700 dark:bg-gray-800 md:hidden",
        className,
      )}
      {...props}
    >
      <IconButton
        size="sm"
        variant="ghost"
        icon={<Bars3Icon className="h-6 w-6" />}
        onClick={() => toggleSidebar()}
      />
      <div className="ml-auto flex shrink-0 items-center">
        <WeldLogo className="h-3" />
      </div>
    </div>
  );
}
