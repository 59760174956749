import { PlayIcon } from "@heroicons/react/24/solid";
import { useMatch } from "@tanstack/react-location";
import { FindQueryHistoryQuery, QueryDependencyInput } from "@/apollo/types";
import { ReactComponent as EdIcon } from "@/assets/ed.svg";
import {
  Button,
  ButtonGroup,
  IconButton,
  PrimaryButton,
  SecondaryButton,
} from "@/components/elements/Button";
import Tooltip from "@/components/elements/Tooltip";
import FormatIcon from "@/components/icons/FormatIcon";
import HistoryIcon from "@/components/icons/HistoryIcon";
import dayjs from "dayjs";
import { isWindows } from "@/helpers/isWindows";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useToast } from "@/providers/ToastProvider";
import { ComponentPropsWithoutRef, useCallback, useEffect } from "react";
import { LocationGenerics } from "routes";

import { useCurrentModel } from "../hooks/useCurrentModel";
import { useFormatWeldSQL } from "../hooks/useFormatWeldSQL";
import { useFullModelPathRenderer } from "../hooks/useFullPathNames";
import { useQueryHistory } from "../hooks/useQueryHistory";
import { useAssistantSidebar } from "../model-generator/assistant";
import {
  useGetEditorTextState,
  useModelEditorDispatch,
  useModelEditorState,
} from "../ModelEditorStore";
import { usePreview } from "../Preview/usePreview";
import { useGetQueryDependencies } from "../useQueryDependencies";
import { useHistorySidebar } from "./history/HistorySideBar";
import { useUpdateEditorText } from "./monaco/useUpdateEditorText";
import PublishModel from "./PublishModel";
import SaveDashboardModel from "./SaveDashboardModel";
import { SaveModelDraft } from "./SaveModelDraft";
import { useGetSelectedText, useSelectedText } from "./SQLEditor";

export const EditorActionButtons = () => {
  const state = useModelEditorState();
  const isViewingHistory = state.selectedHistoryItem !== -1;
  return isViewingHistory ? (
    <HistoryDiffActionButtons />
  ) : (
    <MainActionButtons />
  );
};

const MainActionButtons = () => {
  const state = useModelEditorState();

  const { aiSqlGenerator: showAIModelCreator } = useFlags();

  const currentModel = useCurrentModel();
  const history = useQueryHistory(currentModel?.id);
  const isEditingDashboardModel = !!currentModel?.dashboardId;
  return (
    <>
      <ButtonGroup size="sm" className="gap-2">
        <FormatSqlButton />
        {showAIModelCreator && <AiSqlAssistantButton />}
        {history.length > 0 && currentModel?.publishedQuery && (
          <ToggleHistoryViewButton history={history} />
        )}
      </ButtonGroup>
      <ButtonGroup size="sm" className="gap-2">
        {!isEditingDashboardModel && <PublishModel />}
        {isEditingDashboardModel && <SaveDashboardModel />}
        {state.currentModelType === "draft" && <SaveModelDraft />}
        <ExecuteSqlButton />
      </ButtonGroup>
    </>
  );
};

function ToggleHistoryViewButton(props: {
  history: FindQueryHistoryQuery["model"]["sqlQueries"];
}) {
  const latestUpdated = dayjs(props.history[0].createdAt);
  const diff = dayjs.duration(latestUpdated.diff(dayjs())).humanize(true);

  const [, setSidebarVisibility] = useHistorySidebar();
  return (
    <Tooltip
      content={<span>View publish history (last published {diff})</span>}
    >
      <IconButton
        variant="ghost"
        colorScheme="secondary"
        icon={<HistoryIcon className="h-5 w-5 text-gray-500" />}
        aria-label="View publish history"
        onClick={() => {
          setSidebarVisibility((value) => !value);
        }}
      />
    </Tooltip>
  );
}

const ExecuteSqlButton = (props: ComponentPropsWithoutRef<typeof Button>) => {
  const handleExecute = useExecuteSql();
  const [selectedText] = useSelectedText();
  const isTextSelection = !!selectedText;

  const mixpanel = useMixpanel();

  return (
    <Tooltip
      content={`Run ${isTextSelection ? "selected text " : ""}(${
        isWindows() ? "Ctrl + ↵" : "⌘ + ↵"
      })`}
    >
      <PrimaryButton
        onClick={() => {
          handleExecute();
          mixpanel.track("Query Executed", {
            action: "button clicked",
          });
        }}
        iconRight={<PlayIcon />}
        data-stonly="editor__run-button"
        className="w-20"
        {...props}
      >
        {isTextSelection ? "Run*" : "Run"}
      </PrimaryButton>
    </Tooltip>
  );
};

const FormatSqlButton = (props: React.ComponentProps<typeof PrimaryButton>) => {
  const toast = useToast();
  const getTextState = useGetEditorTextState();
  const mixpanel = useMixpanel();
  const formatWeldSQL = useFormatWeldSQL({
    onError: (error) => {
      let errorMessage = "An error occurred while formatting your query";
      if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      toast("Formatting error", errorMessage, "error");
    },
  });

  const updateText = useUpdateEditorText();

  const handleFormat = () => {
    const { weldSql } = getTextState();
    const formattedSql = formatWeldSQL(weldSql);
    updateText(formattedSql);
  };

  return (
    <Tooltip
      content={
        <span>
          Format (
          {isWindows() ? (
            <span className="uppercase" style={{ fontSize: "0.7em" }}>
              Ctrl
            </span>
          ) : (
            <span>⌘</span>
          )}
          <span>+ ⇧ + F</span>)
        </span>
      }
    >
      <IconButton
        variant="ghost"
        colorScheme="secondary"
        icon={<FormatIcon className="h-5 w-5 text-gray-500" />}
        aria-label="Format SQL"
        onClick={() => {
          handleFormat();
          mixpanel.track("Model Formatted", {
            action: "button clicked",
          });
        }}
        {...props}
      />
    </Tooltip>
  );
};

export const HistoryDiffActionButtons = (props: {}) => {
  const state = useModelEditorState();
  const dispatch = useModelEditorDispatch();
  const getTextState = useGetEditorTextState();

  const model = useCurrentModel();
  const modelDisplayName = useFullModelPathRenderer(model);

  const preview = usePreview();

  const {
    params: { modelId },
  } = useMatch<LocationGenerics>();

  const queryHistory = useQueryHistory(modelId);
  useEffect(() => {
    const latestHistoryValue = queryHistory[0]?.weldSql || "";
    if (latestHistoryValue !== getTextState().defaultSql) {
      dispatch({
        type: "set_initial_sql",
        payload: latestHistoryValue,
      });
    }
  }, [dispatch, queryHistory, getTextState]);

  const updateText = useUpdateEditorText();

  return (
    <ButtonGroup size="sm" className="gap-2 lg:gap-4">
      <SecondaryButton
        onClick={() => {
          dispatch({
            type: "change_selected_history_item",
            payload: -1,
          });
          const historyValue =
            queryHistory[state.selectedHistoryItem]?.weldSql ?? "";
          updateText(historyValue);
        }}
      >
        Reset draft to this version
      </SecondaryButton>
      <PrimaryButton
        onClick={() => {
          if (!model?.id) return;

          const selectedHistoryItem = queryHistory[state.selectedHistoryItem];
          const dependencies: QueryDependencyInput[] = (
            selectedHistoryItem.dependencies ?? []
          ).map((dependency) => ({
            ...dependency,
            __typename: undefined,
          }));

          const displayName =
            state.currentModelType === "model"
              ? modelDisplayName
              : state.modelName;

          preview({
            sqlQuery: selectedHistoryItem.weldSql,
            dependencies: dependencies,
            modelName: displayName,
            historyDate: new Date(selectedHistoryItem.createdAt),
            modelId: model.id,
            isTextSelection: false,
            modelType: "model",
          });
        }}
        iconRight={<PlayIcon />}
      >
        Run
      </PrimaryButton>
    </ButtonGroup>
  );
};

export const AiSqlAssistantButton = () => {
  const { onToggle, isOpen } = useAssistantSidebar();
  const mixpanel = useMixpanel();
  return (
    <>
      <Tooltip content="Ed, your AI assistant">
        <IconButton
          variant="ghost"
          colorScheme="secondary"
          icon={<EdIcon className="h-5 w-5 text-sky-500" />}
          aria-label="Use AI to generate a SQL model"
          onClick={() => {
            if (!isOpen) {
              mixpanel.track("AI Model Generator Dialog Opened");
            }
            onToggle();
          }}
          data-stonly="ai-assistant-open-button"
        >
          <span className="absolute right-0 top-0 h-2 w-2 -translate-y-1/2 translate-x-1/2 rounded-full bg-sky-500" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export const useExecuteSql = () => {
  const state = useModelEditorState();
  const getTextState = useGetEditorTextState();
  const getQueryDependencies = useGetQueryDependencies();

  const getSelectedText = useGetSelectedText();

  const preview = usePreview();

  const model = useCurrentModel();
  const modelDisplayName = useFullModelPathRenderer(model);

  const handleExecute = useCallback(async () => {
    if (!state.currentModelId) return;

    const displayName =
      state.currentModelType === "model" ? modelDisplayName : state.modelName;

    const selectedText = await getSelectedText();
    return preview({
      sqlQuery: selectedText || getTextState().weldSql,
      dependencies: getQueryDependencies(getTextState().dependencyReferences),
      modelName: displayName,
      modelId: state.currentModelId,
      isTextSelection: !!selectedText,
      modelType: state.currentModelType,
    });
  }, [
    state.currentModelId,
    state.currentModelType,
    state.modelName,
    getQueryDependencies,
    modelDisplayName,
    preview,
    getSelectedText,
    getTextState,
  ]);

  return handleExecute;
};
