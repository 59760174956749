import { Input } from "@/components/primitives/input";
import { InputDescription } from "@/components/primitives/InputDescription";
import { InputError } from "@/components/primitives/InputError";
import { InputErrorIndicator } from "@/components/primitives/InputErrorIndicator";
import FieldLabel from "@/components/primitives/InputLabel";
import { FormType } from "@/integrations";
import { Controller, useFormContext } from "react-hook-form";

export default function FormTextInput({
  field,
}: {
  field: Extract<FormType, { type: "text" | "email" | "number" | "password" }>;
}) {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { name, inputProps, type, required, label, pattern } = field;
  const error = errors?.[name]?.message;

  return (
    <div className="pb-4">
      <FieldLabel id={name} required={required}>
        {label}
      </FieldLabel>

      <div className="relative">
        <Controller
          control={control}
          defaultValue={field.value}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onBlur={onBlur}
              onChange={(v: any) => onChange(v)}
              value={value}
              type={type}
              name={field.name}
              className={!!error ? "pr-12" : ""}
              {...inputProps}
            />
          )}
          rules={{ required, pattern }}
          name={name}
        />

        {!!error && (
          <span className="absolute right-4 top-1/2 w-7 -translate-y-1/2 transform text-green-500">
            <InputErrorIndicator className="ml-2" />
          </span>
        )}
      </div>
      {!!field.description && (
        <InputDescription className="m-1">
          <>{field.description}</>
        </InputDescription>
      )}
      {!!error && <InputError className="mt-1">{String(error)}</InputError>}
    </div>
  );
}
