import { ComponentProps, useEffect, useRef, useState } from "react";

import {
  PlanName,
  useSetMaxBillableRowsMutation,
  useStripePlanProductQuery,
  useWorkspaceSyncStatsQuery,
} from "@/apollo/types";
import {
  Button,
  PrimaryButton,
  SecondaryButton,
} from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import { TextMuted } from "@/components/elements/Typography";
import { LocaleProvider, useLocaleContext, usePrice } from "@/features/billing";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useToast } from "@/providers/ToastProvider";
import { formatCurrency, formatRowCountCompact } from "@/shared/formatters";
import { startOfMonthUTC } from "@/utils/date-utils";

import { getPriceTierFromUsageLimit } from "../lib/getPriceTierFromUsageLimit";
import { RowVolumeSlider } from "./RowVolumeSlider";

function ModalWrapper(
  props: ComponentProps<typeof UsageLimitConfiguratorModal>,
) {
  return (
    <Modal size="lg" {...props}>
      <ModalCloseButton />
      <ModalHeader>Usage limit</ModalHeader>
      <LocaleProvider>
        <UsageLimitConfiguratorModal {...props} />
      </LocaleProvider>
    </Modal>
  );
}

export { ModalWrapper as UsageLimitConfiguratorModal };

function UsageLimitConfiguratorModal(
  props: ComponentProps<typeof Modal> & {
    planName: PlanName;
    initialLimit?: number;
  },
) {
  const mixpanel = useMixpanel();
  const initialValueRef = useRef(props.initialLimit);

  const fromMonthRef = useRef(startOfMonthUTC(new Date()));
  const { data: workspaceSyncStatsData, loading: isLoadingMonthTotal } =
    useWorkspaceSyncStatsQuery({
      variables: {
        fromMonth: fromMonthRef.current,
        toMonth: fromMonthRef.current,
      },
    });

  const toast = useToast();
  const [setMaxBillableRows, { loading: isSavingLimit }] =
    useSetMaxBillableRowsMutation({
      onCompleted: (data) => {
        const limit = data.setMaxBillableRows.accountSettings.maxBillableRows;
        if (limit) {
          toast(
            "Usage limit set",
            `Your usage limit has been set. You will not be charged for more than ${formatRowCountCompact(
              limit,
            )} rows each month.`,
            "success",
          );
        }
      },
    });

  const [clearLimit, { loading: isClearingLimit }] =
    useSetMaxBillableRowsMutation({
      variables: {
        maxRows: null,
      },
      onCompleted: () => {
        toast(
          "Usage limit cleared",
          "You no longer have a limit on the number of rows you can sync each month.",
          "success",
        );
      },
    });

  const saveLimit = async (limit: number) => {
    await setMaxBillableRows({
      variables: {
        maxRows: limit,
      },
    });
  };

  const [limit, setLimit] = useState(initialValueRef.current);
  const { price, isLoading } = usePriceDetails({ planName: props.planName });
  const tiers = (price?.tiers ?? []).filter(
    (
      x,
    ): x is {
      upTo: number;
      price: number;
    } => x.upTo != null && x.price != null,
  );

  useEffect(() => {
    if (tiers.length > 0 && limit == null) {
      const midIndex = Math.floor(tiers.length / 2);
      const midValue = tiers[midIndex].upTo;
      setLimit(midValue ?? 1);
    }
  }, [tiers, limit]);

  const priceTier = getPriceTierFromUsageLimit(limit, price?.tiers ?? []);
  const { currency } = useLocaleContext();

  const currentUsageVolume =
    workspaceSyncStatsData?.workspaceSyncStats.data[0]?.numBillableRows;
  return (
    <>
      <ModalBody>
        <TextMuted as="div" className="text-sm leading-normal">
          Set a cap on your costs by limitting the number of rows your account
          may sync each month. Upon exceeding the set limit, your syncs will be
          paused for the remainder of the month. You can change the limit at any
          time.
        </TextMuted>
        <div className="mt-4 flex justify-center">
          <div className="text-center">
            <TextMuted as="div" className="text-xs">
              Current month total
            </TextMuted>
            <div className="h-8 text-xl font-medium">
              {isLoadingMonthTotal ? (
                <LoadingSpinner />
              ) : currentUsageVolume != null ? (
                <div>{formatRowCountCompact(currentUsageVolume)}</div>
              ) : (
                <span>N/A</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="my-4 w-full max-w-lg px-4">
            {isLoading ? (
              <div className="mt-2 flex h-28 items-center justify-center">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <RowVolumeSlider
                  steps={tiers.map((tier) => tier.upTo)}
                  value={limit ?? 0}
                  onChange={setLimit}
                />
                <div className="mt-4 text-sm">
                  Your price limit will be{" "}
                  <span className="font-semibold">
                    {priceTier?.price != null &&
                      formatCurrency(priceTier.price, {
                        currency,
                      })}
                  </span>{" "}
                  per month
                </div>
              </>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="flex-row-reverse justify-start gap-4">
        <PrimaryButton
          onClick={async () => {
            if (limit != null) {
              await saveLimit(limit);
              mixpanel.track("Usage Limit Set", { limit });
              props.onClose();
            }
          }}
          isDisabled={isClearingLimit || !limit}
          isLoading={isSavingLimit}
          loadingText="Saving ..."
        >
          Save
        </PrimaryButton>
        {initialValueRef.current != null ? (
          <Button
            variant="outline"
            colorScheme="danger"
            onClick={async () => {
              await clearLimit();
              mixpanel.track("Usage Limit Cleared", { limit });
              props.onClose();
            }}
            isDisabled={isSavingLimit}
            isLoading={isClearingLimit}
            loadingText="Clearing ..."
          >
            Clear limit
          </Button>
        ) : (
          <SecondaryButton
            onClick={() => {
              props.onClose();
            }}
            isDisabled={isSavingLimit}
          >
            Cancel
          </SecondaryButton>
        )}
      </ModalFooter>
    </>
  );
}

function usePriceDetails(props: { planName: PlanName }) {
  const { data: productInfo, loading: isLoadingProductInfo } =
    useStripePlanProductQuery({
      variables: {
        planName: props.planName,
      },
    });

  const { currency } = useLocaleContext();
  const { price, loading: isLoadingPrice } = usePrice({
    priceId: productInfo?.getPlanProduct?.price.id,
    currency,
    planName: props.planName,
  });

  return {
    price: price,
    isLoading: isLoadingProductInfo || isLoadingPrice,
  };
}
