import { createEmptyIntegration } from "./helpers";
import { createConnectorOption } from "./useCreateConnectorOptions";

const email = createConnectorOption(
  createEmptyIntegration("email", "Email Forward", ["EltSourceConnection"]),
  { elt: "show", reverseElt: "hide" },
);

const weldSampleData = createConnectorOption(
  createEmptyIntegration("weld-sample-data", "WELD Sample Data", [
    "EltSourceConnection",
  ]),
  { elt: "show", reverseElt: "hide" },
);

const options = [email, weldSampleData];

export const usePseudoConnectorOptions = () => {
  return options;
};
