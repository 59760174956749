import { PropsWithChildren, createContext, useContext, useMemo } from "react";

import {
  PlanName,
  RecurrenceInterval,
  RecurrenceUsageType,
  StripeSubscriptionItemFragment,
  StripeSubscriptionQuery,
  StripeSubscriptionStatus,
  SubscriptionStatus,
  WeldProductType,
  useStripeSubscriptionQuery,
} from "@/apollo/types";
import { useCurrentPlan, useSubscriptionStatus } from "@/features/subscription";
import { useCurrentAccount } from "@/providers/account";

type BillingDetails = {
  subscriptionId?: string;
  customerId?: string;
  planName?: PlanName;
  plan?: StripeSubscriptionItemFragment;
  userPlan?: StripeSubscriptionItemFragment;
  addOns: StripeSubscriptionItemFragment[];
  isAnnualSubscription: boolean;
  isMeteredUsagePlan: boolean;
  isTieredUsagePlan: boolean;
  isEnterprisePlan: boolean;
  status?: StripeSubscriptionStatus;
  trialEndDate?: Date;
  cancelDate?: Date;
  currentPeriodStartDate?: Date;
  currentPeriodEndDate?: Date;
  stripeAccount: StripeSubscriptionQuery["accountBySlug"];
  currency?: string;
  rowLimit?: number;
  canCancelPlan: boolean;
};

type UseBillingDetailsReturn = (
  | {
      loading: true;
      data: undefined;
      error?: never;
    }
  | {
      loading: false;
      data: BillingDetails;
      error?: never;
    }
  | {
      loading: false;
      data: undefined;
      error: string;
    }
) & {
  refetch: () => ReturnType<
    ReturnType<typeof useStripeSubscriptionQuery>["refetch"]
  >;
};

const enterprisePlans: PlanName[] = [
  PlanName.Business,
  PlanName.Enterprise,
  PlanName.Custom,
  PlanName.Growth,
];

const isEnterprisePlan = (planName?: PlanName) =>
  planName != null && enterprisePlans.includes(planName);

export const useBillingDetails = (): UseBillingDetailsReturn => {
  const account = useCurrentAccount();
  const weldPlan = useCurrentPlan();
  const {
    data,
    loading: isLoadingStripeData,
    refetch,
  } = useStripeSubscriptionQuery({
    variables: { slug: account.slug },
  });

  const subscriptionStatus = useSubscriptionStatus();
  const isEnterpriseFromFeatureFlag =
    subscriptionStatus.subscriptionId == null &&
    subscriptionStatus.status === SubscriptionStatus.Active;

  const stripeAccount = data?.accountBySlug;
  return useMemo(() => {
    if (isLoadingStripeData) {
      return {
        loading: true,
        data: undefined,
        refetch,
      };
    }
    if (!stripeAccount) {
      return {
        loading: false,
        data: undefined,
        error: "No stripe account found",
        refetch,
      };
    }

    const details = stripeAccount.stripeSubscription?.stripeSubscriptionDetails;
    const planName = weldPlan.name;

    const plan = details?.items.find(
      (item) => item.price.product.type === WeldProductType.WeldPlan,
    );

    const userPlan = details?.items.find((item) => {
      if (!item.price.lookupKey) return false;
      return Object.values(weldPlan.userPriceLookupKeys ?? {}).includes(
        item.price.lookupKey,
      );
    });

    const addOns =
      details?.items.filter(
        (item) => item.price.product.type === WeldProductType.AddOn,
      ) ?? [];

    const customerCurrency = stripeAccount.stripeCustomer?.currency;

    const isMeteredUsagePlan =
      plan?.price.recurring?.usageType === RecurrenceUsageType.Metered;

    const isTieredUsagePlan = plan?.price.tiers != null;

    const isAnnualSubscription =
      !isMeteredUsagePlan &&
      plan?.price.recurring?.interval === RecurrenceInterval.Year;

    const cancelDate = details?.cancelDate
      ? new Date(details.cancelDate)
      : undefined;

    const canCancelPlan =
      isTieredUsagePlan &&
      cancelDate == null &&
      (details?.status === StripeSubscriptionStatus.Active ||
        details?.status === StripeSubscriptionStatus.Trialing);

    return {
      loading: false,
      data: {
        subscriptionId: details?.id,
        customerId: stripeAccount.stripeCustomer?.id,
        planName,
        plan,
        userPlan,
        addOns,
        isMeteredUsagePlan,
        isTieredUsagePlan,
        isAnnualSubscription,
        isEnterprisePlan:
          isEnterprisePlan(planName) || isEnterpriseFromFeatureFlag,
        status: details?.status,
        trialEndDate:
          details?.status === StripeSubscriptionStatus.Trialing &&
          details?.trialEndDate
            ? new Date(details.trialEndDate)
            : undefined,
        cancelDate,
        canCancelPlan,
        currentPeriodStartDate: details?.currentPeriodStartDate
          ? new Date(details.currentPeriodStartDate)
          : undefined,
        currentPeriodEndDate: details?.currentPeriodEndDate
          ? new Date(details.currentPeriodEndDate)
          : undefined,
        stripeAccount,
        currency: customerCurrency ?? undefined,
        rowLimit: isAnnualSubscription ? plan.quantity : undefined,
      },
      refetch,
    };
  }, [
    isLoadingStripeData,
    stripeAccount,
    refetch,
    isEnterpriseFromFeatureFlag,
    weldPlan,
  ]);
};

const BillingDetailsContext = createContext<NonNullable<BillingDetails> | null>(
  null,
);

export const useBillingDetailsContext = () => {
  const billingDetails = useContext(BillingDetailsContext);
  if (!billingDetails) {
    throw new Error(
      "useBillingDetailsContext must be used within a BillingDetailsProvider",
    );
  }
  return billingDetails;
};

export function BillingDetailsProvider({
  children,
  ...billingDetails
}: PropsWithChildren<ReturnType<typeof useBillingDetails>>) {
  if (!billingDetails.data) {
    return null;
  }
  return (
    <BillingDetailsContext.Provider value={billingDetails.data}>
      {children}
    </BillingDetailsContext.Provider>
  );
}
