import { snakeCase, startCase } from "lodash";
import { Mixpanel, OverridedMixpanel } from "mixpanel-browser";
import React, { useLayoutEffect } from "react";

const PROJECT_TOKEN = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;
const PROXY_DOMAIN = process.env.REACT_APP_MIXPANEL_PROXY_DOMAIN;

declare global {
  interface Window {
    mixpanel: OverridedMixpanel;
  }
}

const mixpanel = window.mixpanel || {};

const noopProxyHandler = {
  get: function (target: any, key: string | symbol): any {
    if (typeof target[key] === "function") {
      return function () {};
    }
    if (typeof target[key] === "object") {
      return new Proxy(target[key], noopProxyHandler);
    }
    return target[key];
  },
};

const noopMixpanel = new Proxy(mixpanel, noopProxyHandler);

export const MixpanelContext = React.createContext<Mixpanel>(noopMixpanel);

function normalizeEventName(event_name: string) {
  return startCase(event_name.trim());
}

type Dict = { [key: string]: any };
function normalizePropertyNames(properties: Dict) {
  return Object.entries(properties).reduce<Dict>((acc, [key, val]) => {
    acc[snakeCase(key.trim())] = val;
    return acc;
  }, {});
}

/**
 * Simple proxy that proxies Mixpanel.track() to normalize property names
 */
const createMixpanelProxy = (instance: Mixpanel) =>
  new Proxy(instance, {
    get(target: typeof instance, prop: keyof typeof instance) {
      if (prop === "track") {
        const trackOverride: typeof instance.track = (
          event_name,
          properties,
          optionsOrCallback,
          callback,
        ) => {
          target[prop](
            normalizeEventName(event_name),
            properties == null
              ? properties
              : normalizePropertyNames(properties),
            optionsOrCallback,
            callback,
          );
        };
        return trackOverride;
      }
      return target[prop];
    },
  });

export function MixpanelProvider({ children }: { children: React.ReactNode }) {
  const [instance, setInstance] = React.useState<Mixpanel>(noopMixpanel);

  useLayoutEffect(() => {
    if (!(PROJECT_TOKEN && PROXY_DOMAIN)) return;
    mixpanel.init(PROJECT_TOKEN, {
      api_host: PROXY_DOMAIN,
      debug: process.env.NODE_ENV !== "production",
      loaded: function (mixpanel) {
        unregisterObsoleteProperties(mixpanel);
        const releaseId = process.env.RELEASE_ID;
        if (releaseId) {
          mixpanel.register({ release_id: releaseId });
        }
        setInstance(createMixpanelProxy(mixpanel));
      },
      // Ignore the web browser's Do Not Track setting
      ignore_dnt: true,
    });
  }, []);
  return (
    <MixpanelContext.Provider value={instance}>
      {children}
    </MixpanelContext.Provider>
  );
}

export function useMixpanel() {
  return React.useContext(MixpanelContext);
}

/**
 * Unregister a few super properties that are no longer used.
 * Registered properties are persisted in cookie/localStorage and
 * will keep being sent to Mixpanel until they are unregistered.
 */
function unregisterObsoleteProperties(mixpanel: Mixpanel) {
  mixpanel.unregister("releaseId");
}
