import dayjs from "dayjs";
import { useMemo, useState } from "react";

import {
  ConnectionDocument,
  ListSyncsDocument,
  RemoveSyncMutationVariables,
  useRemoveSyncMutation,
  useRunRevEtlSyncNowMutation,
  useStartSyncMutation,
  useStopSyncMutation,
} from "@/apollo/types";
import ConfirmDeleteModal from "@/components/elements/ConfirmDeleteModal";
import MenuItem from "@/components/elements/MenuItem";
import TableMenu from "@/components/modules/TableMenu";
import useDeleteItem from "@/hooks/useDeleteItem";
import usePrevious from "@/hooks/usePrevious";
import { useToast } from "@/providers/ToastProvider";

import { RowData } from "../columns";

export const SyncMenuCell = ({ sync }: { sync: RowData }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleDelete = useDeleteItem<RemoveSyncMutationVariables>({
    title: "sync",
    variables: { syncId: sync.id },
    mutation: useRemoveSyncMutation,
    refetchQueries: [
      { query: ListSyncsDocument },
      {
        query: ConnectionDocument,
        variables: { id: sync.destinationConnection?.id ?? "" },
      },
    ],
  });

  const [runSyncNow] = useRunRevEtlSyncNowMutation({
    refetchQueries: [ListSyncsDocument],
    variables: {
      syncId: sync.id,
    },
  });

  const canRunJobNow = useMemo(() => {
    const job = sync.job;
    if (!job?.nextRun) {
      return false;
    }
    const nextRun = dayjs(job.nextRun);
    const jobIsRunning = job.running;
    const jobIsStarting = dayjs().isAfter(nextRun);

    const isNotStopped = sync.status !== "STOPPED";
    const isQuarantined = job.isQuarantined;

    return (!jobIsStarting || isQuarantined) && isNotStopped && !jobIsRunning;
  }, [sync]);

  return (
    <>
      <ConfirmDeleteModal
        title="sync"
        onConfirm={handleDelete}
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
      />
      <TableMenu>
        <ToggleSyncMenuItem syncId={sync.id} active={sync.active} />
        {canRunJobNow && (
          <MenuItem text="Run sync now" onClick={() => runSyncNow()} />
        )}
        <div className="mx-4 my-1 border-b" />
        <MenuItem
          danger={true}
          text="Delete"
          onClick={() => setShowConfirmModal(true)}
        />
      </TableMenu>
    </>
  );
};

const ToggleSyncMenuItem = ({
  syncId,
  active,
}: {
  syncId: string;
  active: boolean;
}) => {
  const toast = useToast();

  const prevActive = usePrevious(active);

  const useMutation = active ? useStopSyncMutation : useStartSyncMutation;
  const [toggleSync] = useMutation({
    onError(error) {
      toast(
        `Sync not ${active ? "stopped" : "started"}`,
        error.message,
        "error",
      );
    },
    onCompleted() {
      toast(
        `Sync ${prevActive ? "stopped" : "started"}`,
        `Your sync has been succesfully ${prevActive ? "stopped" : "started"}.`,
        "success",
      );
    },
    refetchQueries: [{ query: ListSyncsDocument, variables: { syncId } }],
  });

  const onToggleSync = (e: any) => {
    e.stopPropagation();
    toggleSync({
      variables: { syncId },
    });
  };

  return <MenuItem text={active ? "Stop" : "Start"} onClick={onToggleSync} />;
};
