import { XMarkIcon } from "@heroicons/react/24/outline";
import { FindQueryHistoryQuery } from "@/apollo/types";
import { IconButton } from "@/components/elements/Button";
import dayjs from "dayjs";
import classNames from "@/helpers/classNames";
import { atom, useAtom } from "jotai";
import { useCallback } from "react";

import { useCurrentModel } from "../../hooks/useCurrentModel";
import { SqlHistoryItem } from "../../hooks/useQueryHistory";
import NewEntryButton from "../NewEntryButton";
import HistoryButton from "./HistoryButton";

const HistoryOpenAtom = atom(false);

export function useHistorySidebar() {
  return useAtom(HistoryOpenAtom);
}

export default function HistorySideBar(props: {
  onSelect: (historyItemIndex: number) => any;
  selected: number;
  unsavedChanges: boolean;
  history: FindQueryHistoryQuery["model"]["sqlQueries"] | undefined;
}) {
  const currentModel = useCurrentModel();

  const [historyOpen, setHistoryOpen] = useAtom(HistoryOpenAtom);

  const handleSelect = useCallback(
    (entry: number) => {
      props.onSelect(entry);
    },
    [props],
  );

  if (!props.history?.length || !currentModel?.publishedQuery) return null;

  return (
    <div
      className={classNames(
        `relative flex h-full shrink-0 flex-col overflow-hidden border-l border-t transition-all dark:border-gray-700`,
        historyOpen ? "w-80" : "w-0 border-l-0",
      )}
    >
      <div
        className={`flex items-center justify-between px-2 py-2 text-xs font-semibold`}
      >
        <span className="ml-2 leading-5 dark:text-gray-200">History</span>
        <IconButton
          onClick={() => {
            handleSelect(-1);
            setHistoryOpen(false);
          }}
          variant="ghost"
          icon={<XMarkIcon className="h-5 w-5" />}
          size="sm"
        >
          <span className="sr-only">Close panel</span>
        </IconButton>
      </div>
      <div className="relative w-80 grow">
        <div className="absolute inset-0 overflow-y-auto">
          <nav aria-label="Progress">
            <ol className="overflow-hidden">
              {props.unsavedChanges && (
                <NewEntryButton
                  open={historyOpen}
                  onClick={() => handleSelect(-1)}
                  selected={props.selected === -1}
                />
              )}
              {[...props.history]
                .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
                .map((entry, index, array) => (
                  <HistoryButton
                    open={historyOpen}
                    key={entry.id}
                    index={index}
                    model={currentModel}
                    historyItem={entry as SqlHistoryItem}
                    isLast={index === array.length - 1}
                    selected={
                      props.selected === -1
                        ? !props.unsavedChanges
                          ? index === 0
                          : false
                        : props.selected === index
                    }
                    onClick={() =>
                      handleSelect(
                        !props.unsavedChanges && index === 0 ? -1 : index,
                      )
                    }
                  />
                ))}
            </ol>
          </nav>
        </div>
      </div>
    </div>
  );
}
