import { startCase } from "lodash";

import { FullSyncTrigger, OperationMode, SyncJobStatus } from "@/apollo/types";

export function getSyncJobColorProps<
  T extends {
    status?: SyncJobStatus;
    operationMode?: OperationMode;
    fullSyncTrigger?: FullSyncTrigger;
  },
>(params: T) {
  switch (params.status) {
    case SyncJobStatus.Scheduled:
    case SyncJobStatus.Running:
      return { fill: "#60a5fa" };
    case SyncJobStatus.Completed: {
      if (
        params.operationMode === OperationMode.Full &&
        params.fullSyncTrigger !== FullSyncTrigger.Lookback
      ) {
        return { fill: "#eda92b" };
      }
      return { fill: "#2ba67f" };
    }
    case SyncJobStatus.Failed:
      return { fill: "#f87171" };
    case SyncJobStatus.Retrying:
      return { fill: "#facc15" };
    case SyncJobStatus.Delayed:
      return { fill: "#d4d4d8" };
    default:
      return { fill: "#d1d5db" };
  }
}

export function formatSyncStatus(status: SyncJobStatus) {
  return startCase(status.toLowerCase());
}

export function formatOperationMode(
  mode: OperationMode,
  fullSyncTrigger?: FullSyncTrigger | null,
) {
  if (fullSyncTrigger === FullSyncTrigger.Lookback) {
    return startCase(OperationMode.Incremental.toLowerCase());
  }
  return startCase(mode.toLowerCase());
}

export function formatFullSyncTrigger(trigger: FullSyncTrigger) {
  switch (trigger) {
    case FullSyncTrigger.AlwaysFull: {
      return "Always";
    }
    case FullSyncTrigger.Error: {
      return "Error";
    }
    case FullSyncTrigger.InitialSync: {
      return "Initial sync";
    }
    case FullSyncTrigger.Lookback: {
      return "Lookback";
    }
    case FullSyncTrigger.Midnight: {
      return "At midnight (UTC)";
    }
    case FullSyncTrigger.Migration: {
      return "Data warehouse migration";
    }
    case FullSyncTrigger.SchemaChange: {
      return "Schema change";
    }
    case FullSyncTrigger.User: {
      return "Initiated by user";
    }
    default: {
      return "";
    }
  }
}
