import { useMemo, useReducer } from "react";
import { SyncContext } from "./SyncContext";
import syncReducer, {
  SyncActionType,
  SyncStateType,
  initialState,
} from "./syncReducer";

export default function SyncStateProvider(props: { children: JSX.Element }) {
  const [state, dispatch]: [SyncStateType, any] = useReducer(
    syncReducer,
    initialState,
  );
  const syncContext: [SyncStateType, (action: SyncActionType) => void] =
    useMemo(() => [state, dispatch], [state, dispatch]);
  return (
    <SyncContext.Provider value={syncContext}>
      {props.children}
    </SyncContext.Provider>
  );
}
