import {
  MaterialisationStatus,
  WorkflowJobStatus,
  WorkflowRunStatus,
} from "@/apollo/types";

export const getJobStatusColor = (
  status: WorkflowJobStatus,
  type: "bg" | "text",
) => {
  switch (status) {
    case WorkflowJobStatus.Done:
      return type === "bg"
        ? "bg-green-500 dark:bg-green-500"
        : "text-green-500 dark:text-green-500";
    case WorkflowJobStatus.Failed:
      return type === "bg"
        ? "bg-red-500 dark:bg-red-500"
        : "text-red-500 dark:text-red-500";
    case WorkflowJobStatus.Running:
    case WorkflowJobStatus.NotReady:
      return type === "bg" ? "" : "text-gray-800 dark:text-gray-400";
    case WorkflowJobStatus.Ready:
      return type === "bg"
        ? "bg-gray-200 dark:bg-gray-600"
        : "text-gray-800 dark:text-gray-200";
  }
};

export const getMaterializationStatusColor = (
  status: MaterialisationStatus,
  type: "bg" | "text",
) => {
  switch (status) {
    case MaterialisationStatus.Succeeded:
      return type === "bg"
        ? "bg-green-500 dark:bg-green-500"
        : "text-green-500 dark:text-green-500";
    case MaterialisationStatus.Failed:
      return type === "bg"
        ? "bg-red-500 dark:bg-red-500"
        : "text-red-500 dark:text-red-500";
    case MaterialisationStatus.Running:
      return type === "bg"
        ? "bg-gray-200 dark:bg-gray-600"
        : "text-gray-800 dark:text-white";
  }
};

export const getWorkflowStatusColor = (
  status: WorkflowRunStatus,
  type: "bg" | "text",
) => {
  switch (status) {
    case WorkflowRunStatus.Running:
    case WorkflowRunStatus.NotStarted:
    case WorkflowRunStatus.Starting:
      return type === "bg" ? "bg-blue-500" : "text-blue-500 dark:text-blue-500";
    case WorkflowRunStatus.Failed:
      return type === "bg"
        ? "bg-red-500 dark:bg-red-500"
        : "text-red-500 dark:text-red-500";
    case WorkflowRunStatus.Done:
      return type === "bg"
        ? "bg-green-400 dark:bg-green-600"
        : "text-green-400 dark:text-white";
  }
};
