import { DependencyType } from "@/apollo/types";
import { uniqBy } from "lodash";
import { useQueryDependencies } from "@/pages/ModelTool/useQueryDependencies";
import React from "react";

import { useListPublishedModels } from "../../hooks/useListModels";
import { useRawViews } from "../../useRawViews";

export type DependencyInfo = {
  id: string;
  weldTag: string;
  path: string[];
  type: Omit<DependencyType, DependencyType.MissingReference>;
};

export function useDependencyInfo() {
  const modelDependencies = useQueryDependencies();
  const { rawViews } = useRawViews();
  const publishedModelsQuery = useListPublishedModels();

  return React.useMemo(() => {
    return uniqBy(modelDependencies, (x) => x.dwItemId).reduce<
      DependencyInfo[]
    >((acc, dep) => {
      if (dep.type === DependencyType.RawView) {
        const rawView = rawViews.find((x) => x.viewId === dep.dwItemId);
        if (!rawView) return acc;
        acc.push({
          id: dep.dwItemId,
          weldTag: dep.weldTag,
          path: rawView.path,
          type: DependencyType.RawView,
        });
      }
      if (
        dep.type === DependencyType.ModelView ||
        dep.type === DependencyType.MaterializedTable
      ) {
        const model = publishedModelsQuery.publishedModels.find(
          (m) => m.id === dep.dwItemId,
        );
        if (model == null) return acc;

        const path = model.dwSync?.path ?? model.dwTable?.path;
        if (path == null) return acc;
        acc.push({
          id: dep.dwItemId,
          weldTag: dep.weldTag,
          path,
          type: DependencyType.RawView,
        });
      }
      return acc;
    }, []);
  }, [modelDependencies, rawViews, publishedModelsQuery.publishedModels]);
}
