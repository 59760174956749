import { FieldError } from "react-hook-form";

const ValidationMessages: Record<string, string> = {
  invalidCharacters: "Name can only contain underscores and letters",
  noSpecialCharacters: "Can not contain special characters",
  hasNoDoubleUnderscore: "Cannot have double underscores",
  required: "This field is required",
};

export const ValidationErrorDisplay = (props: { error: FieldError }) => {
  const errorMsg = ValidationMessages[props.error.type];

  return (
    <div className="text-xs text-red-500">{errorMsg || props.error.type}</div>
  );
};
