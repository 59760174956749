import {
  FindOneSyncQuery,
  OperationModes,
  OrchestrationSchedulerType,
} from "@/apollo/types";
import { v4 as uuid } from "uuid";

export type MappingType = {
  uuid: string;
  isPrimaryMapping?: boolean;
  sourcePropertyId?: string;
  destinationPropertyId?: string;
};

export type SyncStateType = {
  queryId: string;
  destinationId: string;
  sourceId: string;
  destinationSettings: Record<string, any>;
  objectTypeName: string;
  operation: { id: string; mode: OperationModes } | null;
  scheduleKey?: string;
  mapping: MappingType[];
  active: boolean;
  autoFocus?: boolean;
  config?: FindOneSyncQuery["findOneSync"]["config"];
  refreshingDestinationProperties: boolean;
  schedulerType: OrchestrationSchedulerType;
  orchestrationWorkflowId?: string;
};

export type SyncActionType =
  | {
      type: "initialize";
      payload: SyncStateType;
    }
  | {
      type: "stop_sync";
    }
  | { type: "initialize_mapping"; payload: MappingType[] }
  | { type: "change_query"; payload: string }
  | { type: "change_destination"; payload: string }
  | {
      type: "change_destination_settings";
      payload: { key: string; value: any };
    }
  | { type: "change_object_type"; payload: string }
  | {
      type: "change_operation";
      payload: { id: string; mode: OperationModes } | null;
    }
  | { type: "change_schedule"; payload: string }
  | {
      type: "add_mapping_row";
      payload: Omit<MappingType, "uuid"> & { source?: string };
    }
  | { type: "added_mapping_row" }
  | { type: "remove_mapping_row"; payload: string }
  | {
      type: "change_mapping_source_property";
      payload: { propertyId?: string; uuid?: string };
    }
  | {
      type: "change_mapping_destination_property";
      payload: { propertyId?: string; uuid?: string };
    }
  | { type: "refresh_destination_properties" }
  | { type: "refreshed_destination_properties" }
  | {
      type: "update_scheduler_type";
      payload: {
        schedulerType: OrchestrationSchedulerType;
        orchestrationWorkflowId?: string;
      };
    };
export const initialState: SyncStateType = {
  queryId: "",
  destinationId: "",
  sourceId: "",
  destinationSettings: {},
  objectTypeName: "",
  operation: null,
  scheduleKey: "0 0 * * *",
  config: {},
  mapping: [
    {
      uuid: uuid(),
      isPrimaryMapping: true,
      sourcePropertyId: "",
      destinationPropertyId: "",
    },
  ],
  active: false,
  refreshingDestinationProperties: false,
  schedulerType: OrchestrationSchedulerType.Local,
};

export const initSyncState = ({
  findOneSync: sync,
}: FindOneSyncQuery): SyncStateType => {
  return {
    ...initialState,
    operation: {
      id: sync.operations[0].availableOperationId,
      mode: sync.operations[0].operationMode,
    },
    mapping: sync.mappings.map(mapProperties),
    scheduleKey: sync.syncInterval ?? undefined,
    objectTypeName: sync.primaryObject,
    destinationId: sync.destinationConnectionId,
    sourceId: sync.sourceConnectionId,
    queryId: sync.model?.id || "",
    active: sync.active,
    config: sync.config,
    destinationSettings: sync.config?.destinationSettings,
    schedulerType: sync.schedulerType,
    orchestrationWorkflowId: sync.orchestrationWorkflow?.id ?? undefined,
  };
};

const mapProperties = (item: {
  primaryKey: boolean;
  sourcePropertyId: string;
  destinationPropertyId: string;
}) => ({
  isPrimaryMapping: item.primaryKey,
  uuid: uuid(),
  sourcePropertyId: item.sourcePropertyId,
  destinationPropertyId: item.destinationPropertyId,
});

const reducer = (
  state: SyncStateType,
  action: SyncActionType,
): SyncStateType => {
  switch (action.type) {
    case "initialize":
      return action.payload;
    case "initialize_mapping":
      const initialMapping: MappingType[] = action.payload;
      if (!initialMapping?.some((item: MappingType) => item.isPrimaryMapping)) {
        initialMapping.push({
          uuid: uuid(),
          isPrimaryMapping: true,
          sourcePropertyId: "",
          destinationPropertyId: "",
        });
      }
      return {
        ...state,
        mapping: initialMapping,
      };
    case "stop_sync":
      return {
        ...state,
        active: false,
      };
    case "change_query": {
      const idDidNotChange = action.payload === state.queryId;
      if (idDidNotChange) return state;
      return {
        ...state,
        queryId: action.payload,

        mapping: state.mapping
          .filter((m) => m.isPrimaryMapping)
          .map((m) => ({ ...m, sourcePropertyId: "" })),
      };
    }
    case "change_destination": {
      const idDidNotChange = action.payload === state.destinationId;
      if (idDidNotChange) return state;
      return {
        ...state,
        destinationId: action.payload,
        objectTypeName: "",
        operation: null,
        destinationSettings: {},

        mapping: state.mapping
          .filter((m) => m.isPrimaryMapping)
          .map((m) => ({
            ...m,
            destinationPropertyId: "",
          })),
      };
    }
    case "change_destination_settings": {
      return {
        ...state,
        destinationSettings: {
          ...state.destinationSettings,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case "change_object_type": {
      const nameDidNotChange = action.payload === state.objectTypeName;
      if (nameDidNotChange) return state;
      return {
        ...state,
        objectTypeName: action.payload,
        operation: null,

        mapping: state.mapping
          .filter((m) => m.isPrimaryMapping)
          .map((m) => ({
            ...m,
            destinationPropertyId: "",
          })),
      };
    }
    case "change_operation": {
      const idDidNotChange = action.payload?.id === state.operation?.id;
      if (idDidNotChange) return state;

      const oldOperationIsUpdateUpsert =
        state.operation?.mode === OperationModes.Upsert ||
        state.operation?.mode === OperationModes.Update;
      const newOperationIsUpdateUpsert =
        action.payload?.mode === OperationModes.Upsert ||
        action.payload?.mode === OperationModes.Update;

      if (oldOperationIsUpdateUpsert && newOperationIsUpdateUpsert)
        return {
          ...state,
          operation: action.payload,
        };

      return {
        ...state,
        operation: action.payload,
        mapping: state.mapping.map((item) =>
          item.isPrimaryMapping
            ? { ...item, sourcePropertyId: "", destinationPropertyId: "" }
            : item,
        ),
      };
    }
    case "change_schedule":
      return { ...state, scheduleKey: action.payload };
    case "add_mapping_row":
      return {
        ...state,
        autoFocus: action.payload?.source === "button",
        mapping: [
          ...state.mapping,
          {
            sourcePropertyId: action.payload?.sourcePropertyId || "",
            destinationPropertyId: action.payload?.destinationPropertyId || "",
            uuid: uuid(),
          },
        ],
      };
    case "added_mapping_row":
      return {
        ...state,
        autoFocus: false,
      };
    case "remove_mapping_row":
      return {
        ...state,
        mapping: state.mapping.filter(({ uuid }) => uuid !== action.payload),
      };
    case "change_mapping_source_property":
      return {
        ...state,
        mapping: state.mapping.map((row) => {
          return row.uuid !== action.payload.uuid
            ? row
            : { ...row, sourcePropertyId: action.payload.propertyId };
        }),
      };
    case "change_mapping_destination_property": {
      return {
        ...state,
        mapping: state.mapping.map((mappingItem) =>
          mappingItem.uuid !== action.payload.uuid
            ? mappingItem
            : {
                ...mappingItem,
                destinationPropertyId: action.payload.propertyId,
              },
        ),
      };
    }

    case "refresh_destination_properties":
      return { ...state, refreshingDestinationProperties: true };
    case "refreshed_destination_properties":
      return { ...state, refreshingDestinationProperties: false };
    case "update_scheduler_type": {
      return {
        ...state,
        schedulerType: action.payload.schedulerType,
        orchestrationWorkflowId: action.payload.orchestrationWorkflowId,
      };
    }
    default:
      return state;
  }
};

export default reducer;
