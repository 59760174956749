import { useMatch, useNavigate } from "@tanstack/react-location";
import {
  ButtonGroup,
  PrimaryButton,
  SecondaryButton,
} from "components/elements/Button";
import { useDisclosure } from "hooks/useDisclosure";
import { LocationGenerics } from "routes";

import {
  ListWebhooksDocument,
  ListWebhooksQuery,
  useDeleteWebhookMutation,
  useListWebhooksQuery,
} from "@/apollo/types";
import DefaultTable from "@/components/elements/DefaultTable";
import { useListOrchestrationWorkflows } from "@/hooks/useListOrchestrationWorkflows";
import dayjs from "dayjs";
import React from "react";
import { CellProps, Column } from "react-table";
import {
  EtlJobBox,
  ModelJobBox,
  ReverseEtlJobBox,
} from "../components/WorkflowTable/SharedComponents";
import {
  OrchestrationJob,
  Row,
  useTableRows,
} from "../components/WorkflowTable/useTableRows";
import { OrchestrationTabsLayout } from "../OrchestrationTabsLayout";
import { CreateWebhookModal } from "./CreateWebhookModal";
import { useToast } from "@/providers/ToastProvider";
import { FormatTime } from "@/components/elements/FormatTime";
import { useCurrentAccount } from "@/providers/account";
import { connectExternalTool } from "@/router";

type Props = {};
type ListWebhooksRow = ListWebhooksQuery["listWebhoooks"][0];
export type SelectableJob = {
  label: string | null | undefined;
  id: string;
  type: "model" | "elt-sync" | "elt-source-stream" | "reverse-elt-sync";
  icon: JSX.Element | null;
  name: string;
};

export const mapJobsToSelectableJobs = (
  tableRows: Row[],
): (SelectableJob | null)[] => {
  return tableRows
    .filter((tr) => tr.type !== "webhook")
    .map((tr) => {
      if (tr.type === "model" && tr.model)
        return {
          label:
            tr.model.name ??
            dayjs(tr.model.createdAt).format("YYYY/MM/DD HH:mm"),
          id: tr.model.id,
          type: tr.type,
          icon: ModelJobBox({
            materializationType: tr.model.materializationType,
            tooltipContent: tr.model.name,
          }),
        } as SelectableJob;
      else if (tr.type === "elt-sync")
        return {
          label: tr.sync.destinationSchemaName,
          id: tr.sync.id,
          type: tr.type,
          icon: (
            <EtlJobBox
              tooltipContent={`${tr.sync.destinationSchemaName} (${tr.jobs.length} tables)`}
            />
          ),
        } as SelectableJob;
      else if (tr.type === "reverse-elt-sync")
        return {
          label: tr.sync.destinationIntegrationId,
          id: tr.sync.id,
          type: tr.type,
          icon: (
            <ReverseEtlJobBox
              tooltipContent={`${tr.sync.destinationIntegrationId} - ${tr.sync?.destinationConnection.label}`}
            />
          ),
        } as SelectableJob;
      return null;
    });
};

export const OrchestrationWebHooks = (props: Props) => {
  const account = useCurrentAccount();
  const navigate = useNavigate();
  const match = useMatch<LocationGenerics>();
  const orchestrationWorkflowId = match.params.workflowId;

  const createTriggerModalDisclosure = useDisclosure();
  const { data: externalWebhooks, loading: externalWebhooksLoading } =
    useListWebhooksQuery({
      variables: { orchestrationWorkflowId: orchestrationWorkflowId },
    });

  // Get table rows
  const { workflows, loading: loadingWorkflows } =
    useListOrchestrationWorkflows();
  const workflow = workflows.find((w) => w.id === orchestrationWorkflowId);
  const jobs: OrchestrationJob[] = (workflow?.currentRun?.jobs ??
    []) as OrchestrationJob[];
  const tableRows = useTableRows({
    workflowId: orchestrationWorkflowId,
    jobs,
    isHistorical: false,
    groupSourceStreams: true,
  });
  const selectableJobs = mapJobsToSelectableJobs(tableRows);
  // END: Get table rows

  const toast = useToast();
  const [deleteWebhook] = useDeleteWebhookMutation({
    onError(error) {
      toast(`Failed setting up webhook`, error.message, "error");
    },
    onCompleted() {
      toast(
        `Webhook deleted`,
        `Your webhook has been succesfully deleted.`,
        "info",
      );
    },
    refetchQueries: [
      { query: ListWebhooksDocument, variables: { orchestrationWorkflowId } },
    ],
  });

  const columns = React.useMemo<Column<ListWebhooksRow>[]>(
    () => [
      {
        Header: "Name",
        Cell: ({
          value,
        }: CellProps<ListWebhooksRow, ListWebhooksRow["name"]>) => (
          <div>{value}</div>
        ),
        accessor: "name",
      },
      {
        Header: "Runs after",
        Cell: ({
          value,
        }: CellProps<ListWebhooksRow, ListWebhooksRow["dependencies"]>) => (
          <div className="flex flex-row">
            {value.map((v) => (
              <div key={v.id} title={v.name} className="ml-1">
                {selectableJobs.find(
                  (s) => s?.type === v.type && s?.id === v.itemId,
                )?.icon ?? <div>v.itemId</div>}
              </div>
            ))}
          </div>
        ),
        accessor: "dependencies",
      },
      {
        Header: "Created",
        Cell: ({
          value,
        }: CellProps<ListWebhooksRow, ListWebhooksRow["createdAt"]>) => (
          <FormatTime date={value ?? ""} />
        ),
        accessor: "createdAt",
      },
      {
        Header: "Updated",
        Cell: ({
          value,
        }: CellProps<ListWebhooksRow, ListWebhooksRow["updatedAt"]>) => (
          <FormatTime date={value ?? ""} />
        ),
        accessor: "updatedAt",
      },
      {
        Header: "Actions",
        Cell: ({
          row: { original: value },
        }: CellProps<
          ListWebhooksRow,
          {
            webhookId: ListWebhooksRow["webhookId"];
          }
        >) => (
          <ButtonGroup size="xs" className="gap-2">
            <PrimaryButton
              colorScheme="danger"
              onClick={async (e) => {
                deleteWebhook({
                  variables: {
                    webhookId: value.webhookId,
                  },
                });
              }}
            >
              {"Delete"}
            </PrimaryButton>
          </ButtonGroup>
        ),
      },
    ],
    [selectableJobs, deleteWebhook],
  );

  return (
    <OrchestrationTabsLayout current="webhooks">
      <div className="relative flex flex-row">
        <PrimaryButton
          size="sm"
          onClick={() => createTriggerModalDisclosure.onOpen()}
        >
          Create webhook
        </PrimaryButton>
        <SecondaryButton
          className="ml-2"
          size="sm"
          onClick={() =>
            navigate({
              to: connectExternalTool(account.slug),
            })
          }
        >
          Setup connection
        </SecondaryButton>
      </div>

      {createTriggerModalDisclosure.isOpen && (
        // This Modal has to be inside the SlideOver. Else the escape button first closes the
        // Slide-over and then after the modal.
        <CreateWebhookModal
          onClose={createTriggerModalDisclosure.onClose}
          isOpen={createTriggerModalDisclosure.isOpen}
          selectableJobs={selectableJobs}
          loadingWorkflows={loadingWorkflows}
        />
      )}

      <DefaultTable
        isLoading={externalWebhooksLoading}
        columns={columns}
        data={externalWebhooks?.listWebhoooks ?? []}
        onRowClick={(_row) => {}}
      />
    </OrchestrationTabsLayout>
  );
};
