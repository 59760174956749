import { useFirebaseDatabase } from "@/features/firebase";
import { ref } from "firebase/database";
import { useMemo } from "react";

export function useDatabaseReference(
  path: string | (() => string | undefined),
) {
  const db = useFirebaseDatabase();

  let pathString: string | undefined;
  if (typeof path === "function") {
    try {
      pathString = path();
    } catch {}
  } else {
    pathString = path;
  }
  return useMemo(() => {
    if (!pathString) {
      return undefined;
    }
    return ref(db, pathString);
  }, [db, pathString]);
}
