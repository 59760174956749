import React, { ComponentProps } from "react";

import { FeatureGuard } from "@/components/elements/FeatureGuard";
import { useCurrentPlan } from "@/features/subscription";

export function AuditLogsFeatureGuard(
  props: {
    children: React.ReactNode;
  } & Pick<ComponentProps<typeof FeatureGuard>, "fallback">,
) {
  const { isEnabled, isLoading } = useAuditLogsFeatureGuard();
  return (
    <FeatureGuard test={isLoading || isEnabled} fallback={props.fallback}>
      {props.children}
    </FeatureGuard>
  );
}

function useAuditLogsFeatureGuard() {
  const { features, loading } = useCurrentPlan();
  const isEnabled = features.auditLogsEnabled;
  return {
    isEnabled,
    isLoading: loading,
  };
}
