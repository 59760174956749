import React, { useEffect } from "react";

export const useArrowKeyFocusNavigation = (
  containerRef: React.RefObject<HTMLDivElement>,
  currentFocusedRef: React.RefObject<HTMLElement>,
) => {
  useEffect(() => {
    const currentContainerRef = containerRef.current;
    if (!currentContainerRef) return;
    const keyDownListener = (e: KeyboardEvent) => {
      if (!["ArrowDown", "ArrowUp"].includes(e.key)) return;
      if (!currentFocusedRef.current) return;
      e.preventDefault();

      const nextItem = getNextTabableElement(
        currentFocusedRef.current,
        currentContainerRef,
        e.key === "ArrowUp",
      );

      nextItem?.focus();
    };

    currentContainerRef.addEventListener("keydown", keyDownListener);

    return () =>
      currentContainerRef.removeEventListener("keydown", keyDownListener);
  }, [containerRef, currentFocusedRef]);
};

const getNextTabableElement = (
  element: HTMLElement,
  container: HTMLElement,
  reverse: boolean,
): HTMLElement | undefined => {
  const nextItemElement = !reverse
    ? ((element.nextSibling || container.firstChild) as HTMLElement)
    : ((element.previousSibling || container.lastChild) as HTMLElement);
  if (!nextItemElement) return undefined;
  if (nextItemElement.tabIndex < 0) {
    return getNextTabableElement(nextItemElement, container, reverse);
  } else return nextItemElement;
};
