import { useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

import { PlanName } from "@/apollo/types";
import {
  ScheduleSelect,
  useSyncScheduleContext,
} from "@/components/elements/SyncScheduler";
import Tooltip from "@/components/elements/Tooltip";
import {
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@/components/elements/input/ListboxDropdown";
import { ViewAddOnsButton } from "@/components/modules/UpgradeNowButton";
import { useCurrentPlan, useSubscriptionStatus } from "@/features/subscription";
import cn from "@/helpers/classNames";
import { ListboxProps } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { CronOption } from "../WeldSyncScheduler";
import { UnavailableCronOptionTooltipContent } from "./UnavailableCronOptionTooltipContent";

export function SyncScheduleListbox(
  props: ListboxProps<"div", CronOption, CronOption>,
) {
  const weldPlan = useCurrentPlan();
  const { options, selectedOption } = useSyncScheduleContext<CronOption>();
  const { trial } = useSubscriptionStatus();

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "absolute",
    placement: "bottom-start",
    modifiers: [
      { name: "preventOverflow", enabled: true },
      { name: "offset", options: { offset: [0, 10] } },
    ],
  });

  return (
    <ScheduleSelect value={selectedOption} {...props}>
      <>
        <ListboxButton
          ref={setReferenceElement}
          className={cn("w-auto", {
            "text-gray-500": selectedOption == null,
          })}
          iconRight={<ChevronDownIcon className="h-5 w-5" />}
        >
          {selectedOption?.label ?? "Select a schedule"}
        </ListboxButton>

        {createPortal(
          <ListboxOptions
            className="max-w-sm"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {options.map((option) => {
              if (!option.disabled) {
                return (
                  <ListboxOption
                    key={option.value}
                    value={option}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </ListboxOption>
                );
              }
              return (
                <li className="relative" key={option.value}>
                  <Tooltip
                    content={
                      <UnavailableCronOptionTooltipContent
                        currentPlanName={weldPlan.name}
                        targetPlan={option.targetPlan}
                      />
                    }
                  >
                    <div>
                      <ListboxOption
                        as="div"
                        value={option}
                        disabled={option.disabled}
                      >
                        <span>{option.label}</span>
                      </ListboxOption>
                      {!trial?.isActive &&
                        weldPlan.name === PlanName.Essential &&
                        weldPlan.name === option.targetPlan?.plan.name &&
                        option.targetPlan.addOn != null && (
                          <div className="absolute right-2 top-1/2 -translate-y-1/2">
                            <ViewAddOnsButton
                              colorScheme="primary"
                              variant="solid"
                              source="unavailable sync interval option - listbox"
                              size="xs"
                            >
                              Get add-on
                            </ViewAddOnsButton>
                          </div>
                        )}
                    </div>
                  </Tooltip>
                </li>
              );
            })}
          </ListboxOptions>,
          document.body,
        )}
      </>
    </ScheduleSelect>
  );
}
