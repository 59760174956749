import { useState } from "react";

import {
  Connection,
  ConnectionDocument,
  ListSyncsDocument,
  OrchestrationSchedulerType,
  useCreateSyncMutation,
  useSourcePropertiesQuery,
} from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import {
  SlideOverBody,
  SlideOverFooter,
  SlideOverHeader,
} from "@/components/elements/SlideOver";
import { IntegrationLogoBox } from "@/integrations";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";

import { useSetupRevELTSimple } from "../../useSetupRevELTSimple";
import { GoogleSheetsConnectionSelector } from "./GoogleSheetsConnectionSelector";
import {
  GoogleSheetsSettings,
  GoogleSheetsSettingsState,
} from "./GoogleSheetsSettings";
import { generateMappings } from "./utils/generateMappings";

export const GoogleSheetsReverseEltSidebar = () => {
  // States
  const { close, model } = useSetupRevELTSimple();

  const [currentDestination, setCurrentDestination] = useState<Connection>();

  const [settings, setSettings] = useState<GoogleSheetsSettingsState>();

  // Dependencies
  const { dataWarehouseConnectionId } = useCurrentAccount();

  const { data: sourceProperties } = useSourcePropertiesQuery({
    variables: { modelId: model.id },
    skip: !model.id,
  });

  const toast = useToast();
  const [createSync, { loading: creating }] = useCreateSyncMutation({
    onCompleted() {
      close();
    },
    onError(error) {
      toast(`Sync not created`, error.message, "error");
    },
    refetchQueries: [
      { query: ListSyncsDocument },
      { query: ConnectionDocument, variables: { id: currentDestination?.id } },
    ],
  });

  const handleSubmit = async () => {
    if (
      !dataWarehouseConnectionId ||
      !currentDestination ||
      !settings ||
      !settings.operation ||
      !sourceProperties
    ) {
      return;
    }

    settings.connectionSettings.templateChoice = {
      value: "Source Column Name",
      label: "Source Column Name",
    };

    await createSync({
      variables: {
        sourceConnectionId: dataWarehouseConnectionId,
        destinationConnectionId: currentDestination.id,
        modelId: model.id,
        operations: {
          id: settings.operation.id,
          objectType: "sheet",
          integrationId: currentDestination.integrationId,
          mappings: generateMappings(
            sourceProperties,
            settings.operation.operationMode,
          ),
        },
        primaryObject: "sheet",
        syncInterval: settings.cron,
        config: JSON.stringify({
          destinationSettings: settings.connectionSettings,
        }),
        schedulerType: OrchestrationSchedulerType.Local,
      },
    });
  };

  return (
    <>
      <SlideOverHeader className="flex items-center space-x-2">
        <IntegrationLogoBox id={"google-sheets"} size="md" />
        <div>
          <div className="flex items-center space-x-1">
            <p>{model.name}</p>
          </div>
          <p className="text-xs">Export to Google Sheets</p>
        </div>
      </SlideOverHeader>
      <SlideOverBody>
        <div className="max-w-2xl space-y-8">
          <GoogleSheetsConnectionSelector
            onDestinationSelected={(selection) => {
              setCurrentDestination(undefined);
              setCurrentDestination(selection);
            }}
          />
          {currentDestination && (
            <GoogleSheetsSettings
              key={currentDestination.id}
              destination={currentDestination}
              onChange={(settings) => setSettings(settings)}
            />
          )}
        </div>
      </SlideOverBody>
      <SlideOverFooter>
        <Button onClick={close} variant="outline">
          Cancel
        </Button>

        <Button
          colorScheme="primary"
          variant="solid"
          disabled={!currentDestination || !settings?.valid}
          isLoading={creating}
          onClick={() => {
            handleSubmit();
          }}
        >
          Start syncing data
        </Button>
      </SlideOverFooter>
    </>
  );
};
