export function HiglightedText(props: { highlighted: string; text: string }) {
  const matches = props.text.match(
    new RegExp(`(.*)(${props.highlighted})(.*)`),
  );

  if (!props.highlighted || !Array.isArray(matches)) {
    return <span>{props.text}</span>;
  }

  return (
    <span>
      <span>{matches[1]}</span>
      <span className="bg-yellow-300 dark:bg-yellow-700">{matches[2]}</span>
      <span>{matches[3]}</span>
    </span>
  );
}
