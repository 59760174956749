const stepsMap: Record<string, string> = {
  "2314287": "Get started with data modeling",
  "2332406": "Select AI mode",
  "2332591": "Type input",
};

const guide = {
  id: "e5MH8ob3lF",
  name: "First model empty",
  stepsMap,
};

export default guide;
