export default function HistoryLine() {
  return (
    <div
      className={`left-8 -ml-px h-full w-0.5 bg-gray-300 dark:bg-gray-700`}
      style={{
        position: "absolute",
        top: "50%",
        transform: "",
      }}
      aria-hidden="true"
    />
  );
}
