import { useFindAvailableOperationsQuery } from "@/apollo/types";
import { useSyncContext } from "../modules/SyncContext";
import useChainedObject from "@/hooks/useChainedObject";
export default function useOperations() {
  const [state] = useSyncContext();
  const {
    loading: operationsLoading,
    data: { findAllAvailableOperations = [] } = {},
  } = useFindAvailableOperationsQuery({
    variables: {
      connectionId: state.destinationId,
      objectType: state.objectTypeName,
    },
    skip: !state.destinationId || !state.objectTypeName,
  });

  const { currentObject } = useChainedObject(
    findAllAvailableOperations,
    "id",
    state.operation?.id ?? "",
  );

  return {
    findAllAvailableOperations,
    operationsLoading,
    currentOperation: currentObject,
  };
}
