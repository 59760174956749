import { isOnLocalhost } from "@/helpers/environment";

import initDatadog from "./datadog";
import initSentry from "./sentry";
import { getProductionEnvironment } from "./utils";

export default function init() {
  const releaseId = process.env.RELEASE_ID;
  const environment = getProductionEnvironment();
  if (
    process.env.NODE_ENV === "production" &&
    releaseId !== undefined &&
    environment !== undefined &&
    !isOnLocalhost
  ) {
    initSentry(releaseId, environment);
    initDatadog(releaseId, environment);
  }
}
