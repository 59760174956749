import {
  CronOptionBase,
  SyncScheduleContext,
  UseSyncScheduleProps,
  useSyncSchedule,
} from "./useSyncSchedule";

type RenderProps<TOption extends CronOptionBase = CronOptionBase> = Pick<
  ReturnType<typeof useSyncSchedule<TOption>>,
  | "value"
  | "options"
  | "mode"
  | "canSelectTimeOffset"
  | "showTimeOffsetInput"
  | "onToggleMode"
  | "onToggleTimeOffsetInput"
>;

export type RenderPropChildren<
  TOption extends CronOptionBase = CronOptionBase,
> = (props: RenderProps<TOption>) => React.ReactNode;

type MaybeRenderProp<TOption extends CronOptionBase = CronOptionBase> =
  | React.ReactNode
  | RenderPropChildren<TOption>;

type SyncScheduleProps<TOption extends CronOptionBase = CronOptionBase> =
  UseSyncScheduleProps<TOption> & {
    children?: MaybeRenderProp<TOption>;
  };

export function SyncSchedule<TOption extends CronOptionBase = CronOptionBase>(
  props: SyncScheduleProps<TOption>,
) {
  const ctx = useSyncSchedule(props);
  const {
    value,
    options,
    mode,
    showTimeOffsetInput,
    canSelectTimeOffset,
    onToggleMode,
    onToggleTimeOffsetInput,
  } = ctx;

  const children =
    typeof props.children === "function"
      ? props.children({
          value,
          options,
          mode,
          showTimeOffsetInput,
          canSelectTimeOffset,
          onToggleMode,
          onToggleTimeOffsetInput,
        })
      : props.children;
  return (
    <SyncScheduleContext.Provider value={ctx as any}>
      {children}
    </SyncScheduleContext.Provider>
  );
}
