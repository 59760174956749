import { useIsSignedIn } from "./useIsSignedIn";

export function FirebaseAuthWrapper(props: {
  children: React.ReactNode;
  loadingElement?: React.ReactNode;
}): JSX.Element {
  const isSignedIn = useIsSignedIn();
  if (isSignedIn) {
    return props.children as JSX.Element;
  }
  return (props.loadingElement as JSX.Element) ?? null;
}
