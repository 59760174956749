import { isOnStagingDomain } from "@/helpers/environment";

export function getProductionEnvironment() {
  if (process.env.NODE_ENV !== "production") return undefined;
  if (isOnStagingDomain) return "staging";
  if (process.env.REACT_APP_VERCEL_ENV === "preview") return "preview";
  return "production";
}

export type ProductionEnvironmentName = ReturnType<
  typeof getProductionEnvironment
>;
