import classNames from "@/helpers/classNames";
import React from "react";

export function FeatureStateText({
  className,
  ...props
}: React.ComponentProps<"span">) {
  return (
    <span
      className={classNames(
        "align-super text-[.75em] font-bold",
        "bg-gradient-to-r bg-clip-text text-transparent",
        className,
      )}
      {...props}
    />
  );
}

export const BetaFeatureText: typeof FeatureStateText = ({
  children,
  className,
  ...props
}) => {
  return (
    <FeatureStateText
      className={classNames("from-violet-400 to-fuchsia-400", className)}
      {...props}
    >
      {children || "BETA"}
    </FeatureStateText>
  );
};

export const ExperimentalFeatureText: typeof FeatureStateText = ({
  children,
  className,
  ...props
}) => {
  return (
    <FeatureStateText
      className={classNames("from-lime-500 to-emerald-500", className)}
      {...props}
    >
      {children || "EXPERIMENTAL"}
    </FeatureStateText>
  );
};
