import cronExpressionToDayjs from "@/helpers/cronExpressionToDayjs";

import { CronDisplayReadable } from "./CronDisplayReadable";
import { FormatRelativeTime } from "./FormatRelativeTime";
import { FormatTime } from "./FormatTime";
import Tooltip from "./Tooltip";
import { TextMuted } from "./Typography";

export default function IntegrationCellSyncFrequency(props: {
  syncInterval: string;
}) {
  if (props.syncInterval.trim() === "") {
    return <>Not defined</>;
  }
  const date = cronExpressionToDayjs(props.syncInterval);
  return (
    <Tooltip content={<FormatTime date={date} format="LLL" />}>
      <span>
        <FormatRelativeTime date={date} />{" "}
        <TextMuted>
          (<CronDisplayReadable cron={props.syncInterval} />)
        </TextMuted>
      </span>
    </Tooltip>
  );
}
