import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import { useMemo } from "react";

import { useDrafts } from "../../QueryEditor/useModelDraft";
import { DraftModelItemType, ModelItemType } from "../SidebarFactory";

export const useDraftItems = () => {
  const { models, loadingModels } = useListModels(true);
  const { drafts } = useDrafts();

  const items = useMemo(() => {
    const unpublished: ModelItemType[] = [...models]
      .filter((model) => !model.publishedQuery && !model.dashboardId)
      .sort((a, b) => {
        return b.createdAt.localeCompare(a.createdAt);
      })
      .map((model) => {
        return {
          itemType: "model",
          id: model.id,
          type: model.type,
          model,
        };
      });

    const draftModelsItems: DraftModelItemType[] = drafts.map((draftModel) => ({
      itemType: "draft",
      id: draftModel.id,
      draftModel,
    }));

    return [...draftModelsItems, ...unpublished];
  }, [models, drafts]);

  return {
    items,
    loading: loadingModels,
  };
};
