import { useMountEffect } from "@/hooks/useMountEffect";
import { useRef } from "react";
import useLocalStorageState from "use-local-storage-state";
import { createStorageKey } from "@/utils/storage";

const STORAGE_KEY = createStorageKey("signup_utm_params");

const UTM_PARAMS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

type UTMParams = Record<string, string>;

export function utmSearchParamsToObj(searchParams: URLSearchParams) {
  return UTM_PARAMS.reduce<UTMParams>((acc, paramName) => {
    const value = searchParams.get(paramName);
    if (value) {
      acc[paramName] = value;
    }
    return acc;
  }, {});
}

export function usePersistUTMParamsTemporarily(searchParams: URLSearchParams) {
  const [, setValue] = useLocalStorageState<UTMParams>(STORAGE_KEY);

  useMountEffect(() => {
    const values = utmSearchParamsToObj(searchParams);
    if (Object.keys(values).length > 0) {
      setValue(values);
    }
  });
}

export function useReadTemporaryUTMParamsOnce() {
  const [values, , { removeItem }] =
    useLocalStorageState<UTMParams>(STORAGE_KEY);

  const valuesRef = useRef(values);
  useMountEffect(() => removeItem());

  return valuesRef.current;
}
