export const ErrorIcon = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      {...props}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="#FDBFB7" />
      <circle cx="25" cy="25" r="15" fill="#FC604D" />
      <path
        d="M25 22.4999V25.6249M17.2475 28.4383C16.5258 29.6883 17.4283 31.2499 18.8708 31.2499H31.1291C32.5708 31.2499 33.4733 29.6883 32.7525 28.4383L26.6241 17.8149C25.9025 16.5649 24.0975 16.5649 23.3758 17.8149L17.2475 28.4383ZM25 28.1249H25.0058V28.1316H25V28.1249Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
