const root = "workspaces-flattened";

export function getModelPath(accountId: string, modelId: string) {
  return `${root}/${accountId}/models/${modelId}`;
}

export function getUsersPath(accountId: string, modelId: string) {
  return `${getModelPath(accountId, modelId)}/users`;
}

export function getUserPath(
  accountId: string,
  modelId: string,
  userId: string,
) {
  return `${getUsersPath(accountId, modelId)}/${userId}`;
}

export function getUserCursorPath(
  accountId: string,
  modelId: string,
  userId: string,
) {
  return `${getUserPath(accountId, modelId, userId)}/cursor`;
}
