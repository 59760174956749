import React from "react";

const TableIcon = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19.5 14.25v-2.625m0 0a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m11.25 9.375H12m0 5.05v5.075m0-5.075v-5.05m0 5.05H4.5m7.5 0h7.5M12 21.75h2.176-8.551A1.125 1.125 0 014.5 20.625V3.375c0-.621.504-1.125 1.125-1.125H10.5a9 9 0 019 9v9.375c0 .621-.504 1.125-1.125 1.125H12zM4.5 11.625H12"
      ></path>
    </svg>
  );
};

export default TableIcon;
