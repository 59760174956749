import { ReactNode, useMemo, useRef } from "react";
import { Column, usePagination, useSortBy, useTable } from "react-table";

import cn from "@/helpers/classNames";
import { ArrowUpIcon, ArrowsUpDownIcon } from "@heroicons/react/20/solid";

import { LoadingOverlay } from "./LoadingComponents";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table";
import Pagination from "./pagination/Pagination";

export default function DefaultTable<T extends object = any>({
  columns,
  data,
  empty,
  onRowClick,
  paginate = false,
  isLoading,
  isSortable,
}: {
  columns: Column<T>[];
  data: T[];
  empty?: ReactNode;
  onRowClick?: (row: T) => void;
  paginate?: boolean;
  isLoading?: boolean;
  isSortable?: boolean;
}) {
  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    pageCount,
    prepareRow,
    rows,
    state: { pageIndex },
  } = useTable<T>(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
      },
    },
    ...(useRef(isSortable).current ? [useSortBy] : []),
    usePagination,
  );

  const shownRows = useMemo(
    () => (paginate ? page : rows),
    [paginate, page, rows],
  );

  return (
    <div className="relative flex w-full flex-col">
      <div className="w-full">
        <Table {...getTableProps()}>
          <TableHeader>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    {...header.getHeaderProps(
                      header.getSortByToggleProps
                        ? header.getSortByToggleProps({
                            style: {
                              maxWidth: header.maxWidth,
                              minWidth: header.minWidth,
                              width: header.width,
                            },
                          })
                        : {
                            style: {
                              maxWidth: header.maxWidth,
                              minWidth: header.minWidth,
                              width: header.width,
                            },
                          },
                    )}
                  >
                    <div
                      className={cn("flex items-center whitespace-nowrap", {
                        "font-medium": header.isSorted,
                      })}
                    >
                      {header.render("Header")}
                      {header.canSort && (
                        <div
                          className={cn(
                            "ml-1 inline-flex h-4 w-4 text-gray-500 opacity-40",
                            {
                              "opacity-100": header.isSorted,
                            },
                          )}
                        >
                          {header.isSorted ? (
                            <ArrowUpIcon
                              className={cn({
                                "rotate-180 transition-transform":
                                  header.isSortedDesc,
                              })}
                            />
                          ) : (
                            <ArrowsUpDownIcon />
                          )}
                        </div>
                      )}
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody {...getTableBodyProps()}>
            {empty && data.length === 0 ? (
              <TableRow>
                <TableCell className="p-12" colSpan={columns.length}>
                  {empty}
                </TableCell>
              </TableRow>
            ) : (
              shownRows.map((row, i) => {
                prepareRow(row);

                return (
                  <TableRow
                    onClick={
                      typeof onRowClick === "function"
                        ? () => onRowClick(row.original)
                        : undefined
                    }
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          {...cell.getCellProps({
                            style: {
                              maxWidth: cell.column.maxWidth,
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                            },
                          })}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        {paginate && (canPreviousPage || canNextPage) && (
          <Pagination
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
          />
        )}
      </div>
      {isLoading ? <LoadingOverlay /> : null}
    </div>
  );
}
