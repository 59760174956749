import { PropsWithChildren, createContext, useContext, useState } from "react";

import { useMountEffect } from "@/hooks/useMountEffect";
import { Stripe, loadStripe } from "@stripe/stripe-js";

const StripeContext = createContext<Stripe | null>(null);

export function useStripeObject() {
  const ctx = useContext(StripeContext);
  if (!ctx) {
    throw new Error("StripeProvider not found");
  }
  return ctx;
}

export function StripeProvider(props: PropsWithChildren<{}>) {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useMountEffect(async () => {
    const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    if (!publishableKey) {
      // eslint-disable-next-line no-console
      console.error("REACT_APP_STRIPE_PUBLISHABLE_KEY not set");
      return;
    }
    try {
      const stripe = await loadStripe(publishableKey);
      setStripe(stripe);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error loading stripe", err);
      return;
    }
  });

  if (!stripe) {
    return null;
  }

  return (
    <StripeContext.Provider value={stripe}>
      {props.children}
    </StripeContext.Provider>
  );
}
