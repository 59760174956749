import { ComponentProps } from "@tw-classed/react";
import classNames from "@/helpers/classNames";
import React from "react";

import { Input } from "./Input";
import { InputAddonProps } from "./InputAddon";

export const InputGroup = ({
  children,
  className,
  ...props
}: React.PropsWithChildren<React.ComponentProps<"div">>) => {
  const childrenArray = React.Children.toArray(children);
  const { hasLeftAddon, hasRightAddon } = childrenArray.reduce<{
    hasLeftAddon: boolean;
    hasRightAddon: boolean;
  }>(
    (acc, child) => {
      if (React.isValidElement<InputAddonProps>(child)) {
        const id = (child as any).type.id;
        if (id === "InputAddonLeft") {
          acc.hasLeftAddon = true;
        }
        if (id === "InputAddonRight") {
          acc.hasRightAddon = true;
        }
      }
      return acc;
    },
    {
      hasLeftAddon: false,
      hasRightAddon: false,
    },
  );

  const clones = childrenArray.map((child) => {
    const id = (child as any).type.id;
    if (
      id === "Input" &&
      React.isValidElement<ComponentProps<typeof Input>>(child)
    ) {
      return React.cloneElement(child, {
        className: classNames(
          child.props.className,
          hasLeftAddon && "rounded-tl-none rounded-bl-none",
          hasRightAddon && "rounded-tr-none rounded-br-none",
        ),
      });
    }
    return child;
  });
  return (
    <div className={classNames("relative flex w-full", className)} {...props}>
      {clones}
    </div>
  );
};
