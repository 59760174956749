import { useApolloClient } from "@apollo/client";
import {
  DatawarehouseMigration,
  DwMigrationStatus,
  GetRawViewsDocument,
  ListModelsDocument,
  useDatawarehouseMigrationQuery,
} from "@/apollo/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useToast } from "@/providers/ToastProvider";
import { useMemo, useRef } from "react";
import { useSocketEvent } from "@/socket/SocketContext";

const activeDWMigrationStatuses = [
  DwMigrationStatus.Started,
  DwMigrationStatus.SyncingData,
  DwMigrationStatus.SyncingModels,
];

const isActiveMigrationStatus = (status: DwMigrationStatus) => {
  return status != null && activeDWMigrationStatuses.includes(status);
};

const POLL_INTERVAL = 5000;

type MigrationState =
  | {
      migration: undefined;
      migrationIsActive: false;
    }
  | {
      migration: DatawarehouseMigration;
      migrationIsActive: true;
    };

export const useCurrentMigration = (): MigrationState => {
  const toast = useToast();
  const { dataWarehouseMigrationEnabled } = useFlags();
  const migrationWasActive = useRef(false);
  const client = useApolloClient();

  const { data, startPolling, stopPolling } = useDatawarehouseMigrationQuery({
    notifyOnNetworkStatusChange: true,
    skip: !dataWarehouseMigrationEnabled,
    onCompleted: (data) => {
      const migration = data?.datawarehouseMigration;
      if (!migration) return;

      if (isActiveMigrationStatus(migration.status)) {
        migrationWasActive.current = true;
        startPolling(POLL_INTERVAL);
      } else {
        stopPolling();
        if (migrationWasActive.current) {
          if (migration.status === DwMigrationStatus.Failed) {
            // Migration failed while active, show an error
            toast(
              "Migration failed",
              migration?.errorMessage ?? "Something went wrong.",
              "error",
            );
          }
          if (migration.status === DwMigrationStatus.Completed) {
            toast(
              "Migration completed",
              "Your data warehouse migration has completed.",
              "success",
            );

            //Reload models since they are affected by the last step of the migration
            client.refetchQueries({
              include: [ListModelsDocument, GetRawViewsDocument],
            });
          }
        }
        migrationWasActive.current = false;
      }
    },
  });

  useSocketEvent("data-warehouse-migration:started", {
    async onMessage() {
      startPolling(POLL_INTERVAL);
    },
  });

  const migrationState: MigrationState = useMemo(() => {
    if (
      data?.datawarehouseMigration != null &&
      isActiveMigrationStatus(data.datawarehouseMigration.status)
    ) {
      return {
        migration: data.datawarehouseMigration,
        migrationIsActive: true,
      };
    }
    return {
      migration: undefined,
      migrationIsActive: false,
    };
  }, [data?.datawarehouseMigration]);

  return migrationState;
};
