import { groupBy } from "lodash";

import { ListSyncsQuery } from "@/apollo/types";
import { IntegrationLogoBox } from "@/integrations";

import { ExportItemType, FolderItemType } from "../SidebarFactory";

export function useExportsFolders(
  data: ListSyncsQuery["syncs"] = [],
): FolderItemType[] {
  const groupedByIntegration = groupBy(data, "destinationIntegrationId");
  return Object.entries(groupedByIntegration).map(([integrationId, syncs]) => {
    return {
      id: integrationId,
      itemType: "folder",
      folder: {
        id: integrationId,
        name: integrationId,
        folderType: "export",
      },
      children: syncs.map<ExportItemType>((sync) => {
        let label = sync.primaryObject;
        if (
          sync.destinationIntegrationId === "google-sheets" &&
          sync.config?.destinationSettings != null
        ) {
          const settings = sync.config.destinationSettings;
          label = [settings?.spreadsheet?.label, settings?.sheet?.label]
            .filter(Boolean)
            .join(", ");
        }

        return {
          id: sync.id,
          itemType: "export",
          sync,
          label,
        };
      }),
      icon: <IntegrationLogoBox id={integrationId} size="1xs" />,
    };
  });
}
