import { useChartTheme } from "@/features/charts/themes";
import { getChartColor } from "@/features/charts/themes/visualizeTheme";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ChartVisualization } from "../VisualizationType";
import { TooltipContent } from "./TooltipContent";
import { useMemo } from "react";
import { PresentationChartLineIcon } from "@heroicons/react/24/outline";
import { useTimeFormater } from "./useTimeFormater";

type Props = { chart: ChartVisualization; data: any[] };

export function LineChartComponent(props: Props) {
  const chartTheme = useChartTheme("visualize");

  const isValidKeys = useMemo(() => {
    const xKey = props.chart.keys?.x;
    const yKey = props.chart.keys?.y;
    return (
      typeof xKey === "string" &&
      xKey.length > 0 &&
      Array.isArray(yKey) &&
      yKey.length > 0
    );
  }, [props.chart.keys?.x, props.chart.keys?.y]);

  const timeFormatter = useTimeFormater(props.data, props.chart.keys.x);

  if (!isValidKeys)
    return (
      <div className="flex h-full w-full px-8 py-8">
        <div className="flex h-full w-full items-center justify-center space-x-2 rounded bg-gray-100 dark:bg-gray-700">
          <PresentationChartLineIcon className="h-6 w-6" />
          <span className="italic">No columns selected</span>
        </div>
      </div>
    );

  return (
    <div className="relative h-full w-full overflow-hidden">
      <div className="absolute inset-0">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart {...chartTheme.LineChart} data={props.data}>
            <CartesianGrid {...chartTheme.CartesianGrid} />
            <XAxis
              {...chartTheme.XAxis}
              padding={{ left: 15, right: 15 }}
              dataKey={props.chart?.keys?.x}
              axisLine={false}
              tickFormatter={timeFormatter}
            />
            <YAxis
              tickFormatter={(value) => {
                const formatter = Intl.NumberFormat("en", {
                  notation: "compact",
                });
                if (isNaN(value)) {
                  return value;
                }
                return formatter.format(value);
              }}
              {...chartTheme.YAxis}
              axisLine={false}
              width={30}
            />
            <Tooltip
              wrapperStyle={{ outline: "none" }}
              {...chartTheme.Tooltip}
              content={<TooltipContent />}
            />
            {props.chart.keys?.y?.map((column, i) => (
              <Line
                key={column}
                name={column}
                radius={3}
                type={"natural"}
                {...chartTheme.Line}
                stroke={getChartColor(i)}
                strokeWidth={3}
                dot={false}
                dataKey={column}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
