import { useMatch } from "@tanstack/react-location";
import { LoadingFull } from "@/components/elements/LoadingComponents";
import MainPageLayout from "@/components/layouts/MainPageLayout";
import dayjs from "dayjs";
import { LocationGenerics } from "routes";

import { useWorkflowRun } from "./components/useWorkflowRun";
import { Workflow } from "./components/Workflow";

const duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const HistoricGlobalOrchestrationRun = (props: {}) => {
  const match = useMatch<LocationGenerics>();

  const workflowId = match.params.workflowId;

  const { orchestrationWorkflowRun, loading: workflowRunLoading } =
    useWorkflowRun(match.params.workflowRunId);

  return (
    <MainPageLayout>
      <div className="relative">
        {workflowId && orchestrationWorkflowRun && (
          <Workflow
            workflowId={workflowId}
            isCurrentWorkflow={false}
            nextRunStartingNow={false}
            currentRun={orchestrationWorkflowRun}
            nextRun={undefined}
            isHistoric={true}
          />
        )}
        {workflowRunLoading && <LoadingFull />}
      </div>
    </MainPageLayout>
  );
};

export default HistoricGlobalOrchestrationRun;
