import { useDarkMode } from "@/providers/DarkModeProvider";
import { CSSProperties } from "react";
import { LightAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";
import sql from "react-syntax-highlighter/dist/esm/languages/hljs/sql";
import darkStyle from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";
import defaultStyle from "react-syntax-highlighter/dist/esm/styles/hljs/default-style";

SyntaxHighlighter.registerLanguage("sql", sql);
SyntaxHighlighter.registerLanguage("json", json);

type CodeDisplayProps = {
  code: string;
  language: "sql" | "json";
  showLineNumber?: boolean;
  style?: CSSProperties | undefined;
};

export function CodeDisplay({
  code,
  language,
  showLineNumber = true,
  style,
}: CodeDisplayProps) {
  const { isDarkModeEnabled } = useDarkMode();
  return (
    <SyntaxHighlighter
      language={language}
      showLineNumbers={showLineNumber}
      style={isDarkModeEnabled ? darkStyle : defaultStyle}
      customStyle={{
        backgroundColor: isDarkModeEnabled ? "#141515" : "#F1F2F3",
        color: isDarkModeEnabled ? "#f8f8f8" : "#1d1f21",
        borderRadius: "0.75rem",
        padding: "1rem",
        fontSize: "0.75rem",
        ...style,
      }}
    >
      {code}
    </SyntaxHighlighter>
  );
}

export function SQLCodeDisplay({ sql }: { sql: string }) {
  return <CodeDisplay code={sql} language="sql" />;
}

export function JSONCodeDisplay({ json }: { json: string }) {
  return <CodeDisplay code={json} language="json" />;
}
