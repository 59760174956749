import { Notification } from "@/apollo/types";
import { useIsInBackOffice } from "@/pages/BackOffice/useIsInBackOffice";
import {
  useRemoveNotification,
  useRemoveNotificationBackoffice,
} from "@/features/notifications";
import { useCallback } from "react";

export const useRetryNotification = (options?: {
  onCompleted?: (message: string) => void;
  onError?: (message: string) => void;
}) => {
  const { isBackOffice } = useIsInBackOffice();

  const [removeNotification, removing] = useRemoveNotification({
    onCompleted: () => options?.onCompleted?.("Sync will be retried."),
    onError: () =>
      options?.onError?.("An error occurred while queueing the sync for retry"),
  });

  const [removeNotificationBo, removingBo] = useRemoveNotificationBackoffice({
    onCompleted: () => options?.onCompleted?.("Sync will be retried."),
    onError: () =>
      options?.onError?.("An error occurred while queueing the sync for retry"),
  });

  const handleRetry = useCallback(
    (notification: Notification) => {
      if (isBackOffice) {
        removeNotificationBo(notification);
      } else {
        removeNotification(notification);
      }
    },
    [isBackOffice, removeNotification, removeNotificationBo],
  );

  return { retry: handleRetry, loading: removing || removingBo };
};
