import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { MaterializationType, useUnpublishModelMutation } from "@/apollo/types";
import { DangerButton } from "@/components/elements/Button";
import Tooltip from "@/components/elements/Tooltip";
import { useToast } from "@/providers/ToastProvider";

type Props = { modelId: string; materializationType: MaterializationType };

const UnpublishModelButton = (props: Props) => {
  const toast = useToast();

  const [unpublishModel, { loading }] = useUnpublishModelMutation({
    onError(error) {
      toast(`Error stopping materialization`, error.message, "error");
    },
    onCompleted() {
      toast(
        `Unpublished model`,
        `The materialized table was removed.`,
        "success",
      );
    },
  });

  return (
    <div className="flex items-center space-x-2">
      <DangerButton
        className="w-44"
        onClick={() => {
          if (!loading)
            unpublishModel({
              variables: { modelId: props.modelId },
            });
        }}
        isLoading={loading}
        loadingText="Unpublishing ..."
      >
        Unpublish {props.materializationType}
      </DangerButton>
      <Tooltip
        content={`Unpublishing will return the model to draft status, and remove the ${props.materializationType} from your datawarehouse.`}
      >
        <div>
          <InformationCircleIcon className="h-5 w-5 dark:text-white" />
        </div>
      </Tooltip>
    </div>
  );
};

export default UnpublishModelButton;
