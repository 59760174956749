import { PropsWithChildren } from "react";

import { PrimaryButton } from "./Button";

type Props = PropsWithChildren<{
  title: string;
  buttonText: string;
  description: string;
  onClick: Function;
  icon: (props: React.ComponentProps<"svg">) => JSX.Element;
}>;

export default function EmptyTable({
  title,
  description,
  buttonText,
  onClick,
  icon: Icon,
  children,
}: Props) {
  return (
    <div className="grid h-full place-items-center">
      <div className="inline-block w-full max-w-sm text-center">
        <Icon className="mb-4 inline h-32 text-black dark:text-white" />

        <span className="mb-1 block text-base font-semibold dark:text-white">
          {title}
        </span>
        <span className="mb-8 block text-sm leading-relaxed dark:text-gray-200">
          {description}
        </span>
        <PrimaryButton onClick={() => onClick()}>{buttonText}</PrimaryButton>
        {children}
      </div>
    </div>
  );
}
