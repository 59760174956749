import { atom, useAtom } from "jotai";
import { Model } from "@/apollo/types";

interface SimpleRevELTSidebar {
  isOpen: boolean;
  model: Pick<Model, "id" | "name">;
  integrationId?: string;
}

const revELTSidebarAtom = atom<SimpleRevELTSidebar>({
  isOpen: false,
  model: { id: "", name: "" },
});

export const useSetupRevELTSimple = () => {
  const [currentState, setState] = useAtom(revELTSidebarAtom);

  const open = (model: Pick<Model, "id" | "name">, integrationId: string) => {
    setState({ isOpen: true, model, integrationId });
  };
  const close = () => {
    setState({ ...currentState, isOpen: false });
  };
  return { ...currentState, open, close };
};
