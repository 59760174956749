export const WarningIcon = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      {...props}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="#FFEEB4" />
      <circle cx="25" cy="25" r="15" fill="#FFD644" />
      <path
        d="M27.3808 29.235C28.9335 29.0512 30.459 28.6847 31.9258 28.1433C30.6836 26.7673 29.9973 24.9787 30 23.125V22.5417V22.5C30 21.1739 29.4732 19.9021 28.5355 18.9645C27.5979 18.0268 26.3261 17.5 25 17.5C23.6739 17.5 22.4021 18.0268 21.4645 18.9645C20.5268 19.9021 20 21.1739 20 22.5V23.125C20.0025 24.9789 19.3158 26.7675 18.0733 28.1433C19.5175 28.6767 21.04 29.0475 22.6192 29.235M27.3808 29.235C25.7992 29.4226 24.2008 29.4226 22.6192 29.235M27.3808 29.235C27.5009 29.6099 27.5308 30.0078 27.468 30.3964C27.4052 30.785 27.2515 31.1533 27.0195 31.4713C26.7874 31.7893 26.4836 32.048 26.1327 32.2263C25.7817 32.4047 25.3936 32.4976 25 32.4976C24.6064 32.4976 24.2183 32.4047 23.8673 32.2263C23.5164 32.048 23.2126 31.7893 22.9805 31.4713C22.7485 31.1533 22.5948 30.785 22.532 30.3964C22.4692 30.0078 22.4991 29.6099 22.6192 29.235M17.6033 21.25C17.8383 19.8541 18.4648 18.5537 19.41 17.5M30.59 17.5C31.5352 18.5537 32.1617 19.8541 32.3967 21.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
