import { ApolloClient } from "@apollo/client";
import { Route } from "@tanstack/react-location";
import { FindOneSyncDocument } from "@/apollo/types";
import { ReverseEltSyncBreadcrumb } from "@/components/elements/breadcrumbs/ReverseEltSyncBreadcrumb";
import LoadingError from "@/components/elements/routing/LoadingError";
import { LocationGenerics, getAccountContext } from "routes";

import EditReverseEltSyncPage from "./EditReverseEltSync";
import { NewRerverseEltSyncPage } from "./NewReverseEltSync";
import ReverseEltSyncLayout from "./ReverseEltSync";
import ReverseEltSyncsPage from "./ReverseEltSyncs";
import ReverseEltSyncsUsagePage from "./ReverseEltSyncsUsagePage";

export const exportsRoute = (
  client: ApolloClient<object>,
): Route<LocationGenerics> => {
  return {
    path: "reverse-etl",
    meta: {
      breadcrumb: () => "Reverse ETL",
    },
    children: [
      {
        path: "new",
        element: <NewRerverseEltSyncPage />,
        meta: {
          breadcrumb: () => "New",
        },
      },
      {
        path: "usage",
        element: <ReverseEltSyncsUsagePage />,
        meta: {
          breadcrumb: () => "Usage statistics",
        },
      },
      {
        path: ":syncId",
        meta: {
          breadcrumb: ({ syncId }) => (
            <ReverseEltSyncBreadcrumb syncId={syncId} />
          ),
        },
        children: [
          {
            path: "configuration",
            element: <EditReverseEltSyncPage />,
            meta: {
              breadcrumb: () => "Configuration",
            },
          },
          {
            element: <ReverseEltSyncLayout />,
            errorElement: <LoadingError />,
            loader: async (match) =>
              (
                await client.query({
                  query: FindOneSyncDocument,
                  variables: { syncId: match.params.syncId },
                  context: await getAccountContext(match.params.slug, client),
                })
              ).data,
          },
        ],
      },
      {
        element: <ReverseEltSyncsPage />,
      },
    ],
  };
};
