import welcomeGuide from "./welcome-guide-deprecated";
import draftModelGuide from "./draft-model";
import firstEltSyncGuide from "./FirstEltSynced";
import exampleModelGuide from "./example-model";

const Guides = new Map([
  [welcomeGuide.id, welcomeGuide],
  [exampleModelGuide.id, exampleModelGuide],
  [draftModelGuide.id, draftModelGuide],
  [firstEltSyncGuide.id, firstEltSyncGuide],
]);

export default Guides;
