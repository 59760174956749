import React from "react";

import classNames from "@/helpers/classNames";
import { IntegrationLogoBox } from "@/integrations";

function Container(props: React.ComponentProps<"div">) {
  return (
    <div
      className={classNames(
        "bg-white dark:bg-gray-800",
        "rounded border dark:border-gray-700",
        "p-3",
        "shadow-lg",
        props.className,
      )}
    >
      {props.children}
    </div>
  );
}

function Header({
  meta,
  name,
  integrationId,
  ...divProps
}: React.ComponentProps<"div"> & {
  meta?: string;
  name: string;
  integrationId: string;
}) {
  return (
    <div
      {...divProps}
      className={classNames("flex items-center gap-2", divProps.className)}
    >
      <IntegrationLogoBox id={integrationId} size="sm" />
      <div className="flex flex-col leading-none">
        <span className="text-xs text-gray-600 dark:text-gray-300">{meta}</span>
        <span>{name}</span>
      </div>
    </div>
  );
}

function Value({
  value,
  ...props
}: React.ComponentProps<"div"> & { value: number }) {
  return (
    <span className="font-semibold" {...props}>
      {new Intl.NumberFormat(navigator.language).format(value)}
    </span>
  );
}

const ChartTooltip = { Container, Header, Value };
export default ChartTooltip;
