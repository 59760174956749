import { Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ModifierPhases, Placement } from "@popperjs/core";
import { delay } from "lodash";
import { cloneElement, forwardRef, useEffect, useMemo } from "react";
import { useState } from "react";
import { createPortal } from "react-dom";
import { Modifier, usePopper } from "react-popper";
import mergeRefs from "@/utils/mergeRefs";

type TooltipProps = {
  children: JSX.Element;
  content: string | JSX.Element | null;
  disabled?: boolean;
  placement?: Placement;
  maxWidth?: string;
};

const Tooltip = forwardRef<any, TooltipProps>((props, forwardedRef) => {
  const [referenceElement, setReferenceElement] = useState<any>();
  const [popperElement, setPopperElement] = useState<any>();

  /**
   * Popper Modifiers must be defined in a useMemo hook to avoid infinite loop.
   * @url https://github.com/floating-ui/floating-ui/issues/794#issuecomment-736727000
   */
  const modifiers: Modifier<unknown>[] = useMemo(() => {
    return [
      { name: "preventOverflow", enabled: true },
      { name: "offset", options: { offset: [0, 4] } },
      {
        name: "maxWidth",
        enabled: true,
        phase: "beforeWrite" as ModifierPhases,
        requires: ["computeStyles"],
        fn: ({ state }) => {
          if (props.maxWidth) state.styles.popper.maxWidth = props.maxWidth;
        },
        effect({ state }) {
          if (props.maxWidth)
            state.elements.popper.style.maxWidth = props.maxWidth;
        },
      },
    ];
  }, [props.maxWidth]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "absolute",
    placement: props.placement ?? "top-start",
    modifiers,
  });

  const [visible, setVisible] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);

  useEffect(() => {
    if (props.disabled) {
      setHovered(false);
    }
  }, [props.disabled]);

  useEffect(() => {
    if (!hovered) {
      return;
    }
    const fn = () => setVisible(true);
    const timerId = delay(fn, 100);
    return () => clearTimeout(timerId);
  }, [hovered]);

  if (props.content === null) {
    return props.children;
  }

  return (
    <>
      {cloneElement(props.children, {
        ref: mergeRefs(forwardedRef, setReferenceElement),
        onMouseEnter: () => setHovered(true),
        onMouseLeave: () => setHovered(false),
      })}
      {visible &&
        createPortal(
          <Transition
            appear
            afterLeave={() => setVisible(false)}
            show={hovered}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="relative z-[9999]" // TODO: create a portal manager
          >
            <div
              className="max-w-sm rounded border border-gray-700 bg-gray-800 px-2 py-1 text-xs text-white dark:bg-gray-600"
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              {props.content}
            </div>
          </Transition>,
          document.body,
        )}
    </>
  );
});

export default Tooltip;

export const InformationIconTooltip = (props: {
  content: string | JSX.Element;
  iconClassName?: string;
}) => {
  return (
    <Tooltip content={props.content}>
      <div>
        <InformationCircleIcon
          className={props.iconClassName ?? "h-4 w-4 dark:text-white"}
        />
      </div>
    </Tooltip>
  );
};
