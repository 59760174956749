import classNames from "@/helpers/classNames";
import { Dashboard } from "@/pages/ModelTool/Visualize/VisualizationType";
import DashboardOptions from "./DashboardOptions";
import DashboardIcon from "@/components/icons/DashboardIcon";

export const DashboardItem = (props: {
  isHighlighted: boolean;
  isSelected: boolean;
  dashboard: Dashboard;
}) => {
  return (
    <div className="relative w-full grow">
      <div>
        <div className="flex items-center space-x-1.5">
          <DashboardIcon
            className={classNames(
              "h-4 w-4",
              "text-gray-600 dark:text-gray-200",
            )}
          />
          <div className="flex grow items-center overflow-hidden">
            <span className="truncate text-xs">{props.dashboard.name}</span>
          </div>

          <div className="ml-auto">
            <DashboardOptions dashboard={props.dashboard} />
          </div>
        </div>
      </div>
    </div>
  );
};
