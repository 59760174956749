import { LocationGenerics } from "routes";

import { EltSyncBreadcrumb } from "@/components/elements/breadcrumbs/EltSyncBreadcrumb";
import LoadingError from "@/components/elements/routing/LoadingError";
import { ApolloClient } from "@apollo/client";
import { Navigate, Outlet, Route } from "@tanstack/react-location";

import EditEltSyncPage from "./EditEltSync";
import EltSyncsPage from "./EltSyncsPage";
import EltSyncsUsagePage from "./EltSyncsUsagePage";

export const dataSourcesRoute = (
  client: ApolloClient<object>,
): Route<LocationGenerics> => {
  return {
    path: "sources",
    element: <Outlet />,
    meta: {
      breadcrumb: () => "Data Sources",
    },
    children: [
      {
        path: "usage",
        element: <EltSyncsUsagePage />,
        meta: {
          breadcrumb: () => "Usage statistics",
        },
      },
      {
        path: ":syncId",
        meta: {
          breadcrumb: ({ syncId }) => <EltSyncBreadcrumb syncId={syncId} />,
        },
        children: [
          {
            path: "configuration",
            element: <EditEltSyncPage />,
            meta: {
              breadcrumb: () => "Configuration",
            },
          },
          {
            // Redirect to `/sources` and open sync slide-over
            element: ({ params }) =>
              Promise.resolve(
                <Navigate<LocationGenerics>
                  to=".."
                  search={{
                    viewDataSource: params.syncId,
                  }}
                />,
              ),
            errorElement: <LoadingError />,
          },
        ],
      },
      {
        element: <EltSyncsPage />,
      },
    ],
  };
};
