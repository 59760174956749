import { useFlags } from "launchdarkly-react-client-sdk";
import { ComponentProps } from "react";

import { ActionButton, Button } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { Heading, TextMuted } from "@/components/elements/Typography";
import Center from "@/components/elements/layout/Center";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import {
  PromptSuggestionsList,
  usePromptSuggestionsFromSync,
} from "@/features/ai-prompt-suggestions-from-sync";
import cn from "@/helpers/classNames";
import { useMountEffect } from "@/hooks/useMountEffect";
import { IntegrationLogoBox } from "@/integrations";
import { useIntegration } from "@/integrations";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useCreateDraftModel } from "@/pages/ModelTool/QueryEditor/useModelDraft";
import { useAssistantSidebar } from "@/pages/ModelTool/model-generator/assistant";
import { useSocketEvent } from "@/socket/SocketContext";
import { ArrowPathIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/outline";

import { useStepsContext } from "../../../stepper/useSteps";
import { Content, Footer } from "../../components/Layout";
import { useDataSourceStepContext } from "../../contexts/DataSourceStepsContext";

export function SetupCompleteStep() {
  const { connection, createResult, onResetConfig, onCancelSetup } =
    useDataSourceStepContext();
  const { setCurrentStepIndex } = useStepsContext();
  const sourceIntegration = useIntegration(connection?.integrationId);
  const mixpanel = useMixpanel();
  const { onOpen: viewSync } = useViewDataSourceSlideOver();

  useMountEffect(() => {
    mixpanel.track("Data Source Setup Completed Step Viewed", {
      integration_id: sourceIntegration?.id,
    });
  });

  const { autoGenAiPromptSuggestionsEnabled } = useFlags();

  const { lastMessage } = useSocketEvent("elt-sync:table-defs-generated");
  const areTablesReady = lastMessage?.payload.id === createResult?.id;
  return (
    <>
      <Content className="flex flex-col">
        <div className="grow">
          <Center className="h-full w-full">
            <div className="flex flex-col items-center gap-4 text-center">
              <IntegrationLogoBox
                id={connection?.integrationId ?? ""}
                size="xl"
              />
              <p className="max-w-md text-lg">
                Successfully connected to data source
              </p>
              <p className="mb-12 max-w-md text-sm text-gray-700 dark:text-gray-500">
                Our servers will start syncing data from{" "}
                {sourceIntegration?.name}. It could take several minutes
                depending on the amount of data to be synced.
              </p>
            </div>
          </Center>
        </div>
        {autoGenAiPromptSuggestionsEnabled &&
          createResult &&
          createResult.destinationSchemaName && (
            <div className="grow">
              <div className="flex justify-center rounded border p-4">
                <div className="flex flex-col items-center">
                  <Heading className="mb-2 w-full text-center">
                    Start modelling your {sourceIntegration?.name} data with AI
                  </Heading>
                  <TextMuted className="mb-4 text-xs">
                    Ed can generate insights for you based on this data source.
                    Click on a suggestion to start a chat with Ed.
                  </TextMuted>
                  <div className="relative flex w-full max-w-xl flex-col items-center">
                    <AIAssistantDropOffSection
                      syncId={createResult.id}
                      destinationSchemaName={createResult.destinationSchemaName}
                      sourceIntegrationId={sourceIntegration?.id}
                      onClose={() => {
                        onCancelSetup({ forceClose: true });
                      }}
                    />
                    {!areTablesReady && (
                      <div className="absolute inset-0 flex items-center justify-center bg-white dark:bg-gray-800">
                        <div className="flex items-center gap-2">
                          <LoadingSpinner />
                          <TextMuted>
                            Waiting for tables to be generated
                          </TextMuted>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
      </Content>

      <Footer>
        <ActionButton
          variant="ghost"
          colorScheme="secondary"
          onClick={() => {
            onCancelSetup({ forceClose: true });
            mixpanel.track("Close DataSourceSlideOver Button Clicked", {
              step: "Setup Complete",
            });
          }}
        >
          Close
        </ActionButton>
        <ActionButton
          colorScheme="secondary"
          icon={<PlusIcon />}
          className="ml-auto"
          onClick={() => {
            setCurrentStepIndex(0);
            onResetConfig();
            mixpanel.track("Reset DataSourceSlideOver Button Clicked", {
              step: "Setup Complete",
            });
          }}
        >
          Set up new data source
        </ActionButton>
        <ActionButton
          colorScheme="secondary"
          iconRight={<ArrowRightIcon />}
          onClick={() => {
            if (!createResult) return;
            onCancelSetup({ forceClose: true });
            viewSync({ syncId: createResult.id });
            mixpanel.track("Go To Data Source Button Clicked", {
              step: "Setup Complete",
            });
          }}
        >
          Go to data source
        </ActionButton>
      </Footer>
    </>
  );
}

function AIAssistantDropOffSection(props: {
  syncId: string;
  destinationSchemaName: string;
  sourceIntegrationId?: string;
  onClose: () => void;
}) {
  const mixpanel = useMixpanel();
  const { promptSuggestions, isLoading, refetch } =
    usePromptSuggestionsFromSync({
      syncId: props.syncId,
      suggestionsCount: 4,
    });

  useMountEffect(() => {
    mixpanel.track("Generate insights with AI Clicked", {
      integration_id: props.sourceIntegrationId,
      source: "New Data Source Slideover",
    });
  });

  const createDraftModel = useCreateDraftModel();
  const openModelInTab = useOpenModelInTab();
  const { onOpen: onOpenAssistant } = useAssistantSidebar();

  const handlePromptSuggestionClicked: ComponentProps<
    typeof PromptSuggestionsList
  >["onClickSuggestion"] = async (prompt) => {
    mixpanel.track("AI Assistant Drop Off Clicked", {
      prompt_description: prompt.description,
      prompt: prompt.prompt,
      integration_id: props.sourceIntegrationId,
      table_count: prompt.dependencies.length,
      source: "New Data Source Slideover",
    });

    const draft = createDraftModel("");
    openModelInTab({ modelId: draft.id, type: "draft" });

    onOpenAssistant({
      input: `Using the following models: ${prompt.dependencies
        .map(
          (tableName) => `{{raw.${props.destinationSchemaName}.${tableName}}}`,
        )
        .join(", ")}\n\n${prompt.prompt}`,
    });

    props.onClose();
  };

  return (
    <>
      <PromptSuggestionsList
        prompts={promptSuggestions}
        onClickSuggestion={handlePromptSuggestionClicked}
      />
      <div className="mt-4">
        <TextMuted
          as={Button}
          variant="link"
          size="xs"
          onClick={() => refetch()}
          icon={<ArrowPathIcon />}
          className={cn(isLoading && "opacity-0")}
        >
          Recreate insights
        </TextMuted>
      </div>
    </>
  );
}
