import { useDataWarehouse } from "@/providers/account";

export function useDWSchemaName() {
  const dw = useDataWarehouse();

  switch (dw?.integrationId) {
    case "bigquery":
    case "weld-bigquery":
      return "dataset";
    case "snowflake":
    case "postgres":
      return "schema";
    default:
      return "schema";
  }
}

export function useDWDisplayName() {
  const dw = useDataWarehouse();

  switch (dw?.integrationId) {
    case "bigquery":
    case "weld-bigquery":
      return "BigQuery";
    case "snowflake":
      return "Snowflake";
    case "postgres":
      return "Postgres";
    default:
      return "Data warehouse";
  }
}

export function useDWCaseSensitive() {
  const dw = useDataWarehouse();

  switch (dw?.integrationId) {
    case "bigquery":
    case "weld-bigquery":
      return false;
    default:
      return true;
  }
}
