import dayjs from "dayjs";
import { useMemo } from "react";

import {
  SubscriptionStatus,
  useCurrentSubscriptionStateQuery,
} from "@/apollo/types";
import { useCurrentAccount } from "@/providers/account";

export function useSubscriptionStatus() {
  const { data } = useCurrentSubscriptionStateQuery();
  const { status, customerId, subscriptionId } =
    data?.currentSubscriptionState ?? {};
  const trial = useTrialStatus(status ?? undefined);
  return {
    status: status ?? null,
    trial,
    customerId: customerId ?? null,
    subscriptionId: subscriptionId ?? null,
  };
}

function useTrialStatus(subscriptionStatus: SubscriptionStatus | undefined) {
  const account = useCurrentAccount();

  return useMemo(() => {
    if (account.trialEndsAt == null) {
      return null;
    }

    const trialEndDate = new Date(account.trialEndsAt);
    const isExpired = dayjs(account.trialEndsAt).isBefore(dayjs());
    const daysLeft = Math.round(
      Math.max(0, dayjs(account.trialEndsAt).diff(dayjs(), "day", true)),
    );

    const isActive =
      subscriptionStatus === SubscriptionStatus.Freemium && !isExpired;

    return {
      isExpired,
      isActive,
      daysLeft,
      trialEndDate,
    };
  }, [account.trialEndsAt, subscriptionStatus]);
}

export function useIsFreemiumTrialExpired() {
  const { status, trial } = useSubscriptionStatus();
  return status === SubscriptionStatus.Freemium && trial?.isExpired === true;
}
