export const isBuilt = process.env.NODE_ENV === "production";
export const isDevelopment = process.env.NODE_ENV === "development";
export const isOnStagingDomain = window.location.hostname.includes("staging");
export const isOnPreviewDomain = new RegExp(
  "https://web-\\w+-weldhq.vercel.app",
).test(window.location.origin);
export const isOnLocalhost = ["localhost", "127.0.0.1"].includes(
  window.location.hostname,
);
export const isOnProductionDomain =
  isBuilt && !isOnStagingDomain && !isOnPreviewDomain && !isOnLocalhost;

const envFlags = {
  isBuilt,
  isOnProductionDomain,
  isDevelopment,
  isOnStagingDomain,
  isOnLocalhost,
};

export default envFlags;
