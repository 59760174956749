import { useListSyncsQuery } from "@/apollo/types";
import SyncsTable from "@/pages/ReverseEltSyncs/modules/SyncsTable";

import MainTabsLayout from "./MainTabsLayout";
import { RevEltSyncsEmptyState } from "./modules/RevEltSyncsEmptyState";

export default function ReverseEltSyncsPage() {
  const { data, loading } = useListSyncsQuery();
  if (loading) return <div>Loading...</div>;
  return (
    <MainTabsLayout>
      {data?.syncs.length === 0 ? (
        <RevEltSyncsEmptyState />
      ) : (
        <SyncsTable syncs={data?.syncs ?? []} />
      )}
    </MainTabsLayout>
  );
}
