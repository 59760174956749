import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import React from "react";
import { useDebouncedCallback } from "use-debounce";

import { PaneState } from "./useReflexAccordion";

export function usePaneResizeController(
  onResizeComplete: (state: Record<string, Pick<PaneState, "flex">>) => void,
) {
  const onResizeCompleteRef = useLatestValueRef(onResizeComplete);
  const stateRef = React.useRef<Record<string, Pick<PaneState, "flex">>>({});

  const completeHandler = React.useCallback(() => {
    // Call complete handler
    onResizeCompleteRef.current(stateRef.current);
    // Reset state
    stateRef.current = {};
  }, [onResizeCompleteRef]);

  const debouncedStopHandler = useDebouncedCallback(completeHandler, 50);

  const updateHandler = React.useCallback((name: string, flex: number) => {
    stateRef.current[name] = { flex };
  }, []);

  return React.useMemo(
    () => ({
      onUpdate: updateHandler,
      onStop: (name: string, flex: number) => {
        updateHandler(name, flex);
        debouncedStopHandler();
      },
    }),
    [updateHandler, debouncedStopHandler],
  );
}
