import { useSocketEvent } from "@/socket/SocketContext";

export function useTrialStartedListener(options: {
  onTrialStarted: (trialEndDate: Date) => void;
}) {
  useSocketEvent("account:trial-started", {
    async onMessage({ payload }) {
      options.onTrialStarted(payload.trialEndDate);
    },
  });
}
