import React, { Fragment, useEffect, useRef } from "react";

import cn from "@/helpers/classNames";
import { Dialog, Transition, TransitionEvents } from "@headlessui/react";

import { classed } from "../classed.config";
import { IconButton } from "./Button";
import { CloseButton, CloseButtonIcon } from "./CloseButton";

const SlideOverContext = React.createContext<{
  onClose: () => void;
  setSize: (size: SlideOverSize) => void;
  resetSize: () => void;
} | null>(null);

export const useSlideOverContext = () => {
  const ctx = React.useContext(SlideOverContext);
  if (!ctx) {
    throw new Error("SlideOverContext is undefined");
  }
  return ctx;
};

type SlideOverSize = "sm" | "md" | "lg" | "xl" | "3xl" | "4xl" | "full";

function getPanelWidth(size: SlideOverSize = "md") {
  switch (size) {
    case "sm":
      return "max-w-sm";
    case "md":
      return "max-w-xl";
    case "lg":
      return "max-w-3xl";
    case "xl":
      return "max-w-4xl";
    case "3xl":
      return "max-w-7xl";
    case "4xl":
      return "max-w-8xl";
    case "full":
      return "max-w-full";
  }
}

type Props = {
  children: React.ReactNode;
  show: boolean;
  onClose: () => void;
  bgOverlay?: boolean;
  size?: SlideOverSize;
  initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined;
} & Pick<TransitionEvents, "afterLeave">;

export function SlideOver(props: Props) {
  const initialSizeRef = useRef<SlideOverSize>(props.size ?? "md");
  const [size, setSize] = React.useState<SlideOverSize>(initialSizeRef.current);

  useEffect(() => {
    initialSizeRef.current = props.size ?? "md";
    setSize(initialSizeRef.current);
  }, [props.size]);

  const ctx = {
    onClose: props.onClose,
    setSize: (size: SlideOverSize) => setSize(size),
    resetSize: () => setSize(initialSizeRef.current),
  };

  return (
    <SlideOverContext.Provider value={ctx}>
      <Transition.Root
        show={props.show}
        as={Fragment}
        afterLeave={props.afterLeave}
      >
        <Dialog
          as="div"
          className="relative z-50"
          onClose={props.onClose}
          initialFocus={props.initialFocus}
        >
          {props.bgOverlay && <BackgroundOverlay />}
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={cn(
                    "pointer-events-auto relative flex w-screen flex-col border-l border-gray-200 bg-white shadow-xl transition-all dark:border-gray-700 dark:bg-gray-800",
                    getPanelWidth(size),
                  )}
                >
                  {props.children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </SlideOverContext.Provider>
  );
}

export const SlideOverHeader = classed.div(
  "shrink grow-0 basis-0 p-6 text-lg font-medium",
);
export const SlideOverFooter = classed.div(
  "flex justify-between border-t px-6 py-4 dark:border-gray-700",
);
export const SlideOverBody = classed.div("flex-1 overflow-auto px-6 py-2");

export function SlideOverCloseButton(
  props: Partial<React.ComponentProps<typeof IconButton>>,
) {
  const { onClose } = useSlideOverContext();
  return (
    <CloseButton
      {...props}
      icon={<CloseButtonIcon className="h-5 w-5" />}
      className={cn("absolute right-2 top-2 z-10", props.className)}
      onClick={() => onClose()}
      size="sm"
    />
  );
}

const BackgroundOverlay = () => {
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-in-out duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in-out duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="fixed inset-0 bg-black/40 transition-opacity"
        aria-hidden="true"
      />
    </Transition.Child>
  );
};
