import { merge } from "lodash";
import { noop } from "lodash";
import React from "react";
import { HandlerProps } from "react-reflex";

import { calcFlexValues, flexSumInvariant } from "./helpers";
import { ReflexAccordionProps } from "./ReflexAccordion";
import {
  ReflexAccordionItemProps,
  isReflexAccordionItem,
} from "./ReflexAccordionItem";
import { usePaneResizeController } from "./usePaneResizeController";
import { useRecalibrateOnContainerResize } from "./useRecalibrateOnContainerResize";
import {
  flexSum,
  getAllOpen,
  getAllOpenAfter,
  getAllOpenBefore,
  getAvailableSpace,
  getClosestOpenBefore,
  getMaxAvailableSpace,
} from "./utils";

export const ReflexAccordionContext = React.createContext<
  ReturnType<typeof useReflexAccordion>
>({
  panes: [],
  totalFlex: 0,
  remainderFlex: 0,
  collapsedPaneFlex: 0,
  togglePane: noop,
  onStartResizePane: noop,
  onStopResizePane: noop,
  onResizePane: noop,
});

export const useReflexAccordionContext = () =>
  React.useContext(ReflexAccordionContext);

export type PaneState = {
  index: number;
  isCollapsed: boolean;
  flex: number;
  flexBeforeCollapse: number;
  name: string;
};

function initializePaneState(children: React.ReactNode) {
  const childrenArr = React.Children.toArray(children);
  const paneCount = childrenArr.filter(isReflexAccordionItem).length;
  return childrenArr.reduce<Record<string, PaneState>>((acc, child, index) => {
    if (isReflexAccordionItem(child)) {
      const name = `pane-${index}`;
      const flex = 1 / paneCount;
      acc[name] = {
        index: index,
        isCollapsed: false,
        flex: flex,
        flexBeforeCollapse: flex,
        name,
      };
    }
    return acc;
  }, {});
}

export function useReflexAccordion(
  props: ReflexAccordionProps,
  containerHeight: number | undefined,
) {
  const [state, setState] = React.useState<Record<string, PaneState>>(() => {
    return initializePaneState(props.children);
  });

  const { panes, totalFlex } = React.useMemo(() => {
    const panes = Object.values(state);
    const totalFlex = flexSum(panes);
    return { panes, totalFlex };
  }, [state]);

  const { collapsedPaneFlex, minPaneFlex } = calcFlexValues({
    containerHeight,
    collapsedPaneSize: props.collapsedPaneSize,
    minPaneSize: props.minPaneSize,
  });

  /**
   * This is a helper hook which provides a root `onStopResize` handler
   * which returns the state of all panes. This is not provided in `react-reflex`.
   * Additionally there is a bug in `react-reflex` where not all panes being resized will
   * trigger the `onStopResize` handler when resizing stops - this hook works around that.
   */
  const resizeController = usePaneResizeController((newState) => {
    setState((state) => merge({}, state, newState));
  });

  /**
   * When the `containerHeight` changes we need to recalculate the
   * flex value of the absolute values (`collapsedPaneSize` and `minPaneSize`)
   * as well as the pane flex values
   */
  useRecalibrateOnContainerResize({
    state,
    containerHeight,
    collapsedPaneSize: props.collapsedPaneSize,
    minPaneSize: props.minPaneSize,
    onChange: (newState) => {
      setState((state) => merge({}, state, newState));
    },
    recalibrateRateMs: 20,
  });

  const onStartResizePane = React.useCallback(() => {
    // Not used
  }, []);

  const onStopResizePane = React.useCallback(
    ({ component }: HandlerProps) => {
      const { name, flex } = component.props as ReflexAccordionItemProps;
      if (name == null || flex == null) return;
      resizeController.onStop(name, flex);
    },
    [resizeController],
  );

  const onResizePane = React.useCallback(
    ({ component }: HandlerProps) => {
      const { name, flex } = component.props as ReflexAccordionItemProps;
      if (name == null || flex == null) return;
      resizeController.onUpdate(name, flex);
    },
    [resizeController],
  );

  const togglePane = React.useCallback(
    (name: string, shouldOpenPaneArg?: boolean) => {
      const pane = state[name];

      if (
        shouldOpenPaneArg !== undefined &&
        pane.isCollapsed !== shouldOpenPaneArg
      ) {
        // Nothing changed
        return;
      }

      const panes = Object.values(state);
      const openPanesAfterCurrent = getAllOpenAfter(pane.index, panes);
      const shouldCollapse =
        shouldOpenPaneArg !== undefined
          ? !shouldOpenPaneArg
          : !pane.isCollapsed;

      const newState = {
        ...state,
      };

      if (shouldCollapse) {
        const isLastOpenPane = openPanesAfterCurrent.length === 0;
        if (isLastOpenPane) {
          // Give flex of pane to closest open pane
          const closestOpenPane = getClosestOpenBefore(pane.index, panes);
          if (closestOpenPane) {
            newState[closestOpenPane.name].flex +=
              newState[name].flex - collapsedPaneFlex;
          }
        } else {
          // Split flex of pane among all open panes
          const flexSplit =
            (pane.flex - collapsedPaneFlex) / openPanesAfterCurrent.length;
          openPanesAfterCurrent.forEach((x) => {
            newState[x.name].flex += flexSplit;
          });
        }
        newState[name].flexBeforeCollapse = pane.flex;
        newState[name].flex = collapsedPaneFlex;
      } else {
        const allOpenPanes = getAllOpen(panes);
        if (allOpenPanes.length === 0) {
          // There are no open panes, i.e. the pane can take up all the remaining space (minus the combined size of the collapsed panes)
          newState[name].flex =
            1 - flexSum(panes.filter((x) => x.name !== pane.name));
        } else {
          const availableSpace = getMaxAvailableSpace(
            openPanesAfterCurrent,
            minPaneFlex,
          );
          const spaceToAllocate = pane.flexBeforeCollapse - collapsedPaneFlex;
          if (availableSpace < spaceToAllocate) {
            // It is not possible to allocate enough space from the succeeding panes.
            // Therefore, squeeze the succeeding panes to the minimum size and start
            // allocating space from the preceeding panes.

            // squeeze succeeding panes to minimum size
            openPanesAfterCurrent.forEach((x) => {
              newState[x.name].flex = minPaneFlex;
            });
            const openPanesBeforeCurrent = getAllOpenBefore(pane.index, panes);
            let accumulatedSpace = availableSpace;
            /**
             * Free up space for the expanding pane by iterating, in reverse order,
             * through the open panes before this pane and take as much space
             * as possible step by step until enough space has been accumulated.
             */
            let currentIndex = openPanesBeforeCurrent.length - 1;
            while (currentIndex >= 0) {
              const siblingPane = openPanesBeforeCurrent[currentIndex];
              const availableSpace = getAvailableSpace(
                siblingPane,
                minPaneFlex,
              );
              if (accumulatedSpace + availableSpace >= spaceToAllocate) {
                // we've got enough space free'ed up now
                const necessarySpace = spaceToAllocate - accumulatedSpace;
                newState[siblingPane.name].flex -= necessarySpace;
                accumulatedSpace += necessarySpace;
                break;
              } else {
                // squeeze to min size
                newState[siblingPane.name].flex = minPaneFlex;
                accumulatedSpace += availableSpace;
              }
              currentIndex--;
            }
            newState[name].flex = accumulatedSpace + collapsedPaneFlex;
          } else {
            // Each open pane will be reduced in size by a fraction of the size of the pane which is being opened.
            // The fraction is relative to the size of the given pane.
            const targetPanesTotalFlex = flexSum(openPanesAfterCurrent);
            openPanesAfterCurrent.forEach((x, i, arr) => {
              newState[x.name].flex -=
                pane.flexBeforeCollapse * (x.flex / targetPanesTotalFlex) -
                collapsedPaneFlex / arr.length;
            });
            newState[name].flex = pane.flexBeforeCollapse;
          }
        }
      }
      newState[name].isCollapsed = shouldCollapse;

      setState(newState);
      flexSumInvariant(Object.values(newState));
    },
    [state, collapsedPaneFlex, minPaneFlex],
  );

  return {
    onStartResizePane,
    onStopResizePane,
    onResizePane,
    togglePane,
    collapsedPaneFlex,
    panes,
    totalFlex,
    remainderFlex: Math.max(0, 1 - totalFlex),
  };
}
