import { useEffect, useState } from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";

import { TaxIdData } from "@/apollo/types";
import Select from "@/components/elements/Select";
import FieldLabel from "@/components/primitives/InputLabel";
import { Input } from "@/components/primitives/input";
import {
  StripeFieldLabel,
  StripeInput,
  StripeSelect,
} from "@/features/billing/plans/create-subscription/components/StripeInput";

import { useCountryOptions } from "./useCountryOptions";
import { useTaxIdOptionsForCountry } from "./useTaxIdOptions";

export type BillingInfoFormData = {
  email: string;
  companyName: string;
  address: {
    line1: string;
    postalCode: string;
    city: string;
    country: string;
  };
  taxIdData?: {
    type: string;
    country: string;
    value: string;
  };
};

export function BillingInfoForm() {
  const { register } = useFormContext<BillingInfoFormData>();
  const countryOptions = useCountryOptions();
  return (
    <>
      <div>
        <FieldLabel required htmlFor="companyName">
          Company name
        </FieldLabel>
        <Input
          {...register("companyName", { required: true })}
          id="companyName"
        />
      </div>

      <div>
        <FieldLabel required htmlFor="email">
          Email
        </FieldLabel>
        <Input {...register("email", { required: true })} id="email" />
      </div>

      <div>
        <FieldLabel required htmlFor="address.country">
          Country
        </FieldLabel>
        <Controller<BillingInfoFormData>
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              onBlur={onBlur}
              onChange={(option: { value: string; label: string }) =>
                onChange(option.value)
              }
              value={countryOptions.find((o) => o.value === value)}
              options={countryOptions}
              isClearable={false}
              placeholder="Select country"
            />
          )}
          rules={{ required: true }}
          name="address.country"
        />
      </div>

      <div>
        <FieldLabel required htmlFor="address.line1">
          Address
        </FieldLabel>
        <Input
          {...register("address.line1", { required: true })}
          id="address.line1"
        />
      </div>

      <div className="flex space-x-4">
        <div className="w-32">
          <FieldLabel required htmlFor="address.postalCode">
            Postal code
          </FieldLabel>
          <Input
            {...register("address.postalCode", { required: true })}
            id="address.postalCode"
          />
        </div>

        <div className="flex-1">
          <FieldLabel required htmlFor="address.city">
            City
          </FieldLabel>
          <Input
            {...register("address.city", { required: true })}
            id="address.city"
          />
        </div>
      </div>
    </>
  );
}

type TaxIdOption = {
  label: string;
  value: string;
  data: TaxIdData;
};

export function TaxIdForm() {
  const { register, watch, setValue, setFocus } =
    useFormContext<BillingInfoFormData>();
  const { touchedFields } = useFormState<BillingInfoFormData>();

  const billingCountry = watch("address.country");
  const taxIdCountry = watch("taxIdData.country");

  const options = useTaxIdOptionsForCountry(billingCountry);

  const [expandedTaxIdData, setExpandedTaxIdData] = useState<
    TaxIdOption["data"] | null
  >(() => {
    return (
      options.find(
        (x) =>
          x.data.countryCode.toLowerCase() === billingCountry?.toLowerCase(),
      )?.data ?? null
    );
  });

  useEffect(() => {
    if (billingCountry !== taxIdCountry) {
      if (options.length > 0) {
        setValue("taxIdData.type", options[0].value);
        setValue("taxIdData.country", options[0].data.countryCode);
        setExpandedTaxIdData(options[0].data);
      } else {
        setValue("taxIdData", undefined);
        setExpandedTaxIdData(null);
      }
    }
  }, [options, setValue, billingCountry, taxIdCountry]);

  const taxIdTypeIsTouched = !!touchedFields.taxIdData?.type;
  const taxIdFromBillingCountry = options.find((x) => {
    return x.data.countryCode.toLowerCase() === billingCountry?.toLowerCase();
  });

  useEffect(() => {
    // Update tax ID type automatically when billing country changes unless it has not been touched by the user
    if (taxIdTypeIsTouched) {
      return;
    }
    if (taxIdFromBillingCountry) {
      setValue("taxIdData.type", taxIdFromBillingCountry.data.enum);
      setValue("taxIdData.country", taxIdFromBillingCountry.data.countryCode);
      setExpandedTaxIdData(taxIdFromBillingCountry.data);
    }
  }, [taxIdFromBillingCountry, taxIdTypeIsTouched, setValue]);

  if (options.length === 0) {
    return null;
  }

  return (
    <div>
      <StripeFieldLabel htmlFor="taxIdData.value">Tax ID</StripeFieldLabel>
      <div className="grid grid-cols-2 gap-2">
        <Controller<BillingInfoFormData>
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <StripeSelect
                value={
                  options.find(
                    (o) =>
                      o.value === value &&
                      o.data.countryCode === expandedTaxIdData?.countryCode,
                  )?.value
                }
                onChange={(e) => {
                  const option = options.find(
                    (o) =>
                      o.value === e.target.value &&
                      o.data.countryCode === expandedTaxIdData?.countryCode,
                  );
                  if (!option) {
                    return;
                  }
                  onChange(option.value);
                  setValue("taxIdData.country", option.data.countryCode);
                  // Save additional data locally
                  setExpandedTaxIdData(option.data);
                  // Focus tax ID value field
                  setFocus("taxIdData.value");
                }}
                onBlur={onBlur}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </StripeSelect>
            );
          }}
          rules={{ required: true }}
          name="taxIdData.type"
        />
        <StripeInput
          id="taxIdData.value"
          placeholder={expandedTaxIdData?.example}
          {...register("taxIdData.value", { required: true })}
        />
      </div>
    </div>
  );
}
