import { ComponentProps, forwardRef, useEffect, useRef } from "react";

import classNames from "@/helpers/classNames";
import mergeRefs from "@/utils/mergeRefs";

export const Checkbox = forwardRef<
  HTMLInputElement,
  ComponentProps<"input"> & {
    indeterminate?: boolean;
  }
>(({ indeterminate, ...props }, ref) => {
  const cRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (cRef.current) {
      cRef.current.indeterminate = indeterminate === true;
    }
  }, [indeterminate]);

  return (
    <input
      {...props}
      type="checkbox"
      className={classNames(
        "form-checkbox",
        "rounded-sm",
        "dark:bg-gray-700",
        "checked:bg-current dark:checked:bg-current",
        "border-gray-300 dark:border-gray-600 dark:ring-offset-gray-800 dark:focus:ring-blue-600",
        props.className,
        props.disabled && "cursor-not-allowed opacity-30",
      )}
      ref={mergeRefs(ref, cRef)}
    />
  );
});
