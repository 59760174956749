import { FormType } from "@/integrations";

import FormFactory from "./FormFactory";
import { TextMuted } from "@/components/elements/Typography";

export default function OAuthForm({
  field,
}: {
  field: Extract<FormType, { type: "oauth" }>;
}) {
  const { label } = field;

  return (
    <div>
      <TextMuted as="div" className="text-sm">
        Connect to {label} by clicking authorize, this will take you to {label}
        's authorization flow
      </TextMuted>
      {field.fields && field.fields.length > 0 && (
        <div className="mt-6">
          {field.fields?.map((f, i) => <FormFactory key={i} form={f} />)}
        </div>
      )}
    </div>
  );
}
