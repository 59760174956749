import { Menu } from "@headlessui/react";
import classNames from "@/helpers/classNames";
import React, { ComponentProps, forwardRef } from "react";

type Props = {
  onClick: (e: React.MouseEvent) => void;
  text: string;
  disabled?: boolean;
  size?: "sm" | "lg";
  danger?: boolean;
  tooltipText?: string;
} & ComponentProps<"button">;

const MenuItem = forwardRef<HTMLButtonElement, Props>(
  ({ text, onClick, size, disabled, danger, tooltipText, ...rest }, ref) => {
    return (
      <Menu.Item ref={ref}>
        {({ active }) => (
          <button
            type="button"
            onClick={(e) => {
              if (disabled) return;
              e.stopPropagation();
              onClick(e);
            }}
            className={classNames(
              active ? "bg-gray-100 dark:bg-gray-700" : "",
              size === "sm" ? "px-3 py-2 text-xs" : "px-4 py-2 text-sm",
              disabled ? "cursor-default opacity-50" : "text-gray-900",
              danger ? "text-red-500" : "",
              "flex w-full text-left dark:border-gray-600 dark:text-white",
            )}
            {...rest}
          >
            <span className="flex-grow">{text}</span>
            {tooltipText && (
              <span className="flex-shrink-0 text-2xs text-gray-400 dark:text-gray-500">
                {tooltipText}
              </span>
            )}
          </button>
        )}
      </Menu.Item>
    );
  },
);

export default MenuItem;
