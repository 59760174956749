import { MakeGenerics, useNavigate, useSearch } from "@tanstack/react-location";
import {
  CurrentUserWithAccountsDocument,
  useAcceptInvitationMutation,
} from "@/apollo/types";
import { SecondaryButton } from "@/components/elements/Button";
import Center from "@/components/elements/layout/Center";
import {
  DeleteIcon,
  ErrorIcon,
  SuccessIcon,
  WarningIcon,
} from "@/components/icons/graphic";
import { useMountEffect } from "@/hooks/useMountEffect";
import SimplePageLayout from "@/layouts/SimplePageLayout";
import React from "react";
import * as routeHelpers from "router/routeHelpers";
import { useNavigateToWorkspace } from "router/useNavigateToWorkspace";

import PageContainer from "../Setup/components/PageContainer";
import { Heading } from "../Setup/components/Typography";

type LocationGenerics = MakeGenerics<{
  Search: {
    invitation: string;
  };
}>;

type AcceptInvitationStatus =
  | "validating"
  | "validation_succeeded"
  | "validation_failed"
  | "code_missing";

function AcceptInvitationPage() {
  const { invitation: invitationId } = useSearch<LocationGenerics>();
  const navigate = useNavigate();
  const navigateToWorkspace = useNavigateToWorkspace();

  const [status, setStatus] = React.useState<AcceptInvitationStatus | null>(
    null,
  );

  const [acceptInvitation] = useAcceptInvitationMutation({
    onCompleted: (data) => {
      setStatus("validation_succeeded");
      setTimeout(() => {
        navigateToWorkspace.byId(data.useInvitation.accountId);
      }, 1000);
    },
    onError: () => {
      setStatus("validation_failed");
    },
    refetchQueries: [CurrentUserWithAccountsDocument],
    awaitRefetchQueries: true,
  });

  useMountEffect(() => {
    if (!invitationId) {
      setStatus("code_missing");
      return;
    }
    acceptInvitation({
      variables: {
        invitationId,
      },
    });
  });

  const result = React.useMemo(() => {
    switch (status) {
      case "validation_succeeded":
        return {
          Icon: SuccessIcon,
          message: "Great! Let's get started.",
        };
      case "validation_failed":
        return {
          Icon: ErrorIcon,
          message: "Your invitation was not valid",
        };
      case "validating": {
        return {
          Icon: WarningIcon,
          message: "Checking your invite ...",
        };
      }
      case "code_missing": {
        return {
          Icon: DeleteIcon,
          message: "Invitation code missing ...",
        };
      }
    }
  }, [status]);

  return (
    <SimplePageLayout>
      <Center className="h-full">
        <PageContainer size="sm">
          {result && (
            <>
              <result.Icon className="mb-5 w-12" />
              <Heading className="mb-6">{result.message}</Heading>
            </>
          )}
          <div className="mt-8 flex flex-col items-center space-y-8">
            <SecondaryButton
              className="p-2"
              onClick={() => navigate({ to: routeHelpers.workspacesRoute() })}
            >
              Go to accounts
            </SecondaryButton>
          </div>
        </PageContainer>
      </Center>
    </SimplePageLayout>
  );
}

export default AcceptInvitationPage;
