import { useEffect } from "react";

import { Button } from "@/components/elements/Button";
import TabLayout from "@/components/layouts/TabLayout";
import { useSettingsTabs } from "@/pages/Settings/hooks/useSettingsTabs";
import { useToast } from "@/providers/ToastProvider";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Link, useSearch } from "@tanstack/react-location";

import ConnectionsTable from "./ConnectionsTable";

export const ConnectionsPage = () => {
  const tabs = useSettingsTabs();

  const { error } = useSearch<{ Search: { error: string } }>();

  const toast = useToast();
  useEffect(() => {
    if (error) {
      toast("Something went wrong", error, "error");
    }
  }, [error, toast]);

  return (
    <TabLayout
      tabs={tabs}
      action={
        <Button
          as={Link}
          to="./new"
          tabIndex={-1}
          icon={<PlusIcon className="w-4" />}
          variant="solid"
          colorScheme="primary"
        >
          New Connector
        </Button>
      }
    >
      <div className="w-full max-w-screen-xl">
        <ConnectionsTable />
      </div>
    </TabLayout>
  );
};
