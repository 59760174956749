import { useSearch } from "@tanstack/react-location";
import CustomTwoPaneLayout from "@/components/modules/CustomTwoPaneLayout";
import { useAtom } from "jotai";
import { ReactElement, useCallback, useEffect } from "react";

import {
  EditorBottomPane,
  EditorTabBarRatioAtom,
  EditorTabBarSettingAtom,
  ModelBarOption,
  ModelEditorTabBar,
  useEditorMenuOptions,
} from "./EditorBottomBar";

export default function EditorWithBar(props: { children: ReactElement }) {
  const editorMenuItems = useEditorMenuOptions();
  const [ratio, setRatio] = useAtom(EditorTabBarRatioAtom);
  const [currentSetting, setCurrentSetting] = useAtom(EditorTabBarSettingAtom);

  const isOpen = ratio > 5;

  const openPanel = () => setRatio(55);
  const closePanel = () => setRatio(0);

  const search = useSearch();
  useEffect(() => {
    if (search.openpane) {
      const setting = editorMenuItems.find(
        (item) => item.setting === search.openpane,
      );
      if (setting) {
        setCurrentSetting(setting.setting);
        setRatio(55);
      }
    }
  }, [search, setCurrentSetting, setRatio, editorMenuItems]);

  //Close the bottom panel if the current setting is not in the menu items:
  useEffect(() => {
    const currentSettingExists = editorMenuItems.some(
      (item) => item.setting === currentSetting,
    );
    if (!currentSettingExists) {
      setCurrentSetting(ModelBarOption.QueryResult);
      setRatio(0);
    }
  }, [editorMenuItems, currentSetting, setCurrentSetting, setRatio]);

  return (
    <CustomTwoPaneLayout
      handleComponent={
        <ModelEditorTabBar
          currentSetting={currentSetting}
          setCurrentSetting={(setting) => {
            if (isOpen && setting.setting === currentSetting) {
              closePanel();
            }
            if (!isOpen) {
              openPanel();
            }
            setCurrentSetting(setting.setting);
          }}
          toggleOpen={() => {
            setRatio(isOpen ? 0 : 55);
          }}
          isOpen={isOpen}
          menuItems={editorMenuItems}
        />
      }
      bottomPaneVisible={true}
      topPaneComponent={props.children}
      bottomPaneComponent={
        <div className="relative h-full overflow-hidden border-t dark:border-gray-700">
          <EditorBottomPane setting={currentSetting} />
        </div>
      }
      bottomRatio={ratio}
      setBottomRatio={setRatio}
    />
  );
}

export const useOpenPaneOnExecute = () => {
  const [, setRatio] = useAtom(EditorTabBarRatioAtom);
  const [currentSetting, setCurrentSetting] = useAtom(EditorTabBarSettingAtom);

  const handleOpen = useCallback(
    (setting?: ModelBarOption) => {
      setRatio((p) => (p < 10 ? 55 : p));
      if (setting && currentSetting !== ModelBarOption.QueryResult) {
        setCurrentSetting(setting);
        return;
      }
      if (currentSetting !== ModelBarOption.Visualize)
        setCurrentSetting(ModelBarOption.QueryResult);
    },
    [currentSetting, setCurrentSetting, setRatio],
  );
  return handleOpen;
};
