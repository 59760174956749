import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { reverse, sortBy } from "lodash";
import { useMemo, useState } from "react";
import type { CellProps, Column } from "react-table";

import {
  InvitationsDocument,
  InvitationsQuery,
  useInvitationsQuery,
  useRemoveInvitationMutation,
} from "@/apollo/types";
import ConfirmDeleteModal from "@/components/elements/ConfirmDeleteModal";
import DefaultTable from "@/components/elements/DefaultTable";
import EmptyTable from "@/components/elements/EmptyTable";
import MenuItem from "@/components/elements/MenuItem";
import { UserGraphicIcon } from "@/components/icons/ContextualGraphicIcon";
import TableMenu from "@/components/modules/TableMenu";
import { useUsersLimitReachedUpgradeDialog } from "@/features/subscription";
import useDeleteItem from "@/hooks/useDeleteItem";
import useLoadingManager from "@/hooks/useLoadingManager";
import { useNavigate } from "@tanstack/react-location";

dayjs.extend(relativeTime);

const dateFormat = "YYYY-MM-DD";
const dateTimeFormat = `${dateFormat} HH:mm`;

type Row = InvitationsQuery["invitations"][0];

export default function InvitationTable() {
  const columns = useMemo<Column<Row>[]>(
    () => [
      {
        Header: "Email",
        Cell: ({ row }: CellProps<Row>) => {
          return <span>{row.original.email}</span>;
        },
        accessor: "email",
      },
      {
        Header: "First name",
        Cell: ({ row }: CellProps<Row>) => {
          return <span>{row.original.firstName}</span>;
        },
        accessor: "firstName",
      },
      {
        Header: "Last name",
        Cell: ({ row }: CellProps<Row>) => {
          return <span>{row.original.lastName}</span>;
        },
        accessor: "lastName",
      },
      {
        Header: "Role",
        Cell: ({ row }: CellProps<Row>) => {
          return <span>{row.original.role ?? "none"}</span>;
        },
        accessor: "used",
      },
      {
        Header: "Expires at",
        Cell: ({ row }: CellProps<Row>) => (
          <div title={dayjs(row.original.expiresAt).format(dateTimeFormat)}>
            {dayjs(row.original.expiresAt).format(dateFormat)}
          </div>
        ),
        accessor: "expiresAt",
      },
      {
        Header: "Created at",
        Cell: ({ row }: CellProps<Row>) => (
          <div>{dayjs(row.original.createdAt).format(dateFormat)}</div>
        ),
        accessor: "createdAt",
      },
      {
        Header: "Updated at",
        Cell: ({ row }: CellProps<Row>) => (
          <div title={dayjs(row.original.updatedAt).format(dateTimeFormat)}>
            {dayjs(row.original.updatedAt).format(dateFormat)}
          </div>
        ),
        accessor: "updatedAt",
      },
      {
        Header: "",
        Cell: ({ row }: CellProps<Row>) => (
          <div className="flex justify-end">
            <InvitationMenu invitationId={row.original.id} />
          </div>
        ),
        accessor: "id",
      },
    ],
    [],
  );

  const { data, error, loading } = useInvitationsQuery();

  const tableData = reverse(sortBy(data?.invitations, "createdAt")) || [];

  const navigate = useNavigate();
  const { validateLimitReached, limitReachedDialog } =
    useUsersLimitReachedUpgradeDialog();

  return useLoadingManager({ loading, message: `Loading invitations` }, () => {
    if (!data?.invitations.length)
      return (
        <>
          <EmptyTable
            icon={UserGraphicIcon}
            title="Invite your friends"
            description="Invite your colleagues and friends to join you in this amazing WELD account"
            buttonText="+ Invite new user"
            onClick={() => {
              validateLimitReached(() => {
                navigate({
                  to: "./new",
                });
              });
            }}
          />
          {limitReachedDialog()}
        </>
      );

    if (error) {
      return (
        <>
          An error happened while loading the users. Try again or contact admin
          if it persists.
        </>
      );
    }

    return <DefaultTable columns={columns} data={tableData} />;
  });
}

const InvitationMenu = ({ invitationId }: { invitationId: string }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleDelete = useDeleteItem({
    title: "invitation",
    variables: { invitationId },
    mutation: useRemoveInvitationMutation,
    refetchQueries: [{ query: InvitationsDocument }],
  });
  return (
    <>
      <ConfirmDeleteModal
        title="invitation"
        onConfirm={handleDelete}
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
      />
      <TableMenu>
        <MenuItem
          text="Delete invitation"
          onClick={() => setShowConfirmModal(true)}
        />
      </TableMenu>
    </>
  );
};
