import Content from "@/components/elements/layout/Content";
import Breadcrumbs from "@/components/modules/Breadcrumbs";
import Tabs, { Tab } from "@/components/modules/Tabs";
import { TaskActionBar } from "@/components/modules/TaskActionBar";
import PageHeader from "@/components/elements/PageHeader";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  tabs: Tab[];
  action?: JSX.Element;
  noCancel?: boolean;
}>;

export default function TabTaskLayout({
  children,
  tabs,
  action,
  noCancel,
}: Props) {
  return (
    <>
      <Content>
        <PageHeader.Container>
          <Breadcrumbs />
        </PageHeader.Container>
        <Tabs tabs={tabs} action={action} />
        <div>{children}</div>
      </Content>
      <TaskActionBar mode="normal" noCancel={noCancel} />
    </>
  );
}
