import React from "react";

export default function FormatIcon(props: React.ComponentProps<"svg">) {
  return (
    <svg
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      fill="currentColor"
      role="img"
      height="1em"
      width="1em"
      {...props}
    >
      <path
        d="M2.15381 2.33301H11.7788M2.15381 5.39551H6.96631M2.15381 8.45801H11.7788M2.15381 11.5205H6.96631"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
