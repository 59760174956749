import {
  ArchiveBoxIcon,
  DocumentPlusIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { useMatches, useNavigate } from "@tanstack/react-location";
import { useHasDeletedModelsQuery } from "@/apollo/types";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { useReflexAccordionItemContext } from "@/components/elements/ReflexAccordion/ReflexAccordionItem";
import Tooltip from "@/components/elements/Tooltip";
import classNames from "@/helpers/classNames";
import { useArrowKeyFocusNavigation } from "@/hooks/useArrowKeyFocusNavigation";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useCurrentAccount } from "@/providers/account";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { LocationGenerics } from "routes";

import {
  useCreateDraftModel,
  useHasArchivedDrafts,
} from "../../QueryEditor/useModelDraft";
import { PaneActionButton } from "../components/PaneActionButton";
import {
  PaneEmptyState,
  PaneEmptyStateButton,
  PaneEmptyStateText,
} from "../components/PaneEmptyState";
import { PaneContent, PaneHeader } from "../components/SidebarPane";
import {
  GroupItemType,
  SidebarItemFactory,
  SidebarItemType,
} from "../SidebarFactory";
import { useNewSidebarItemState } from "../useNewSidebarItemState";
import { useSidebarFolders } from "../useSidebarFolders";
import ModelsArchiveModal from "./ModelsArchive";
import { useDraftItems } from "./useDraftItems";

export const DraftsPane = React.memo(function DraftsPaneMemo() {
  const mixpanel = useMixpanel();
  const matches = useMatches<LocationGenerics>();

  const hasArchivedDrafts = useHasArchivedDrafts();
  const { data } = useHasDeletedModelsQuery();

  const newItemState = useNewSidebarItemState();

  const showArchiveButton =
    hasArchivedDrafts || (data?.hasDeletedModels.value ?? false);

  const modelId = useMemo(
    () => matches[1]?.params.modelId ?? matches[2]?.params.draftId,
    [matches],
  );

  const { items, loading } = useDraftItems();

  const { isOpen, toggle: togglePane } = useReflexAccordionItemContext();
  const navigate = useNavigate();

  const { slug } = useCurrentAccount();

  const createDraft = useCreateDraftModel();
  const handleNew = async () => {
    const draft = createDraft();
    navigate({ to: `/${slug}/editor/draft/${draft.id}` });
    togglePane(true);
    mixpanel.track("Draft Created", {
      source: "sidebar",
    });
  };

  const archiveDisclosure = useDisclosure();
  return (
    <>
      <PaneHeader
        open={isOpen}
        toggle={togglePane}
        actions={
          <div className="flex gap-2">
            {showArchiveButton && (
              <Tooltip content="View archived models">
                <PaneActionButton
                  icon={<ArchiveBoxIcon />}
                  onClick={() => {
                    togglePane(true);
                    archiveDisclosure.onOpen();
                  }}
                />
              </Tooltip>
            )}
            <Tooltip content="Create a new model">
              <PaneActionButton
                onClick={handleNew}
                variant="solid"
                icon={<PlusIcon />}
                colorScheme="primary"
              />
            </Tooltip>
          </div>
        }
      >
        <div className="truncate">Drafts</div>

        <ModelsArchiveModal
          open={archiveDisclosure.isOpen}
          onClose={archiveDisclosure.onClose}
        />
      </PaneHeader>
      <PaneContent>
        {loading ? (
          <div className="flex items-center justify-center px-4 py-2">
            <LoadingSpinner />
          </div>
        ) : items.length === 0 ? (
          <PaneEmptyState>
            <PaneEmptyStateText>
              Start exploring your data by jumping into the code editor.
            </PaneEmptyStateText>
            <PaneEmptyStateButton
              icon={<DocumentPlusIcon />}
              onClick={handleNew}
            >
              <span className="truncate">Create model</span>
            </PaneEmptyStateButton>
          </PaneEmptyState>
        ) : (
          <Unpublished
            unpublishedItems={items}
            selectedId={modelId}
            isAddingNew={!!newItemState.item}
          />
        )}
      </PaneContent>
    </>
  );
});

const Unpublished: React.FC<{
  headline?: string;
  unpublishedItems: Exclude<SidebarItemType, GroupItemType>[];
  selectedId?: string;
  isAddingNew?: boolean;
}> = (props) => {
  const { openFolders, toggleFolder } = useSidebarFolders();

  const [highlightedId, setHighlightedId] = useState("");

  const containerRef = useRef<HTMLDivElement>(null);
  const currentHightlightedRef = useRef<HTMLDivElement>(null);

  useArrowKeyFocusNavigation(containerRef, currentHightlightedRef);

  useEffect(() => {
    const currentContainerRef = containerRef.current;
    if (!highlightedId || !currentContainerRef) return;

    const enterDownListener = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        currentHightlightedRef.current?.click();
      }
    };

    currentContainerRef.addEventListener("keydown", enterDownListener);
    return () =>
      currentContainerRef.removeEventListener("keydown", enterDownListener);
  }, [highlightedId]);

  const unpublishedGroup: GroupItemType = React.useMemo(() => {
    return {
      id: "unpublished_models",
      itemType: "group",
      name: "Unpublished",
      children: props.unpublishedItems,
    };
  }, [props.unpublishedItems]);

  return (
    <div
      className={classNames(
        "flex h-full select-none flex-col",
        props.isAddingNew && "bg-gray-200 dark:bg-gray-700",
      )}
      onBlur={() => setHighlightedId("")}
    >
      <div>
        {unpublishedGroup.children.map((item) => (
          <SidebarItemFactory
            key={item.id}
            level={1}
            item={item}
            openItems={openFolders}
            onToggleFolder={toggleFolder}
            onFocus={(id) => {
              setHighlightedId(id);
            }}
            setHighlightedId={setHighlightedId}
            selectedId={props.selectedId}
          />
        ))}
      </div>
    </div>
  );
};
