import { useEffect, useState } from "react";

import { useCallbackRef } from "@/hooks/useCallbackRef";
import { useMountEffect } from "@/hooks/useMountEffect";

export function useScrollState(elementRef: React.RefObject<HTMLElement>) {
  const [state, setState] = useState({
    isScrollAtTop: true,
    isScrollAtBottom: true,
  });

  const handleScroll = useCallbackRef(() => {
    const scrollContainer = elementRef.current;
    if (!scrollContainer) return;

    const scrollTop = scrollContainer.scrollTop;
    const scrollHeight = scrollContainer.scrollHeight;
    const clientHeight = scrollContainer.clientHeight;

    const isScrollAtTop = scrollTop === 0;
    const isScrollAtBottom = scrollTop + clientHeight === scrollHeight;

    if (
      isScrollAtTop !== state.isScrollAtTop ||
      isScrollAtBottom !== state.isScrollAtBottom
    ) {
      setState({
        isScrollAtTop,
        isScrollAtBottom,
      });
    }
  });

  useEffect(() => {
    const scrollContainer = elementRef.current;
    if (!scrollContainer) return;
    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [elementRef, handleScroll]);

  useMountEffect(() => {
    handleScroll();
  });

  return {
    isScrollAtTop: state.isScrollAtTop,
    isScrollAtBottom: state.isScrollAtBottom,
    isScrolled: !state.isScrollAtTop && !state.isScrollAtBottom,
  };
}
