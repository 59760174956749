import Picker from "@emoji-mart/react";
import { Popover } from "@headlessui/react";
import { Button } from "@/components/elements/Button";
import { useDarkMode } from "@/providers/DarkModeProvider";
import { useState } from "react";
import { usePopper } from "react-popper";

export const EmojiPicker = (props: {
  onEmojiChange: (emoji: string | null) => Promise<any>;
  children: JSX.Element;
}) => {
  const { isDarkModeEnabled } = useDarkMode();
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "fixed",
    placement: "right-start",
    modifiers: [
      { name: "preventOverflow", enabled: true },
      { name: "offset", options: { offset: [5, 5] } },
    ],
  });

  return (
    <Popover>
      {({ close }) => (
        <>
          <Popover.Button
            className={"flex items-center"}
            ref={setReferenceElement}
          >
            {props.children}
          </Popover.Button>

          <Popover.Panel
            className="absolute z-30"
            style={styles.popper}
            {...attributes.popper}
            ref={setPopperElement}
            onClick={(e: any) => e.stopPropagation()}
          >
            <Picker
              data={async () => {
                const response = await fetch(
                  "https://cdn.jsdelivr.net/npm/@emoji-mart/data",
                );
                return response.json();
              }}
              theme={isDarkModeEnabled ? "dark" : "light"}
              onEmojiSelect={async (emoji: { unified: string }) => {
                await props.onEmojiChange("0x" + emoji.unified);
                close();
              }}
            />
            <div className="relative z-10 -mt-2 w-full rounded-b-md">
              <Button
                className="w-full rounded-b-md py-2 text-center"
                onClick={async () => {
                  await props.onEmojiChange(null);
                  close();
                }}
              >
                Remove Emoji
              </Button>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};
