import FormContainer from "@/components/elements/FormContainer";
import Centered from "@/components/elements/layout/Centered";
import Container from "@/components/elements/layout/Container";
import Content from "@/components/elements/layout/Content";
import ContentContainer from "@/components/elements/layout/ContentContainer";
import PageHeader from "@/components/elements/PageHeader";
import Breadcrumbs from "@/components/modules/Breadcrumbs";
import { TaskActionBar } from "@/components/modules/TaskActionBar";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export default function FormLayout({ children }: Props) {
  return (
    <Container>
      <ContentContainer>
        <Content narrow>
          <PageHeader.Container>
            <Breadcrumbs />
          </PageHeader.Container>
          <Centered>
            <FormContainer>{children}</FormContainer>
          </Centered>
        </Content>
        <TaskActionBar mode="narrow" />
      </ContentContainer>
    </Container>
  );
}
