import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export default function init(releaseId: string, environment: string) {
  Sentry.init({
    dsn: "https://7631783c3f414caa9817bab45bd7754c@o510832.ingest.sentry.io/5870611",
    integrations: [new Integrations.BrowserTracing()],
    release: releaseId,
    environment,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
