import { escapeRegExp } from "lodash";
import { Button } from "@/components/elements/Button";
import { LinkWithSlug } from "@/routes";
import { useNotificationAction } from "@/features/notifications";
import { Notification } from "@/apollo/types";
import { useCurrentAccount } from "@/providers/account";
import { useNotificationItem } from "@/components/modules/notifications";
import { useToast } from "@/providers/ToastProvider";

export function NotificationAction(props: {
  notification: Notification;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const account = useCurrentAccount();
  const { messageData } = useNotificationItem(props.notification);
  const action = messageData?.action;

  if (!action?.url) {
    return (
      <NotificationPredefinedAction
        notification={props.notification}
        onClick={props.onClick}
      />
    );
  }

  const actionUrl = action.url;
  const actionLabel = action.label ?? "View issue";
  if (!actionUrl.startsWith("http")) {
    return (
      <NotificationAppAction
        url={actionUrl}
        label={actionLabel}
        onClick={props.onClick}
      />
    );
  }

  const url = new URL(actionUrl);
  const isAppUrl = /^(localhost|workspace(\.staging)?\.weld\.app)$/.test(
    url.hostname,
  );
  if (!isAppUrl) {
    return (
      <NotificationExternalAction
        url={url.toString()}
        label={actionLabel}
        onClick={props.onClick}
      />
    );
  }

  const toPath = url.pathname.replace(
    new RegExp(`^\\/${escapeRegExp(account.slug)}`),
    "",
  );
  return (
    <NotificationAppAction
      url={toPath + url.search}
      label={actionLabel}
      onClick={props.onClick}
    />
  );
}

function NotificationExternalAction(props: {
  url: string;
  label: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <Button
      as={LinkWithSlug}
      to={props.url}
      onClick={props.onClick}
      variant="solid"
      colorScheme="primary"
    >
      {props.label}
    </Button>
  );
}

function NotificationAppAction(props: {
  url: string;
  label: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <Button
      as={LinkWithSlug}
      to={props.url}
      onClick={props.onClick}
      variant="solid"
      colorScheme="primary"
    >
      {props.label}
    </Button>
  );
}
function NotificationPredefinedAction(props: {
  notification: Notification;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const toast = useToast();
  const { getActionProps } = useNotificationAction({
    onCompleted: (message) => toast("Success", message, "success"),
    onError: (message) => toast("Error", message, "error"),
  });
  const actionProps = getActionProps(props.notification);
  return (
    <>
      {actionProps.map((action, index) => (
        <Button
          key={`actionButton-${index + 1}`}
          as={LinkWithSlug}
          to={action.to}
          onClick={async (event) => {
            event.preventDefault();
            await action.handler?.(event);
            if (!event.isPropagationStopped()) {
              props.onClick?.(event);
            }
          }}
          variant="solid"
          colorScheme={action.colorScheme ?? "primary"}
        >
          {action.label}
        </Button>
      ))}
    </>
  );
}
