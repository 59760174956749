import { useMonacoInstance } from "@/pages/ModelTool/QueryEditor/SQLEditor";
import { useToast } from "@/providers/ToastProvider";
import { useCallback, useMemo } from "react";

export const useInserInEditor = () => {
  const [editor] = useMonacoInstance();
  const toast = useToast();

  const editorOpen = useMemo(() => !!editor, [editor]);

  const inserToEditor = useCallback(
    (text: string) => {
      if (!editor) return toast("Not inserted", "No editor found", "warning");

      const model = editor?.getModel();
      if (model) {
        const range = editor?.getSelection();
        if (!range) return;
        // insert at cursor position
        model.pushEditOperations(
          [],
          [
            {
              range,
              text: `${text}`,
            },
          ],
          () => null,
        );
        editor.focus();
      }
    },
    [editor, toast],
  );

  return { inserToEditor, editorOpen };
};
