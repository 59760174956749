import Content from "@/components/elements/layout/Content";
import PageHeader from "@/components/elements/PageHeader";
import Breadcrumbs from "@/components/modules/Breadcrumbs";

type Props = {
  children: JSX.Element;
  button?: React.ReactElement;
  narrow?: boolean;
};

export default function MainPageLayout(props: Props) {
  return (
    <Content narrow={props.narrow}>
      <PageHeader.Container>
        <Breadcrumbs />
        <>{props.button && props.button}</>
      </PageHeader.Container>
      <div className="grow">{props.children}</div>
    </Content>
  );
}
