import cronstrue from "cronstrue";
import produce from "immer";
import { useEffect, useRef, useState } from "react";
import {
  Controller,
  FormProvider,
  useController,
  useForm,
  useFormContext,
  useFormState,
} from "react-hook-form";

import {
  OrchestrationSchedulerType,
  useListOrchestrationWorkflowsQuery,
} from "@/apollo/types";
import { UtilityButton } from "@/components/elements/Button";
import { CronDisplayReadable } from "@/components/elements/CronDisplayReadable";
import { CronInput, TimeInput } from "@/components/elements/SyncScheduler";
import Tooltip from "@/components/elements/Tooltip";
import { SchedulerSelecter } from "@/components/modules/SchedulerSelecter";
import {
  SyncScheduleRadioGroup,
  WeldSyncScheduler,
} from "@/components/modules/WeldSyncScheduler";
import { SyncSchedulerMenu } from "@/components/modules/WeldSyncScheduler/components/SyncSchedulerMenu";
import { InputError } from "@/components/primitives/InputError";
import { Input } from "@/components/primitives/input";
import {
  OrchestrationFeatureGuard,
  useOrchestrationFeatureGuard,
} from "@/features/feature-guards";
import classNames from "@/helpers/classNames";
import { useMountEffect } from "@/hooks/useMountEffect";
import { useToast } from "@/providers/ToastProvider";
import mergeRefs from "@/utils/mergeRefs";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { useStepsContext } from "../../../stepper/useSteps";
import { Content, Footer, Heading, SubHeading } from "../../components/Layout";
import { NextButton, PrevButton } from "../../components/NavButton";
import {
  useCreateSync,
  useDataSourceStepContext,
} from "../../contexts/DataSourceStepsContext";
import { useDataSourceStateContext } from "../../step-state";

type FormModel = ReturnType<
  typeof useDataSourceStateContext
>["state"]["syncSettings"]["value"];

function generateSchemaName(input: string) {
  return input.trim().replace(/\W/g, "_").replace(/__+/g, "_");
}

export function SyncSettingsStepContainer() {
  const { onGoToStep } = useStepsContext();
  const { state, setState } = useDataSourceStateContext();
  const { connection } = useDataSourceStepContext();

  const toast = useToast();

  const formMethods = useForm<FormModel>({
    mode: "onChange",
    defaultValues: {
      ...state.syncSettings.value,
      destinationSchemaName:
        state.syncSettings.value.destinationSchemaName ||
        generateSchemaName(
          connection?.label || connection?.integrationId || "",
        ),
    },
  });

  const { isValid, isDirty } = useFormState({
    control: formMethods.control,
  });

  useEffect(() => {
    setState(
      produce((state) => {
        state.syncSettings.isDirty = isDirty;
      }),
    );
  }, [isDirty, setState]);

  const schedulerType = formMethods.watch("schedulerType");

  const { isEnabled: isOrchestrationEnabled } = useOrchestrationFeatureGuard();
  const { setValue } = formMethods;
  useEffect(() => {
    if (
      isOrchestrationEnabled === false &&
      schedulerType === OrchestrationSchedulerType.Global
    ) {
      setValue("schedulerType", OrchestrationSchedulerType.Local);
    }
  }, [schedulerType, isOrchestrationEnabled, setValue]);

  const [createSync, { loading }] = useCreateSync({
    onCompleted: () => {
      onGoToStep("setup-complete");
    },
    onError: (error) => {
      toast(`Sync not created`, error.message, "error");
    },
  });

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit((data) => {
          const newState = produce(state, (draft) => {
            draft.syncSettings.isValid = true;
            draft.syncSettings.value = data;
          });
          setState(newState);
          createSync(newState);
        })}
        className="flex h-full w-full flex-col"
      >
        <Content>
          <Heading>Configure sync</Heading>
          <div className="flex flex-col gap-4">
            <OrchestrationFeatureGuard>
              <Scheduler />
            </OrchestrationFeatureGuard>
            {schedulerType === OrchestrationSchedulerType.Local && (
              <LocalSchedule />
            )}
          </div>
          <hr className="my-8 dark:border-gray-700" />
          <DestinationSchema />
        </Content>
        <Footer className="flex-row-reverse">
          <NextButton
            isDisabled={!isValid}
            isLoading={loading}
            loadingText="Creating ..."
          >
            Create sync
          </NextButton>
          <PrevButton />
        </Footer>
      </form>
    </FormProvider>
  );
}

function Scheduler() {
  const formContext = useFormContext<FormModel>();

  const { data, loading } = useListOrchestrationWorkflowsQuery();

  const schedulerTypeCtrl = useController({
    name: "schedulerType",
    control: formContext.control,
    rules: {
      required: {
        value: true,
        message: "Please select a schedule",
      },
    },
  });

  const orchestrationWorkflowIdCtrl = useController({
    name: "orchestrationWorkflowId",
    control: formContext.control,
    rules: {
      validate: (value) => {
        if (
          formContext.getValues("schedulerType") ===
            OrchestrationSchedulerType.Global &&
          !value
        ) {
          return "Please select a workflow";
        }
        return true;
      },
    },
  });

  if (loading || !data || data.orchestrationWorkflows.length === 0) {
    return null;
  }
  return (
    <div>
      <SubHeading className="mb-2">Schedule</SubHeading>
      <SchedulerSelecter
        hideLabel
        configuration={{
          allowNewWorkflow: false,
          allowNoSchedule: false,
        }}
        orchestrationScheduler={schedulerTypeCtrl.field.value}
        orchestrationWorkflowId={orchestrationWorkflowIdCtrl.field.value}
        onUpdate={(config) => {
          if (!config.orchestrationScheduler) return;
          schedulerTypeCtrl.field.onChange(config.orchestrationScheduler);
          orchestrationWorkflowIdCtrl.field.onChange(
            config.orchestrationWorkflowId,
          );
        }}
      />
    </div>
  );
}

function LocalSchedule() {
  const formContext = useFormContext<FormModel>();
  return (
    <Controller
      name="scheduleKey"
      rules={{
        validate: {
          required: (value) => {
            if (
              formContext.getValues("schedulerType") ===
              OrchestrationSchedulerType.Local
            ) {
              return !!value || "Please select a schedule";
            }
            return true;
          },
          validCron: (value) => {
            try {
              cronstrue.toString(value, { verbose: true });
              return true;
            } catch (e) {
              return "Invalid cron expression";
            }
          },
        },
      }}
      render={({ field }) => (
        <WeldSyncScheduler cron={field.value} onChange={field.onChange}>
          {({ value, mode, showTimeOffsetInput }) => (
            <>
              <div>
                <SubHeading className="mb-2">
                  How often do you want to sync data to WELD?
                </SubHeading>
                <CronInput />
                <SyncScheduleRadioGroup className="grid grid-cols-2 gap-x-4 gap-y-3" />
                {showTimeOffsetInput && (
                  <>
                    <SubHeading className="mb-2 mt-4">Starting at</SubHeading>
                    <TimeInput />
                  </>
                )}
              </div>
              <div>
                <p className="text-sm font-medium">
                  Will sync now and update data:
                </p>
                <div className="flex items-center gap-4 text-sm">
                  <span>
                    {value && (
                      <CronDisplayReadable verbose cron={value.value} />
                    )}
                    {!value && mode === "custom" && (
                      <span>
                        Write a cron expression to see update frequency here
                      </span>
                    )}
                  </span>
                  <SyncSchedulerMenu />
                </div>
              </div>
            </>
          )}
        </WeldSyncScheduler>
      )}
    />
  );
}

function DestinationSchema() {
  const formContext = useFormContext<FormModel>();
  const { ref, ...rest } = formContext.register("destinationSchemaName", {
    required: {
      value: true,
      message: "Destination schema name is required",
    },
    validate: {
      onlyWordCharacters: (value) => {
        return (
          !/\W/g.test(value) ||
          "Only letters, numbers and underscores are allowed"
        );
      },
      noDoubleUnderscores: (value) => {
        return !/__/g.test(value) || 'Cannot contain double underscores ("__")';
      },
    },
  });

  const { errors } = useFormState({ control: formContext.control });
  const errorsForField = errors["destinationSchemaName"];

  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (isEditable) {
      inputRef.current?.focus();
    }
  }, [isEditable]);

  useMountEffect(() => {
    // Trigger validation on mount since the input will be in readonly mode initially
    formContext.trigger("destinationSchemaName");
  });

  return (
    <div>
      <SubHeading className="mb-2 flex items-center space-x-1">
        <span>Destination schema name</span>
        <Tooltip
          content={
            "Name of the directory in the data warehouse, where synced tables are stored in."
          }
        >
          <div>
            <InformationCircleIcon className="h-4 w-4 dark:text-white" />
          </div>
        </Tooltip>
      </SubHeading>
      <div className="relative w-1/2 pr-2">
        <Input
          ref={mergeRefs(ref, inputRef)}
          className={classNames("w-full", !isEditable && "pr-12")}
          placeholder="Name the dataset"
          readOnly={!isEditable}
          {...rest}
        />
        {!isEditable && (
          <div className="absolute right-0 top-0 flex h-12 items-center pr-6">
            <UtilityButton
              className=""
              onClick={() => {
                setIsEditable(true);
              }}
            >
              Edit
            </UtilityButton>
          </div>
        )}
      </div>
      {errorsForField && (
        <InputError className="mt-1">
          {errorsForField.message?.toString()}
        </InputError>
      )}
    </div>
  );
}
