import { FormProvider } from "react-hook-form";
import { useNavigateWithSlug } from "routes";
import invariant from "tiny-invariant";

import DataBox from "@/components/elements/DataBox";
import DocumentationColumnLayout from "@/components/layouts/DocumentationColumnLayout";
import { ConnectorsLimitAlert } from "@/components/modules/PlanAlerts";
import { TaskAction } from "@/components/modules/TaskActionBar";
import {
  CustomConnectorFeatureGuard,
  CustomConnectorTeaser,
} from "@/features/feature-guards";
import {
  useConnectorAdditionValidationUpgradeDialog,
  useCurrentPlan,
} from "@/features/subscription";
import {
  isCustomConnector,
  useCreateConnection,
  useIntegration,
  useIntegrationDocumentationUrl,
} from "@/integrations";
import { useToast } from "@/providers/ToastProvider";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useMatch, useSearch } from "@tanstack/react-location";

import ConnectionForm, {
  ConnectionFormSubmitButton,
  generateLabel,
  useConnectionForm,
} from "./ConnectionForm/ConnectionForm";

export default function NewIntegrationPage() {
  const {
    params: { integrationId },
  } = useMatch();
  const { name } = useSearch<{ Search: { name: string } }>();

  const integration = useIntegration(integrationId);
  invariant(integration, "Integration not found");

  const documentationUrl = useIntegrationDocumentationUrl(
    integration.id,
    false,
  );

  const { features } = useCurrentPlan();

  const navigate = useNavigateWithSlug();
  const toast = useToast();

  const formMethods = useConnectionForm(integration, {
    defaultValues: {
      label: generateLabel(name || integration.id),
    },
  });

  const { loading, createConnection } = useCreateConnection({
    onSuccess(connection) {
      toast(
        "Connector created",
        "The connector has been succesfully created",
        "success",
      );
      navigate({ to: `/settings/connections/${connection.id}` });
    },
    onError(e) {
      toast("Connector not created", e.message, "error");
    },
  });

  const { validateConnectionAddition, validationDialog } =
    useConnectorAdditionValidationUpgradeDialog();

  return (
    <DocumentationColumnLayout documentation={null}>
      <CustomConnectorFeatureGuard
        integrationId={integration.id}
        fallback={() => (
          <div className="mx-auto w-full max-w-lg rounded border border-gray-200 p-8 dark:border-gray-700">
            <CustomConnectorTeaser />
          </div>
        )}
      >
        <div className="mb-4">
          <ConnectorsLimitAlert />
        </div>
        <FormProvider {...formMethods}>
          <form
            id="integration-form"
            onSubmit={formMethods.handleSubmit((data) =>
              createConnection(integration, data),
            )}
          >
            <DataBox
              header="Configuration"
              action={
                <div className="ml-auto self-end">
                  {documentationUrl != null ? (
                    <a
                      href={documentationUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="ml-10 inline-flex items-center text-sm underline"
                    >
                      View setup guide
                      <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
                    </a>
                  ) : undefined}
                </div>
              }
            >
              <div className="max-w-2xl">
                <ConnectionForm integration={integration} isLoading={loading} />
                <TaskAction>
                  <ConnectionFormSubmitButton
                    onClick={() => validateConnectionAddition(integration.id)}
                    integration={integration}
                    isLoading={loading}
                    isDisabled={
                      isCustomConnector(integration.id) &&
                      !features.customConnectorEnabled
                    }
                  />
                </TaskAction>
              </div>
            </DataBox>
          </form>
        </FormProvider>
        {validationDialog()}
      </CustomConnectorFeatureGuard>
    </DocumentationColumnLayout>
  );
}
