import { useEffect } from "react";

/**
 * Run an effect once when component is first mounted.
 */
export function useMountEffect(effect: CallableFunction): void {
  useEffect(() => {
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
