import { CheckIcon } from "@heroicons/react/24/solid";
import AppearTransition from "@/components/elements/AppearTransition";
import { ActionButton } from "@/components/elements/Button";
import Centered from "@/components/elements/layout/Centered";
import Container from "@/components/elements/layout/Container";
import Content from "@/components/elements/layout/Content";
import ContentContainer from "@/components/elements/layout/ContentContainer";
import PageHeader from "@/components/elements/PageHeader";
import Breadcrumbs from "@/components/modules/Breadcrumbs";
import { TaskAction, TaskActionBar } from "@/components/modules/TaskActionBar";
import ButtonPrimitive from "@/components/primitives/Button";
import classNames from "@/helpers/classNames";
import usePrevious from "@/hooks/usePrevious";
import { isEqual } from "lodash";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type DynamicStepProps = {
  isCompleted?: boolean;
  disabled?: boolean;
  hasBeenOpened?: boolean;
  hidden?: boolean;
};

type Step = {
  id: string;
  name: string;
  description: string;
  isCurrent?: boolean;
  initialized?: boolean;
} & DynamicStepProps;

type ContextType = {
  steps: Step[];
  setSteps: (fn: (prev: Step[]) => Step[]) => any;
  current: number;
  setCurrent: any;
} | null;

const StepContext = createContext<ContextType>(null);

const useStepContext: () => ContextType = () => {
  const context = useContext(StepContext);
  if (!context?.steps) return null;
  return { ...context, steps: context.steps.filter((step) => !step.hidden) };
};

const useStep = (props: DynamicStepProps & { id: string }) => {
  const context = useStepContext();
  const prevProps = usePrevious(props);

  useEffect(() => {
    if (isEqual(props, prevProps)) return;

    context?.setSteps((prev: Step[]) =>
      prev.map((step) =>
        step.id === props.id ? { ...step, ...props, initialized: true } : step,
      ),
    );
  }, [props, context, prevProps]);

  const index = useMemo(
    () => context?.steps.findIndex((step) => step.id === props.id),
    [context?.steps, props.id],
  );

  const value = useMemo(
    () => ({ index, step: index !== undefined ? context?.steps[index] : null }),
    [index, context?.steps],
  );
  return value;
};

const StepLayout = ({
  children,
  steps: stepArray,
  onCancel,
}: {
  children: JSX.Element | JSX.Element[];
  steps: Step[];
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const [steps, setSteps] = useState<Step[]>(stepArray);
  const [current, setCurrent] = useState<number>(0);

  const value = useMemo(
    () => ({ steps, setSteps, current, setCurrent }),
    [steps, current],
  );
  return (
    <StepContext.Provider value={value}>
      <Container>
        <Steps />
        <ContentContainer>
          <Content>
            <PageHeader.Container>
              <Breadcrumbs />
            </PageHeader.Container>
            <Centered>{children}</Centered>
          </Content>
          <TaskActionBar mode="normal" onCancelClicked={onCancel} />
        </ContentContainer>
      </Container>
    </StepContext.Provider>
  );
};
export default StepLayout;

type StepContainerProps = {
  id: string;
  action?: JSX.Element;
} & DynamicStepProps;

export const StepContainer = memo(
  ({
    children,
    action,
    ...props
  }: StepContainerProps & { children: JSX.Element | JSX.Element[] }) => {
    const [hasBeenOpened, setHasBeenOpened] = useState(false);
    const { index, step } = useStep({ ...props, hasBeenOpened });
    const context = useStepContext();

    const isVisible = useMemo(
      () => index === context?.current,
      [context, index],
    );

    useEffect(() => {
      if (!isVisible) return;
      setHasBeenOpened(true);
    }, [isVisible]);

    const handleNext: React.FormEventHandler<HTMLFormElement> = useCallback(
      (e) => {
        if (e.target !== e.currentTarget) {
          return;
        }
        e.preventDefault();
        if (!context) return;
        if (index === context.steps.length - 1) return;
        context.setCurrent((prev: number) => prev + 1);
      },
      [context, index],
    );

    if (!isVisible) return null;

    return (
      <AppearTransition>
        <div className="flex flex-col justify-center">
          <form
            className="flex flex-col space-y-10 p-1"
            id="step-container-form"
            onSubmit={handleNext}
          >
            <div>
              <p className="mb-1 text-xl text-gray-700 dark:text-white">
                {step?.name}
              </p>
              <p className="font-light text-gray-500">{step?.description}</p>
            </div>
            <div className="grow">{children}</div>
            <TaskAction>
              {action ? (
                action
              ) : (
                <ActionButton
                  form="step-container-form"
                  type="submit"
                  disabled={!props.isCompleted}
                >
                  Next
                </ActionButton>
              )}
            </TaskAction>
          </form>
        </div>
      </AppearTransition>
    );
  },
);

function Steps() {
  const context = useStepContext();
  const steps = context?.steps || [];

  return (
    <nav aria-label="Progress" className="h-full">
      <ol className="flex h-full max-w-sm flex-col justify-center overflow-hidden border-r bg-gray-50 p-4 dark:border-gray-700 dark:bg-gray-800 lg:p-8">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? "pb-10" : "",
              !step.initialized ? "opacity-0" : "opacity-100",
              "relative transition-opacity duration-500",
            )}
          >
            {context?.current === stepIdx ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300 dark:bg-gray-500"
                    aria-hidden="true"
                  />
                ) : null}
                <ButtonPrimitive
                  onClick={() => context.setCurrent(stepIdx)}
                  className="group relative flex items-start text-left"
                  aria-current="step"
                >
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-600 bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-blue-600" />
                    </span>
                  </span>
                  <span className="ml-4 hidden min-w-0 flex-col lg:flex">
                    <span className="text-xs font-semibold uppercase tracking-wide text-blue-600">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-200">
                      {step.description}
                    </span>
                  </span>
                </ButtonPrimitive>
              </>
            ) : step.isCompleted && step.hasBeenOpened ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className={`absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 ${
                      context?.current || 0 > stepIdx
                        ? "bg-blue-600"
                        : "bg-gray-300"
                    }`}
                    aria-hidden="true"
                  />
                ) : null}
                <ButtonPrimitive
                  className="group relative flex items-start text-left"
                  onClick={() => context?.setCurrent(stepIdx)}
                >
                  <span className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-blue-600 group-hover:bg-blue-800">
                      <CheckIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <span className="ml-4 hidden min-w-0 flex-col lg:flex">
                    <span className="text-xs font-semibold uppercase tracking-wide dark:text-gray-200">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </ButtonPrimitive>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300 dark:bg-gray-600"
                    aria-hidden="true"
                  />
                ) : null}
                <button
                  type="button"
                  disabled={step.disabled}
                  className={`group relative flex items-start text-left ${
                    step.disabled
                      ? "cursor-default text-gray-200 dark:text-gray-500"
                      : "text-gray-500 dark:text-gray-200"
                  }`}
                  onClick={() => context?.setCurrent(stepIdx)}
                >
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span
                      className={`relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 bg-white dark:bg-gray-700 ${
                        !step.disabled
                          ? "border-gray-300 group-hover:border-gray-400 dark:border-gray-500"
                          : "border-gray-100 dark:border-gray-500"
                      }`}
                    >
                      <span
                        className={`h-2.5 w-2.5 rounded-full bg-transparent ${
                          !step.disabled ? "group-hover:bg-gray-300" : ""
                        }`}
                      />
                    </span>
                  </span>
                  <span className="ml-4 hidden min-w-0 flex-col lg:flex">
                    <span className="text-xs font-semibold uppercase tracking-wide">
                      {step.name}
                    </span>
                    <span className="text-sm">{step.description}</span>
                  </span>
                </button>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
