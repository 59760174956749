import { useFlags } from "launchdarkly-react-client-sdk";
import { ComponentProps, useState } from "react";

import {
  IncidentFragment,
  useScheduledIncidentsLazyQuery,
  useUnresolvedIncidentsLazyQuery,
} from "@/apollo/types";
import {
  Banner,
  BannerCloseButton,
  BannerDescription,
  BannerIcon,
  BannerTitle,
} from "@/components/elements/Banner";
import { Button } from "@/components/elements/Button";
import cn from "@/helpers/classNames";
import { useInterval } from "@/hooks/useInterval";
import { useTimeout } from "@/hooks/useTimeout";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

type Status = NonNullable<ComponentProps<typeof Banner>["status"]>;
const allowedStatuses: Status[] = ["info", "warning", "error"];

function useIncidentFromLaunchDarkly() {
  const { operationIssuesBanner: bannerOptions } = useFlags();
  if (typeof bannerOptions?.message === "string") {
    let status: Status = "info";
    if (
      bannerOptions.status &&
      allowedStatuses.includes(bannerOptions.status as Status)
    ) {
      status = bannerOptions.status as Status;
    }
    return {
      id: "launchdarkly",
      title: bannerOptions.title ?? "Operation issues",
      message: bannerOptions.message,
      status,
    };
  }
  return undefined;
}

const formatIncidentDate = (date: Date | string | number) =>
  new Date(date).toLocaleString(undefined, {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

const INITIAL_FETCH_DELAY = 0;
const DEFAULT_POLL_INTERVAL = 10 * 60 * 1000; // 10 minutes
const POLL_INTERVAL_WHEN_INCIDENT = 5 * 60 * 1000; // 5 minutes

function useUnresolvedIncident() {
  const [incident, setIncident] = useState<{
    data: IncidentFragment;
    props: ComponentProps<typeof OperationIssuesBanner>;
  } | null>(null);

  const [fetchUnresolvedIncidents] = useUnresolvedIncidentsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const latestIncident = data.unresolvedIncidents[0];

      if (!latestIncident) {
        setIncident(null);
        return;
      }

      if (
        !incident ||
        // new incident
        incident.data.id !== latestIncident.id ||
        // current incident was updated
        incident.data.incidentUpdates[0].id !==
          latestIncident.incidentUpdates[0].id
      ) {
        const latestUpdate = latestIncident.incidentUpdates[0];
        const message = `${
          latestUpdate.displayAt
            ? formatIncidentDate(latestUpdate.displayAt) + ": "
            : ""
        }${latestUpdate.body}`;
        setIncident({
          data: latestIncident,
          props: {
            id: latestIncident.id,
            title: latestIncident.name,
            message,
            status: "warning",
            link: latestIncident.shortlink,
          },
        });
      }
    },
  });

  // Make sure we fetch the incident on first render after a short delay
  useTimeout(fetchUnresolvedIncidents, INITIAL_FETCH_DELAY);
  // Then fetch unresolved incidents on a regular interval
  let refetchInterval =
    incident == null ? DEFAULT_POLL_INTERVAL : POLL_INTERVAL_WHEN_INCIDENT;
  useInterval(fetchUnresolvedIncidents, refetchInterval);

  return incident;
}

function useScheduledIncident() {
  const [incident, setIncident] = useState<{
    data: IncidentFragment;
    props: ComponentProps<typeof OperationIssuesBanner>;
  } | null>(null);

  const [fetchScheduledIncidents] = useScheduledIncidentsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const latestIncident = data.scheduledIncidents[0];
      if (!latestIncident) {
        setIncident(null);
        return;
      }

      if (latestIncident.impact !== "maintenance") {
        setIncident(null);
        return;
      }

      if (
        !incident ||
        // new incident
        incident.data.id !== latestIncident.id ||
        // current incident was updated
        incident.data.incidentUpdates[0].id !==
          latestIncident.incidentUpdates[0].id
      ) {
        const latestUpdate = latestIncident.incidentUpdates[0];
        const scheduledFor = formatIncidentDate(latestIncident.scheduledFor);
        setIncident({
          data: latestIncident,
          props: {
            id: latestIncident.id,
            title: `Scheduled maintenance on ${scheduledFor}`,
            message: latestUpdate.body,
            status: "info",
            link: latestIncident.shortlink,
            compact: true,
          },
        });
      }
    },
  });

  // Make sure we fetch the incident on first render after a short delay
  useTimeout(fetchScheduledIncidents, INITIAL_FETCH_DELAY);
  // Then fetch unresolved incidents on a regular interval
  let refetchInterval =
    incident == null ? DEFAULT_POLL_INTERVAL : POLL_INTERVAL_WHEN_INCIDENT;
  useInterval(fetchScheduledIncidents, refetchInterval);

  return incident;
}

export function useOperationIssues() {
  const launchDarklyIncident = useIncidentFromLaunchDarkly();
  const statusPageUnresolvedIncident = useUnresolvedIncident();
  const statusPageScheduledIncident = useScheduledIncident();

  return [
    launchDarklyIncident,
    statusPageUnresolvedIncident?.props,
    statusPageScheduledIncident?.props,
  ].filter((x): x is ComponentProps<typeof OperationIssuesBanner> => !!x);
}

export function OperationIssuesBanner(props: {
  id: string;
  message: string;
  title: string;
  status: ComponentProps<typeof Banner>["status"];
  link?: string;
  compact?: boolean;
  onDismiss?: () => void;
}) {
  return (
    <Banner size="sm" status={props.status} compact={props.compact}>
      <BannerIcon />
      <BannerTitle>{props.title}</BannerTitle>
      <BannerDescription
        className={cn("w-full overflow-hidden", {
          "flex gap-2": props.compact,
        })}
      >
        <div className="truncate" title={props.message}>
          {props.message}
        </div>
        {props.link && (
          <Button
            variant="link"
            as="a"
            iconRight={<ArrowTopRightOnSquareIcon />}
            href={props.link}
            target="_blank"
            rel="noreferrer"
            size="sm"
          >
            More info
          </Button>
        )}
      </BannerDescription>
      {typeof props.onDismiss === "function" && (
        <BannerCloseButton
          onClick={() => {
            props.onDismiss?.();
          }}
        />
      )}
    </Banner>
  );
}
