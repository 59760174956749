import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { FindOneSyncQuery, useFindOneSyncQuery } from "@/apollo/types";
import { SecondaryButton } from "@/components/elements/Button";
import {
  SlideOver,
  SlideOverBody,
  SlideOverCloseButton,
  SlideOverFooter,
  SlideOverHeader,
} from "@/components/elements/SlideOver";
import SyncDetails from "@/pages/ReverseEltSyncs/modules/SyncDetails";
import React from "react";
import { useNavigateWithSlug } from "routes";

type ExportsSlideOverProps = Pick<
  React.ComponentProps<typeof SlideOver>,
  "show" | "onClose"
> & {
  syncId?: string;
};

export function ExportsSlideOver({ syncId, ...props }: ExportsSlideOverProps) {
  const navigate = useNavigateWithSlug();

  const { data } = useFindOneSyncQuery({
    variables: { syncId: String(syncId) },
    skip: !syncId,
  });

  const sync = data?.findOneSync;

  return (
    <SlideOver {...props}>
      <SlideOverCloseButton />
      <SlideOverHeader>Export</SlideOverHeader>
      <SlideOverBody>{sync && <ExportContents sync={sync} />}</SlideOverBody>
      <SlideOverFooter>
        <SecondaryButton
          onClick={() => navigate({ to: `/reverse-etl/${syncId}` })}
          icon={<Cog6ToothIcon />}
        >
          Configure
        </SecondaryButton>
      </SlideOverFooter>
    </SlideOver>
  );
}

function ExportContents({ sync }: { sync: FindOneSyncQuery["findOneSync"] }) {
  return (
    <div className="space-y-4">
      <SyncDetails.ModelName sync={sync} />
      <SyncDetails.Connector sync={sync} />
      <SyncDetails.Schedule sync={sync} />
      <SyncDetails.Operation sync={sync} />
      <SyncDetails.Mappings sync={sync} />
    </div>
  );
}
