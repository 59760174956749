import { Button } from "components/elements/Button";
import TabLayout from "components/layouts/TabLayout";
import { useDisclosure } from "hooks/useDisclosure";
import { ComponentProps } from "react";

import { useSettingsTabs } from "../hooks/useSettingsTabs";
import { DbtSetupSlideOver } from "./dbt/DbtSetupSlideOver";
import { useConnections } from "@/features/connectors";
import { CheckIcon } from "@heroicons/react/20/solid";
import { IntegrationLogo } from "@/integrations";

export default function ExternalToolsPage() {
  const tabs = useSettingsTabs();
  const connections = useConnections("UtilityConnection");

  const slideOverDisclosure = useDisclosure();
  const hasDbt =
    !connections.loading &&
    !!connections.connections.find((c) => c.integrationId === "dbt-cloud");

  return (
    <TabLayout tabs={tabs}>
      <>
        <DbtSetupSlideOver
          show={slideOverDisclosure.isOpen}
          onClose={() => {
            slideOverDisclosure.onClose();
          }}
        />
        <DBTButton
          onClick={() => {
            slideOverDisclosure.onOpen();
          }}
          disabled={hasDbt}
        >
          dbt cloud{"  "}
          {hasDbt && (
            <CheckIcon
              className="ml-3 h-5 w-5 text-green-500 dark:text-green-400"
              aria-hidden="true"
            />
          )}
        </DBTButton>
      </>
    </TabLayout>
  );
}

const DBTButton = (props: ComponentProps<typeof Button>) => {
  return (
    <Button
      {...props}
      icon={<IntegrationLogo id="dbt-cloud" />}
      variant="outline"
    />
  );
};
