import { SparklesIcon } from "@heroicons/react/24/outline";
import { Outlet, Route } from "@tanstack/react-location";
import { useCurrentUser } from "@/providers/UserProvider";
import { LocationGenerics } from "routes";

const BackOfficeGuard = () => {
  const user = useCurrentUser();
  return user.weldSuperAdmin ? <Outlet /> : null;
};

export const backOfficeRoute: Route<LocationGenerics> = {
  path: "back-office",
  element: <BackOfficeGuard />,
  meta: {
    breadcrumb: () => <SparklesIcon className="h-5" />,
  },
  children: [
    {
      path: "elt-errors",
      children: [
        {
          path: "details",
          children: [
            {
              path: ":accountId",
              children: [
                {
                  path: "sources",
                  children: [
                    {
                      path: ":syncId",
                      element: () =>
                        import("./BackOfficeEltSyncDetails").then((m) => (
                          <m.default />
                        )),
                    },
                  ],
                },
                {
                  path: "revese-etl",
                  children: [
                    {
                      path: ":syncId",
                      element: () =>
                        import("./BackOfficeEltSyncDetails").then((m) => (
                          <m.default />
                        )),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "stream-list",
          children: [
            {
              path: ":syncId",
              element: () =>
                import("./BackOfficeEltList/BackOfficeEltList").then((m) => (
                  <m.default />
                )),
            },
          ],
        },
        {
          element: () =>
            import("./BackOfficeEltList/BackOfficeEltList").then((m) => (
              <m.default />
            )),
        },
      ],
    },
    {
      path: "notifications",
      element: () =>
        import("./BackOfficeNotificationsPage").then((m) => <m.default />),
    },
    {
      path: "freemium-accounts",
      element: () =>
        import("./FreemiumAccountsPage").then((m) => <m.default />),
    },
    {
      path: "erd-generator",
      element: () =>
        import("./ERDGenerator/BackOfficeERDGeneratorPage").then((m) => (
          <m.default />
        )),
    },
    {
      path: "usage-forecast",
      element: () =>
        import("./usage-forecast/UsageForecastPage").then((m) => <m.default />),
    },
    {
      element: () => import("./BackOfficeHomePage").then((m) => <m.default />),
    },
  ],
};
