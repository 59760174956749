import { IconButton, UtilityButton } from "@/components/elements/Button";
import WeldLogo from "@/components/elements/Weldlogo";
import { useHideGlobalBanners } from "@/components/modules/AppBanners";
import { WorkspaceSwitcher } from "@/components/modules/WorkspaceSwitcher";
import { useAuth0 } from "@auth0/auth0-react";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";

import { CollapsibleSidebar } from "../components/CollapsibleSidebar";
import { ConfettiRoot } from "../components/Confetti";

export default function SetupLayout(
  props: React.PropsWithChildren<{
    showWorkspaceSwitcher?: boolean;
  }>,
) {
  const { logout } = useAuth0();
  useHideGlobalBanners();
  return (
    <>
      <ConfettiRoot />
      <div className="absolute inset-0">
        <CollapsibleSidebar>
          <div className="relative flex h-full flex-col overflow-hidden bg-white dark:bg-gray-900 dark:text-white">
            <div className="mt-8 pb-4">
              <WeldLogo className="mx-auto h-3 w-auto" />
              <div className="absolute right-2 top-2">
                {props.showWorkspaceSwitcher && (
                  <WorkspaceSwitcher>
                    <IconButton
                      icon={<SquaresPlusIcon className="h-6 w-6" />}
                      className="bg-gray-500 text-white hover:bg-gray-500/80"
                    />
                  </WorkspaceSwitcher>
                )}
              </div>
              <div className="absolute left-2 top-2 leading-none">
                <UtilityButton
                  type="button"
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                  className="text-gray-300"
                >
                  Sign out
                </UtilityButton>
              </div>
            </div>
            <div className="flex flex-1 flex-col overflow-auto">
              <div className="h-full w-full">{props.children}</div>
            </div>
          </div>
        </CollapsibleSidebar>
      </div>
    </>
  );
}
