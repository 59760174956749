import { classed } from "@/components/classed.config";

export const Card = classed.div(
  "flex flex-col relative min-w-0 break-words shadow-sm border dark:border-gray-700 rounded-sm",
);

export const CardHeader = classed.div("px-5 pt-3");

export const CardBody = classed.div("p-5 flex-auto");

export const CardFooter = classed.div("px-5 py-3 flex");
