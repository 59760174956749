import { RadioGroup } from "@headlessui/react";
import { useEltJobsCompletionStatsQuery } from "@/apollo/types";
import { Card, CardBody } from "@/components/elements/Card";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Tooltip from "@/components/elements/Tooltip";
import { TextMuted } from "@/components/elements/Typography";
import dayjs from "dayjs";
import cn from "@/helpers/classNames";
import { useMemo, useState } from "react";
import { formatPercent, formatRowCountCompact } from "@/shared/formatters";

const periodOptions = [
  {
    label: "24 Hours",
    value: "1d",
  },
  {
    label: "30 Days",
    value: "30d",
  },
] as const;

type PeriodOption = (typeof periodOptions)[number]["value"];

export function EltJobsCompletionStats() {
  const [period, setPeriod] = useState<PeriodOption>("30d");

  const fromDate = useMemo(() => {
    const toDate = (v: typeof period) => {
      switch (v) {
        case "1d":
          return dayjs().subtract(1, "days").toDate();
        default:
          return dayjs().subtract(30, "days").toDate();
      }
    };
    const date = toDate(period);
    // Round to the nearest 5 minutes
    const coeff = 1000 * 60 * 5;
    return new Date(Math.floor(date.getTime() / coeff) * coeff);
  }, [period]);

  const { data, loading } = useEltJobsCompletionStatsQuery({
    variables: {
      from: fromDate,
    },
  });
  const stats = data?.eltJobsCompletionStats.stats;
  return (
    <div className="flex w-fit flex-col">
      <RadioGroup
        value={period}
        onChange={setPeriod}
        className="mb-1.5 flex gap-2 px-2"
      >
        {periodOptions.map((option) => (
          <RadioGroup.Option
            key={option.value}
            as="button"
            value={option.value}
            className="leading-none"
          >
            {({ checked }) => (
              <span
                className={cn(
                  "text-xs text-gray-500 underline-offset-2 hover:underline",
                  {
                    "font-semibold text-blue-600 underline": checked,
                  },
                )}
              >
                {option.label}
              </span>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      <Card className="min-w-[18rem] rounded">
        <CardBody className="px-0 py-4">
          <div className="flex divide-x">
            <div className="px-8">
              <Tooltip
                content={
                  <>
                    The total count of synchronization jobs completed during the
                    period.
                  </>
                }
              >
                <TextMuted as="div" className="mb-1.5 text-xs">
                  Total sync jobs
                </TextMuted>
              </Tooltip>
              <div className="flex h-7 items-center text-2xl font-medium">
                {loading ? (
                  <LoadingSpinner />
                ) : stats ? (
                  formatRowCountCompact(stats.total)
                ) : (
                  <StatsNotAvailable />
                )}
              </div>
            </div>
            <div className="px-8">
              <Tooltip
                content={
                  <>
                    The percentage of synchronization jobs that completed
                    successfully during the period.
                  </>
                }
              >
                <TextMuted as="div" className="mb-1.5 text-xs">
                  Success rate
                </TextMuted>
              </Tooltip>
              <div className="flex h-7 items-center text-2xl font-medium">
                {loading ? (
                  <LoadingSpinner />
                ) : stats ? (
                  formatPercent(stats.successPercent, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: stats.successPercent > 0.999 ? 2 : 1,
                  })
                ) : (
                  <StatsNotAvailable />
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

function StatsNotAvailable() {
  return (
    <Tooltip content="Metric not available for this period">
      <TextMuted className="text-sm italic underline decoration-dashed underline-offset-2">
        N/A
      </TextMuted>
    </Tooltip>
  );
}
