import { PropsWithChildren } from "react";

import AppearTransition from "./AppearTransition";

export default function FormContainer(props: PropsWithChildren<{}>) {
  return (
    <AppearTransition>
      <div className="mx-auto w-full rounded border bg-white px-8 dark:border-gray-700 dark:bg-gray-800 dark:text-white">
        {props.children}
      </div>
    </AppearTransition>
  );
}
