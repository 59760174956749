import { useCallback, useMemo } from "react";

import {
  ListModelFoldersQuery,
  ListModelsQuery,
  useListModelFoldersQuery,
} from "@/apollo/types";

type Folder = ListModelFoldersQuery["listModelFolders"][number];
type Model = ListModelsQuery["models"][number];

export const useModelFolders = (
  options: Parameters<typeof useListModelFoldersQuery>[0] = {},
) => {
  const { data: { listModelFolders: modelFolders = [] } = {}, loading } =
    useListModelFoldersQuery(options);
  return { modelFolders, loading };
};

export function useSubFolders(baseFolder: Folder | null) {
  const { modelFolders } = useModelFolders();
  return useMemo(() => {
    if (baseFolder == null) {
      return modelFolders;
    }
    return modelFolders.filter((f) => f.parentId === baseFolder.id);
  }, [modelFolders, baseFolder]);
}

export const useModelFolder = (id?: string) => {
  const { modelFolders, loading } = useModelFolders();
  const modelFolder = useMemo(
    () => modelFolders.find((f) => f.id === id),
    [id, modelFolders],
  );
  return { modelFolder, loading };
};

export const useBaseFolders = () => {
  const { modelFolders, loading } = useModelFolders();

  const baseFolders = useMemo(
    () => modelFolders.filter((f) => f.parentId === null),
    [modelFolders],
  );

  return { baseFolders, loading };
};

function generateFolderTrail(
  folderId: string | null | undefined,
  folders: Folder[],
) {
  if (folderId == null) return null;

  const traverseFolders = (
    currentFolderId: string,
    trail: Folder[],
    allFolders: Folder[],
  ): Folder[] => {
    const currentFolder = allFolders.find((f) => f.id === currentFolderId);
    if (currentFolder) {
      trail.unshift(currentFolder);
      if (currentFolder.parentId != null) {
        return traverseFolders(currentFolder.parentId, trail, allFolders);
      }
    }
    return trail;
  };

  return traverseFolders(folderId, [], folders);
}

export function useModelFolderHierarchy(folderId: string | null | undefined) {
  const { modelFolders } = useModelFolders();
  return useMemo(() => {
    if (folderId == null) return [];
    const list = generateFolderTrail(folderId, modelFolders);
    if (list == null) {
      return [];
    }
    return list;
  }, [folderId, modelFolders]);
}

export function useGetModelPath() {
  const { modelFolders } = useModelFolders();
  return useCallback(
    (model: Pick<Model, "folder" | "name">) => {
      if (model.folder?.id == null) return model.name;
      const folderList = generateFolderTrail(model.folder.id, modelFolders);
      if (folderList == null || folderList.length === 0) {
        return model.name;
      }
      return folderList
        .map((f) => f.name)
        .concat(model.name)
        .join(".");
    },
    [modelFolders],
  );
}

export function useModelPath(model?: Model) {
  const getModelPath = useGetModelPath();
  return useMemo(() => {
    if (model == null) return null;
    return getModelPath(model);
  }, [model, getModelPath]);
}
