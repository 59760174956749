import { useCallback } from "react";

import { DependencyType, GetRawViewsQuery } from "@/apollo/types";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";

import { usePreview } from "../Preview/usePreview";
import { useCreateDraftModel } from "../QueryEditor/useModelDraft";

export const useQueryRawData = () => {
  const createDraftModel = useCreateDraftModel();
  const openModelInTab = useOpenModelInTab();

  const preview = usePreview();

  return useCallback(
    (rawView: GetRawViewsQuery["getRawViews"][0]) => {
      const sqlQuery = `SELECT * FROM {{${rawView.weldTag}}} LIMIT 100;`;

      const draft = createDraftModel(sqlQuery);

      openModelInTab({ modelId: draft.id, type: "draft" });
      preview({
        sqlQuery: sqlQuery,
        dependencies: [
          {
            dwItemId: rawView.viewId,
            weldTag: rawView.weldTag,
            type: DependencyType.RawView,
          },
        ],
        modelId: draft.id,
        isTextSelection: false,
        modelName: `${draft.name} (${draft.number})`,
        modelType: "draft",
      });
    },
    [createDraftModel, openModelInTab, preview],
  );
};
