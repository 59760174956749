import { IntegrationSyncStatsFragment } from "@/apollo/types";
import { useChartTheme } from "@/features/charts/themes";
import { RowCountBasis } from "@/features/usage-stats";
import { range } from "lodash";
import { ComponentProps } from "react";
import {
  Bar,
  BarChart,
  BarProps,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { gridLines, yAxisWidth } from "./chartConstants";
import ChartTooltip from "./ChartTooltip";
import PopperPortal from "./PopperPortal";
import { StatsTable } from "./StatsTable";
import SyncChartCategoryTick from "./SyncChartCategoryTick";
import { formatRowsSynced } from "./utils";

type RowsSyncedChartProps = {
  width?: number;
  height?: number;
  data: IntegrationSyncStatsFragment[];
  rowCountBasis: RowCountBasis;
  isBillableRowsEnabled: boolean;
  showEltData?: boolean;
  showReverseEltData?: boolean;
};

export function RowsSyncedByIntegrationChart(props: RowsSyncedChartProps) {
  const {
    data,
    width: containerWidth,
    height: containerHeight,
    showEltData = true,
    showReverseEltData = true,
  } = props;
  const chartTheme = useChartTheme("stats");
  if (containerWidth == null || containerHeight == null) {
    return null;
  }

  const barListProps: Omit<BarProps, "ref">[] = [];
  if (showEltData !== false) {
    barListProps.push({
      dataKey:
        props.rowCountBasis === "billable-rows"
          ? "elt.numBillableRows"
          : "elt.numRowsSynced",
      ...chartTheme.Bar,
    });
  }
  if (showReverseEltData !== false) {
    barListProps.push({
      dataKey:
        props.rowCountBasis === "billable-rows"
          ? "reverseElt.numBillableRows"
          : "reverseElt.numRowsSynced",
      ...chartTheme.BarSecondary,
    });
  }

  return (
    <BarChart
      {...chartTheme.BarChart}
      layout="vertical"
      width={containerWidth}
      height={Math.max(data.length * 30, 120)}
      data={data}
    >
      <CartesianGrid
        {...chartTheme.CartesianGrid}
        horizontal={false}
        verticalPoints={range(gridLines).map((i) => {
          // Generate vertical grid lines positions
          const gridTickWidth = (containerWidth - yAxisWidth) / gridLines;
          return yAxisWidth + gridTickWidth * (i + 1);
        })}
      />
      <XAxis
        type="number"
        hide
        domain={([, dataMax]) => {
          // Extend the domain a bit by adding an additional grid line to make sure the label of longest bar is visible
          return [0, (dataMax / gridLines) * (gridLines + 1)];
        }}
      />
      <YAxis
        {...chartTheme.YAxis}
        dataKey="integrationId"
        type="category"
        width={yAxisWidth}
        tick={<SyncChartCategoryTick />}
      />
      <Tooltip
        {...chartTheme.Tooltip}
        content={
          <IntegrationsTooltip
            isBillableRowsEnabled={props.isBillableRowsEnabled}
            showEltData={showEltData}
            showReverseEltData={showReverseEltData}
          />
        }
      />
      {barListProps.map((barProps, index, arr) => {
        const isLast = index === arr.length - 1;
        return (
          <Bar
            key={index}
            stackId="a"
            label={
              isLast
                ? chartTheme.Bar.getLabelProps({
                    formatter: formatRowsSynced,
                  })
                : undefined
            }
            {...barProps}
            radius={isLast ? chartTheme.Bar.radius : [0, 0, 0, 0]}
          />
        );
      })}
    </BarChart>
  );
}

type IntegrationsTooltipProps = {
  active?: boolean;
  label?: string;
  payload?: {
    value: number;
    payload: IntegrationSyncStatsFragment;
    name: string;
    dataKey: string;
    fill: string;
  }[];
  isBillableRowsEnabled?: boolean;
  showEltData?: boolean;
  showReverseEltData?: boolean;
};

function IntegrationsTooltip({
  active,
  label,
  payload,
  isBillableRowsEnabled,
  showEltData,
  showReverseEltData,
}: IntegrationsTooltipProps) {
  if (!active || !payload || !payload.length || !label) {
    return null;
  }
  const data = payload[0].payload;
  const eltData = payload.find((x) => x.dataKey.startsWith("elt"));
  const reverseEltData = payload.find((x) => x.dataKey.startsWith("reverse"));

  const showLabels = showEltData && showReverseEltData;
  const statsData: ComponentProps<typeof StatsTable>["data"] = [];
  if (showEltData && eltData) {
    statsData.push({
      numRowsSynced: eltData.payload.elt.numRowsSynced,
      numBillableRows: eltData.payload.elt.numBillableRows,
      color: eltData.fill,
      label: showLabels ? "Data sources" : "",
    });
  }
  if (showReverseEltData && reverseEltData) {
    statsData.push({
      numRowsSynced: reverseEltData.payload.reverseElt.numRowsSynced,
      numBillableRows: reverseEltData.payload.reverseElt.numBillableRows,
      color: reverseEltData.fill,
      label: showLabels ? "Reverse ETL" : "",
    });
  }
  return (
    <PopperPortal active={active}>
      <ChartTooltip.Container className="flex flex-col gap-2 p-0">
        <ChartTooltip.Header
          integrationId={data.integrationId}
          name={label}
          className="px-3 pt-3"
        />
        <StatsTable showBillableRows={isBillableRowsEnabled} data={statsData} />
      </ChartTooltip.Container>
    </PopperPortal>
  );
}
