import { ComponentProps } from "@tw-classed/react";
import cn from "@/helpers/classNames";
import React, { forwardRef } from "react";

import { Button, ButtonIcon, useButtonProps } from "./Button";

export type IconButtonProps = Omit<
  ComponentProps<typeof Button>,
  "iconRight" | "loadingText"
> & {
  icon: React.ReactElement;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (propsArg, ref) => {
    const {
      icon,
      children,
      as,
      size = "md",
      ...restProps
    } = useButtonProps(propsArg);
    return (
      <Button
        ref={ref}
        size={size}
        as={as as any}
        {...restProps}
        className={cn("p-0", restProps.className)}
      >
        <ButtonIcon size={size} icon={icon as React.ReactElement} />
        {children}
      </Button>
    );
  },
);
