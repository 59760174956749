import { useState } from "react";

import { IconButton } from "@/components/elements/Button";
import cn from "@/helpers/classNames";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";

export type QuestionComponentProps<T> = {
  onChange: (value: T) => void;
};

const otherOption = { label: "Other", value: "other", isCustom: true };
const referralOptions: { label: string; value: string; isCustom?: boolean }[] =
  [
    { label: "Facebook Ad", value: "facebook" },
    { label: "LinkedIn", value: "linkedin" },
    { label: "Instagram", value: "instagram" },
    { label: "Search (Google, Bing, etc.)", value: "search" },
    { label: "Referral (eg. from friend/colleague)", value: "referral" },
    otherOption,
  ];

export function ReferralQuestion(props: QuestionComponentProps<string>) {
  const [selectedOption, setSelectedOption] =
    useState<(typeof referralOptions)[number]>();
  const [customValue, setCustomValue] = useState("");
  return (
    <>
      <RadioGroup
        value={selectedOption}
        onChange={(option) => {
          setSelectedOption(option);
          if (!option.isCustom) {
            props.onChange(option.value);
          }
        }}
      >
        <RadioGroup.Label className="block pb-4 text-xl font-medium">
          Where did you hear about us?
        </RadioGroup.Label>
        <div className="flex flex-col gap-2">
          {referralOptions.map((option) => (
            <RadioGroup.Option value={option} key={option.value}>
              {({ checked, active }) => (
                <div className="flex items-center gap-2">
                  {option.isCustom ? (
                    <>
                      <input
                        type="radio"
                        value={otherOption.value}
                        checked={checked}
                        className="form-radio"
                        readOnly
                      />
                      {active || checked ? (
                        <>
                          <input
                            autoFocus
                            type="text"
                            className={cn(
                              "h-6 w-full max-w-72 border-b bg-black/5 px-1 outline-none ring-0 focus:ring-0 dark:bg-white/5 dark:text-white",
                              {
                                "border-blue-600": active,
                              },
                            )}
                            placeholder="Other (please specify)"
                            value={customValue}
                            onFocus={() => {
                              setSelectedOption(otherOption);
                            }}
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) => {
                              setCustomValue(e.target.value);
                            }}
                          />
                          <IconButton
                            icon={<CheckIcon />}
                            size="sm"
                            className="h-6 w-6 min-w-6"
                            colorScheme="primary"
                            variant="solid"
                            onClick={() => {
                              const value = customValue
                                .toLowerCase()
                                .trim()
                                .replace(/\s+/g, "-");
                              props.onChange(value);
                            }}
                          />
                        </>
                      ) : (
                        <RadioGroup.Label>
                          {customValue || "Other"}
                        </RadioGroup.Label>
                      )}
                    </>
                  ) : (
                    <>
                      <input
                        type="radio"
                        value={option.value}
                        checked={checked}
                        className="form-radio"
                        readOnly
                      />
                      <RadioGroup.Label>{option.label}</RadioGroup.Label>
                    </>
                  )}
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}

const currentDataAccessOptions = [
  { label: "I manually export data", value: "manual-export" },
  { label: "I use other ETL tools", value: "etl-tool" },
  { label: "I use no-code solutions", value: "no-code-solution" },
  { label: "I'm new to data integration.", value: "none" },
];

export function CurrentDataAccessQuestion(
  props: QuestionComponentProps<string>,
) {
  return (
    <>
      <RadioGroup onChange={props.onChange}>
        <RadioGroup.Label className="block pb-4 text-xl font-medium">
          How do you currently manage your data access?
        </RadioGroup.Label>
        <div className="flex flex-col gap-2">
          {currentDataAccessOptions.map((option) => (
            <RadioGroup.Option key={option.value} value={option.value}>
              {({ checked }) => (
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    value={option.value}
                    checked={checked}
                    className="form-radio"
                    readOnly
                  />
                  <RadioGroup.Label>{option.label}</RadioGroup.Label>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}

const intendedUseOptions = [
  { label: "Internal use", value: "internal" },
  { label: "External use", value: "external" },
];

export function IntendedUse(props: QuestionComponentProps<string>) {
  return (
    <>
      <RadioGroup onChange={props.onChange}>
        <RadioGroup.Label className="block pb-4 text-xl font-medium">
          What is your intended use for WELD?
        </RadioGroup.Label>
        <div className="flex flex-col gap-2">
          {intendedUseOptions.map((option) => (
            <RadioGroup.Option key={option.value} value={option.value}>
              {({ checked }) => (
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    value={option.value}
                    checked={checked}
                    className="form-radio"
                    readOnly
                  />
                  <RadioGroup.Label>{option.label}</RadioGroup.Label>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}

const sqlFamiliarityOptions = [
  { label: "No, I've never used it", value: "no" },
  { label: "Yes, I'm a beginner", value: "beginner" },
  { label: "Yes, I'm experienced", value: "experienced" },
];

export function SQLFamiliarity(props: QuestionComponentProps<string>) {
  return (
    <>
      <RadioGroup onChange={props.onChange}>
        <RadioGroup.Label className="block pb-4 text-xl font-medium">
          Are you familiar with SQL?
        </RadioGroup.Label>
        <div className="flex flex-col gap-2">
          {sqlFamiliarityOptions.map((option) => (
            <RadioGroup.Option key={option.value} value={option.value}>
              {({ checked }) => (
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    value={option.value}
                    checked={checked}
                    className="form-radio"
                    readOnly
                  />
                  <RadioGroup.Label>{option.label}</RadioGroup.Label>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}

const connectorCountOptions = [
  { label: "Up to 4", value: "4" },
  { label: "Between 5 and 8", value: "5-8" },
  { label: "More than 8", value: "8+" },
];

export function ConnectorCount(props: QuestionComponentProps<string>) {
  return (
    <>
      <RadioGroup onChange={props.onChange}>
        <RadioGroup.Label className="block pb-4 text-xl font-medium">
          How many connectors do you plan to set up with WELD?
        </RadioGroup.Label>
        <div className="flex flex-col gap-2">
          {connectorCountOptions.map((option) => (
            <RadioGroup.Option key={option.value} value={option.value}>
              {({ checked }) => (
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    value={option.value}
                    checked={checked}
                    className="form-radio"
                    readOnly
                  />
                  <RadioGroup.Label>{option.label}</RadioGroup.Label>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}
