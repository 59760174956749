import { PropsWithChildren, createContext, useContext } from "react";

import { useCurrentMigration } from "./useCurrentMigration";

const DataWarehouseMigrationContext = createContext<ReturnType<
  typeof useCurrentMigration
> | null>(null);

export function useDataWarehouseMigrationContext() {
  const context = useContext(DataWarehouseMigrationContext);
  if (!context) {
    throw new Error(
      "useDataWarehouseMigrationContext must be used within a DataWarehouseMigrationProvider",
    );
  }
  return context;
}

export function DatawarehouseMigrationProvider(props: PropsWithChildren<{}>) {
  const migrationState = useCurrentMigration();
  return (
    <DataWarehouseMigrationContext.Provider value={migrationState}>
      {props.children}
    </DataWarehouseMigrationContext.Provider>
  );
}
