export interface NavigatorUAData {
  readonly platform: string;
}

export function isWindows() {
  if ("userAgentData" in navigator) {
    const userAgentData = (navigator as any).userAgentData as NavigatorUAData;
    return userAgentData.platform.includes("Win");
  }
  return navigator.platform.includes("Win");
}

export const optionKeyString = `${isWindows() ? "Ctrl" : "⌘"}`;
