import { EltSync } from "@/apollo/types";
import {
  getSourceStreamJobIds,
  useFilteredNotifications,
} from "./filterNotifications";
import { useMemo } from "react";

/**
 * Returns all the notifications that may affect the SourceStream.
 *
 * This includes:
 * - Account notifications
 * - Connector notifications
 * - Sync notifications
 * - Stream notifications
 */
export const useSourceStreamNotifications = (
  eltSync: Pick<EltSync, "id" | "sourceIntegrationId" | "sourceConnectionId">,
  sourceStreamName: string,
) => {
  const filters = useMemo(() => {
    return {
      connectionIds: [eltSync.sourceConnectionId],
      syncIds: [eltSync.id],
      scheduledJobIds: getSourceStreamJobIds({
        eltSyncId: eltSync.id,
        sourceIntegrationId: eltSync.sourceIntegrationId,
        sourceStreamNames: [sourceStreamName],
      }),
    };
  }, [
    eltSync.id,
    eltSync.sourceConnectionId,
    eltSync.sourceIntegrationId,
    sourceStreamName,
  ]);

  const { notifications } = useFilteredNotifications(filters);

  const allNotifications = useMemo(
    () => [
      ...notifications.account,
      ...notifications.connector,
      ...notifications.sync,
      ...notifications.eltStream,
    ],
    [notifications],
  );

  return allNotifications;
};
