import { motion } from "framer-motion";
import { ComponentProps, useState } from "react";

import { Button, ButtonGroup } from "@/components/elements/Button";
import { CloseButtonIcon } from "@/components/elements/CloseButton";
import { TextMuted } from "@/components/elements/Typography";
import cn from "@/helpers/classNames";
import { useDarkMode } from "@/providers/DarkModeProvider";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

import { useDocumentationPane } from "./useDocumentationPane";

export function DocumentationPane(
  props: ReturnType<typeof useDocumentationPane>["paneProps"] &
    Pick<ComponentProps<"div">, "className">,
) {
  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
          width: 0,
          transition: {
            duration: 0.15,
          },
        },
        visible: {
          opacity: 1,
          width: "100%",
          transition: {
            duration: 0.15,
          },
        },
      }}
      animate={props.isOpen ? "visible" : "hidden"}
      initial="hidden"
      className={cn(
        "relative flex max-w-xs flex-col overflow-hidden border-l border-t bg-white dark:bg-zinc-900 sm:max-w-sm md:max-w-md lg:max-w-lg",
        props.className,
      )}
    >
      <div className="z-10 flex w-full flex-none justify-end border-b bg-gray-50 shadow dark:bg-gray-900">
        <ButtonGroup size="xs" variant="ghost" className="flex gap-3 p-2">
          <Button
            as="a"
            icon={<ArrowTopRightOnSquareIcon />}
            href={props.data?.href}
            target="_blank"
          >
            Open in new tab
          </Button>
          <Button icon={<CloseButtonIcon />} onClick={() => props.onClose()}>
            Close guide
          </Button>
        </ButtonGroup>
      </div>
      {props.data && (
        <DocumentationView
          title={`Documentation for ${props.data.title}`}
          src={props.data.embedHref}
          sectionId={props.data.setupSectionIds?.etl}
          className="w-screen max-w-xs grow sm:max-w-sm md:max-w-md lg:max-w-lg"
        />
      )}
    </motion.div>
  );
}

export function DocumentationView({
  src,
  title,
  sectionId,
  ...props
}: ComponentProps<"iframe"> & {
  src: string;
  title: string;
  sectionId?: string;
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const url = new URL(src);
  const { isDarkModeEnabled } = useDarkMode();
  url.searchParams.set("forcedTheme", isDarkModeEnabled ? "dark" : "light");
  if (sectionId) {
    url.hash = sectionId;
  }
  return (
    <>
      {!isLoaded && (
        <div className="absolute inset-0 flex items-center justify-center">
          <TextMuted>Loading documentation...</TextMuted>
        </div>
      )}
      <iframe
        {...props}
        src={url.toString()}
        title={title}
        onLoad={() => setIsLoaded(true)}
        loading="lazy"
      />
    </>
  );
}
