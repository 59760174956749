import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import useWeldAPI from "@/hooks/useWeldAPI";
import { useCallback, useState } from "react";
import invariant from "tiny-invariant";

import { CreateChatMessageInput } from "../types";
import { useCompletionStream } from "./useCompletionStream";

type ResultContext = {
  threadId: string;
  messageId: string;
  responseMessageId: string;
};

type ChatStreamOptions = {
  onChunk?: (chunk: string, context: ResultContext) => void;
  onThreadCreated?: (context: ResultContext) => void;
  onMessageCreated?: (context: ResultContext) => void;
  onCompleted?: (value: string, context: ResultContext) => void;
};

export function useChatStream(options?: ChatStreamOptions) {
  const hookOptionsRef = useLatestValueRef(options);
  const [isLoading, setIsLoading] = useState(false);
  const fetchApi = useWeldAPI();
  const [processStream, meta] = useCompletionStream();
  return [
    useCallback(
      async (
        options: { input: CreateChatMessageInput } & ChatStreamOptions,
      ) => {
        setIsLoading(true);
        try {
          const response = await fetchApi("/ai-assistant-v2/chat-message", {
            method: "POST",
            body: JSON.stringify({
              input: options.input,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          const threadId = response.headers.get("X-Chat-Thread-Id");
          const messageId = response.headers.get("X-Chat-Message-Id");
          const responseMessageId = response.headers.get(
            "X-Chat-ResponseMessage-Id",
          );

          invariant(threadId, "No threadId returned from fetch");
          invariant(messageId, "No messageId returned from fetch");
          invariant(
            responseMessageId,
            "No responseMessageId returned from fetch",
          );

          const reader = response.body?.getReader();
          invariant(reader, "No reader returned from fetch");

          const resultContext = { messageId, threadId, responseMessageId };
          if (options.input.threadId === undefined && threadId) {
            options.onThreadCreated?.(resultContext);
            hookOptionsRef.current?.onThreadCreated?.(resultContext);
          }
          options.onMessageCreated?.(resultContext);
          hookOptionsRef.current?.onMessageCreated?.(resultContext);

          const message = await processStream(reader, {
            onChunk: (value) => options.onChunk?.(value, resultContext),
            onCompleted: (value) => options.onCompleted?.(value, resultContext),
          });
          if (message) {
            options.onCompleted?.(message, resultContext);
            hookOptionsRef.current?.onCompleted?.(message, resultContext);
          }
          return {
            threadId,
            messageId,
            responseMessageId,
          };
        } finally {
          setIsLoading(false);
        }
      },
      [fetchApi, processStream, hookOptionsRef],
    ),
    {
      ...meta,
      isLoading: meta.isLoading || isLoading,
    },
  ] as const;
}
