import { useValidateCustomFormQuery } from "@/apollo/types";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Select from "@/components/elements/Select";
import { TextMuted } from "@/components/elements/Typography";
import { Checkbox } from "@/components/primitives/Checkbox";
import FieldLabel from "@/components/primitives/InputLabel";
import { useMountEffect } from "@/hooks/useMountEffect";
import { DropdownOption, FormType } from "@/integrations";
import { useToast } from "@/providers/ToastProvider";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import {
  FolderOption,
  GoogleDriveFolderInput,
} from "./GoogleDriveFolderSelector";

type FormValues = {
  connectionId: string;
  folderConfig: FolderOption | null;
  syncMode: DropdownOption<string> | null;
  fileType: DropdownOption<string> | null;
  hasHeaderRow: boolean | undefined;
  stringifyProperties: boolean | undefined;
};

export function GoogleDriveForm(props: {
  field: Extract<FormType, { type: "google-drive-form" }>;
}) {
  const { register, setValue, getValues, watch } = useFormContext<FormValues>();
  const toast = useToast();

  const {
    fileType,
    connectionId,
    folderConfig,
    hasHeaderRow,
    syncMode,
    stringifyProperties,
  } = watch();

  const fileTypeOptions = props.field.fileType;
  const syncModeOptions = props.field.syncMode;

  useMountEffect(() => {
    register("folderConfig", { required: true });
    register("syncMode", { required: true });
    register("fileType", { required: true });
    register("hasHeaderRow", { required: false });
    register("stringifyProperties", { required: false });

    if (fileTypeOptions.length === 1) {
      setValue("fileType", fileTypeOptions[0]);
    }
    if (syncModeOptions.length === 1) {
      setValue("syncMode", syncModeOptions[0]);
    }
  });

  const { data: filesData, loading: isLoadingFiles } =
    useValidateCustomFormQuery({
      variables: {
        input: {
          connectionId: getValues()["connectionId"],
          payload: folderConfig
            ? JSON.stringify({ folderId: folderConfig.value, fileType })
            : "",
        },
      },
      skip: !folderConfig,
      onError(error) {
        toast("Google Drive API call failed", error.message, "error");
      },
    });

  const filesInFolder: string[] | undefined =
    filesData?.validateCustomForm.customFormValidationResult;
  const partialFilesInFolder = useMemo(
    () => filesInFolder?.slice(0, 40) ?? [],
    [filesInFolder],
  );

  const setFileType = (option: DropdownOption<string> | null) => {
    setValue("fileType", option);
  };

  const setHasHeaderRow = (value: boolean) => {
    setValue("hasHeaderRow", value);
  };

  const setSyncMode = (value: DropdownOption<string>) => {
    setValue("syncMode", value);
  };

  const setFolder = (value: FolderOption | null) => {
    setValue("folderConfig", value);
  };

  const setStringifyProperties = (value: boolean) => {
    setValue("stringifyProperties", value);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="max-w-sm">
        <FieldLabel htmlFor="file-type" required={true}>
          File type
        </FieldLabel>
        <Select
          inputId="file-type"
          placeholder="Select one..."
          onChange={(v: DropdownOption<string>) => {
            setFileType(v);
          }}
          options={fileTypeOptions}
          value={fileType}
          hideSelectedOptions={false}
        />
      </div>

      <div>
        <FieldLabel htmlFor="hasHeaderRow" required={true}>
          First row is column names
        </FieldLabel>
        <Checkbox
          id="hasHeaderRow"
          checked={hasHeaderRow ?? false}
          onChange={() => {
            setHasHeaderRow(!hasHeaderRow);
          }}
        />
      </div>

      <div>
        <FieldLabel htmlFor="stringifyProperties" required={true}>
          Stringify all properties
        </FieldLabel>
        <Checkbox
          id="stringifyProperties"
          checked={stringifyProperties ?? false}
          onChange={() => {
            setStringifyProperties(!stringifyProperties);
          }}
        />
      </div>

      <div className="max-w-sm">
        <FieldLabel htmlFor="sync-mode" required={true}>
          Sync mode
        </FieldLabel>
        <Select
          inputId="sync-mode"
          placeholder="Select one..."
          onChange={(v: DropdownOption<string>) => {
            setSyncMode(v);
          }}
          options={syncModeOptions}
          value={syncMode}
          hideSelectedOptions={false}
        />
      </div>

      <div className="max-w-sm">
        <FieldLabel htmlFor="folder" required={true}>
          Folder
        </FieldLabel>
        <GoogleDriveFolderInput
          inputId="folder"
          value={folderConfig}
          onChangeFolder={(folder: FolderOption | null) => {
            setFolder(folder);
          }}
          connectionId={connectionId}
        />
      </div>

      {isLoadingFiles ? (
        <div className="flex gap-2 text-sm">
          <LoadingSpinner /> Loading files...
        </div>
      ) : (
        <div>
          <div className="text-sm font-semibold">
            Files matching setup: {filesInFolder?.length ?? 0}
          </div>
          <div className="mt-2 columns-4 gap-x-4 gap-y-2 text-sm">
            {partialFilesInFolder.map((x) => (
              <div key={x} title={x} className="truncate">
                <span className="truncate">{x}</span>
              </div>
            ))}
          </div>
          {filesInFolder &&
            partialFilesInFolder.length < filesInFolder.length && (
              <TextMuted as="p" className="mt-2 text-sm italic">
                and {filesInFolder.length - partialFilesInFolder.length} more...
              </TextMuted>
            )}
        </div>
      )}
    </div>
  );
}
