import {
  Connection,
  ConnectionWithCredentialsDocument,
  ConnectionsDocument,
  useConnectionLazyQuery,
  useUpdateConnectionMutation,
} from "@/apollo/types";

import { useOAuth } from "../oauth";
import { IntegrationType } from "../types";

type ConnectionOptions = {
  onSuccess: (
    connection: Pick<Connection, "id" | "label" | "integrationId">,
  ) => void;
  onError: (error: Error) => void;
};

export function useUpdateConnection(
  connectionId: string,
  integration: IntegrationType,
  options: ConnectionOptions,
) {
  const [updateConnection, { loading, client }] = useUpdateConnectionMutation({
    refetchQueries: [
      { query: ConnectionsDocument },
      {
        query: ConnectionWithCredentialsDocument,
        variables: { id: connectionId },
      },
    ],
    onCompleted: (data) => {
      options.onSuccess(data.updateConnection);
    },
    onError: (e) => {
      options.onError(e);
    },
  });

  const [fetchConnection] = useConnectionLazyQuery();

  const { handleOAuth, oAuthLoading } = useOAuth({
    onSuccess: async (connectionId) => {
      client.refetchQueries({
        include: [ConnectionsDocument],
      });
      const { data } = await fetchConnection({
        variables: {
          id: connectionId,
        },
      });
      if (data?.connection == null) {
        options.onError(new Error("Connection not found"));
        return;
      }
      options.onSuccess(data.connection);
    },
    onError: options.onError,
  });

  return {
    loading: loading || oAuthLoading,
    updateConnection: (data: Record<string, any>) => {
      if (integration.form.type === "oauth") {
        return handleOAuth({
          integrationId: integration.id,
          connectionId,
          data,
        });
      }

      return updateConnection({
        variables: {
          connectionId,
          payload: btoa(JSON.stringify(data)),
        },
      });
    },
  };
}
