import classNames from "@/helpers/classNames";
import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import useMediaQuery from "@/hooks/useMediaQuery";
import { useMountEffect } from "@/hooks/useMountEffect";
import React from "react";
import { twMerge } from "tailwind-merge";

import Tooltip from "../Tooltip";

type SidebarState = {
  shouldOverlay: boolean; // mobile vs desktop
  isCollapsed: boolean; // thin vs wide
  isToggled: boolean; // open vs closed for mobile
  width: number;
  collapsedWidth: number;
  breakpoint: number;
};

type SidebarActions = {
  updateSidebarState: (
    state:
      | Partial<SidebarState>
      | ((currentState: SidebarState) => Partial<SidebarState>),
  ) => void;
  toggleSidebar: (value?: boolean) => void;
  toggleCollapseState: (value?: boolean) => void;
};

type SidebarContextType = SidebarState & SidebarActions;

const defaultSidebarState: SidebarState = {
  shouldOverlay: false,
  isCollapsed: false,
  isToggled: false,
  width: 255,
  collapsedWidth: 52,
  breakpoint: 768,
};

const SidebarContext = React.createContext<SidebarContextType | null>(null);

export function useSidebar() {
  const ctx = React.useContext(SidebarContext);
  if (ctx == null) {
    throw new Error("SidebarProvider is missing");
  }
  return ctx;
}

export function SidebarProvider({
  children,
  onCollapseChange,
  ...options
}: {
  children: React.ReactNode;
  onCollapseChange?: (isCollapsed: boolean) => void;
} & Partial<SidebarState>) {
  const [state, setState] = React.useState<SidebarState>(() => ({
    ...defaultSidebarState,
    ...options,
  }));

  const onCollapseChangeRef = useLatestValueRef(onCollapseChange);

  const updateState = React.useCallback(
    (
      state:
        | Partial<SidebarState>
        | ((currentState: SidebarState) => Partial<SidebarState>),
    ) => {
      setState((prevState) => {
        const newState = typeof state === "function" ? state(prevState) : state;
        return {
          ...prevState,
          ...newState,
        };
      });
    },
    [],
  );

  const toggleSidebar = React.useCallback((value?: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isToggled: value === undefined ? !prevState.isToggled : value,
    }));
  }, []);

  const toggleCollapseState = React.useCallback(
    (value?: boolean) => {
      const newValue = value === undefined ? !state.isCollapsed : value;
      setState({
        ...state,
        isCollapsed: newValue,
      });
      onCollapseChangeRef.current?.(newValue);
    },
    [state, onCollapseChangeRef],
  );

  const ctx = React.useMemo(
    () => ({
      ...state,
      updateSidebarState: updateState,
      toggleSidebar,
      toggleCollapseState,
    }),
    [state, updateState, toggleSidebar, toggleCollapseState],
  );

  return (
    <SidebarContext.Provider value={ctx}>{children}</SidebarContext.Provider>
  );
}

export function Sidebar(props: {
  children: React.ReactNode;
  defaultCollapsed?: boolean;
}) {
  const { children, defaultCollapsed } = props;

  const {
    isCollapsed,
    isToggled,
    breakpoint,
    width,
    collapsedWidth,
    updateSidebarState,
    toggleSidebar,
    toggleCollapseState,
  } = useSidebar();

  const shouldOverlay = useMediaQuery(`(max-width: ${breakpoint}px)`);

  React.useEffect(() => {
    updateSidebarState((prevState) => ({
      shouldOverlay: shouldOverlay,
      isCollapsed: shouldOverlay ? false : prevState.isCollapsed,
    }));
  }, [shouldOverlay, updateSidebarState]);

  useMountEffect(() => {
    if (defaultCollapsed) {
      toggleCollapseState(true);
    }
  });

  const handleBackdropClick = () => {
    toggleSidebar(false);
  };

  return (
    <aside
      className={classNames(
        shouldOverlay && "fixed left-0 top-0 z-40 h-full overflow-hidden",
        "transition-all duration-300 ease-in-out",
      )}
      style={{
        width: isCollapsed ? collapsedWidth : width,
        minWidth: isCollapsed ? collapsedWidth : width,
        left: shouldOverlay ? (isToggled ? 0 : width * -1) : undefined,
      }}
    >
      <div className="relative z-[2] h-full">{children}</div>
      {shouldOverlay && isToggled && (
        <div
          role="button"
          tabIndex={0}
          aria-label="backdrop"
          onClick={handleBackdropClick}
          className="fixed inset-0 z-[1] bg-black/50"
        />
      )}
    </aside>
  );
}

export function NavDivider({
  className,
  ...props
}: React.ComponentProps<"div">) {
  return (
    <div
      className={twMerge(
        classNames("my-2 h-px bg-gray-200 dark:bg-gray-700", className),
      )}
      {...props}
    />
  );
}

type AsProp<EType extends React.ElementType> = {
  as?: EType;
};

type NavButtonProps<EType extends React.ElementType> = {
  icon: React.ReactElement;
  children: React.ReactNode;
} & React.PropsWithChildren<AsProp<EType>> &
  React.ComponentPropsWithoutRef<EType>;

function NavButtonInner<EType extends React.ElementType = "button">(
  { icon, children, as, ...asComponentProps }: NavButtonProps<EType>,
  ref: React.ForwardedRef<any>,
) {
  const Component = as || "button";
  const ctx = useSidebar();
  return (
    <Tooltip
      content={ctx.isCollapsed ? <>{children}</> : null}
      placement="right-start"
    >
      <span>
        <Component
          ref={ref}
          {...asComponentProps}
          className={twMerge(
            classNames(
              "flex w-full items-center gap-3 rounded-md p-2",
              "text-left text-sm font-medium",
              "transition-all",
              "focus:outline-none focus:ring-2",
              "bg-black/0 hover:bg-black/10 active:bg-black/20 dark:bg-white/0 dark:hover:bg-white/10 dark:active:bg-white/20",
              asComponentProps.className,
            ),
          )}
        >
          {React.cloneElement(icon, {
            className: classNames("w-5 h-5 shrink-0", icon.props.className),
            "aria-hidden": "true",
            role: "presentation",
          })}
          <span className="flex-1 truncate">{children}</span>
        </Component>
      </span>
    </Tooltip>
  );
}

export const NavButton = React.forwardRef(NavButtonInner) as <
  EType extends React.ElementType = "button",
>(
  props: NavButtonProps<EType> & { ref?: React.ForwardedRef<any> },
) => ReturnType<typeof NavButtonInner>;

export function SkeletonLoader({
  className,
  ...props
}: React.ComponentProps<"div">) {
  return (
    <div
      className={classNames("animation-gradient-loading rounded-sm", className)}
      {...props}
    />
  );
}
