import dayjs from "dayjs";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";

import { PlanName } from "@/apollo/types";
import { useCurrentPlan } from "@/features/subscription";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useMountEffect } from "@/hooks/useMountEffect";
import { useTimeout } from "@/hooks/useTimeout";
import { createStorageKey } from "@/utils/storage";

import { EssentialPlanDiscontinuationDialog } from "../shared/ui/EssentialPlanDiscontinuationDialog";
import { discontinuationDate, getNextDismissedUntil } from "../shared/utils";

const bannerAtom = atomWithStorage<{
  dismissedUntil: number;
}>(createStorageKey("essential-plan-discontinuation-dialog"), {
  dismissedUntil: 0,
});

export function AutoShowingEssentialPlanDiscontinuationDialog() {
  const weldPlan = useCurrentPlan();
  const { showEssentialPlanDiscontinuationAnnouncement } = useFlags();
  if (
    weldPlan.name !== PlanName.Essential ||
    !showEssentialPlanDiscontinuationAnnouncement
  ) {
    return null;
  }
  // eslint-disable-next-line react/jsx-pascal-case
  return <_AutoShowingEssentialPlanDiscontinuationDialog />;
}

function _AutoShowingEssentialPlanDiscontinuationDialog() {
  const [state, setState] = useAtom(bannerAtom);

  const disclosure = useDisclosure();

  useMountEffect(() => {
    if (
      typeof state.dismissedUntil === "number" &&
      state.dismissedUntil <= Date.now()
    ) {
      disclosure.onOpen();
    }
  });

  const delay = useMemo(() => {
    if (
      typeof state.dismissedUntil === "number" &&
      state.dismissedUntil > Date.now()
    ) {
      return state.dismissedUntil - Date.now();
    }
    return null;
  }, [state.dismissedUntil]);

  useTimeout(() => {
    if (
      typeof state.dismissedUntil === "number" &&
      state.dismissedUntil <= Date.now()
    ) {
      disclosure.onOpen();
    }
  }, delay);

  return (
    <EssentialPlanDiscontinuationDialog
      {...disclosure}
      onClose={() => {
        const nextDismissedUntil = getNextDismissedUntil(
          dayjs(),
          discontinuationDate,
        );
        setState({ dismissedUntil: nextDismissedUntil.valueOf() });
        disclosure.onClose();
      }}
    />
  );
}
