import { ComponentProps, deriveClassed } from "@tw-classed/react";
import { classed } from "@/components/classed.config";
import ButtonPrimitive from "@/components/primitives/Button";
import cn from "@/helpers/classNames";
import React from "react";

export const ButtonCardBase = classed(
  ButtonPrimitive,
  "group relative flex items-center justify-center transition-all",
  "bg-white hover:bg-blue-50 dark:bg-gray-800 dark:hover:bg-blue-400/10",
  "rounded-md border border-gray-200 hover:border-blue-200 focus:border-gray-300 dark:border-gray-700 dark:hover:border-gray-500 dark:focus:border-gray-500",
  "focus:ring-blue-600 focus:ring-offset-2",
  "overflow-hidden",
);

export type ButtonCardProps = ComponentProps<typeof ButtonCard>;

export const ButtonCard = deriveClassed<
  typeof ButtonCardBase,
  ComponentProps<typeof ButtonCardBase>
>((props, ref) => {
  return <ButtonCardBase ref={ref} type="button" {...props} />;
});

type ButtonCardIconProps = {
  icon: React.ReactElement;
};

export const ButtonCardIcon = ({
  className,
  icon,
}: React.PropsWithChildren<
  ButtonCardIconProps & React.ComponentProps<"div">
>) => {
  return (
    <div
      className={cn(
        "flex h-12 w-12 items-center justify-center rounded-full bg-gray-100 transition-all group-hover:bg-blue-100 dark:bg-gray-700 dark:group-hover:bg-gray-600",
        className,
      )}
    >
      {React.isValidElement<{ className: string }>(icon) &&
        React.cloneElement(icon, {
          className: cn(icon.props.className, "w-6"),
        })}
    </div>
  );
};

export const ButtonCardText = classed.div("text-xs");

export const RadioButtonCard = classed(
  ButtonCard,
  "focus-within:ring-offset-0 focus:ring-offset-0 dark:hover:bg-blue-400/10",
  {
    variants: {
      isChecked: {
        true: "bg-blue-50 ring-[3px] ring-blue-600 focus-within:ring-[3px] focus:ring-[3px] dark:bg-blue-400/10",
      },
    },
  },
);
