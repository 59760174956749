export default function syncOperationExplainers(syncOperation: string): string {
  if (syncOperation === "upsert") {
    return "Inserts new records and updates already synced data";
  } else if (syncOperation === "insert") {
    return "Inserts new data only - first time syncs all data";
  } else if (syncOperation === "mirror") {
    return "Syncs all data everytime";
  } else if (syncOperation === "update") {
    return "Update existing data only";
  } else {
    return "";
  }
}
