import { getAuth } from "@firebase/auth";
import { getDatabase } from "@firebase/database";
import { useMountEffect } from "@/hooks/useMountEffect";
import React from "react";

import { useSignInWithToken } from "./auth/useSignInWithToken";
import firebaseConfig from "./config";
import { FirebaseAppProvider, useFirebaseApp } from "./firebaseApp";
import { FirebaseAuthProvider, FirebaseDatabaseProvider } from "./sdk";

function WeldFirebaseSdkProviders(props: React.PropsWithChildren<{}>) {
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);
  const database = getDatabase(firebaseApp);

  const signInWithToken = useSignInWithToken();

  useMountEffect(() => {
    signInWithToken(auth);
  });

  return (
    <FirebaseAuthProvider sdk={auth}>
      <FirebaseDatabaseProvider sdk={database}>
        {props.children}
      </FirebaseDatabaseProvider>
    </FirebaseAuthProvider>
  );
}

export function WeldFirebaseAppProvider(props: React.PropsWithChildren<{}>) {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <WeldFirebaseSdkProviders>{props.children}</WeldFirebaseSdkProviders>
    </FirebaseAppProvider>
  );
}
