import { Notification, useSetNextSyncToFullSyncMutation } from "@/apollo/types";
import { useRetryNotification } from "@/features/notifications";
import { useCallback } from "react";

export const useRunFullSyncNotification = (options?: {
  onCompleted?: (message: string) => void;
  onError?: (message: string) => void;
}) => {
  const [setNextSyncToFullSync, { loading: loadingRunFullSync }] =
    useSetNextSyncToFullSyncMutation();
  const { retry, loading: retryLoading } = useRetryNotification({
    onCompleted: () =>
      options?.onCompleted?.("Successfully scheduled a full sync"),
    onError: () =>
      options?.onError?.(
        "Error occurred while queueing the sync for a full sync",
      ),
  });

  const handleRunFullSync = useCallback(
    (notification: Notification, sourceStreamId: string) => {
      if (loadingRunFullSync || retryLoading) return;

      void setNextSyncToFullSync({
        variables: {
          sourceStreamId: sourceStreamId,
          forceFullSync: true,
        },
        onCompleted: () => {
          retry(notification);
        },
        onError: () =>
          options?.onError?.(
            "Error occurred while queueing the sync for a full sync",
          ),
      });
    },
    [loadingRunFullSync, retryLoading, setNextSyncToFullSync, retry, options],
  );

  return {
    runFullSync: handleRunFullSync,
    loading: retryLoading || loadingRunFullSync,
  };
};
