import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ListModelsQuery, MaterializationType } from "@/apollo/types";
import Tooltip from "@/components/elements/Tooltip";
import classNames from "@/helpers/classNames";
import React from "react";

import { isDraftModel } from "../utils/modelUtils";

type Props = {
  model: ListModelsQuery["models"][0];
};

const MaterializationInfo = (props: Props) => {
  if (isDraftModel(props.model)) return null; // Only show for published models

  return props.model.materializationType === MaterializationType.Table ? (
    <MaterializedTableInformation model={props.model} />
  ) : (
    <ViewInformation model={props.model} />
  );
};

export default MaterializationInfo;

const MaterializedTableInformation = (props: Props) => {
  const existsInDatawarehouse =
    props.model.dwTable !== null && props.model.dwTable?.latestSyncTime;

  const tableCreateFailed = props.model.dwTable?.status === "FAILED";

  if (existsInDatawarehouse && !tableCreateFailed) return null;

  return (
    <Tooltip
      content={
        tableCreateFailed
          ? "An error occured creating the table."
          : "The table is being created."
      }
    >
      <div>
        <InformationCircleIcon
          className={classNames(
            "ml-1 h-3 w-3",
            tableCreateFailed
              ? "text-danger"
              : "text-gray-600 dark:text-gray-200",
          )}
        />
      </div>
    </Tooltip>
  );
};
const ViewInformation = (props: Props) => {
  const existsInDatawarehouse = props.model.dwSync !== null;
  const outOfSync =
    props.model.publishedQuery?.id !== props.model.dwSync?.queryId;
  if (existsInDatawarehouse && !outOfSync) return null; //Todo: add info about where it is in datawarehouse?

  return (
    <Tooltip
      content={
        outOfSync
          ? "Model is out of sync with datawarehouse."
          : "Model does not exist in datawarehouse."
      }
    >
      <div>
        <InformationCircleIcon
          className={classNames("ml-1 h-3 w-3 text-danger")}
        />
      </div>
    </Tooltip>
  );
};
