import { ComponentProps, Suspense, useEffect } from "react";

import { Alert, AlertIcon } from "@/components/elements/Alert";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import { WELD_EXISTING_CUSTOMER_CALL_URL } from "@/constants/external-urls/hubspot";
import { WELD_PRICING_URL } from "@/constants/external-urls/weld-website";
import { useMixpanel } from "@/monitoring/mixpanel";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/16/solid";
import { ErrorBoundary } from "@sentry/react";

import { BestFitLoader, BestFitPlanProjection } from "./BestFitPlanProjection";

export function EssentialPlanDiscontinuationDialog(
  props: ComponentProps<typeof Modal>,
) {
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (props.isOpen) {
      mixpanel.track("Essential Plan Discontinuation Dialog - Opened");
    }
  }, [props.isOpen, mixpanel]);

  return (
    <Modal {...props} centerOnScreen={false} size="lg">
      <ModalHeader>Upcoming Discontinuation of the Essential Plan</ModalHeader>
      <ModalCloseButton />
      <ModalBody className="pb-5">
        <div className="space-y-4">
          <p>Thank you for being a valued Weld customer! </p>
          <p>
            We wanted to remind you that the Essential Plan will be{" "}
            <strong>discontinued on December 1st, 2024</strong>. Until this
            date, you’ll need to transition to one of our updated plans, which
            you can{" "}
            <a
              href={WELD_PRICING_URL}
              className="inline-flex items-center text-blue-600 underline"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                mixpanel.track(
                  "Essential Plan Discontinuation Dialog - Pricing Page Clicked",
                );
              }}
            >
              explore on our website{" "}
              <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
            </a>
            . This transition is necessary for us to continue operating and to
            provide even better support for all our customers.
          </p>
          <p>
            We've reviewed your usage and tailored the best option for you.
            Check out our recommended plan below:
          </p>
          <ErrorBoundary>
            <Suspense fallback={<BestFitLoader />}>
              <BestFitPlanProjection />
            </Suspense>
          </ErrorBoundary>
          <p>
            We’d be happy to have a price discussion or negotiation with you
            prior to this date so we can offer you the best possible price. You
            can{" "}
            <a
              href={WELD_EXISTING_CUSTOMER_CALL_URL}
              className="inline-flex items-center text-blue-600 underline"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                mixpanel.track(
                  "Essential Plan Discontinuation Dialog - Book a Call Clicked",
                );
              }}
            >
              book a call with our sales team{" "}
              <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
            </a>{" "}
            or simply reply to the emails we’ve sent, and we’ll be happy to
            assist you directly.
          </p>
        </div>
        <div className="mt-5">
          <Alert status="warning">
            <AlertIcon />
            If you do not transition to a new plan by December 1st, 2024, we
            will automatically upgrade you to the plan that best fits your
            current usage based on the connectors you have set up.
          </Alert>
        </div>
      </ModalBody>
    </Modal>
  );
}
