import { ApolloClient } from "@apollo/client";
import { Route } from "@tanstack/react-location";
import { ConnectionsDocument } from "@/apollo/types";
import { ConnectionBreadcrumb } from "@/components/elements/breadcrumbs/ConnectionBreadcrumb";
import { IntegrationBreadcrumb } from "@/components/elements/breadcrumbs/IntegrationBreadcrumb";
import { LocationGenerics, getAccountContext } from "routes";

import { ConnectionsPage } from "./Connections";
import EditConnectionPage from "./EditConnection";
import NewConnectionPage from "./NewConnectionPage";
import NewIntegrationPage from "./SetupConnection";
import ViewConnectionPage from "./ViewConnection";

export const connectionsRoute = (
  client: ApolloClient<object>,
): Route<LocationGenerics> => {
  return {
    path: "connections",
    meta: {
      breadcrumb: () => "Connections",
    },
    children: [
      {
        path: "new",
        meta: {
          breadcrumb: () => "New",
        },
        children: [
          {
            path: ":integrationId",
            element: <NewIntegrationPage />,
            meta: {
              breadcrumb: ({ integrationId }) => (
                <IntegrationBreadcrumb integrationId={integrationId} />
              ),
            },
          },
          { element: <NewConnectionPage /> },
        ],
      },
      {
        path: ":connectionId",
        meta: {
          breadcrumb: ({ connectionId }) => (
            <ConnectionBreadcrumb connectionId={connectionId} />
          ),
        },
        children: [
          {
            path: "configuration",
            element: <EditConnectionPage />,
            meta: {
              breadcrumb: () => "Configuration",
            },
          },
          {
            element: <ViewConnectionPage />,
          },
        ],
      },
      {
        element: <ConnectionsPage />,
        loader: async (match) => {
          return (
            await client.query({
              query: ConnectionsDocument,
              context: await getAccountContext(match.params.slug, client),
            })
          ).data;
        },
      },
    ],
  };
};
