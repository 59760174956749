import { useMatch } from "@tanstack/react-location";
import {
  GetSyncErrorsDocument,
  GetSyncErrorsQuery,
  useFindOneSyncQuery,
  useGetSyncErrorsQuery,
  useResetAllErrorsMutation,
  useResetErrorsMutation,
} from "@/apollo/types";
import { SecondaryButton } from "@/components/elements/Button";
import confirm from "@/components/elements/Confirm";
import DataBox from "@/components/elements/DataBox";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import TabLayout from "@/components/layouts/TabLayout";
import { SyncHistory } from "@/components/modules/SyncHistory";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import produce from "immer";
import Lineage from "@/pages/ModelTool/lineage/Lineage";
import SyncButton from "@/pages/ReverseEltSyncs/modules/SyncButton";
import { useMemo } from "react";
import { LocationGenerics } from "routes";

import SyncDetails from "./modules/SyncDetails";

dayjs.extend(relativeTime);
dayjs.extend(duration);

export default function ReverseEltSyncLayout() {
  const tabs = useMemo(
    () => [
      { name: "Overview", href: `.`, id: "overview" },
      {
        name: "Configuration",
        href: `./configuration`,
        id: "configuration",
      },
    ],
    [],
  );

  return (
    <TabLayout tabs={tabs} action={<SyncButton />}>
      <ReverseEltSync />
    </TabLayout>
  );
}

function ReverseEltSync() {
  const {
    params: { syncId },
  } = useMatch<LocationGenerics>();

  const [resetErrorsMutation, { loading }] = useResetAllErrorsMutation({
    variables: {
      id: syncId,
    },
    refetchQueries: [
      {
        query: GetSyncErrorsDocument,
        variables: {
          id: syncId,
        },
      },
    ],
  });
  const handleResetAll = async () => {
    if (
      await confirm({
        message:
          "Are you sure you want to reset all errors? All objects that failed will be retried on next sync.",
        title: "Reset errors",
      })
    )
      resetErrorsMutation();
  };

  return (
    <div className="flex flex-col gap-8">
      {/* <ReverseEltSyncNotifications syncId={syncId} /> */}
      <DataBox header="Details">
        <Details syncId={syncId} />
      </DataBox>
      <DataBox
        header="Errors"
        action={
          <SecondaryButton
            size="sm"
            onClick={() => handleResetAll()}
            isLoading={loading}
            loadingText="Resetting"
          >
            Reset all
          </SecondaryButton>
        }
      >
        <AggregatedSyncErrors />
      </DataBox>
      <DataBox header="History">
        <SyncHistory pollTime={5000} />
      </DataBox>

      <DataBox header="Lineage">
        <div style={{ height: "70vh" }}>
          <Lineage focusedId={syncId} />
        </div>
      </DataBox>
    </div>
  );
}

const AggregatedSyncErrors = () => {
  const match = useMatch();
  const { data, loading } = useGetSyncErrorsQuery({
    variables: {
      id: match.params.syncId,
    },
  });

  const aggregated = useMemo(() => {
    if (!data?.getErrors) return {};
    return data.getErrors.reduce<
      Record<string, NonNullable<typeof data>["getErrors"]>
    >((obj, error) => {
      return produce(obj, (draft) => {
        if (draft[error.error]) {
          draft[error.error].push(error);
        } else {
          draft[error.error] = [error];
        }
      });
    }, {});
  }, [data?.getErrors]);

  if (loading) return <LoadingSpinner />;

  if (!Object.keys(aggregated).length) {
    return <div className="text-sm">No errors found for this sync</div>;
  }
  return (
    <div className="isolate max-h-96 overflow-auto">
      <table className="-mx-2 w-full border-collapse">
        <thead className="relative z-[1]">
          <tr className="sticky top-0 bg-white dark:bg-gray-800">
            <th className="whitespace-nowrap px-2 py-1 text-left text-xs font-normal text-gray-600 dark:text-gray-400">
              Message
            </th>
            <th className="whitespace-nowrap px-2 py-1 text-right text-xs font-normal text-gray-600 dark:text-gray-400">
              Failed Objects
            </th>
            <th className="whitespace-nowrap px-2 py-1 text-left text-xs font-normal text-gray-600 dark:text-gray-400"></th>
          </tr>
        </thead>
        <tbody className="overflow-y-auto">
          {Object.entries(aggregated).map(([message, errors]) => (
            <AggregatedErrorItem
              key={message}
              message={message}
              errors={errors}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const AggregatedErrorItem = (props: {
  errors: GetSyncErrorsQuery["getErrors"];
  message: string;
}) => {
  const match = useMatch();
  const [resetErrorsMutation, { loading }] = useResetErrorsMutation({
    refetchQueries: [
      {
        query: GetSyncErrorsDocument,
        variables: {
          id: match.params.syncId,
        },
      },
    ],
  });
  const handleResetAll = async () => {
    if (
      await confirm({
        message:
          "Are you sure you want to reset all objects with this error? All objects with this error will be retried on next sync.",
        title: "Reset errors",
      })
    )
      resetErrorsMutation({
        variables: {
          id: match.params.syncId,
          objectIds: props.errors.map((error) => error.objectId),
        },
      });
  };

  return (
    <tr>
      <td className="px-2 py-1 font-mono text-xs">{props.message}</td>
      <td className="px-2 py-1 text-right align-top text-sm">
        {props.errors.length}
      </td>
      <td className="px-2 py-1 text-right align-top">
        <SecondaryButton
          isLoading={loading}
          loadingText="Resetting"
          size="xs"
          onClick={() => handleResetAll()}
        >
          Reset
        </SecondaryButton>
      </td>
    </tr>
  );
};

export const Details = ({ syncId }: { syncId: string }) => {
  const { data } = useFindOneSyncQuery({
    variables: { syncId: syncId },
  });

  const sync = data?.findOneSync;
  if (!sync?.sourceIntegrationId) return null;
  return (
    <div className="flex flex-col space-y-2 xl:flex-row">
      <div className="w-full space-y-4 xl:w-1/2">
        <SyncDetails.ModelName sync={sync} />
        <SyncDetails.Connector sync={sync} />
        <SyncDetails.Schedule sync={sync} />
        <SyncDetails.Operation sync={sync} />
      </div>
      <div className="w-full space-y-2 xl:w-1/2">
        <SyncDetails.Mappings sync={sync} />
      </div>
    </div>
  );
};
