import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import classNames from "@/helpers/classNames";
import { ComponentProps, ReactNode, createContext, useContext } from "react";

import { IconButton } from "../Button";

type BannerStatus = "info" | "warning" | "error";
type BannerColorScheme = "blue" | "yellow" | "red";
type BannerSize = "sm" | "md";

const COLORS: Record<BannerColorScheme, { container: string; icon: string }> = {
  blue: {
    container:
      "border-blue-200 bg-blue-50 dark:border-blue-600 dark:bg-blue-600/20",
    icon: "text-blue-500",
  },
  yellow: {
    container:
      "border-yellow-200 bg-yellow-50 dark:border-yellow-600/80 dark:bg-yellow-600/10",
    icon: "text-yellow-500",
  },
  red: {
    container:
      "border-red-200 bg-red-50 dark:border-red-600/80 dark:bg-red-600/10",
    icon: "text-red-500",
  },
};

const STATUSES: Record<
  BannerStatus,
  {
    icon: typeof InformationCircleIcon;
    colorScheme: keyof typeof COLORS;
  }
> = {
  info: { icon: InformationCircleIcon, colorScheme: "blue" },
  warning: { icon: ExclamationCircleIcon, colorScheme: "yellow" },
  error: { icon: ExclamationTriangleIcon, colorScheme: "red" },
};

function getStatusColorScheme(status: BannerStatus) {
  return STATUSES[status].colorScheme;
}

function getStatusIcon(status: BannerStatus) {
  return STATUSES[status].icon;
}

type BannerOptions = {
  status: BannerStatus;
  size: BannerSize;
  compact?: boolean;
};

const BannerContext = createContext<BannerOptions | null>(null);
const useBannerContext = () => {
  const value = useContext(BannerContext);
  if (value === null) {
    throw new Error("BannerContext is null");
  }
  return value;
};

const useBannerStyles = () => {
  const { status } = useBannerContext();
  const colorScheme = getStatusColorScheme(status);
  return COLORS[colorScheme];
};

type BannerProps = Partial<BannerOptions> & {
  children: ReactNode;
  action?: ReactNode;
};

export function Banner(props: BannerProps) {
  const { status = "info", size = "sm", compact = false } = props;
  return (
    <BannerContext.Provider value={{ status, size, compact }}>
      <div
        className={classNames(
          "relative flex flex-wrap justify-center gap-1 border-b px-6 py-2 text-base text-gray-800 dark:text-white/80",
          COLORS[getStatusColorScheme(status)].container,
          {
            "flex-nowrap": compact,
            "text-sm": size === "sm",
          },
        )}
        role="alert"
      >
        {props.children}
        {props.action && (
          <div className="mx-2 flex shrink-0 items-center">{props.action}</div>
        )}
      </div>
    </BannerContext.Provider>
  );
}

export function BannerIcon(props: ComponentProps<"span">) {
  const { status, size } = useBannerContext();
  const styles = useBannerStyles();
  const Icon = getStatusIcon(status);
  return (
    <span
      {...props}
      className={classNames(
        "mx-1 flex shrink-0 items-center",
        styles.icon,
        props.className,
      )}
    >
      <Icon className={classNames(size === "sm" ? "h-5 w-5" : "h-6 w-6")} />
    </span>
  );
}

export function BannerTitle(props: ComponentProps<"div">) {
  return (
    <div
      {...props}
      className={classNames("shrink-0 text-center font-bold", props.className)}
    />
  );
}

export function BannerDescription(props: ComponentProps<"div">) {
  return (
    <div {...props} className={classNames("text-center", props.className)} />
  );
}

export function BannerCloseButton(
  props: Partial<ComponentProps<typeof IconButton>>,
) {
  return (
    <IconButton
      {...props}
      icon={<XMarkIcon className="h-4 w-4" />}
      variant="ghost"
      className="absolute right-1 top-1 bg-black/0 hover:bg-black/10 dark:bg-white/0 dark:hover:bg-white/10"
      size="xs"
    />
  );
}
