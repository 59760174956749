import classNames from "@/helpers/classNames";
import React from "react";

const Title = ({
  className,
  children,
  ...props
}: React.ComponentProps<"h1">) => (
  <h1 className={classNames("text-2xl font-normal", className)} {...props}>
    {children}
  </h1>
);

const Container = ({
  children,
  className,
}: {
  children: JSX.Element | JSX.Element[];
  className?: string;
}) => (
  <div
    className={classNames(
      "mb-4 flex h-10 shrink-0 items-center justify-between sm:mb-6 md:mb-8",
      className,
    )}
  >
    {children}
  </div>
);

const PageHeader = { Title, Container };
export default PageHeader;
