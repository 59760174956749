import { MakeGenerics, useNavigate, useSearch } from "@tanstack/react-location";
import { useCallback, useRef } from "react";

import { useAiAssistantSidebarContext } from "./AiAssistantSidebarProvider";

type LocationGenerics = MakeGenerics<{
  Search: {
    assistant: "chat" | "history";
    assistantThread: string;
    assistantInitToken: string;
  };
}>;

export function useAssistantSidebar() {
  const navigate = useNavigate<LocationGenerics>();
  const search = useSearch<LocationGenerics>();

  const { setInitOptions } = useAiAssistantSidebarContext();

  const assistantProps = {
    threadId: search.assistantThread,
    view: search.assistant === "history" ? "history" : "chat",
    initToken: search.assistantInitToken,
  } as const;

  const mostRecentOpenParams = useRef(assistantProps);
  if (search.assistant) {
    mostRecentOpenParams.current = assistantProps;
  }
  return {
    props: assistantProps,
    isOpen: search.assistant !== undefined,
    onOpen: useCallback(
      (
        options?: {
          view?: "chat" | "history";
        } & Parameters<typeof setInitOptions>[0],
      ) => {
        const { view, ...initOptions } = options ?? {};
        const initToken = initOptions.input
          ? setInitOptions(initOptions)
          : undefined;
        navigate({
          search: (old) => {
            return {
              ...old,
              assistant: view || "chat",
              assistantThread: options?.threadId,
              assistantInitToken: initToken,
            };
          },
        });
      },
      [navigate, setInitOptions],
    ),
    onToggle: useCallback(() => {
      navigate({
        search: (old) => {
          if (search.assistant === undefined) {
            return {
              ...old,
              assistant: mostRecentOpenParams.current.view,
              assistantThread: mostRecentOpenParams.current.threadId,
            };
          }
          return {
            ...old,
            assistant: undefined,
            assistantThread: undefined,
            assistantInitToken: undefined,
          };
        },
      });
    }, [navigate, search.assistant]),
    onClose: useCallback(() => {
      navigate({
        search: (old) => {
          return {
            ...old,
            assistant: undefined,
            assistantThread: undefined,
            assistantInitToken: undefined,
          };
        },
      });
    }, [navigate]),
  };
}
