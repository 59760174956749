import { RocketLaunchIcon } from "@heroicons/react/20/solid";
import { ClockIcon } from "@heroicons/react/24/solid";
import { PrimaryButton } from "@/components/elements/Button";
import Center from "@/components/elements/layout/Center";
import { useSidebar } from "@/components/elements/Sidebar";
import Tooltip from "@/components/elements/Tooltip";
import { ChangePlanDialog } from "@/features/billing";
import { useSubscriptionStatus } from "@/features/subscription";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "@/helpers/classNames";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useMixpanel } from "@/monitoring/mixpanel";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

function Container(props: ComponentProps<"div">) {
  return (
    <div
      {...props}
      className={twMerge(
        classNames(
          "relative -mx-2 flex shrink items-center overflow-hidden rounded-md py-8",
          props.className,
        ),
      )}
    />
  );
}

export function TrialBox({
  daysLeft,
}: NonNullable<ReturnType<typeof useSubscriptionStatus>["trial"]>) {
  const { isCollapsed } = useSidebar();
  const disclosure = useDisclosure();
  const mixpanel = useMixpanel();

  const onUpgradePlan = () => {
    disclosure.onOpen();
    mixpanel.track("Upgrade Now Button Clicked", {
      source: "sidebar trial box",
    });
  };

  if (isCollapsed) {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              delay: 0.2,
              duration: 0.5,
            },
          }}
        >
          <Container className="px-2">
            <Tooltip
              content={
                <span>
                  Your free trial will end in{" "}
                  <span className="font-medium">{daysLeft} days</span>.
                  <br />
                  To keep using our paid features, upgrade to one of our paid
                  plans.
                </span>
              }
              placement="right-start"
            >
              <Center
                as="button"
                className="group relative isolate h-9 w-9"
                onClick={onUpgradePlan}
              >
                <div className="absolute left-1/2 top-1/2 h-9 w-9 -translate-x-1/2 -translate-y-1/2 rounded-full bg-blue-100 transition-all duration-400 ease-out group-hover:scale-[2] dark:bg-blue-400/20" />
                <div className="absolute left-1/2 top-1/2 h-7 w-7 -translate-x-1/2 -translate-y-1/2 rounded-full bg-blue-600 transition-all duration-400 ease-out group-hover:scale-125 dark:bg-blue-700" />
                <span className="z-0 text-white">{daysLeft}</span>
              </Center>
            </Tooltip>
            <ChangePlanDialog {...disclosure} size="lg" />
          </Container>
        </motion.div>
      </AnimatePresence>
    );
  }
  return (
    <Container>
      <div
        role="button"
        onClick={onUpgradePlan}
        className="mx-2 flex max-h-48 flex-col items-center gap-2 rounded border border-blue-100 bg-blue-50 px-4 py-4 dark:border-white/10 dark:bg-blue-400/10"
      >
        <Center className="group h-10 w-10 shrink-0 rounded-full bg-blue-200 transition-all dark:bg-blue-400/10">
          <ClockIcon className="h-6 w-6 text-blue-600" />
        </Center>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                delay: 0.4,
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
            className="flex flex-col items-center gap-2"
          >
            <div className="text-center text-sm">
              Your free trial will end in
              <br />
              <span className="font-medium">{daysLeft} days</span>
            </div>
            <div className="text-xs">
              To keep using our paid features, upgrade to one of our paid plans
            </div>

            <PrimaryButton size="xs" icon={<RocketLaunchIcon />}>
              Upgrade now
            </PrimaryButton>
          </motion.div>
        </AnimatePresence>
      </div>
      <ChangePlanDialog {...disclosure} size="lg" />
    </Container>
  );
}
