import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { ComponentProps, deriveClassed } from "@tw-classed/react";
import { FormatRelativeTime } from "@/components/elements/FormatRelativeTime";
import { FormatTime } from "@/components/elements/FormatTime";
import { List } from "@/components/elements/List";
import Tooltip from "@/components/elements/Tooltip";
import { TextMuted } from "@/components/elements/Typography";
import cn from "@/helpers/classNames";
import { capitalize } from "lodash";

import { classed } from "../../classed.config";
import { useNotificationItem } from "./useNotificationItem";

const BaseNotificationAlertLevel = classed.span("w-4 h-4", {
  variants: {
    level: {
      ERROR: "text-red-500 dark:text-red-400",
      WARNING: "text-yellow-500 dark:text-yellow-400",
      INFO: "text-blue-500 dark:text-blue-400",
    },
  },
  defaultVariants: {
    level: "INFO",
  },
});

const NotificationAlertLevel = deriveClassed<
  typeof BaseNotificationAlertLevel,
  ComponentProps<typeof BaseNotificationAlertLevel>
>(({ children, level, ...restProps }, ref) => {
  return (
    <Tooltip content={capitalize(level)}>
      <BaseNotificationAlertLevel {...restProps} level={level} ref={ref}>
        {level === "ERROR" ? (
          <ExclamationCircleIcon />
        ) : level === "WARNING" ? (
          <ExclamationTriangleIcon />
        ) : (
          <InformationCircleIcon />
        )}
      </BaseNotificationAlertLevel>
    </Tooltip>
  );
});

export function NotificationItem(
  props: ComponentProps<typeof List.ItemButton> &
    ReturnType<typeof useNotificationItem>,
) {
  const {
    alertLevel,
    id,
    lastOccurence,
    message,
    title,
    icon,
    className,
    integrationId,
    messageData,
    secondaryAction,
    ...listItemProps
  } = props;
  return (
    <List.ItemButton
      {...listItemProps}
      secondaryAction={secondaryAction}
      className={cn("w-full p-4 text-left text-sm", className)}
    >
      <div className="flex w-full gap-4">
        <List.ItemIcon>{icon}</List.ItemIcon>
        <div className="grow overflow-hidden">
          <div className="mb-2 flex items-center gap-4">
            <List.Heading className="truncate">{title}</List.Heading>
            <div className="ml-auto flex flex-none items-center gap-2 text-xs">
              <TextMuted as="div">
                <Tooltip content={<FormatTime date={lastOccurence} />}>
                  <FormatRelativeTime date={lastOccurence} />
                </Tooltip>
              </TextMuted>
              <NotificationAlertLevel level={alertLevel} />
            </div>
          </div>
          <div>
            <List.Text as={TextMuted} className="line-clamp-2 text-xs">
              {message}
            </List.Text>
          </div>
        </div>
      </div>
    </List.ItemButton>
  );
}
