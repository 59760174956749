import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { useEffect } from "react";

export function useMonacoActions(
  editor: monaco.editor.IStandaloneCodeEditor | undefined,
  descriptors: Parameters<
    monaco.editor.IStandaloneCodeEditor["addAction"]
  >[0][],
) {
  const descriptorsRef = useLatestValueRef(descriptors);
  useEffect(() => {
    if (!editor) return;
    const disposables = descriptorsRef.current.map((descriptor) =>
      editor.addAction(descriptor),
    );
    return () => {
      disposables.forEach((disposable) => disposable.dispose());
    };
  }, [descriptorsRef, editor]);
}
