import React, { useCallback } from "react";

import {
  ConnectionsDocument,
  useRefreshDestinationMutation,
} from "@/apollo/types";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Tooltip from "@/components/elements/Tooltip";
import { useConnection } from "@/features/connectors";
import classNames from "@/helpers/classNames";
import { IntegrationLogo } from "@/integrations";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

import useDestinationProperties from "../hooks/useDestinationProperties";
import useDestinations from "../hooks/useDestinations";
import useModelOptions from "../hooks/useModelOptions";
import { useSyncContext } from "./SyncContext";

export default function MappingIcons(props: { hasDeleteButton: boolean }) {
  const [state, dispatch] = useSyncContext();
  const toast = useToast();

  const { currentDestination, destinationsLoading } = useDestinations();

  const { currentQuery, modelsLoading: queriesLoading } = useModelOptions(
    state.queryId,
  );
  const { refetchDestinationProperties } = useDestinationProperties();

  const [refreshDestination] = useRefreshDestinationMutation({
    onError(error) {
      dispatch({ type: "refreshed_destination_properties" });
      toast("Destination properties not refreshed", error.message, "error");
    },
    onCompleted() {
      refetchDestinationProperties();
      dispatch({ type: "refreshed_destination_properties" });
      toast(
        "Destination properties refreshed",
        "Your destination properties has succesfully been refreshed.",
        "success",
      );
    },

    refetchQueries: [
      { query: ConnectionsDocument, variables: { type: "destination" } },
    ],
  });

  const handleRefreshDestinationProperties = useCallback(() => {
    if (!state.destinationId || state.refreshingDestinationProperties) return;

    dispatch({ type: "refresh_destination_properties" });

    refreshDestination({
      variables: { connectionId: state.destinationId },
    });
  }, [
    dispatch,
    state.destinationId,
    state.refreshingDestinationProperties,
    refreshDestination,
  ]);

  const account = useCurrentAccount();
  const { connection: dwConnection } = useConnection(
    account.dataWarehouseConnectionId ?? "",
  );

  return (
    <div className="flex items-center space-x-4">
      <div className="grow">
        <div className="flex items-center space-x-2">
          <div className="flex-1">
            {currentQuery && dwConnection ? (
              <IntegrationBadge
                integrationId={dwConnection?.integrationId}
                objectName={currentQuery?.label}
              />
            ) : queriesLoading ? (
              <LoadingSpinner />
            ) : (
              <MissingPropertiesTag>missing source</MissingPropertiesTag>
            )}
          </div>
          <div className="flex shrink-0 justify-center px-6">
            <ChevronRightIcon className="w-4 text-gray-400" />
          </div>

          <div className="flex flex-1 justify-end lg:justify-start">
            {currentDestination ? (
              <div className="flex shrink-0 items-center space-x-2">
                <IntegrationBadge
                  integrationId={currentDestination.integrationId}
                  objectName={state.objectTypeName}
                />
                <Tooltip
                  content={`Reload the available properties for destination.`}
                >
                  <button
                    type="button"
                    disabled={state.refreshingDestinationProperties}
                    className={classNames(
                      `flex items-center space-x-1 text-xs underline dark:text-gray-400`,
                      state.refreshingDestinationProperties && "opacity-50",
                    )}
                    onClick={handleRefreshDestinationProperties}
                  >
                    <span>Refresh properties</span>
                    <ArrowPathIcon
                      className={classNames(
                        `w-3 text-gray-600 dark:text-gray-400`,
                        state.refreshingDestinationProperties && "animate-spin",
                      )}
                    />
                  </button>
                </Tooltip>
              </div>
            ) : destinationsLoading ? (
              <LoadingSpinner />
            ) : (
              <MissingPropertiesTag>Missing Destination</MissingPropertiesTag>
            )}
          </div>
        </div>
      </div>
      {props.hasDeleteButton && <div className={`w-6 shrink-0`} />}
    </div>
  );
}

type IntegrationBadgeProps = {
  integrationId: string;
  objectName: string;
};

const IntegrationBadge = ({
  integrationId,
  objectName,
}: IntegrationBadgeProps) => (
  <div className="inline-flex items-center space-x-2 rounded bg-gray-100 px-1.5 py-1 text-xs dark:bg-gray-800 dark:text-gray-300">
    <IntegrationLogo className="h-4" id={integrationId} />
    <span className="truncate">{objectName}</span>
  </div>
);

function MissingPropertiesTag(props: React.ComponentProps<"div">) {
  return (
    <div className="inline-block rounded-sm bg-gray-100 p-1 text-xs dark:bg-gray-700">
      {props.children}
    </div>
  );
}
