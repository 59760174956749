import { useEffect, useState } from "react";

import {
  EltSyncsQuery,
  ListModelsQuery,
  QueryDependencyInput,
} from "@/apollo/types";
import Tooltip from "@/components/elements/Tooltip";
import TableIcon from "@/components/icons/TableIcon";
import { IntegrationLogo } from "@/integrations";

import { useFullModelPathRenderer } from "../hooks/useFullPathNames";
import { useGetLineageDependencies } from "../lineage/useLineageDag";

export const OrchestrationInfo = (props: {
  workflowId: string;
  modelId: string;
  dependencies: QueryDependencyInput[];
}) => {
  const { modelDependencies, syncDependencies } = useOrchestrationDependencies(
    props.modelId,
    props.dependencies,
    props.workflowId,
  );

  const hasModelDependencies = modelDependencies.length > 0;
  const hasSyncDependencies = syncDependencies.length > 0;

  if (!hasModelDependencies && !hasSyncDependencies) return null;

  return (
    <div className="flex items-center space-x-2 rounded border px-4 py-2 dark:border-gray-700">
      <div className="text-sm">
        The workflow will update the model after the following dependencies have
        finished updating:{" "}
        <ul className="ml-1 list-inside list-disc">
          {hasSyncDependencies && (
            <li>
              <Tooltip
                content={
                  <div>
                    {syncDependencies.map((d) => (
                      <span className="flex items-center space-x-2" key={d.id}>
                        <IntegrationLogo
                          className="h-4 w-4"
                          id={d.sourceIntegrationId}
                        />
                        <span>{d.destinationSchemaName}</span>
                      </span>
                    ))}
                  </div>
                }
              >
                <span className="cursor-pointer underline">
                  {syncDependencies.length} syncs
                </span>
              </Tooltip>
            </li>
          )}
          {hasModelDependencies && (
            <li>
              <Tooltip
                content={
                  <ModelDisplayTooltipContent models={modelDependencies} />
                }
              >
                <span className="cursor-pointer underline">
                  {modelDependencies.length} models
                </span>
              </Tooltip>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

const useOrchestrationDependencies = (
  modelId: string,
  queryDependencies: QueryDependencyInput[],
  workflowId: string,
) => {
  const getLineageDependencies = useGetLineageDependencies();

  const [dependencies, setDependencies] = useState({
    modelDependencies: [] as ListModelsQuery["models"],
    syncDependencies: [] as EltSyncsQuery["eltSyncs"],
  });

  useEffect(() => {
    async function getDependencies() {
      const lineageDependencyNodes = await getLineageDependencies(
        modelId,
        queryDependencies.map((d) => d.dwItemId),
      );
      const modelDependencies: ListModelsQuery["models"] = [];
      const syncDependencies: EltSyncsQuery["eltSyncs"] = [];

      lineageDependencyNodes.forEach((d) => {
        if (
          d.type === "model" &&
          d.model.orchestrationWorkflow?.id === workflowId
        )
          modelDependencies.push(d.model);
        if (
          d.type === "elt-sync" &&
          d.eltSync.orchestrationWorkflow?.id === workflowId
        ) {
          syncDependencies.push(d.eltSync);
        }
      });

      setDependencies({ modelDependencies, syncDependencies });
    }

    getDependencies();
  }, [getLineageDependencies, modelId, queryDependencies, workflowId]);

  return dependencies;
};

const ModelDisplayTooltipContent = (props: {
  models: ListModelsQuery["models"];
}) => {
  return (
    <div>
      {props.models.length === 0 && "No models found as dependencies."}
      {props.models.map((m) => (
        <ModelNameDisplay model={m} key={m.id} />
      ))}
    </div>
  );
};
const ModelNameDisplay = (props: {
  className?: string;
  model: ListModelsQuery["models"][0];
}) => {
  const displayName = useFullModelPathRenderer(props.model);
  return (
    <div className="flex items-center space-x-2">
      <TableIcon className="h-3 w-3 text-white" />
      <span>{displayName}</span>
    </div>
  );
};
