import { useFlags } from "launchdarkly-react-client-sdk";
import { maxBy, minBy } from "lodash";
import { useCallback, useMemo } from "react";

import {
  FeatureSet,
  PlanName,
  Product,
  WeldAddOnType,
  WeldPlan,
  useAllWeldPlansQuery,
  useCurrentWeldPlanQuery,
} from "@/apollo/types";

import { useAvailablePlanAddOns } from "./usePlanAddOns";

const DefaultFeatureSet: FeatureSet = {
  aiAssistantHistoryEnabled: false,
  auditLogsEnabled: false,
  columnHashingEnabled: false,
  customConnectorEnabled: false,
  limitConnectors: {
    value: 2,
  },
  limitUsers: {
    value: 1,
  },
  limitRows: {
    value: 20_000_000,
  },
  limitWorkspaces: {
    value: 1,
  },
  lineageEnabled: true,
  minSyncIntervalInMinutes: 24 * 60,
  modelTestingEnabled: false,
  onDemandSyncsEnabled: false,
  orchestrationEnabled: false,
};

export function useCurrentPlan() {
  const { data: currentPlanData, loading: isLoadingCurrentPlan } =
    useCurrentWeldPlanQuery();

  const currentPlan = currentPlanData?.currentPlan;
  const planName = currentPlan?.name ?? PlanName.Premium;
  const features = currentPlan?.features ?? DefaultFeatureSet;

  return {
    loading: isLoadingCurrentPlan,
    ...currentPlan,
    name: planName,
    features,
    priceLookupKeys: currentPlan?.priceLookupKeys ?? null,
    userPriceLookupKeys: currentPlan?.userPriceLookupKeys ?? null,
    configurableLimits: currentPlan?.configurableLimits ?? null,
  };
}

export function useWeldPlan(name: PlanName) {
  const { data: allWeldPlansData, loading } = useAllWeldPlansQuery();
  return {
    loading,
    plan: allWeldPlansData?.allWeldPlans.find((x) => x.name === name),
  };
}

export function useWeldPlans() {
  const { data: allWeldPlansData, loading } = useAllWeldPlansQuery();
  const planAddOns = useAvailablePlanAddOns();

  // These flags are used to filter the displayed plans for the current account and are used as a sales hack.
  const { displayOnlyBusinessPlan, displayOnlyPremiumPlan } = useFlags();

  const plans = useMemo(() => {
    const plans = allWeldPlansData?.allWeldPlans ?? [];
    if (displayOnlyBusinessPlan) {
      return plans.filter((x) => x.name === PlanName.Business);
    }
    if (displayOnlyPremiumPlan) {
      return plans.filter((x) => x.name === PlanName.Premium);
    }
    return plans;
  }, [displayOnlyBusinessPlan, displayOnlyPremiumPlan, allWeldPlansData]);

  const activePlans = plans.filter((x) => x.isLegacyPlan !== true);

  return {
    loading,
    plans,
    activePlans,
    getApplicablePlan: useCallback(
      (
        targetFeatures: Partial<
          Pick<
            FeatureSet,
            "minSyncIntervalInMinutes" | "limitConnectors" | "limitUsers"
          >
        >,
      ) => {
        if (targetFeatures.minSyncIntervalInMinutes) {
          const addOns = planAddOns.getByType(WeldAddOnType.SyncFrequency);
          return featureTests.minSyncIntervalInMinutes(
            targetFeatures.minSyncIntervalInMinutes,
            activePlans,
            addOns,
          );
        }
        if (targetFeatures.limitConnectors) {
          return featureTests.limitConnectors(
            targetFeatures.limitConnectors,
            activePlans,
            [],
          );
        }

        return null;
      },
      [activePlans, planAddOns],
    ),
  };
}

type TargetPlan = {
  plan: WeldPlan;
  addOn: Product | null;
};

type FeatureTests = {
  [K in keyof Pick<
    FeatureSet,
    "minSyncIntervalInMinutes" | "limitConnectors" | "limitUsers"
  >]: (
    arg: FeatureSet[K],
    plans: WeldPlan[],
    addOns: Product[],
  ) => TargetPlan | null;
};

const getApplicablePlanForSyncFrequency: FeatureTests["minSyncIntervalInMinutes"] =
  (value, plans, planAddOns) => {
    let applicableCombo: TargetPlan | null = null;

    // Which plan would allow this option?
    const targetPlan = maxBy(
      plans.filter((plan) => plan.features.minSyncIntervalInMinutes <= value),
      (plan) => plan.features.minSyncIntervalInMinutes,
    );

    if (targetPlan) {
      applicableCombo = {
        plan: targetPlan,
        addOn: null,
      };
    }

    const EssentialPlan = plans.find((x) => x.name === PlanName.Essential);
    if (targetPlan?.name !== PlanName.Essential && EssentialPlan != null) {
      // Which add-on on the Essential plan would allow this option?
      const targetAddOn = maxBy(
        planAddOns.filter(
          (x) =>
            x.features.minSyncIntervalInMinutes != null &&
            x.features.minSyncIntervalInMinutes <= value,
        ),
        (x) => x.features.minSyncIntervalInMinutes,
      );
      if (targetAddOn) {
        applicableCombo = {
          plan: EssentialPlan,
          addOn: targetAddOn,
        };
      }
    }
    return applicableCombo;
  };

const getApplicablePlanForConnectorsLimit: FeatureTests["limitConnectors"] = (
  value,
  plans,
) => {
  // Which plan would allow this option?
  let targetPlan = minBy(
    plans.filter((plan) => plan.features.limitConnectors.value >= value.value),
    (plan) => plan.features.limitConnectors.value,
  );

  if (!targetPlan) {
    targetPlan = plans.find((x) => x.features.limitConnectors.value === -1);
  }

  if (targetPlan) {
    return {
      plan: targetPlan,
      addOn: null,
    };
  }
  return null;
};

const getApplicablePlanForUsersLimit: FeatureTests["limitUsers"] = (
  value,
  plans,
) => {
  // Which plan would allow this option?
  let targetPlan = minBy(
    plans.filter((plan) => plan.features.limitUsers.value >= value.value),
    (plan) => plan.features.limitUsers.value,
  );

  if (!targetPlan) {
    targetPlan = plans.find((x) => x.features.limitUsers.value === -1);
  }

  if (targetPlan) {
    return {
      plan: targetPlan,
      addOn: null,
    };
  }
  return null;
};

const featureTests: FeatureTests = {
  minSyncIntervalInMinutes: getApplicablePlanForSyncFrequency,
  limitConnectors: getApplicablePlanForConnectorsLimit,
  limitUsers: getApplicablePlanForUsersLimit,
};
