import { LazyQueryHookOptions, QueryResult } from "@apollo/client";
import { CurrentUserWithAccountsDocument } from "@/apollo/types";
import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import { useCallback, useState } from "react";

type CallbackOptions = {
  onStatusCheckCompleted: () => void;
  onStatusCheckError: (error: Error) => void;
};

type UsePollDWConnectionStatusOptions<TData> = {
  getStatus: (
    options?: Partial<LazyQueryHookOptions<TData, any>>,
  ) => Promise<QueryResult<TData, any>>;
  selectStatus: (data: TData) => {
    status: string;
    error?: string | null;
  } | null;
};

/**
 * Poll the connection status of a managed data warehouse
 */
export function usePollDWConnectionStatus<TData>(
  options: UsePollDWConnectionStatusOptions<TData>,
) {
  const optionsRef = useLatestValueRef(options);

  const [isConnectionEstablished, setIsConnectionEstablished] = useState(false);

  const startPeriodicStatusCheck = useCallback(
    async ({
      onStatusCheckCompleted,
      onStatusCheckError,
      ...options
    }: Parameters<UsePollDWConnectionStatusOptions<TData>["getStatus"]>[0] &
      CallbackOptions) => {
      const { client, stopPolling } = await optionsRef.current.getStatus({
        ...options,
        pollInterval: 2000,
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        onCompleted: async (res) => {
          const result = optionsRef.current.selectStatus(res);
          if (result == null) {
            return;
          }
          if (result.status === "done") {
            try {
              stopPolling();
            } catch {}
            await client.refetchQueries({
              include: [CurrentUserWithAccountsDocument],
            });
            setIsConnectionEstablished(true);
            onStatusCheckCompleted();
          }
          if (result.status === "error") {
            try {
              stopPolling();
            } catch {}
            setIsConnectionEstablished(false);
            const error = new Error(
              result.error ?? "The connection to the data warehouse failed",
            );
            onStatusCheckError(error);
          }
        },
      });
    },
    [optionsRef],
  );

  return {
    startPeriodicStatusCheck,
    isConnectionEstablished,
  };
}
