import { useMemo } from "react";

import {
  AvailableIntegrationFragment,
  useAvailableIntegrationsQuery,
} from "@/apollo/types";
import {
  IntegrationType,
  hasAbility,
  useIntegrationFlagHelper,
  useIntegrations,
} from "@/integrations";

export function useFilterByDataWarehouseSupport(
  dataWarehouseIntegration: IntegrationType | null,
  integrations: IntegrationType[],
) {
  const dataWarehouseHasExtractAbility = hasAbility(
    dataWarehouseIntegration,
    "EltDestinationConnection",
  );
  const dataWarehouseHasActivateAbility = hasAbility(
    dataWarehouseIntegration,
    "ReverseEltSourceConnection",
  );

  const dataWarehouseHasBothAbilities =
    dataWarehouseHasExtractAbility && dataWarehouseHasActivateAbility;

  return useMemo(() => {
    if (dataWarehouseIntegration == null) {
      return [];
    }
    return integrations
      .filter(
        (i) =>
          hasAbility(i, "EltSourceConnection") ||
          hasAbility(i, "ReverseEltDestinationConnection"),
      )
      .filter((i) => {
        if (dataWarehouseHasBothAbilities) {
          return true;
        }
        if (dataWarehouseHasExtractAbility) {
          return hasAbility(i, "EltSourceConnection");
        }
        if (dataWarehouseHasActivateAbility) {
          return hasAbility(i, "ReverseEltDestinationConnection");
        }
        return false;
      });
  }, [
    integrations,
    dataWarehouseIntegration,
    dataWarehouseHasBothAbilities,
    dataWarehouseHasExtractAbility,
    dataWarehouseHasActivateAbility,
  ]);
}

function useFilterByFeatureFlag(integrations: IntegrationType[]) {
  const integrationFlagHelper = useIntegrationFlagHelper();
  return useMemo(() => {
    return integrations.filter((i) => {
      const eltFeature = integrationFlagHelper.checkFlag(
        i.id,
        "EltSourceConnection",
      );
      const reverseEltFeature = integrationFlagHelper.checkFlag(
        i.id,
        "ReverseEltDestinationConnection",
      );
      return eltFeature !== "hide" || reverseEltFeature !== "hide";
    });
  }, [integrations, integrationFlagHelper]);
}

export function useAvailableIntegrations(
  dataWarehouseIntegration: IntegrationType | null,
) {
  const integrations = useIntegrations();
  const supportedIntegrations = useFilterByDataWarehouseSupport(
    dataWarehouseIntegration,
    integrations,
  );
  const enabledIntegrations = useFilterByFeatureFlag(supportedIntegrations);

  const { data } = useAvailableIntegrationsQuery();
  const integrationMetadata = data?.availableIntegrations;

  return useMemo(() => {
    if (!integrationMetadata || enabledIntegrations.length === 0) {
      return enabledIntegrations;
    }

    const integrationMetadataMap = integrationMetadata.reduce(
      (acc, integration) => {
        acc[integration.id] = integration;
        return acc;
      },
      {} as Record<string, AvailableIntegrationFragment>,
    );

    return enabledIntegrations.map((integration) => {
      return {
        ...integration,
        metadata: integrationMetadataMap[integration.id],
      };
    });
  }, [enabledIntegrations, integrationMetadata]);
}
