import {
  RadioGroup,
  RadioGroupProps,
  RadioOptionProps,
} from "@headlessui/react";
import classNames from "@/helpers/classNames";
import { Ref, forwardRef } from "react";

import { CronOptionBase, useSyncScheduleContext } from "../useSyncSchedule";

export function ScheduleRadioGroup<T extends CronOptionBase = CronOptionBase>(
  props: Omit<RadioGroupProps<"div", T>, "children"> & {
    children: React.ReactNode;
  },
) {
  const { selectedOption, onChange, mode } = useSyncScheduleContext<T>();

  return (
    <RadioGroup
      className={classNames(props.className, mode === "custom" && "hidden")}
      value={selectedOption}
      onChange={(option: T) => {
        if (option == null || option.disabled) {
          return;
        }
        onChange(option);
      }}
    >
      <RadioGroup.Label className="sr-only">Schedule options</RadioGroup.Label>
      {props.children}
    </RadioGroup>
  );
}

function ScheduleRadioOptionFn<T extends CronOptionBase = CronOptionBase>(
  props: RadioOptionProps<"div", T>,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <RadioGroup.Option
      ref={ref}
      value={props.value}
      className={({ checked }) =>
        classNames(
          "relative cursor-pointer overflow-hidden rounded-sm border border-gray-300 px-5 py-4 focus:outline-none dark:border-gray-700",
          props.disabled && "text-gray-400 dark:text-gray-500",
          checked
            ? "bg-blue-50 ring-2 ring-blue-600 dark:bg-blue-400/10"
            : "hover:bg-gray-50 dark:hover:bg-blue-400/10",
        )
      }
    >
      {props.children}
    </RadioGroup.Option>
  );
}

export const ScheduleRadioOption = forwardRef(ScheduleRadioOptionFn) as <
  T extends CronOptionBase = CronOptionBase,
>(
  props: RadioOptionProps<"div", T> & { ref?: Ref<HTMLDivElement> },
) => ReturnType<typeof ScheduleRadioOptionFn>;
