import React from "react";

export const BlueOneEye1 = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 100 100"
    >
      <ellipse cx="50.5" cy="50" fill="#99C5FA" rx="50.5" ry="50"></ellipse>
      <mask
        id="mask0_1928_11295"
        style={{ maskType: "alpha" }}
        width="100"
        height="100"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect width="100" height="100" fill="#C4C4C4" rx="2"></rect>
      </mask>
      <g mask="url(#mask0_1928_11295)">
        <ellipse
          cx="55.724"
          cy="55.171"
          fill="#0070F4"
          rx="104.483"
          ry="82.759"
        ></ellipse>
        <ellipse
          cx="47.529"
          cy="50"
          fill="#0059C3"
          rx="77.235"
          ry="73.529"
          transform="rotate(-180 47.53 50)"
        ></ellipse>
        <path
          fill="#0070F4"
          d="M49.775 20.076a1.396 1.396 0 011.842.002c54.526 47.907 54.526 132.83 0 180.737a1.397 1.397 0 01-1.842.001c-54.664-47.847-54.664-132.893 0-180.74z"
        ></path>
        <ellipse
          fill="#fff"
          rx="21.738"
          ry="21.523"
          transform="rotate(-179.611 25.912 36.947) skewX(.051)"
        ></ellipse>
        <ellipse
          fill="#fff"
          rx="3.538"
          ry="3.503"
          transform="rotate(-179.611 25.293 5.174) skewX(.051)"
        ></ellipse>
        <ellipse
          fill="#000"
          rx="11.244"
          ry="11.133"
          transform="rotate(-179.611 25.912 36.946) skewX(.051)"
        ></ellipse>
      </g>
    </svg>
  );
};
