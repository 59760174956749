import { ComponentProps, deriveClassed } from "@tw-classed/react";
import cn from "@/helpers/classNames";
import { forwardRef } from "react";

import { Button, ButtonPrimitive } from "./Button";

export const PrimaryButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>((props, ref) => {
  return <Button ref={ref} variant="solid" colorScheme="primary" {...props} />;
});

export const SecondaryButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>((props, ref) => {
  return (
    <Button ref={ref} variant="outline" colorScheme="secondary" {...props} />
  );
});

export const DangerButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>((props, ref) => {
  return <Button ref={ref} variant="solid" colorScheme="danger" {...props} />;
});

export const UtilityButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>((props, ref) => {
  return (
    <Button
      ref={ref}
      variant="link"
      colorScheme="secondary"
      size="sm"
      {...props}
    />
  );
});

export const ActionButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>((props, ref) => {
  return (
    <Button
      ref={ref}
      variant="solid"
      colorScheme="primary"
      size="lg"
      style={{
        minWidth: "12rem",
      }}
      {...props}
    />
  );
});

export const TabButton = deriveClassed<
  typeof ButtonPrimitive,
  ComponentProps<typeof ButtonPrimitive> & {
    active: boolean;
    underlineActive?: boolean;
  }
>(({ active, underlineActive = false, children, ...props }, ref) => {
  return (
    <ButtonPrimitive
      ref={ref}
      {...props}
      className={cn(
        "relative flex items-center gap-1",
        "truncate rounded-sm px-1 text-sm focus:outline-none",
        {
          "text-blue-500 hover:text-blue-600": active,
          "text-gray-600 hover:text-gray-700 dark:text-gray-200 dark:hover:text-gray-100":
            !active,
        },
        props.className,
      )}
    >
      {children}
      {underlineActive && active && (
        <div className="absolute bottom-0 left-0 w-full border-b border-primary" />
      )}
    </ButtonPrimitive>
  );
});
