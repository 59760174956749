import { ComponentProps, useMemo } from "react";
import { Column, useTable } from "react-table";

import { classed } from "@/components/classed.config";
import { formatRowCount } from "@/shared/formatters";

const Td = classed.td(
  "text-sm border-b border-gray-100 dark:border-gray-700 px-3 py-1 text-muted-foreground",
);

const Th = classed.th(
  "text-sm font-normal bg-gray-100 dark:bg-gray-700 border-b dark:border-gray-600 px-2 py-0.5 text-muted-foreground text-left",
);

export type StatsTableRow = {
  numRowsSynced?: number;
  numBillableRows?: number;
  color?: string;
  label: string;
};

function useStatsTableColumns(props: ComponentProps<typeof StatsTable>) {
  const { showBillableRows = true, showTotalRows = true } = props;
  return useMemo<Column<StatsTableRow>[]>(() => {
    const showHeaders = showBillableRows && showTotalRows;
    const columns: Column<StatsTableRow>[] = [
      {
        Header: "",
        accessor: "label",
        Cell: ({ value, row }) => {
          return (
            <div className="flex items-center gap-2">
              {row.original.color && (
                <div
                  className="h-3 w-3 rounded"
                  style={{ backgroundColor: row.original.color }}
                />
              )}
              {value}
            </div>
          );
        },
      },
    ];
    if (showBillableRows !== false) {
      columns.push({
        Header: showHeaders ? "Active rows" : "",
        accessor: "numBillableRows",
        Cell: ({ value }) => {
          return (
            <div className="min-w-[5rem] text-right font-semibold">
              {value != null && formatRowCount(value)}
            </div>
          );
        },
      });
    }
    if (showTotalRows !== false) {
      columns.push({
        Header: showHeaders ? "Total rows" : "",
        accessor: "numRowsSynced",
        Cell: ({ value }) => {
          return (
            <div className="min-w-[5rem] text-right font-semibold">
              {value != null && formatRowCount(value)}
            </div>
          );
        },
      });
    }
    return columns;
  }, [showBillableRows, showTotalRows]);
}

export function StatsTable(props: {
  data: {
    numRowsSynced?: number;
    numBillableRows?: number;
    color?: string;
    label: string;
  }[];
  showBillableRows?: boolean;
  showTotalRows?: boolean;
}) {
  const { showBillableRows = true, showTotalRows = true } = props;
  const showHeaders = showBillableRows && showTotalRows;
  const columns = useStatsTableColumns(props);
  const { rows, prepareRow, getTableBodyProps, getTableProps, headerGroups } =
    useTable({
      columns,
      data: props.data,
    });
  return (
    <table {...getTableProps()} className="border-t dark:border-gray-600">
      {showHeaders && (
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
              ))}
            </tr>
          ))}
        </thead>
      )}
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
