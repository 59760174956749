import { useModelDisplayName } from "@/pages/ModelTool/hooks/useFullPathNames";

export const ModelName = (props: { modelId: string }) => {
  const { modelName, pathName } = useModelDisplayName(props.modelId);
  return (
    <div className="flex flex-col">
      {pathName && (
        <div className="text-xs text-gray-600 dark:text-gray-400">
          {pathName}
        </div>
      )}
      <div>{modelName}</div>
    </div>
  );
};
