import { ComponentProps } from "react";

import { BookMeetingButton } from "../BookMeetingButton";

export function SyncsBlockedBookMeetingButton(
  props: ComponentProps<typeof BookMeetingButton>,
) {
  return (
    <BookMeetingButton
      {...props}
      message="Hi, I'd like to book a call to discuss unblocking syncs for my account."
    />
  );
}
