import { ListModelsQuery, MaterializationType } from "@/apollo/types";
import dayjs from "dayjs";

type Model = ListModelsQuery["models"][0];

export function isDraftModel(model: Pick<Model, "publishedQuery">) {
  return model.publishedQuery === null;
}

export function isPendingTable(
  model: Pick<Model, "materializationType" | "publishedQuery" | "dwTable">,
) {
  return (
    model.materializationType === MaterializationType.Table &&
    !isDraftModel(model) &&
    !model.dwTable?.latestSyncTime
  );
}

export function getModelDisplayName(model: Model) {
  return model
    ? model.name || dayjs(model.createdAt).format("YYYY/MM/DD HH:mm")
    : "";
}
