import { useMixpanel } from "@/monitoring/mixpanel";
import React from "react";

import Guides from "../guides";
import { useStonlyWidget } from "./useStonlyWidget";
import { createStorageKey } from "@/utils/storage";

export function useTrackStonlyEvents() {
  const StonlyWidget = useStonlyWidget();
  const mixpanel = useMixpanel();

  React.useEffect(() => {
    function onClose(e: any) {
      const guide = Guides.get(e.guideId);

      mixpanel.track("Product Tour Ended", {
        close_reason: e.closeReason,
        guide_id: e.guideId,
        guide_name: guide?.name,
        step_id: e.stepId || undefined,
        step_name: guide?.stepsMap[e.stepId],
      });

      setGuideViewed(e.guideId);
    }

    function onGuidedTourStart(e: any) {
      const guide = Guides.get(e.guideId);

      mixpanel.track("Product Tour Started", {
        guide_id: e.guideId,
        guide_name: guide?.name,
      });
    }

    StonlyWidget("addEventListener", "close", onClose);
    StonlyWidget("addEventListener", "guidedTourStart", onGuidedTourStart);
    return () => {
      StonlyWidget("removeEventListener", "close", onClose);
      StonlyWidget("removeEventListener", "guidedTourStart", onGuidedTourStart);
    };
  }, [StonlyWidget, mixpanel]);
}

//Track when a user closes a Stonly tour.
const createStonlyViewedStorageKey = (guideId: string) => {
  return createStorageKey("stonly-viewed", guideId);
};

const setGuideViewed = (guideId: string) => {
  localStorage.setItem(createStonlyViewedStorageKey(guideId), "true");
};

export const isGuideViewed = (guideId: string) => {
  return localStorage.getItem(createStonlyViewedStorageKey(guideId)) === "true";
};
