import { useListObjectTypesQuery } from "@/apollo/types";
import { useSyncContext } from "../modules/SyncContext";
import useChainedObject from "@/hooks/useChainedObject";

export default function useObjectTypes() {
  const [state] = useSyncContext();
  const { loading: objectTypesLoading, data: { objectTypes = [] } = {} } =
    useListObjectTypesQuery({
      variables: { connectionId: state.destinationId },
      skip: !state.destinationId,
    });

  const { currentObject } = useChainedObject(
    objectTypes,
    "objectName",
    state.objectTypeName,
  );

  return { objectTypes, objectTypesLoading, currentObjectType: currentObject };
}
