import { Switch, SwitchProps } from "@headlessui/react";
import cn from "@/helpers/classNames";
import { ComponentProps } from "react";

const OffIcon = (props: ComponentProps<"svg">) => (
  <svg {...props} fill="currentColor" viewBox="0 0 12 12">
    <path
      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const OnIcon = (props: ComponentProps<"svg">) => (
  <svg {...props} fill="currentColor" viewBox="0 0 12 12">
    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
  </svg>
);

type Size = "sm" | "md" | "lg";

const containerSizeCls: Record<Size, string> = {
  sm: "h-4 w-7",
  md: "h-6 w-11",
  lg: "h-8 w-14",
};

const toggleSizeCls: Record<Size, string> = {
  sm: "h-3 w-3",
  md: "h-5 w-5",
  lg: "h-7 w-7",
};

const translateCls: Record<Size, string> = {
  sm: "translate-x-3",
  md: "translate-x-5",
  lg: "translate-x-6",
};

export default function Toggle({
  checked,
  className,
  onChange,
  size = "md",
  id,
}: SwitchProps<"button"> & {
  size?: Size;
}) {
  const Icon = checked ? OnIcon : OffIcon;
  return (
    <Switch
      id={id}
      checked={checked}
      onChange={onChange}
      type="button"
      className={cn(
        "relative inline-flex shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
        {
          "bg-blue-600": checked,
          "bg-gray-200 dark:bg-gray-700": !checked,
        },
        containerSizeCls[size],
        className,
      )}
    >
      <span className="sr-only">Use setting</span>

      <span
        className={cn(
          "pointer-events-none relative flex transform items-center justify-center rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
          toggleSizeCls[size],
          checked ? translateCls[size] : "translate-x-0",
        )}
        aria-hidden="true"
      >
        <Icon
          className={cn("w-3/5 text-gray-400", {
            "text-blue-600": checked,
          })}
        />
      </span>
    </Switch>
  );
}
