import { useMemo } from "react";

import { useIntegrationsMap } from "@/integrations";

import { ConnectorOption } from "./useCreateConnectorOptions";

const communityConnectors = [
  ["Tapfiliate", "tapfiliate"],
  ["Hair Tools", "hairtools"],
  ["Harvest", "harvest"],
  ["HeapsGo", "heapsgo"],
  ["Pipedrive", "pipedrive"],
  ["Recharge", "recharge"],
  ["Rotaready", "rotaready"],
  ["SevenRooms", "sevenrooms"],
];

export function useCommunityCustomConnectorOptions(): ConnectorOption[] {
  const registry = useIntegrationsMap();
  const customIntegration = registry.get("custom-integration");
  return useMemo(() => {
    if (customIntegration == null) {
      return [];
    }
    return communityConnectors.map(([name, id]) => {
      return {
        label: name,
        value: id,
        integration: {
          ...customIntegration,
          name: name,
        },
        abilities: ["EltSourceConnection"],
        isCommunity: true,
        isBeta: false,
        isPreview: false,
      };
    });
  }, [customIntegration]);
}
