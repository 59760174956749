import { useToast } from "@/providers/ToastProvider";
import { useMemo } from "react";
import { useCallback } from "react";
import { useSyncContext } from "./SyncContext";
import useDestinationProperties from "../hooks/useDestinationProperties";
import useSourceProperties from "../hooks/useSourceProperties";
import { levenshtein } from "@/helpers/levenshtein";
export default function useAutoMapping() {
  const [state, dispatch] = useSyncContext();

  const toast = useToast();

  const { destinationProperties, destinationPropertiesObject } =
    useDestinationProperties();

  const { sourceProperties } = useSourceProperties();

  const unmappedSourceProperties = useMemo(
    () =>
      sourceProperties.filter(
        (sp) =>
          !state.mapping.some((m) => m.sourcePropertyId === sp.propertyId),
      ),
    [sourceProperties, state.mapping],
  );

  const autoMapablePairs = useMemo(() => {
    const trim = (str: string) => str.toLowerCase().replace(" ", "");

    type MapablePair = {
      sourceProperty: any;
      destinationProperty: any;
    };

    const mapablePairs = destinationProperties.reduce(
      (prev, destinationProperty, index) => {
        if (destinationProperty.automapMatchAny) {
          const source = unmappedSourceProperties[index];
          if (source) {
            prev.push({ sourceProperty: source, destinationProperty });
          }
        } else {
          const source = unmappedSourceProperties.find((sourceProperty) => {
            return (
              levenshtein(
                trim(sourceProperty.propertyName),
                trim(destinationProperty.propertyName),
              ) < 3
            );
          });
          if (source) {
            prev.push({ sourceProperty: source, destinationProperty });
          }
        }
        return prev;
      },
      [] as MapablePair[],
    );

    const onlyUnused = mapablePairs.filter(
      ({ destinationProperty }) =>
        !state.mapping.some(
          (item) =>
            item.destinationPropertyId === destinationProperty.propertyId &&
            item.sourcePropertyId,
        ),
    );
    return onlyUnused;
  }, [destinationProperties, unmappedSourceProperties, state.mapping]);

  const handleAutoMap = useCallback(() => {
    if (!autoMapablePairs.length) return;
    autoMapablePairs.forEach(({ sourceProperty, destinationProperty }) => {
      const mappingId = state.mapping.find(
        ({ destinationPropertyId }) =>
          destinationPropertyId === destinationProperty.propertyId,
      )?.uuid;

      if (mappingId) {
        // already in list
        dispatch({
          type: "change_mapping_source_property",
          payload: { propertyId: sourceProperty.propertyId, uuid: mappingId },
        });
      } else {
        // add to list
        dispatch({
          type: "add_mapping_row",
          payload: {
            sourcePropertyId: sourceProperty.propertyId,
            destinationPropertyId: destinationProperty.propertyId,
          },
        });
      }
    });
    toast(
      "Auto mapped",
      "Mapped all properties that could be automatically mapped",
      "success",
    );
  }, [autoMapablePairs, toast, state.mapping, dispatch]);

  const handleRemoveIncompleteMappings = useCallback(() => {
    //remove all rows without source properties - that are not primary or with required DP
    state.mapping
      .filter(
        ({ sourcePropertyId, isPrimaryMapping, destinationPropertyId }) => {
          const destinationProperyIsRequired =
            destinationPropertyId &&
            destinationPropertiesObject[destinationPropertyId]?.isRequired;
          const hasSourceProperty = !!sourcePropertyId;
          if (
            isPrimaryMapping ||
            destinationProperyIsRequired ||
            hasSourceProperty
          )
            return false;
          else return true;
        },
      )
      .forEach((item) => {
        dispatch({ type: "remove_mapping_row", payload: item.uuid });
      });
  }, [destinationPropertiesObject, dispatch, state.mapping]);

  const handleAddRest = useCallback(() => {
    handleRemoveIncompleteMappings();

    unmappedSourceProperties.forEach((sourceProperty) => {
      dispatch({
        type: "add_mapping_row",
        payload: {
          sourcePropertyId: sourceProperty.propertyId,
          destinationPropertyId: undefined,
        },
      });
    }, []);
  }, [handleRemoveIncompleteMappings, unmappedSourceProperties, dispatch]);

  return { handleAddRest, handleAutoMap, autoMapablePairs };
}
