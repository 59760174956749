import { forwardRef } from "react";

import FieldLabel from "@/components/primitives/InputLabel";
import { Input } from "@/components/primitives/input";
import cn from "@/helpers/classNames";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { ComponentProps } from "@tw-classed/react";

export function StripeFieldLabel(props: ComponentProps<typeof FieldLabel>) {
  return (
    <FieldLabel
      {...props}
      className={cn(
        "mb-1 text-sm font-normal text-gray-800 dark:text-white",
        props.className,
      )}
    />
  );
}

export const StripeInput = forwardRef<
  HTMLInputElement,
  ComponentProps<typeof Input>
>((props, ref) => {
  return (
    <Input
      ref={ref}
      {...props}
      className={cn(
        "h-11 rounded border-gray-200 shadow-sm dark:border-[#515255] dark:bg-gray-700 dark:shadow-[0px_2px_4px_rgba(0,0,0,0.5),0px_1px_6px_rgba(0,0,0,0.25)]",
        "focus:border-[hsla(221,83%,53%,50%)] focus:shadow-[0px_1px_1px_rgba(0,0,0,0.03),0px_3px_6px_rgba(0,0,0,0.02),0_0_0_3px_hsla(210,96%,45%,25%),0_1px_1px_0_rgba(0,0,0,0.08)] focus:outline-0 focus:ring-0 dark:focus:border-[hsla(221,83%,53%,50%)] dark:focus:shadow-[0px_1px_1px_rgba(0,0,0,0.03),0px_3px_6px_rgba(0,0,0,0.02),0_0_0_3px_hsla(210,96%,45%,25%),0_1px_1px_0_rgba(0,0,0,0.08)]",
        props.className,
      )}
    />
  );
});

export const StripeSelect = forwardRef<
  HTMLSelectElement,
  React.SelectHTMLAttributes<HTMLSelectElement>
>((props, ref) => {
  return (
    <div className="relative h-11">
      <select
        ref={ref}
        {...props}
        className={cn(
          "block w-full cursor-pointer appearance-none p-3 pr-7 text-sm",
          "h-11 rounded border border-gray-200 shadow-sm dark:border-[#515255] dark:bg-gray-700 dark:shadow-[0px_2px_4px_rgba(0,0,0,0.5),0px_1px_6px_rgba(0,0,0,0.25)]",
          "focus:border-[hsla(221,83%,53%,50%)] focus:shadow-[0px_1px_1px_rgba(0,0,0,0.03),0px_3px_6px_rgba(0,0,0,0.02),0_0_0_3px_hsla(210,96%,45%,25%),0_1px_1px_0_rgba(0,0,0,0.08)] focus:outline-0 focus:ring-0 dark:focus:border-[hsla(221,83%,53%,50%)] dark:focus:shadow-[0px_1px_1px_rgba(0,0,0,0.03),0px_3px_6px_rgba(0,0,0,0.02),0_0_0_3px_hsla(210,96%,45%,25%),0_1px_1px_0_rgba(0,0,0,0.08)]",
          props.className,
        )}
      />
      <div className="pointer-events-none absolute right-0 top-0 flex h-full w-9 items-center justify-center">
        <ChevronDownIcon className="h-6 w-6 opacity-70" />
      </div>
    </div>
  );
});
