import {
  ListModelsQuery,
  MaterialisationStatus,
  MaterializationType,
  OrchestrationSchedulerType,
} from "@/apollo/types";
import { CronDisplayReadable } from "@/components/elements/CronDisplayReadable";
import DefaultTable from "@/components/elements/DefaultTable";
import { LoadingFull } from "@/components/elements/LoadingComponents";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
} from "@/components/elements/Modal";
import TableIcon from "@/components/icons/TableIcon";
import MainPageLayout from "@/components/layouts/MainPageLayout";
import classNames from "@/helpers/classNames";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import { HumanizedTimeDiff } from "@/pages/ModelTool/materialization/TableMaterilizationInfo";
import { ModelViewInfo } from "@/pages/ModelTool/ModelView";
import { useMemo } from "react";
import { CellProps, Column } from "react-table";

import { getMaterializationStatusColor } from "./components/utility/getJobStatusColor";
import { MaterializationStatusDisplay } from "./components/WorkflowJobStatusDisplay";
import { MaterializationStatusIcon } from "./components/WorkflowTable/ItemStatusIcon";
import { ModelName } from "./components/WorkflowTable/ModelName";

type Row = ListModelsQuery["models"][0];

const columns: Column<Row>[] = [
  {
    Header: "Models",
    Cell: ({ row }: CellProps<ListModelsQuery["models"][0]>) => {
      return (
        <div className="flex items-center space-x-4">
          <div
            className={classNames(
              "flex h-10 w-10 items-center justify-center rounded bg-gray-100 dark:bg-gray-700",
            )}
          >
            <TableIcon
              className={classNames(
                "h-4 w-4 flex-none text-white",
                getMaterializationStatusColor(
                  row.original.dwTable?.status ?? MaterialisationStatus.Failed,
                  "text",
                ),
              )}
            />
          </div>
          <ModelName modelId={row.original.id} />
        </div>
      );
    },
    width: "70%",
  },
  {
    Header: "Scheduled",
    Cell: ({ row }: CellProps<Row>) => (
      <div className="">
        <div className="font-medium">
          {row.original.localMaterializationJob?.cronExpression ? (
            <CronDisplayReadable
              capitalize
              className="font-bold"
              cron={row.original.localMaterializationJob?.cronExpression}
            />
          ) : (
            "Unknown schedule"
          )}
        </div>
        {row.original.localMaterializationJob?.nextRun && (
          <div className="flex items-center space-x-2">
            <div className="font-medium">Next run</div>

            <HumanizedTimeDiff
              value={row.original.localMaterializationJob?.nextRun}
            />
          </div>
        )}
      </div>
    ),
  },
  {
    Header: "Table materialization",
    Cell: ({ row }: CellProps<Row>) => (
      <div className="flex items-center space-x-2">
        {row.original.dwTable?.status && (
          <>
            <MaterializationStatusIcon status={row.original.dwTable.status} />
            <MaterializationStatusDisplay
              materializationStatus={row.original.dwTable.status}
            />
          </>
        )}
      </div>
    ),
  },
  {
    Header: "Tests",
    Cell: ({ row }: CellProps<Row>) => {
      return (
        <div className="flex items-center">
          <div className="flex-1 font-medium opacity-50">Skipped</div>
        </div>
      );
    },
  },
];

const LocalJobs = () => {
  const { models, loadingModels } = useLocalJobsModels();
  const { isOpen, onOpen, onClose, data: modelId } = useDisclosure<string>();
  return (
    <>
      <MainPageLayout>
        <div>
          {loadingModels && <LoadingFull />}
          <DefaultTable
            onRowClick={(row) => onOpen(row.id)}
            columns={columns}
            data={models}
            empty={
              <div className="w-full space-y-4 text-center dark:text-white">
                <p className="text-lg">No local jobs</p>
                <p className="flex flex-col text-sm">
                  <span>
                    Models materialized as tables on a local schedule will
                    appear here.
                  </span>
                </p>
              </div>
            }
          />
        </div>
      </MainPageLayout>
      {modelId && (
        <Modal size="lg" isOpen={isOpen} onClose={onClose}>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ height: "70vh" }}>
              <ModelViewInfo modelId={modelId} />
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default LocalJobs;

export const useLocalJobsModels = () => {
  const { models, loadingModels } = useListModels();
  return useMemo(() => {
    return {
      models: models.filter(
        (m) =>
          !!m.publishedQuery &&
          m.materializationType === MaterializationType.Table &&
          m.orchestrationScheduler === OrchestrationSchedulerType.Local,
      ),
      loadingModels,
    };
  }, [models, loadingModels]);
};
