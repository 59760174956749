import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { LocationGenerics } from "routes";

import {
  useOpenDraftInTab,
  useOpenModelInTab,
} from "@/components/modules/ModelTabs";
import { useDraftModelGuide } from "@/features/product-guide/guides/draft-model/useDraftModelGuide";
import { useExampleModelGuide } from "@/features/product-guide/guides/example-model/useExampleModelGuide";
import { useMountEffect } from "@/hooks/useMountEffect";
import * as Sentry from "@sentry/react";
import { useSearch } from "@tanstack/react-location";

import { useEditorState } from "../EditorStore";
import {
  useGetEditorTextState,
  useModelEditorDirtyState,
  useModelEditorDispatch,
  useModelEditorState,
} from "../ModelEditorStore";
import { useCurrentModel } from "../hooks/useCurrentModel";
import { useDetectFlattenedSQL } from "../hooks/useFireMonaco";
import { useQueryHistory } from "../hooks/useQueryHistory";
import SQLEditor from "./SQLEditor";
import { HistoryDiffViewer } from "./history/HistoryDiffViewer";
import HistorySideBar from "./history/HistorySideBar";
import { useCurrentModelDraft } from "./useModelDraft";

export const ModelLiveEditor = () => {
  const state = useModelEditorState();
  const dispatch = useModelEditorDispatch();
  const [, editorStateDispatch] = useEditorState();
  const getTextState = useGetEditorTextState();
  const isModelDirty = useModelEditorDirtyState();
  //product tour guide triggers:
  useExampleModelGuide();

  const openModelInTab = useOpenModelInTab();
  const model = useCurrentModel();

  useEffect(() => {
    if (model?.id !== state.currentModelId) {
      dispatch({
        type: "initialize_model",
        payload: model,
      });
    }
  }, [model, state.currentModelId, dispatch]);

  const { dashboardId, tileId } = useSearch<LocationGenerics>();

  useEffect(() => {
    if (!model?.id) return;
    editorStateDispatch({
      type: "initialize",
      payload: {
        currentItemId: model?.id,
        currentItemName: model?.name,
        currentEditorState: "model",
        dashboardId,
        tileId,
      },
    });
  }, [dashboardId, editorStateDispatch, model?.id, model?.name, tileId]);

  useMountEffect(() => {
    if (!model?.id) return;
    openModelInTab({ modelId: model.id });
  });

  const handleSelectHistoryItem = useCallback(
    (historyItem: number) =>
      dispatch({ type: "change_selected_history_item", payload: historyItem }),
    [dispatch],
  );

  const queryHistory = useQueryHistory(model?.id);
  useEffect(() => {
    const latestHistoryValue = queryHistory[0]?.weldSql || "";
    if (latestHistoryValue !== getTextState().defaultSql) {
      dispatch({
        type: "set_initial_sql",
        payload: latestHistoryValue,
      });
    }
  }, [dispatch, queryHistory, getTextState]);

  return (
    <>
      <Helmet>
        <title>{`${model?.name} | WELD`}</title>
      </Helmet>
      <div className="relative flex h-full grow overflow-hidden">
        <div className="relative h-full w-full overflow-hidden">
          <Editor />
        </div>
        <HistorySideBar
          onSelect={handleSelectHistoryItem}
          selected={state.selectedHistoryItem}
          unsavedChanges={isModelDirty}
          history={queryHistory}
        />
      </div>
    </>
  );
};

export const DraftEditor = () => {
  const state = useModelEditorState();
  const dispatch = useModelEditorDispatch();
  const [, editorStateDispatch] = useEditorState();
  const openDraftInTab = useOpenDraftInTab();
  useDraftModelGuide();

  const { currentDraftModel } = useCurrentModelDraft();

  useEffect(() => {
    if (currentDraftModel?.id !== state.currentModelId) {
      dispatch({
        type: "initialize_draft",
        payload: currentDraftModel,
      });
    }
  }, [state.currentModelId, dispatch, currentDraftModel]);

  useEffect(() => {
    if (!currentDraftModel?.id) return;
    editorStateDispatch({
      type: "initialize",
      payload: {
        currentItemId: currentDraftModel?.id,
        currentItemName: currentDraftModel?.name,
        currentEditorState: "draft",
      },
    });
  }, [
    currentDraftModel?.id,
    currentDraftModel?.name,
    dispatch,
    editorStateDispatch,
  ]);

  useMountEffect(() => {
    if (!currentDraftModel?.id) return;
    openDraftInTab(currentDraftModel.id, currentDraftModel.number);
  });

  return (
    <>
      <Helmet>
        <title>{`${currentDraftModel?.name ?? "Unsaved draft"} | WELD`}</title>
      </Helmet>
      <div className="relative flex h-full grow overflow-hidden">
        <Editor />
      </div>
    </>
  );
};

function Editor() {
  const state = useModelEditorState();

  // This is a hack to force the editor to remount when the SQL is flattened
  // This happens in cases where the backend publishes a model.
  const [mountTime, setMountTime] = useState(new Date().getTime());
  const updateMountTime = useCallback(() => {
    setMountTime(new Date().getTime());
  }, []);
  useDetectFlattenedSQL(updateMountTime);

  return (
    <div className="absolute inset-0 overflow-y-auto">
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <SQLEditor key={`${state.currentModelId}-${mountTime}`} />
      </Sentry.ErrorBoundary>
      {state.currentModelType === "model" && <HistoryDiffViewer />}
    </div>
  );
}
