import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { useEffect, useMemo } from "react";

import { useGetViewDetailsLazyQuery } from "@/apollo/types";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import { isWindows } from "@/helpers/isWindows";
import { useToast } from "@/providers/ToastProvider";
import { useMonaco } from "@monaco-editor/react";

import { useSchemaSidebar } from "../../ModelEditorStore";
import { useListPublishedModels } from "../../hooks/useListModels";
import { useRawViews } from "../../useRawViews";
import { getWeldTagAtPosition } from "./getWeldTagAtPosition";

export function useWeldTagHoverDialog(
  editor: monaco.editor.IStandaloneCodeEditor | undefined,
  language = "sql",
) {
  const monaco = useMonaco();
  const toast = useToast();

  const { publishedModels } = useListPublishedModels();
  const { rawViews } = useRawViews();
  const [getViewDetails] = useGetViewDetailsLazyQuery();

  const [, setSchemaSidebar] = useSchemaSidebar();
  const openModelInTab = useOpenModelInTab();
  const { onOpen: viewSync } = useViewDataSourceSlideOver();

  const openSchemaSidebarCmdId = useMemo(() => {
    return editor?.addCommand(0, (_, args) => {
      setSchemaSidebar(args.id);
    });
  }, [editor, setSchemaSidebar]);

  const openModelInTabCmdId = useMemo(() => {
    return editor?.addCommand(0, (_, args) => {
      openModelInTab({ modelId: args.id });
    });
  }, [editor, openModelInTab]);

  const viewEltSyncCmdId = useMemo(() => {
    return editor?.addCommand(0, (_, args) => {
      viewSync({ syncId: args.id });
    });
  }, [editor, viewSync]);

  const copyColumnNameCmdId = useMemo(() => {
    return editor?.addCommand(0, (_, args) => {
      if (!navigator.clipboard) return;
      const value = args?.columnName;
      if (value == null) return;
      try {
        navigator.clipboard.writeText(value);
        toast("Copied column name to clipboard", undefined, "success", 1500);
      } catch {
        // nevermind
      }
    });
  }, [editor, toast]);

  useEffect(() => {
    if (monaco == null) return;

    function getReferenceInfo(weldTag: string) {
      if (weldTag.startsWith("raw.")) {
        const rawView = rawViews.find((rv) => rv.weldTag === weldTag);
        if (rawView == null) {
          return null;
        }
        return {
          type: "rawView",
          id: rawView.viewId,
          path: rawView.path,
          syncId: rawView.syncId,
        };
      }
      const model = publishedModels.find((pm) => {
        return (
          pm.dwSync?.weldTag === weldTag || pm.dwTable?.weldTag === weldTag
        );
      });
      if (model == null) {
        return null;
      }
      const dwEntity = model?.dwSync ?? model?.dwTable;
      if (dwEntity == null) {
        return null;
      }
      return {
        type: "model",
        materializationType: model.materializationType,
        id: dwEntity.modelId,
        path: dwEntity.path,
      };
    }

    const disposable = monaco.languages.registerHoverProvider(language, {
      provideHover: async (model, position) => {
        const result = getWeldTagAtPosition(model, position);
        if (result == null) {
          return null;
        }
        const { weldTag, range } = result;
        const dwReference = getReferenceInfo(weldTag);
        if (dwReference == null) {
          return null;
        }

        const { data } = await getViewDetails({
          variables: {
            input: {
              path: dwReference.path,
            },
          },
        });

        const schema = data?.getViewDetails.dataSchema ?? [];

        const contents = [
          createHeaderContent(
            weldTag,
            dwReference.type === "rawView"
              ? "Data Source"
              : dwReference.materializationType === "table"
                ? "Table"
                : "Model",
          ),
          dwReference.type === "model"
            ? createOpenInNewTabContent(dwReference.id, openModelInTabCmdId)
            : [],
          dwReference.type === "rawView" && dwReference.syncId != null
            ? createViewEltSyncContent(dwReference.syncId, viewEltSyncCmdId)
            : [],
          createViewSchemaInSidebarContent(
            dwReference.id,
            openSchemaSidebarCmdId,
          ),
          createSchemaContent(schema, copyColumnNameCmdId),
        ].flat();

        return {
          range,
          contents: contents,
        };
      },
    });
    return () => disposable.dispose();
  }, [
    monaco,
    publishedModels,
    rawViews,
    getViewDetails,
    openSchemaSidebarCmdId,
    openModelInTabCmdId,
    copyColumnNameCmdId,
    viewEltSyncCmdId,
    language,
  ]);
}

function createHeaderContent(
  weldTag: string,
  type: string | undefined,
): monaco.IMarkdownString {
  return { value: `**${weldTag}**  ${type ? `(${type})` : ""}` };
}

function createSchemaContent(
  schema: { name: string; type: string }[],
  copyCommandId: string | null | undefined,
): monaco.IMarkdownString {
  const head = `| Name | Type |`;
  const div = `| :--- | :--- |`;
  const body = schema
    .map((column) => {
      return `| [\`${truncate(
        column.name,
        40,
      )}\`](command:${copyCommandId}?${encodeURIComponent(
        JSON.stringify({
          title: "Copy Column Name",
          columnName: column.name,
        }),
      )}) | *${column.type}* |`;
    })
    .join("\n");

  return {
    value: [head, div, body].join("\n"),
    supportHtml: true,
    isTrusted: true,
  };
}

function truncate(input: string, maxLength: number) {
  if (input.length <= maxLength) return input;
  const separator = "...";
  const separatorLength = separator.length;
  const charsToShow = maxLength - separatorLength;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);
  return (
    input.substring(0, frontChars) +
    separator +
    input.substring(input.length - backChars)
  );
}

function createViewSchemaInSidebarContent(
  referenceId: string,
  commandId: string | null | undefined,
): monaco.IMarkdownString {
  return {
    value: `[View schema in sidebar](command:${commandId}?${encodeURIComponent(
      JSON.stringify({
        id: referenceId,
      }),
    )})`,
    isTrusted: true,
  };
}

function createViewEltSyncContent(
  referenceId: string,
  commandId: string | null | undefined,
): monaco.IMarkdownString {
  return {
    value: `[View sync](command:${commandId}?${encodeURIComponent(
      JSON.stringify({
        id: referenceId,
      }),
    )})`,
    isTrusted: true,
  };
}

function createOpenInNewTabContent(
  referenceId: string,
  commandId: string | null | undefined,
): monaco.IMarkdownString {
  return {
    value: `[Open model in new tab](command:${commandId}?${encodeURIComponent(
      JSON.stringify({
        id: referenceId,
      }),
    )}) (${isWindows() ? "ctrl + click" : "cmd + click"})`,
    isTrusted: true,
  };
}
