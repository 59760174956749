import { useRef } from "react";

import { Button } from "@/components/elements/Button";
import { Checkbox } from "@/components/primitives/Checkbox";
import FieldLabel from "@/components/primitives/InputLabel";
import { Input } from "@/components/primitives/input";
import { useToast } from "@/providers/ToastProvider";

import { FormValues, useRequestConnectorForm } from "./useRequestConnector";

export function RequestConnectorForm(props: {
  defaultValues?: Partial<FormValues>;
}) {
  const toast = useToast();

  const inputRef = useRef<HTMLInputElement>(null);
  const { methods, submit } = useRequestConnectorForm({
    defaultValues: props.defaultValues,
    onSubmitted: (values, status) => {
      if (status === "duplicate") {
        toast(`${values.integrationName} already requested`, "", "info");
      } else {
        toast(`${values.integrationName} requested`, ``, "success");
      }
      inputRef.current?.focus();
    },
  });
  return (
    <form onSubmit={methods.handleSubmit(submit)} className="w-full">
      <div>
        <div className="flex flex-col gap-3">
          <div className="">
            <FieldLabel htmlFor="request-connector-name" className="leading-4">
              Name
            </FieldLabel>
            <Input
              id="request-connector-name"
              {...methods.register("integrationName", {
                required: true,
                minLength: 2,
              })}
              size="sm"
              placeholder="What is the name of the integration?"
              className="w-full"
            />
          </div>
          <div>
            <FieldLabel className="leading-4">Type</FieldLabel>
            <div className="flex gap-5 text-sm">
              <label className="flex items-center gap-2">
                <Checkbox {...methods.register("elt")} />
                <span>Data source</span>
              </label>
              <label className="flex items-center gap-2">
                <Checkbox {...methods.register("reverseEtl")} />
                <span>Reverse ETL</span>
              </label>
              <label className="flex items-center gap-2">
                <Checkbox {...methods.register("destination")} />
                <span>Destination</span>
              </label>
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            size="sm"
            type="submit"
            isDisabled={!methods.formState.isValid}
            colorScheme="primary"
            variant="solid"
          >
            Submit request
          </Button>
        </div>
      </div>
    </form>
  );
}
