import { useListTemplateConfigurationsQuery } from "@/apollo/types";
import { useMemo } from "react";

import { useTemplate } from "./TemplatesProvider";

export const useTemplateConfig = () => {
  const { data, loading } = useListTemplateConfigurationsQuery();

  const { marketingTemplate } = useTemplate();

  return useMemo(() => {
    const config = data?.listTemplateConfigurations.find(
      (templateConfig) => templateConfig.useCase === marketingTemplate?.name,
    );
    return { config, loading };
  }, [data, marketingTemplate, loading]);
};

export const useCheckMetricAvailable = (integrationId?: string) => {
  const { config, loading } = useTemplateConfig();
  return useMemo(() => {
    if (loading || !config) return false;
    return config.integrations.some(
      (integration) => integration.sourceIntegationId === integrationId,
    );
  }, [config, loading, integrationId]);
};
