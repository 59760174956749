import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { PrimaryButton, SecondaryButton } from "@/components/elements/Button";
import { BetaFeatureText } from "@/components/elements/FeatureStateText";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import FieldLabel from "@/components/primitives/InputLabel";
import { Input } from "@/components/primitives/input";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useNavigate } from "@tanstack/react-location";

import { FormType } from "../components/EditorEmptyState/EditorEmptyState";
import FolderSelecter from "../components/FolderSelecter";
import { useCreateDashboard } from "./useDashboards";

export const AddNewDashboard = (props: {
  open: boolean;
  onFinish: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  const createDashboard = useCreateDashboard();
  const navigate = useNavigate();
  const mixpanel = useMixpanel();

  const handleNewDashboard: SubmitHandler<FormType> = (values) => {
    if (loading) return;

    const folderId = values.selectedSubFolder || values.selectedBaseFolder;
    setLoading(true);

    const draftDashboard = createDashboard(values.name, folderId);

    setLoading(false);
    navigate({ to: `./dashboard/${draftDashboard.id}` });
    props.onFinish();
    mixpanel.track("Dashboard Created", {
      source: "blank model tab",
    });
  };

  const form = useForm<FormType>({
    defaultValues: {
      name: "",
    },
    mode: "onChange",
  });

  return (
    <Modal
      isOpen={props.open}
      onClose={() => {
        props.onFinish();
      }}
    >
      <ModalCloseButton />
      <ModalHeader>
        Add new dashboard <BetaFeatureText />
      </ModalHeader>
      <ModalBody className="flex flex-col gap-6">
        <div>
          <FieldLabel>Dashboard name</FieldLabel>
          <Input
            autoFocus
            autoComplete="off"
            {...form.register("name", {
              required: true,
            })}
          />
        </div>
        <div>
          <FieldLabel>Save to folder</FieldLabel>
          <FolderSelecter form={form} />
        </div>
      </ModalBody>
      <ModalFooter className="flex-row-reverse justify-start gap-4">
        <PrimaryButton
          onClick={form.handleSubmit(handleNewDashboard)}
          disabled={!form.formState.isValid}
        >
          Create dashboard
        </PrimaryButton>
        <SecondaryButton onClick={() => props.onFinish()}>
          Cancel
        </SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};
