import classNames from "@/helpers/classNames";
import React from "react";

export type TextAreaProps = React.ComponentPropsWithRef<"textarea">;

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        ref={ref}
        className={classNames(
          "form-input min-h-[5rem] w-full resize-none rounded-sm outline-0",
          "border-gray-300 invalid:border-red-500 dark:border-gray-700",
          `${
            props.readOnly || props.disabled
              ? "bg-gray-50 dark:bg-gray-800"
              : "dark:bg-gray-800"
          }`,
          "placeholder-gray-400",
          "disabled:cursor-not-allowed disabled:opacity-60",
          "focus:z-10",
          className,
        )}
        {...props}
      />
    );
  },
);

const TextAreaNamespace = Object.assign(TextArea, { id: "TextArea" });
export { TextAreaNamespace as TextArea };
