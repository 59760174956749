import { parseExpression } from "cron-parser";
import dayjs from "dayjs";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactNode, useMemo } from "react";

import { Product, WeldPlan } from "@/apollo/types";
import {
  CronOptionBase,
  RenderPropChildren,
  SyncSchedule,
} from "@/components/elements/SyncScheduler";
import { useCurrentPlan } from "@/features/subscription";

export type CronOption = CronOptionBase & {
  targetPlan?: { plan: WeldPlan; addOn: Product | null } | null;
};

export const DefaultOptions = {
  "1d": {
    label: "Every day",
    value: "0 0 * * *",
  },
  "12h": {
    label: "Every 12 hours",
    value: "0 */12 * * *",
  },
  "6h": {
    label: "Every 6 hours",
    value: "0 */6 * * *",
  },
  "3h": {
    label: "Every 3 hours",
    value: "0 */3 * * *",
  },
  "1h": {
    label: "Every hour",
    value: "0 * * * *",
  },
  "30m": {
    label: "Every 30 minutes",
    value: "*/30 * * * *",
  },
  "10m": {
    label: "Every 10 minutes",
    value: "*/10 * * * *",
  },
  "5m": {
    label: "Every 5 minutes",
    value: "*/5 * * * *",
  },
} as const;

type WeldSyncSchedulerProps = {
  cron?: string;
  onChange?: (cron: string) => void;
  children: ReactNode | RenderPropChildren<CronOption>;
};

export function WeldSyncScheduler(props: WeldSyncSchedulerProps) {
  const { features } = useCurrentPlan();
  // const { getApplicablePlan } = useWeldPlans();
  const { enableAllSyncFrequencies } = useFlags();
  const minSyncIntervalInMinutes = features.minSyncIntervalInMinutes;

  const { value, options } = useMemo(() => {
    const value =
      props.cron === undefined
        ? undefined
        : {
            value: props.cron,
            label: props.cron,
            id: props.cron,
          };

    if (enableAllSyncFrequencies) {
      return {
        value,
        options: Object.values(DefaultOptions),
      };
    }

    // Enhance options with additional data concerning current plan limits
    const referenceDate = dayjs().startOf("day").toDate();
    const options = Object.values(DefaultOptions).map((option) => {
      const result = parseExpression(option.value, {
        currentDate: referenceDate,
      });
      const nextDate = result.next();
      const diffInMinutes =
        (nextDate.getTime() - referenceDate.getTime()) / 60_000;
      const isValidOption = diffInMinutes >= minSyncIntervalInMinutes;
      if (isValidOption) {
        // This option is valid for the current plan
        return option;
      }
      // const targetPlan = getApplicablePlan({
      //   minSyncIntervalInMinutes: diffInMinutes,
      // });
      return {
        ...option,
        disabled: true,
        // targetPlan,
      };
    });
    return {
      value,
      options,
    };
  }, [
    minSyncIntervalInMinutes,
    props.cron,
    enableAllSyncFrequencies,
    // getApplicablePlan,
  ]);
  return (
    <SyncSchedule<CronOption>
      value={value}
      options={options}
      onChange={(value) => {
        props.onChange?.(value);
      }}
    >
      {typeof props.children === "function"
        ? (renderProps) => {
            if (typeof props.children === "function") {
              return props.children(renderProps);
            }
            return props.children;
          }
        : props.children}
    </SyncSchedule>
  );
}
