import { useGetCustomFormValuesLazyQuery } from "@/apollo/types";
import { AsyncSelect } from "@/components/elements/Select";
import { useToast } from "@/providers/ToastProvider";
import { useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";

export type FolderOption = {
  label: string;
  value: string;
};

export function GoogleDriveFolderInput(props: {
  inputId?: string;
  value: FolderOption | null;
  onChangeFolder: (folder: FolderOption | null) => void;
  connectionId: string;
}) {
  const toast = useToast();

  const [callCustomFormValuesQuery] = useGetCustomFormValuesLazyQuery({
    onError(error) {
      toast("Google Drive API call failed", error.message, "error");
    },
  });

  const fetchOptions = useCallback(
    async (query: string) => {
      const { data, error } = await callCustomFormValuesQuery({
        variables: {
          input: {
            connectionId: props.connectionId,
            dependencies: JSON.stringify({
              inputValue: query,
            }),
          },
        },
      });
      if (error) {
        throw error;
      }
      return data?.getCustomFormValues.customFormValues ?? [];
    },
    [callCustomFormValuesQuery, props.connectionId],
  );

  const loadOptions = useDebouncedCallback(
    (inputValue: string, callback: (options: FolderOption[]) => void) => {
      if (!inputValue) {
        callback([]);
        return;
      }
      try {
        fetchOptions(inputValue).then((results) => {
          callback(results ?? []);
        });
      } catch (error) {
        callback([]);
      }
    },
    250,
  );

  return (
    <AsyncSelect<FolderOption>
      inputId={props.inputId}
      value={props.value}
      placeholder="Start typing to search for a folder..."
      loadOptions={loadOptions}
      onChange={(option) => {
        props.onChangeFolder(option);
      }}
      autoFocus={false}
      isClearable
    />
  );
}
