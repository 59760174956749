import { LocationGenerics, getAccountContext } from "routes";

import { StripeSubscriptionDocument } from "@/apollo/types";
import { StripeProvider } from "@/features/billing";
import { backOfficeRoute } from "@/pages/BackOffice/routes";
import { connectionsRoute } from "@/pages/Connections/routes";
import { ApolloClient } from "@apollo/client";
import { Navigate, Route } from "@tanstack/react-location";

import AuditLogsPage from "./AuditLogs/AuditLogsPage";
import BIToolsPage from "./BITools/BIToolsPage";
import DeleteWorkspacePage from "./DeleteWorkspace";
import ExternalToolsPage from "./ExternalTools/ExternalToolsPage";
import InvitationsPage from "./Invitations";
import NewInvitationPage from "./NewInvitation";
import NotificationsPage from "./Notifications";
import UsersPage from "./Users";

export const settingsRoute = (
  client: ApolloClient<object>,
): Route<LocationGenerics> => {
  return {
    path: "settings",
    meta: {
      breadcrumb: () => "Settings",
    },
    children: [
      {
        path: "delete",
        element: <DeleteWorkspacePage />,
        meta: {
          breadcrumb: () => "Delete Workspace",
        },
      },
      {
        path: "bi-tools",
        element: <BIToolsPage />,
        meta: {
          breadcrumb: () => "BI Tools",
        },
      },
      {
        path: "external-tools",
        element: <ExternalToolsPage />,
        meta: {
          breadcrumb: () => "External Tools",
        },
      },
      {
        path: "invitations",
        meta: {
          breadcrumb: () => "Invitations",
        },
        children: [
          {
            path: "new",
            element: <NewInvitationPage />,
            meta: {
              breadcrumb: () => "New",
            },
          },
          { element: <InvitationsPage /> },
        ],
      },
      {
        path: "data-warehouse",
        meta: {
          breadcrumb: () => "Data Warehouse",
        },
        element: () =>
          import("./DataWarehouse/DataWarehousePage").then((m) => (
            <m.default />
          )),
      },
      {
        path: "notifications",
        meta: {
          breadcrumb: () => "Notifications",
        },
        element: <NotificationsPage />,
      },
      {
        path: "audit-logs",
        meta: {
          breadcrumb: () => "Audit Logs",
        },
        element: <AuditLogsPage />,
      },
      {
        path: "users",
        meta: {
          breadcrumb: () => "Users",
        },
        element: <UsersPage />,
      },
      {
        path: "user",
        meta: {
          breadcrumb: () => "User Settings",
        },
        element: () =>
          import("./user/UserSettingsPage").then((m) => <m.UserSettingsPage />),
      },
      {
        path: "billing",
        meta: {
          breadcrumb: () => "Billing",
        },
        loader: async (match) => {
          return (
            await client.query({
              query: StripeSubscriptionDocument,
              variables: { slug: match.params.slug },
              context: await getAccountContext(match.params.slug, client),
            })
          ).data;
        },
        children: [
          {
            path: "modify-plan",
            meta: {
              breadcrumb: () => "Modify Plan",
            },
            element: () =>
              import("./Billing/ModifyPlanPage").then((m) => <m.default />),
          },
          {
            element: () =>
              import("./Billing/BillingPage").then((m) => (
                <StripeProvider>
                  <m.default />
                </StripeProvider>
              )),
          },
        ],
      },
      connectionsRoute(client),
      backOfficeRoute,
      {
        element: <Navigate to={`./connections`} />,
      },
    ],
  };
};
