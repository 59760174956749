import { useOrchestrationWorkflowQuery } from "@/apollo/types";

export function WorkflowBreadcrumb(props: { workflowId: string }) {
  const { workflowId } = props;
  const { data, loading } = useOrchestrationWorkflowQuery({
    variables: {
      workflowId,
    },
  });

  if (loading) return null;
  if (data?.orchestrationWorkflow == null) return null;

  return <span>{data.orchestrationWorkflow.name}</span>;
}
