import { useGetCustomFormValuesQuery } from "@/apollo/types";
import { SecondaryButton } from "@/components/elements/Button";
import Select from "@/components/elements/Select";
import { SimplifiedFormContext } from "@/components/modules/ConnectionSettings";
import FieldLabel from "@/components/primitives/InputLabel";
import produce from "immer";
import { FormType } from "@/integrations";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";

import FacebookAdsCustomReport from "./FacebookAdsCustomReport";

export type FacebookAdsReportOption = {
  label: string;
  value: string;
};

export type FacebookAdsReport = {
  name: string;
  id: string;
  breakdowns: FacebookAdsReportOption[];
  action_breakdowns: FacebookAdsReportOption[];
  levels: FacebookAdsReportOption[];
  action_report_time: FacebookAdsReportOption[];
  fields: FacebookAdsReportOption[];
  view_window: FacebookAdsReportOption;
  click_window: FacebookAdsReportOption;
  aggregation: FacebookAdsReportOption;
};

const useCustomFormFieldsQuery = (connectionId: string) => {
  const { data, loading, refetch } = useGetCustomFormValuesQuery({
    variables: {
      input: {
        connectionId: connectionId,
        dependencies: "{}",
      },
    },
    onError(error) {
      return [];
    },
  });
  return { data: data?.getCustomFormValues.customFormValues, loading, refetch };
};

const getOptions = (customFieldsData: any) => {
  const mapToDisplay = (values: string[]) =>
    (values || []).map((f: string) => ({ label: f, value: f }));
  const breakdownOptions: { label: string; value: string }[] = mapToDisplay(
    customFieldsData?.breakdowns,
  );
  const actionBreakdownOptions: { label: string; value: string }[] =
    mapToDisplay(customFieldsData?.action_breakdowns);
  const fieldsOptions: { label: string; value: string }[] = mapToDisplay(
    customFieldsData?.fields,
  );
  const levelOptions: { label: string; value: string }[] = mapToDisplay(
    customFieldsData?.levels,
  );
  const actionReportTimeOptions: { label: string; value: string }[] =
    mapToDisplay(customFieldsData?.action_report_time);

  const clickWindowOptions: { label: string; value: string }[] = mapToDisplay(
    customFieldsData?.click_window,
  );
  const viewWindowOptions: { label: string; value: string }[] = mapToDisplay(
    customFieldsData?.view_window,
  );
  const aggregationOptions: { label: string; value: string }[] = mapToDisplay(
    customFieldsData?.aggregation,
  );
  return {
    breakdownOptions,
    actionBreakdownOptions,
    fieldsOptions,
    levelOptions,
    actionReportTimeOptions,
    clickWindowOptions,
    viewWindowOptions,
    aggregationOptions,
  };
};

export default function FacebookAdsForm(props: {
  field: Extract<FormType, { type: "facebook-ads-form" }>;
}) {
  const context = useFormContext();
  const [adaccount, setAdAccount] = useState<
    { label: string; value: string }[] | undefined
  >(context.getValues()["adaccount"]);

  const displaySimplified = useContext(SimplifiedFormContext);

  const accounts = props.field.accounts;

  const [reportErrors, setReportErrors] = useState<Record<string, string>>({});
  const [invalidCombinationReportIds, setInvalidCombinationReportIds] =
    useState<string[]>([]);

  const { data: customFieldsData } = useCustomFormFieldsQuery(
    context.getValues()["connectionId"],
  );

  const {
    actionBreakdownOptions,
    breakdownOptions,
    fieldsOptions,
    levelOptions,
    actionReportTimeOptions,
    viewWindowOptions,
    aggregationOptions,
    clickWindowOptions,
  } = getOptions(customFieldsData);

  const adAccountOptions: any[] = useMemo(() => {
    return accounts.map((account) => ({
      label: `${account.value} (${account.label})`,
      value: account.value,
      disabled: false,
    }));
  }, [accounts]);

  useEffect(() => {
    context.register("custom-reports", { required: false });
    context.register("adaccount", { required: true });
  }, [context]);

  const getInitialReports = () => {
    const values = context.getValues()["custom-reports"];
    if (values === "NO_REPORTS") return [];
    return values?.filter((v: any) => !!v) ?? [];
  };
  const [reports, setReports] =
    useState<FacebookAdsReport[]>(getInitialReports());

  const changeReport = (report: FacebookAdsReport) => {
    setReports((prev) =>
      produce(prev, (draft) => {
        const cur = draft.find((rep) => rep.id === report.id);
        if (cur) {
          cur.name = report.name;
          cur.breakdowns = report.breakdowns;
          cur.fields = report.fields;
          cur.action_breakdowns = report.action_breakdowns;
          cur.levels = report.levels;
          cur.action_report_time = report.action_report_time;
          cur.click_window = report.click_window;
          cur.view_window = report.view_window;
          cur.aggregation = report.aggregation;
        }
      }),
    );
  };

  const removeReport = (report: FacebookAdsReport) => {
    setReports((prev) =>
      produce(prev, (draft) => {
        draft = draft.filter((rep) => rep.id !== report.id);
        return draft;
      }),
    );
  };

  const onReportInvalid = useCallback((reportId: string) => {
    setInvalidCombinationReportIds((prev) => [...prev, reportId]);
  }, []);

  const onReportValid = useCallback((reportId: string) => {
    setInvalidCombinationReportIds((prev) =>
      prev.filter((x) => x !== reportId),
    );
  }, []);

  const setValue = context.setValue;
  const setError = context.setError;

  useEffect(() => {
    if (reports.length === 0) {
      setValue("custom-reports", [], {
        shouldValidate: false,
      });
    }
  }, [reports.length, setValue]);

  useEffect(() => {
    //If there is any report where the combination is invalid, set report and don't evaluate further
    if (invalidCombinationReportIds.length > 0) {
      setError("custom-reports", {
        message: "Validation error",
      });
      return;
    }

    //Only evaluate when all reports are valid
    const getReports = () => {
      if (!reports.length) {
        return [];
      }
      const foundReports = reports.every((r) => {
        return !!r.name && !!r.fields.length && !!r.levels;
      })
        ? reports
        : undefined;
      return foundReports;
    };

    const currentReports = getReports();
    if (currentReports && Array.isArray(currentReports)) {
      //Check if all report names are unique
      const duplicateReportNames = currentReports.filter(
        (x) => currentReports.filter((y) => y.name === x.name).length > 1,
      );

      const _reportErrors: Record<string, string> = {};
      for (const duplicateReport of duplicateReportNames) {
        _reportErrors[duplicateReport.id] = "Report names must be unique";
      }

      setReportErrors(_reportErrors);
      if (duplicateReportNames.length > 0) {
        setError("custom-reports", {
          message: "Validation error",
        });
      } else {
        setValue("custom-reports", currentReports, {
          shouldValidate: true,
        });
      }
    }
  }, [reports, setValue, setError, invalidCombinationReportIds.length]);

  useEffect(() => {
    setValue("adaccount", adaccount, {
      shouldValidate: true,
    });
  }, [adaccount, setValue]);
  return (
    <div className="space-y-8">
      <div>
        <FieldLabel id="name" required={true}>
          Ad Accounts
        </FieldLabel>
        <Select
          isMulti={true}
          isLoading={false}
          placeholder="Select one"
          onChange={(v: any) => setAdAccount(v)}
          options={adAccountOptions}
          value={adaccount}
          hideSelectedOptions={false}
        />
      </div>
      {!!reports.length && (
        <div className="text-sm">Custom reports ({reports.length}/20)</div>
      )}
      {reports.map((r) => {
        return (
          <FacebookAdsCustomReport
            report={r}
            key={r.id}
            onChangeReport={changeReport}
            onRemoveReport={removeReport}
            initialBreakdownOptions={breakdownOptions}
            initialActionBreakdownOptions={actionBreakdownOptions}
            initialFieldOptions={fieldsOptions}
            initialLevelOptions={levelOptions}
            initialActionReportTimeOptions={actionReportTimeOptions}
            initialClickOptions={clickWindowOptions}
            initialViewOptions={viewWindowOptions}
            initialAggregationOptions={aggregationOptions}
            validationError={reportErrors[r.id]}
            connectionId={context.getValues()["connectionId"]}
            adAccounts={adaccount}
            onReportInvalid={onReportInvalid}
            onReportValid={onReportValid}
          />
        );
      })}
      {!displaySimplified && (
        <SecondaryButton
          onClick={() =>
            setReports((prev) => [
              ...prev,
              {
                id: uuid(),
                action_breakdowns: [],
                fields: [],
                breakdowns: [],
                action_report_time: [
                  { label: "impression", value: "impression" },
                ],
                levels: [],
                name: "",
                click_window: { label: "7d_click", value: "7d_click" },
                view_window: { label: "1d_view", value: "1d_view" },
                aggregation: { label: "daily", value: "daily" },
              },
            ])
          }
        >
          Add custom report
        </SecondaryButton>
      )}
    </div>
  );
}
export const toValue = (option: any) => option.value;
export const FacebookAdsReportNames = [
  "account",
  "action_canvas_component",
  "action_carousel_card",
  "action_conversion_device",
  "action_product_id",
  "action_video_sound",
  "action_video_view_type",
  "ad",
  "ad_insight",
  "ad_set",
  "ad_set_insight",
  "ad_set_roas_insight",
  "all_level",
  "campaign",
  "campaign_country_insight",
  "campaign_insight",
  "creative",
  "custom_audience",
  "custom_conversion",
  "demographics_age",
  "demographics_age_and_gender",
  "demographics_country",
  "demographics_country_ad",
  "demographics_delivery_device",
  "demographics_delivery_platform",
  "demographics_delivery_platform_ad",
  "demographics_delivery_platform_and_device",
  "demographics_dma_region",
  "demographics_gender",
  "demographics_region",
  "label",
] as const;
export type FacebookAdsReportType = (typeof FacebookAdsReportNames)[number];

export const isFacebookAdsReportType = (
  s: string,
): s is FacebookAdsReportType =>
  FacebookAdsReportNames.includes(s as FacebookAdsReportType);
