import dayjs from "dayjs";
import { useMemo } from "react";
import {
  CartesianGrid,
  Customized,
  CustomizedProps,
  ReferenceArea,
  ReferenceLine,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
} from "recharts";

import { FullSyncTrigger, SyncJobStatus } from "@/apollo/types";
import PopperPortal from "@/components/modules/sync-stats-charts/PopperPortal";
import { useChartTheme } from "@/features/charts/themes";
import { useDarkMode } from "@/providers/DarkModeProvider";
import {
  formatBytes,
  formatDate,
  formatDuration,
  formatRowCount,
} from "@/shared/formatters";

import { StatsDatum } from "./types";
import { useTimelineState } from "./useTimelineState";
import { useTimelineWindow } from "./useTimelineWindow";
import {
  formatFullSyncTrigger,
  formatOperationMode,
  formatSyncStatus,
  getSyncJobColorProps,
} from "./utils";

function useTimelineChartProps(config: {
  startDate: Date;
  domain: [number, number];
  stepSize: number;
  stepAnchor: Date;
}) {
  return useMemo(() => {
    const genTicks = (start: number, end: number, stepSize: number) => {
      const ticks: number[] = [];
      for (let i = start; i <= end; i += stepSize) {
        ticks.push(i);
      }
      return ticks;
    };
    const xAxisProps: XAxisProps = {
      domain: config.domain,
      tickFormatter: (d: number) => {
        if (config.stepSize < 24 * 60 * 60 * 1000) {
          return `${dayjs(d).format("HH:mm")}`;
        }
        return `${dayjs(d).format("DD.MM")}`;
      },
      ticks: genTicks(
        config.stepAnchor.valueOf(),
        config.domain[1],
        config.stepSize,
      ),
    };
    return {
      xAxisProps,
    };
  }, [config]);
}

export function TimelineChart(props: {
  data: ReturnType<typeof useTimelineState>["data"];
  window: ReturnType<typeof useTimelineWindow>["window"];
  width?: number;
  height?: number;
  hideSourceStreamLabels?: boolean;
}) {
  const {
    data,
    window,
    width: containerWidth,
    height: containerHeight,
  } = props;

  const chart = useTimelineChartProps(window);
  const theme = useChartTheme("default");
  const { isDarkModeEnabled } = useDarkMode();

  const preparedData = useMemo(() => {
    const d: {
      sourceStreamName: string;
      data: StatsDatum[];
    }[] = (data ?? []).map((category) => {
      return {
        ...category,
        data: [
          {
            // Add a dummy point at the start of the window. this is needed when there is no data in the window for a stream
            x: window.domain[0],
            startedAt: new Date(window.domain[0]).toISOString(),
            finishedAt: new Date(window.domain[0]).toISOString(),
            rowsSynced: 0,
            rowsSyncedMb: 0,
            sourceStreamName: category.sourceStreamName,
            isDummy: true,
          } as StatsDatum,
        ].concat(
          category.data.map((stat) => ({
            ...stat,
            x: Math.max(new Date(stat.startedAt).getTime(), window.domain[0]),
          })),
        ),
      };
    });
    return d;
  }, [data, window.domain]);

  if (containerWidth == null || containerHeight == null) {
    return null;
  }
  return (
    <>
      <ScatterChart
        width={containerWidth}
        height={30 + Math.max(preparedData.length, 1) * 40}
        layout="vertical"
        margin={{ top: 0, right: 24, bottom: 0, left: 24 }}
      >
        {preparedData.map(({ sourceStreamName }, index) => (
          <ReferenceArea
            key={`category_bg_${sourceStreamName}`}
            x1={window.domain[0]}
            x2={window.domain[1]}
            y1={sourceStreamName}
            y2={sourceStreamName}
            fill={
              index % 2 === 0
                ? isDarkModeEnabled
                  ? "rgba(255,255,255,0.05)"
                  : "rgba(0,0,0,0.05)"
                : "rgba(0,0,0,0.0)"
            }
          />
        ))}
        <CartesianGrid
          horizontal={false}
          {...theme.CartesianGrid}
          verticalCoordinatesGenerator={(props) => {
            // This is done manually to avoid a vertical line at the very end of the chart
            return props.xAxis.ticks.map(props.xAxis.scale);
          }}
        />
        <XAxis
          {...chart.xAxisProps}
          {...theme.XAxis}
          stroke={theme.text}
          axisLine={{ stroke: theme.XAxis.stroke }}
          type="number"
          scale={"time"}
          interval={0}
          fontSize={12}
          orientation={"top"}
          tickLine={false}
          allowDataOverflow
          name="time"
          dataKey="x"
          includeHidden={false}
        />
        <YAxis
          dataKey="sourceStreamName"
          type="category"
          allowDuplicatedCategory={false}
          {...theme.YAxis}
          stroke={theme.text}
          axisLine={{ stroke: theme.XAxis.stroke }}
          width={1}
          mirror
          tick={
            props.hideSourceStreamLabels
              ? false
              : (props) => {
                  const { x, y, payload } = props;
                  return (
                    <g>
                      <text
                        x={x}
                        y={y}
                        fill={theme.text}
                        fontSize={12}
                        fontWeight={400}
                        stroke="none"
                        textAnchor="start"
                        alignmentBaseline="middle"
                        dx={-4}
                        dy={-8}
                      >
                        {payload.value}
                      </text>
                    </g>
                  );
                }
          }
          name="sourceStream"
        />
        <Tooltip
          content={<TooltipContent />}
          cursor={false}
          allowEscapeViewBox={{ y: false, x: false }}
          offset={0}
        />
        {preparedData.map((d) => (
          <Scatter
            key={d.sourceStreamName}
            name={d.sourceStreamName}
            data={d.data}
            shape={
              <SyncJobRectangle
                currentDateTime={window.currentDate}
                hideSourceStreamLabels={props.hideSourceStreamLabels}
              />
            }
          />
        ))}
        <ReferenceLine x={window.currentDate.valueOf()} stroke="#333" />
        <Customized component={CustomizedLowerXAxis} />
      </ScatterChart>
    </>
  );
}

function SyncJobRectangle(props: {
  currentDateTime: dayjs.Dayjs;
  xAxis?: XAxisProps;
  payload?: StatsDatum;
  y?: number;
  hideSourceStreamLabels?: boolean;
}) {
  const { y, payload, xAxis } = props;
  if (!payload || y == null || typeof xAxis?.scale !== "function") {
    return null;
  }
  if (payload.isDummy) {
    return <g />;
  }

  const { finishedAt } = payload;
  const x = xAxis.scale(payload.x);

  let width = 0;
  if (finishedAt) {
    const x2 = dayjs(finishedAt).valueOf();
    width = xAxis.scale(x2) - x;
  } else {
    width = xAxis.scale(props.currentDateTime.valueOf()) - x;
  }
  if (width < 0) {
    return null;
  }
  const colorProps = getSyncJobColorProps({
    status: payload.status ?? undefined,
    operationMode: payload.operationMode ?? undefined,
    fullSyncTrigger: payload.fullSyncTrigger ?? undefined,
  });
  return (
    <rect
      {...colorProps}
      x={x}
      y={props.hideSourceStreamLabels ? y : y + 9}
      width={
        payload.status === SyncJobStatus.Running ? width : Math.max(width, 5)
      }
      height={10}
      rx={1}
    />
  );
}

function TooltipContent(props: {
  active?: boolean;
  label?: string;
  payload?: {
    value: number;
    payload: StatsDatum;
    name: string;
    dataKey: string;
    fill: string;
  }[];
}) {
  const { active, payload } = props;
  if (!active || !payload || !payload.length) {
    return null;
  }
  const data = payload[0].payload;
  return (
    <PopperPortal active={active}>
      <div className="mt-4 rounded-sm border bg-white text-sm shadow-lg dark:border-gray-700 dark:bg-gray-800">
        <div className="px-3 pt-3">
          <div className="mb-2 font-medium">{data.sourceStreamName}</div>
        </div>
        <div className="flex flex-col gap-3 px-3 pb-3">
          <ul className="space-y-0.5">
            {data.startedAt != null && (
              <li>
                Sync start:{" "}
                {formatDate(data.startedAt, {
                  timeStyle: "long",
                })}
              </li>
            )}
            {data.finishedAt != null && (
              <li>
                Sync end:{" "}
                {formatDate(data.finishedAt, {
                  timeStyle: "long",
                })}
              </li>
            )}
            {data.duration != null && (
              <li>
                Duration:{" "}
                {formatDuration(Math.floor(data.duration / 1000) * 1000)}
              </li>
            )}
            {data.status != null && (
              <li>Status: {formatSyncStatus(data.status)}</li>
            )}
          </ul>
          <ul className="space-y-0.5">
            <li>Rows synced: {formatRowCount(data.rowsSynced)}</li>
            <li>Date volume: {formatBytes(data.rowsSyncedMb * 1024 * 1024)}</li>
            {data.operationMode != null && (
              <li>
                Mode:{" "}
                {formatOperationMode(data.operationMode, data.fullSyncTrigger)}
              </li>
            )}
            {data.fullSyncTrigger != null &&
              data.fullSyncTrigger !== FullSyncTrigger.None && (
                <li>
                  Full sync trigger:{" "}
                  {formatFullSyncTrigger(data.fullSyncTrigger)}
                </li>
              )}
          </ul>
        </div>
      </div>
    </PopperPortal>
  );
}

function CustomizedLowerXAxis(props: CustomizedProps<any, any>) {
  const { width, height } = props;
  const theme = useChartTheme("default");
  if (
    !width ||
    !height ||
    typeof width !== "number" ||
    typeof height !== "number"
  ) {
    return null;
  }
  return (
    <line
      id="customized-lower-x-axis"
      x1={24}
      y1={height - 0.5}
      x2={width - 24}
      y2={height - 0.5}
      stroke={theme.XAxis.stroke}
    />
  );
}
