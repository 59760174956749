import {
  useCloseCurrentTab,
  useCreateNewEmptyTab,
} from "@/components/modules/ModelTabs";
import { isWindows } from "@/helpers/isWindows";
import { useKBar } from "kbar";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useCallback, useEffect } from "react";

import { useGetEditorTextState } from "../ModelEditorStore";
import { useExecuteSql } from "../QueryEditor/ActionButtons";
import { useUpdateEditorText } from "../QueryEditor/monaco/useUpdateEditorText";
import { useFocusMode } from "./useFocusMode";
import { useFormatWeldSQL } from "./useFormatWeldSQL";

export function useKeyEventHandler() {
  const mixpanel = useMixpanel();

  const getTextState = useGetEditorTextState();
  const handleExecute = useExecuteSql();
  const kbar = useKBar();
  const [, setFocusMode] = useFocusMode();
  const createNewEmptyTab = useCreateNewEmptyTab();
  const closeCurrentTab = useCloseCurrentTab();
  const updateText = useUpdateEditorText();
  const formatWeldSQL = useFormatWeldSQL();

  return useCallback(
    (e: KeyboardEvent) => {
      const modKey = isWindows() ? e.ctrlKey : e.metaKey;

      if (modKey && e.code === "Enter") {
        e.preventDefault();
        // Stop propagation so that the monaco editor does not add a line break
        e.stopPropagation();
        handleExecute();
        mixpanel.track("Query Executed", {
          action: "keyboard shortcut",
        });
      }

      if (modKey && e.shiftKey && e.key === "f") {
        e.preventDefault();
        e.stopPropagation();
        updateText(formatWeldSQL(getTextState().weldSql));
        mixpanel.track("Model Formatted", {
          action: "keyboard shortcut",
        });
      }

      if (modKey && e.shiftKey && e.code === "Equal") {
        e.preventDefault();
        // This will bubble up from the monaco editor so it needs to be stopped in order not to have the handler run twice
        e.stopPropagation();
        createNewEmptyTab();
        mixpanel.track("New Editor Tab Created", {
          action: "keyboard shortcut",
        });
      }

      if (modKey && e.shiftKey && e.code === "Minus") {
        e.preventDefault();
        // This will bubble up from the monaco editor so it needs to be stopped in order not to have the handler run twice
        e.stopPropagation();
        closeCurrentTab();
        mixpanel.track("Editor Tab Closed", {
          action: "keyboard shortcut",
        });
      }

      if (modKey && e.key === ".") {
        e.preventDefault();
        // This will bubble up from the monaco editor so it needs to be stopped in order not to have the handler run twice
        e.stopPropagation();
        setFocusMode((prev) => !prev);
        mixpanel.track("Focus Mode Toggled", {
          action: "keyboard shortcut",
        });
      }

      if (modKey && e.key === "k") {
        if (e.defaultPrevented) {
          return;
        }
        e.preventDefault();
        kbar.query.toggle();
        mixpanel.track("KBar Toggled", {
          action: "keyboard shortcut",
        });
      }
    },
    [
      setFocusMode,
      createNewEmptyTab,
      closeCurrentTab,
      handleExecute,
      kbar.query,
      formatWeldSQL,
      getTextState,
      updateText,
      mixpanel,
    ],
  );
}

export function useKeyboardShortcuts() {
  const handleKeyDown = useKeyEventHandler();
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
}
