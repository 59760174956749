import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import {
  getPeriodLength,
  parseCronExpression,
  tokenizeCronExpression,
} from "./cron-utils";

export type CronOptionBase = {
  label: string;
  value: string;
  disabled?: boolean;
};

type TimeOffset = {
  hour: number;
  minute: number;
};

export type UseSyncScheduleProps<
  TOption extends CronOptionBase = CronOptionBase,
> = {
  value: TOption | undefined;
  options: TOption[];
  onChange: (
    value: string,
    option: TOption | undefined,
    offset: TimeOffset | undefined,
  ) => void;
};

export function useSyncSchedule<
  TOption extends CronOptionBase = CronOptionBase,
>(props: UseSyncScheduleProps<TOption>) {
  const { value, options } = props;

  const state = useMemo(() => {
    if (value?.value === undefined) {
      return {
        selectedOption: undefined,
        timeOffset: {
          hour: 0,
          minute: 0,
          maxHour: 0,
          maxMinute: 0,
        },
        error: undefined,
      };
    }
    try {
      const { baseExpression, timeOffset } = parseCronExpression(value.value);
      const selectedOption = options.find((x) => x.value === baseExpression);
      return {
        selectedOption,
        timeOffset,
        error: undefined,
      };
    } catch (error) {
      return {
        selectedOption: undefined,
        timeOffset: {
          hour: 0,
          minute: 0,
          maxHour: 0,
          maxMinute: 0,
        },
        error:
          error instanceof Error ? error : new Error(JSON.stringify(error)),
      };
    }
  }, [value, options]);

  const onChangeProp = props.onChange;
  const onChange = useCallback(
    (option: TOption | undefined, timeOffsetArg?: TimeOffset) => {
      if (option === undefined) {
        return;
      }
      try {
        let timeOffset = timeOffsetArg ?? state.timeOffset;
        const parseResult = parseCronExpression(option.value);
        // Make sure that current time offset won't overflow when added to the new cron expression
        if (parseResult.timeOffset.maxHour < timeOffset.hour) {
          timeOffset.hour = 0;
        }
        if (parseResult.timeOffset.maxMinute < timeOffset.minute) {
          timeOffset.minute = 0;
        }

        if (
          timeOffset === undefined ||
          (timeOffset.hour === 0 && timeOffset.minute === 0)
        ) {
          onChangeProp(option.value, option, timeOffset);
          return;
        }

        const hourFields = Array.from(parseResult.result.fields.hour).map(
          (x) => x + timeOffset.hour,
        );
        const minuteFields = Array.from(parseResult.result.fields.minute).map(
          (x) => x + timeOffset.minute,
        );

        const { dayOfMonth, month, dayOfWeek } = tokenizeCronExpression(
          option.value,
        );

        const newExpression = [
          getPeriodLength(minuteFields, 60) === 1
            ? "*"
            : minuteFields.join(","),
          getPeriodLength(hourFields, 24) === 1 ? "*" : hourFields.join(","),
          dayOfMonth,
          month,
          dayOfWeek,
        ].join(" ");

        onChangeProp(newExpression, option, timeOffset);
      } catch (error) {
        onChangeProp(option.value, undefined, undefined);
      }
    },
    [onChangeProp, state.timeOffset],
  );

  const [mode, setMode] = useState<"select" | "custom">(() => {
    if (value === undefined) {
      return "select";
    }
    return options.find((x) => x.value === state.selectedOption?.value)
      ? "select"
      : "custom";
  });

  const onToggleMode = useCallback(() => {
    if (mode === "custom") {
      onChange(state.selectedOption ?? options[0]);
    } else {
      // setShowTimeOffsetInput(false);
    }
    setMode(mode === "custom" ? "select" : "custom");
  }, [onChange, mode, state.selectedOption, options]);

  const [showTimeOffsetInput, setShowTimeOffsetInput] = useState(() => {
    const { hour, minute } = state.timeOffset;
    return hour !== 0 || minute !== 0;
  });

  const onToggleTimeOffsetInput = useCallback(() => {
    setShowTimeOffsetInput((x) => !x);
  }, []);

  const onChangeTimeOffset = (offset: TimeOffset) => {
    onChange(state.selectedOption ?? options[0], offset);
  };

  const onResetTimeOffset = () => {
    onChange(state.selectedOption ?? options[0], { hour: 0, minute: 0 });
    setShowTimeOffsetInput(false);
  };

  const canSelectTimeOffset =
    mode !== "custom" &&
    !!state.selectedOption &&
    state.timeOffset.maxMinute > 0 &&
    state.timeOffset.maxHour > 0;

  return {
    value,
    options,
    error: state.error,
    timeOffset: state.timeOffset,
    selectedOption: state.selectedOption,
    mode,
    canSelectTimeOffset,
    showTimeOffsetInput,
    onChange,
    onChangeTimeOffset,
    onToggleMode,
    onToggleTimeOffsetInput,
    onResetTimeOffset,
  };
}

export const SyncScheduleContext = createContext<ReturnType<
  typeof useSyncSchedule
> | null>(null);

export function useSyncScheduleContext<
  TOption extends CronOptionBase = CronOptionBase,
>() {
  const context = useContext(SyncScheduleContext);
  if (!context) {
    throw new Error(
      "useSyncScheduleContext must be used within a SyncScheduleContext",
    );
  }
  return context as ReturnType<typeof useSyncSchedule<TOption>>;
}
