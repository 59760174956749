import { useEffect } from "react";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import { TextMuted } from "@/components/elements/Typography";
import { datadogRum } from "@datadog/browser-rum";
import { ComponentProps } from "@tw-classed/react";

import ViewsSelector from "./ViewsSelector";

export function ViewsSelectorDialog({
  modalProps,
  ...props
}: Omit<ComponentProps<typeof ViewsSelector>, "onClose"> & {
  modalProps: ComponentProps<typeof Modal>;
}) {
  useEffect(() => {
    if (!modalProps.isOpen) return;
    datadogRum.addAction("AI-Assistant - Select Tables Dialog Opened");
    datadogRum.startSessionReplayRecording();
  }, [modalProps.isOpen]);

  return (
    <Modal centerOnScreen={false} {...modalProps}>
      <ModalHeader>
        <div>Select Tables</div>
        <TextMuted as="div" className="mt-2 text-sm">
          Choose relevant tables from your Data Sources and Models to help Ed
          provide more accurate answers.
        </TextMuted>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <ViewsSelector {...props} onClose={modalProps.onClose} />
      </ModalBody>
    </Modal>
  );
}
