interface Props {
  className?: string;
}

const OrchestrationIcon = (props: Props) => {
  return (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2 11.522V18a2 2 0 002 2h2.391M2 11.522V7.259m0 4.263h4.391M2 15.956h5M2 7.26V4a2 2 0 012-2h5.586a1 1 0 01.707.293L15 7M2 7.26h4.391m0 0H12.5m-6.109 0v4.262m0 8.478H9.5m-3.109 0v-8.478m0 0L7.5 11.5"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.403 9.468v4.935h3.702m3.701 0a7.402 7.402 0 11-14.804 0 7.402 7.402 0 0114.805 0z"
      ></path>
    </svg>
  );
};

export default OrchestrationIcon;
