import dayjs from "dayjs";
import { capitalize } from "lodash";

import { ListOrchestrationWorkflowsQuery } from "@/apollo/types";
import { PrimaryButton } from "@/components/elements/Button";
import { ButtonCard } from "@/components/elements/ButtonCard";
import { CronDisplayReadable } from "@/components/elements/CronDisplayReadable";
import DataBox from "@/components/elements/DataBox";
import OrchestrateIcon, {
  LocalScheduleIcon,
} from "@/components/icons/OrchestrateIcon";
import MainPageLayout from "@/components/layouts/MainPageLayout";
import { useListOrchestrationWorkflows } from "@/hooks/useListOrchestrationWorkflows";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "@tanstack/react-location";

import {
  WorkflowRunStatusBadge,
  WorkflowRunStatusDisplayText,
} from "./components/WorkflowRunStatusDisplay";

const OrchestrationOverview = () => {
  const { workflows, loading: loadingWorkflows } =
    useListOrchestrationWorkflows();

  const navigate = useNavigate();
  return (
    <MainPageLayout>
      <>
        <div className="flex flex-col space-y-8">
          <DataBox
            header="Orchestration"
            subheader="Orchestration execute jobs in order, determined by the data-lineage."
            loading={loadingWorkflows}
            action={
              <PrimaryButton
                onClick={() => {
                  navigate({
                    to: "./setup",
                  });
                }}
                icon={<PlusIcon />}
              >
                Add Orchestration
              </PrimaryButton>
            }
          >
            <div className="grid gap-4 lg:grid-cols-2">
              {workflows.map((workflow) => {
                return (
                  <OrchestrationWorkflowBox
                    key={workflow.id}
                    workflow={workflow}
                  />
                );
              })}
            </div>
            {loadingWorkflows && workflows.length === 0 && (
              <div className="grid gap-4 lg:grid-cols-2">
                <DataboxLoadingSkeleton />
                <DataboxLoadingSkeleton />
              </div>
            )}
          </DataBox>
          <DataBox
            header="Independent jobs"
            subheader="Independent jobs will run on custom configured schedules, independently of each other."
          >
            <ButtonCard
              className="w-full p-5"
              onClick={() => {
                navigate({ to: "./independent" });
              }}
            >
              <div className="w-full grow">
                <div className="flex items-center space-x-4">
                  <LocalScheduleIcon className="h-8 w-8 flex-none stroke-1" />
                  <div className="text-lg font-medium">Independent Jobs</div>
                </div>
              </div>
            </ButtonCard>
          </DataBox>
        </div>
      </>
    </MainPageLayout>
  );
};

export default OrchestrationOverview;

const OrchestrationWorkflowBox = ({
  workflow,
}: {
  workflow: ListOrchestrationWorkflowsQuery["orchestrationWorkflows"][0];
}) => {
  const { currentRun, cronExpression } = workflow;
  const navigate = useNavigate();
  const nextRunDate =
    workflow.nextRun == null ? null : dayjs(workflow.nextRun.dueAt);
  const currentRunStatus = currentRun?.status;
  return (
    <ButtonCard
      className="w-full flex-col p-5 text-left"
      onClick={() => {
        navigate({
          to: `./${workflow.id}`,
        });
      }}
    >
      <div className="flex w-full grow flex-col space-y-4">
        <div className="flex items-center">
          <div className="flex items-center space-x-4">
            <OrchestrateIcon className="h-8 w-8 flex-none stroke-1" />
            <div>
              <div className="text-lg font-medium">{workflow.name}</div>
            </div>
          </div>
          {currentRunStatus && (
            <div className="ml-auto">
              <WorkflowRunStatusBadge runStatus={currentRunStatus}>
                <WorkflowRunStatusDisplayText runStatus={currentRunStatus} />
              </WorkflowRunStatusBadge>
            </div>
          )}
        </div>
        <div className="text-xs">
          Schedule:{" "}
          <span className="font-medium text-muted-foreground">
            <CronDisplayReadable capitalize cron={cronExpression} />{" "}
            {nextRunDate != null && (
              <span>({capitalize(nextRunDate?.fromNow())})</span>
            )}
          </span>
        </div>
      </div>
    </ButtonCard>
  );
};

const DataboxLoadingSkeleton = () => {
  return <div className="animation-gradient-loading h-24 w-full" />;
};
