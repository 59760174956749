import { ComponentProps, createContext, useContext } from "react";

import { Price, RecurrenceInterval, RecurrenceUsageType } from "@/apollo/types";
import { formatCurrency } from "@/shared/formatters";

import { useLocaleContext } from "../providers/CurrencyProvider";

type FormattedPriceProps = {
  price: Pick<Price, "currency" | "price" | "recurring" | "currencyOptions">;
  currency?: string;
  quantity?: number;
  convertBillingInterval?: `${RecurrenceInterval}`;
};

const FormattedPriceContext = createContext<ReturnType<typeof usePrice> | null>(
  null,
);

function useFormattedPriceContext() {
  const context = useContext(FormattedPriceContext);
  if (context === null) {
    throw new Error("Missing FormattedPriceContext");
  }
  return context;
}

function usePrice(props: FormattedPriceProps) {
  const quantity = props?.quantity ?? 1;
  const currency = props?.currency ?? props.price.currency;
  const price =
    props.price.currencyOptions.find((x) => x.currency === currency)?.price ??
    props.price.price;

  let value =
    props.price.recurring?.usageType === RecurrenceUsageType.Metered
      ? price
      : price * quantity;

  if (
    props.price.recurring != null &&
    props.convertBillingInterval &&
    props.convertBillingInterval !== props.price.recurring.interval
  ) {
    value = convertRecurringPriceToInterval(
      value,
      props.price.recurring.interval,
      props.convertBillingInterval as RecurrenceInterval,
    );
  }
  return {
    rawValue: value,
    value: formatCurrency(value, { currency }),
    interval: props.price.recurring?.interval,
    convertBillingInterval: props.convertBillingInterval,
    price: props.price,
    currency,
  };
}

function FormattedPriceFn(
  props: Omit<ComponentProps<"span">, "children"> &
    FormattedPriceProps & {
      children?:
        | React.ReactNode
        | ((price: ReturnType<typeof usePrice>) => React.ReactNode);
    },
) {
  const {
    price,
    currency: currencyProp,
    quantity,
    children,
    convertBillingInterval,
    ...spanProps
  } = props;

  const currencyContext = useLocaleContext();
  const currency = currencyProp ?? currencyContext.currency;
  const context = usePrice({
    price,
    quantity,
    currency,
    convertBillingInterval,
  });
  return (
    <FormattedPriceContext.Provider value={context}>
      <span {...spanProps}>
        {typeof children === "function"
          ? children(context)
          : children === undefined
            ? formatCurrency(context.rawValue, { currency })
            : children}
      </span>
    </FormattedPriceContext.Provider>
  );
}

function PriceFn(
  props: ComponentProps<"span"> & {
    options?: Intl.NumberFormatOptions;
  },
) {
  const { rawValue, currency } = useFormattedPriceContext();
  const formattedValue = formatCurrency(rawValue, {
    currency,
    ...props.options,
  });
  return <span {...props}>{formattedValue}</span>;
}

function PeriodFn({
  includeBillingInterval,
  ...spanProps
}: ComponentProps<"span"> & {
  includeBillingInterval?: boolean;
}) {
  const { price, convertBillingInterval } = useFormattedPriceContext();
  if (price.recurring == null) {
    return null;
  }
  const { interval, intervalCount } = price.recurring;

  let output = "";
  if (convertBillingInterval) {
    output = `per ${convertBillingInterval.toLowerCase()}`;
  } else if (intervalCount === 1) {
    output = `per ${interval.toLowerCase()}`;
  } else {
    output = `every ${intervalCount} ${interval.toLowerCase()}s`;
  }

  if (includeBillingInterval && interval !== convertBillingInterval) {
    const billingInterval = (
      {
        Day: "daily",
        Week: "weekly",
        Month: "monthly",
        Year: "annually",
      } as Record<RecurrenceInterval, string>
    )[interval];
    output += `, billed ${billingInterval}`;
  }

  return <span {...spanProps}>{output}</span>;
}

const FormattedPriceNamespace = Object.assign(FormattedPriceFn, {
  Price: PriceFn,
  Period: PeriodFn,
});

export { FormattedPriceNamespace as FormattedPrice };

function convertRecurringPriceToInterval(
  price: number,
  fromInterval: RecurrenceInterval,
  toInterval: RecurrenceInterval,
) {
  const intervalsInDays: Record<RecurrenceInterval, number> = {
    Year: 365.25,
    Month: 30.436875,
    Week: 7,
    Day: 1,
  };
  // Calculate conversion factors
  const conversionFactor =
    intervalsInDays[toInterval] / intervalsInDays[fromInterval];
  // Convert price to new billing period
  return price * conversionFactor;
}
