import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { CheckIcon, PlayIcon, PlusIcon } from "@heroicons/react/24/solid";
import {
  MaterializationType,
  ModelTestResult,
  ModelTestsByModelDocument,
  ModelTestsByModelQuery,
  TestStatus,
  TestType,
  useModelTestsByModelQuery,
  useOrchestrationWorkflowQuery,
  useRemoveModelTestMutation,
} from "@/apollo/types";
import { SecondaryButton, UtilityButton } from "@/components/elements/Button";
import { LoadingFull } from "@/components/elements/LoadingComponents";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import MenuItem from "@/components/elements/MenuItem";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import PreviewTable from "@/components/elements/PreviewTable";
import Tooltip from "@/components/elements/Tooltip";
import SqlHighlighter from "@/components/modules/highlighter/SqlHighlighter";
import { ModelTestScheduleSelectorModal } from "@/components/modules/ModelTestScheduleSelectorModal";
import TableMenu from "@/components/modules/TableMenu";
import ButtonPrimitive from "@/components/primitives/Button";
import FieldLabel from "@/components/primitives/InputLabel";
import { useOrchestrationFeatureGuard } from "@/features/feature-guards";
import classNames from "@/helpers/classNames";
import { useToast } from "@/providers/ToastProvider";
import { useMemo, useState } from "react";

import { useCurrentModel } from "../hooks/useCurrentModel";
import OrchestrationSchedulerTypeInfo from "../materialization/OrchestrationSchedulerTypeInfo";
import { useTableSchema } from "../Preview/Preview";
import { AddTest } from "./AddTest";
import { useStartRunningTest, useTestResult } from "./useRunTest";

export function ModelTests() {
  const [addingTest, setAddingTest] = useState(false);
  const currentModel = useCurrentModel();
  const toast = useToast();

  const [runOnModel, setRunOnModel] = useState<"published" | "draft">(
    "published",
  );
  const tests = useModelTests(currentModel?.id ?? "");
  const runTest = useStartRunningTest();
  const [isEditingSchedule, setIsEditingSchedule] = useState(false);
  const { isEnabled: showModelTestsSchedule } = useOrchestrationFeatureGuard();
  const { data } = useOrchestrationWorkflowQuery({
    variables: {
      workflowId: currentModel?.orchestrationWorkflow?.id ?? "",
    },
  });

  if (!currentModel) return null;

  return (
    <>
      <div className="h-full space-y-8 overflow-auto p-8">
        <div>
          <FieldLabel>Run tests on</FieldLabel>
          <span className="relative z-0 inline-flex rounded-md">
            <ButtonPrimitive
              className={classNames(
                runOnModel === "published"
                  ? "bg-primary text-white"
                  : "bg-white hover:bg-gray-50",
                `relative rounded-l-md border px-4 py-2 text-sm font-medium focus:z-10 focus:ring-offset-2`,
              )}
              onClick={() => setRunOnModel("published")}
            >
              Published model
            </ButtonPrimitive>
            <ButtonPrimitive
              className={classNames(
                runOnModel === "draft"
                  ? "bg-primary text-white"
                  : "hover:bg-gray-50 dark:hover:bg-gray-700",
                "-ml-px cursor-default rounded-r-md border px-4 py-2 text-sm font-medium opacity-50 focus:z-10 focus:ring-offset-2",
              )}
              onClick={() => {
                toast(
                  "Not implemented",
                  "This feature is not yet ready...",
                  "warning",
                );
              }}
            >
              Current draft
            </ButtonPrimitive>
          </span>
        </div>
        <div className="flex flex-col space-y-1">
          <div className="flex w-full text-sm font-medium">
            <div className="w-3/12" />
            <div className="w-3/12 px-2">Column name</div>
            <div className="w-3/12 px-2">Test id</div>
            <div className="w-3/12 px-2">Test type</div>
            <div className="w-3/12" />
          </div>
          {tests.length === 0 && (
            <div className="w-full rounded bg-gray-200 p-4 text-xs dark:bg-gray-700">
              No tests found
            </div>
          )}
          {tests.map((test) => {
            return <ModelTest key={test.id} modelTest={test} />;
          })}
          <div>
            <SecondaryButton
              onClick={() => setAddingTest(true)}
              icon={<PlusIcon className="h-4 w-4" />}
              className={"w-full"}
            >
              <div>Add new test</div>
            </SecondaryButton>
          </div>
        </div>
        <div className="flex">
          {tests.length > 0 && (
            <SecondaryButton
              onClick={() => {
                tests.forEach((test, i) => {
                  setTimeout(() => {
                    runTest(test.id);
                    //timeout fumction just to make it look like they're starting one by one.
                  }, i * 100);
                });
              }}
              icon={<PlayIcon />}
            >
              Run all tests
            </SecondaryButton>
          )}
        </div>
        {showModelTestsSchedule &&
          currentModel.materializationType === MaterializationType.View && (
            <div>
              <FieldLabel>Schedule</FieldLabel>
              <div className="flex items-center space-x-8">
                <OrchestrationSchedulerTypeInfo
                  orchestrationScheduler={currentModel.orchestrationScheduler}
                  workflow={data?.orchestrationWorkflow}
                />
                <div className="flex items-center space-x-2">
                  <SecondaryButton
                    onClick={() => setIsEditingSchedule(true)}
                    className="w-44"
                  >
                    Edit schedule
                  </SecondaryButton>
                  <Tooltip content="Set tests to be run as part of an orchestration.">
                    <div>
                      <InformationCircleIcon className="h-5 w-5 dark:text-white" />
                    </div>
                  </Tooltip>
                  <ModelTestScheduleSelectorModal
                    handleClose={() => setIsEditingSchedule(false)}
                    isShowing={isEditingSchedule}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
      <AddTest
        modelId={currentModel.id}
        addingTest={addingTest}
        onFinish={() => setAddingTest(false)}
      />
    </>
  );
}

const useModelTests = (modelId: string) => {
  const { data } = useModelTestsByModelQuery({
    variables: {
      modelId,
    },
  });
  return useMemo(() => data?.model.modelTests ?? [], [data]);
};

const ModelTest = (props: {
  modelTest: ModelTestsByModelQuery["model"]["modelTests"][0];
}) => {
  const runTest = useStartRunningTest();
  const testResult = useTestResult(props.modelTest.id);
  const toast = useToast();

  const [viewTestDetails, setViewTestDetails] = useState(false);

  const [removeModelTest, removeModelTestMutation] = useRemoveModelTestMutation(
    {
      variables: {
        id: props.modelTest.id,
      },
      refetchQueries: [ModelTestsByModelDocument],
      onCompleted: () => {
        toast("Test removed", "Successfully removed the test", "success");
      },
      onError: (error) => {
        toast("Error removing test", error.message, "error");
      },
    },
  );

  const renderTestStatusText = () => {
    if (testResult) {
      return <TestStatusIndicator status={testResult.status} />;
    }

    return <span className="text-sm font-medium">Run test now</span>;
  };

  return (
    <div className="relative flex w-full items-center rounded border bg-gray-50 py-2 dark:border-gray-600 dark:bg-gray-700">
      <div className="flex w-3/12 items-center space-x-2 pl-4 pr-2 text-sm font-medium">
        {testResult?.status === TestStatus.Running ? (
          <div className="flex items-center space-x-2">
            <LoadingSpinner className="h-4 w-4" />
            <span className="text-sm font-medium">Running test</span>
          </div>
        ) : (
          <button
            className="group flex items-center space-x-2"
            onClick={() => {
              runTest(props.modelTest.id);
            }}
          >
            <PlayIcon className="h-4 w-4 text-gray-600 group-hover:text-gray-800 dark:text-gray-200 dark:group-hover:text-white" />
            {renderTestStatusText()}
          </button>
        )}
        {testResult?.modelTestWeldSql && (
          <UtilityButton
            onClick={() => {
              setViewTestDetails(true);
            }}
            className="text-gray-600 dark:text-gray-300"
          >
            View details
          </UtilityButton>
        )}
      </div>
      <div className="w-3/12 px-2 text-sm">
        <div>{props.modelTest.column}</div>
      </div>
      <div className="w-3/12 px-2">
        <div>{props.modelTest.id}</div>
      </div>
      <div className="w-3/12 px-2">
        <TestTypeTag type={props.modelTest.type} />
      </div>
      <div className="flex w-3/12 justify-end px-4">
        <TableMenu size="sm">
          {/* <MenuItem text="Edit test" onClick={() => {}} /> */}
          <MenuItem
            text="Delete test"
            onClick={() => {
              if (removeModelTestMutation.loading) return;
              removeModelTest();
            }}
          />
        </TableMenu>
      </div>
      {removeModelTestMutation.loading && <LoadingFull />}
      {testResult && testResult.modelTestWeldSql && (
        <TestRunDetails
          show={viewTestDetails}
          onClose={() => {
            setViewTestDetails(false);
          }}
          modelTest={props.modelTest}
          testResult={testResult}
        />
      )}
    </div>
  );
};

const TestRunDetails = (props: {
  show: boolean;
  onClose: () => void;
  modelTest: ModelTestsByModelQuery["model"]["modelTests"][0];
  testResult: ModelTestResult;
}) => {
  const [showSql, setShowSql] = useState(false);
  return (
    <Modal isOpen={props.show} onClose={props.onClose} size="lg">
      <ModalCloseButton />
      <ModalHeader className="flex items-center gap-2">
        <span className="font-medium">{props.modelTest.column}</span>
        <TestTypeTag type={props.modelTest.type} />
      </ModalHeader>
      <ModalBody className="flex flex-col gap-6">
        <div>
          <TestStatusIndicatorLarge status={props.testResult.status} />
        </div>
        {!!props.testResult.failedRows?.length && (
          <div>
            <div className="font-medium">Test failed for rows</div>
            <div className="rounded border">
              <TestResultData data={props.testResult.failedRows} />
            </div>
          </div>
        )}
        <div>
          <UtilityButton tabIndex={-1} onClick={() => setShowSql((p) => !p)}>
            {showSql ? "Hide" : "Show"} test SQL statement
          </UtilityButton>
          <div
            className={classNames(
              showSql ? "max-h-94" : "max-h-0",
              "overflow-hidden",
            )}
          >
            <SqlHighlighter
              sqlStatement={props.testResult.modelTestWeldSql ?? ""}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export const TestTypeTag = (props: { type: TestType }) => {
  const displayType = (type: TestType) => {
    switch (type) {
      case TestType.Uniqueness:
        return "Unique";
      case TestType.NotNull:
        return "Not null";
    }
  };
  const description = (type: TestType) => {
    switch (type) {
      case TestType.Uniqueness:
        return "Tests that all values for the column are unique.";
      case TestType.NotNull:
        return "Tests that no values for the column contain null values.";
    }
  };

  return (
    <Tooltip content={description(props.type)}>
      <span className="rounded bg-gray-200 px-2 py-1 text-xs font-medium dark:bg-gray-600">
        {displayType(props.type)}
      </span>
    </Tooltip>
  );
};

export const TestStatusIndicator = (props: { status: TestStatus }) => {
  if (props.status === TestStatus.Passed) {
    return <span className="text-sm font-medium text-green-500">Passed</span>;
  }
  if (props.status === TestStatus.Failed) {
    return <span className="text-sm font-medium text-red-500">Failed</span>;
  }
  if (props.status === TestStatus.ExecutionError) {
    return (
      <span className="text-sm font-medium text-red-500">Execution error</span>
    );
  }
  return null;
};

export const TestStatusIndicatorLarge = (props: { status: TestStatus }) => {
  return (
    <div
      className={classNames(
        props.status === TestStatus.Passed ? "bg-green-500" : "bg-red-500",
        "inline-flex items-center space-x-2 rounded p-2 px-4 text-white",
      )}
    >
      {props.status === TestStatus.Passed ? (
        <CheckIcon className="h-6 w-6" />
      ) : (
        <ExclamationCircleIcon className="h-6 w-6" />
      )}
      <span className="">{props.status}</span>
    </div>
  );
};

export const TestResultData = (props: { data: any[] }) => {
  const tableSchema = useTableSchema(props.data);

  return (
    <PreviewTable data={props.data} columns={tableSchema} rowsPerPage={20} />
  );
};
