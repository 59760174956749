import {
  ClockIcon,
  ChartBarIcon,
  PresentationChartLineIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";
import Select from "@/components/elements/Select";
import { Input } from "@/components/primitives/input";
import FieldLabel from "@/components/primitives/InputLabel";
import produce from "immer";
import {
  ChartVisualization,
  Tile,
  Visualization,
  VisualizationType,
} from "../VisualizationType";
import { columnOption } from "./useTileColumnOptions";

export const chartTypeOptions = [
  {
    id: "table",
    label: "Table",
    value: VisualizationType.Table,
    icon: <TableCellsIcon className="h-4 w-4 dark:text-white" />,
  },
  {
    id: "bar",
    label: "Bar",
    value: VisualizationType.Bar,
    icon: <ChartBarIcon className="h-4 w-4 dark:text-white" />,
  },
  {
    id: "line",
    label: "Line",
    value: VisualizationType.Line,
    icon: <PresentationChartLineIcon className="h-4 w-4 dark:text-white" />,
  },
  {
    id: "metric",
    label: "Metric",
    value: VisualizationType.Metric,
    icon: <PresentationChartLineIcon className="h-4 w-4 dark:text-white" />,
  },
];

export const SelectVisualizationType = (props: {
  originalTile: Tile;
  setVisualization: (visualization: Visualization) => void;
  visualization: Visualization;
  columnOptions: columnOption[];
}) => {
  const { visualization, setVisualization } = props;

  return (
    <>
      <div>
        <FieldLabel>Visualization type</FieldLabel>
        <Select
          placeholder="Select chart type"
          options={chartTypeOptions}
          isClearable={false}
          value={chartTypeOptions.find((o) => o.value === visualization.type)}
          onChange={(option: (typeof chartTypeOptions)[0]) => {
            const pVisualization = props.originalTile.visualization;
            if (option.value === VisualizationType.Table) {
              setVisualization({ type: option.value });
              return;
            }
            if (option.value === pVisualization.type) {
              setVisualization(pVisualization);
              return;
            }

            if (option.value === VisualizationType.Metric) {
              setVisualization({
                type: option.value,
                metricColumn:
                  pVisualization.type === VisualizationType.Metric
                    ? pVisualization.metricColumn
                    : "",
                unit: "",
                unitPostition: "suffix",
              });
              return;
            }

            const keys = {
              x:
                props.columnOptions.find((o) => o.value === "timestamp")
                  ?.value ?? "",
              y: [],
            } as ChartVisualization["keys"];

            if (
              pVisualization.type === VisualizationType.Bar ||
              pVisualization.type === VisualizationType.Line
            ) {
              keys.x = pVisualization.keys?.x || keys.x;
              keys.y = pVisualization.keys?.y || keys.y;
            }
            setVisualization({
              type: option.value,
              keys,
            });
          }}
          iconParser={(option: (typeof chartTypeOptions)[0]) => {
            return option.icon;
          }}
        />
      </div>

      {(visualization.type === VisualizationType.Line ||
        visualization.type === VisualizationType.Bar) && (
        <div className="flex space-x-2">
          <div className="flex-1">
            <FieldLabel className="flex items-center space-x-1">
              <span>X-Axis</span>
              <ClockIcon className="w-3" />
            </FieldLabel>
            <Select
              options={props.columnOptions}
              onChange={(option: (typeof props.columnOptions)[number]) => {
                setVisualization(
                  produce(visualization, (p) => {
                    p.keys.x = option.value;
                  }),
                );
              }}
              isClearable={false}
              value={props.columnOptions.find(
                (o) => o.value === visualization.keys.x,
              )}
            />
          </div>
          <div className="flex-1">
            <FieldLabel>Y-Axis</FieldLabel>
            <Select
              isMulti
              options={props.columnOptions}
              isClearable={false}
              value={props.columnOptions.filter((item) =>
                visualization.keys?.y?.includes(item.value),
              )}
              onChange={(option: typeof props.columnOptions) => {
                setVisualization(
                  produce(visualization, (p) => {
                    p.keys.y = option.map((item) => item.value);
                  }),
                );
              }}
            />
          </div>
        </div>
      )}
      {visualization.type === VisualizationType.Metric && (
        <div className="flex space-x-2">
          <div className="flex-1">
            <FieldLabel>Column</FieldLabel>
            <Select
              options={props.columnOptions}
              onChange={(option: (typeof props.columnOptions)[number]) => {
                setVisualization(
                  produce(visualization, (p) => {
                    p.metricColumn = option.value;
                  }),
                );
              }}
              isClearable={false}
              value={props.columnOptions.find(
                (o) => o.value === visualization.metricColumn,
              )}
            />
          </div>
          <div className="flex-1">
            <FieldLabel>Unit</FieldLabel>
            <Input
              value={visualization.unit}
              onChange={(e) => {
                setVisualization(
                  produce(visualization, (p) => {
                    p.unit = e.target.value;
                  }),
                );
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
