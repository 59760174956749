import { Dialog, Transition, TransitionEvents } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "@/helpers/classNames";
import React, { Fragment } from "react";

import { IconButton } from "./Button";

const SlideUpContext = React.createContext<{
  onClose: () => void;
}>({
  onClose: () => {},
});

type Props = {
  children: React.ReactNode;
  show: boolean;
  onClose: () => void;
  initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined;
} & Pick<TransitionEvents, "afterLeave">;

export function SlideUp(props: Props) {
  const ctx = {
    onClose: props.onClose,
  };

  return (
    <SlideUpContext.Provider value={ctx}>
      <Transition.Root
        show={props.show}
        as={Fragment}
        afterLeave={props.afterLeave}
      >
        <Dialog
          as="div"
          className="relative z-50"
          onClose={props.onClose}
          initialFocus={props.initialFocus}
        >
          <BackgroundOverlay />
          <div className="pointer-events-none fixed bottom-0 right-0 flex max-w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-500"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-300 sm:duration-500"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <Dialog.Panel
                className={classNames(
                  "pointer-events-auto relative flex h-screen w-screen flex-col border-t border-gray-200 bg-white shadow-xl dark:border-gray-700 dark:bg-gray-800",
                  "max-h-[70vh]",
                )}
              >
                {props.children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </SlideUpContext.Provider>
  );
}

export function SlideOverCloseButton() {
  const { onClose } = React.useContext(SlideUpContext);
  return (
    <IconButton
      onClick={() => onClose()}
      variant="ghost"
      icon={<XMarkIcon className="h-5 w-5" />}
      className="absolute right-2 top-2 z-10"
      size="sm"
    >
      <span className="sr-only">Close panel</span>
    </IconButton>
  );
}

const BackgroundOverlay = () => {
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-in-out duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in-out duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="fixed inset-0 bg-black/40 transition-opacity"
        aria-hidden="true"
      />
    </Transition.Child>
  );
};
