import { OrchestrationWorkflowHistoryQuery } from "apollo/types";
import DefaultTable from "components/elements/DefaultTable";
import { LoadingFull } from "components/elements/LoadingComponents";
import Tooltip from "components/elements/Tooltip";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { CellProps, Column } from "react-table";
import { LocationGenerics } from "routes";

import classNames from "@/helpers/classNames";
import useNow from "@/hooks/useNow";
import { useMatch, useNavigate } from "@tanstack/react-location";

import { OrchestrationTabsLayout } from "./OrchestrationTabsLayout";
import {
  WorkflowRunStatusBadge,
  WorkflowRunStatusDisplayText,
} from "./components/WorkflowRunStatusDisplay";
import { useWorkflowHistory } from "./components/useWorkflow";

const Duration = require("dayjs/plugin/duration");
const RelativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(Duration);
dayjs.extend(RelativeTime);

type Props = {};

type Row = NonNullable<
  OrchestrationWorkflowHistoryQuery["orchestrationWorkflow"]
>["runs"][0];

const OrchestrationHistory = (props: Props) => {
  const match = useMatch<LocationGenerics>();

  const { workflows, loading } = useWorkflowHistory(match.params.workflowId);

  const sortedWorkflows = useMemo(() => {
    return [...workflows].sort((a, b) => {
      const dueAtDifference =
        dayjs(b.dueAt).valueOf() - dayjs(a.dueAt).valueOf();
      if (dueAtDifference !== 0) return dueAtDifference;

      const aStartedAt = a.startedAt ? dayjs(a.startedAt).valueOf() : 0;
      const bStartedAt = b.startedAt ? dayjs(b.startedAt).valueOf() : 0;

      return bStartedAt - aStartedAt;
    });
  }, [workflows]);

  const columns = React.useMemo<Column<Row>[]>(
    () => [
      {
        Header: "Orchestration run",
        Cell: OverallRunColumn,
      },
      {
        Header: "Total run time",
        Cell: TotalRunTimeColumn,
      },
      {
        Header: "Materialization",
        Cell: ({ row }: CellProps<Row>) => {
          return (
            <WorkflowRunStatusBadge runStatus={row.original.status}>
              <WorkflowRunStatusDisplayText runStatus={row.original.status} />
            </WorkflowRunStatusBadge>
          );
        },
        accessor: "status",
      },
      {
        Header: "Tests",
        Cell: ({ row }: CellProps<Row>) => {
          return <div className="text-gray-500">No tests</div>;
        },
      },
    ],
    [],
  );

  const navigate = useNavigate();

  const handleRowClick = (row: Row) => {
    navigate({
      to: `./${row?.id}`,
    });
  };

  return (
    <OrchestrationTabsLayout current="history">
      <div className="relative">
        <DefaultTable
          onRowClick={handleRowClick}
          columns={columns}
          data={sortedWorkflows}
          empty={<EmptyOrchestrationHistory />}
          paginate={true}
        />
        {loading && <LoadingFull />}
      </div>
    </OrchestrationTabsLayout>
  );
};

export default OrchestrationHistory;

const EmptyOrchestrationHistory: React.FC<{}> = (props) => {
  return (
    <div className="relative w-full space-y-4 text-center dark:text-white">
      <p className="text-lg">{"No orchestration workflows has run yet"}</p>
      <p className="flex flex-col text-sm">
        <span>A list of historic orchestration runs will appear here.</span>
      </p>
    </div>
  );
};

const OverallRunColumn = ({ row }: CellProps<Row>) => {
  const now = useNow(60_000);

  const date = useMemo(() => {
    return row.original.startedAt
      ? dayjs(row.original.startedAt)
      : dayjs(row.original.dueAt);
  }, [row.original.dueAt, row.original.startedAt]);

  const diffInHours = now.diff(date, "hours");

  const formatDate = (date: dayjs.Dayjs, options: Intl.DateTimeFormatOptions) =>
    new Intl.DateTimeFormat(window.navigator.language, options).format(
      date.toDate(),
    );

  let timeDisplay =
    diffInHours <= 24
      ? now.to(date)
      : formatDate(date, {
          dateStyle: "short",
          timeStyle: "short",
        });

  return (
    <Tooltip
      content={formatDate(date, {
        dateStyle: "long",
        timeStyle: "short",
      })}
    >
      <span>{timeDisplay}</span>
    </Tooltip>
  );
};

const TotalRunTimeColumn = ({ row }: CellProps<Row>) => {
  const durationDisplayString = useMemo(() => {
    if (!row.original.finishedAt || !row.original.startedAt) return "N/A";

    const duration = dayjs.duration(
      dayjs(row.original.finishedAt).diff(dayjs(row.original.startedAt)),
    );

    let display = ``;
    if (duration.asHours() >= 1) {
      display += `${Math.floor(duration.asHours())}h `;
    }
    if (duration.minutes() > 0) display += `${duration.minutes()}m `;
    if (duration.seconds() > 0) display += `${duration.seconds()}s `;

    return display;
  }, [row.original.finishedAt, row.original.startedAt]);

  return (
    <div className="flex items-center space-x-2 text-muted-foreground">
      <div className={classNames("")}>{durationDisplayString}</div>
    </div>
  );
};
