import LoadingSpinner from "@/components/elements/LoadingSpinner";
import SyncStatusLabel, { Mode } from "@/components/elements/SyncStatusLabel";
import { useReverseEltSyncNotifications } from "@/features/notifications";

import { RowData } from "../columns";

export const SyncStatusCell = ({ sync }: { sync: RowData }) => {
  const { notifications, loading } = useReverseEltSyncNotifications([sync]);

  if (loading) return <LoadingSpinner />;

  if (sync.status === "NOT_STARTED" || sync.status === "PAUSED")
    return <SyncStatusLabel mode={sync.status as Mode} />;

  if (notifications.count === 0) return <SyncStatusLabel mode="RUNNING" />;

  return <SyncStatusLabel mode="FAILED" />;
};
