import { SQLCodeDisplay } from "@/components/elements/CodeDisplay";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import { useDWDisplayName } from "@/integrations/hooks/useDataWarehouseSpecifics";
import { useMemo } from "react";

import { useFormatWeldSQL } from "../hooks/useFormatWeldSQL";
import { useCurrentPreview, usePreviewCompiledSqlAtom } from "./Preview";

export const PreviewCompiledSqlModal = (props: {}) => {
  const [previewCompiledSql, setPreviewCompiledSql] =
    usePreviewCompiledSqlAtom();

  const dwDisplayName = useDWDisplayName();

  const openPreview = useCurrentPreview();

  const formatWeldSQL = useFormatWeldSQL();

  const formattedSql = useMemo(() => {
    if (!openPreview?.dwSql) return null;
    return formatWeldSQL(openPreview.dwSql).replaceAll(" ", "\u00a0");
  }, [openPreview?.dwSql, formatWeldSQL]);

  if (!formattedSql) return null;

  return (
    <Modal
      size="lg"
      isOpen={previewCompiledSql}
      onClose={() => setPreviewCompiledSql(false)}
    >
      <ModalCloseButton />
      <ModalHeader>Compiled SQL for {dwDisplayName}</ModalHeader>
      <ModalBody className="max-h-96 overflow-auto">
        <SQLCodeDisplay sql={formattedSql} />
      </ModalBody>
    </Modal>
  );
};
