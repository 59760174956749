import { ComponentProps } from "react";

import { Alert, AlertIcon } from "@/components/elements/Alert";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import { Heading, SectionHeading } from "@/components/elements/Typography";

import { EdIcon } from "../EdIcon";

export function HowToGuideDialog(props: ComponentProps<typeof Modal>) {
  return (
    <Modal size="lg" {...props}>
      <ModalHeader className="flex items-center gap-3">
        <EdIcon className="h-6 w-6" />
        How to Reference Data Sources and Models in Your Messages to Ed
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody className="pb-6">
        <div className="text-sm">
          <p>
            To get accurate and helpful answers, you need to provide Ed with
            context about your data. You can do this by referencing Data Sources
            and Models in your messages, as described below, or by selecting
            them manually via the "Select Tables" dialog.
          </p>
          <p className="mt-2">
            Note that the data context is set in the initial message to Ed when
            you start a conversation and cannot be changed during the
            conversation. If you need to change the context, you will need to
            start a new chat.
          </p>
          <Heading className="mb-2 mt-4 font-semibold">
            Data Sources and Models
          </Heading>
          <div className="space-y-2">
            <p>
              You can reference Data Sources and Models using the special syntax{" "}
              <code>{"{{folder.table}}"}</code>
            </p>
            <SectionHeading className="font-semibold">Examples</SectionHeading>
            <ol className="list-inside list-decimal space-y-2">
              <li>
                <strong>Data Source</strong>: to refer to the "campaign" table
                from the "facebook_ads" Data Source, write:
                <div className="my-2 ml-4">
                  <code>{"{{raw.facebook_ads.campaign}}"}</code>
                </div>
                <em className="ml-4">
                  Notice that the "raw" folder is used to reference Data
                  Sources.
                </em>
              </li>
              <li>
                <strong>Published model</strong>: To refer to the "customers"
                model located in the "finance" folder, write:
                <div className="ml-4 mt-2">
                  <code>{"{{finance.customers}}"}</code>
                </div>
              </li>
            </ol>
            <p>
              By referencing Data Sources or Models in this way, Ed will
              understand their table schemas and provide more accurate answers.
            </p>
          </div>
          <Heading className="mb-2 mt-4 font-semibold">
            The Currently Viewed Model
          </Heading>
          <div className="space-y-2">
            <div>
              By default, the current model you are viewing is included in the
              prompt to Ed. This includes:
              <ul className="my-2 list-inside list-disc space-y-2">
                <li>The SQL code</li>
                <li>All Data Sources and Models referenced by that SQL code</li>
              </ul>
            </div>
            <p>
              You can disable this feature by toggling the
              <em>"Include current model as context"</em> switch.
            </p>
          </div>
          <Heading className="mb-2 mt-4 font-semibold">
            Complete Data Source context
          </Heading>
          <div className="space-y-2">
            <p>
              You can also select one or more Data Sources to include in the
              prompt. This will make Ed aware of the table schemas in the
              selected Data Sources.
            </p>
            <Alert>
              <AlertIcon />
              Including too many Data Sources can make the prompt excessively
              long, potentially impacting the accuracy and performance of Ed's
              responses.
            </Alert>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
