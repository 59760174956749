import { DocumentIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import { MaterializationType } from "@/apollo/types";
import TableIcon from "@/components/icons/TableIcon";
import classNames from "@/helpers/classNames";

type ModelIconProps = {
  type?: MaterializationType;
  isDraft?: boolean;
} & React.ComponentProps<typeof DocumentIcon>;

const ModelSVGIcon = ({
  type,
  className,
  isDraft,
  ...svgProps
}: ModelIconProps) => {
  const Icon = isDraft
    ? DocumentIcon
    : type === MaterializationType.Table
      ? TableIcon
      : DocumentTextIcon;
  return <Icon className={classNames("shrink-0", className)} {...svgProps} />;
};

export default ModelSVGIcon;
