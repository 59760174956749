import { ComponentProps } from "react";

import { Button } from "@/components/elements/Button";
import Tooltip from "@/components/elements/Tooltip";
import { SourceStream } from "@/features/elt";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

import { StreamMode } from "../types/StreamMode";
import { OptionTooltips } from "./OptionTooltips";

export const SyncLabels = (props: {
  streamState?: SourceStream;
  mode: StreamMode;
  configurableIncremental: boolean;
  onIncrementalClick: () => void;
}) => {
  const showMidnightTooltip = props.streamState?.fullSyncAtMidnight;
  const midnightTooltip = (
    <ModeTooltip content={OptionTooltips.midnight}>
      <span>
        <Button as="div" size="xs" className="pointer-events-none">
          Midnight
        </Button>
      </span>
    </ModeTooltip>
  );

  const showProtectedTooltip = props.streamState?.protectedFromFullSync;
  const protectedTooltip = (
    <ModeTooltip content={OptionTooltips.protectedShort}>
      <span>
        <Button as="div" size="xs" className="pointer-events-none">
          Protected
        </Button>
      </span>
    </ModeTooltip>
  );

  switch (props.mode) {
    case StreamMode.FIXED_FULL:
      return (
        <ModeTooltip content={OptionTooltips.fullShort}>
          <span>
            <Button as="div" size="xs" className="pointer-events-none">
              Full
            </Button>
          </span>
        </ModeTooltip>
      );
    case StreamMode.MANUAL_FULL:
      return (
        <ModeTooltip content={OptionTooltips.full}>
          <span>
            <Button as="div" size="xs" className="pointer-events-none">
              Full
            </Button>
          </span>
        </ModeTooltip>
      );
    case StreamMode.INCREMENTAL:
      return (
        <>
          {showMidnightTooltip && midnightTooltip}
          {showProtectedTooltip && protectedTooltip}
          <ModeTooltip
            content={
              props.configurableIncremental
                ? OptionTooltips.incremental
                : OptionTooltips.incrementalShort
            }
          >
            <span>
              <Button
                as="div"
                size="xs"
                iconRight={
                  props.configurableIncremental ? (
                    <PencilSquareIcon />
                  ) : undefined
                }
                className={
                  props.configurableIncremental
                    ? "cursor-pointer"
                    : "pointer-events-none"
                }
                onClick={
                  props.configurableIncremental
                    ? props.onIncrementalClick
                    : undefined
                }
              >
                Incremental
              </Button>
            </span>
          </ModeTooltip>
        </>
      );
    case StreamMode.APPEND:
      return (
        <>
          {showMidnightTooltip && midnightTooltip}
          {showProtectedTooltip && protectedTooltip}
          <ModeTooltip content={OptionTooltips.append}>
            <span>
              <Button
                type="button"
                size="xs"
                iconRight={
                  props.configurableIncremental ? (
                    <PencilSquareIcon />
                  ) : undefined
                }
                className={
                  props.configurableIncremental
                    ? "cursor-pointer"
                    : "pointer-events-none"
                }
                onClick={
                  props.configurableIncremental
                    ? props.onIncrementalClick
                    : undefined
                }
              >
                Append
              </Button>
            </span>
          </ModeTooltip>
        </>
      );
  }
};

const ModeTooltip = (props: ComponentProps<typeof Tooltip>) => (
  <Tooltip {...props} maxWidth="300px" placement="top-end" />
);
