import {
  ChevronRightIcon,
  DocumentTextIcon,
  FolderIcon,
} from "@heroicons/react/24/outline";
import {
  ListModelFoldersDocument,
  useCreateModelFolderMutation,
} from "@/apollo/types";
import classNames from "@/helpers/classNames";
import { useCurrentAccount } from "@/providers/account";
import { useToast } from "@/providers/ToastProvider";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useNewSidebarItemState } from "../useNewSidebarItemState";
import { useSidebarFolders } from "../useSidebarFolders";

interface Props {
  newItem: {
    itemType: "folder" | "model";
    folderId?: string;
  };
}

const NewItem: React.FC<Props> = (props) => {
  const { openFolder } = useSidebarFolders();
  const { clearNewItem } = useNewSidebarItemState();

  const { dataWarehouseConnectionId } = useCurrentAccount();

  const [newName, setNewName] = useState("");

  const toast = useToast();

  const [createFolder, { loading: creatingFolder }] =
    useCreateModelFolderMutation({
      onError(error) {
        toast(`Folder not created`, error.message, "error");
      },
      onCompleted(data) {
        openFolder(data.createModelFolder.id);
        toast(
          `Folder created`,
          `The folder was succesfully created`,
          "success",
        );
        clearNewItem();
      },
      refetchQueries: [
        {
          query: ListModelFoldersDocument,
        },
      ],
    });

  const nameError = useMemo(() => validateItemName(newName), [newName]);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSaveFolder = useCallback(
    async (name: string) => {
      await createFolder({
        variables: {
          input: {
            name,
            DWName: name,
            parentId: props.newItem.folderId,
          },
        },
      });
    },
    [createFolder, props.newItem.folderId],
  );

  const saveNewItem = useCallback(async () => {
    if (!dataWarehouseConnectionId || creatingFolder) return;

    if (newName.length === 0) {
      clearNewItem();
      return;
    }
    if (nameError) {
      toast("Invalid name", nameError, "error");
      inputRef.current?.focus();
      return;
    }
    if (props.newItem.itemType === "folder") {
      handleSaveFolder(newName);
    } else {
      toast(
        "Not supported",
        "Only creating folders is currently supported.",
        "warning",
      );
    }
  }, [
    dataWarehouseConnectionId,
    newName,
    nameError,
    props.newItem.itemType,
    clearNewItem,
    toast,
    handleSaveFolder,
    creatingFolder,
  ]);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 50);
  }, []);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        clearNewItem();
      } else if (e.key === "Enter") {
        saveNewItem();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [clearNewItem, saveNewItem]);

  return (
    <>
      <div
        className={classNames(
          "flex items-center space-x-1 bg-white py-1 text-sm dark:bg-gray-800",
        )}
      >
        {/* Add folder or model icon */}
        {props.newItem.itemType === "folder" ? (
          <>
            <ChevronRightIcon className="w-3 shrink-0 text-gray-600" />
            <FolderIcon className="h-4 w-4" />
          </>
        ) : (
          <DocumentTextIcon className="w-4 shrink-0 text-gray-600" />
        )}
        <div className="text-xs">
          <input
            className="focus:outline-none dark:bg-transparent dark:text-white"
            onBlur={() => {
              saveNewItem();
            }}
            spellCheck={false}
            ref={inputRef}
            value={newName}
            onChange={(e) => {
              setNewName(e.target.value);
            }}
          />
        </div>
      </div>
      {nameError && (
        <>
          <div className="absolute left-0 top-0 w-full border-t border-red-500" />
          <div className="absolute bottom-0 left-0 w-full border-b border-red-500" />
        </>
      )}
    </>
  );
};

export default NewItem;

const validateItemName = (name: string) => {
  if (/__/.test(name)) return "Names cannot contain double underscore.";
  const isValid = /^[\w_]*$/.test(name);
  if (!isValid) return "Names can only contain letters and underscore.";
  return undefined;
};
