import produce from "immer";
import { atom, useAtom } from "jotai";

import {
  ModelTestResult,
  StartTestDocument,
  StartTestQuery,
  TestStatus,
  useGetTestResultQuery,
} from "@/apollo/types";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";

const testResultAtom = atom<{ [key: string]: ModelTestResult }>({});
export const useTestResultsAtom = () => useAtom(testResultAtom);

export const useStartRunningTest = () => {
  const toast = useToast();

  const client = useApolloClient();

  const [testResults, setTestResults] = useTestResultsAtom();

  const startRunningTest = async (modelTestId: string) => {
    const existingTest = testResults[modelTestId] as
      | ModelTestResult
      | undefined;
    if (existingTest?.status === TestStatus.Running) return;

    try {
      setTestResults((prev) => {
        return produce(prev, (prev) => {
          prev[modelTestId] = { modelTestId, status: TestStatus.Running };
        });
      });

      const { data, error }: ApolloQueryResult<StartTestQuery> =
        await client.query({
          query: StartTestDocument,
          variables: { modelTestId },
          fetchPolicy: "network-only",
        });

      const result = data?.startTest;
      if (!result) {
        throw new Error(error?.message ?? "Error starting test execution");
      }

      setTestResults((prev) => {
        return produce(prev, (prev) => {
          prev[modelTestId] = result;
        });
      });
    } catch (error: any) {
      toast("Error starting test", error.message, "error");
      setTestResults((prev) => {
        return produce(prev, (prev) => {
          prev[modelTestId] = {
            modelTestId,
            status: TestStatus.Failed,
            error: error?.message,
          };
        });
      });
    }
  };

  return startRunningTest;
};

export const useTestResult = (
  modelTestId: string,
): ModelTestResult | undefined => {
  const [testResult, setTestResult] = useAtom(testResultAtom);
  const existingResult = testResult[modelTestId];
  const account = useCurrentAccount();
  useGetTestResultQuery({
    fetchPolicy: "network-only",
    variables: {
      modelTestId,
      queryExecutionId: existingResult?.queryExecutionId ?? "",
      sourceConnectionId: account.dataWarehouseConnectionId ?? "",
    },
    pollInterval: 1000,
    skip:
      !existingResult?.queryExecutionId ||
      existingResult?.status !== TestStatus.Running,
    onCompleted: (data) => {
      setTestResult((prev) => {
        return produce(prev, (prev) => {
          prev[modelTestId] = {
            ...data.getTestResult,
            modelTestWeldSql: prev[modelTestId]?.modelTestWeldSql,
          };
        });
      });
    },
    onError: (error) => {
      setTestResult((prev) => {
        return produce(prev, (prev) => {
          prev[modelTestId] = {
            modelTestId,
            status: TestStatus.Failed,
            error: error?.message,
            modelTestWeldSql: prev[modelTestId]?.modelTestWeldSql,
          };
        });
      });
    },
  });

  return existingResult;
};
