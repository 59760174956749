import { ComponentProps } from "@tw-classed/react";
import { IconButton } from "@/components/elements/Button";
import cn from "@/helpers/classNames";
import { forwardRef } from "react";

export const PaneActionButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof IconButton>
>((props, ref) => {
  return (
    <IconButton
      ref={ref}
      size="xs"
      variant="ghost"
      {...props}
      className={cn("px-1.5", props.className)}
    />
  );
});
