import { Notification, NotificationEntityType } from "@/apollo/types";
import IconWithBG from "@/components/elements/IconWithBG";
import OrchestrationIcon from "@/components/icons/OrchestrationIcon";
import TableIcon from "@/components/icons/TableIcon";
import { MessageData } from "@/features/notifications/components/NotificationDetailModal";
import { IntegrationLogoBox } from "@/integrations";

function getTargetIntegrationId(
  notification: Notification,
): string | undefined {
  return notification.source.toLowerCase().includes("activate")
    ? notification.detail?.destinationIntegrationId
    : notification.detail?.sourceIntegrationId;
}

function getTitleProp(notification: Notification) {
  if (notification.entityType === NotificationEntityType.Materialisation) {
    return `Table update (${notification.entityName})`;
  }
  if (
    notification.entityType === NotificationEntityType.Sync ||
    notification.entityType === NotificationEntityType.Stream
  ) {
    return `${notification.detail.sourceIntegrationId} (${notification.entityName})`;
  }
  return notification.entityName ?? notification.accountName;
}

function getIconProp(notification: Notification) {
  if (notification.entityType === NotificationEntityType.Workflow) {
    return (
      <IconWithBG
        size="xs"
        icon={<OrchestrationIcon />}
        className="bg-gray-200 dark:bg-gray-700"
      />
    );
  }
  if (notification.entityType === NotificationEntityType.Materialisation) {
    return (
      <IconWithBG
        size="xs"
        icon={<TableIcon />}
        className="bg-gray-200 dark:bg-gray-700"
      />
    );
  }
  return (
    <IntegrationLogoBox
      size="xs"
      id={getTargetIntegrationId(notification) || ""}
    />
  );
}

export function useNotificationItem(notification: Notification) {
  const messageData = notification.messageData as MessageData | null;

  return {
    id: notification.id,
    integrationId: getTargetIntegrationId(notification),
    title: messageData?.title ?? getTitleProp(notification),
    message: notification.message,
    alertLevel:
      notification.notificationLevel as `${Notification["notificationLevel"]}`,
    lastOccurence: new Date(notification.lastOccurence),
    icon: getIconProp(notification),
    messageData: notification.messageData as MessageData | null,
  };
}
