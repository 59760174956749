import { Role } from "@/apollo/types";
import { useDataWarehouseContext } from "@/providers/DataWarehouseProvider";
import { useCurrentUser } from "@/providers/UserProvider";
import { useUserRole } from "@/providers/account";

export function useSettingsTabs() {
  const user = useCurrentUser();
  const userRole = useUserRole();
  const dwh = useDataWarehouseContext();

  const tabs = [
    {
      name: "Connections",
      href: "../connections",
      id: "connections",
    },
    {
      name: "Users",
      href: "../users",
      id: "users",
    },
    {
      name: "Invitations",
      href: "../invitations",
      id: "invitations",
    },
  ];

  if (userRole === Role.Admin) {
    tabs.push({
      name: "Billing",
      href: "../billing",
      id: "billing",
    });
  }

  if (
    dwh.integration.id === "snowflake" ||
    dwh.integration.id === "weld-bigquery" ||
    dwh.integration.id === "bigquery"
  ) {
    tabs.push({
      name: "Data Warehouse",
      href: "../data-warehouse",
      id: "data-warehouse",
    });
  }

  tabs.push({
    name: "BI Tools",
    href: `../bi-tools`,
    id: "bi-tools",
  });

  tabs.push({
    name: "External Tools",
    href: `../external-tools`,
    id: "external-tools",
  });

  tabs.push({
    name: "Notifications",
    href: "../notifications",
    id: "notifications",
  });

  if (userRole === Role.Admin) {
    tabs.push({
      name: "Audit Logs",
      href: "../audit-logs",
      id: "audit-logs",
    });
  }

  if (user.weldSuperAdmin) {
    tabs.push({
      name: "Back office",
      href: "../back-office",
      id: "back-office",
    });
  }

  return [
    ...tabs,
    {
      name: "Delete",
      href: "../delete",
      id: "delete",
    },
  ];
}
