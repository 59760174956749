import { Helmet } from "react-helmet-async";

import capitalize from "@/helpers/capitalize";
import useConvertBreadcrumb from "@/hooks/useConvertBreadcrumb";
import { LocationGenerics } from "@/routes";
import { useMatches } from "@tanstack/react-location";

export default function Title() {
  const matches = useMatches<LocationGenerics>();
  const match = matches[matches.length - 1];
  const [, , ...breadcrumbs] = match.pathname.split("/");
  const convertBreadcrumb = useConvertBreadcrumb();
  return (
    <Helmet>
      <title>
        {breadcrumbs
          .map((page) => capitalize(convertBreadcrumb(page)))
          .join(" / ")}{" "}
        | WELD
      </title>
    </Helmet>
  );
}
