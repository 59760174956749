import { useCallback, useState } from "react";

import {
  Account,
  CurrentUserWithAccountsDocument,
  useCreateWorkspaceWithManagedDatawarehouseMutation,
  useGetSelfOnboardingProcessStatusLazyQuery,
  useStartSelfOnboardingProcessMutation,
} from "@/apollo/types";
import { useLatestValueRef } from "@/hooks/useLatestValueRef";

import { usePollDWConnectionStatus } from "./usePollDWConnectionStatus";

type AccountInfo = Pick<Account, "id" | "slug" | "name">;

type CallbackOptions = {
  onCompleted: (account: AccountInfo) => void;
  onError: (error: Error, account?: AccountInfo) => void;
};

/**
 * Create a new workspace with a managed data warehouse
 */
export function useCreateWorkspaceWithManagedDataWarehouse(
  options: CallbackOptions & {
    onAccountCreated: (account: AccountInfo) => void;
  },
): [
  typeof createManagedWorkspace,
  {
    isConnecting: boolean;
    isConnected: boolean;
    data?: AccountInfo;
  },
] {
  const optionsRef = useLatestValueRef(options);
  const [account, setAccount] = useState<AccountInfo | undefined>(undefined);
  const [
    createManagedWorkspace,
    { called: createWorkspaceResultCalled, reset: createWorkspaceResultReset },
  ] = useCreateWorkspaceWithManagedDatawarehouseMutation({
    onCompleted(data) {
      options.onAccountCreated(data.createWorkspaceWithManagedDatawarehouse);
      setAccount(data.createWorkspaceWithManagedDatawarehouse);
    },
    refetchQueries: [CurrentUserWithAccountsDocument],
    awaitRefetchQueries: true,
  });

  const [getConnectStatus] = useGetSelfOnboardingProcessStatusLazyQuery();
  const { startPeriodicStatusCheck, isConnectionEstablished } =
    usePollDWConnectionStatus({
      getStatus: getConnectStatus,
      selectStatus: (data) => data?.getSelfOnboardingProcessStatus,
    });

  const create: typeof createManagedWorkspace = useCallback(
    async (options) => {
      return new Promise<Awaited<ReturnType<typeof createManagedWorkspace>>>(
        async (resolve, reject) => {
          await createManagedWorkspace({
            ...options,
            onError: (error) => {
              reject(error.message);
              optionsRef.current.onError(error);
              createWorkspaceResultReset();
            },
            onCompleted: (data) => {
              // Account is now created
              const account = data.createWorkspaceWithManagedDatawarehouse;
              setAccount(account);
              // Start polling for connection status to the data warehouse
              startPeriodicStatusCheck({
                context: {
                  accountId: account.id,
                },
                onStatusCheckCompleted: () => {
                  // Data warehouse is now connected
                  resolve({ data });
                  optionsRef.current.onCompleted(account);
                  createWorkspaceResultReset();
                },
                onStatusCheckError: (error) => {
                  // Data warehouse connection failed
                  reject(error.message);
                  optionsRef.current.onError(error, account);
                  createWorkspaceResultReset();
                },
              });
            },
          });
        },
      );
    },
    [
      createManagedWorkspace,
      startPeriodicStatusCheck,
      createWorkspaceResultReset,
      optionsRef,
    ],
  );

  return [
    create,
    {
      isConnecting: createWorkspaceResultCalled,
      isConnected: isConnectionEstablished,
      data: account,
    },
  ];
}

/**
 * Connect a managed data warehouse to an existing account
 */
export function useConnectManagedDataWarehouse(options: CallbackOptions) {
  const optionsRef = useLatestValueRef(options);
  const [connectDatawarehouse, { called, reset }] =
    useStartSelfOnboardingProcessMutation({
      onError() {
        reset();
      },
    });

  const [getConnectStatus] = useGetSelfOnboardingProcessStatusLazyQuery();
  const { startPeriodicStatusCheck, isConnectionEstablished } =
    usePollDWConnectionStatus({
      ...options,
      getStatus: getConnectStatus,
      selectStatus: (data) => data?.getSelfOnboardingProcessStatus,
    });

  return {
    connectDatawarehouse: useCallback(
      async (account: AccountInfo, location: string) => {
        // Start connecting data warehouse
        return new Promise<void>((resolve, reject) => {
          connectDatawarehouse({
            context: {
              accountId: account.id,
            },
            variables: {
              location: location,
            },
            onError(error) {
              optionsRef.current.onError(error, account);
              reset();
              reject();
            },
            onCompleted() {
              // Start polling for connection status to the data warehouse
              startPeriodicStatusCheck({
                context: {
                  accountId: account.id,
                },
                onStatusCheckCompleted: () => {
                  // Data warehouse is now connected
                  resolve();
                  optionsRef.current.onCompleted(account);
                  reset();
                },
                onStatusCheckError: (error) => {
                  // Data warehouse connection failed
                  reject(error.message);
                  optionsRef.current.onError(error, account);
                  reset();
                },
              });
            },
          });
        }).catch((error) => void error);
      },
      [connectDatawarehouse, optionsRef, startPeriodicStatusCheck, reset],
    ),
    isConnecting: called,
    isConnected: isConnectionEstablished,
  };
}
