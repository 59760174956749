import { Checkbox } from "@/components/primitives/Checkbox";
import FieldLabel from "@/components/primitives/InputLabel";
import { FormType } from "@/integrations";
import { Description } from "@/pages/Setup/components/Typography";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

type Props = {
  field: Extract<FormType, { type: "checkbox" }>;
};

export default function CheckboxInput({ field }: Props) {
  const { name: id, inputProps, required, label, description } = field;

  const { trigger, unregister, register } = useFormContext();

  useEffect(() => {
    trigger(id);
  }, [id, trigger]);

  useEffect(() => {
    return () => unregister(id);
  }, [id, unregister]);

  return (
    <div className="pb-4">
      <FieldLabel id={id} required={required}>
        {label}
      </FieldLabel>
      <Description className="text-xs">{description}</Description>
      <Checkbox
        {...inputProps}
        {...register(id, {
          required,
        })}
      />
    </div>
  );
}
