import { useMemo } from "react";

import { Notification, NotificationsQuery } from "@/apollo/types";
import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import { useCurrentAccount } from "@/providers/account";

import { useNotifications } from "../useNotifications";

export const useFilteredNotifications = (filterBy?: {
  connectionIds?: string[];
  syncIds?: string[];
  scheduledJobIds?: string[];
}) => {
  const { notifications: allNotifications, loading } = useNotifications();
  const accountId = useCurrentAccount().id;

  const filterByRef = useLatestValueRef(filterBy);
  const notifications = useMemo(() => {
    return filterNotifications(allNotifications, {
      connector: filterByRef.current?.connectionIds,
      sync: filterByRef.current?.syncIds,
      eltStream: filterByRef.current?.scheduledJobIds,
      account: [accountId],
    });
  }, [accountId, allNotifications, filterByRef]);

  return {
    notifications,
    loading,
  };
};

export type FilteredNotifications = {
  count: number;
  account: Notification[];
  connector: Notification[];
  sync: Notification[];
  eltStream: Notification[];
};

/**
 * A query-like utility function for filtering out the all-notifications list
 * based on a provided set of matching entityIds.
 *
 * The filtering operations are quite common, so we group them up in a single loop this way.
 * It also allows for a more declarative approach to the filtering logic.
 */
const filterNotifications = (
  notifications: NotificationsQuery["notifications"],
  match: {
    account?: string[];
    connector?: string[];
    sync?: string[];
    eltStream?: string[];
  },
): FilteredNotifications => {
  return notifications.reduce(
    (acc, notification) => {
      const entityType =
        notification.entityType === "ACCOUNT" && notification.isLock
          ? "account"
          : notification.entityType === "CONNECTOR"
            ? "connector"
            : notification.entityType === "SYNC"
              ? "sync"
              : notification.entityType === "STREAM"
                ? "eltStream"
                : null;

      if (!entityType) return acc;

      const entityIds = match[entityType];
      if (
        entityIds?.length &&
        entityIds.includes(notification.entityId ?? "")
      ) {
        acc[entityType].push(notification);
        acc.count++;
      }

      return acc;
    },
    {
      count: 0,
      account: [],
      connector: [],
      sync: [],
      eltStream: [],
    } as FilteredNotifications,
  );
};

/**
 * Utility function to generate unique IDs to refer to a sourcestreams.
 * This is the same ID used for scheduledJobId, in the backend.
 */
export const getSourceStreamJobIds = (input: {
  eltSyncId: string;
  sourceIntegrationId: string;
  sourceStreamNames: string[];
}) => {
  return input.sourceStreamNames.flatMap((sourceStreamName) => {
    const formattedSourceStreamName = sourceStreamName.replaceAll(" ", "_");
    return `${input.eltSyncId}-${input.sourceIntegrationId}-${formattedSourceStreamName}`;
  });
};

export const useDedupedEltStreamNotifications = (
  filteredNotifications: FilteredNotifications,
) => {
  const eltStreamNotifications = useMemo(() => {
    // For jobs/stream level we want to show only one notification per sync, so we need to group them by syncId
    const syncNotifications: {
      [syncId: string]: Notification[];
    } = {};
    filteredNotifications.eltStream.forEach((notification) => {
      const syncId =
        notification.detail.syncId ?? notification.detail.eltSyncId;
      if (!syncId) return;

      const alreadyInNotifications = filteredNotifications.sync.find(
        (syncNotification) => syncNotification.entityId === syncId,
      );
      if (alreadyInNotifications) return;

      if (!syncNotifications[syncId]) {
        syncNotifications[syncId] = [];
      }
      syncNotifications[syncId].push(notification);
    });

    return Object.entries(syncNotifications).map(([syncId, notifications]) => {
      return {
        syncId,
        notifications,
      };
    });
  }, [filteredNotifications.eltStream, filteredNotifications.sync]);

  return eltStreamNotifications;
};
