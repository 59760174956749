import { useEffect } from "react";

import { useLatestValueRef } from "./useLatestValueRef";

/**
 * Run effect only when component is unmounted.
 */
export function useUnmountEffect(effect: CallableFunction): void {
  const effectRef = useLatestValueRef(effect);

  useEffect(
    () => () => {
      effectRef.current();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
