import { XMarkIcon } from "@heroicons/react/24/outline";
import { useValidateCustomFormLazyQuery } from "@/apollo/types";
import { Alert, AlertIcon } from "@/components/elements/Alert";
import Select from "@/components/elements/Select";
import { Input } from "@/components/primitives/input";
import FieldLabel from "@/components/primitives/InputLabel";
import { useEffect, useState } from "react";

import {
  GoogleAnalytics4Report,
  GoogleAnalytics4ReportOption,
  isAnalyticsReportType,
} from "./GoogleAnalytics4Form";

const INVALID_REPORT_MESSAGE = "Invalid report";

export default function GoogleAnalytics4CustomReport(props: {
  onChangeReport: (report: GoogleAnalytics4Report) => void;
  onRemoveReport: (report: GoogleAnalytics4Report) => void;
  onReportInvalid: (reportId: string) => void;
  onReportValid: (reportId: string) => void;
  report: GoogleAnalytics4Report;
  initialDimensionOptions: { label: string; value: string }[];
  initialMetricOptions: { label: string; value: string }[];
  connectionId: string;
  invalidCombination: boolean;
  propertyId?: string;
  validationError?: string;
}) {
  const {
    report,
    initialDimensionOptions,
    initialMetricOptions,
    onChangeReport,
    onRemoveReport,
    validationError,
    connectionId,
    propertyId,
    invalidCombination,
    onReportValid,
    onReportInvalid,
  } = props;

  const [validDimensions, setValidDimensions] = useState<
    GoogleAnalytics4ReportOption[] | null
  >(null);
  const [validMetrics, setValidMetrics] = useState<
    GoogleAnalytics4ReportOption[] | null
  >(null);
  const [validationCallError, setValidationCallError] =
    useState<boolean>(false);

  const [callValidationQuery, { loading: validationQueryLoading }] =
    useValidateCustomFormLazyQuery();

  useEffect(() => {
    async function runValidation() {
      //Call validation endpoint
      const queryData = {
        metrics: report.metrics.map((x) => x.value),
        dimensions: report.dimensions.map((x) => x.value),
        id: report.id,
      };
      const { data, error } = await callValidationQuery({
        variables: {
          input: {
            connectionId,
            payload: JSON.stringify({ report: queryData, propertyId }),
          },
        },
      });

      //If error occurs, show warning and use initial metrics/dimensions
      if (error) {
        if (error.message === INVALID_REPORT_MESSAGE) {
          onReportInvalid(report.id);
        } else {
          setValidationCallError(true);
        }
        setValidDimensions(null);
        setValidMetrics(null);
      }

      /*
        The result we are getting from the validation endpoint is a list of metrics and dimensions
        that are compatible with the report at it's current state.
      */
      const result = data?.validateCustomForm.customFormValidationResult;

      if (result) {
        onReportValid(report.id);
        setValidDimensions(
          result.dimensions.map((x: string) => ({ label: x, value: x })),
        );
        setValidMetrics(
          result.metrics.map((x: string) => ({ label: x, value: x })),
        );
      }
    }

    /*
      Don't call the validation if we got an error before
      The error is either a 5xx error in the call to the API or a rate limiting error
      As Google is sanctioning for producing too many errors (10), we should avoid repeating failing requests
    */
    if (!validationCallError) {
      if (report.metrics.length > 0 || report.dimensions.length > 0) {
        runValidation();
      } else {
        setValidDimensions(null);
        setValidMetrics(null);
        onReportValid(report.id); //If we cannot validate, we assume it is valid
      }
    }
  }, [
    report.id,
    report.metrics,
    report.dimensions,
    connectionId,
    propertyId,
    callValidationQuery,
    validationCallError,
    onReportInvalid,
    onReportValid,
  ]);

  return (
    <div
      className="relative rounded-sm border bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700"
      key={report.id}
    >
      <div className="pb-4">
        <FieldLabel id="name" required={true}>
          Name
        </FieldLabel>
        <Input
          value={report.name}
          onChange={(e) => onChangeReport({ ...report, name: e.target.value })}
        />
        {isAnalyticsReportType(report.name) && (
          <div className="text-red-500">
            {report.name} is a reserved report name and cannot be used.
          </div>
        )}
      </div>
      {/*This form element does not have any effect, having this is handled in the backend. The field is needed for UX understandability*/}
      <div className="pb-4">
        <FieldLabel id="name" required={true}>
          Required dimensions
        </FieldLabel>
        <div className="dark:bg-gray-000 rounded-sm border bg-gray-100 p-2 opacity-75 dark:border-gray-700 dark:bg-gray-800">
          <span className="rounded-sm bg-black px-2 py-1 text-xs text-white dark:bg-gray-700">
            date
          </span>
        </div>
      </div>
      <div className="pb-4">
        <FieldLabel id="name" required={true}>
          Dimensions in custom report ({report.dimensions?.length ?? 0}/8)
        </FieldLabel>
        <Select
          isMulti
          isLoading={validationQueryLoading}
          placeholder="Select one"
          onChange={(v: any) => onChangeReport({ ...report, dimensions: v })}
          isOptionDisabled={() => report.dimensions?.length >= 8}
          options={validDimensions || initialDimensionOptions}
          value={report.dimensions}
        />
      </div>
      <div className="pb-4">
        <FieldLabel id="name" required={true}>
          Metrics in custom report ({report.metrics?.length ?? 0}/10)
        </FieldLabel>
        <Select
          isMulti
          isLoading={validationQueryLoading}
          placeholder="Select one"
          onChange={(v: any) => onChangeReport({ ...report, metrics: v })}
          options={validMetrics || initialMetricOptions}
          isOptionDisabled={() => report.metrics?.length >= 10}
          value={report.metrics}
        />
      </div>
      <div className="absolute right-0 top-0 p-2">
        <button type="button" onClick={() => onRemoveReport(report)}>
          <XMarkIcon className="w-4" />
        </button>
      </div>
      {validationError ? (
        <div className="mt-2">
          <Alert status="error">
            <AlertIcon />
            {validationError}
          </Alert>
        </div>
      ) : null}
      {invalidCombination ? (
        <div className="mt-2">
          <Alert status="error">
            <AlertIcon />
            The chosen metrics and dimensions are not compatible. Please remove
            the item you've added last
          </Alert>
        </div>
      ) : null}
      {validationCallError ? (
        <div className="mt-2">
          <Alert status="warning">
            <AlertIcon />
            We could not validate your report. Dimension and metric combination
            might be invalid.
          </Alert>
        </div>
      ) : null}
    </div>
  );
}
