import { datadogRum } from "@datadog/browser-rum";

import { ProductionEnvironmentName } from "./utils";

export default function init(
  releaseId: string,
  environment: ProductionEnvironmentName,
) {
  datadogRum.init({
    applicationId: "656f2bed-d7e7-46ab-a077-178e5ad6d533",
    clientToken: "pub0f2f3d94b03f595f9d0231c44e66522e",
    site: "datadoghq.eu",
    service: "weld-app",
    version: releaseId,
    sessionSampleRate: 100,
    sessionReplaySampleRate: environment !== "production" ? 0 : 100,
    trackInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    env: environment,
  });

  /**
   * Start session replay recording for 50% of users.
   * The reason for manually performing a draw here instead of setting
   * `sessionReplaySampleRate` to 50% is that we want to be able to explicitly
   * start session recording based based on other factors (e.g. new user going through signup flow).
   * This is not possible unless `sessionReplaySampleRate` is set to 100%.
   */
  const shouldStartSessionReplayRecording = Math.random() < 0.5;
  if (shouldStartSessionReplayRecording) {
    datadogRum.startSessionReplayRecording();
  }
}
