import { remove } from "firebase/database";
import useIdleTimer from "@/hooks/useIdleTimer";
import { useMountEffect } from "@/hooks/useMountEffect";
import { useCurrentAccount } from "@/providers/account";
import { useCurrentUser } from "@/providers/UserProvider";
import { useEffect } from "react";

import { getUserCursorPath, getUserPath, getUsersPath } from "./lib/db/paths";
import { useDatabaseReference } from "./lib/db/useDatabaseReference";

export function useAutoHideRemoteUserSelection(
  modelId: string | undefined,
  timeoutMs = 60_000,
) {
  const account = useCurrentAccount();
  const user = useCurrentUser();

  const allUsersRef = useDatabaseReference(() => {
    if (!modelId) {
      return undefined;
    }
    return getUsersPath(account.id, modelId);
  });
  const currentUserRef = useDatabaseReference(() => {
    if (!modelId) {
      return undefined;
    }
    return getUserPath(account.id, modelId, user.id);
  });
  const currentUserCursorRef = useDatabaseReference(() => {
    if (!modelId) {
      return undefined;
    }
    return getUserCursorPath(account.id, modelId, user.id);
  });

  useMountEffect(() => {
    // Clear all users in the model initially.
    // This is a bit of a hack to work around a bug in @otjs/firebase-monaco where users are not always removed when leaving the model
    // Users and their cursors will be recreated as soon as a user interacts with the model.
    if (allUsersRef) {
      void remove(allUsersRef);
    }
  });

  const isIdle = useIdleTimer(timeoutMs);

  useEffect(() => {
    if (!isIdle) {
      return;
    }
    // Remove the cursor of the current user when the user is idle
    if (currentUserCursorRef) {
      void remove(currentUserCursorRef);
    }
  }, [isIdle, currentUserCursorRef]);

  useEffect(
    () => () => {
      // Remove the user when the component unmounts
      // There is a bug in @otjs/firebase-monaco that doesn't always remove the user
      if (currentUserRef) {
        void remove(currentUserRef);
      }
    },
    [currentUserRef],
  );
}
