import { useIntegrationsQuery } from "@/apollo/types";
import React, { createContext, useContext, useMemo } from "react";

import { Ability, IntegrationType } from "./types";
import { hasAbility } from "./utils";

export const IntegrationContext = createContext<IntegrationType[] | null>(null);

export const useIntegrations = (ability?: Ability): IntegrationType[] => {
  const integrations = useContext(IntegrationContext);
  if (integrations === null) {
    throw new Error(
      "useIntegrations must be used within a IntegrationsProvider",
    );
  }
  return useMemo(() => {
    const list =
      ability === undefined
        ? integrations
        : integrations.filter((i) => hasAbility(i, ability));
    return list;
  }, [integrations, ability]);
};

export const useIntegrationsMap = (
  ability?: Ability,
): Map<string, IntegrationType> => {
  const integrations = useIntegrations(ability);
  return useMemo(() => {
    return new Map(integrations.map((x) => [x.id, x]));
  }, [integrations]);
};

export const IntegrationsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { data, loading } = useIntegrationsQuery();
  if (loading) return null;
  return (
    <IntegrationContext.Provider value={data?.integrations ?? []}>
      {children}
    </IntegrationContext.Provider>
  );
};
