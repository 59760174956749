import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import invariant from "tiny-invariant";

export type Step = {
  id: string;
  title: string;
  // The component to render for this step
  component: React.ComponentType;
  // If true, the step is not clickable in the navigation
  isDisabled?: boolean;
  // If true, the step is not shown in the navigation
  isHidden?: boolean;
  tooltip?: string;
};

const StepNavigationContext = createContext<{
  steps: Step[];
  currentStepIndex: number;
  currentStep: Step;
  nextStep: Step | undefined;
  prevStep: Step | undefined;
  setCurrentStepIndex: (index: number) => void;
  onNextStep: () => void;
  onPrevStep: () => void;
  onGoToStep: (stepId: string) => void;
} | null>(null);

export function useStepsContext() {
  const ctx = useContext(StepNavigationContext);
  if (!ctx) {
    throw new Error(
      "useStepNavigation must be used within a StepNavigationContext.Provider",
    );
  }
  return ctx;
}

function getNextStep(steps: Step[], currentStepIndex: number) {
  return steps.slice(currentStepIndex + 1).find((step) => {
    return step.isDisabled !== true;
  });
}

function getPrevStep(steps: Step[], currentStepIndex: number) {
  return steps
    .slice(0, currentStepIndex)
    .reverse()
    .find((step) => {
      return step.isDisabled !== true;
    });
}

type InitialStepArg = number | string;

export function useSteps(
  steps: Step[],
  initialStepArg: InitialStepArg | (() => InitialStepArg) = 0,
) {
  invariant(steps.length > 0, "Steps must not be empty");
  invariant(
    steps.some((x) => x.isHidden !== true && x.isDisabled !== true),
    "At least one step must be active",
  );

  const [currentStepIndex, setCurrentStepIndex] = useState(() => {
    const initialStep =
      typeof initialStepArg === "function" ? initialStepArg() : initialStepArg;
    if (typeof initialStep === "number") {
      return initialStep;
    }
    return steps.findIndex((x) => x.id === initialStep) || 0;
  });
  const currentStep = steps[currentStepIndex];

  const { nextStep, prevStep } = useMemo(() => {
    return {
      nextStep: getNextStep(steps, currentStepIndex),
      prevStep: getPrevStep(steps, currentStepIndex),
    };
  }, [currentStepIndex, steps]);

  const onGoToStep = useCallback(
    (stepId: (typeof steps)[number]["id"]) => {
      const index = steps.findIndex((x) => x.id === stepId);
      if (index !== -1) {
        setCurrentStepIndex(index);
      }
    },
    [steps],
  );

  const onNextStep = useCallback(() => {
    const nextStep = getNextStep(steps, currentStepIndex);
    if (!nextStep) return;
    onGoToStep(nextStep?.id);
  }, [currentStepIndex, onGoToStep, steps]);

  const onPrevStep = useCallback(() => {
    const prevStep = getPrevStep(steps, currentStepIndex);
    if (!prevStep) return;
    onGoToStep(prevStep.id);
  }, [currentStepIndex, onGoToStep, steps]);

  return {
    steps,
    currentStepIndex,
    currentStep,
    nextStep,
    prevStep,
    setCurrentStepIndex,
    onNextStep,
    onPrevStep,
    onGoToStep,
  };
}

export function StepContextProvider({
  children,
  ...ctx
}: PropsWithChildren<ReturnType<typeof useSteps>>) {
  return (
    <StepNavigationContext.Provider value={ctx}>
      {children}
    </StepNavigationContext.Provider>
  );
}

export function useStep(id: string) {
  const { currentStep, steps } = useStepsContext();
  const index = steps.findIndex((x) => x.id === id);

  const isFirstStep = useMemo(() => {
    return steps.find((step) => step.isHidden !== true)?.id === id;
  }, [steps, id]);

  const isLastStep = useMemo(() => {
    function findLastVisible(steps: Step[]) {
      let i = steps.length - 1;
      while (i >= 0) {
        const step = steps[i];
        if (step.isHidden !== true) {
          return step;
        }
        i -= 1;
      }
    }
    return findLastVisible(steps)?.id === id;
  }, [steps, id]);

  return {
    index,
    isCurrentStep: currentStep.id === id,
    isFirstStep,
    isLastStep,
  };
}
