import {
  FindQueryHistoryQuery,
  useFindQueryHistoryQuery,
} from "@/apollo/types";
import { useMemo } from "react";

export type SqlHistoryItem = FindQueryHistoryQuery["model"]["sqlQueries"][0];

export const useQueryHistory = (modelId?: string) => {
  const { data: queryHistoryData } = useFindQueryHistoryQuery({
    variables: { modelId: modelId ?? "" },
    skip: !modelId,
  });

  const queryHistory = useMemo(() => {
    return queryHistoryData?.model.sqlQueries || [];
  }, [queryHistoryData]);

  return queryHistory;
};
