import { IntegrationLogo, useIntegration } from "@/integrations";

export function IntegrationBreadcrumb(props: { integrationId: string }) {
  const integration = useIntegration(props.integrationId);
  if (integration == null) return null;
  return (
    <div className="flex items-center gap-2">
      <IntegrationLogo id={props.integrationId} className="h-5 w-5" />
      <span className="truncate">{integration.name}</span>
    </div>
  );
}
