import Center from "@/components/elements/layout/Center";
import React from "react";

function NoDataFallback(props: React.ComponentProps<"span">) {
  return (
    <Center>
      <span
        className="py-8 text-lg uppercase text-gray-300 dark:text-gray-600"
        {...props}
      />
    </Center>
  );
}

export function NoDataPlaceholder() {
  return <NoDataFallback>No data available for this month</NoDataFallback>;
}

export function ErrorDataPlaceholder() {
  return <NoDataFallback>Error loading stats for this month</NoDataFallback>;
}
