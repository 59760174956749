import { isDevelopment } from "@/helpers/environment";

import { PaneState } from "./useReflexAccordion";
import { flexSum, isEqual, toRelativeValue } from "./utils";

export function calcFlexValues(options: {
  containerHeight?: number;
  collapsedPaneSize: number;
  minPaneSize: number;
}) {
  const { collapsedPaneSize, containerHeight, minPaneSize } = options;
  return {
    collapsedPaneFlex:
      containerHeight === undefined
        ? 0
        : toRelativeValue(containerHeight, collapsedPaneSize),
    minPaneFlex:
      containerHeight === undefined
        ? 0
        : toRelativeValue(containerHeight, minPaneSize),
  };
}

export function flexSumInvariant(panes: PaneState[]) {
  if (!isDevelopment) return;
  const totalFlex = flexSum(panes);
  const hasOpenPanes = panes.some((x) => !x.isCollapsed);
  if (hasOpenPanes && !isEqual(totalFlex, 1, 0.01)) {
    // eslint-disable-next-line no-console
    console.error("[invariant]", "flex sum != 1", totalFlex, panes);
  }
}
