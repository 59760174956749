import dayjs from "dayjs";
import { useMemo } from "react";

import {
  FindSyncHistoryResponse,
  GetJobLogsQuery,
  JobLoggingMessageStatus,
  useGetJobLogsQuery,
} from "@/apollo/types";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import classNames from "@/helpers/classNames";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

type Props = {
  onClose: () => void;
  parentJob: FindSyncHistoryResponse;
};

export function ReverseEltJobLoggingModalContainer({
  onClose,
  parentJob,
}: Props) {
  const { data: { getJobLogs: jobLogs = [] } = {}, stopPolling } =
    useGetJobLogsQuery({
      variables: {
        jobId: parentJob.jobId,
      },
      skip: !parentJob.jobId,
      pollInterval: 500,
    });

  const sortedJobLog = useMemo(() => {
    return [...jobLogs].reverse();
  }, [jobLogs]);

  return (
    <ReverseEltJobLoggingModal
      onClose={() => {
        stopPolling();
        return onClose();
      }}
      logs={sortedJobLog}
      job={parentJob}
    />
  );
}

export type ReverseEltJobLoggingModalProps = {
  onClose: () => void;
  logs: GetJobLogsQuery["getJobLogs"];
  job: FindSyncHistoryResponse;
};

export function ReverseEltJobLoggingModal(
  props: ReverseEltJobLoggingModalProps,
) {
  const logLevelToClassNameMap = {
    [JobLoggingMessageStatus.Log]: "text-gray-800 dark:text-white",
    [JobLoggingMessageStatus.Warn]: "text-yellow-500",
    [JobLoggingMessageStatus.Error]: "text-red-500",
  };
  return (
    <Modal onClose={props.onClose} isOpen={!!props.job} size="lg">
      <ModalCloseButton />
      <ModalHeader>Logs for Reverse Etl sync</ModalHeader>

      <ModalBody>
        <div className="text-xs text-muted-foreground">
          Started at: {dayjs(props.job.startedAt).format("DD/MM/YYYY HH:mm:ss")}
        </div>
        <div className="text-xs text-muted-foreground">
          ID: {props.job.jobId}
        </div>

        <div className="mt-3">
          {props.logs.map((log, i) => {
            const color = logLevelToClassNameMap[log.status] ?? "";

            return (
              <div
                className={classNames("flex items-start space-x-4", color)}
                key={i}
              >
                <span className="flex w-24 items-center justify-between">
                  <span>{dayjs(log.timestamp).format("HH:mm:ss")}</span>
                  <ChevronRightIcon className="w-3" />
                </span>
                <span className="flex-1">{log.message}</span>
              </div>
            );
          })}
        </div>
        <ModalFooter />
      </ModalBody>
    </Modal>
  );
}
