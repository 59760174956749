import dayjs, { ConfigType } from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);

type FormatTimeProps = {
  date: ConfigType;
  format?: string;
  utc?: boolean;
  showYear?: boolean;
} & React.ComponentProps<"span">;

export function FormatDate({
  date: dateProp,
  format,
  utc,
  showYear,
  ...spanProps
}: FormatTimeProps) {
  const date = utc ? dayjs.utc(dateProp) : dayjs(dateProp);
  const now = utc ? dayjs.utc() : dayjs();
  const dateFormat =
    now.isSame(date, "year") && showYear !== true ? "MMM D" : "ll";
  return <span {...spanProps}>{date.format(format ?? dateFormat)}</span>;
}
