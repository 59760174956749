import { IdType, Row as RowType } from "react-table";

import { RowData } from "./columns";

export const globalFilterFn = (
  rows: Array<RowType<RowData>>,
  columnIds: Array<IdType<RowData>>,
  filterValue: any,
): Array<RowType<RowData>> => {
  if (filterValue === undefined || typeof filterValue !== "string") {
    return rows;
  }
  if (filterValue.trim() === "") {
    return rows;
  }
  return rows.filter((row) => {
    const d = row.original;
    return [
      d.destinationIntegrationId,
      d.destinationConnection.label,
      d.model?.name,
      d.primaryObject,
      d.config?.destinationSettings?.spreadsheet?.label,
      d.config?.destinationSettings?.sheet?.label,
      d.modelPath,
    ]
      .filter((value): value is string => typeof value === "string")
      .some((value) => value.toLowerCase().includes(filterValue.toLowerCase()));
  });
};
