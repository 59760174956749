import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import * as Sentry from "@sentry/react";
import { Button } from "@/components/elements/Button";
import { useMountEffect } from "@/hooks/useMountEffect";
import { useMixpanel } from "@/monitoring/mixpanel";

export const AppUnknownExceptionViewRenderer: Sentry.FallbackRender = (
  props,
) => {
  return <AppUnknownExceptionView {...props} />;
};

export const AppUnknownExceptionView = (
  props: Parameters<Sentry.FallbackRender>[0],
) => {
  const mixpanel = useMixpanel();

  const mpProps = {
    error: props.error.message,
    event_id: props.eventId,
  };

  useMountEffect(() => {
    mixpanel.track("AppUnknownExceptionView Shown", mpProps);
  });

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="flex max-w-md items-center space-x-10">
        <ExclamationTriangleIcon className="h-16 w-16 text-gray-500" />
        <div className="flex flex-col space-y-4">
          <div>An unknown error occured, please try reloading the app</div>
          <Button
            variant="outline"
            colorScheme="primary"
            size="sm"
            onClick={() => {
              mixpanel.track(
                "AppUnknownExceptionView Reload Clicked",
                mpProps,
                () => {
                  window.location.reload();
                },
              );
            }}
          >
            Reload app
          </Button>
        </div>
      </div>
    </div>
  );
};
