import { Input } from "@/components/primitives/input";
import { InputError } from "@/components/primitives/InputError";
import { Hidden } from "@/integrations/forms";
import { Controller, useFormContext } from "react-hook-form";

export default function FormHiddenInput({ field }: { field: Hidden }) {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { name, inputProps } = field;
  const error = errors?.[name]?.message;

  return (
    <div className="pb-4">
      <Controller
        control={control}
        defaultValue={field.value}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            onBlur={onBlur}
            onChange={(v: any) => onChange(v)}
            value={value}
            type="hidden"
            name={field.name}
            {...inputProps}
          />
        )}
        rules={{ required: true }}
        name={name}
      />
      {!!error && (
        <InputError className="mt-1">
          <>{error}</>
        </InputError>
      )}
    </div>
  );
}
