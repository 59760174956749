import { JSONParser } from "@streamparser/json-whatwg";
import { useMountEffect } from "@/hooks/useMountEffect";
import useUpdateEffect from "@/hooks/useUpdateEffect";
import useWeldAPI from "@/hooks/useWeldAPI";
import { useCallback, useRef, useState } from "react";

export type PromptSuggestion = {
  description: string;
  prompt: string;
  dependencies: string[];
};

type LazyListItem<T> = {
  value: T | null;
  key: string;
  isLoading: boolean;
};

export function usePromptSuggestionsFromSync(options: {
  syncId: string;
  suggestionsCount: number;
  autoFetch?: boolean;
}) {
  const fetchApi = useWeldAPI();

  const [isLoading, setIsLoading] = useState(false);
  const [promptSuggestions, setPromptSuggetions] = useState<
    LazyListItem<PromptSuggestion>[]
  >([]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const controller = new AbortController();
      abortControllerRef.current = controller;

      setPromptSuggetions(
        Array.from({ length: options.suggestionsCount }, (_, index) => ({
          value: null,
          key: index.toString(),
          isLoading: true,
        })),
      );

      const responseStream = await fetchApi(
        "/ai-data-insights-v2/prompt-suggestions-from-sync",
        {
          method: "POST",
          body: JSON.stringify({
            syncId: options.syncId,
            suggestionsCount: options.suggestionsCount,
          }),
          headers: {
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        },
      );

      streamPromptSuggestions(responseStream, {
        onEnd: () => {
          setIsLoading(false);
          abortControllerRef.current = null;
        },
        onData: (item, index: number) => {
          setPromptSuggetions((prev) => {
            const newItems = [...prev];
            newItems[index] = {
              value: item,
              key: item.description,
              isLoading: false,
            };
            return newItems;
          });
        },
      });
    } catch (error) {
      setIsLoading(false);
      abortControllerRef.current = null;
    }
  }, [fetchApi, options.suggestionsCount, options.syncId]);

  useMountEffect(() => {
    if (options.autoFetch !== false) {
      fetch();
    }
  });

  useUpdateEffect(() => {
    if (options.autoFetch !== false) {
      fetch();
    }
  }, [options.autoFetch, fetch]);

  return { promptSuggestions, isLoading, refetch: () => fetch() };
}

async function streamPromptSuggestions(
  response: Response,
  options: {
    onStart?: () => void;
    onEnd?: () => void;
    onData: (item: PromptSuggestion, index: number) => void;
  },
) {
  try {
    const parser = new JSONParser({
      stringBufferSize: undefined,
      paths: ["$.prompts.*"],
      keepStack: false,
    });

    options.onStart?.();

    const reader = response.body?.pipeThrough(parser).getReader();
    if (!reader) throw new Error("No reader returned from fetch");

    while (true) {
      const { done, value: parsedElementInfo } = await reader.read();
      if (done) break;
      const { value, key } = parsedElementInfo;
      if (
        value &&
        value.description &&
        value.prompt &&
        value.dependencies &&
        value.dependencies.length > 0
      ) {
        options.onData(value, key as number);
      }
    }
    options.onEnd?.();
  } catch (error: any) {
    options.onEnd?.();
  }
}
