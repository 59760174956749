import { useFirebaseDatabase } from "@/features/firebase";
import { useCurrentAccount } from "@/providers/account";
import { Tile } from "./VisualizationType";
import { useListVals } from "react-firebase-hooks/database";
import { ref } from "firebase/database";

export const useModelTiles = (modelId?: string) => {
  const database = useFirebaseDatabase();
  const account = useCurrentAccount();

  const [values] = useListVals<Tile>(
    ref(database, `workspaces/${account.id}/models/${modelId}/tiles`),
    {
      keyField: "i",
    },
  );

  return values ?? [];
};
