import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { ComponentProps, deriveClassed } from "@tw-classed/react";
import { classed } from "@/components/classed.config";
import cn from "@/helpers/classNames";
import { createContext, useContext } from "react";

const AlertContainer = classed.div(
  "flex w-full items-center px-4 py-3 text-sm",
  {
    variants: {
      status: {
        info: "bg-blue-50 dark:bg-blue-400/20",
        success: "bg-green-100 dark:bg-green-400/20",
        warning: "bg-yellow-400/30 dark:bg-yellow-600/20",
        error: "bg-red-100 dark:bg-red-400/20",
      },
    },
    defaultVariants: {
      status: "info",
    },
  },
);

const AlertIconBase = classed.span("mr-3 flex shrink-0 items-center", {
  variants: {
    status: {
      info: "text-blue-500",
      success: "text-green-500",
      warning: "text-yellow-600",
      error: "text-red-500",
    },
  },
});

type AlertStatus = "info" | "success" | "warning" | "error";

const StatusToIconDict: Record<AlertStatus, typeof InformationCircleIcon> = {
  info: InformationCircleIcon,
  success: CheckCircleIcon,
  warning: ExclamationCircleIcon,
  error: ExclamationTriangleIcon,
};

export function getStatusIcon(status: AlertStatus) {
  return StatusToIconDict[status];
}

const AlertContext = createContext<AlertStatus>("info");

export const Alert = deriveClassed<
  typeof AlertContainer,
  ComponentProps<typeof AlertContainer>
>(({ status = "info", children, ...restProps }, ref) => {
  return (
    <AlertContext.Provider value={status}>
      <AlertContainer
        ref={ref}
        status={status}
        {...(restProps as any)}
        className={cn(
          "flex w-full items-center px-4 py-3 text-sm",
          restProps.className,
        )}
      >
        {children}
      </AlertContainer>
    </AlertContext.Provider>
  );
});

export const AlertTitle = classed.div("mr-2 font-bold");

export const AlertIcon = deriveClassed<
  typeof AlertIconBase,
  ComponentProps<typeof AlertIconBase>
>((props, ref) => {
  const status = useContext(AlertContext);
  const Icon = getStatusIcon(status);
  return (
    <AlertIconBase ref={ref} status={status} {...props}>
      <Icon className="h-6 w-6" />
    </AlertIconBase>
  );
});
