import { useMatch, useMatches } from "@tanstack/react-location";
import { LocationGenerics } from "routes";

export const useIsInBackOffice = () => {
  const match = useMatch<LocationGenerics>();

  const isBackOffice = useMatches().some((m) =>
    m.pathname.includes("/back-office/"),
  );
  return {
    isBackOffice,
    accountId: match.params.accountId ?? "",
  };
};
