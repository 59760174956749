import { TextMuted } from "@/components/elements/Typography";
import cn from "@/helpers/classNames";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useSearch } from "@tanstack/react-location";

export function PaymentMethodSetupResultPage() {
  const search = useSearch<{
    Search: {
      payment_checkout: "success" | "cancelled" | string;
    };
  }>();

  const succeeded = search.payment_checkout === "success";
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div
        className={cn(
          "relative w-full max-w-lg rounded border bg-white p-8 dark:bg-gray-700",
          {
            "border-2 border-green-600": succeeded,
          },
        )}
      >
        {succeeded ? (
          <>
            <CheckCircleIcon className="absolute right-2 top-2 h-8 w-8 text-green-600" />
            <h1 className="flex items-center text-xl">
              Payment Method Setup Successful{" "}
            </h1>
            <TextMuted as="p" className="mt-4">
              You have successfully set up your payment method.
            </TextMuted>
          </>
        ) : (
          <>
            <h1 className="text-xl">Payment Method Setup Cancelled</h1>
            <TextMuted as="p" className="mt-4">
              You have cancelled the payment method setup.
            </TextMuted>
          </>
        )}
      </div>
    </div>
  );
}
