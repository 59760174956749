import { useChartTheme } from "@/features/charts/themes";
import { RowCountBasis } from "@/features/usage-stats";
import { range } from "lodash";
import { ComponentProps } from "react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";

import { gridLines } from "./chartConstants";
import ChartTooltip from "./ChartTooltip";
import PopperPortal from "./PopperPortal";
import { StatsTable } from "./StatsTable";
import SyncChartCategoryTick from "./SyncChartCategoryTick";
import { formatRowsSynced } from "./utils";

const yAxisWidth = 225;

type SourceStreamDatum = {
  integrationId: string;
  sourceStreamName: string;
  numRowsSynced: number;
  numBillableRows: number;
};

type RowsSyncedChartProps = {
  width?: number;
  height?: number;
  data: SourceStreamDatum[];
  rowCountBasis: RowCountBasis;
  isBillableRowsEnabled: boolean;
};

export function RowsSyncedBySourceStreamChart(props: RowsSyncedChartProps) {
  const { data, width: containerWidth, height: containerHeight } = props;
  const chartTheme = useChartTheme("stats");
  if (containerWidth == null || containerHeight == null) {
    return null;
  }
  return (
    <BarChart
      {...chartTheme.BarChart}
      layout="vertical"
      width={containerWidth}
      height={Math.max(data.length * 30, 120)}
      data={data}
    >
      <CartesianGrid
        {...chartTheme.CartesianGrid}
        horizontal={false}
        verticalPoints={range(gridLines).map((i) => {
          // Generate vertical grid lines positions
          const gridTickWidth = (containerWidth - yAxisWidth) / gridLines;
          return yAxisWidth + gridTickWidth * (i + 1);
        })}
      />
      <XAxis
        type="number"
        hide
        domain={([, dataMax]) => {
          // Extend the domain a bit by adding an additional grid line to make sure the label of longest bar is visible
          return [0, (dataMax / gridLines) * (gridLines + 1)];
        }}
      />
      <YAxis
        {...chartTheme.YAxis}
        dataKey={(d: SourceStreamDatum) => d.sourceStreamName}
        type="category"
        width={yAxisWidth}
        tick={
          <SyncChartCategoryTick
            getIntegrationId={(index: number) => data[index].integrationId}
          />
        }
      />
      <Tooltip
        {...chartTheme.Tooltip}
        content={
          <SourceStreamTooltip
            isBillableRowsEnabled={props.isBillableRowsEnabled}
          />
        }
      />

      <Bar
        {...chartTheme.Bar}
        dataKey={
          props.rowCountBasis === "billable-rows"
            ? "numBillableRows"
            : "numRowsSynced"
        }
        label={chartTheme.Bar.getLabelProps({
          formatter: formatRowsSynced,
        })}
      />
    </BarChart>
  );
}

type IntegrationTooltipProps = {
  active?: boolean;
  label?: string;
  payload?: {
    value: string;
    payload: SourceStreamDatum;
    fill: string;
  }[];
  isBillableRowsEnabled?: boolean;
};

function SourceStreamTooltip({
  active,
  label,
  payload,
  isBillableRowsEnabled,
}: IntegrationTooltipProps) {
  if (!active || !payload || !payload.length || !label) {
    return null;
  }

  const data = payload[0].payload;
  const statsData: ComponentProps<typeof StatsTable>["data"] = [
    {
      numRowsSynced: data.numRowsSynced,
      numBillableRows: data.numBillableRows,
      color: payload[0].fill,
      label: "",
    },
  ];

  const integrationId = payload[0].payload.integrationId;
  return (
    <PopperPortal active={active}>
      <ChartTooltip.Container className="flex flex-col gap-4 p-0">
        <ChartTooltip.Header
          integrationId={integrationId}
          name={label}
          meta={integrationId}
          className="px-3 pt-3"
        />
        <StatsTable showBillableRows={isBillableRowsEnabled} data={statsData} />
      </ChartTooltip.Container>
    </PopperPortal>
  );
}
