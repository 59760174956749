import { noop } from "lodash";
import { useEffect, useRef } from "react";
/**
 * Taken from https://usehooks-ts.com/react-hook/use-timeout
 * A declarative hook to set a timeout.
 */
export function useTimeout(callback: () => void, delay: number | null) {
  const savedCallback = useRef<typeof callback>(noop);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }
    let id = setTimeout(() => savedCallback.current(), delay);
    return () => clearTimeout(id);
  }, [delay]);
}
