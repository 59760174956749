import { SubscriptionStatus } from "@/apollo/types";
import { ButtonGroup } from "@/components/elements/Button";
import { useSubscriptionStatus } from "@/features/subscription";
import { useCurrentAccount } from "@/providers/account";

import { SyncsBlockedBookMeetingButton } from "../../SyncsBlocked";
import { UpgradeNowButton } from "../../UpgradeNowButton";
import { WorkspaceAlert, WorkspaceAlertResolver } from "../useWorkspaceAlerts";

export const useSyncsBlockedResolver: WorkspaceAlertResolver = () => {
  const { status } = useSubscriptionStatus();

  const account = useCurrentAccount();
  const accountSettings = account.accountSettings;
  const workspaceHasSyncsBlocked =
    accountSettings.deactivateELTSyncs ||
    accountSettings.deactivateReverseELTSyncs;

  if (workspaceHasSyncsBlocked === false) {
    return [];
  }

  const eltSyncsBlocked = accountSettings.deactivateELTSyncs;
  const reverseEtlSyncsBlocked = accountSettings.deactivateReverseELTSyncs;
  const action =
    status === SubscriptionStatus.Freemium ? (
      <ButtonGroup size="xs" className="flex gap-2">
        <UpgradeNowButton source="syncs blocked banner" />
        <SyncsBlockedBookMeetingButton />
      </ButtonGroup>
    ) : (
      <SyncsBlockedBookMeetingButton size="xs" />
    );

  const alerts: WorkspaceAlert[] = [];
  if (eltSyncsBlocked) {
    alerts.push({
      id: "elt-syncs-blocked",
      status: "warning",
      title: "Data source syncs have been deactivated for this account.",
      message:
        "Please book a call with us to continue syncing data to this account.",
      action,
    });
  }
  if (reverseEtlSyncsBlocked) {
    alerts.push({
      id: "reverse-etl-syncs-blocked",
      status: "warning",
      title: "Reverse ETL syncs have been deactivated for this account.",
      message:
        "Please book a call with us to continue exporting data from this account.",
      action,
    });
  }
  return alerts;
};
