import { useMemo } from "react";

import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Tooltip from "@/components/elements/Tooltip";
import classNames from "@/helpers/classNames";
import { IntegrationLogoBox } from "@/integrations";
import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import { useModelPath } from "@/pages/ModelTool/hooks/useModelFolder";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { ReverseEtlJobBox } from "./SharedComponents";
import { ReverseEltSyncRow } from "./useTableRows";

export const ReverseEltSyncItemColumn = ({
  row,
}: {
  row: ReverseEltSyncRow;
}) => {
  const isLoading = row.isLoading;

  const notInJob = !row.job;
  const noLongerInOrchestration =
    !isLoading && !row.isHistorical && row.isRemoved;

  const { models } = useListModels();

  const model = useMemo(
    () => models.find((x) => x.id === row.sync?.model?.id),
    [models, row],
  );

  const modelPath = useModelPath(model);

  return (
    <div
      className={classNames(
        "flex items-center space-x-4",
        noLongerInOrchestration && "opacity-50",
      )}
    >
      <ReverseEtlJobBox />
      {isLoading && (
        <div className="flex items-center space-x-2">
          <LoadingSpinner /> <span>Loading model..</span>
        </div>
      )}
      {row.sync && (
        <div className="text-xs">
          <div className="text-gray-600 dark:text-gray-400">{modelPath}</div>
          <div className="flex items-center gap-1">
            <IntegrationLogoBox
              id={row.sync.destinationIntegrationId}
              size="xs"
            />
            <span>{row.sync?.destinationConnection.label}</span>
          </div>
        </div>
      )}

      {notInJob && (
        <Tooltip
          content={
            "This sync was recently configured to use orchestration. It will be included in the next run."
          }
        >
          <div className="flex items-center space-x-1 rounded border p-1 text-xs">
            <InformationCircleIcon className="h-4 w-4" />
            <span>Recently added</span>
          </div>
        </Tooltip>
      )}
      {noLongerInOrchestration && (
        <Tooltip
          content={
            "This sync is no longer configured to use orchestration. It will not be included in the next run."
          }
        >
          <div className="flex items-center space-x-1 rounded border p-1 text-xs">
            <InformationCircleIcon className="h-4 w-4" />
            <span>Removed</span>
          </div>
        </Tooltip>
      )}
    </div>
  );
};
