import { createContext, useContext } from "react";

import { OrchestrationSchedulerType } from "@/apollo/types";
import { SourceStream } from "@/features/elt";

type StepState<T> = {
  isValid: boolean;
  isDirty: boolean;
  value: T;
};

export type DataSourceState = {
  source: StepState<{
    integrationId?: string;
    sourceId: string;
  }>;
  sourceSettings: StepState<{
    startDate?: string | null;
    sourceSettings: Record<string, any>;
  }>;
  tables: StepState<{
    sourceStreams: Map<string, SourceStream>;
  }>;
  syncSettings: StepState<{
    scheduleKey: string;
    schedulerType: OrchestrationSchedulerType;
    orchestrationWorkflowId?: string;
    destinationSchemaName: string;
  }>;
};

export const createInitialState = (values?: {
  integrationId?: string;
  sourceId?: string;
}): DataSourceState => ({
  source: {
    isValid: values?.sourceId ? true : false,
    isDirty: false,
    value: {
      integrationId: values?.integrationId,
      sourceId: values?.sourceId ?? "",
    },
  },
  sourceSettings: {
    isValid: false,
    isDirty: false,
    value: {
      startDate: null,
      sourceSettings: {},
    },
  },
  tables: {
    isValid: false,
    isDirty: false,
    value: {
      sourceStreams: new Map(),
    },
  },
  syncSettings: {
    isValid: false,
    isDirty: false,
    value: {
      scheduleKey: "0 0 * * *",
      schedulerType: OrchestrationSchedulerType.Local,
      destinationSchemaName: "",
      orchestrationWorkflowId: undefined,
    },
  },
});

export const DataSourceStateContext = createContext({
  state: createInitialState(),
  setState: (
    state: DataSourceState | ((state: DataSourceState) => DataSourceState),
  ) => {},
});

export function useDataSourceStateContext() {
  return useContext(DataSourceStateContext);
}
