import { SQLCodeDisplay } from "@/components/elements/CodeDisplay";
import { useFormatWeldSQL } from "@/pages/ModelTool/hooks/useFormatWeldSQL";
import { useMemo } from "react";

type Props = {
  sqlStatement: string;
};

const SqlHighlighter = (props: Props) => {
  const formatWeldSQL = useFormatWeldSQL();

  const formattedSql = useMemo(() => {
    return formatWeldSQL(props.sqlStatement).replaceAll(" ", "\u00a0");
  }, [props.sqlStatement, formatWeldSQL]);

  if (!formattedSql) return null;

  return <SQLCodeDisplay sql={formattedSql} />;
};

export default SqlHighlighter;
